import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import Modal from "./modal.js";

import axios from 'axios';
import { RiAdminFill, RiPencilLine } from "react-icons/ri";
import '../index.css';







class Adminhistoryehs extends Component {

    constructor(props) {
        super(props)
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });



        this.state = {
            count: 0,
            type1: 'Approved',
            type2: 'Rejected',
            addModalShow: false,
            types: [],
            rows: [],

            admin: 1,
            utype: localStorage.getItem('utype'),
            month: prevMonth,
            year: longYear,
            startDate: date,
            endDate: date,


        }

    }




    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var utype = localStorage.getItem('utype');
        var location = localStorage.getItem('location');
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if (utype == 3) {
            this.props.history.push('/unauthorized')

        }
        if (utypeid == 4) {

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/GetAdminHistoryEhs', { params: { UserId: 0, LocationId: 0, mode: 4, StartDate: startDate, EndDate: endDate } }).then(response => {
                // console.log(response.data);
                //  console.log(response.data[0].location.locationName);
                this.setState({
                    rows: response.data
                });
                console.log(this.state.rows)
            });
        }
        else {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/GetAdminHistoryEhs', { params: { UserId: 0, LocationId: parseInt(location), mode: 2, StartDate: startDate, EndDate: endDate } }).then(response => {
                // console.log(response.data);
                //  console.log(response.data[0].location.locationName);
                this.setState({
                    rows: response.data
                });
                console.log(this.state.rows)
            });

        }
        /*  axios.all([
              axios.get('https://localhost:23013/api/GetAdminApprovalForm/GetSubmissionsNoStats', { params: { UserId: parseInt(utype) } }),
  
              axios.get('https://localhost:23013/api/AdminApproval/GetTotalApproved', { params: { UserId: parseInt(utype) } }),
  
              axios.get('https://localhost:23013/api/AdminApproval/GetTotalRejected', { params: { UserId: parseInt(utype) } })
  
  
          ]).then(axios.spread((response1, response2,response3) => {
  
  
              console.log(response1.data);
              console.log(response2.data);
              console.log(response3.data);
              if (response1.data.Status === 'Success') {
                  alert("Data Save Successfully");
                  window.location.reload();
                  console.log(response1.data.Status);
  
              }
  
              else {
                  alert('Data Saved Successfully');
                  window.location.reload();
              } 
          }));*/
    }










    handleRemoveSpecificRow = (idx, id) => (e) => {

        e.preventDefault();
        let index = idx + 1;
        axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalRawMaterial/DeleteRawMaterial', { params: { TMatId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));



        //   let rowarr = [...this.state.rows];
        // let deletedItem = rowarr.findIndex(item => item.id === idx);
        // rowarr.splice(deletedItem, 1);
        // this.setState({...this.state.rows=rowarr})

        // let row = [...this.state.rows.filter(x=>x.id!==idx)];
        // this.setState({rows:row})




        //  const rows = [...this.state.rows]
        //   rows.splice(idx,1)
        //   this.setState({ rows })
        //   e.preventDefault()
        //  var newrows=this.state.rows.slice();
        //  newrows.splice(idx,1);
        //  this.setState({ rows:newrows })

        // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
        //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
        //  this.setState({
        //    rows: newRows,
        //  });

    }

    handleRowClick = (id, idx) => (event) => {
        
        localStorage.setItem('adminhistoryehs', 'true')
        this.props.history.push("/" + this.state.rows[idx].FormName + "/?id=" + id);
    }
    getColor = (status) => {
        if (status === "Approved") return '#F0FFFF ';
        if (status === "Rejected") return '#E84C2B';
        return '#EFF449';
    };
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleSubmit = event => {
        alert(`${this.state.rows}`)
        const data = new FormData(event.target);
        fetch('weatherforecast', {
            method: 'POST',
            body: data,
        });
    }

    doSomethingWith = (value) => {

    }
    renderRows() {

        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id} style={{ backgroundColor: this.getColor(item.Status) }}>





                    {/*<td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="submissionid" required id="standard-required" defaultValue={item.submissionId} />
                    </td > */}


                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="user" required id="standard-required" defaultValue={item.UserName} />
                    </td>

                    <td>

                        <TextField InputProps={{ readOnly: true }} type="text" name="location" required id="standard-required" defaultValue={item.LocationName} />



                    </td >
                    <td>

                        <TextField InputProps={{ readOnly: true }} type="text" name="FormName" required id="standard-required" defaultValue={this.capitalizeFirstLetter(item.FormName.replace('view', ''))} />



                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="date" required id="standard-required" defaultValue={item.SubmissionDate} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="user" required id="standard-required" defaultValue={item.Status} />
                    </td>
                    <td onClick={this.handleRowClick(item.submissionId, idx)} style={{ color: 'blue', textDecoration: 'underline' }}>
                        <a> View  </a>
                    </td>
                    {/*<td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="submitted" required id="standard-required" defaultValue={item.submittedTo} />
                    </td>*/}
                    {/*  <td>
                       
                        <FormControl>
                           
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="status"
                                onChange= {this.handleChange1(idx,item.submissionId)}
                            >
                                <MenuItem value={this.state.type1}>Approve</MenuItem>
                                <MenuItem value={this.state.type2}>Reject</MenuItem>
                               
                            </Select>
                                
                               
                            </FormControl></td> */}




                </tr>


            );

        });

    }

    handleChange = event => {

        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        const value = event.target.value;

        this.setState({
            [name]: value
        });
    };

    handleChange1 = event => {
        
        const name = event.target.name;

        const value = event.target.value;
        this.setState({
            [name]: value
        });
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalRawMaterial/GetTotalRawMaterialbyId', { params: { Search: value } }).then(response => {
            console.log(response.data);

        })
    }


    handleSubmit = event => {
        event.preventDefault();
        
        this.setState({
            rows: []
        });
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/GetAdminApprovalEhs/GetAdminHistoryEhsBetweenDates', { params: { UserId: 0, LocationId: 1, mode: 3, startDate: startDate, endDate: endDate } })
            .then(response => {
                this.setState({
                    rows: response.data
                });
                console.log(response.data)
            })
    }

    handleSubmit2 = event => {
        event.preventDefault();
        var location = localStorage.getItem('location');
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/GetAdminHistoryEhs', { params: { UserId: 0, LocationId: parseInt(location), mode: 2, StartDate: startDate, EndDate: endDate } }).then(response => {
            // console.log(response.data);
            //  console.log(response.data[0].location.locationName);
            this.setState({
                rows: response.data
            });
            console.log(this.state.rows)
        });

    }



    render() {

        return (

            <div>

                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>EHS Summary List </h5>
                <br />
                <Form className="font-link" >
                    <div>
                        <label for="StartDate">START DATE:</label>
                        <input type="date" id="StartDate" name="startDate" onChange={this.handleChange} />
                    &nbsp;
                    <label style={{ marginLeft: '15px', fontWeight: 'bold' }} for="EndDate">END DATE:</label>
                        <input type="date" id="EndDate" name="endDate" onChange={this.handleChange} />
                        <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
                        <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit2} type="submit" className="btn btn-success btn-sm">CLEAR</button>
                    </div>
                    <br />

                    {/*  <div>
                        <label for="Search">SEARCH:</label>
                        <input type="text" id="Search" name="Search" placeholder="User/Status" onChange={this.handleChange1} />

                    </div> */}

                    <br />
                    {/*<h5>ADMIN PAGE </h5>*/}

                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>


                        <thead>

                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                                {/*<th>SUBMISSION ID</th>*/}


                                <th>USER</th>
                                <th>LOCATION</th>
                                <th>FORM NAME</th>
                                <th>SUBMISSION DATE</th>
                                <th>STATUS </th>
                                <th>ACTION </th>

                            </tr>
                        </thead>
                        <tbody>







                            {this.renderRows()}




                        </tbody>
                    </Table>





                    {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                </Form>
            </div>
        )


    }
}


export default Adminhistoryehs