
import React, { useCallback, useEffect } from "react";
import axios from "axios";
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import TextField from '@material-ui/core/TextField';
import * as d3 from "d3";
import { Watch } from 'react-loader-spinner';
import { Modal } from "react-bootstrap";

import '../index.css';

function CumilativeData() {
    let date = new Date()
    let longYear1 = date.toLocaleString('en-us', { year: 'numeric' })
    let prevYear1 = longYear1 - 1;
    let prevtoPrevYear1 = longYear1 - 2;
    let [locations, setLocations] = React.useState([]);
    let [prevtoPrevYear, setprevtoPrevYear] = React.useState(prevtoPrevYear1);
    let [prevYear, setprevYear] = React.useState(prevYear1);
    let [longYear, setlongYear] = React.useState(longYear1);
    let [location, setLocation] = React.useState("All");
    let [month, setMonth] = React.useState("All");
    let [isOpen,setIsOpen] = React.useState(false);
    let [isLoading,setIsLoading] = React.useState(false);
    let [opacity,setIsOpacity] = React.useState(0);
    let [year, setYear] = React.useState(prevtoPrevYear);
    let [requirementKey, setrequirementKey] = React.useState(Math.random());
    const [rows,setrows]=React.useState([])
    React.useEffect(() => {
        
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboard/GetUserRoleLocation')
            .then(response => {
                let typesapi1 = response.data;
                console.log(typesapi1);
                setLocations(locations = typesapi1);
            }).catch(error => {
                console.log(error);
            });
    
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CumilativeData/GetCumilativeData', { params: { Year: prevtoPrevYear } })
            .then(response => {
               
                let rows=response.data
                setrows(rows)
                if (rows.length != 0) {
                    rows.push(d3.keys(rows[0])
                        .reduce(function (sumRow, sumCol) {
                            var isNum = !isNaN(rows[0][sumCol])
                            return (sumRow[sumCol] = (isNum ? d3.sum(rows, function (row) {
                                return Math.round(row[sumCol] * 100 / 100)

                            }) : "Total"), sumRow)
                        }, {}));
                }
                setrequirementKey(Math.random())
                console.log(response.data)

            })
        
        
    }, []);

    const openModal = () => {
        setIsOpen(true)
        setIsOpacity(1)
       
    };
    const closeModal = () => {

       
       
        setIsOpen((true), () => {
            window.setTimeout(() => {
                setIsOpen(false)
            }, 5000)
        });
    }
    const handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setLocation(value)
        
    }

    const handleChange1 = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setMonth(value)
       
    }

    const handleChange2 = (e) => {
        debugger;
        e.preventDefault();
        const value = e.target.value
        setYear(value)
       
    }
    const handleSubmit = (e) => {
        debugger;
        
        openModal();
        if (location == "All" && month == "All") {

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CumilativeData/GetCumilativeData', { params: { Year: year } })
                .then(response => {
                    let rows=response.data
                    setrows(rows)
                    if (rows.length != 0) {
                        rows.push(d3.keys(rows[0])
                            .reduce(function (sumRow, sumCol) {
                                var isNum = !isNaN(rows[0][sumCol])
                                return (sumRow[sumCol] = (isNum ? d3.sum(rows, function (row) {
                                    return Math.round(row[sumCol] * 100 / 100)

                                }) : "Total"), sumRow)
                            }, {}));
                    }
                    setrequirementKey(Math.random())
                    console.log(response.data)
                    closeModal()
                })
        }
        else if (location == "All" && month != "All") {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CumilativeData/GetCumilativeData', { params: { Year: year } })
                .then(response => {
                    let rows = response.data.filter(x => x.Month == month)
                    setrows(rows)
                    if (rows.length != 0) {
                        rows.push(d3.keys(rows[0])
                            .reduce(function (sumRow, sumCol) {
                                var isNum = !isNaN(rows[0][sumCol])
                                return (sumRow[sumCol] = (isNum ? d3.sum(rows, function (row) {
                                    return Math.round(row[sumCol] * 100 / 100)

                                }) : "Total"), sumRow)
                            }, {}));
                    }
                    setrequirementKey(Math.random())

                    closeModal()
                })

        }
        else if (location != "All" && month == "All") {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CumilativeData/GetCumilativeData', { params: { Year: year } })
                .then(response => {
                    let rows = response.data.filter(x => x.LocationName == location)
                    setrows(rows)
                    if (rows.length != 0) {
                        rows.push(d3.keys(rows[0])
                            .reduce(function (sumRow, sumCol) {
                                var isNum = !isNaN(rows[0][sumCol])
                                return (sumRow[sumCol] = (isNum ? d3.sum(rows, function (row) {
                                    return Math.round(row[sumCol] * 100 / 100)

                                }) : "Total"), sumRow)
                            }, {}));
                    }
                    setrequirementKey(Math.random())

                    closeModal()
                })


        }

        else {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CumilativeData/GetCumilativeData', { params: { Year: year } })
                .then(response => {
                    debugger;
                    let rows = response.data.filter(x => x.Month == month && x.LocationName == location)
                    setrows(rows)
                    if (rows.length != 0) {
                        rows.push(d3.keys(rows[0])
                            .reduce(function (sumRow, sumCol) {
                                var isNum = !isNaN(rows[0][sumCol])
                                return (sumRow[sumCol] = (isNum ? d3.sum(rows, function (row) {
                                    return Math.round(row[sumCol] * 100 / 100)

                                }) : "Total"), sumRow)
                            }, {}));
                    }
                    setrequirementKey(Math.random())
                   
                    closeModal()
                })

        }

    }

   

    const renderRows = rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}>




                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{width:"150px"}}type="text" required id="standard-required" defaultValue={item.LocationName} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "110px" }} type="text" required id="standard-required" defaultValue={item.Month} />
                    </td >
                    

                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }}type="number" required id="standard-required" defaultValue={item.HSD} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }}type="number" required id="standard-required" defaultValue={item.LPG} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }}type="number" required id="standard-required" defaultValue={item.PNG} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.Diesel} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.Gasoline} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.NEnergy} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.REnergy} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.NEnergyConsumed} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }}type="number" required id="standard-required" defaultValue={item.Intensity} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.Scope1} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }}type="number" required id="standard-required" defaultValue={item.Scope2} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.SO2} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.NO2} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.Recycled} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.Discharge} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.Rainfall} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.Rainwater} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.GroundWater} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.SewageWater} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.QuantityGenerated} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.QuantityRecycled} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.Disposal} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.NewspaperConsumed} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.NewspaperRecycled} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.InkConsumed} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.InkRecycled} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.SolventConsumed} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.SolventRecycled} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.CTPConsumed} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.CTPRecycled} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.CO2Emission} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.EnvFine} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.SafFine} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.TotalExpense} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.TotalCost} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.WaterWithdrawal} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.TotalFatalities} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.AccidentEmp} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.AccidentCon} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.LTIFREmp} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.TRIFREmp} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.LTIFRCon} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.TRIFRCon} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.TrainingHoursEmp} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} style={{ width: "60px" }} type="number" required id="standard-required" defaultValue={item.TrainingHoursCon} />
                    </td>
                </tr>

            );
        });
    

    return (
        <>
        <div id="OperationUnit" style={{ width: '320px', float: 'left' }}>

                <select name="OperationUnit" class="form-control" onChange={handleChange} >

                        <option selected value="All">All Operation Units</option>
                    {locations.map(type => (

                        <option
                            key={type.locationName}
                            value={type.locationName}
                        >
                            {type.locationName}
                        </option>
                    ))}

                    </select></div>
                <div id="month" style={{ width: '280px', float: 'left', marginLeft: '50px' }}>

                    <select name="LocationId" required class="form-control" onChange={handleChange1} >
                        <option selected value="All">All Months</option>
                    
                    <option value="January" >January</option>
                    <option value="February" >February</option>
                    <option value="March" >March</option>
                    <option value="April" >April</option>
                    <option value="May" >May</option>
                    <option value="June" >June</option>
                    <option value="July" >July</option>
                    <option value="August" >August</option>
                    <option value="September" >September</option>
                    <option value="October" >October</option>
                    <option value="November" >November</option>
                    <option value="December" >December</option>
                    </select></div>

            <div id="Year" style={{ width: '280px', float: 'left', marginLeft: '50px'  }}>

                    <select name="Year" required class="form-control" onChange={handleChange2} >

                    <option selected value={prevtoPrevYear} >{prevtoPrevYear}</option>
                    <option value={prevYear} >{prevYear}</option>
                    <option value={longYear} >{longYear}</option>

                    </select></div>
            <button type="submit" style={{ float: 'right' }} className="btn btn-success" onClick={handleSubmit}>Get Data</button>   

            <br /> <br />
            <Form id="myform" className="font-link" >
                <br />
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Cumilative Data </h5>

               
                <br />

                <Table  key={requirementKey}hover style={{ "borderWidth": "1px", 'borderColor': "white", overflowX: 'scroll', display: 'block', overflowY: 'scroll',height:"500px" }}>
                    
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>   {/* 9BC2E6 */}

                            <th>SITE </th>
                            <th></th>
                            <th>Table 1</th>
                            <th>Table 1</th>
                            <th>Table 1</th>
                            <th>Table 1</th>
                            <th>Table 1</th>
                            <th>Table 2</th>
                            <th>Table 3</th>
                            <th>Table 4</th>
                            <th>Table 5</th>
                            <th>Table 6</th>
                            <th>Table 7</th>
                            <th>Table 8</th>
                            <th>Table 8</th>
                            <th>Table 9</th>
                            <th>Table 10</th>
                            <th>Table 11</th>
                            <th>Table 11</th>
                            <th>Table 11</th>
                            <th>Table 11</th>
                            <th>Table 12</th>
                            <th>Table 12</th>
                            <th>Table 12</th>
                            <th>Table 13</th>
                            <th>Table 13</th>
                           
                            <th>Table 13</th>
                            <th>Table 13</th>
                            
                            <th>Table 13</th>
                            <th>Table 13</th>
                            
                            <th>Table 13</th>
                            <th>Table 13</th>
                            
                            <th>Table 15</th>
                            <th>Table 16</th>
                            <th>Table 17</th>
                            <th>Table 18</th>
                            <th>Table 19</th>
                            <th>Table 20</th>
                            <th>Table 21</th>
                            <th>Table 22</th>
                            <th>Table 22</th>
                            <th>Table 23</th>
                            <th>Table 23</th>
                            <th>Table 23</th>
                            <th>Table 23</th>
                            <th>Table 24</th>
                            <th>Table 24</th>



                        </tr>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>   {/* 9BC2E6 */}

                            <th>Site Name</th>
                            <th>Month</th>
                            <th>HSD</th>
                            <th>LPG</th>
                            <th>PNG</th>
                            <th>Diesel</th>
                            <th>Gasoline</th>
                            <th>Non Renewable Energy Purchased</th>
                            <th>Renewable Energy Purchased</th>
                            <th>Non Renewable Energy Consumption</th>
                            <th>Energy Intensity</th>
                            <th>Direct Ghg Emission(Scope1)</th>
                            <th>Direct Ghg Emission(Scope2)</th>
                            <th>Sox Emission</th>
                            <th>Nox Emission</th>
                            <th>Water Recycled</th>
                            <th>Total Water Discharge</th>
                            <th>Rainfall Harvested</th>
                            <th>Rain Water Harvested</th>
                            <th>Ground Water Harvested</th>
                            <th>Sewage Water Harvested</th>
                            <th>Generated Waste</th>
                            <th>Recycled Waste</th>
                            <th>Reused Waste</th>
                            <th>Newspaper Consumed</th>
                            <th>Newspaper Recycled</th>
                            
                            <th>Ink Consumed</th>
                            <th>Ink Recycled</th>
                            
                            <th>Solvent Consumed</th>
                            <th>Solvent Recycled</th>
                            
                            <th>CTP Plates Consumed</th>
                            <th>CTP Plates Recycled</th>
                            
                            <th>Total Carbon Emission</th>
                            
                            <th>Legal Compliance Environment</th>
                            <th>Legal Compliance Safety</th>
                            <th>Total Expenditure</th>
                            <th>Total Cost Avoidance</th>
                            <th>Water Withdrawal</th>
                            <th>Work Related Fatalities</th>
                            <th>Occupational Illness Employees</th>
                            <th>Occupational Illness Contractors</th>
                            <th>Employees LTI FR</th>
                            <th>Employees TRI FR</th>
                            <th>Contractor LTI FR</th>
                            <th>Contractor TRI FR</th>
                            <th>Employees Training Hours</th>
                            <th>Contractor Training Hours</th>

                           

                        </tr>
                    </thead>
                    <tbody>

                        {renderRows}
                        </tbody>
                      
                </Table>
            

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>
           
        </>
        )


}

export default CumilativeData