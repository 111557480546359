import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LineChart from "../components/LineChart/LineChart";
import SimpleCard from "../components/Card";
import computeWasteGeneratedArray from '../utils/WasteGenerated';
import computeWasteRecycledArray from '../utils/WasteRecycle';
import computeWasteReuseArray from '../utils/WasteReuse';
import React from 'react'
const useStyles = makeStyles({
    root : {
        boxShadow: " 10px 10px 10px #888888!important",
        padding: "1%",
    }
})


export default function Waste(props) {
    const classes = useStyles();

    let wasteOne = props.waste1;
    let wasteTwo = props.waste2;

    // console.log(wasteOne);
    let actualWasteGeneratedOne = computeWasteGeneratedArray(wasteOne);
    let actualWasteGeneratedTwo = computeWasteGeneratedArray(wasteTwo);

    let actualWasteRecycledOne = computeWasteRecycledArray(wasteOne);
    let actualWasteRecycledTwo = computeWasteRecycledArray(wasteTwo);

    let actualWasteReusedOne = computeWasteReuseArray(wasteOne)
    let actualWasteReusedTwo = computeWasteReuseArray(wasteTwo)

    return (
        <div className={classes.root}>
            <SimpleCard title="Waste" unit={"All Value in Metric Tonne"}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <LineChart data1={actualWasteGeneratedOne} data2={actualWasteGeneratedTwo} title="Generated" year1={props.year1} year2={props.year2} unit="MT"/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <LineChart data1={actualWasteRecycledOne} data2={actualWasteRecycledTwo} title="Recycled" year1={props.year1} year2={props.year2} unit="MT"/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <LineChart data1={actualWasteReusedOne} data2={actualWasteReusedTwo} title="Reused" year1={props.year1} year2={props.year2} unit="MT"/>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}