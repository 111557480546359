import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';


class Nearmiss extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            type1: 'Major',
            type2: 'Serious',
            type3: 'Minor',
            type4: 'Frequent',
            type5: 'Occasional',
            type6: 'Rare',
            rows: [{ id: 0 }],
            rows1: [],
          
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            admincode: localStorage.getItem('admincode'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            prevMonth: prevMonth,
            prevYear:prevYear

        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
    }



    handleChange = e => {
        

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        var value = e.target.value;
       


        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var utype = parseInt(this.state.utype)
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var admincode = parseInt(this.state.admincode)
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            
            ...rows[0],
            [name]: value,
            "UserId": utype,
            "LocationId": location,
           
            "IncidentYear": year,
            "IncidentMonth": prevMonth
        };
        rows[0] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],

            "FormId": 3,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });
    };



    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {

        event.preventDefault();
        
        const { rows } = this.state;
        const { rows1 } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/NearMissIncident/GetIncidentDetailsByMonth', { params: { IncidentMonth: prevMonth, IncidentYear: year, UserId: id } })
            .then(response => {
                this.setState({
                    rows2: response.data
                })

            })
        
        var rows2 = this.state.rows2
        if (rows2.length == 0) {


                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    var newbody = JSON.parse(Options.body);
                    const Options1 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows1)
                    };
                    let checkbody = JSON.parse(Options1.body);
                    axios.all([
                        await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/NearMissIncident/SaveIncidentDetails', newbody),
                        axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)
                    ]).then(axios.spread((response1, response2) => {
                        if (response1.data.Status === 'Success') {
                            alert("Data Save Successfully");
                            window.location.reload();
                            console.log(response1.data.Status);

                        }

                        else {
                            alert('Data Saved Successfully');
                            window.location.reload();
                        }
                    }));
                }
                else {

            alert("Data Already Submitted")
            window.location.reload();
                }

        



    }

    
    handleClick1 = () => (event) => {

        this.props.history.push("/nearmissview");  
    };




    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label class="no-space-break">

                        Date Of Reporting:&nbsp;{this.state.newdate}  &nbsp;
                        {this.state.longMonth},
                   {this.state.longYear}

                    </label>

                    <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                    <br />
                    <br />

                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px'}}>Incident/Near Miss Report </h5>

                <div className="col-1">
                    <br />

                
                    <div >
                        <label class="no-space-break">Date of incident: </label> <br />
                        <TextField selected type="date" name="DateOfIncident" required id="standard-required" onChange={this.handleChange}/>
                    </div>
                    <br />
                    <div>
                        <label class="no-space-break">Time of Incident: </label>
                        <br />
                        <TextField
                            name="TimeOfIncident"

                            type="time"
                            defaultValue="hh:mm"
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            
                        />
                    </div>
                    <br />
                   
                    <div>
                        <label class="no-space-break">Severity potential of incident: </label> <br />
                        <FormControl>

                            <select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                onChange={this.handleChange}
                                name="IncidentSeverity"

                            >
                                <option selected disabled value='0'>--Select--</option>
                                <option value={this.state.type1} >Major</option>
                                <option value={this.state.type2} >Serious</option>
                                <option value={this.state.type3} >Minor</option>

                            </select>

                        </FormControl>
                    </div>
                    
                </div>
                <div className="col-2">
                    <br />

                    <div >
                        <label class="no-space-break">Estimated loss due to incidents: </label> <br />
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="EstimatedLoss" required id="standard-required" onChange={this.handleChange}/>
                    </div>
                    <br />

                    <div >
                        <label class="no-space-break">Exact location of incident: </label> <br />
                        <TextField type="text" name="IncidentLocation" required id="standard-required" onChange={this.handleChange} />
                    </div>

                    <br />
                    <div>
                        <label class="no-space-break">Probability reoccurance rate: </label> <br />
                        <FormControl>

                            <select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                onChange={this.handleChange}
                                name="ProbabilityRate"

                            >
                                <option selected disabled value='0'>--Select--</option>
                                <option value={this.state.type4} >Frequent</option>
                                <option value={this.state.type5} >Occasional</option>
                                <option value={this.state.type6} >Rare</option>

                            </select>

                        </FormControl>
                    </div>
                   
                </div>
                <div>
                    <br />
                    <br />
                   
                    <label class="no-space-break">Brief description of incident: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        onChange={this.handleChange}
                        name="EventBreif"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br />
                    <label class="no-space-break">Root causes of incident: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="RootCause"
                        onChange={this.handleChange}

                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br />
                    <label class="no-space-break">Corrective and preventive action: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="CorrectiveAction"
                        onChange={this.handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    


                </div>






                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Nearmiss