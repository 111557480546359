import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editauditoffice extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {

            count: 0,

            type1: 'Yes',
            type2: 'No',
            type3: 'Open',
            type4: 'Closed',

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: ''


        }
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AuditOffice/GetAuditOfficeById', { params: { OfficeId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0]


            });

        });


    }









    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name


        const value = e.target.value


        var rows = this.state.rows;

        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };



    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/AuditOffice/UpdateAuditOfficeList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/auditofficeview')
            }).catch(err => console.log(err));


    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Edit Audit Checklist:Electrical </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="CheckPoints">
                                    <label class="no-space-break">Check Points </label>
                                    <textarea disabled rows="5" name="checkPoints" type="text" class="form-control" value={this.state.rows.checkPoints} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Requirements">
                                    <label class="no-space-break">Requirements</label>
                                    <textarea disabled rows="5" name="requirements" type="text" class="form-control" value={this.state.rows.requirements} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>




                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Action">
                                    <label class="no-space-break">Action </label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.action} name="action">

                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="no-space-break">Observations</label>
                                <div class="input-group">


                                    <input required name="observations" class="form-control" type="text" value={this.state.rows.observations} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>





                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="ActionPlan">
                                    <label class="no-space-break">Action Plan</label>
                                    <input name="plan" type="text" class="form-control" value={this.state.rows.plan} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Responsibility">
                                    <label class="no-space-break">Responsibility</label>
                                    <input name="responsibility" type="text" class="form-control" value={this.state.rows.responsibility} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Timelines">
                                    <label class="no-space-break">Timelines</label>
                                    <input name="timelines" type="text" class="form-control" value={this.state.rows.timelines} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Status">
                                    <label class="no-space-break">Status </label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.status} name="status">

                                        <option value={this.state.type3} >Open</option>
                                        <option value={this.state.type4} >Closed</option>



                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editauditoffice