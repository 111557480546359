import React, { Component, useState, useRef } from 'react'
import {Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Modal} from "react-bootstrap";
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink  } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import ReactDOM from 'react-dom';
import Tooltip from "react-simple-tooltip"

 
class Deconsumption extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        
       
        let date= new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth()-1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;
     this.state= {
         count: 0,
         counter: 1,
         maxlength:0,
         types: [],
         months: [
             'January',
             'February',
             'March',
             'April',
             'May',
             'June',
             'July',
             'August',
             'September',
             'October',
             'November',
             'December',
         ],
         last3Months:[],
         validationError: "",
         rows: [{}],
       row1:{ type1:'Hsd',unit1:'Litres',  density1:'', calorific1:'',value1:''},
       row2:{ type2:'Lpg',   unit2:'Litres',density2:'313', calorific2:'313',value2:''},
         row3: { type3: 'Png', unit3: 'Scm', density3: '5050', calorific3: '5050', value3: '' },
         row4: {
             "FuelType": "hsg",
             "Units": "kgs",
             "DensityNtp": 16,
             "CalorificFuelVal": 17,
             "ConsumptionYear": null,
             "Quantity": 25
         },
         login: [],
        
         ucode: localStorage.getItem('username'),
         utype: localStorage.getItem('utype'),
         location: localStorage.getItem('location'),
         longMonth: longMonth,
         longYear: longYear,
         prevMonth: prevMonth,
         prevYear: prevYear,
        isOpen:false
        
        
        }   
      
  
}


    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        
        var directenergy = JSON.parse(localStorage.getItem("directenergy"));
        var rows = this.state.rows;
        
        if (directenergy != null) {
            rows=directenergy
           
            this.setState({
                rows
            })
        }
      
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/DirectEnergyConsumption/GetDirectType')
            .then(response => {
              
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi,
                  maxlength:typesapi.length

                });
            })
            //  .then(data => {
            // let typesapi = Object.keys(data.type);
            //console.log(typesapi);



            //    })
            .catch(error => {
                console.log(error);

            });

    } 
    
    getmonths() {
        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        var today = new Date();
        var last3Months = []

        for (var i = 0; i < 3; i++) {
            last3Months.push(monthNames[(today.getMonth() - i)]);
        }

        

    }
    openModal = (e) => {

       
        this.setState({ isOpen: true }, () => {
            window.setTimeout(() => {
                this.setState({ isOpen: false })
            },2000)
        });

    }
    closeModal = () => this.setState({ isOpen: false });

    isDisabled() {
        
        var counter = this.state.counter;
        var maxlength=this.state.maxlength;
        if (counter == maxlength) {

            return true;

        }
        else {
            return false;
        }

    }
handleChange = (idx) => (e) => {
   
 e.preventDefault();
  // const { name, value } = e.target;
   
    const name = e.target.name;
    var value = 0;
    var year=0
  //let id=name+(idx);
    if (name == "DensityNtp" || name == "CalorificFuelVal" || name == "Quantity" )
    {
        
         value = parseInt(e.target.value);
        
    }
     else {
        value = e.target.value;
       
    }
   
    const rows = [...this.state.rows];
    let utype = this.state.utype;
    let location = this.state.location;
    
    //var longYear = this.state.longYear;
    //var prevMonth = this.state.prevMonth;
    //var prevYear = this.state.prevYear;
    //if (prevMonth == "December") {
    //    year = prevYear
    //}
    //else {
    //    year=longYear
    //}

    var month = localStorage.getItem("month1");
    var year = localStorage.getItem("year1")
    var Reference = localStorage.getItem('globalvar')
  let row = {
    ...rows[idx],
  
    [name]: value,
     
      "ConsumptionYear": year,
      "Month": month,
      "LocationId": parseInt(location),
      "UserId": parseInt(utype),
      "ReferenceId":Reference
  };
    rows[idx] = row;
    this.state.rows=rows
  

    this.handletotal(idx);
    this.handletotal1(idx);
    this.handletotal2(idx);
    
    };

                                                

    handleChange1 = (idx) => (e) => {

        e.preventDefault();
        var index = this.getIndex(e.target.value);

        // const { name, value } = e.target;
        const name = e.target.name;
        var value = 0;
        var year = 0;
        var value1 = "";
        //let id=name+(idx);





        value = e.target.value;

        
        const login = [...this.state.login]
        let utype = this.state.utype;
        let location = this.state.location;
        let admincode = parseInt(localStorage.getItem('admincode'))
        let row1 = {

            "FormId": parseInt(value),
            "FormSlug": "DirectConsumption",
            "LocationId": parseInt(location),
            "UserId": parseInt(utype),
            "SubmittedTo": admincode



        };
        login[idx] = row1;
        this.setState({
            login
        });

        const types = [...this.state.types];
        var type = types[index].unit;
        var cal = types[index].calorificFuelValue;
        var dense = types[index].densityNtp;

        //var longYear = this.state.longYear;
        //var prevMonth = this.state.prevMonth;
        //var prevYear = this.state.prevYear;
        //if (prevMonth == "December") {
        //    year = prevYear
        //}
        //else {
        //    year = longYear
        //}

       
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        console.log(Reference)
        const rows = [...this.state.rows];
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "unit": type,
            "CalorificFuelVal": cal,
            "DensityNtp": dense,
            "ConsumptionYear": year,
            "Month": month,
            "LocationId": parseInt(location),
            "UserId": parseInt(utype),
            "ReferenceId":Reference
        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };



    getIndex(value) {
      
        let arr = this.state.types;
        let prop = 'dmasterId'
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }

    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handletotal(idx){
    
        const rows = [...this.state.rows];
        rows[idx].fuelConsumption = rows[idx].Quantityltr * rows[idx].DensityNtp

        this.setState({
            rows
        });
    };

    handletotal1(idx) {

        const rows = [...this.state.rows];
        rows[idx].heatValue = (rows[idx].fuelConsumption * rows[idx].CalorificFuelVal)/1000000

        this.setState({
            rows
        });
    };

    handletotal2(idx) {
     
        const rows = [...this.state.rows];
        rows[idx].quantity= rows[idx].heatValue * 277.78
        
        this.setState({
            rows
        });
    };
    isDisabled1() {
       
        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }

                         handleAddRow =() => (event) => {
                             event.preventDefault();
                             let count = this.state.count 
                             let counter= this.state.counter
                             count = count + 1;
                             counter = counter + 1;
                          this.setState({count})
                             this.setState({ counter })
                          // this.setState((prevState) => ({ count: prevState.count + '1'}));
                       
                          const item = {
                            id:count
                            // type: "",
                            // unit: "",
                            // consumed: "",
                            // recycle:""
                            
                          };
                         
                          this.setState({
                            rows: [...this.state.rows, item]                       
                            
                          });
                        };
                        // handleRemoveRow = (event) => {
                        //   event.preventDefault();
                        //   this.setState({
                        //     rows: this.state.rows.slice(0, -1)
                        //   });
                        // };
                        handleRemoveSpecificRow = (idx) => () => {
                        
                          const rows = [...this.state.rows]
                          rows.splice(idx, 1)
                            this.setState({ rows })
                            let counter = this.state.counter;
                            counter = counter - 1;
                            this.setState({
                            counter
                            })
                        }
                     
          

    handleSubmit = event =>  {
        debugger;
        event.preventDefault();
        const { rows } = this.state;
        
        //var prevMonth = this.state.prevMonth;
        //var year = 0;
        //var longYear=this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year=longYear
        //}
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');
        var id = localStorage.getItem('utype')
        //axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectEnergyConsumption/GetDirectEnergyMonthWise', { params: { Month: month, ConsumptionYear: year, UserId: id } })
        //    .then(response => {
              
              /*  if (response.data.length == 0) {*/


                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    localStorage.setItem('directenergy', Options.body);
                    let newbody = JSON.parse(Options.body);
                    console.log(newbody)
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SaveDirectEnergyConsumptiontemp', newbody).then(response => { 
                      
                        if (response.data == 1) {
                            this.openModal()
                        }

                        else {

                            alert("Data Not Saved.Kindly Save Again")
                        }
                 
                    })
                  
                  /*  }*/
                //else {

                //    alert("Data Already Submitted")
                //}
                
            /*})*/
        
       
      /*  let newbody = JSON.parse(Options.body);
        const Options1 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(login)
        };
        let checkbody = JSON.parse(Options1.body);
        axios.all([
            axios.post('http://localhost:23013/api/DirectEnergyConsumption/SaveDirectEnergyConsumption', newbody),

            axios.post('http://localhost:23013/api/UserSubmission/SaveUserSubmission', checkbody)

        ]).then(axios.spread((response1, response2) => {

            if (response1.data.Status === 'Success') {
                alert("Data Save Successfully");
                window.location.reload();
                console.log(response1.data.Status);

            }

            else {
                alert('Data Saved Successfully');
                window.location.reload();
            }
        })); */
        
             //  axios.post('http://localhost:23013/api/DirectEnergyConsumption/', {
            //FuelType: this.state.rows[0].FuelType, Units: this.state.rows[0].Units,
           // DensityNtp: this.state.rows[0].DensityNtp, CalorificFuelVal: this.state.row4.CalorificFuelVal,
           // ConsumptionYear: this.state.ConsumptionYear, Quantity: this.state.row4.Quantity
        //})
          //  .then(json => {
             //   if (json.data.Status === 'Success') {
               //     console.log(json.data.Status);
                 //   alert("Data Save Successfully");
                   // this.props.history.push('/deconsumption')
               // }
                //else {
                  //  alert('Data not Saved');
                    //
                    //this.props.history.push('/deconsumption')
                //}
            //})  

             }    

    


             renderRows(){
              return this.state.rows.map((item, idx) => {
                return(
                
                <tr id="addr0" key={item.id}>
               
                
                
                <td>  
                <FormControl>
                 <Select 
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    defaultValue="Select"
                                    name="dmasterId"
                                    onChange={this.handleChange1(idx)}
                                    value={this.state.rows[idx].dmasterId}
                >                 
                                    {this.state.types.map(type => (
                                        <MenuItem
                                            key={type.fuelType}
                                            value={type.dmasterId}
                                        >
                                            {type.fuelType}
                                        </MenuItem>
                                    ))}
               
                                </Select>
                                
               
              </FormControl> </td>
               
              


               <td>
                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="DensityNtp" value={this.state.rows[idx].DensityNtp}/>
               </td>
               <td>
                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="CalorificFuelVal" value={this.state.rows[idx].CalorificFuelVal}/>
               </td>
               
                        <td>
                            <TextField InputProps={{ inputProps: { min: 0, max: 9999999, step: "any" } }} type="number" required id="standard-required" name="Quantityltr" onChange={this.handleChange(idx)} value={this.state.rows[idx].Quantityltr}/>
                        </td>    
                        <td>
                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="FuelConsumption" value={this.state.rows[idx].fuelConsumption} />
                        </td>    
                        <td>
                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="HeatValue"  value={this.state.rows[idx].heatValue}  />
                        </td>  
                        <td>
                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="Quantity" value={this.state.rows[idx].quantity} />
                        </td>  
               <td>
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  onClick={this.handleRemoveSpecificRow(idx)}>
        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>                          
                                </button>
                                
                              </td>
            </tr>
               
            );
          });
            }

             render() {
                 const text = "Total amount of various non-renewable fuels used at the facility\nfor generation of power (eg. HSD, PNG, CNG, LDO etc.)."
              return  (
                  <Form id="myform" className="font-link" onSubmit={this.handleSubmit}>
                      <br />
                      <div className="App">
                          <div className="wordalign">
                          {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                          <label>
                              {this.state.longMonth}   &nbsp;
                   {this.state.longYear}
                                  &nbsp;&nbsp;
                                  <Tooltip
                                      arrow={15}
                                      background="#ffffff"
                                      border="black"
                                      color="black"
                                      content={text.split("\n").map((i, key) => {

                                          return <div key={key}>{i}</div>;


                                      })}
                                      fadeDuration={0}
                                      fadeEasing="linear"
                                      fixed={false}
                                      fontFamily="Bw Glenn Sans Medium"
                                      fontSize="12px"
                                      offset={-20}
                                      padding={15}

                                      placement="right"
                                      radius={50}
                                      zIndex={1}




                                  >

                                      <span className="btn btn-outline-danger btn-sm">

                                          info
                               {/*<svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                                      </span>
                                  </Tooltip>
                          </label>

                             
                          {/*     <Select 
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  defaultValue="Select"
                  name="month"
                                    
                          >

                              {this.state.last3Months.map(month => (
                                  <MenuItem
                                      key={month}
                                      value={month}
                                  >
                                      { month}
                                  </MenuItem>
                              ))}
               
                                </Select>
                                
                          <Select>
                              <option value="2020">2020</option>
                              <option value="2021">2021</option>

                          </Select>
                  */}
                      
                     
                              <button onClick={this.handleAddRow()} style={{ float: 'right' }} disabled={this.isDisabled()} className="btn btn-info btn-md">+ADD</button>
                      {/*  <h5>DIRECT ENERGY CONSUMPTION </h5> */}
                      </div>
                      </div>
                      <Modal style={{ backgroundColor: 'white', width: '500px', height: '200px', borderColor: '#ffffff00' }}

                          show={this.state.isOpen} onHide={this.closeModal}>
                       
                          <Modal.Header style={{ backgroundColor: '#001946',color:'white'}} >
                                  <Modal.Title>Message</Modal.Title>
                              </Modal.Header>
                          <Modal.Body>
                              Data Saved Successfully

                          </Modal.Body>
                      </Modal>
                      <br />
                      <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                          <thead>
                              <tr style={{ backgroundColor: '#001946', color: 'white' }}>   {/* 9BC2E6 */}
                               
                    <th>FUEL TYPE</th>
                  
                    <th>DENSITY AT N.T.P (Kg/Lt)</th>
                    <th>CALORIFIC VALUE OF FUEL (Kcal/Mt)</th>
                    <th>QTY USED (IN LT)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                    <th>FUEL CONSUMPTION IN KGS</th>
                    <th>HEAT VALUE<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                    <th>QTY CONSUMED(MwH)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                  
                  </tr>
                </thead>
                <tbody>
              
               
                  {this.renderRows()}
                   
               </tbody>
               </Table>
                     
                      <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit} disabled={this.isDisabled1()}>SAVE</button>   
     
             {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
               
             
               </Form>
                   
              )
            }
}

export default Deconsumption