import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import '../index.css';


class Airemissions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            count: 0,
            rows: [{ id: 0 }],
            row1: { spm: '', nox: '', so2: '', h2s: '' },
        }

    }


    handleChange = (idx) => (e) => {
        debugger;
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        //let id=name+(idx);
        const value = e.target.value;
        const rows = [...this.state.rows];

        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,


        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };

    handleAddRow = () => (event) => {
        debugger;
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        debugger;
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }



    handleSubmit = event => {
        alert(`${this.state.unit1}`)
        const data = new FormData(event.target);
        fetch('weatherforecast', {
            method: 'POST',
            body: data,
        });



    }




    renderRows() {
        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}>

                    <td>{idx}</td>

                    <td>
                        <TextField  type="number" required id="standard-required" name="spm" defaultValue={this.state.row1.spm} onChange={this.handleChange(idx)} />
                    </td>

                    <td>
                        <TextField  type="number" required id="standard-required" name="nox" defaultValue={this.state.row1.nox} onChange={this.handleChange(idx)} />

                    </td>
                    <td>
                        <TextField  type="number" required id="standard-required" name="so2" defaultValue={this.state.row1.so2} onChange={this.handleChange(idx)} />
                    </td>
                    <td>
                        <TextField  type="number" required id="standard-required" name="h2s" defaultValue={this.state.row1.h2s} onChange={this.handleChange(idx)} />
                    </td>
                    <td>
                        <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button>

                    </td>
                </tr>

            );
        });
    }

    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>
                <button onClick={this.handleAddRow()} style={{ float: 'right' }} className="btn btn-info btn-md">+Add</button>
                <h5>NOx, SOx AND OTHER SIGNIFICANT AIR EMISSIONS(Tonnes)</h5>
                <div class="shadow1">

                    <Table hover style={{ "borderWidth": "5px", 'borderColor': "#aaaaaa", 'borderStyle': 'ridge' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#9BC2E6' }}>
                                <th>#</th>
                                <th>SPM</th>
                                <th>NOX</th>
                                <th>SO2</th>
                                <th>H2S</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                    <td>1</td>
                    <td>
                        <select value={this.state.type1} onChange={this.handleTypeChange1}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit1} onChange={this.handleUnitChange1}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density1} onChange={this.handleDensityChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific1} onChange={this.handleCalorificChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value1} onChange={this.handleValueChange1 } />  
                   </td>    
        
                </tr>
                <tr>
                    <td>2</td>
                    <td>
                        <select value={this.state.type2} onChange={this.handleTypeChange2}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit2} onChange={this.handleUnitChange2}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density2} onChange={this.handleDensityChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific2} onChange={this.handleCalorificChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value2} onChange={this.handleValueChange2} />  
                   </td>  
        
                </tr>
                <tr>
                    <td>3</td>
                    <td>
                        <select value={this.state.type3} onChange={this.handleTypeChange3}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit3} onChange={this.handleUnitChange3}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density3} onChange={this.handleDensityChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific3} onChange={this.handleCalorificChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value3} onChange={this.handleValueChange3} />  
                   </td>  
                  </tr> */}

                            {this.renderRows()}

                        </tbody>
                    </Table>
                </div >
                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>Save</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Airemissions