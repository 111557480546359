import * as React from 'react';
import { quarters } from '../Data/Quarter';


export default function computeDirectEmissionArray(temp) {
    let quarter1 = 0;
    let quarter2 = 0;
    let quarter3 = 0;
    let quarter4 = 0;

    temp.map(instance => {

        if (quarters.q1.includes(instance.month))
        {
            quarter1 += Math.round(instance.total);
        }
        else if(quarters.q2.includes(instance.month))
        {
            quarter2 += Math.round(instance.total);
        }
        else if(quarters.q3.includes(instance.month))
        {
            quarter3 += Math.round(instance.total);
        }
        else
        {
            quarter4 += Math.round(instance.total);
        }

    })

    let actualDirectEmission = [];
    actualDirectEmission.push(quarter1);
    actualDirectEmission.push(quarter2);
    actualDirectEmission.push(quarter3);
    actualDirectEmission.push(quarter4);

    // console.log(actualDirectEmission);
    return actualDirectEmission;
}