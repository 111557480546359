
import MDCard from "../components/MDCard";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LineChart from "../components/LineChart/LineChart";
import SimpleCard from "../components/Card";
import computeDirectEmissionArray from "../utils/DirectEmission";
import computeIndirectEmissionArray from "../utils/IndirectEmission";
import computeTransportationArray from "../utils/Transportation";
import React from 'react'
const useStyles = makeStyles({
    root : {
        boxShadow: " 10px 10px 10px #888888!important",
        padding: "1%",
    }
})

function formattingDate(year , month , day) {

    let firstDayYear = new Date(year , month , day);
    firstDayYear.setDate(firstDayYear.getDate() + 1);
    firstDayYear = firstDayYear.toISOString();
    firstDayYear = firstDayYear.replace(/T.*/ , '').split("-").reverse().join("-");
    firstDayYear = firstDayYear.split("-").reverse().join("-");

    return firstDayYear;

}







export default function AirEmission(props) {

    const classes = useStyles();

    const year1 = props.year1;
    const year2 = props.year2;

    let directEmissionOne = props.directEmission1;
    let directEmissionTwo = props.directEmission2;
    let indirectEmissionOne = props.indirectEmission1;
    let indirectEmissionTwo = props.indirectEmission2;
    let transportationOne = props.transportation1;
    let transportationTwo = props.transportation2;

    // console.log(indirectEmissionOne);
    // console.log(directEmissionTwo);

    let actualDirectEmissionOne = computeDirectEmissionArray(directEmissionOne);
    let actualDirectEmissionTwo = computeDirectEmissionArray(directEmissionTwo);
    let actualIndirectEmissionOne = computeIndirectEmissionArray(indirectEmissionOne);
    let actualIndirectEmissionTwo = computeIndirectEmissionArray(indirectEmissionTwo);
    let actualTransportationEmissionOne = computeTransportationArray(transportationOne);
    let actualTransportationEmissionTwo = computeTransportationArray(transportationTwo);
    // console.log(actualIndirectEmissionTwo);
   
   


    
    var firstDayYear1 = formattingDate(year1 , 0 , 1);
    // console.log(firstDayYear1);
    var lastDayYear1 = formattingDate(year1 , 11 , 31); 
    // console.log(lastDayYear1);
    var firstDayYear2 = formattingDate(year2 , 0 , 1);
    var lastDayYear2 = formattingDate(year2 , 11 , 31);
    let volume = "3"
    
    return (
       
        <div className={classes.root}>
        <SimpleCard title={`Air Emission`} unit="All value in m3">
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
                        <LineChart data1={actualDirectEmissionOne} data2={actualDirectEmissionTwo} title="Direct Emission" year1={props.year1} year2={props.year2} unit="TC02e"/>


        </Grid>
        <Grid item xs={12} lg={4} >
                        <LineChart data1={actualIndirectEmissionOne} data2={actualIndirectEmissionTwo} title="Indirect Emission" year1={props.year1} year2={props.year2} unit="TC02e"/>
        </Grid>
        <Grid item xs={12} lg={4}>
                        <LineChart data1={actualTransportationEmissionOne} data2={actualTransportationEmissionTwo} title={"Transportation Emission"} year1={props.year1} year2={props.year2} unit="TC02e"/>
        </Grid>
        </Grid>

        </SimpleCard>
        </div>
    )
}