import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";
import { Prev } from 'react-bootstrap/esm/PageItem';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
class MinutesOfMeeting extends Component {

    constructor(props) {
        super(props)

        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();


        this.state = {
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,

            types: [],
            unit: '',
            validationError: "",
            count: 0,
            
            rows: [{ id: 0 }],
            rows1: [{ id: 0 }],
            rows2: [{ id: 0 }],
            rows3: [{ id: 0 }],
            rows4: [],
            rows5: [],
            rows6: [],
            rows7: [],
            rows8: [],
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            admincode: localStorage.getItem('admincode')
            
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
    }



    handleChange = e => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        const value = e.target.value;

        const rows = [...this.state.rows];
        const rows4 = [...this.state.rows4];
        var check = this.state.check;

        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
       
        
        
        let row = {

            ...rows[0],
            [name]: value,
            "UserId": utype,
            "LocationId": location,

            "Year": year,
            "Month": prevMonth
        };
        rows[0] = row;
        this.setState({
            rows
        });

        let row1 = {
            ...rows4[0],

            "FormId": 14,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows4[0] = row1;
        this.setState({
            rows4
        });

    };



    handleChange1 = (idx) => (e) => {
                e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        //let id=name+(idx);
        var value = parseInt(e.target.value);

        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows1[idx],
            id: idx,
            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows1[idx] = row;
        this.setState({
            rows1
        });
    };

    handleChange2 = (idx) => (e) => {
       
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        //let id=name+(idx);

        const value = e.target.value;


        var rows2 = this.state.rows2;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows2[idx],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows2[idx] = row;
        this.setState({
            rows2
        });
    };


    handleChange3 = (idx) => (e) => {
       
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        //let id=name+(idx);

        const value = e.target.value;


        var rows3 = this.state.rows3;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows3[idx],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows3[idx] = row;
        this.setState({
            rows3
        });
    };




    handleAddRow = () => (event) => {
        
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows2: [...this.state.rows2, item]

        });
    };

    handleAddRow1 = () => (event) => {
       
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows3: [...this.state.rows3, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows2 = [...this.state.rows2]
        rows2.splice(idx, 1)
        this.setState({ rows2 })
    }

    handleRemoveSpecificRow1 = (idx) => () => {
        
        const rows3 = [...this.state.rows3]
        rows3.splice(idx, 1)
        this.setState({ rows3 })
    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {
        
        event.preventDefault();

        const { rows } = this.state;
        const { rows1 } = this.state;
        const { rows2 } = this.state;
        const { rows3 } = this.state;
        const { rows4 } = this.state;


        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
       await axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesMeeting/GetMinutesMeetingByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesStatusOld/GetMinutesStatusByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingOldPoints/GetMeetingOldPointsByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingNewPoints/GetMeetingNewPointsByMonth', { params: { Month: prevMonth, Year: year, UserId: id } })


        ]).then(axios.spread((response1, response2, response3, response4) => {
            this.setState({
                rows5: response1.data,
                rows6: response2.data,
                rows7: response3.data,
                rows8: response4.data,
               
            })

        }))
        var rows5 = this.state.rows5
        var rows6 = this.state.rows6
        var rows7 = this.state.rows7
        var rows8 = this.state.rows8
       
        if (rows5.length == 0 || rows6.length == 0 || rows7.length == 0 || rows8.length == 0 ) {


            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            let newbody = JSON.parse(Options.body);

            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let newbody1 = JSON.parse(Options1.body);

            const Options2 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows2)
            };
            let newbody2 = JSON.parse(Options2.body);
            const Options3 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows3)
            };
            let newbody3 = JSON.parse(Options3.body);

            const Options4 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows4)
            };
            let checkbody = JSON.parse(Options4.body);
            //localStorage.setItem('rawmaterial', Options.body);
            //alert("Data Saved Successfully");
            /*  const Options1 = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(login)
              };
              let checkbody = JSON.parse(Options1.body);*/
            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesMeeting/SaveMinutesMeetingList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesStatusOld/SaveMinutesStatusList', newbody1),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingOldPoints/SaveMeetingOldPointsList', newbody2),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingNewPoints/SaveMeetingNewPointsList', newbody3),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

            ]).then(axios.spread((response1, response2) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }
       

    }


    toggle = (index) => {
        let collapse = "isOpen" + index;

        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }

    handleClick1 = () => (event) => {

        this.props.history.push("/minutesofmeetingview");
    };

    handletotal = (idx) => (e) => {
        debugger

       
        e.preventDefault();

        const rows1 = [...this.state.rows1];
        rows1[idx].Status = parseInt(parseInt(rows1[idx].PointsClosed) / parseInt(rows1[idx].PointsRaised) * 100)


        this.setState({
            rows1
        });
    };



    render() {

        return (


            <div >
                <br />
                <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                <br />
                <label class="no-space-break">

                    Date Of Reporting:&nbsp;{this.state.newdate}  &nbsp;
                        {this.state.longMonth},
                   {this.state.longYear}


                <h5 style={{ backgroundColor: '#001946', width: '1100px', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Minutes Of Meeting </h5>

                </label>
                <br />
                    <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                            
                            

                        </div>

                        <div className="col-4">
                            <br />


                            <div >
                                <label class="no-space-break">Date: </label> <br />
                                <TextField selected type="date" name="Date" required id="standard-required" onChange={this.handleChange} />
                            </div>
                            <br />
                            <div>
                                <label class="no-space-break">Time: </label>
                                <br />
                            <TextField
                               
                                    name="Time"

                                    type="time"
                                    defaultValue="hh:mm"
                                    onChange={this.handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </div>

                        </div>

                        <div className="col-2">
                            <br />



                            <div >
                                <label class="no-space-break">Venue: </label> <br />
                                <TextField type="text" name="Venue" required id="standard-required" onChange={this.handleChange} />
                            </div>

                            <br />

                            <div >
                                <label class="no-space-break">Agenda: </label> <br />
                                <TextField type="text" name="Agenda" required id="standard-required" onChange={this.handleChange} />
                            </div>

                    </div>
                    <br />
                
                    
                        <label class="no-space-break">Members Present: </label>
                        <br />
                        <TextField
                            id="outlined-full-width"
                            label="Description"
                            style={{ margin: 8 }}
                            onChange={this.handleChange}
                            name="MembersPresent"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </Form>
                <br />

                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen2
                    })}
                    onClick={() => this.toggle(2)}
                >
                    <span className="app__toggle-text">STATUS OF OLD POINTS </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen2}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen2 ? "app__collapse--active" : "")
                    }
                >





                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>


                                <th>TOTAL POINTS RAISED</th>
                                <th>POINTS CLOSED</th>
                                <th>STATUS %</th>
                              

                            </tr>

                        </thead>
                        <tbody>
                            {this.state.rows1.map((item, idx) => (
                                <tr id="addr0" key={item.id}>


                                 
                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} required id="standard-required" name="PointsRaised" onChange={this.handleChange1(idx)} onBlur={this.handletotal(idx)} />
                                    </td>
                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} required id="standard-required" name="PointsClosed" onChange={this.handleChange1(idx)} onBlur={this.handletotal(idx)}/>
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="Status" value={this.state.rows1[idx].Status}  />
                                    </td>
                                    {/*<td>
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={this.handleRemoveSpecificRow(idx)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>

                                    </td>*/}
                                </tr>




                            ))}


                        </tbody>
                    </Table>

                    {/*   <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button> */}
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen3
                    })}
                    onClick={() => this.toggle(3)}
                >
                    <span className="app__toggle-text">OLD POINTS </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen3 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen3}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen3 ? "app__collapse--active" : "")
                    }
                >






                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>


                                <th>POINT</th>
                                <th>ACTION</th>
                                <th>RESPONSIBILITY</th>
                                <th>TIMELINE</th>
                                <th>STATUS</th>
                               

                            </tr>

                        </thead>
                        <tbody>
                            {this.state.rows2.map((item, idx) => (
                                <tr id="addr0" key={item.id}>




                                    
                                    <td>
                                        <TextField type="text" required id="standard-required" name="Point" onChange={this.handleChange2(idx)} />
                                    </td>
                                    <td>
                                        <TextField type="text" required id="standard-required" name="Action" onChange={this.handleChange2(idx)} />
                                    </td>
                                    <td>
                                        <TextField type="text" required id="standard-required" name="Responsibility" onChange={this.handleChange2(idx)} />
                                    </td>
                                    <td>
                                        <TextField type="text" required id="standard-required" name="Timeline" onChange={this.handleChange2(idx)} />
                                    </td>
                                    <td>
                                        <TextField type="text" required id="standard-required" name="Status" onChange={this.handleChange2(idx)} />
                                    </td>
                                    {/* <td >
                                        <div style={{ width: '100px' }}>
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={this.handleRemoveSpecificRow(idx)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>
                                        
                                            <button class="btn btn-outline-danger btn-sm" onClick={this.handleAddRow()} style={{marginLeft:'8px'}}  >
                                            <span style={{ fontSize: '15px' }}>+ </span>
                                            </button>
                                           </div>
                                    </td> */}
                                </tr>




                            ))}


                        </tbody>
                    </Table>
                    {/*<button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button> */}
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen4
                    })}
                    onClick={() => this.toggle(4)}
                >
                    <span className="app__toggle-text">NEW POINTS</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen4 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen4}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen4 ? "app__collapse--active" : "")
                    }
                >




                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>


                                <th>POINT</th>
                                <th>ACTION</th>
                                <th>RESPONSIBILITY</th>
                                <th>TIMELINE</th>
                                
                               

                            </tr>

                        </thead>
                    <tbody>
                        {this.state.rows3.map((item, idx) => (
                            <tr id="addr0" key={item.id}>





                                <td>
                                    <TextField type="text" required id="standard-required" name="Point" onChange={this.handleChange3(idx)} />
                                </td>
                                <td>
                                    <TextField type="text" required id="standard-required" name="Action" onChange={this.handleChange3(idx)} />
                                </td>
                                <td>
                                    <TextField type="text" required id="standard-required" name="Responsibility" onChange={this.handleChange3(idx)} />
                                </td>
                                <td>
                                    <TextField type="text" required id="standard-required" name="TimeLine" onChange={this.handleChange3(idx)} />
                                </td>
                               
                                {/* <td>
                                    <div style={{ width: '100px' }}>
                                    <button
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={this.handleRemoveSpecificRow1(idx)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-danger btn-sm" onClick={this.handleAddRow1()} style={{ marginLeft: '8px' }}  >
                                        <span style={{ fontSize: '15px' }}>+ </span>
                                    </button>
                                </div>
                                        </td> */}
                            </tr>




                        ))}


                        </tbody>
                    </Table>
                    <button style={{ float: 'right' }} className="btn btn-success" onClick={this.handleSubmit}>SAVE</button>

                </Collapse>


            </div>


        )
    }
}

export default MinutesOfMeeting