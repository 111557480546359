import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editincidenthappen extends Component {

    constructor(props) {
        super(props)
        this.handleChange1 = this.handleChange1.bind(this);
       
        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {

            count: 0,
            type1:"Yes",
            type2:"No",
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: '',
            unsafeConditions: [],
            unsafeActs: [],
            rows1: [{ id: 0 }],
            rows2: [{ id: 0 }],

        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/IncidentHappen/GetIncidentHappenById', { params: { HappenId: id } }).then(response => {
            console.log(response.data);
          

            this.setState({
                rows: response.data[0],
                unsafeConditions:response.data[0].unsafeConditions.split(","),
                unsafeActs: response.data[0].unsafeActs.split(",")
            });
            console.log(this.state.UnsafeActs)
        });


    }









    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name
      
        
       const value = e.target.value

       
        var rows = this.state.rows;

        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };


    handleChange1(e) {
        
        let target = e.target
        let name = target.name
        var rows= this.state.rows;

        let value = Array.from(target.selectedOptions, option => option.value);

        this.setState({ [name]: value });
       
        let row = {
            ...rows,
            [name]: value.join(',')
        };
        rows = row;
        this.setState({
            rows
        });
    }

   
    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/IncidentHappen/UpdateIncidentHappenList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/incidentinvestigationview')
            }).catch(err => console.log(err));


    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Edit Incident Happen </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <label class="no-space-break">Unsafe Workplace Conditions(Use Ctrl Key For Multiple Selection): </label> <br />
                                <select name="unsafeConditions"
                                    multiple={true}
                                    value={ this.state.unsafeConditions}

                                    onChange={this.handleChange1}


                                >

                                    <option value="Inadequate guard" >Inadequate guard</option>
                                    <option value="Unguarded Hazard" >Unguarded Hazard</option>
                                    <option value="Safety device is defective" >Safety device is defective</option>
                                    <option value="Tool or equipment defective" >"Tool or equipment defective</option>
                                    <option value="Workstation Layout is Hazardous" >Workstation Layout is Hazardous</option>
                                    <option value="Unsafe Lighting" >Unsafe Lighting</option>

                                    <option value="Lack of needed personal protective equipment" >Lack of needed personal protective equipment</option>
                                    <option value="Lack of appropriate equipment/tools" >Lack of appropriate equipment/tools</option>
                                    <option value="Unsafe Clothing" >Unsafe Clothing</option>

                                    <option value="No training or Insufficient training" >No training or Insufficient training</option>
                                    <option value="Others" >Others</option>


                                </select>
                            </div>

                            <div class="mb-3 col-md-6">
                                <label class="no-space-break">Unsafe Acts By People(Use Ctrl Key For Multiple Selection):</label> <br />



                                <select name="unsafeActs"
                                    multiple={true}
                                    value={this.state.unsafeActs}

                                    onChange={this.handleChange1}


                                >

                                    <option value="Operating without permission" >Operating without permission</option>
                                    <option value="Operating at unsafe speed" >Operating at unsafe speed</option>
                                    <option value="Safety device is defective" >Safety device is defective</option>
                                    <option value="Servicing equipment that has power to it" >Servicing equipment that has power to it</option>
                                    <option value="Making a safety device inoperative" >"Making a safety device inoperative</option>
                                    <option value="Using defective equipment" >Using defective equipment</option>
                                    <option value="Using equipment in an unapproved way" >Using equipment in an unapproved way</option>
                                    <option value="Unsafe lifting" >Unsafe lifting</option>
                                    <option value="Taking an unsafe position or posture" >Taking an unsafe position or posture</option>
                                    <option value="Failure to wear personal protective equipment" >Failure to wear personal protective equipment</option>
                                    <option value="Failure to use the available equipment/tools" >Failure to use the available equipment/tools</option>
                                    <option value="Others" >Others</option>


                                </select>
                            </div>


                        </div>
                        



                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Sex">
                                    <label class="no-space-break">Why Unsafe Conditions Exists </label>
                                  
                                 
                                    <input name="unsafeExists" type="text" class="form-control" value={this.state.rows.unsafeExists} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="no-space-break">Why Unsafe Acts Occur</label>
                                <div class="input-group">


                                    <input required name="unsafeOccurs" class="form-control" type="text" value={this.state.rows.unsafeOccurs} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>





                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Rewards">
                                    <label class="no-space-break">Rewards</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.reward} name="reward">
                                        
                                        <option value={this.state.type1}>Yes</option>
                                        <option value={this.state.type2}>No</option>
                                        

                                    </select>

                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="UnsafeReported">
                                    <label class="no-space-break">Unsafe Acts Reported earlier</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.unsafeReported} name="unsafeReported">

                                        <option value={this.state.type1}>Yes</option>
                                        <option value={this.state.type2}>No</option>
                                        

                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="similarIncidents">
                                    <label class="no-space-break">Similar Incidents earlier</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.similarIncidents} name="similarIncidents">

                                        <option value={this.state.type1}>Yes</option>
                                        <option value={this.state.type2}>No</option>


                                    </select>
                                </div>
                            </div>
                            
                        </div>
                            
                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editincidenthappen