import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Moment from 'moment';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { text } from 'devexpress-dashboard/model/index.metadata';


class Editfireinvesting extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
            types: [],
            unit: '',
            validationError: "",
            count: 0,

            rows: [{ id: 0 }],

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            selectedFile: null,
            image: null 

        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/FireInvestigation/GetFireInvestigationById', { params: { FireInvestId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0],
                image: process.env.PUBLIC_URL+response.data[0].uploadedImage

            });
           

        });

    }



    handleChange = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
       
      
        var value = 0;
        if (name == "lossEstimated") {
            value = parseInt(e.target.value)

        }
        else {

            value=e.target.value
        }


        var rows = this.state.rows;
       
        let row = {

            ...rows,
            [name]: value,
            
        };
        rows= row;
        this.setState({
            rows
        });
    };



    handleChange2 = e => {
       
        e.preventDefault();


        const name = e.target.name;

       
            this.setState({ selectedFile: e.target.files });
            this.setState({ image: URL.createObjectURL(e.target.files[0]) });
       
        console.log(this.state.selectedFile)
        let filename = e.target.files[0].name;
        let root = `/Images/` + filename
       
        //const val = e.target.value;
        //var sub=val.substring(12,val.length)


        var rows = this.state.rows;
        var utype = parseInt(this.state.utype)
        let row = {
            ...rows,

            [name]: root,


        };

        rows= row;
        this.setState({
            rows
        });
      
      
    };


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    formatDate(string) {
        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('YYYY-MM-DD')


    }

    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
       
            // Create an object of formData
            const file = new FormData();
        if (this.state.selectedFile != null) {
            // Update the formData object
            file.append("formFile", this.state.selectedFile[0])
            file.append("filename", this.state.selectedFile[0].name)
        }
        axios.all([
            axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/FireInvestigation/UpdateFireInvestigationDetails', newbody),

            axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/SafetyWalkList/UploadeFiles', file)

        ]).then(axios.spread((response1, response2) => {
            if (response1.data.Status === 'Success') {
                alert("Entry Updated Successfully");
                this.props.history.push('/fireinvestigatingview')
                
            }

            else {
                alert("Entry Updated Successfully");
                this.props.history.push('/fireinvestigatingview')
            }
            
            
        })).catch(err => console.log(err))
      


    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label class="no-space-break">

                        Date Of Reporting:&nbsp;{this.state.newdate}  &nbsp;
                        {this.state.longMonth},
                   {this.state.longYear}

                    </label>


                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Preliminary Fire Investigation </h5>

                <div className="col-4">
                    <br />


                    <div >
                        <label class="no-space-break">Date of fire incident: </label> <br />
                        <TextField selected type="date" name="dateOfFire" required id="standard-required" value={this.formatDate(this.state.rows.dateOfFire) } onChange={this.handleChange}  />
                    </div>
                    <br />
                    <div>
                        <label class="no-space-break">Time of fire incident: </label>
                        <br />
                        <TextField
                            name="timeOfFire"

                            type="time"
                            defaultValue="hh:mm"
                            value={this.state.rows.timeOfFire}
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </div>

                </div>


                <div className="col-3" >
                    <br />

                    <div >
                        <div class='box' ><img className="img-fluid1" src={this.state.image} alt='No Image Selected' width="300" height="100" />
                        </div>
                        <br />
                        <input className="btn btn-primary btn-sm" type="file" name="uploadedImage" onChange={this.handleChange2} /> <br />


                        <br />  <br />



                    </div>





                </div>
                <div className="col-5">
                    <br />



                    <div >
                        <label class="no-space-break">Exact location of incident: </label> <br />
                        <TextField type="text" name="fireLocation" required id="standard-required" value={this.state.rows.fireLocation} onChange={this.handleChange} />
                    </div>

                    <br />

                    <div >
                        <label class="no-space-break">Material involved in the fire: </label> <br />
                        <TextField type="text" name="materialInvolved" required id="standard-required" value={this.state.rows.materialInvolved} onChange={this.handleChange} />
                    </div>

                </div>
                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />


                    <label class="no-space-break">Details of property damaged by fire: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        onChange={this.handleChange}
                        value={this.state.rows.damageDetails}
                        name="damageDetails"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br />
                    <label class="no-space-break">Details of any injuries due to fire: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        onChange={this.handleChange}
                        value={this.state.rows.injury}
                        name="injury"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br />
                    <label class="no-space-break">Estimated loss due to fire: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        type="number"
                        label="Description"
                        style={{ margin: 8 }}
                        value={this.state.rows.lossEstimated}
                        onChange={this.handleChange}
                        name="lossEstimated"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br />
                    <label class="no-space-break">Brief description of the incident: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        value={this.state.rows.breifDescription}
                        onChange={this.handleChange}
                        name="breifDescription"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />


                    <br />
                    <label class="no-space-break">Possible causes of fire: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        value={this.state.rows.causeOfFire}
                        onChange={this.handleChange}
                        name="causeOfFire"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br />
                    <label class="no-space-break">Corrective and preventive action: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        value={this.state.rows.correctiveAction}
                        name="correctiveAction"
                        onChange={this.handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />


                </div>






                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editfireinvesting