import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editcarbonemission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: '',
            id: 0,
            mode: '',
            type: '',
            quantity: ''

        };
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin ==null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/SignificantCarbonEmission/GetCarbonEmissionByID', { params: { CoEmissionId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0],
                
            });

        });
    }

    handletotal = event => {
        
        event.preventDefault();
        let rows = this.state.rows;
        const name = event.target.name;
        let tonne = 1;
        let km = 1;
        if (name == "tonne") {
            tonne = parseInt(event.target.value);
        }

        else {
            km = parseInt(event.target.value);

        }
        
        rows.caEmissions = tonne * km * rows.emissionFactor
        this.setState({
            rows: rows
        })
    }


    handleSubmit = event => {
        
        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/SignificantCarbonEmission/UpdateCarbonEmission', newbody)
            .then(response => {
                alert("Entry Updated Successfully");

                this.props.history.push("/energyview/?name=" + "15.CO2 Emissions(Scope3) &&" + "id=" + submissionid);

            }).catch(err => console.log(err));
    }

    handleChange = event => {
        
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        const value = parseFloat(event.target.value);
        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
           
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <div className="form-container">
                <Form className="register-form" onSubmit={this.handleSubmit}>

                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="Aspects">Aspects</label>
                            <input disabled name="Aspects" step="any" className="form-control" id="Aspects" defaultValue={this.state.rows.aspects} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="EmissionFactor">Emission Factor</label>
                            <input disabled name="EmissionFactor" step="any" className="form-control" id="EmissionFactor" defaultValue={this.state.rows.emissionFactor} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="Tonne">Tonne</label>
                            <input required type="number" name="tonne" step="any" className="form-control" id="Tonne" defaultValue={this.state.rows.tonne} onChange={this.handleChange} onKeyUp={this.handletotal} onBlur={this.handletotal} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="Km">Km</label>
                            <input required type="number" name="km" step="any" className="form-control" id="Km" defaultValue={this.state.rows.km} onChange={this.handleChange} onKeyUp={this.handletotal} onBlur={this.handletotal} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="CaEmissions">Co2 Emissions</label>
                            <input disabled type="number" name="CaEmissions" step="any" className="form-control" id="CaEmissions" defaultValue={this.state.rows.caEmissions} onChange={this.handleChange}  />
                        </div>
                    </div>
                    <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>

                </Form>
            </div>
        );
    }
}

export default Editcarbonemission 