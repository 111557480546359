import React, { Component } from 'react'
import { Table, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import '../index.css';
import axios from 'axios';
import Tooltip from "react-simple-tooltip"

class Carbonemission extends Component {
    constructor(props) {
        super(props)
        let date = new Date()
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;
        this.state = {
            count: 0,
            rows: [{ id: 0 }],
            mode1:'Road',
            mode2:'Air',
            mode3:'Ship',
            type1:'Petrol',
            type2:'Diesel',
            type3: 'Others',
            fuel:'',
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear:prevYear,
            types: [],
            multiple: 0,
            isOpen: false
        }

    }
    async componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);

       await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SignificantCarbonEmission/GetCarbonEmissionMaster')
            .then(response => {
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi

                });
                // ucode=localStorage.getItem('username'),
                // utype=localStorage.getItem('utype'),
                // location=localStorage.getItem('location')

            })
            //  .then(data => {
            // let typesapi = Object.keys(data.type);
            //console.log(typesapi);



            //    })
            .catch(error => {
                console.log(error);

            });
       
        var carbon = JSON.parse(localStorage.getItem("carbon"));
        var carbontotal = JSON.parse(localStorage.getItem("carbontotal"));
        var types = this.state.types;
        var total = this.state.total;
        if (carbon != null) {
            types = carbon
            total=carbontotal
            this.setState({
                types,
                total
            })
        }
    }

    openModal = (e) => {


        this.setState({ isOpen: true }, () => {
            window.setTimeout(() => {
                this.setState({ isOpen: false })
            }, 2000)
        });

    }
    closeModal = () => this.setState({ isOpen: false });


    handleChange = (idx) => (e) => {
       
        e.preventDefault();
       
        const name = e.target.name;
        const value = parseFloat(e.target.value);
      
        const types = [...this.state.types];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        //var year = 0

        //var prevYear = this.state.prevYear;
        //if (prevMonth == "December") {
        //    year = prevYear
        //}
        //else {
        //    year = longYear
        //}
        let row = {
            ...types[idx],
            id: idx,
            [name]: value,
            "Year": year,
            "Month": month,
            "LocationId": location,
            "UserId": utype,
            "ReferenceId":Reference
           
        };
        types[idx] = row;
        this.setState({
            types
        });
    };


    handletotal = (idx) => (event) => {
         
        let types = this.state.types;
        const name = event.target.name;
        const value = event.target.value;
        let tonne ;
        let km;
        if (value != "") {
            if (name == "Tonne") {
                tonne = parseFloat(value);
                km=1
            }

            else {
                km = parseFloat(value);
                tonne=1
            }
            types[idx].CaEmissions = tonne * km * types[idx].emissionFactor

            this.setState({
                types: types,

            })
        }
        
       
        var total = 0
        for (var i = 0; i < types.length; i++) {
            if (types[i].CaEmissions != null) {
                total = total + types[i].CaEmissions
            }
            else {
                continue;
            }

        }

        this.setState({
            total: total
        })
    }


  
    isDisabled1() {

        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }

    handleSubmit = event => {

        event.preventDefault();
        const { types } = this.state;
        const total = this.state.total;
        //var prevMonth = this.state.prevMonth;
        //var year = 0;
        //var longYear = this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year = longYear
        //}
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');
        var id = localStorage.getItem('utype')
        //axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SignificantCarbonEmission/GetCarbonEmissionByMonth', { params: { Month: month,Year: year, UserId: id } })
        //    .then(response => {
                
        //        if (response.data.length == 0) {


                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(types)
                    };
                    localStorage.setItem('carbon', Options.body);
                    localStorage.setItem('carbontotal', total);
                    let newbody = JSON.parse(Options.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SaveCarbonEmissiontemp', newbody).then(response => {

                        if (response.data == 1) {
                            this.openModal()
                        }

                        else {

                            alert("Data Not Saved.Kindly Save Again")
                        }

                    })

            //    }
            //    else {

            //        alert("Data Already Submitted")
            //    }

            //})
        
        /*let newbody = JSON.parse(Options.body);


        axios.post('https://localhost:23013/api/SignificantCarbonEmission/SaveCarbonEmission', newbody)
            .then(response1 => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }); */


    }  



    renderRows() {
        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}>

                   

                      
        <td>  
        <FormControl>
        
        <Select  isSearchable required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="ModeOfTransport"
          onChange={this.handleChange(idx)}
         >
                                <MenuItem value={this.state.mode1} >Road</MenuItem>
                                <MenuItem value={this.state.mode2} >Air</MenuItem>
                                <MenuItem value={this.state.mode3} >Ship</MenuItem>
         
        </Select>
       
      </FormControl> </td>

                    <td>
                        <FormControl>
                          
                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="FuelType"
                                onChange={this.handleChange(idx)}
                            >
                                <MenuItem value={this.state.type1} >Petrol</MenuItem>
                                <MenuItem value={this.state.type2} >Diesel</MenuItem>
                                <MenuItem value={this.state.type3} >Others</MenuItem>

                            </Select>

                        </FormControl> </td>
                    <td>
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any"} }} required id="standard-required" name="FuelUsed" defaultValue={this.state.fuel} onChange={this.handleChange(idx)} />
                    </td>
                 
                    <td>
                        <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx)}>
                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button>

                    </td>
                </tr>

            );
        });
    }

    render() {
        const text = "Emissions due to transportation of raw materials, finished goods and wastes. For transportation by Road,\nplease provide only Kms covered by trucks and the fuel used by them. For transportation by rail, air or ship,\nindicate the distance covered in Tonne-Km under respective items. For all transportation between units,\nonly transportation with regard to material despatch should be accounted.The Emission factors are as\nper GHG protocols"
        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>
                <br />
                <div className="App">
                    <div className="wordalign">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label>
                        {this.state.longMonth}   &nbsp;
                   {this.state.longYear}
                            &nbsp;&nbsp;
                    <Tooltip
                        arrow={15}
                        background="#ffffff"
                        border="black"
                        color="black"
                        content={text.split("\n").map((i, key) => {

                            return <div key={key}>{i}</div>;


                        })}
                        fadeDuration={0}
                        fadeEasing="linear"
                        fixed={false}
                        fontFamily="Bw Glenn Sans Medium"
                        fontSize="12px"
                        offset={-20}
                        padding={15}

                        placement="right"
                        radius={50}
                        zIndex={1}




                    >

                            <span className="btn btn-outline-danger btn-sm">

                                info
                               {/*<svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                            </span>
                            </Tooltip>

                        </label>
                </div>
                </div>
                {/*<h5>SIGNIFICANT CO2 EMISSIONS DUE TO TRANSPORTATION </h5> */}
                <Modal style={{ backgroundColor: 'white', width: '500px', height: '200px', borderColor: '#ffffff00' }}

                    show={this.state.isOpen} onHide={this.closeModal}>

                    <Modal.Header style={{ backgroundColor: '#001946', color: 'white' }} >
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Data Saved Successfully

                          </Modal.Body>
                </Modal>
                <br />
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                               
                                 
                                <th>ASPECTS</th>
                                <th>EMISSION FACTOR</th>
                                <th>TONNE</th>
                                <th>KM</th>
                                <th>CO2 EMISSIONS</th>
                              
                               
                            </tr>

                        </thead>
                        <tbody> 
                        {this.state.types.map((item, idx) => (


                            <tr id="addr0" key={item.id}>

                            

                                <td>
                                    <TextField InputProps={{ readOnly: true }} style={{ width: '280px' }} type="text" name="Aspects" defaultValue={item.aspects} />
                                </td>


                                <td>
                                    <TextField InputProps={{ readOnly: true }} type="number" name="EmissionFactor" defaultValue={item.emissionFactor}/>
                                </td >

                                <td>
                                    <TextField required id="standard-required" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" name="Tonne" onChange={this.handleChange(idx)} onKeyUp={this.handletotal(idx)} value={item.Tonne}/>
                                </td>
                                <td>
                                    <TextField required id="standard-required" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" name="Km" onChange={this.handleChange(idx)} onKeyUp={this.handletotal(idx)} value={item.Km}/>
                                </td>
                                <td>
                                    <TextField InputProps={{ readOnly: true }} type="number" name="CaEmissions" value={item.CaEmissions}   />
                                </td>
                              

                            </tr>

                            
                        ))}
                      

                           
                        {/*     {this.renderRows()} */}

                        </tbody>
                    </Table>

                <div style={{ float: 'right' }}>
                    <label style={{ fontSize: '18px' }}>Total</label>
                    <TextField InputProps={{ readOnly: true }} type="number" name="Total" value={Math.round(this.state.total * 100000) / 100000}/>
                </div>

                <br/>
                <br/>
                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit} disabled={this.isDisabled1()}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Carbonemission