import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editpermitheight extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {

            count: 0,


            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: '',
            type1: "Yes",
            type2: "No",
            type3: "NA",


        }
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }

        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/PermitWorkingHeight/GetHeightWorkPermitById', { params: { HeightPermitId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0]


            });

        });


    }









    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name

        var value = e.target.value


        var rows = this.state.rows;

        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };


    formatDate(string) {

        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('YYYY-MM-DD')


    }

    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/PermitWorkingHeight/UpdateHeightWorkPermitList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/permitheightview')
            }).catch(err => console.log(err));


    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Edit General Work Permit </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Date">
                                    <label class="no-space-break">Date</label>
                                    <input required name="date" type="date" class="form-control" value={this.formatDate(this.state.rows.date)} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Time">
                                    <label class="no-space-break" >Time</label>
                                    <input name="time" type="time" class="form-control" defaultValue={this.state.rows.time} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>

                       
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="JobDescription">
                                    <label class="no-space-break">Job Description</label>
                                    <input required name="jobDescription" type="text" class="form-control" value={this.state.rows.jobDescription} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="AreaLocation">
                                    <label class="no-space-break" >Area Location</label>
                                    <input name="areaLocation" type="text" class="form-control" defaultValue={this.state.rows.areaLocation} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>


                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="CarriedBy">
                                    <label class="no-space-break">Work Carried By</label>
                                    <input required name="carriedBy" type="text" class="form-control" value={this.state.rows.carriedBy} onChange={this.handleChange} />
                                </div>
                            </div>
                           


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="GoodConstruction">
                                    <label class="no-space-break" >Scaffold of Good Construction,Adequate,Strength With 50cm Clear <br />Walkways,Toeboards With Wide Screens.</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.goodConstruction} name="goodConstruction">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="WellSecured">
                                    <label class="no-space-break" >Scaffold Well Secured With Stairways, Hand Rails,Should Be Wide <br />Enough To Pass Two Persons At a Time</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.wellSecured} name="wellSecured">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="HouseKeeping">
                                    <label class="no-space-break" >Maintained Good Housekeeping at Work Location/Site </label>
                                    <br />
                                    <br />
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.houseKeeping} name="houseKeeping">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="NetsFixed">
                                    <label class="no-space-break" >Should Have Nets Fixed At The Bottom of Scaffold <br />To Prevent Bodily Injury In Case of Fall</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.netsFixed} name="netsFixed">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="StrongMaterial">
                                    <label class="no-space-break" >Strong Material, Well Maintained Ladders</label>
                                    <br />
                                    <br />
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.strongMaterial} name="strongMaterial">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="LaddersNotPlaced">
                                    <label class="no-space-break" >Ladders Not Placed Against Loose Boxes,Materials,Round Objects,<br /> Near Electrical Installation</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.laddersNotPlaced} name="laddersNotPlaced">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="LaddersSufficient">
                                    <label class="no-space-break" >Ladders of Sufficient Height Used,on Top Tied Down and Man Positioned <br />at The Foot of Ladder.Safety Footwear Provided</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.laddersSufficient} name="laddersSufficient">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="SafetyFootwear">
                                    <label class="no-space-break" >Safety Footwear Provided</label>
                                    <br />
                                    <br />
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.safetyFootwear} name="safetyFootwear">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="LaddersAngle">
                                    <label class="no-space-break" >Ladder Placed at an Angle of 70 to 75 Degrees.</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.laddersAngle} name="laddersAngle">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="AreaOfWork">
                                    <label class="no-space-break" >Area of Work Baricaded So That No Person Can Walk Under The Ladder</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.areaOfWork} name="areaOfWork">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="SafetyBelt">
                                    <label class="no-space-break" >Safety Belt Provided and Worn</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.safetyBelt} name="safetyBelt">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="SafetyHelmet">
                                    <label class="no-space-break" >Safety Helmet Provided</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.safetyHelmet} name="safetyHelmet">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>
                                        <option value={this.state.type3} >NA</option>
                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="PermissionFrom">
                                    <label class="no-space-break" >Permission From</label>
                                    <input name="permissionFrom" type="time" class="form-control" defaultValue={this.state.rows.permissionFrom} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="PermissionTo">
                                    <label class="no-space-break" >Permission To</label>
                                    <input name="permissionTo" type="time" class="form-control" defaultValue={this.state.rows.permissionTo} onChange={this.handleChange} />
                                </div>
                            </div>

                       
                    </div>
                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editpermitheight