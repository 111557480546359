import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Modal, Button } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Moment from 'moment';
import '../index.css';

class Workpermitview extends Component {

    constructor(props) {
        super(props)

        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            count: 0,

            rows: [],
            rows1: [],
            rows2: [],
            rows3: [],
            rows4: [],
            isOpen: false,
          
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            year1: '2020',
            year2: '2021',
            year3: '2022',
            status1: 'Approved',
            status2: 'Rejected',
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            month: prevMonth,
            year: longYear,
            status: "pending",
            id: ''
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null) {
            window.location.href = '/'
            return;
        }
        var prevYear = this.state.prevYear;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        if (prevMonth == "December") {
            this.setState({
                year: prevYear
            })
        }
        else {
            this.setState({
                year: longYear
            })
        }

        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        if (utypeid == 1) {
            document.getElementById('monthfilter').style.display = "none";
            
            document.getElementById("resubmit").style.display = "none";

            var adminhistoryehs = localStorage.getItem('adminhistoryehs')
            if (adminhistoryehs == 'true') {
                document.getElementById('status').style.display = "none";
            }

            else {
                document.getElementById('status').style.display = "inline";
            }
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            this.setState({
                id: id
            });
             axios.all([
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/HotWorkPermit/GetHotWorkPermitFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkPermitCheckSheet/GetWorkPermitCheckSheetFiltered', { params: { SubmissionId: id } }),
            ]).then(axios.spread((response1, response2) => {
                this.setState({
                    rows: response1.data,
                    rows3: response2.data,
                })
            }))
        }
        else {
            var Status = localStorage.getItem('Status')
            localStorage.removeItem("Status");
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            if (Status == 'Rejected' || id != null) {
                document.getElementById('monthfilter').style.display = "none";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "inline";
                localStorage.setItem('submissionid', id);
                this.setState({
                    id: id
                });
                 axios.all([
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/HotWorkPermit/GetHotWorkPermitFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkPermitCheckSheet/GetWorkPermitCheckSheetFiltered', { params: { SubmissionId: id } }),

                ]).then(axios.spread((response1, response2) => {
                    this.setState({
                        rows: response1.data,
                        rows3: response2.data,
                    })
                }))
            }
            else {
                document.getElementById('monthfilter').style.display = "inline";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "none";
                var uid = localStorage.getItem('utype')

                 axios.all([
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/HotWorkPermit/GetHotWorkPermitByUId', { params: { UserId: uid } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkPermitCheckSheet/GetWorkPermitCheckSheetByUId', { params: { UserId: uid } })
                ]).then(axios.spread((response1, response2) => {
                    this.setState({
                        rows1: response1.data,
                        rows4:response2.data
                    })
                    var prevMonth = this.state.prevMonth;
                    var rows = response1.data.filter(x => x.month == prevMonth);
                    var rows3 = response2.data.filter(x => x.month == prevMonth);
                    this.setState({
                        rows: rows,
                        rows3:rows3
                    })
                }));
            }
        }

    }


    isDisabled() {
        var utype = localStorage.getItem('utype')
        if (utype == 1) {
            return true;
        }
        else {
            return false;
        }
    }

    isDisplayed() {
        var Status = localStorage.getItem('Status')
        var id = localStorage.getItem('submissionid')
        if (Status == 'Rejected' || id != null) {
            return "inline"
        }
        else {
            return "none";
        }
    }


    openModal = (e) => {
        
        this.setState({ status: e.target.value });
        this.setState({ isOpen: true });
    }

    closeModal = () => this.setState({ isOpen: false });
    toggle = (index) => {
        
        let collapse = "isOpen" + index;
        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }

    handleRemoveSpecificRow = (idx, id) => (e) => {
        e.preventDefault();
        var result = window.confirm("Are you sure you want to delete?");
        if (result) {
            axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/HotWorkPermit/DeleteHotWorkPermit', { params: { WorkPermitId: id } }).then(response => {
                alert("Entry Deleted Successfully");
                window.location.reload();

            }).catch(err => console.log(err));
        }
    }

    edit(idx) {
        this.props.history.push("/editworkpermit/?id=" + idx);
    }
    edit1(idx) {
        this.props.history.push("/editworkpermitcheck/?id=" + idx);
    }
    handleSubmit9 = (event) => {
        this.props.history.push("/");
    }

    formatDate(string) {
        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        var check = new Date(string).toLocaleDateString([], options);
        return Moment(check).format('DD-MM-YYYY')
    }

    handleSubmit = event => {
        event.preventDefault();
        var month = this.state.month;
        var year = this.state.year;
        var id = localStorage.getItem('utype')
        axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/HotWorkPermit/GetHotWorkPermitByMonth', { params: { Month: month, Year: year, UserId: id } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkPermitCheckSheet/GetWorkPermitCheckSheetByMonth', { params: { Month: month, Year: year, UserId: id } })
        ]).then(axios.spread((response1, response2) => {
                this.setState({
                    rows: response1.data,
                    rows3:response2.data
                });
                console.log(response1.data)
            }))
    }

    handleSubmit1 = e => {
        const { rows2 } = this.state;
        var Status = localStorage.getItem('Status')
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows2)
        };
        let newbody = JSON.parse(Options.body);
        if (Status == 'Submitted') {
            axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateUserSubmittedEhs', newbody[0])
                .then(response => {
                    this.setState({ isOpen1: false })
                    alert("Data Rejected Again");
                    this.props.history.push('/usersubmittedehs')
                });
        }
        else {
            axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSStatus', newbody)
                .then(response => {
                    this.setState({ isOpen: false })
                    alert("Status Updated Successfully");
                    this.props.history.push('/ehsadminview')
                });
        }
    }

    handleSubmit2 = e => {
        e.preventDefault();
        let SubmissionId = parseInt(this.state.id);
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateEhsStatus', { params: { SubmissionId: SubmissionId } })
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/adminrejectedehs')

            }).catch(err => console.log(err));
    }
    handleSubmit3 = event => {
        event.preventDefault();
        this.setState({
            month: this.state.prevMonth,
            year: this.state.longYear
        })
        this.componentDidMount();
    }
    handleChange = event => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    };


    handleChange1 = e => {
        const rows2 = [...this.state.rows2];
        let row = {
            [e.currentTarget.name]: e.currentTarget.value,

            "Status": this.state.status,
            "RegulatorId": 1,
            "SubmissionId": parseInt(this.state.id)
        }
        rows2[0] = row
        this.setState({
            rows2
        });
    };

    renderRows() {
        return this.state.rows.map((item, idx) => {
            return (
                <tr id="addr0" key={item.workPermitId}>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '120px' }} required id="standard-required" defaultValue={this.formatDate(item.date)} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.startTime} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.endTime} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.contractorEmployee} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.workDescription} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.workLocation} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.projectManager} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.dutyOfficer} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.perCarryHot} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.signOff} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.permitClose} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.contractorName} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.contractorContact} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.securityName} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.securityContact} />
                    </td>

                   
                    <td width="10%">
                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.workPermitId)} style={{ marginRight: '8px', display: this.isDisplayed() }} disabled={this.isDisabled()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </button>
                        {/* <button id="delete"
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.recommndationId)} style={{ marginTop: '8px', display: this.isDisplayed() }} disabled={this.isDisabled()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button> */}
                    </td>
                </tr>
            );
        });
    }


    render() {
        return (
            <Form className="font-link" >
                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Hot Work Permit </h5>
                <br />
                <div id="monthfilter">
                    <FormControl>
                        <select
                            value={this.state.month}
                            name="month"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Month--</option>
                            <option value={this.state.month1} >January</option>
                            <option value={this.state.month2} >February</option>
                            <option value={this.state.month3} >March</option>
                            <option value={this.state.month4} >April</option>
                            <option value={this.state.month5} >May</option>
                            <option value={this.state.month6} >June</option>
                            <option value={this.state.month7} >July</option>
                            <option value={this.state.month8} >August</option>
                            <option value={this.state.month9} >September</option>
                            <option value={this.state.month10} >October</option>
                            <option value={this.state.month11} >November</option>
                            <option value={this.state.month12} >December</option>
                        </select>
                    </FormControl>
                    &nbsp;
                    <FormControl>
                        <select
                            value={this.state.year}
                            name="year"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Year--</option>
                            <option value={this.state.year1} >2020</option>
                            <option value={this.state.year2} >2021</option>
                            <option value={this.state.year3} >2022</option>
                        </select>
                    </FormControl>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit3} type="submit" className="btn btn-success btn-sm">CLEAR</button>

                </div>
                <br />
                <div id="status" style={{ float: 'right' }}>
                    <label style={{ fontWeight: 'bold' }}>Status:</label>
                    <FormControl>
                        <select
                            value={this.state.year}
                            name="year"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Year--</option>
                            <option value={this.state.prevYear} >{this.state.prevYear}</option>
                            <option value={this.state.longYear} >{this.state.longYear}</option>
                        </select>
                    </FormControl>
                </div>
                <div id="resubmit">
                    <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit2}>RE-SUBMIT FOR APPROVAL</button>
                </div>
                <br />

                <Modal style={{ backgroundColor: '#ffffff00', width: '500px', borderColor: '#ffffff00' }}
                    show={this.state.isOpen} onHide={this.closeModal}>
                    <div className="App1">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label for='Remarks'>Remarks: </label>
                            <TextField type="text" name="Remarks" id="Remarks" onChange={this.handleChange1} style={{ width: '350px' }} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.handleSubmit1} style={{ marginRight: '8px' }}> Submit </Button>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
                <br />
                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white", overflowX: 'scroll', display: 'block', overflowY: 'hidden' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                            <th>DATE</th>
                            <th>START TIME</th>
                            <th>END TIME</th>
                            <th>NO OF CONTRACTOR EMPLOYEES WORKING</th>
                            <th>WORK DESCRIPTION </th>
                            <th>WORK LOCATION</th>
                            <th>PROJECT MANAGER</th>
                            <th>DUTY SECURITY AND FIRE OFFICER</th>
                            <th>PERSONS CARRYING OUT HOT WORK</th>
                            <th>FIRE WATCH SIGN OFF</th>
                            <th>CLOSE OF PERMIT</th>
                            <th>CONTRACTOR NAME </th>
                            <th>CONTRACTOR CONTACT</th>
                            <th>SECURITY NAME </th>
                            <th>SECURITY CONTACT</th>

                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </Table>
                
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECKPOINTS</th>
                                    <th>RESPONSIBILITY</th>
                                    <th>ACTION</th>
                                    <th>REMARKS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.rows3.map((item, idx) => (
                                    <tr id="addr0" key={item.safetyCheckId}>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} style={{ width: '280px' }} type="text" required id="standard-required" defaultValue={item.checkpoints} />
                                        </td >
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} style={{ width: '140px' }} type="text" required id="standard-required" defaultValue={item.responsibility} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.action} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.remarks} />
                                        </td>
                                        <td width="10%">
                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit1(item.safetyCheckId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </button>
                                            {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}


                                        </td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </Table>
                        {/*  <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>
               <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
                   
                
            </Form>


        )

    }
}

export default Workpermitview