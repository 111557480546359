import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editusers extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
            types: [],
            locations: [],
            typeid: "",
            validationError: "",
            count: 0,
            Upwd: '',
            confirm: '',

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
         userroleid:''


        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin ==null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/GetUserDetailsByID', { params: { UserId: id } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/GetUsersRole'),


        ]).then(axios.spread((response1, response2) => {
            console.log(response1.data)
            let rows=[...this.state.rows]
            let rowdata=response1.data
            let typesapi = response2.data;
            console.log(typesapi);
            this.setState({
                types: typesapi

            });
            rows=rowdata
            this.setState({
                rows
            })
          
            let userRoleid = response1.data[0].userRoleId
            this.setState({
                userroleid:userRoleid
            })
            axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/GetUserRoleLocation', { params: { UserRoleId: this.state.userroleid } })
                .then(response => {
                    let typesapi1 = response.data;
                    console.log(typesapi1);
                    this.setState({
                        locations: typesapi1

                    });

                })

                .catch(error => {
                    console.log(error);

                });
        }));

      /*  this.transferdata(); */

        
    }



    





    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = event => {
        
        event.preventDefault();
        const name = event.target.name;

        if (name == "UserRoleId") {
            var val = parseInt(event.target.value);
            axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/GetUserRoleLocation', { params: { UserRoleId: val } })
                .then(response => {
                    let typesapi1 = response.data;
                    console.log(typesapi1);
                    this.setState({
                        locations: typesapi1

                    });

                })

                .catch(error => {
                    console.log(error);

                });
        }


        var value = 0;
        if (name == "utypeId" || name == "contact" || name == "locationId" || name == "userRoleId") {
            value = parseInt(event.target.value);

        }
        else {

            value = event.target.value;
        }
        this.setState({
            [name]: value
        });
        const rows = [...this.state.rows];

     
        let row = {

            ...rows[0],

            [name]: value
        };
        rows[0] = row;
        this.setState({
            rows
        });
    }

    handleSubmit = event => {
        debugger
        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row[0])
        };
        //let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/UpdateUserDetails', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/userlist')

            }).catch(err => console.log(err));
    }



 


    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: 'cyan', textAlign: 'center' }}>Update User </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Name">
                                    <label class="form-label">Name</label>
                                    <input required placeholder="Enter Name" name="uname" type="text" class="form-control" defaultValue={this.state.rows[0].uname} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Contact">
                                    <label class="form-label" >Contact</label>
                                    <input required placeholder="Enter Contact Number" name="contact" type="number" class="form-control" defaultValue={this.state.rows[0].contact} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>



                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Email">
                                    <label class="form-label">Email Id</label><input required placeholder="Enter Email Address" name="email" type="email" class="form-control" defaultValue={this.state.rows[0].email}  onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Address">
                                    <label class="form-label">Address</label><input required placeholder="Address" name="address" type="text" class="form-control" defaultValue={this.state.rows[0].address} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Username">
                                    <label class="form-label">Username</label>
                                    <input required placeholder="Enter Username" name="ucode" type="text" class="form-control" defaultValue={this.state.rows[0].ucode} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="utype">
                                    <label class="form-label">User Type</label> <br /><select required class="form-control" name="utypeId" value={this.state.rows[0].utypeId} onChange={this.handleChange}><option disabled value='0'>--Select--</option><option value="1">Admin</option><option value="2" selected="">Plant User</option><option value="3">Regulator</option></select></div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="business">
                                    <label class="form-label">Role</label><br />
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows[0].userRoleId} name="userRoleId">
                                        <option selected disabled value='0'>--Select--</option>
                                        {this.state.types.map(type => (
                                            <option
                                                key={type.userRoleId}
                                                value={type.userRoleId}
                                            >
                                                {type.roleType}
                                            </option>
                                        ))}
                                    </select></div>

                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="location">
                                    <label class="form-label">Location</label><br />
                                    <select name="locationId" required class="form-control" value={this.state.rows[0].locationId} onChange={this.handleChange}>
                                        <option selected disabled value='0'>--Select--</option>
                                        {this.state.locations.map(type => (
                                            <option
                                                key={type.locationId}
                                                value={type.locationId}
                                            >
                                                {type.locationName}
                                            </option>
                                        ))}
                                    </select></div>
                            </div>


                        </div>


                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editusers