import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Modal, Button } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Moment from 'moment';
import '../index.css';


class Electricpermitview extends Component {

    constructor(props) {
        super(props)

        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            count: 0,

            rows: [],
            rows1: [],
            rows2: [],
            isOpen: false,
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            year1: '2020',
            year2: '2021',
            year3: '2022',
            status1: 'Approved',
            status2: 'Rejected',
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,

            month: prevMonth,
            year: longYear,
            status: "pending",
            id: '',
            requirementKey: Math.random(),
            editable: true,
            butdisplay:"none"
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin ==null) {
            window.location.href = '/'
            return;
        }
        var prevYear = this.state.prevYear;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        if (prevMonth == "December") {
            this.setState({
                year: prevYear
            })
        }
        else {
            this.setState({
                year: longYear
            })
        }


        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        if (utypeid == 1) {
            

            document.getElementById('monthfilter').style.display = "none";
            document.getElementById("resubmit").style.display = "none";
            document.getElementById("edit").style.display = "none";

            var adminhistoryehs = localStorage.getItem('adminhistoryehs')
            if (adminhistoryehs == 'true') {
                document.getElementById('status').style.display = "none";
            }

            else {
                document.getElementById('status').style.display = "inline";
            }
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            this.setState({
                id: id
            });
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ElectricalWorkPermit/GetElectricalWorkPermitFiltered', { params: { SubmissionId: id } }).then(response => {
                this.setState({
                    rows: response.data[0],
                })
            })
        }
        else {
            var Status = localStorage.getItem('Status')
            localStorage.removeItem("Status");
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            if (Status == 'Rejected' || id != null) {
                document.getElementById('monthfilter').style.display = "none";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "inline";
                document.getElementById("edit").style.display = "inline";
                localStorage.setItem('submissionid', id);
                this.setState({
                    id: id
                });
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ElectricalWorkPermit/GetElectricalWorkPermitFiltered', { params: { SubmissionId: id } }).then(response => {
                    this.setState({
                        rows: response.data[0]
                    })
                })
            }
            else {
                document.getElementById('monthfilter').style.display = "inline";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "none";
                document.getElementById("edit").style.display = "none";
                var uid = localStorage.getItem('utype')
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ElectricalWorkPermit/GetElectricalWorkPermitByUId', { params: { UserId: uid } }).then(response => {
                    this.setState({
                        rows1: response.data
                    })
                    var prevMonth = this.state.prevMonth;
                    var rows1 = response.data.filter(x => x.month == prevMonth);
                    var rows=rows1[0]
                    
                    this.setState({
                        rows: rows
                    })
                    console.log(this.state.rows.name)
                });
            }
        }

    }


    isDisabled() {
        var utype = localStorage.getItem('utype')
        if (utype == 1) {
            return true;
        }
        else {
            return false;
        }
    }

    isDisplayed() {
        var Status = localStorage.getItem('Status')
        var id = localStorage.getItem('submissionid')
        if (Status == 'Rejected' || id != null) {
            return "inline"
        }
        else {
            return "none";
        }
    }

   
    openModal = (e) => {
        
        this.setState({ status: e.target.value });
        this.setState({ isOpen: true });
    }

    closeModal = () => this.setState({ isOpen: false });

    handleRemoveSpecificRow = (idx, id) => (e) => {
        e.preventDefault();
        var result = window.confirm("Are you sure you want to delete?");
        if (result) {
            axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/ElectricalWorkPermit/DeleteElectricalWorkPermit', { params: { ElectricPermitId: id } }).then(response => {
                alert("Entry Deleted Successfully");
                window.location.reload();

            }).catch(err => console.log(err));
        }
    }

    edit = (idx) => event => {
        
        event.preventDefault();
        var editable = false;
        var butdisplay="inline"
        this.setState({
            editable: editable,
            butdisplay:butdisplay
        })
        return;
    }

    handleSubmit9 = (event) => {
        this.props.history.push("/");
    }

    formatDate(string) {
       
        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        var check = new Date(string).toLocaleDateString([], options);
        return Moment(check).format('YYYY-MM-DD')
    }

    handleSubmit = event => {

        
        event.preventDefault();
        var month = this.state.month;
        var year = this.state.year;
        var id = localStorage.getItem('utype')
        this.setState({ requirementKey: Math.random() });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ElectricalWorkPermit/GetElectricalWorkPermitByMonth', { params: { Month: month, Year: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows: response.data
                });
                this.forceUpdate();
            })
    }

    handleSubmit1 = e => {
        const { rows2 } = this.state;
        var Status = localStorage.getItem('Status')
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows2)
        };
        let newbody = JSON.parse(Options.body);
        if (Status == 'Submitted') {
            axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateUserSubmittedEhs', newbody[0])
                .then(response => {
                    this.setState({ isOpen1: false })
                    alert("Data Rejected Again");
                    this.props.history.push('/usersubmittedehs')
                });
        }
        else {
            axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSStatus', newbody)
                .then(response => {
                    this.setState({ isOpen: false })
                    alert("Status Updated Successfully");
                    this.props.history.push('/ehsadminview')
                });
        }
    }

    handleSubmit2 = e => {
        e.preventDefault();
        let SubmissionId = parseInt(this.state.id);
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateEhsStatus', { params: { SubmissionId: SubmissionId } })
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/adminrejectedehs')

            }).catch(err => console.log(err));
    }

    handleSubmit3 = event => {
        
        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/ElectricalWorkPermit/UpdateElectricalWorkPermitList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                
                window.location.reload();
            }).catch(err => console.log(err));
    }
    handleSubmit4 = event => {
        event.preventDefault();
        this.setState({
            month: this.state.prevMonth,
            year: this.state.longYear
        })
        this.componentDidMount();
    }
    handleChange = event => {
        
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    };


    handleChange1 = e => {
        const rows2 = [...this.state.rows2];
        let row = {
            [e.currentTarget.name]: e.currentTarget.value,

            "Status": this.state.status,
            "RegulatorId": 1,
            "SubmissionId": parseInt(this.state.id)
        }
        rows2[0] = row
        this.setState({
            rows2
        });
    };

    handleChange2 = event => {
        
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        var rows = this.state.rows;
        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };


    render() {
        return (
            <Form className="font-link" onSubmit={this.handleSubmit3} >
                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Electric Permit </h5>
                <br />
                <div id="monthfilter">
                    <FormControl>
                        <select
                            value={this.state.month}
                            name="month"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Month--</option>
                            <option value={this.state.month1} >January</option>
                            <option value={this.state.month2} >February</option>
                            <option value={this.state.month3} >March</option>
                            <option value={this.state.month4} >April</option>
                            <option value={this.state.month5} >May</option>
                            <option value={this.state.month6} >June</option>
                            <option value={this.state.month7} >July</option>
                            <option value={this.state.month8} >August</option>
                            <option value={this.state.month9} >September</option>
                            <option value={this.state.month10} >October</option>
                            <option value={this.state.month11} >November</option>
                            <option value={this.state.month12} >December</option>
                        </select>
                    </FormControl>
                    &nbsp;
                    <FormControl>
                        <select
                            value={this.state.year}
                            name="year"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Year--</option>
                            <option value={this.state.prevYear} >{this.state.prevYear}</option>
                            <option value={this.state.longYear} >{this.state.longYear}</option>
                        </select>
                    </FormControl>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
               '<button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit4} type="submit" className="btn btn-success btn-sm">CLEAR</button>
                </div>
                <br />
                <div id="status" style={{ float: 'right' }}>
                    <label style={{ fontWeight: 'bold' }}>Status:</label>
                    <FormControl>
                        <select
                            name="Status"
                            onChange={this.openModal}
                        >
                            <option selected disabled value='0'>--Pending--</option>
                            <option value={this.state.status1} >Approve</option>
                            <option value={this.state.status2} >Reject</option>
                        </select>

                    </FormControl>
                </div>
                <div id="resubmit">
                    <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit2}>RE-SUBMIT FOR APPROVAL</button>
                </div>
                <br />
                <div id="edit">
                    <button class="btn btn-outline-danger btn-sm" onClick={this.edit(1)} style={{ marginRight: '8px', display: this.isDisplayed() }} disabled={this.isDisabled()}>
                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                    </button>
                    </div>
                <Modal style={{ backgroundColor: '#ffffff00', width: '500px', borderColor: '#ffffff00' }}
                    show={this.state.isOpen} onHide={this.closeModal}>
                    <div className="App1">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label for='Remarks'>Remarks: </label>
                            <TextField type="text" name="Remarks" id="Remarks" onChange={this.handleChange1} style={{ width: '350px' }} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.handleSubmit1} style={{ marginRight: '8px' }}> Submit </Button>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
                <br />
                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                

                    {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                    <div className="App">
                        {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}


                    
                    </div>
                   


                <div key={this.state.requirementKey}>
                    <div className="col-1">
                        <br />


                        <div >
                            <label class="no-space-break">Date: </label> <br />
                        <TextField selected type="date" name="date" required id="standard-required" onChange={this.handleChange2} value={this.formatDate(this.state.rows.date)} disabled={ this.state.editable}/>
                        </div>


                    </div>
                    <div className="col-2">

                        <br />

                        <div>
                            <label class="no-space-break">Time: </label>
                            <br />
                        <TextField
                            
                                name="time"
                                onChange={this.handleChange2}
                                type="time"
                                defaultValue="hh:mm"
                            value={this.state.rows.time}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            disabled={this.state.editable}
                            />
                        </div>

                        <br />


                    </div>


                    <br />

                    <label class="no-space-break">

                    I <TextField style={{ width: '300px' }} selected type="text" name="name" required id="standard-required" onChange={this.handleChange2} value={this.state.rows.name} disabled={this.state.editable}/>
certify that the following apparatus <TextField style={{ width: '200px' }} selected type="text" name="apparatusName" required id="standard-required" onChange={this.handleChange2} value={this.state.rows.apparatusName} disabled={this.state.editable}/> has
been made dead.Is isolated from all live <br /> conductors and has been connected to earth and the work mentioned in Para (3) can now be carried out in accordance with the Safety Rules and  <br /> Regulations.  </label> <br />

                    <br />
                    <label class="no-space-break"> For the purpose of making the above apparatus dead, the following Switches/Isolators/Links/Fuses have been opened and the section so isolated has <br />been earthed at each isolation point and danger notice plates fixed thereon.  </label> <br />
                    <br />
                    <div className='col-1'>
                        <div >
                            <label class="no-space-break"> Switches: </label> <br />

                        </div>
                        <br />
                        <div >
                            <label class="no-space-break"> Isolators: </label> <br />

                        </div>
                        <br />
                        <div >
                            <label class="no-space-break"> Links: </label> <br />

                        </div>
                        <br />
                        <div >
                            <label class="no-space-break"> Fuses: </label> <br />

                        </div>
                        <br />
                    </div>

                    <div className='col-2'>
                        <div >

                        <TextField selected type="text" name="switches" required id="standard-required" onChange={this.handleChange2} value={this.state.rows.switches} disabled={this.state.editable}/>
                        </div>
                        <br />
                        <div >

                        <TextField selected type="text" name="isolators" required id="standard-required" onChange={this.handleChange2} value={this.state.rows.isolators} disabled={this.state.editable}/>
                        </div>
                        <br />
                        <div >

                        <TextField selected type="text" name="links" required id="standard-required" onChange={this.handleChange2} value={this.state.rows.links} disabled={this.state.editable}/>
                        </div>
                        <br />
                        <div >

                        <TextField selected type="text" name="fuses" required id="standard-required" onChange={this.handleChange2} value={this.state.rows.fuses} disabled={this.state.editable}/>
                        </div>
                    </div>
                    <div>
                        <br /> <br />
                        <label class="no-space-break"> Work to be carried out (testing work, if any, to be specifically mentioned): </label> <br />

                        <TextField
                            id="outlined-full-width"
                            label="Description"
                            style={{ margin: 8 }}
                            onChange={this.handleChange2}
                            name="workCarried"
                            fullWidth
                        margin="normal"
                        value={this.state.rows.workCarried}
                        disabled={this.state.editable}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                        <br /> <br />
                        <label class="no-space-break"> I have also recorded the above operations in the Log Sheet / Log Book including the instructions for the person who may relieve me. </label> <br />
                        <br />
                    <label class="no-space-break"> This permit is now being issued to<TextField style={{ width: '300px' }} selected type="text" name="permitIssuedTo" required id="standard-required" onChange={this.handleChange2} value={this.state.rows.permitIssuedTo} disabled={this.state.editable}/>(Name of the person to whom the permit is being issued) for carrying out the <br />  work  mentioned in Para (3). </label> <br />

                    </div>
                    </div>
                    <br />


                <button type="submit" className="btn btn-success" onSubmit={this.handleSubmit3} style={{ display: this.state.butdisplay, float: 'right'}}>UPDATE</button>

                    {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                </Form>
        )
    }
}

export default Electricpermitview