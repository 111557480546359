import React, { Component } from 'react'
import { Table, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import '../index.css';
import Tooltip from "react-simple-tooltip"

class Wastebytd extends Component {
    constructor(props) {
        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;
        this.state = {
            count: 0,
            rows: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, {id:9}],
           waste1: 'Hazardous I(ink, chemicals etc.)', 
           waste2: 'Hazardous II (Oil soaked cloth, bio medical waste etc)',
            waste3: 'Hazardous III (Used oil, waste oil etc)',
            waste4: 'SubTotal of Hazardous (I,II & III)', 
            waste5: 'Non Hazardous (eg Solid waste,ETP/STP sludge biodegradable waste etc)',
            waste6: 'E Waste',

            waste7: 'SubTotal of Non Hazardous', 
            waste8: 'Plastic Waste', 
      
            waste9: 'Total Waste (in tonnes)' ,
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            isOpen: false
        }

    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var wastebytd = JSON.parse(localStorage.getItem("wastebytd"));
        var rows = this.state.rows;

        if (wastebytd != null) {
            rows = wastebytd

            this.setState({
                rows
            })
        }
    }

  


    handletotal1 (idx1,idx2,idx3,idx) {
        

      
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseFloat(this.state.location);
        var utype = parseFloat(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        const rows = [...this.state.rows];
        rows[idx].QuantityGenerated = parseFloat(rows[idx1].QuantityGenerated) + parseFloat(rows[idx2].QuantityGenerated) + parseFloat(rows[idx3].QuantityGenerated)
        rows[idx].WmasterId = idx+1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = month;
        rows[idx].Year = year;
        rows[idx].ReferenceId = Reference;
        this.setState({
            rows
        });
    };


    handletotal2(idx1, idx2, idx3, idx){
           

       
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseFloat(this.state.location);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        var utype = parseFloat(this.state.utype);
        const rows = [...this.state.rows];
        rows[idx].QuantityRecycled = parseFloat(rows[idx1].QuantityRecycled) + parseFloat(rows[idx2].QuantityRecycled) + parseFloat(rows[idx3].QuantityRecycled)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = month;
        rows[idx].Year = year;
        rows[idx].ReferenceId = Reference;
        this.setState({
            rows
        });
    };


    handletotal3 (idx1, idx2, idx3, idx) {
        

        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseFloat(this.state.location);
        var utype = parseFloat(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        const rows = [...this.state.rows];
        rows[idx].Disposal = parseFloat(rows[idx1].Disposal) + parseFloat(rows[idx2].Disposal) + parseFloat(rows[idx3].Disposal)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = month;
        rows[idx].Year = year;
        rows[idx].ReferenceId = Reference;
        this.setState({
            rows
        });
    };


    handletotal4 = (idx1,idx2) => (e) => {
           

       

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseFloat(this.state.location);
        var utype = parseFloat(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        const name = e.target.name;
        if (name == "QuantityGenerated") {
            rows[idx2].QuantityGenerated = rows[idx1].QuantityGenerated
        }

        else if (name == "QuantityRecycled") {
            rows[idx2].QuantityRecycled = rows[idx1].QuantityRecycled
        }

        else {

            rows[idx2].Disposal = rows[idx1].Disposal
        }
        rows[idx2].WmasterId = idx2+1;
        rows[idx2].UserId = utype;
        rows[idx2].LocationId = location;
        rows[idx2].Month = month;
        rows[idx2].Year = year;
        rows[idx2].ReferenceId = Reference;
        this.setState({
            rows
        });


        this.handletotal5(3, 5, 6, 7, 8);
        this.handletotal6(3, 5, 6, 7, 8);
        this.handletotal7(3, 5, 6, 7, 8);
    };
    handletotal5 (idx1, idx2, idx3, idx4,idx) {
                    

       

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseFloat(this.state.location);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        var utype = parseFloat(this.state.utype);
        rows[idx].QuantityGenerated = parseFloat(rows[idx1].QuantityGenerated) + parseFloat(rows[idx2].QuantityGenerated) + parseFloat(rows[idx3].QuantityGenerated)+ parseFloat(rows[idx4].QuantityGenerated)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = month;
        rows[idx].Year = year;
        rows[idx].ReferenceId = Reference;
        this.setState({
            rows
        });
    };
    handletotal6 (idx1, idx2, idx3, idx4,idx) {
                    
      

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseFloat(this.state.location);
        var utype = parseFloat(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        rows[idx].QuantityRecycled = parseFloat(rows[idx1].QuantityRecycled) + parseFloat(rows[idx2].QuantityRecycled) + parseFloat(rows[idx3].QuantityRecycled) + parseFloat(rows[idx4].QuantityRecycled)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = month;
        rows[idx].Year = year;
        rows[idx].ReferenceId = Reference;
        this.setState({
            rows
        });
    };

    handletotal7(idx1, idx2, idx3, idx4,idx)  {

      

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseFloat(this.state.location);
        var utype = parseFloat(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        rows[idx].Disposal = parseFloat(rows[idx1].Disposal) + parseFloat(rows[idx2].Disposal) + parseFloat(rows[idx3].Disposal) + parseFloat(rows[idx4].Disposal)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = month;
        rows[idx].Year = year;
        rows[idx].ReferenceId = Reference;
        this.setState({
            rows
        });
    };

    handleChange = (idx) => (e) => {
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        //let id=name+(idx);
        const value = parseFloat(e.target.value);
        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        //var year = 0

        //var prevYear = this.state.prevYear;
        //if (prevMonth == "December") {
        //    year = prevYear
        //}
        //else {
        //    year = longYear
        //}
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "WmasterId": idx+1,
            "Year": year,
            "Month": month,
            "LocationId": location,
            "UserId": utype,
            "ReferenceId":Reference
       
        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };

    isDisabled1() {

        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }

    openModal = (e) => {


        this.setState({ isOpen: true }, () => {
            window.setTimeout(() => {
                this.setState({ isOpen: false })
            }, 2000)
        });

    }

    closeModal = () => this.setState({ isOpen: false });

    handleAddRow = () => (event) => {
        
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }



    handleSubmit = event => {
        event.preventDefault();
        debugger;
        const { rows } = this.state;
        //var prevMonth = this.state.prevMonth;
        //var year = 0;
        //var longYear = this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year = longYear
        //}
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');
        var id = localStorage.getItem('utype')
        //axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WasteByTd/GetWasteByTdByMonth', { params: { Month: month, Year: year, UserId: id } })
        //    .then(response => {
                
        //        if (response.data.length == 0) {


                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    localStorage.setItem('wastebytd', Options.body);
                    let newbody = JSON.parse(Options.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SaveWasteByTdtemp', newbody).then(response => {

                        if (response.data == 1) {
                            this.openModal()
                        }

                        else {

                            alert("Data Not Saved.Kindly Save Again")
                        }

                    })
            //    }
            //    else {

            //        alert("Data Already Submitted")
            //    }

            //})
       
        /* let newbody = JSON.parse(Options.body);
         axios.post("http://localhost:23013/api/TotalWaterWithdrawn/SaveTotalWaterWithdrawn", newbody)
             .then(response => {
                 if (response.data.Status === 'Success') {
                     alert("Data Save Successfully");
                     window.location.reload();
                     console.log(response.data.Status);
 
                 }
 
                 else {
                     alert('Data Saved Successfully');
                     window.location.reload();
                 }
 
             })
 
             //  .then(data => {
             // let typesapi = Object.keys(data.type);
             //console.log(typesapi);
 
 
 
             //    })
             .catch(error => {
                 console.log(error);
 
             });*/



    }    
    




    render() {
        const text = "Total Amount of Waste by Type and Disposal Method -Facility should have proper records on tracking\nof waste sold for recycling. Disposal method refers to the method by which waste is treated\nor disposed which includes the recycle / reuse, composting, incineration and landfill.\nBio-medical waste is defined as per the Biomedical Waste (management & handling) Rules 1998.\nHazardous wastes are those which come within the purview of Hazardous Wastes (Management,\nHandling and Transboundary Movement)Rules 2016."
        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>
                <br />
                {/* <button onclick={this.handleaddrow()} style={{ float: 'right' }} classname="btn btn-info btn-md">+add</button> */}
                <div className="wordalign">
                <label>
                    {this.state.longMonth}   &nbsp;
                   {this.state.longYear}
  &nbsp;&nbsp;
                  <Tooltip
                        arrow={15}
                        background="#ffffff"
                        border="black"
                        color="black"
                        content={text.split("\n").map((i, key) => {

                            return <div key={key}>{i}</div>;


                        })}
                        fadeDuration={0}
                        fadeEasing="linear"
                        fixed={false}
                        fontFamily="Bw Glenn Sans Medium"
                        fontSize="12px"
                        offset={-20}
                        padding={15}

                        placement="right"
                        radius={50}
                        zIndex={1}

                    >
                        <span className="btn btn-outline-danger btn-sm">

                            info
                               {/*<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                        </span>
                    </Tooltip>

                    </label>
                </div>
                <Modal style={{ backgroundColor: 'white', width: '500px', height: '200px', borderColor: '#ffffff00' }}

                    show={this.state.isOpen} onHide={this.closeModal}>

                    <Modal.Header style={{ backgroundColor: '#001946', color: 'white' }} >
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Data Saved Successfully

                          </Modal.Body>
                </Modal>
                <div class="shadow1">
                   
                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                <th>S.NO</th>
                                <th>WASTE CATEGORY</th>
                                <th>QUANTITY GENERATED</th>
                                <th>RECYCLE/REUSE</th>
                                <th>DISPOSAL</th>
                                {/*  <th>ACTION</th> */}
                            </tr>
                        </thead>
                        <tbody>
                           
                            <tr>
                                <td>1</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste1}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityGenerated" required id="standard-required" onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal1(0, 1, 2, 3); this.handletotal5(3, 5, 6, 7, 8) }} value={this.state.rows[0].QuantityGenerated}/>
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityRecycled" required id="standard-required" onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal2(0, 1, 2, 3); this.handletotal6(3, 5, 6, 7, 8) }} value={this.state.rows[0].QuantityRecycled}/> 
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="Disposal" required id="standard-required" onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal3(0, 1, 2, 3); this.handletotal7(3, 5, 6, 7, 8) }} value={this.state.rows[0].Disposal}/>
                                </td>  
                             
                               
                            </tr>
                            
                            <tr>
                                <td>2</td>
                                <td >
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste2}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="QuantityGenerated" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal1(0, 1, 2, 3); this.handletotal5(3, 5, 6, 7, 8) }} value={this.state.rows[1].QuantityGenerated}/>
                                </td>

                                <td>
                                    <TextField type="number" name="QuantityRecycled" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal2(0, 1, 2, 3); this.handletotal6(3, 5, 6, 7, 8) }} value={this.state.rows[1].QuantityRecycled}/>
                                </td>
                                <td>
                                    <TextField type="number" name="Disposal" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal3(0, 1, 2, 3); this.handletotal7(3, 5, 6, 7, 8) }} value={this.state.rows[1].Disposal}/>
                                </td>  
                        
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste3}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityGenerated" required id="standard-required" onChange={this.handleChange(2)} onKeyUp={() => { this.handletotal1(0, 1, 2, 3); this.handletotal5(3, 5, 6, 7, 8) }} value={this.state.rows[2].QuantityGenerated}/>
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityRecycled" required id="standard-required" onChange={this.handleChange(2)} onKeyUp={() => { this.handletotal2(0, 1, 2, 3); this.handletotal6(3, 5, 6, 7, 8) }} value={this.state.rows[2].QuantityRecycled}/>
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="Disposal" required id="standard-required" onChange={this.handleChange(2)} onKeyUp={() => { this.handletotal3(0, 1, 2, 3); this.handletotal7(3, 5, 6, 7, 8) }} value={this.state.rows[2].Disposal}/>
                                </td>
                                
                            </tr>
                           
                            <tr>
                                <td>4</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste4}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} name="QuantityGenerated" required id="standard-required" value={this.state.rows[3].QuantityGenerated} />
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} name="QuantityRecycled" required id="standard-required" value={this.state.rows[3].QuantityRecycled} />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" name="Disposal" value={this.state.rows[3].Disposal} />
                                </td>
                           
                            </tr>
                            <tr class="table-info">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>

                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste5}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityGenerated" required id="standard-required" onChange={this.handleChange(4)} onKeyUp={this.handletotal4(4, 6)} value={this.state.rows[4].QuantityGenerated}/>
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityRecycled" required id="standard-required" onChange={this.handleChange(4)} onKeyUp={this.handletotal4(4, 6)} value={this.state.rows[4].QuantityRecycled}/>
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="Disposal" required id="standard-required" onChange={this.handleChange(4)} onKeyUp={this.handletotal4(4, 6)} value={this.state.rows[4].Disposal}/>
                                </td>
                              
                            </tr>
                            
                            <tr>
                                <td>6</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                       name="Waste"
                                        defaultValue={this.state.waste6}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityGenerated" required id="standard-required" onChange={this.handleChange(5)} onKeyUp={() => { this.handletotal1(0, 1, 2, 3); this.handletotal5(3, 5, 6, 7, 8) }} value={this.state.rows[5].QuantityGenerated}/>
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityRecycled" required id="standard-required" onChange={this.handleChange(5)} onKeyUp={() => { this.handletotal2(0, 1, 2, 3); this.handletotal6(3, 5, 6, 7, 8) }} value={this.state.rows[5].QuantityRecycled}/>
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="Disposal" required id="standard-required" onChange={this.handleChange(5)} onKeyUp={() => { this.handletotal3(0, 1, 2, 3); this.handletotal7(3, 5, 6, 7, 8) }} value={this.state.rows[5].Disposal}/>
                                </td>
                              
                            </tr>
                            <tr class="table-info">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>

                            </tr>
                            <tr>
                                <td>7</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste7}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} name="QuantityGenerated" readonly="readonly" required id="standard-required" value={this.state.rows[6].QuantityGenerated}/>
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} name="QuantityRecycled" readonly="readonly" required id="standard-required" value={this.state.rows[6].QuantityRecycled} />
                                </td>
                                <td>
                                    <TextField type="number" name="Disposal" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[6].Disposal} />
                                </td>
                               
                              
                            </tr>
                            <tr class="table-info">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>

                            </tr>
                            <tr>
                                <td>8</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste8}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityGenerated" required id="standard-required" onChange={this.handleChange(7)} onKeyUp={() => this.handletotal5(3, 5, 6, 7, 8)} value={this.state.rows[7].QuantityGenerated}/>
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="QuantityRecycled" required id="standard-required" onChange={this.handleChange(7)} onKeyUp={() => this.handletotal6(3, 5, 6, 7, 8)} value={this.state.rows[7].QuantityRecycled}/>
                                </td>
                            <td>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="Disposal" required id="standard-required" onChange={this.handleChange(7)} onKeyUp={() => this.handletotal7(3, 5, 6, 7, 8)} value={this.state.rows[7].Disposal} />
                                </td>
                             
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste9}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[8].QuantityGenerated}/>
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[8].QuantityRecycled}/>
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }}  required id="standard-required" value={this.state.rows[8].Disposal}  />
                                </td>

                            </tr>

                            {/*  {this.renderRows()} */}

                        </tbody>
                    </Table>
                </div >
                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit} disabled={this.isDisabled1()}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Wastebytd