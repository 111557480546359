import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editinjuredemployee extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {

            count: 0,
            
            type1: 'Male',
            type2: 'Female',
            type3: 'Abrasion,scrapes',
            type4: 'Amputation',
            type5: 'Broken bone',
            type6: 'Bruise',
            type7: 'Burn(heat)',
            type8: 'Burn(chemical)',
            type9: 'Concussion(head)',
            type10: 'Crushing Injury',
            type11: 'Cut/Laceration/Puncture',
            type12: 'Hernia',
            type13: 'Illness',
            type14: 'Sprain/Strain',
            type15: 'Body Part Damage',
            type16: 'Others',
            type17: 'Regular Full Time',
            type18: 'Regular Part Time',
            type19: 'Seasonal',
            type20: 'Temporary',
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: ''


        }
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/InjuredEmployee/GetInjuredEmployeeById', { params: { InjuredId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0]


            });

        });


    }









    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name
        var value = 0;
        if (name == "age" || name == "monthsEmployer" || name == "monthsJob") {
            value = parseInt(e.target.value)
        }
        else {
            value = e.target.value

        }
        var rows = this.state.rows;

        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };



    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/InjuredEmployee/UpdateInjuredEmployeeList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/incidentinvestigationview')
            }).catch(err => console.log(err));


    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Edit Injured Employee </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Name">
                                    <label class="no-space-break">Name</label>
                                    <input name="name" type="text" class="form-control" value={this.state.rows.name} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Department">
                                    <label class="no-space-break">Department</label>
                                    <input name="department" type="text" class="form-control" value={this.state.rows.department} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>
                        



                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Sex">
                                    <label class="no-space-break">Sex </label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.sex} name="sex">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Male</option>
                                        <option value={this.state.type2} >Female</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="no-space-break">Job Title</label>
                                <div class="input-group">


                                    <input required name="jobTitle" class="form-control" type="text" value={this.state.rows.jobTitle} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>





                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Age">
                                    <label class="no-space-break">Age</label>
                                    <input name="age" type="number" class="form-control" value={this.state.rows.age} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="MonthsEmployer">
                                    <label class="no-space-break">Months With The Employer</label>
                                    <input name="monthsEmployer" type="number" class="form-control" value={this.state.rows.monthsEmployer} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="EmployeeType">
                                    <label class="no-space-break">Employee Type </label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.employeeType} name="employeeType">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type17} >Regular Full Time</option>
                                        <option value={this.state.type18} >Regular Part Time</option>
                                        <option value={this.state.type19} >Seasonal</option>
                                        <option value={this.state.type20} >Temporary</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="InjuryNature">
                                    <label class="no-space-break">Nature Of Injury </label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.injuryNature} name="injuryNature">
                                        <option disabled selected value="0" >--Select--</option>
                                        <option value={this.state.type3} >Abrasion,scrapes</option>
                                        <option value={this.state.type4} >Amputation</option>
                                        <option value={this.state.type5} >Broken Bone</option>
                                        <option value={this.state.type6} >Bruise</option>
                                        <option value={this.state.type7} >Burn(heat)</option>
                                        <option value={this.state.type8} >Burn(chemical)</option>
                                        <option value={this.state.type9} >Concussion(To the Head)</option>
                                        <option value={this.state.type10} >Crushing Injury</option>
                                        <option value={this.state.type11} >Cut/Laceration/Puncture</option>
                                        <option value={this.state.type12} >Hernia</option>
                                        <option value={this.state.type13} >Illness</option>
                                        <option value={this.state.type14} >Sprain/Strain</option>
                                        <option value={this.state.type15} >Damage To Body System</option>
                                        <option value={this.state.type16} >Others</option>


                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            

                            <div class="mb-3 col-md-6">
                                <div id="MonthsJob">
                                    <label class="no-space-break">Months During The Job</label>
                                    <input name="monthsJob" type="number" class="form-control" value={this.state.rows.monthsJob} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editinjuredemployee