import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';



class Aspectimpact extends Component {

    constructor(props) {
        super(props)

        this.handletotal = this.handletotal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
            types: [],
            hasError: false,
            selected: null,
            unit: '',
            validationError: "",
            count: 0,
            rows: [{ id: 0 }],
            login: [],
            con1:'N',
            con2:'A',
            con3:'E',
            imp1:'AP',
            imp2:'WP',
            leg1:'HW',
            leg2:'WG',
            leg3:'NU',
            leg4:'DOR',
            leg5:'GI',
            pro1: 1,
            pro2: 2,
            pro3: 3,
            pro4: 4,
            pro5: 5,
            pro6: 6,
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            check: ""
        }
    }

    componentDidMount() {

        document.getElementById("navribbon").style.display = "none";
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalRawMaterial/GetType')
            .then(response => {
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi

                });
                // ucode=localStorage.getItem('username'),
                // utype=localStorage.getItem('utype'),
                // location=localStorage.getItem('location')

            })
            //  .then(data => {
            // let typesapi = Object.keys(data.type);
            //console.log(typesapi);



            //    })
            .catch(error => {
                console.log(error);

            });
    }



    handleChange = (idx) => (e) => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        var value = 0;
        //let id=name+(idx);
     
        if (name == "RmasterId" || name == "QtyConsumed" || name == "QtyRecycled") {
            value = parseInt(e.target.value);

        }
        else {
            value = e.target.value;
        }
        const rows = [...this.state.rows];
        var utype = parseInt(this.state.utype)
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "UserId": utype

        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };




    handletotal = (idx) => (e) => {

        
        e.preventDefault();

        const rows = [...this.state.rows];
        rows[idx].overall = parseInt(rows[idx].Quantity) * parseInt(rows[idx].Frequency) * parseInt(rows[idx].Environment)


        this.setState({
            rows
        });
    };
    // handleTypeChange = event => {
    //   
    //   this.setState({
    //   type1: event.target.value,

    //     })
    //    }



    handleAddRow = () => (event) => {
        this.setState({ selected: null });
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count,
            unit: ''
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]


        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => (e) => {

        e.preventDefault();
        let count = this.state.count
        count = count - 1;
        this.setState({ count })
        let rowarr = [...this.state.rows];
        let deletedItem = rowarr.findIndex(item => item.id === idx);
        rowarr.splice(deletedItem, 1);
        this.setState({ ...this.state.rows = rowarr })

        // let row = [...this.state.rows.filter(x=>x.id!==idx)];
        // this.setState({rows:row})




        //  const rows = [...this.state.rows]
        //   rows.splice(idx,1)
        //   this.setState({ rows })
        //   e.preventDefault()
        //  var newrows=this.state.rows.slice();
        //  newrows.splice(idx,1);
        //  this.setState({ rows:newrows })

        // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
        //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
        //  this.setState({
        //    rows: newRows,
        //  });

    }
    getIndex(value) {

        let arr = this.state.types;
        let prop = 'rmasterId'
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }

    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = event => {

        event.preventDefault();
        

        if (!this.state.selected) {
            this.setState({ hasError: true });
            return
        }
        const { rows } = this.state;
        const { login } = this.state;
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        //  let newbody = JSON.parse(Options.body);

        localStorage.setItem('rawmaterial', Options.body);
        alert("Data Saved Successfully");
        /*  const Options1 = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(login)
          };
          let checkbody = JSON.parse(Options1.body);
          axios.all([
              axios.post('https://localhost:23013/api/TotalRawMaterial/SaveTotalRawMaterials', newbody),
  
              axios.post('https://localhost:23013/api/UserSubmission/SaveUserSubmission', checkbody)
  
          ]).then(axios.spread((response1, response2) => {
  
              if (response1.data.Status === 'Success') {
                  alert("Data Save Successfully");
                  window.location.reload();
                  console.log(response1.data.Status);
  
              }
  
              else {
                  alert('Data Saved Successfully');
                  window.location.reload();
              }
          }));*/


    }

    renderRows() {
        const { hasError } = this.state;
        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}>


                    {/* <td >
                        {item.id+1}
                    </td> */}
                    <td>
                        <TextField style={{width:'150px'}} type="text" name="Activity" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td >
                    <td>
                        <TextField style={{ width: '150px' }} type="text" name="Aspect" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td >
                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="Conditions"
                                onChange={this.handleChange(idx)}
                             

                            >
                                <MenuItem value={this.state.con1} >N</MenuItem>
                                <MenuItem value={this.state.con2} >A</MenuItem>
                                <MenuItem value={this.state.con3} >E</MenuItem>
                                
                               
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="Impact"
                                onChange={this.handleChange(idx)}
                              

                            >
                                <MenuItem value={this.state.imp1} >AP</MenuItem>
                                <MenuItem value={this.state.imp2} >WP</MenuItem>
                               

                         
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="Quantity"
                                onChange={this.handleChange(idx)}
                                onBlur={this.handletotal(idx)}

                            >
                                <MenuItem value={this.state.pro1} >1</MenuItem>
                                <MenuItem value={this.state.pro2} >2</MenuItem>
                                <MenuItem value={this.state.pro3} >3</MenuItem>



                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="Frequency"
                                onChange={this.handleChange(idx)}
                                onBlur={this.handletotal(idx)}

                            >
                                <MenuItem value={this.state.pro1} >1</MenuItem>
                                <MenuItem value={this.state.pro2} >2</MenuItem>
                                <MenuItem value={this.state.pro3} >3</MenuItem>
                              
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="Environment"
                                onChange={this.handleChange(idx)}
                                onBlur={this.handletotal(idx)}
                            >
                                <MenuItem value={this.state.pro1} >1</MenuItem>
                                <MenuItem value={this.state.pro2} >2</MenuItem>
                                <MenuItem value={this.state.pro3} >3</MenuItem>
                               

                                {/*   <MenuItem value={this.state.row1.type1} >Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem> */}
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <TextField style={{width:'40px' }}InputProps={{ readOnly: true }} selected type="number" name="Overall" required id="standard-required" value={this.state.rows[idx].overall} onKeyUp={this.handletotal(idx)} onClick={this.handletotal(idx)} />
                    </td >


                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="Legislative"
                                onChange={this.handleChange(idx)}
                                
                            >
                                <MenuItem value={this.state.leg1} >HW</MenuItem>
                                <MenuItem value={this.state.leg2} >WG</MenuItem>
                                <MenuItem value={this.state.leg3} >NU</MenuItem>
                                <MenuItem value={this.state.leg4} >DOR</MenuItem>
                                <MenuItem value={this.state.leg5} >GI</MenuItem>


                                {/*   <MenuItem value={this.state.row1.type1} >Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem> */}
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <TextField type="text" name="Control" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td>
                    <td>
                        <TextField type="text" name="Rating" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td>
                    <td>
                        <TextField type="text" name="Significance" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td>
                    <td>
                        <TextField type="text" name="Additional" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td>
                    <td>
                        <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx)}>
                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button>

                    </td>
                </tr>

            );
        });
    }




    render() {
        const text = "Normal-N\nAbnormal-A\nEmergency-E"
        const text1 = "Air emissions-AP\n Effluent generation spillage-WP"
        const text2 = "Negligible-1\n Moderate-2\n Excessive-3"
        const text3 = "Rare-1\n Sometimes-2\n Continuous-3"
        const text4 = "Slight impact on nuisance-1\n Depletion of natural resources/moderate impact-2\n Fatal or global issue-3"
        const { hasError } = this.state;
        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>
                <br />
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Aspect and Impact </h5>

              
                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    <div className="wordalign">
                        {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                        <label>
                            {this.state.longMonth}   &nbsp;
                   {this.state.longYear}

                        </label>



                    </div>
                </div>
                <br />
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white",overflowX:'scroll',display:'block',overflowY:'hidden',whiteSpace:'pre'}}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                            <th >ACTIVITY</th>
                            <th>ASPECT</th>
                            <th style={{ width: '130px',height:'65px',whiteSpace:'nowrap' ,overflowWrap:'break-word'}}>CONDITIONS &nbsp;
                              <Tooltip
                                    arrow={15}
                                    background="#ffffff"
                                    border="black"
                                    color="black"
                                    className="mytooltip"
                                    content={text.split("\n").map((i, key) => {

                                        return <div key={key}>{i}</div>;


                                    })}
                                    fadeDuration={0}
                                    fadeEasing="linear"
                                    fixed={false}
                                    fontFamily="Bw Glenn Sans Medium"
                                    fontSize="12px"
                                    offset={-20}
                                    padding={15}

                                    placement="right"
                                    radius={50}
                                    zIndex={1}

                                >
                                    <span >
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>

                                </Tooltip>
                                </th>
                            <th style={{ width: '130px', height: '65px', whiteSpace: 'nowrap', overflowWrap: 'break-word' }}>IMPACT &nbsp;
                              <Tooltip
                                    arrow={15}
                                    background="#ffffff"
                                    border="black"
                                    color="black"
                                    className="mytooltip"
                                    content={text1.split("\n").map((i, key) => {

                                        return <div key={key}>{i}</div>;


                                    })}
                                    fadeDuration={0}
                                    fadeEasing="linear"
                                    fixed={false}
                                    fontFamily="Bw Glenn Sans Medium"
                                    fontSize="12px"
                                    offset={-20}
                                    padding={15}

                                    placement="right"
                                    radius={50}
                                    zIndex={1}

                                >
                                    <span >
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>

                                </Tooltip>
                                </th>
                           
                            <th style={{ width: '130px', height: '65px', whiteSpace: 'nowrap', overflowWrap: 'break-word' }} >Q &nbsp;
                            <Tooltip
                                    arrow={15}
                                    background="#ffffff"
                                    border="black"
                                    color="black"
                                    className="mytooltip"
                                    content={text2.split("\n").map((i, key) => {

                                        return <div key={key}>{i}</div>;


                                    })}
                                    fadeDuration={0}
                                    fadeEasing="linear"
                                    fixed={false}
                                    fontFamily="Bw Glenn Sans Medium"
                                    fontSize="12px"
                                    offset={-20}
                                    padding={15}

                                    placement="right"
                                    radius={50}
                                    zIndex={1}

                                >
                                    <span >
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>

                                </Tooltip>
                                </th >
                            <th style={{ width: '130px', height: '65px', whiteSpace: 'nowrap', overflowWrap: 'break-word' }}>F &nbsp;
                                <Tooltip
                                    arrow={15}
                                    background="#ffffff"
                                    border="black"
                                    color="black"
                                    className="mytooltip"
                                    content={text3.split("\n").map((i, key) => {

                                        return <div key={key}>{i}</div>;


                                    })}
                                    fadeDuration={0}
                                    fadeEasing="linear"
                                    fixed={false}
                                    fontFamily="Bw Glenn Sans Medium"
                                    fontSize="12px"
                                    offset={-20}
                                    padding={15}

                                    placement="right"
                                    radius={50}
                                    zIndex={1}

                                >
                                    <span >
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>

                                </Tooltip>
                                </th>
                            <th style={{ width: '130px', height: '65px', whiteSpace: 'nowrap', overflowWrap: 'break-word' }}>EI &nbsp;
                              <Tooltip
                                    arrow={15}
                                    background="#ffffff"
                                    border="black"
                                    color="black"
                                    className="mytooltip"
                                    content={text4.split("\n").map((i, key) => {

                                        return <div key={key}>{i}</div>;


                                    })}
                                    fadeDuration={0}
                                    fadeEasing="linear"
                                    fixed={false}
                                    fontFamily="Bw Glenn Sans Medium"
                                    fontSize="12px"
                                    offset={-20}
                                    padding={15}

                                    placement="right"
                                    radius={50}
                                    zIndex={1}

                                >
                                    <span >
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>

                                </Tooltip>
                                </th>
                            <th>OI </th>
                            <th>L</th>
                            <th>CONTROL MEASURES </th>
                            <th>RATING</th>
                            <th>SIGNIFICANCE</th>
                            <th>ADDITIONAL CONTROL</th>
                            <th>ACTION</th>




                        </tr>
                    </thead>
                    <tbody>

                        {/* {this.state.rows.map((item, idx) => (
      
        
        <tr id="addr0" key={item.id}>
       
         <td>{idx}</td> 
        
        <td>  
        <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="type"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.type1}>Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem>
        </Select>
       
      </FormControl> </td>
       
       <td>
       <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="unit"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.unit1}>Metric Ton</MenuItem>
          <MenuItem value={this.state.row2.unit2}>Kgs</MenuItem>
          <MenuItem value={this.state.row3.unit3}>Ltr</MenuItem>
          <MenuItem value={this.state.row4.unit4}>Nos</MenuItem>
        </Select>
       
      </FormControl>
       </td>

       <td>
       <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="consumed"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.consumed1}>Value</MenuItem>
          
        </Select>
       
      </FormControl>
       </td >
       <td>
       <TextField   name="recycled" required id="standard-required"  onChange={this.handleChange(idx)} defaultValue={this.state.rows.recycle}/>
       </td>    
       <td>
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx)}>
<svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>                          
                        </button>
                        
                      </td>
                      
    </tr>
    
       
   ))}
        */}
                        {this.renderRows()}

                    </tbody>
                </Table>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>
                <button onClick={this.handleAddRow()} style={{ float: 'right', marginRight: '5px' }} className="btn btn-info btn-md">+ADD</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Aspectimpact