import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import Collapse from "@kunukn/react-collapse";
import axios from 'axios';
import '../index.css';
import "./app.scss";
import cx from "classnames";

class Accidentstatistics extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            types: [],
            types1: [],
            unit: '',
            validationError: "",
            count: 0,
            program1: 'Fire Safety Training',
            program2: 'EHS Awareness Training',
            program3: 'Safety at Work',
            program4: 'Others',
            type1: 'Employees',
            type2: 'Contract Employees',
            type3: 'Contractors',
            type4: 'Internal',
            type5: 'External',
            rows: [{ id: 0 }],
            rows1: [{ id: 0 }],
            rows2: [{ id: 0 }],
            rows3: [{ id: 0 }],
            rows4: [],
            rows5: [{ id: 0 }],
            rows6:[],
            rows7:[],
            rows8:[],
            rows9:[],
            rows10:[],
            login: [],
            total: [],
            total1: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear:prevYear,
            admincode: localStorage.getItem('admincode')
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin ==null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        var year = this.state.longYear;
        var location = this.state.location
        localStorage.setItem('homepage', false);
        document.getElementById("navribbon").style.display = "none";
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatistics/GetAccidentStatistics', { params: { Year: year, LocationId: location } })
            .then(response => {
                var totalvalue = 0;
                var total = [];
                for (var i = 1; i < 11; i++) {
                    let rows = response.data.filter(x => x.smasterId == i);
                    for (var j = 0; j < rows.length; j++) {
                        totalvalue = totalvalue + rows[j].value
                    }
                    if (totalvalue == 0) {
                        total.push(0);
                    }
                    else {
                        total.push(totalvalue);
                        totalvalue = 0
                    }
                }
                this.setState({
                    total: total
                })
            })
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatisticsContractors/GetAccidentContractors', { params: { Year: year, LocationId: location } })
            .then(response => {
                var totalvalue = 0;
                var total1 = [];
                for (var i = 1; i < 11; i++) {
                    let rows = response.data.filter(x => x.scMasterId == i);
                    for (var j = 0; j < rows.length; j++) {

                        totalvalue = totalvalue + rows[j].value
                    }
                    if (totalvalue == 0) {
                        total1.push(0);
                    }
                    else {
                        total1.push(totalvalue);
                        totalvalue = 0
                    }
                }
                this.setState({
                    total1: total1
                })
            })
    }



    handleChange = e => {
        e.preventDefault();
        const name = parseInt(e.target.name);
        var value = parseInt(e.target.value);
        const rows = [...this.state.rows];
        const rows4 = [...this.state.rows4];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)
        var index = name - 1;
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows[index],
            "Value": value,
            "SMasterId": name,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[index] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows4[0],
            "FormId": 6,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode
        };
        rows4[0] = row1;
        this.setState({
            rows4
        });
    };



    handleChange1 = (idx) => (e) => {
        e.preventDefault();
        const name = e.target.name;
        var value = 0;
       if (name == "Participants") {
            value = parseInt(e.target.value);
        }
        else {
            value = e.target.value;
        }
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows1[idx],
            id: idx,
            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows1[idx] = row;
        this.setState({
            rows1
        });
    };

    handleChange2 = e => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        var rows2 = this.state.rows2;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows2[0],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows2[0] = row;
        this.setState({
            rows2
        });
    };


    handleChange3 = e => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        var rows3 = this.state.rows3;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows3[0],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows3[0] = row;
        this.setState({
            rows3
        });
    };


    handleChange4 = e => {
        e.preventDefault();
        const name = parseInt(e.target.name);
        var value = parseInt(e.target.value);
        const rows5 = [...this.state.rows5];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var index = name - 1;
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows5[index],

            "Value": value,
            "ScMasterId": name,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows5[index] = row;
        this.setState({
            rows5
        });
    };

    handleAddRow = () => (event) => {
        let count = this.state.count
        count = count + 1;
        this.setState({ count })
        // this.setState((prevState) => ({ count: prevState.count + '1'}));
        const item = {
            id: count
        };
        event.preventDefault();
        this.setState({
            rows1: [...this.state.rows1, item]

        });
    };
    
    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }


    handleSubmit9 = (event) => {
        this.props.history.push("/");
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { rows } = this.state;
        const { rows1 } = this.state;
        const { rows2 } = this.state;
        const { rows3 } = this.state;
        const { rows4 } = this.state;
        const { rows5 } = this.state;

        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
       await axios.all([
           axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatistics/GetAccidentStatisticsByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
           axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Awards/GetAwardsByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
           axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTrainingProgram/GetTrainingProgramByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
           axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Initiatives/GetInitiativesByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
           axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatisticsContractors/GetAccidentContractorsByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
        ]).then(axios.spread((response1, response2, response3, response4, response5) => {
            this.setState({
                rows6: response1.data,
                rows7: response2.data,
                rows8: response3.data,
                rows9: response4.data,
                rows10: response5.data
            })
        }))



      

        var rows6 = this.state.rows6
        var rows7 = this.state.rows7
        var rows8 = this.state.rows8
        var rows9 = this.state.rows9
        var rows10 = this.state.rows10
        if (rows6.length == 0 || rows7.length == 0 || rows8.length == 0 || rows9.length == 0 || rows10.length==0) {

            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            let newbody = JSON.parse(Options.body);

            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let newbody1 = JSON.parse(Options1.body);

            const Options2 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows2)
            };
            let newbody2 = JSON.parse(Options2.body);
            const Options3 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows3)
            };
            let newbody3 = JSON.parse(Options3.body);

            const Options4 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows4)
            };
            let checkbody = JSON.parse(Options4.body);

            const Options5 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows5)
            };
            let newbody4 = JSON.parse(Options5.body);
            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatistics/SaveAccidentStatisticsList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatisticsContractors/SaveAccidentContractorsList', newbody4),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTrainingProgram/SaveTrainingProgramDetails', newbody1),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/Awards/SaveAwardDetails', newbody2),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/Initiatives/SaveInitiativeDetails', newbody3),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

            ]).then(axios.spread((response1) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }


       

    }


    toggle = (index) => {
        let collapse = "isOpen" + index;
        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }

    handleClick1 = () => (event) => {
        this.props.history.push("/monthlyehsview");
    };


    render() {
        return (
            <div className="app">
                <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                <br />
                <h5 style={{ backgroundColor: '#001946', width: '1100px', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Monthly EHS Report </h5>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen1
                    })}
                    onClick={() => this.toggle(1)}
                >
                    <span className="app__toggle-text">Accident Statistics(Employees)</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen1}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen1 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>DETAILS</th>
                                    <th>UNIT</th>
                                    <th>THIS MONTH</th>
                                    <th>THIS YEAR</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr id="addr0" key={0}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='First Aid Injury' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="1" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total[0]} />
                                    </td>
                                </tr>

                                <tr id="addr1" key={1}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Minor Injury' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="2" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total[1]} />
                                    </td>

                                </tr>
                                <tr id="addr2" key={2}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Lost Time Injury' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="3" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }}name="year" required id="standard-required" value={this.state.total[2]} />
                                    </td>
                                </tr>
                                <tr id="addr3" key={3}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Man-Hours Lost' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Hrs.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="4" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total[3]} />
                                    </td>
                                </tr>
                                <tr id="addr4" key={4}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Man-Hours Worked' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Hrs.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="5" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }}  name="year" required id="standard-required" value={this.state.total[4]} />
                                    </td>
                                </tr>
                                <tr id="addr5" key={5}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Near Miss Reported' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="6" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }}  name="year" required id="standard-required" value={this.state.total[5]} />
                                    </td>
                                </tr>
                                <tr id="addr6" key={6}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Total Employees' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="7" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total[6]} />
                                    </td>

                                </tr>
                                <tr id="addr7" key={7}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Serious Accidents' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="8" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total[7]} />
                                    </td>
                                </tr>
                                <tr id="addr8" key={8}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Fatal Accidents' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="9" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total[8]} />
                                    </td>
                                </tr>
                                <tr id="addr9" key={9}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Training Hours' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Hrs.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="10" required id="standard-required" onChange={this.handleChange} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total[9]} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Form>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen5
                    })}
                    onClick={() => this.toggle(5)}
                >
                    <span className="app__toggle-text">Accident Statistics(Contractors)</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen5 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen5}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen5 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" onSubmit={this.handleSubmit}>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>DETAILS</th>
                                    <th>UNIT</th>
                                    <th>THIS MONTH</th>
                                    <th>THIS YEAR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="addr0" key={0}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='First Aid Injury' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="1" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[0]} />
                                    </td>

                                </tr>
                                <tr id="addr1" key={1}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Minor Injury' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="2" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[1]} />
                                    </td>
                                </tr>

                                <tr id="addr2" key={2}>
                                   <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Lost Time Injury' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="3" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[2]} />
                                    </td>

                                </tr>
                                <tr id="addr3" key={3}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Man-Hours Lost' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Hrs.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="4" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[3]} />
                                    </td>

                                </tr>
                                <tr id="addr4" key={4}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Man-Hours Worked' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Hrs.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="5" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[4]} />
                                    </td>
                                </tr>
                                <tr id="addr5" key={5}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Near Miss Reported' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="6" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[5]} />
                                    </td>
                                </tr>
                                <tr id="addr6" key={6}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Total Contractors' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="7" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[6]} />
                                    </td>
                                </tr>
                                <tr id="addr7" key={7}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Serious Accidents' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="8" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[7]} />
                                    </td>
                                </tr>
                                <tr id="addr8" key={8}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Fatal Accidents' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Nos.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="9" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[8]} />
                                    </td>
                                </tr>
                                <tr id="addr9" key={9}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '300px' }} selected type="text" name="details" required id="standard-required" defaultValue='Training Hours' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Hrs.' />
                                    </td >

                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="10" required id="standard-required" onChange={this.handleChange4} />
                                    </td >
                                    <td>
                                        <TextField type="number" InputProps={{ readOnly: true }} name="year" required id="standard-required" value={this.state.total1[9]} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Form>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen2
                    })}
                    onClick={() => this.toggle(2)}
                >
                    <span className="app__toggle-text">AWARDS/CERTIFICATION/ACHIEVEMENTS </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen2}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen2 ? "app__collapse--active" : "")
                    }
                >
                    <TextField
                        id="outlined-full-width"
                        label="Description"

                        name="DescriptionId"
                        onChange={this.handleChange2}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen3
                    })}
                    onClick={() => this.toggle(3)}
                >
                    <span className="app__toggle-text">DETAILS OF EHS TRAINING PROGRAMMES   </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen3 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen3}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen3 ? "app__collapse--active" : "")
                    }
                >
                    <button onClick={this.handleAddRow()} style={{ float: 'right' }} className="btn btn-info btn-md">+ADD</button>
                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                <th>NAME OF THE PROGRAMME</th>
                                <th>CATEGORY OF TRAINEE</th>
                                <th>TYPE OF TRAINER</th>
                                <th>NO OF PARTICIPANTS</th>
                                <th>FACULTY NAME<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                <th>INSTITUTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.rows1.map((item, idx) => (
                                <tr id="addr0" key={item.id}>
                                    <td>
                                        <FormControl>
                                            <Select isSearchable required
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                                name="Programee"
                                                onChange={this.handleChange1(idx)}
                                            >
                                                <MenuItem value={this.state.program1} >Fire Safety Training</MenuItem>
                                                <MenuItem value={this.state.program2} >EHS Awareness Training</MenuItem>
                                                <MenuItem value={this.state.program3} >Safety at Work</MenuItem>
                                                <MenuItem value={this.state.program4} >Others</MenuItem>
                                            </Select>
                                        </FormControl> </td>
                                    <td>
                                        <FormControl>

                                            <Select isSearchable required
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                                name="Category"
                                                onChange={this.handleChange1(idx)}
                                            >
                                                <MenuItem value={this.state.type1} >Employees</MenuItem>
                                                <MenuItem value={this.state.type2} >Contract Employees</MenuItem>
                                            </Select>
                                        </FormControl> </td>
                                    <td>
                                        <FormControl>
                                            <Select isSearchable required
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                                name="TrainerType"
                                                onChange={this.handleChange1(idx)}
                                            >
                                                <MenuItem value={this.state.type4} >Internal</MenuItem>
                                                <MenuItem value={this.state.type5} >External</MenuItem>
                                            </Select>
                                        </FormControl> </td>
                                    <td>
                                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} required id="standard-required" name="Participants" onChange={this.handleChange1(idx)} />
                                    </td>
                                    <td>
                                        <TextField type="text" required id="standard-required" name="FacultyName" onChange={this.handleChange1(idx)} />
                                    </td>
                                    <td>
                                        <TextField type="text" required id="standard-required" name="Institute" onChange={this.handleChange1(idx)} />
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={this.handleRemoveSpecificRow(idx)}>
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen4
                    })}
                    onClick={() => this.toggle(4)}
                >
                    <span className="app__toggle-text">SIGNIFICANT EHS INITIATIVES /CONCERN</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen4 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen4}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen4 ? "app__collapse--active" : "")
                    }
                >
                    <TextField
                        id="outlined-full-width"
                        label="Description"

                        name="Description"
                        onChange={this.handleChange3}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <button style={{ float: 'right' }} className="btn btn-success" onClick={this.handleSubmit}>SAVE</button>
                </Collapse>
            </div>
        )
    }
}

export default Accidentstatistics