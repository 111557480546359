import React, { useState, Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";
import '../index.css';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from "@material-ui/core/ListItemText";
import Moment from 'moment';
import { Modal, Button } from "react-bootstrap";
import { value } from 'devexpress-dashboard/model/index.metadata';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';





class Incidentinvestigationview extends Component {

    constructor(props) {
        super(props)
       
        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' });
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {


            isOpen: false,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            program1: 'Fire',
            program2: 'Ehs',
            program3: 'Safety',
            type1: 'Male',
            type2: 'Female',
            type3: 'Abrasion,scrapes',
            type4: 'Amputation',
            type5: 'Broken bone',
            type6: 'Bruise',
            type7: 'Burn(heat)',
            type8: 'Burn(chemical)',
            type9: 'Concussion(head)',
            type10: 'Crushing Injury',
            type11: 'Cut/Laceration/Puncture',
            type12: 'Hernia',
            type13: 'Illness',
            type14: 'Sprain/Strain',
            type15: 'Body Part Damage',
            type16: 'Others',
            type17: 'Full Time',
            type18: 'Part Time',
            type19: 'Seasonal',
            type20: 'Temporary',
            type21: 'Entering or Leaving Work',
            type22: 'During Normal Work Activities',
            type23: 'During Meal Period',
            type24: 'During Break',
            type25: 'Working Overtime',
            type26: 'Others',
            type27: 'Yes',
            type28: 'No',
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            year1: '2020',
            year2: '2021',
            rows: [{ id: 0 }],
            rows1: [{ id: 0 }],
            rows2: [{ id: 0 }],
            rows3: [{ id: 0 }],
            rows4: [{ id: 0 }],
            rows5: [],
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            month: prevMonth,
            year: longYear,
            newdate: newdate,
            UnsafeConditions: [],
            UnsafeActs: [],
            SuggestedChanges: [],
            selectedFile: null,
            status1: 'Approved',
            status2: 'Rejected',	
            status: "pending",
            id: ''
        }
    }


    componentDidMount() {
        
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null ) {
            window.location.href = '/'
            return;
        }

        var prevYear = this.state.prevYear;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        if (prevMonth == "December") {
            this.setState({
                year: prevYear
            })
        }
        else {
            this.setState({
                year: longYear
            })
        }


        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        if (utypeid == 1) {


            document.getElementById('monthfilter').style.display = "none";
            document.getElementById("resubmit").style.display = "none";

            var adminhistoryehs = localStorage.getItem('adminhistoryehs')
            if (adminhistoryehs == 'true') {
                document.getElementById('status').style.display = "none";
            }

            else {
                document.getElementById('status').style.display = "inline";
            }
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            this.setState({
                id: id
            });
            axios.all([
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/InjuredEmployee/GetInjuredEmployeeFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentDescription/GetIncidentDescriptionFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentHappen/GetIncidentHappenFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/FutureIncidents/GetFutureIncidentFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ReviewedBy/GetReviewedByFiltered', { params: { SubmissionId: id } })


            ]).then(axios.spread((response1, response2, response3, response4) => {
                this.setState({
                    rows: response1.data,
                    rows1: response2.data,
                    rows2: response3.data,
                    rows3: response4.data,

                })
                console.log(response1.data)
                console.log(response2.data)
                console.log(response3.data)
                console.log(response4.data)
            }))

        }

        else {
            var Status = localStorage.getItem('Status')
            localStorage.removeItem("Status");
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            if (Status == 'Rejected' || id != null) {
                document.getElementById('monthfilter').style.display = "none";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "inline";


                localStorage.setItem('submissionid', id);

                this.setState({
                    id: id
                });

                axios.all([
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/InjuredEmployee/GetInjuredEmployeeFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentDescription/GetIncidentDescriptionFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentHappen/GetIncidentHappenFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/FutureIncidents/GetFutureIncidentFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ReviewedBy/GetReviewedByFiltered', { params: { SubmissionId: id } })


                ]).then(axios.spread((response1, response2, response3, response4) => {
                    this.setState({
                        rows: response1.data,
                        rows1: response2.data,
                        rows2: response3.data,
                        rows3: response4.data,

                    })
                    console.log(response1.data)
                    console.log(response2.data)
                    console.log(response3.data)
                    console.log(response4.data)
                }))
            }

            else {

                document.getElementById('monthfilter').style.display = "inline";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "none";
                var uid = localStorage.getItem('utype')
                axios.all([
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/InjuredEmployee/GetInjuredEmployeeByUId', { params: { UserId: uid } }),

                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentDescription/GetIncidentDescriptionByUId', { params: { UserId: uid } }),

                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentHappen/GetIncidentHappenByUId', { params: { UserId: uid } }),

                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/FutureIncidents/GetFutureIncidentByUId', { params: { UserId: uid } }),

                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ReviewedBy/GetReviewedByUId', { params: { UserId: uid } })

                ]).then(axios.spread((response1, response2, response3, response4, response5) => {
                    var prevMonth = this.state.prevMonth;
                    
                    // const rows = {...this.state.rows}
                    var rows = response1.data.filter(x => x.month == prevMonth);
                    var rows1 = response2.data.filter(x => x.month == prevMonth);
                    var rows2 = response3.data.filter(x => x.month == prevMonth);
                    var rows3 = response4.data.filter(x => x.month == prevMonth);
                    var rows4 = response5.data.filter(x => x.month == prevMonth);
                    this.setState({
                        rows: rows,
                        rows1: rows1,
                        rows2: rows2,
                        rows3: rows3,
                        rows4: rows4,
                    });
                    console.log(this.state.rows)
                }));
            }
        }
    }




    formatDate(string) {
        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('DD-MM-YYYY')


    }



    openModal = (e) => {
        
        this.setState({ status: e.target.value });
        this.setState({ isOpen: true });


    }
    closeModal = () => this.setState({ isOpen: false });

   
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    edit(idx) {


        this.props.history.push("/editinjuredemployee/?id=" + idx);
    }

    edit1(idx) {


        this.props.history.push("/editincidentdescription/?id=" + idx);
    }

    edit2(idx) {


        this.props.history.push("/editincidenthappen/?id=" + idx);
    }

    edit3(idx) {


        this.props.history.push("/editfutureincidents/?id=" + idx);
    }

    edit4(idx) {


        this.props.history.push("/editreviewedby/?id=" + idx);
    }

    isDisplayed() {


        var Status = localStorage.getItem('Status')
        var id = localStorage.getItem('submissionid')
        if (Status == 'Rejected' || id != null) {
            return "inline"
        }
        else {
            return "none";
        }


    }


    toggle = (index) => {
        let collapse = "isOpen" + index;

        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }
    handleSubmit = event => {
        event.preventDefault();
        
        var month = this.state.month;
        var year = this.state.year;
        var uid = localStorage.getItem('utype')
        axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/InjuredEmployee/GetInjuredEmployeeByMonth', { params: { Month: month, Year: year, UserId: uid  } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentDescription/GetIncidentDescriptionByMonth', { params: { Month: month, Year: year, UserId: uid  } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentHappen/GetIncidentHappenByMonth', { params: { Month: month, Year: year, UserId: uid } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/FutureIncidents/GetFutureIncidentByMonth', { params: { Month: month, Year: year, UserId: uid } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ReviewedBy/GetReviewedByMonth', { params: { Month: month, Year: year, UserId: uid  } }),

        ]).then(axios.spread((response1, response2, response3, response4,response5) => {
            this.setState({
                rows: response1.data,
                rows1: response2.data,
                rows2: response3.data,
                rows3: response4.data,
                rows4: response5.data,
            });
        }));
    }



    handleChange = event => {
        
        event.preventDefault();
        const name = event.target.name;

        const value = event.target.value;


        this.setState({
            [name]: value
        });
    };


    handleChange1 = e => {
        
        const rows5 = [...this.state.rows5];
        let row = {
            [e.currentTarget.name]: e.currentTarget.value,

            "Status": this.state.status,
            "RegulatorId": 1,
            "SubmissionId": parseInt(this.state.id)
        }
        rows5[0] = row
        this.setState({
            rows5
        });
    };

    handleSubmit1 = e => {
        
        const { rows5 } = this.state;
        var Status = localStorage.getItem('Status')
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows5)
        };
        let newbody = JSON.parse(Options.body);
        if (Status == 'Submitted') {
            axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateUserSubmittedEhs', newbody[0])

                .then(response => {

                    this.setState({ isOpen1: false })
                    alert("Data Rejected Again");
                    this.props.history.push('/usersubmittedehs')
                });
        }

        else {
            axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSStatus', newbody)
                .then(response => {
                    this.setState({ isOpen: false })
                    alert("Status Updated Successfully");
                    this.props.history.push('/ehsadminview')
                });
        }
    }

    handleSubmit2 = e => {
        
        e.preventDefault();
        let SubmissionId = parseInt(this.state.id);

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateEhsStatus', { params: { SubmissionId: SubmissionId } })
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/adminrejectedehs')

            }).catch(err => console.log(err));
    }

    handleSubmit3 = event => {
        event.preventDefault();
        this.setState({
            month: this.state.prevMonth,
            year: this.state.longYear
        })
        this.componentDidMount();
    }
    render() {


        return (
            <div className="app">

                <h5 style={{ backgroundColor: '#001946', width: '1100px', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Incident Investigation Report </h5>
                <br />

                <div id="monthfilter" style={{ fontSize: '16px' }}>

                    <FormControl>

                        <select

                            value={this.state.month}
                            name="month"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Month--</option>
                            <option value={this.state.month1} >January</option>
                            <option value={this.state.month2} >February</option>
                            <option value={this.state.month3} >March</option>
                            <option value={this.state.month4} >April</option>
                            <option value={this.state.month5} >May</option>
                            <option value={this.state.month6} >June</option>
                            <option value={this.state.month7} >July</option>
                            <option value={this.state.month8} >August</option>
                            <option value={this.state.month9} >September</option>
                            <option value={this.state.month10} >October</option>
                            <option value={this.state.month11} >November</option>
                            <option value={this.state.month12} >December</option>

                        </select>

                    </FormControl>

                    &nbsp;


                   <FormControl>
                        <select
                            value={this.state.year}
                            name="year"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Year--</option>
                            <option value={this.state.prevYear} >{this.state.prevYear}</option>
                            <option value={this.state.longYear} >{this.state.longYear}</option>
                        </select>
                    </FormControl>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit3} type="submit" className="btn btn-success btn-sm">CLEAR</button>
                </div>

                <br />
                <div id="status" style={{ float: 'right', fontSize: '16px' }}>
                    <label style={{ fontWeight: 'bold' }}>Status:</label>
                    <FormControl>

                        <select


                            name="Status"
                            onChange={this.openModal}
                        >
                            <option selected disabled value='0'>--Pending--</option>
                            <option value={this.state.status1} >Approve</option>
                            <option value={this.state.status2} >Reject</option>


                        </select>

                    </FormControl>
                </div>
                <div id="resubmit">
                    <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit2}>RE-SUBMIT FOR APPROVAL</button>

                </div>
                <Modal style={{ backgroundColor: '#ffffff00', width: '500px', borderColor: '#ffffff00' }}

                    show={this.state.isOpen} onHide={this.closeModal}>
                    <div className="App1">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<label>
                                Date:
                                </label>
                              {this.state.currentDateTime} <br/> */}

                            <label for='Remarks'>Remarks: </label>
                            <TextField type="text" name="Remarks" id="Remarks" onChange={this.handleChange1} style={{ width: '350px' }} />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.handleSubmit1} style={{ marginRight: '8px' }}> Submit </Button>
                            <Button variant="secondary" onClick={this.closeModal}>

                                Close
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
                <br />

                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen1
                    })}
                    onClick={() => this.toggle(1)}
                >
                    <span className="app__toggle-text">Injured Employee(Complete This Part for Each Employer)</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen1}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen1 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}







                        </div>

                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>NAME</th>
                                    <th>DEPARTMENT</th>
                                    <th>SEX</th>
                                    <th>JOB TITLE AT INCIDENT TIME</th>
                                    <th>AGE</th>
                                    <th>MONTHS WITH THE EMPLOYER</th>
                                    <th>EMPLOYEE TYPE</th>
                                    <th>NATURE OF INJURY</th>
                                    <th>MONTHS DURING THE JOB</th>
                                  





                                </tr>
                            </thead>
                            <tbody>



                                {this.state.rows.map((item, idx) => (


                                    <tr id="addr0" key={item.injuredId}>




                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.name} />
                                        </td >
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.department} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.sex} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.jobTitle} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" defaultValue={item.age} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" defaultValue={item.monthsEmployer} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.employeeType} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.injuryNature} style={{width:'100px'}}/>
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" defaultValue={item.monthsJob} />
                                        </td>




                                        <td width="10%">

                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.injuredId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </button>
                                            {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}


                                        </td>
                                    </tr>

                                )

                                )}




                            </tbody>
                        </Table>


                      




                        {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                    </Form>
                </Collapse>

                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen2
                    })}
                    onClick={() => this.toggle(2)}
                >
                    <span className="app__toggle-text">DESCRIBE THE INCIDENT </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen2}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen2 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}







                        </div>


                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>INCIDENT LOCATION</th>
                                    <th>TIME</th>
                                    <th>EMPLOYEE WORKDAY PART</th>
                                    <th>PPE USED</th>
                                    <th>WITNESS NAME(S)</th>
                                    <th>EVENTS THAT LED INJURY</th>
                                    <th>UPLOADED IMAGE</th>
                                    
                                





                                </tr>
                            </thead>
                            <tbody>



                                {this.state.rows1.map((item, idx) => (


                                    <tr id="addr0" key={item.describeId}>




                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.incidentLocation} />
                                        </td >
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.time} style={{ width: '70px' }}/>
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.workdayPart} style={{ width: '150px' }} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.equipments} style={{ width: '70px' }}/>
                                        </td>
                                       
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.witness} style={{ width: '130px' }} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.events} style={{ width: '130px' }} />
                                        </td>
                                        <td>
                                            <a href={process.env.PUBLIC_URL + item.uploadedPath} target="_blank">
                                                <img src={process.env.PUBLIC_URL + item.uploadedPath} alt='No-Image' width="200" height="50" />
                                            </a>
                                        </td>




                                        <td width="10%">

                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit1(item.describeId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </button>
                                            {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}


                                        </td>
                                    </tr>

                                )

                                )}




                            </tbody>
                        </Table>
                        


                        {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                    </Form>


                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen3
                    })}
                    onClick={() => this.toggle(3)}
                >
                    <span className="app__toggle-text">WHY DID THE INCIDENT HAPPEN?   </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen3 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen3}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen3 ? "app__collapse--active" : "")
                    }
                >


                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                        </div>
                        <br />


                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>UNSAFE WORKPLACE CONDITIONS</th>
                                    <th>UNSAFE ACTS BY PEOPLE</th>
                                    <th>WHY UNSAFE CONDITIONS EXISTS</th>
                                    <th>WHY UNSAFE ACTS OCCUR</th>
                                    <th>REWARDS</th>
                                    <th>UNSAFE ACTS REPORTED</th>
                                    <th>SIMILAR INCIDENTS</th>





                                </tr>
                            </thead>
                            <tbody>



                                {this.state.rows2.map((item, idx) => (


                                    <tr id="addr0" key={item.happenId}>




                                        <td>
                                            <TextField  multiline InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.unsafeConditions} style={{ width: '250px' }}/>
                                        </td >
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.unsafeActs} style={{ width: '250px' }} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.unsafeExists}  />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.unsafeOccurs} />
                                        </td>

                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.reward} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.unsafeReported}  />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.similarIncidents}  />
                                        </td>




                                        <td width="10%">

                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit2(item.happenId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </button>
                                            {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}


                                        </td>
                                    </tr>

                                )

                                )}




                            </tbody>
                        </Table>

                  
                    </Form>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen4
                    })}
                    onClick={() => this.toggle(4)}
                >
                    <span className="app__toggle-text">HOW CAN FUTURE INCIDENTS BE PREVENTED?</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen4 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen4}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen4 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>
                        

                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHANGES SUGGESTED TO PREVENT INCIDENT</th>
                                    <th>THINGS DONE TO CARRY OUT CHANGES</th>
                                    






                                </tr>
                            </thead>
                            <tbody>



                                {this.state.rows3.map((item, idx) => (


                                    <tr id="addr0" key={item.futureId}>




                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.suggestedChanges} style={{ width: '300px' }} />
                                        </td >
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.carrySuggestion}  />
                                        </td>
                                       



                                        <td width="10%">

                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit3(item.futureId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </button>
                                            {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}


                                        </td>
                                    </tr>

                                )

                                )}




                            </tbody>
                        </Table>
                    </Form>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen5
                    })}
                    onClick={() => this.toggle(5)}
                >
                    <span className="app__toggle-text">WHO COMPLETED AND REVIEWED THIS FORM?</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen5 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen5}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen5 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>


                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>TITLE</th>
                                    <th>INVESTIGATED BY</th>
                                    <th>DEPARTMENT</th>
                                    <th>DATE</th>
                                    <th>INVESTIGATION TEAM MEMBERS</th>
                                    
                                   





                                </tr>
                            </thead>
                            <tbody>



                                {this.state.rows4.map((item, idx) => (


                                    <tr id="addr0" key={item.investigatorId}>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.title} />
                                        </td>


                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.investigatedBy} />
                                        </td >
                                       
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.department} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={this.formatDate(item.date)} />
                                        </td>

                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.teamMembers} />
                                        </td>
                                      




                                        <td width="10%">

                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit4(item.investigatorId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </button>
                                            {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}


                                        </td>
                                    </tr>

                                )

                                )}




                            </tbody>
                        </Table>


                    
                      
                    </Form>
                </Collapse>
            </div>



        )
    }
}

export default Incidentinvestigationview