import React,{Component} from 'react'
import {Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink  } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';

import './App.css';
import '../index.css';
import Tooltip from "react-simple-tooltip"
import axios from 'axios';



class Fireextenguisher extends Component {
    constructor(props) {
        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' });
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();     this.state= {
      count:0,
      rows:[{id:0}],
            rows1: [],
         rows2:[],
         ucode: localStorage.getItem('username'),
         utype: localStorage.getItem('utype'),
         location: localStorage.getItem('location'),
         longMonth: longMonth,
         longYear: longYear,
         admincode: localStorage.getItem('admincode'),
            prevMonth: prevMonth,
         prevYear:prevYear
         }   
  
    }

   
    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
    }
    
    handleChange = (idx) => (e) => {
      
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        
        //let id=name+(idx);
       
           const value = e.target.value;
     
         
        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId":utype
        };
        rows[idx] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],

            "FormId": 8,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });

    };
    
                         handleAddRow =() => (event) => {
                        
                          let count=this.state.count
                          count=count+1;
                          this.setState({count})

                          // this.setState((prevState) => ({ count: prevState.count + '1'}));
                       
                          const item = {
                            id:count
                            // type: "",
                            // unit: "",
                            // consumed: "",
                            // recycle:""
                            
                          };
                          event.preventDefault();
                          this.setState({
                            rows: [...this.state.rows, item]                       
                            
                          });
                        };
                        // handleRemoveRow = (event) => {
                        //   event.preventDefault();
                        //   this.setState({
                        //     rows: this.state.rows.slice(0, -1)
                        //   });
                        // };
                        handleRemoveSpecificRow = (idx) => () => {
                        
                          const rows = [...this.state.rows]
                          rows.splice(idx, 1)
                          this.setState({ rows })
                        }
                    
          

    handleSubmit = async (event) => {
        
        event.preventDefault();

        const { rows } = this.state;
        const { rows1 } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/FireExtinguisher/GetFireExtinguisherByMonth', { params: { Month: prevMonth, Year: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows2: response.data
                })

            })

        var rows2 = this.state.rows2
        if (rows2.length == 0) {


            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            var newbody = JSON.parse(Options.body);

            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let checkbody = JSON.parse(Options1.body);
            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/FireExtinguisher/SaveFireExtinguisherDetails', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)
            ]).then(axios.spread((response1, response2) => {
                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }


        

    }

    handleClick1 = () => (event) => {

        this.props.history.push("/fireextinguisherview");  
    };


             renderRows(){
              return this.state.rows.map((item, idx) => {
                  return (
                    
                <tr id="addr0" key={item.id}>
               
                          {/*  <td >  
                            { item.id}
                            </td>*/}
                
                 <td>  
                              <TextField style={{width:'100px'}}type="text" required id="standard-required" name="Make"   onChange={this.handleChange(idx)}/>
                </td>
               
               <td>
                            <TextField type="text" required id="standard-required" name="Type"  onChange={this.handleChange(idx)}/>

               </td>
               <td>
                            <TextField type="text" required id="standard-required" name="Capacity"   onChange={this.handleChange(idx)}/>
               </td> 
                        <td>
                            <TextField
                                  name="MonthlyInspection"
                                 
                                type="date"
                                defaultValue="YYYY-MM-DD"
                                  onChange={this.handleChange(idx)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </td>  
                        <td>
                            <TextField
                                  name="NextOn"

                                type="date"
                                defaultValue="YYYY-MM-DD"
                                  onChange={this.handleChange(idx)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </td>  
                        <td>
                            <TextField
                                  name="AnnualInspection"

                                type="date"
                                defaultValue="YYYY-MM-DD"
                                  onChange={this.handleChange(idx)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </td>  
                        <td>
                            <TextField
                                  name="PressureTested"

                                type="date"
                                  defaultValue="YYYY-MM-DD"
                                  onChange={this.handleChange(idx)}
                                 InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </td>  
                        <td>
                            <TextField
                                  name="DateOfDischarge"

                                type="date"
                                defaultValue="YYYY-MM-DD"
                                  onChange={this.handleChange(idx)} 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </td>  
                        <td>
                            <TextField
                                  name="RefilledOn"

                                type="date"
                                defaultValue="YYYY-MM-DD"
                                  onChange={this.handleChange(idx)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </td>  
                        <td>
                            <TextField
                                  name="RefillingDue"
                                  style={{ width: '145px' }}
                                type="date"
                                defaultValue="YYYY-MM-DD"
                                  onChange={this.handleChange(idx)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </td>  

                       
               <td>
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  onClick={this.handleRemoveSpecificRow(idx)}>
        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>                          
                                </button>
                                
                              </td>
            </tr>
           
            );
          });
            }

    render() {
        

                 const text = "This is the sum of all water drawn (in cubic meter per month) into the facility from all\nsources (groundwater, surface water, Municipal / others and harvested rainwater)\nfor any uses within the unit during the reporting period."
              return  (
                  <Form className="font-link" onSubmit={this.handleSubmit}>
                      <div className="App">
                          <div className="wordalign">

                          {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                          <label>
                              {this.state.longMonth}   &nbsp;
                   {this.state.longYear}

                              </label>
                              <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                              <br />
                              <br />
                          </div>
                      </div>
                      <br />
                      {/*   <h5>TOTAL WATER WITHDRAWAL </h5> */}
                      <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Fire Extinguisher </h5>

                      <br />
                     
                      <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" ,overflowX:'scroll',display:'block',overflowY:'hidden'}}>
                          <thead>
                              <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                   
                                  <th>MAKE <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                                  <th>TYPE  <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                                  <th>CAPACITY  <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                                  <th>MONTHLY INSPECTION DATES  </th>
                                  <th>NEXT ON <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                    <th>ANNUAL INSPECTION DATES</th>
                                  <th>PRESSURE TESTED ON  <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                                  <th>DATE OF DISCHARGE <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                                  <th>REFILLED ON <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                                  <th>DUE FOR REFILLING <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                 
                                  <th>ACTION <br /><span style={{ color: '#001946', visibility: 'hidden' }} >ACTIVITY</span></th>
                    
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>1</td>
                    <td>
                        <select value={this.state.type1} onChange={this.handleTypeChange1}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit1} onChange={this.handleUnitChange1}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density1} onChange={this.handleDensityChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific1} onChange={this.handleCalorificChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value1} onChange={this.handleValueChange1 } />  
                   </td>    
        
                </tr>
                <tr>
                    <td>2</td>
                    <td>
                        <select value={this.state.type2} onChange={this.handleTypeChange2}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit2} onChange={this.handleUnitChange2}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density2} onChange={this.handleDensityChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific2} onChange={this.handleCalorificChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value2} onChange={this.handleValueChange2} />  
                   </td>  
        
                </tr>
                <tr>
                    <td>3</td>
                    <td>
                        <select value={this.state.type3} onChange={this.handleTypeChange3}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit3} onChange={this.handleUnitChange3}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density3} onChange={this.handleDensityChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific3} onChange={this.handleCalorificChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value3} onChange={this.handleValueChange3} />  
                   </td>  
                  </tr> */}
               
                  {this.renderRows()}
                   
               </tbody>
               </Table>
                          
               <button type="submit" style={{float: 'right'}} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>   
                      <button onClick={this.handleAddRow()} style={{ float: 'right',marginRight:'5px' }} className="btn btn-info btn-md">+ADD</button>

             {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
               
             
               </Form>
                   
              )
            }
}

export default Fireextenguisher