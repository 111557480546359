import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import Checkbox from '@material-ui/core/Checkbox';
import cx from "classnames";


class Workpermit extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            types: [],
            isOpen1: false,
            unit: '',
            validationError: "",
            count: 0,
            type1: 'Welding',
            type2: 'Brazing',
            type3: 'Gas Cutting',
            type4: 'Grinding',
            type5: 'Yes',
            type6: 'No',
            rows: [{ id: 0 ,SignOff:false,PermitClose:false}],
            rows1: [],
            rows2: [],
            rows3: [],
             rows4:[],
            login: [],
            
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            newdate: newdate,
            admincode: localStorage.getItem('admincode'),
        }
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
    }



    handleChange = e => {
        
        const name = e.target.name;
        var value=""
        if (name == "SignOff" || name == "PermitClose") {
            value = e.target.checked;

        }
        else {
            value = e.target.value;
        }
        const rows = [...this.state.rows];
        const rows2 = [...this.state.rows2];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)
        
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows[0],
            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[0] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows2[0],
            "FormId": 18,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode
        };
        rows2[0] = row1;
        this.setState({
            rows2
        });
    };



    handleChange1 = (idx) => (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
      
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var index = idx + 1;
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows1[idx],
            id: idx,
            [name]: value,
            "SafetyCheckMasterId":index,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows1[idx] = row;
        this.setState({
            rows1
        });
    };





    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {
        event.preventDefault();
        
        const { rows } = this.state;
        const { rows1 } = this.state;
        const { rows2 } = this.state;
        

        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
         axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/HotWorkPermit/GetHotWorkPermitByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkPermitCheckSheet/GetWorkPermitCheckSheetByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
           
        ]).then(axios.spread((response1, response2) => {
            
            this.setState({
                
                rows3: response1.data,
                rows4: response2.data,
                
            })
        }))





        var rows3 = this.state.rows3
        var rows4 = this.state.rows4

        if (rows3.length == 0 || rows4.length == 0 ) {

            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            let newbody = JSON.parse(Options.body);

            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let newbody1 = JSON.parse(Options1.body);

           

            const Options2 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows2)
            };
            let checkbody = JSON.parse(Options2.body);

           
            axios.all([


                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/HotWorkPermit/SaveHotWorkPermitList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkPermitCheckSheet/SaveWorkPermitCheckSheetList', newbody1),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

            ]).then(axios.spread((response1) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }




    }


    handleClick1 = () => (event) => {
        window.location.href = '/workpermitview'
    };


    toggle = (index) => {
        let collapse = "isOpen" + index;

        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }


    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label class="no-space-break">

                        Date Of Reporting:&nbsp;{this.state.newdate}  &nbsp;
                        {this.state.longMonth},
                   {this.state.longYear}

                    </label>
                    <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                    <br />
                    <br />


                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center',color:'white' }}>WORK PERMIT </h5>

                <div className="col-1">
                    <br />


                    <div >
                        <label class="no-space-break">Date: </label> <br />
                        <TextField selected type="date" name="Date" required id="standard-required" onChange={this.handleChange} />
                    </div>
                    <br />
                    <div>
                        <label class="no-space-break">End Time: </label>
                        <br />
                        <TextField
                            name="EndTime"

                            type="time"
                            defaultValue="hh:mm"
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            
                        />
                    </div>
                    <br />

                    <div>
                        <label class="no-space-break">Work Description: </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="WorkDescription"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Welding</MenuItem>
                                <MenuItem value={this.state.type2} >Brazing</MenuItem>
                                <MenuItem value={this.state.type3} >Gas Cutting</MenuItem>
                                <MenuItem value={this.state.type4} >Grinding</MenuItem>

                            </Select>

                        </FormControl>
                    </div>

                </div>
                <div className="col-2">
                    
                    <br />

                    <div>
                        <label class="no-space-break">Start Time: </label>
                        <br />
                        <TextField
                            name="StartTime"
                            onChange={this.handleChange}
                            type="time"
                            defaultValue="hh:mm"

                            InputLabelProps={{
                                shrink: true,
                            }}
                           
                        />
                    </div>
                    <br />

                    <div >
                        <label class="no-space-break">No of Contractor Employee Working: </label> <br />
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="ContractorEmployee" required id="standard-required" onChange={this.handleChange}/>
                    </div>
                    <br />
                    <div >
                        <label class="no-space-break">Work Location: </label> <br />
                        <TextField type="text" name="WorkLocation" required id="standard-required" onChange={this.handleChange}/>
                    </div>

                </div>

                       <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen1
                    })}
                    onClick={() => this.toggle(1)}
                >
                    <span className="app__toggle-text">Hot Work Safety Checklist</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen1}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen1 ? "app__collapse--active" : "")
                    }
                >

                   
                     <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#306754', color: 'white' }}>
                                    <th>CHECKPOINTS</th>
                                    <th>RESPONSIBILITY</th>
                                    <th>ACTION</th>
                                    <th>REMARKS</th>





                                </tr>
                            </thead>
                            <tbody>



                                <tr id="addr0" key={0}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Equipment & PPEs of the contractor to be checked at main gate. Only after, acceptance by security/SFO, the contractor to be allowed for work. ' />
                                    </td >
                                   

                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='SFO' />
                                    </td >
                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                                name="Action"
                                                onChange={this.handleChange1(0)}
                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                        <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(0)} />
                                    </td>

                                </tr>

                                <tr id="addr1" key={1}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Tool Box Talk given to all contract workers (Before starting the hot work, Contractor workmen should be trained & briefed about safety measures and record should be maintained.) ' />
                                    </td >


                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='SFO' />
                                    </td >
                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(1)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(1)} />
                                    </td>

                                </tr>

                                <tr id="addr2" key={2}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Regular monitoring & ensuring compliance of the Hot Work Checklist by the SFO ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='SFO' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(2)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(2)} />
                                    </td>


                                </tr>

                                <tr id="addr3" key={3}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Area free of combustible materials ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(3)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(3)} />
                                    </td>

                                </tr>

                                <tr id="addr4" key={4}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Any naked connection observed' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(4)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(4)} />
                                    </td>

                                </tr>



                                <tr id="addr5" key={5}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Ensure localised body earthing of job  ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='SFO' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(5)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(5)} />
                                    </td>

                                </tr>

                                <tr id="addr6" key={6}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Ensure welding machine power source connected with ELCB / MCB ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(6)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(6)} />
                                    </td>

                                </tr>

                                <tr id="addr7" key={7}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Display work in progress signage and area barricaded' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(7)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(7)} />
                                    </td>

                                </tr>

                                <tr id="addr8" key={8}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Availability of suitable fire extinguisher at hot work location and charged fire hydrants connected with hoses. ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(8)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(8)} />
                                    </td>

                                </tr>

                                <tr id="addr9" key={9}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Availability of dedicated fire man/trained security guard at hot work location. Work should not be carried out without fire man/trained security guard  ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='SFO/User' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(9)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(9)} />
                                    </td>

                                </tr>



                                <tr id="addr10" key={10}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Ensure cylinders used for gas cutting are kept upright and secured properly'/>
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(10)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(10)} />
                                    </td>

                                </tr>
                                <tr id="addr11" key={11}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Ensure Regulators, pressure gauges & flash back arrestors should be provided in both welding/gas cutting torch and cylinders' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(11)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(11)} />
                                    </td>

                                </tr>
                                <tr id="addr12" key={12}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Check gas hose condition and ensure it is secured with proper clamps' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(12)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(12)} />
                                    </td>

                                </tr>
                                <tr id="addr13" key={13}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='All power tools are as per standard' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='SFO' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(13)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(13)} />
                                    </td>

                                </tr>
                                <tr id="addr14" key={14}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='No defect in any equipment ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(14)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(14)} />
                                    </td>

                                </tr>
                                <tr id="addr15" key={15}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='The RPM of the grinding wheel should always be greater than grinding machine ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='SFO' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(15)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(15)} />
                                    </td>

                                </tr>
                                <tr id="addr16" key={16}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='In case of hot work at height, ensure welding splatters are contained. ' />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                    </td >

                                    <td>
                                        <FormControl>

                                            <Select isSearchable 
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(16)}

                                            >
                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td >
                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(16)} />
                                    </td>
                                </tr>
                                    <tr id="addr17" key={17}>




                                        <td>
                                            <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='The Supervisor or License worker (for specialized work i.e. welding, grinding, electrical etc.) should attach their ID / License with this permit for approval process' />
                                        </td >
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                        </td >

                                        <td>
                                            <FormControl>

                                                <Select isSearchable 
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(17)}

                                                >
                                                    <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type6} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td >
                                        <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(17)} />
                                        </td>
                                        </tr>
                                        <tr id="addr18" key={18}>




                                            <td>
                                                <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='In case of hot work at height, ensure welding splatters are contained. ' />
                                            </td >
                                            <td>
                                                <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Fireman' />
                                            </td >

                                            <td>
                                                <FormControl>

                                                    <Select isSearchable 
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(18)}

                                                    >
                                                        <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                        <MenuItem value={this.state.type6} >No</MenuItem>


                                                    </Select>

                                                </FormControl>
                                            </td >
                                        
                                            <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(18)} />
                                            </td>
                                        </tr>
                                            <tr id="addr19" key={19}>




                                                <td>
                                                    <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Ensure Lock Out & Tag Out, wherever applicable ' />
                                                </td >
                                                <td>
                                                    <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='SFO' />
                                                </td >

                                                <td>
                                                    <FormControl>

                                                        <Select isSearchable 
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(19)}

                                                        >
                                                            <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                            <MenuItem value={this.state.type6} >No</MenuItem>


                                                        </Select>

                                                    </FormControl>
                                                </td >
                                                <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(20)} />
                                                </td>
                                                 </tr>
                                                <tr id="addr20" key={20}>




                                                    <td>
                                                        <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Not allow anything to enter storm-water drains other than clean water.' />
                                                    </td >
                                                    <td>
                                                        <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='User' />
                                                    </td >

                                                    <td>
                                                        <FormControl>

                                                            <Select isSearchable 
                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper"
                                                                defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(20)}

                                                            >
                                                                <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                                <MenuItem value={this.state.type6} >No</MenuItem>


                                                            </Select>

                                                        </FormControl>
                                                    </td >
                                                    <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(20)} />
                                                    </td>
                                                      </tr>
                                                    <tr id="addr21" key={21}>




                                                        <td>
                                                            <TextField InputProps={{ readOnly: true }} style={{ width: '700px' }} selected type="text" name="details" required id="standard-required" defaultValue='Dispose-off waste in designated waste disposal area only' />
                                                        </td >
                                                        <td>
                                                            <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" defaultValue='Contractor' />
                                                        </td >

                                                        <td>
                                                            <FormControl>

                                                                <Select isSearchable 
                                                                    labelId="demo-simple-select-helper-label"
                                                                    id="demo-simple-select-helper"
                                                                    defaultValue="Select"
                                            name="Action"
                                            onChange={this.handleChange1(21)}

                                                                >
                                                                    <MenuItem value={this.state.type5} >Yes</MenuItem>
                                                                    <MenuItem value={this.state.type6} >No</MenuItem>


                                                                </Select>

                                                            </FormControl>
                                                        </td >
                                                        <td>
                                    <TextField type="text" name="Remarks"  id="standard-required" onChange={this.handleChange1(21)} />
                                                        </td>


                                                    </tr>
                                            
                                            
                             



                            </tbody>
                        </Table>




                      

                        {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}



                    </Collapse> 
                <h5 style={{ backgroundColor: '#001946',color:'white' }} >Authorization</h5>
                <div className='col-1'>

                    
                    <br />
                    <br />
                    <div >
                        <label class="no-space-break">Project Manager: </label> <br />
                        <TextField type="text" name="ProjectManager" required id="standard-required" onChange={this.handleChange}/>
                    </div>


                    <br />
                   

                    <br />
                    </div>
                    <br />
                    <br />
                <div className='col-2'>

                        <div >
                        <label class="no-space-break">Duty Security & Fire Officer: </label> <br />
                        <TextField type="text" name="DutyOfficer" required id="standard-required" onChange={this.handleChange}/>
                        </div>

                </div>

                <br />
                <div style={{marginLeft:'20px'} }>
                    <label class="no-space-break">Person(s) (Contractor employee) Carrying out Hot Work : </label> <br />
                    <TextField  name="PerCarryHot" style={{ margin: 8 }} fullWidth margin="normal" InputLabelProps={{shrink: true}} variant="outlined" required id="standard-required" onChange={this.handleChange} />
                </div>
                <br />
                

                <h5 style={{ backgroundColor: '#001946', color:'white' }} >Permit Close and Withdrawal of Permission</h5>
                <br />
                <div className='col-1' >
                    <label class="no-space-break">Fire Watch Sign Off: </label> <br />
                    <Checkbox
                        checked={this.state.checked}
                        name="SignOff"
                        onChange={this.handleChange}
                        size="small"
                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                    />
                </div>


              
                <div className='col-2' >
                    <label class="no-space-break">Close of Permit : </label>  <br />
                    <Checkbox
                        name="PermitClose"
                        onChange={this.handleChange}
                       
                        size="small"
                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                    />
                </div>

                <h5 style={{ backgroundColor: '#001946',color:'white' }} >Emergency Contact Information</h5>
                <br />
              
                <div className='col-1'>
                    <h5 class="no-space-break">Contractor Contact</h5>

                   
                    <div >
                        <label class="no-space-break">Name: </label> <br />
                        <TextField type="text" name="ContractorName" required id="standard-required" onChange={this.handleChange}/>
                    </div>


                    <br />
                    <h5 class="no-space-break">Security & Fire Control Room </h5>
                    <div >
                        <label class="no-space-break">Name : </label> <br />
                        <TextField type="text" name="SecurityName" required id="standard-required" onChange={this.handleChange}/>
                    </div>

                    <br />
                </div>


                <br />
                <div className='col-2'>
                    
                    <br />
                    <div >
                        <label class="no-space-break">Contact: </label> <br />
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="ContractorContact" required id="standard-required" onChange={this.handleChange} />
                    </div>
                    <br />
                    <br />
                    <div >
                        <label class="no-space-break">Contact: </label> <br />
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="SecurityContact" required id="standard-required" onChange={this.handleChange}/>
                    </div>

                </div>

                <br />

              
                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Workpermit