import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
    // boxShadow: " 10px 10px 10px #888888!important",
    padding: "1%",
    borderWidth: "thick"
  },
  media: {
    height: 140,
  },
  unit: {
    textAlign: "left"
  }
});

export default function SimpleCard({children , title , unit} ) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
          
        {children}
          <Divider>
              {title}
          </Divider>
        <div className={classes.unit}>
              
        </div>
    </div>
  );
}

