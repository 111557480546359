import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LineChart from "../components/LineChart/LineChart";
import SimpleCard from "../components/Card";
import computeWithdrawWaterArray from '../utils/Withdraw';
import computeRecycledWaterArray from '../utils/Recycled';
import React from 'react'
const useStyles = makeStyles({
    root : {
        boxShadow: " 10px 10px 10px #888888!important",
        padding: "1%",
    }
})

const formatCode = (val) => {
    return val + 'm' + String.fromCharCode(179) +'';
}

export default function Water(props) {
    const classes = useStyles();

    let withdrawOne = props.withdraw1;
    let withdrawTwo = props.withdraw2;
    let recycledOne = props.recycled1;
    let recycledTwo = props.recycled2;

    let actualWithdrawOne = computeWithdrawWaterArray(withdrawOne);
    let actualWithdrawTwo = computeWithdrawWaterArray(withdrawTwo);

    let actualRecycledOne = computeRecycledWaterArray(recycledOne);
    let actualRecycledTwo = computeRecycledWaterArray(recycledTwo);


    return (
        <div className={classes.root}>
            <SimpleCard title="Water" unit={'All Values in m�'}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <LineChart data1={actualWithdrawOne} data2={actualWithdrawTwo} title="Withdrawal" year1={props.year1} year2={props.year2} unit={formatCode('')}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <LineChart data1={actualRecycledOne} data2={actualRecycledTwo} title="Recycled" year1={props.year1} year2={props.year2} unit={formatCode('')}/>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}