import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editriskregister extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {

            count: 0,

            status1: 'Compliant',
            status2: 'NonCompliant ',
            status3: 'NotApplicable',
            type3: 'Open',
            type4: 'Closed',
            
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: ''


        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
       
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskRegister/GetRiskRegisterById', { params: { RegisterId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0]


            });

        });


    }




    formatDate(string) {

        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('YYYY-MM-DD')


    }





    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name


        const value = e.target.value


        var rows = this.state.rows;

        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };



    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;
        var id = localStorage.getItem('submissionid')

        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskRegister/UpdateRiskRegisterList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/riskregisterview/?id=' + id)
            }).catch(err => console.log(err));


    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Edit Risk Register </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="ApplicableLegislations">
                                    <label class="no-space-break">Applicable Legislations/Law </label>
                                    <textarea disabled rows="5" name="applicableLegislations" type="text" class="form-control" value={this.state.rows.applicableLegislations} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="CriteriaDefinition">
                                    <label class="no-space-break">Criteria Definition</label>
                                    <textarea disabled rows="5" name="criteriaDefinition" type="text" class="form-control" value={this.state.rows.criteriaDefinition} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="ProceduralRequirements">
                                    <label class="no-space-break">Procedural Requirements</label>
                                    <textarea disabled rows="5" name="proceduralRequirements" type="text" class="form-control" value={this.state.rows.proceduralRequirements} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="ReferenceDocument">
                                    <label class="no-space-break">Reference Document</label>
                                    <textarea disabled rows="5" name="referenceDocument" type="text" class="form-control" value={this.state.rows.referenceDocument} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>



                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <label class="no-space-break">License</label>
                                <div class="input-group">
                                    <input required name="license" class="form-control" type="text" value={this.state.rows.license} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Status">
                                    <label class="no-space-break">Status </label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.status} name="status">

                                        <option value={this.state.status1} >Compliant</option>
                                        <option value={this.state.status2} >Not Compliant</option>
                                        <option value={this.state.status3} >Not Applicable</option>

                                    </select>
                                </div>
                            </div>
                           
                        </div>





                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="NextDate">
                                    <label class="no-space-break">Next Due Date</label>
                                    <input required name="nextDate" type="date" class="form-control" value={this.formatDate(this.state.rows.nextDate)} onChange={this.handleChange} />
                                </div>
                            </div>

                           


                        </div>

                      

                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editriskregister