import React, { Component } from "react";
import "./login.css";
import CustomInput from "./CustomInput";
import { ButtonToggle } from "reactstrap";
import axios from 'axios';
import Button from "./Button";
import background from "../pic.jpg";
import Form from 'react-bootstrap/Form';
import { v4 as uuid } from 'uuid';
class Login extends Component {
    state = {
        Ucode: "",
        Upwd: ""
    };


    componentDidMount() {
        document.getElementById('header').style.display = "none"
        document.getElementById("navribbon").style.display = "none";
        alert(process.env.REACT_APP_GLOBAL_CODE);
        
        
    }

    

    handleChange = e => {
        this.setState({ [e.currentTarget.id]: e.currentTarget.value });
    };
    handleClick = event => {
        debugger;
         event.preventDefault();
        window.location.href = '/forgotpassword'
    };
   
    handleSubmit = e => {
        debugger;       
        let data = this.state;
        const Options = {
            method: 'POST',
            body: JSON.stringify(data)
        };
        let newbody = JSON.parse(Options.body);
        debugger;
        var unique_id = "null";
        var local = localStorage.getItem('globalvar')
        if (local== "null" || local==null) {
            unique_id = uuid();
            localStorage.setItem('globalvar', unique_id)
            local=unique_id
        }
         
      axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/UserLogin/checkUserExist', newbody)
   
            .then(response => {
                    
                console.log(response.data)
                debugger;
    
                if (response.data[0] != null) {
                    if (response.data[0].isActive == "0") {
                        alert("User Deactivated!!");
                        window.location.reload()
                    }

                    else {
                        if (response.data[0].ucode == this.state.Ucode) {
                            //if (window.location.protocol == "http:") {
                            //    alert("https");
                            //}
                            //else {
                            //    alert("http");
                            //}
                         
                            localStorage.setItem('isLoggedIn', "true");
                            sessionStorage.setItem('LoggedIn', "true");
                            localStorage.setItem('username', response.data[0].ucode);
                            
                            localStorage.setItem('globalvar',local)
                            localStorage.setItem('utype', response.data[0].userId);
                            localStorage.setItem('location', response.data[0].locationId);
                            localStorage.setItem('utypeid', response.data[0].utypeId);
                            localStorage.setItem('userroleid', response.data[0].userRoleId);
                            localStorage.setItem('email', response.data[0].email);
                            localStorage.setItem('homepage', false);
                           
                            alert("Login Successful..");
                           
                            this.props.history.push('/home')
                        }
                        else {
                            alert("Incorrect Username or Password");
                        }
                    }
                }
                else {
                    alert("Incorrect Username or Password");
                }

            }).catch(err => console.log(err));
    }

   
    render() {
        return (
            /* <div style={{ backgroundImage: `url(${background})`, backgroundsize: "cover",
                backgroundPosition: "center center"
               }}>*/
            <Form id="loginform" onSubmit={this.handleSubmit} >
            <div className="login-wrapper">
               
                        <div className="form-group">
                            <h5>LOG IN</h5>
                            <br/>
                  
                        <label for='username'> USERNAME</label>
                        <input type="text" name="username" id="Ucode"  onChange={this.handleChange}/>
                       
                            <label for='password'> PASSWORD</label>
                            <input type="password"  name="password" id="Upwd" onChange={this.handleChange}/>
                        
                        <span style={{ float: 'left', paddingTop: '15px' }}><a style={{ color: 'white' }} href="" onClick={this.handleClick}> Forgot password?</a></span> 
                            <Button onClick={this.handleSubmit} style={{ float: 'right' }}  type="button" color="primary" className="form__custom-button">
                               SUBMIT
          </Button>
                            </div>
               
                </div >
            </Form>
            /*</div>*/ 
        );
    }
}
export default Login    