import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import pic from "../blank.png";


class Safetywalk extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();

        this.state = {
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            type1: 'Yes',
            type2: 'No',
            type3: 'NA',
            
            rows: [],
            rows1: [],
            rows2:[],
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            file: null,
            imagePreviewUrl: '',
            selectedFile: null,
            image: process.env.PUBLIC_URL + '/assets/blank.png',
            admincode: localStorage.getItem('admincode'),
            prevMonth: prevMonth,
            prevYear: prevYear
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear
        var prevYear=this.state.prevYear
        if (prevMonth == "December") {
            year = prevYear;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyWalkList/GetSafetyWalkListMonthWise', { params: { SafetyMonth: prevMonth, SafetyYear: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows2: response.data
                })

            })
    }



    handleChange = e  => {
       
        e.preventDefault();
        // const { name, value } = e.target;
        const name = parseInt(e.target.name);
      
        const value = e.target.value;
        
       
        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var utype = parseInt(this.state.utype)
        var location = parseInt(this.state.location)
        var admincode = parseInt(this.state.admincode)
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;

        var year = ""

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        var index = name - 1;
        let row = {
            ...rows[index],
            
            "IsRequired": value,
            "SafetyWalkId":name,
            "UserId": utype,
            "LocationId": location,
            "SafetyYear": year,
            "SafetyMonth": prevMonth,

        };
        
        rows[index] = row;
        this.setState({
            rows
        });
       
        let row1 = {
            ...rows1[0],

            "FormId": 1,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo":admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });

    };



    handleChange1 = e => {
  
        e.preventDefault();
        // const { name, value } = e.target;
        const name = parseInt(e.target.name);

        const value = e.target.value;


        const rows = [...this.state.rows];
        var utype = parseInt(this.state.utype)
        var location = parseInt(this.state.location)
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;

        var year = ""

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        var index = name - 1;
        let row = {
            ...rows[index],

            "Comments": value,
            "SafetyWalkId": name,
            "UserId": utype,
            "SafetyYear": year,
            "SafetyMonth": prevMonth,
            "LocationId": location,
            

        };
        
        rows[index] = row;
        this.setState({
            rows
        });
    };

    handleChange2 = e => {
        
        e.preventDefault();
       
       
        const name = parseInt(e.target.name);
        
      
        this.setState({ selectedFile: e.target.files });
        this.setState({ image: URL.createObjectURL(e.target.files[0])});
        console.log(this.state.image)
        let filename = e.target.files[0].name;
        let root = `/Images/` + filename
        
        //const val = e.target.value;
        //var sub=val.substring(12,val.length)
            
        var index = name - 1;
        const rows = [...this.state.rows];
        var utype = parseInt(this.state.utype)
        var location = parseInt(this.state.location)
       
        let row = {
            ...rows[index],

            "UploadedPath": root,
            "SafetyWalkId": name,
            "UserId": utype,
            "LocationId": location,
          

        };
       
        rows[index] = row;
        this.setState({
            rows
        });
    };





    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {
        event.preventDefault();
        
        const { rows } = this.state;
        const { rows1 } = this.state;
        var rows2=this.state.rows2
        
        if (rows2.length == 0) {
            
                this.transferdata();
            
        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }
       
    }


    transferdata = async (e) => {
        
        debugger;
        const { rows } = this.state;
        const { rows1 } = this.state;
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        let newbody = JSON.parse(Options.body);


        const Options1 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows1)
        };
        let checkbody = JSON.parse(Options1.body);

        // Create an object of formData
        const file = new FormData();

        // Update the formData object
        if (this.state.selectedFile != null) {
            file.append("formFile", this.state.selectedFile[0])
            file.append("filename", this.state.selectedFile[0].name)
        }


        axios.all([
            await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyWalkList/SaveSafetyWalkList', newbody),

            axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyWalkList/UploadeFiles', file),

            axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody),

        ]).then(axios.spread((response1, response2,response3) => {
            debugger;
            if (response1.data.Status === 'Success') {
                alert("Data Save Successfully");
                window.location.reload();
                console.log(response1.data.Status);

            }

            else {
                alert('Data Saved Successfully');
                window.location.reload();
            }
        })).catch(error => {
                console.log(error);
              console.log(this.state.admincode)
            });
    }
   
    handleClick1 = () => (event) =>{
       
        this.props.history.push("/safetywalkview"); 
    };


    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label class="no-space-break">

                        Date Of Reporting:&nbsp;{this.state.newdate}  &nbsp;
                        {this.state.longMonth},
                   {this.state.longYear}

                    </label>

                    <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                    <br />
                    <br />

                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white',paddingTop:'10px',paddingBottom:'10px' }}>Safety Walk Check Sheet </h5>

                <div className="col-4">
                    <br />


                    
                    <label  class="no-space-break">Are site evacuation plan posted? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            
                          
                            name="1"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option  value={this.state.type1} >Yes</option>
                            <option  value={this.state.type2} >No</option>
                            <option  value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>


                    
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Are fire extinguishers accessible,serviceable,<br /> and  within due date for refilling?  </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="2"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Is fire alarm and detection system working <br />properly?  </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="3"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">All manual call points are working? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="4"

                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Is sprinkler supply valve open? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="5"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    
                    <label class="no-space-break">Is emergency lighting working properly? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="6"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Are all fire pumps on auto mode? Mention <br />pressure in pump room. </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="7"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Is public address system functional?  </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="8"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Are all exits clear and marked? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="9"
                            
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    
                    <label class="no-space-break">All display items secured properly?  </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="10"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Are there any broken ladders? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="11"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                   
                    <label class="no-space-break">Are safety sweeps getting done as per <br />company guidelines? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="12"
                          
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Does electric room have any material, <br /> equipment stored in it? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="13"
                         
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>
                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Any damaged wire is in use?  </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="14"

                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <label class="no-space-break">Is first aid box well maintained and easily<br /> accessible? </label> <br />
                    <FormControl>

                        <select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            name="15"
                           
                            onChange={this.handleChange}

                        >
                            <option selected disabled value='0'>--Select--</option>
                            <option value={this.state.type1} >Yes</option>
                            <option value={this.state.type2} >No</option>
                            <option value={this.state.type3} >NA</option>

                        </select>

                    </FormControl>
                    <br />  <br />
                    <br />    <br />

                </div>


                <div className="col-3">
                    <br />

                    <div >
                        <div class='box' ><img className="img-fluid1" src={this.state.image} alt='No Image Selected'  width="300" height="250" />
                            </div>
                        <br />
                        <input className="btn btn-primary btn-sm" type="file"  name ="1" onChange={this.handleChange2} /> <br />
                        

                        <br />  <br />


                       
                    </div>

                     
                   
                    <button type="submit" style={{ float: 'right', marginTop: '1350px' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>

                    <br />  <br />   <br />   <br />

                </div>

                <div className="col-5">
                    <br />
                   
                    <div >
                        <label class="no-space-break">Comments: </label><br />
                        <TextField type="text" name="1"  onChange={this.handleChange1}/>
                      
                    </div>
                    <br />
                    <br />
                    
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <br />
                        <TextField type="text" name="2"  onChange={this.handleChange1} />

                    </div>
                    <br />
                    <br />
                    
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        
                        <TextField type="text" name="3"  onChange={this.handleChange1}/>

                    </div>
                    <br />
                    <br />
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <TextField type="text" name="4" onChange={this.handleChange1}/>

                    </div>
                    <br />
                    <br />

                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <TextField type="text" name="5"  onChange={this.handleChange1}/>

                    </div>
                    <br />
                  
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        
                        <TextField type="text" name="6" onChange={this.handleChange1} />

                    </div>
                    <br />
                    <br />
                    
                   
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <br />
                                              <TextField type="text" name="7" onChange={this.handleChange1}/>

                    </div>
                    <br />
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <TextField type="text" name="8"  onChange={this.handleChange1}/>

                    </div>
                    <br />
                    <br />
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <TextField type="text" name="9"  onChange={this.handleChange1} />

                    </div>
                    <br />
                    <br />
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <TextField type="text" name="10"  onChange={this.handleChange1} />

                    </div>
                    <br />
                    <br />

                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <TextField type="text" name="11" onChange={this.handleChange1}/>

                    </div>
                    <br />
                    <br />
                   
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                       
                        <TextField type="text" name="12" onChange={this.handleChange1}/>

                    </div>
                    <br />
                    <br />
                   
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                     
                    <br />

                        <TextField type="text" name="13"  onChange={this.handleChange1}/>

                    </div>
                    <br />
                    <br />
                
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        <TextField type="text" name="14"  onChange={this.handleChange1}/>

                    </div>
                    <br />
                    <br />
                    
                    <div >
                        <label class="no-space-break">Comments: </label> <br />
                        
                        <TextField type="text" name="15"  onChange={this.handleChange1} />

                    </div>
                    <br />
                    <br />

                    </div>
            



                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Safetywalk