import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';

class Adminhistory extends Component {

    constructor(props) {
        super(props)
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
    
        let date = new Date()
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });

        this.state = {
            count: 0,
            type1: 'Approved',
            type2: 'Rejected',
            addModalShow: false,
            types: [],
            rows: [],
            activePage: 15,
            admin: 1,
            utype: localStorage.getItem('utype'),
            month: prevMonth,
            year: longYear,
            startDate: date,
            endDate: date,
        }

    }


    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        localStorage.setItem('homepage', false);
        document.getElementById("navribbon").style.display = "none";
        var utype = localStorage.getItem('utype');
        var location = localStorage.getItem('location');
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if (utype == 3) {
            this.props.history.push('/unauthorized')

        }
 
        if (utypeid == 4) {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/GetAdminHistoryPage', { params: { UserId: 0, LocationId: 0, mode: 4, StartDate: startDate, EndDate: endDate } }).then(response => {
           
                this.setState({
                    rows: response.data
                });
                console.log(this.state.rows)
            });
        }

        else {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/GetAdminHistoryPage', { params: { UserId: 0, LocationId: parseInt(location), mode: 2, StartDate: startDate, EndDate: endDate } }).then(response => {
                debugger;
                this.setState({
                    rows: response.data
                });
                console.log(this.state.rows)
            });
        }
    }

    handlePageChange(pageNumber) {
        
        this.setState({ activePage: pageNumber });
    }
    
    handleRowClick = (id, Userid) => (event) => {
        
        localStorage.setItem('userid', Userid)
        localStorage.setItem('adminhistory', 'true')
        localStorage.setItem('adminrejectedesg', 'false');
        this.props.history.push("/energyview/?id=" + id);
    }
    handleRemoveSpecificRow = (idx, id) => (e) => {
        e.preventDefault();
        axios.delete(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalRawMaterial/DeleteRawMaterial', { params: { TMatId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));
    }
   
     getColor = (status) => {
         if (status === "Approved") return '#DFFF00 ';
         if (status === "Rejected") return '#E84C2B';
         return '#EFF449';
    };

   
    renderRows() {
        return this.state.rows.map((item, idx) => {
            return (
                <tr id="addr0" key={item.id} style={{ backgroundColor: this.getColor(item.Status) }}>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="user" required id="standard-required" defaultValue={item.UserName} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="location" required id="standard-required" defaultValue={item.LocationName} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="date" required id="standard-required" defaultValue={item.SubmissionDate} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="user" required id="standard-required" defaultValue={item.Status} />
                    </td>
                    <td onClick={this.handleRowClick(item.submissionId, item.UserId)} style={{ color: 'blue', textDecoration: 'underline' }}>
                        <a> View  </a>
                    </td>
                </tr>
            );
        });
    }

    handleChange = event => {
        debugger;
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        //const value= new Date(strvalue)
        this.setState({
            [name]: value
        });
    };

    handleChange1 = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]:value
        });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalRawMaterial/GetTotalRawMaterialbyId', { params: { Search: value } }).then(response => {
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        this.setState({
            rows: []
        });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/GetAdminHistoryDataBetweenDates', { params: { UserId: 0, LocationId: 0, mode: 3,startDate: startDate, endDate: endDate } })
            .then(response => {
                this.setState({
                    rows: response.data
                });
                console.log(response.data)
            })
    }

    handleSubmit2 = event => {
        event.preventDefault();
        var location = localStorage.getItem('location');
        var utypeid = localStorage.getItem('utypeid')
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        

        if (utypeid == 4) {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/GetAdminHistoryPage', { params: { UserId: 0, LocationId: 0, mode: 4, StartDate: startDate, EndDate: endDate } }).then(response => {

                this.setState({
                    rows: response.data
                });
                console.log(this.state.rows)
            });
        }

        else {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/GetAdminHistoryPage', { params: { UserId: 0, LocationId: parseInt(location), mode: 2, StartDate: startDate, EndDate: endDate } }).then(response => {
                debugger;
                this.setState({
                    rows: response.data
                });
                console.log(this.state.rows)
            });
        }
        }

    render() {
        return (
            <div>
                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>ESG Summary List </h5>
                <br />
                <Form className="font-link" >
                  <div>
                        <label for="StartDate">START DATE:</label>
                    <input type="date" id="StartDate" name="startDate" onChange={this.handleChange} />
                    &nbsp;
                    <label style={{ marginLeft: '15px',fontWeight:'bold' }} for="EndDate">END DATE:</label>
                        <input type="date" id="EndDate" name="endDate" onChange={this.handleChange} />
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
                        <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit2} type="submit" className="btn btn-success btn-sm">CLEAR</button>

                    </div>
                    <br />
                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                       <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                <th>USER</th>
                                <th>LOCATION</th>
                                <th>SUBMISSION DATE</th>
                                <th>STATUS </th>
                                <th>ACTION </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </Table>
                </Form>
            </div>
        )
    }
}


export default Adminhistory