import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';


class Editnearmiss extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            type1: 'Major',
            type2: 'Serious',
            type3: 'Minor',
            type4: 'Frequent',
            type5: 'Occasional',
            type6: 'Rare',
            rows: [{ id: 0 }],

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";

        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/NearMissIncident/GetIncidentDetailsById', { params: { IncidentId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0]
            });
           


        });
    }

    formatDate(string) {
        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('YYYY-MM-DD')


    }

    handleChange = e => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        const value = e.target.value;

        var rows = this.state.rows;
       

        let row = {
            ...rows,

            [name]: value,
            // "UserId": utype,
            // "LocationId": location,
            //"SiteName":locationName,
            // "YearOfDrill": longYear,
            // "MonthOfDrill": longMonth
        };
        rows = row;
        this.setState({
            rows
        });
    };


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = event => {
       
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/NearMissIncident/UpdateIncidentDetails', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/nearmissview')
            }).catch(err => console.log(err));
    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label class="no-space-break">

                        Date Of Reporting:&nbsp;{this.state.newdate}  &nbsp;
                        {this.state.longMonth},
                   {this.state.longYear}

                    </label>



                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Incident/Near Miss Form </h5>

                <div className="col-1">
                    <br />


                    <div >
                        <label class="no-space-break">Date of incident: </label> <br />
                        <TextField selected type="date" name="dateOfIncident" required id="standard-required" onChange={this.handleChange} value={this.formatDate(this.state.rows.dateOfIncident)}/>
                    </div>
                    <br />
                    <div>
                        <label class="no-space-break">Time of Incident: </label>
                        <br />
                        <TextField
                            name="timeOfIncident"

                            type="time"
                            defaultValue="hh:mm"
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.rows.timeOfIncident}
                            
                        />
                    </div>
                    <br />

                    <div>
                        <label class="no-space-break">Severity potential of incident: </label> <br />
                        <FormControl>

                            <select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                onChange={this.handleChange}
                                name="incidentSeverity"
                                value={this.state.rows.incidentSeverity}
                            >
                                <option selected disabled value='0'>--Select--</option>
                                <option value={this.state.type1} >Major</option>
                                <option value={this.state.type2} >Serious</option>
                                <option value={this.state.type3} >Minor</option>

                            </select>

                        </FormControl>
                    </div>

                </div>
                <div className="col-2">
                    <br />

                    <div >
                        <label class="no-space-break">Estimated loss due to incidents: </label> <br />
                        <TextField type="number" name="estimatedLoss" required id="standard-required" onChange={this.handleChange} value={this.state.rows.estimatedLoss}/>
                    </div>
                    <br />

                    <div >
                        <label class="no-space-break">Exact location of incident: </label> <br />
                        <TextField type="text" name="incidentLocation" required id="standard-required" onChange={this.handleChange} value={this.state.rows.incidentLocation}/>
                    </div>

                    <br />
                    <div>
                        <label class="no-space-break">Probability reoccurance rate: </label> <br />
                        <FormControl>

                            <select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                onChange={this.handleChange}
                                name="probabilityRate"
                                value={this.state.rows.probabilityRate}
                            >
                                <option selected disabled value='0'>--Select--</option>
                                <option value={this.state.type4} >Frequent</option>
                                <option value={this.state.type5} >Occasional</option>
                                <option value={this.state.type6} >Rare</option>

                            </select>

                        </FormControl>
                    </div>

                </div>
                <div>
                    <br />
                    <br />

                    <label class="no-space-break">Brief description of incident: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        onChange={this.handleChange}
                        value={this.state.rows.eventBreif}
                        name="eventBreif"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br />
                    <label class="no-space-break">Root causes of incident: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="rootCause"
                        onChange={this.handleChange}
                        value={this.state.rows.rootCause}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br />
                    <label class="no-space-break">Corrective and preventive action: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="correctiveAction"
                        onChange={this.handleChange}
                        value={this.state.rows.correctiveAction}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />




                </div>






                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editnearmiss