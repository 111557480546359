import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Edittotalexpenditure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            id: 0,
            SubGroupA: '',
            SubGroupB: '',
           
            TotalExpense: ''
        };
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        debugger;
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalExpenditure/GetTotalExpenditureByID', { params: { ExpenditureId: id } }).then(response => {
            console.log(response.data);
            this.setState({
                CatACapex: response.data[0].catAcapex,
                CatAOpex: response.data[0].catAopex,
                CatBCapex: response.data[0].catBcapex,
                CatBOpex: response.data[0].catBopex,
                TotalExpense: response.data[0].totalExpense,
               

                id: id
            });

        });
    }
    handleSubmit = event => {
        
        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalExpenditure/UpdateTotalExpenditure', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push("/energyview/?name=" + "18.Total Expenditure &&" + "id=" + submissionid);

            }).catch(err => console.log(err));
    }

  

    handleChange = event => {
        
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;


        const value = parseFloat(event.target.value);

        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
    
        let row = {

            ...rows,
            ExpenditureId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="CatACapex">Capex(CAT_A)</label>
                        <input type="number" name="CatACapex" step="any" className="form-control" id="CatACapex" defaultValue={this.state.CatACapex} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="CatAOpex">Opex(CAT_A)</label>
                        <input type="text" name="CatAOpex" step="any" className="form-control" id="CatAOpex" defaultValue={this.state.CatAOpex} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="CatBCapex">Capex(CAT_B)</label>
                        <input type="number" name="CatBCapex" step="any" className="form-control" id="CatBCapex" defaultValue={this.state.CatBCapex} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="CatBOpex">Opex(CAT_B)</label>
                        <input type="text" name="CatBOpex" step="any" className="form-control" id="CatBOpex" defaultValue={this.state.CatBOpex} onChange={this.handleChange} />
                    </div>
                </div>

                <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>
            </Form>
        );
    }
}

export default Edittotalexpenditure