import React,{Component} from 'react'
import { Table, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink  } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';



class Sfclabelledmaterial extends Component {

    constructor(props) {
     super(props)
     this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;

        this.state = {
            types: [],
            hasError: false,
            selected:null,
            unit: '',
            validationError: "",
            count: 0,
            counter: 1,
            maxlength: 0,
      type1:'Newspaper',
      type2:'Ink', 
      type3:'Chemical',
      type4:'Plates',
        rows:[{}],
         row1:{ type1:'newspaper', unit1:'metricton', consumed1:'', recycle1:''},
         row2:{ type2:'ink', unit2:'kgs', consumed2:'313', recycle2:''},
         row3:{ type3:'chemical', unit3:'ltr', consumed3:'5050', recycle3:''},
         row4:{ type4:'plates', unit4:'nos', consumed4:'41040', recycle4:''},
            login: [],
            ucode:localStorage.getItem('username'),
            utype:localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
             longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            check: "",

            isOpen: false
     }
    } 

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var sfclabelled = JSON.parse(localStorage.getItem("sfclabelled"));
        var rows = this.state.rows;

        if (sfclabelled != null) {
            rows = sfclabelled

            this.setState({
                rows
            })
        }
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalRawMaterial/GetType')
            .then(response => {
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi,
                    maxlength: typesapi.length
                });
                   // ucode=localStorage.getItem('username'),
                   // utype=localStorage.getItem('utype'),
                   // location=localStorage.getItem('location')

        })
          //  .then(data => {
               // let typesapi = Object.keys(data.type);
                //console.log(typesapi);
       
                
               
        //    })
            .catch(error => {
                console.log(error);

            });
    }  

    openModal = (e) => {


        this.setState({ isOpen: true }, () => {
            window.setTimeout(() => {
                this.setState({ isOpen: false })
            }, 2000)
        });

    }

    closeModal = () => this.setState({ isOpen: false });

    handleChange = (idx) => (e) => {
        
      e.preventDefault();
      // const { name, value } = e.target;
      const name=e.target.name;
        var value = 0;
        //let id=name+(idx);
        if (name == "RMasterId" || name == "Quantity" || name == "EcoLabelledQuantity") {
            value = parseFloat(e.target.value);

        }
        else {
            value = e.target.value;
        }
      const rows = [...this.state.rows];
        var utype = parseInt(this.state.utype)
        var location = parseInt(this.state.location)
        var Reference = localStorage.getItem("globalvar")
      let row = {
        ...rows[idx],
         id:idx,
        [name]: value,
          "UserId": utype,
          "LocationId": location,
          "ReferenceId":Reference

      };
      rows[idx]=row;
      this.setState({
      rows
      });
    };


    handletotal = (idx) => (e) => {
        

        e.preventDefault();

        const rows = [...this.state.rows];
        var value = (parseFloat(rows[idx].EcoLabelledQuantity)/parseFloat(rows[idx].Quantity))*100
        rows[idx].percentage = Math.round(value *100)/100
        this.setState({
            rows
        });
    };

  
    // handleTypeChange = event => {
    //   
    //   this.setState({
    //   type1: event.target.value,
      
    //     })
    //    }
      
          
    // handleUnitChange = event => {
    //   this.setState({
    //   unit1: event.target.value,
    //     })
    //   }
    //   handleConsumedChange = event => {
    //     this.setState({
    //     consumed1: event.target.value,
    //       })
    //      }
    //      handleRecycleChange= event => {
    //       this.setState({
    //       recycle1: event.target.value,
    //         })
    //        }
                          handleAddRow =() => (event) => {
                              this.setState({ selected: null });
                            let count=this.state.count
                            count=count+1;
                            this.setState({count})
                              let counter = this.state.counter;
                              counter = counter + 1;
                              this.setState({
                                  counter
                              })
                            // this.setState((prevState) => ({ count: prevState.count + '1'}));
                         
                            const item = {
                                id: count,
                                unit:''
                              // type: "",
                              // unit: "",
                              // consumed: "",
                              // recycle:""
                              
                            };
                            event.preventDefault();
                            this.setState({
                              rows: [...this.state.rows, item]
                           
                              
                            });
                          };
                          // handleRemoveRow = (event) => {
                          //   event.preventDefault();
                          //   this.setState({
                          //     rows: this.state.rows.slice(0, -1)
                          //   });
                          // };
                          handleRemoveSpecificRow = (idx) => (e) => {

                              e.preventDefault();
                              let count = this.state.count
                              count = count-1;
                              this.setState({ count })
                            let rowarr = [...this.state.rows];
                            let deletedItem = rowarr.findIndex(item => item.id === idx);
                            rowarr.splice(deletedItem, 1);
                            this.setState({...this.state.rows=rowarr})
                              let counter = this.state.counter;
                              counter = counter - 1;
                              this.setState({
                                  counter
                              })
                            // let row = [...this.state.rows.filter(x=>x.id!==idx)];
                            // this.setState({rows:row})
                          
                            


                            //  const rows = [...this.state.rows]
                            //   rows.splice(idx,1)
                            //   this.setState({ rows })
                            //   e.preventDefault()
                          //  var newrows=this.state.rows.slice();
                          //  newrows.splice(idx,1);
                          //  this.setState({ rows:newrows })

                          // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
                            //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
                            //  this.setState({
                            //    rows: newRows,
                            //  });
                         
    }
    handleChange1 = (idx) => (e) => {


        e.preventDefault();

       // var index = this.getIndex(e.target.value);
        this.setState({ hasError: false });
        // const { name, value } = e.target;
        const name = e.target.name;
        var value = 0;
        var value1 = "";
        //let id=name+(idx);
        if (name == "RMasterId" || name == "Quantity" || name == "EcoLabelledQuantity") {
            value = parseInt(e.target.value);

        }
        else {
            value = e.target.value;
        }
        this.setState({ selected: value });
       // const types = [...this.state.types];
       // var type = types[index].unit

        const login = [...this.state.login]
        let utype = this.state.utype;
        let location = this.state.location;
        let row1 = {

            "FormId": parseInt(value),
            "FormSlug": "RawMaterial",
            "LocationId": parseInt(location),
            "UserId": parseInt(utype),
            "SubmittedTo": 1



        };
        login[idx] = row1;
        this.setState({
            login
        });
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        //var year = 0

        //var prevYear = this.state.prevYear;
        //if (prevMonth == "December") {
        //    year = prevYear
        //}
        //else {
        //    year = longYear
        //}

        const rows = [...this.state.rows];
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
     //       "unit": type,
            "Year": year,
            "Month": month
           
        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };

    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }
    
    handleSubmit = event => {
       
        event.preventDefault();
        
      
        if (!this.state.selected) {
            this.setState({ hasError: true });
            return
        }
        const { rows } = this.state;
        //var prevMonth = this.state.prevMonth;
        //var year = 0;
        //var longYear = this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year = longYear
        //}
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');
        var id = localStorage.getItem('utype')
        //axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SfcLabelledMaterial/GetSfcLabelledMaterialByMonth', { params: { Month: month, Year: year, UserId: id } })
        //    .then(response => {
                
        //        if (response.data.length == 0) {


                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    //  let newbody = JSON.parse(Options.body);

                    localStorage.setItem('sfclabelled', Options.body);
                    let newbody = JSON.parse(Options.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SaveSFCLabelmaterialstemp', newbody).then(response => {

                        if (response.data == 1) {
                            this.openModal()
                        }

                        else {

                            alert("Data Not Saved.Kindly Save Again")
                        }

                    })

            //    }
            //    else {

            //        alert("Data Already Submitted")
            //    }

            //})
               
       
      /*  const Options1 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(login)
        };
        let checkbody = JSON.parse(Options1.body);
        axios.all([
            axios.post('http://localhost:23013/api/TotalRawMaterial/SaveTotalRawMaterials', newbody),

            axios.post('http://localhost:23013/api/UserSubmission/SaveUserSubmission', checkbody)

        ]).then(axios.spread((response1, response2) => {

            if (response1.data.Status === 'Success') {
                alert("Data Save Successfully");
                window.location.reload();
                console.log(response1.data.Status);

            }

            else {
                alert('Data Saved Successfully');
                window.location.reload();
            }
        }));*/

      
             }   
    isDisabled() {
     
        var counter = this.state.counter;
        var maxlength = this.state.maxlength;
        if (counter == maxlength) {

            return true;

        }
        else {
            return false;
        }

    }  

    isDisabled1() {

        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }

    renderRows() {
        const { hasError } = this.state;
      return this.state.rows.map((item, idx) => {
        return(
        
        <tr id="addr0" key={item.id}>
       
         
        
        <td>  
                    <FormControl error={hasError}>
       
        <Select  isSearchable required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="rmasterId"
                            onChange={this.handleChange1(idx)}
                            value={this.state.rows[idx].rmasterId}
                        >
                            {this.state.types.map(type => (
                                <MenuItem 
                                    key={type.rmasterId}
                                    value={type.rmasterId}
                                >
                                    {type.type}
                                </MenuItem>
                            ))}
                            {/*   <MenuItem value={this.state.row1.type1} >Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem> */}
        </Select>
                        {hasError && <FormHelperText>This is required!</FormHelperText>}
      
      </FormControl> </td>
             
      

       <td>
                    <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="Quantity" required id="standard-required" onKeyUp={this.handletotal(idx)} onChange={this.handleChange(idx)} value={this.state.rows[idx].Quantity} />
                    
       </td >
       <td>
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} name="EcoLabelledQuantity" required id="standard-required" onKeyUp={this.handletotal(idx)} onChange={this.handleChange(idx)} value={this.state.rows[idx].EcoLabelledQuantity}/>
                </td>   
                <td>
                    <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" name="percentage" value={this.state.rows[idx].percentage} />
                </td>  
       <td>
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx)}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>                          
                        </button>
                        
                      </td>
    </tr>
       
    );
  });
    }




   render() {
       const text ="Percentage of materials that carry\neco-labels (e.g, FSC, PEFC, SFI etc)"
       const { hasError } = this.state;
       return (
          <Form className="font-link" onSubmit={this.handleSubmit}>
              
            <br />
              {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
              <div className="App">
                  <div className="wordalign">
                  {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                 
                  <label>
                      {this.state.longMonth}   &nbsp;
                   {this.state.longYear}
  &nbsp;&nbsp;  
                  <Tooltip
                               arrow={15}
                               background="#ffffff"
                               border="black"
                               color="black"
                               content={text.split("\n").map((i, key) => {

                                   return <div key={key}>{i}</div>;


                               })}
                               fadeDuration={0}
                               fadeEasing="linear"
                               fixed={false}
                               fontFamily="Bw Glenn Sans Medium"
                               fontSize="12px"
                               offset={-20}
                               padding={15}

                               placement="right"
                               radius={50}
                               zIndex={1}

                           >
                               <span className="btn btn-outline-danger btn-sm">

                                   info
                               {/*<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                               </span>
                           </Tooltip>

                  </label>
                     
                       <button onClick={this.handleAddRow()} style={{ float: 'right' }} disabled={this.isDisabled()} className="btn btn-info btn-md">+ADD</button>
                  </div>
               </div>
               <Modal style={{ backgroundColor: 'white', width: '500px', height: '200px', borderColor: '#ffffff00' }}

                   show={this.state.isOpen} onHide={this.closeModal}>

                   <Modal.Header style={{ backgroundColor: '#001946', color: 'white' }} >
                       <Modal.Title>Message</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                       Data Saved Successfully

                          </Modal.Body>
               </Modal>
               <br />
              <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                  <thead>
                       <tr style={{ backgroundColor: '#001946', color: 'white' }}>
            <th>TYPE(DIRECT MATERIAL)</th>
           
            <th>TOTAL QUANTITY(MT)</th>
            <th>QUANTITY MARKED AS ECO LABEL</th>
            <th>PERCENTAGE(%)</th>
            <th>ACTION</th>
                     


            
          </tr>
        </thead>
        <tbody>

        {/* {this.state.rows.map((item, idx) => (
      
        
        <tr id="addr0" key={item.id}>
       
         <td>{idx}</td> 
        
        <td>  
        <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="type"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.type1}>Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem>
        </Select>
       
      </FormControl> </td>
       
       <td>
       <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="unit"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.unit1}>Metric Ton</MenuItem>
          <MenuItem value={this.state.row2.unit2}>Kgs</MenuItem>
          <MenuItem value={this.state.row3.unit3}>Ltr</MenuItem>
          <MenuItem value={this.state.row4.unit4}>Nos</MenuItem>
        </Select>
       
      </FormControl>
       </td>

       <td>
       <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="consumed"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.consumed1}>Value</MenuItem>
          
        </Select>
       
      </FormControl>
       </td >
       <td>
       <TextField   name="recycled" required id="standard-required"  onChange={this.handleChange(idx)} defaultValue={this.state.rows.recycle}/>
       </td>    
       <td>
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx)}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>                          
                        </button>
                        
                      </td>
                      
    </tr>
    
       
   ))}
        */}
          {this.renderRows()} 
           
       </tbody>
       </Table>

   

      
               <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit} disabled={this.isDisabled1()}>SAVE</button> 

     {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
       
     
       </Form>
           
      )
    }
}

export default Sfclabelledmaterial