import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';


class Editrwharvestingmaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: '',
            id: 0,
            type: '',
            unit: '',

        };
    }


    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin ==null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/RainHarvestMaster/GetRainHarvestMasterByID', { params: { HarvestId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                harvetingType: response.data[0].harvetingType,
                unit: response.data[0].unit,
                id: id
            });

        });
    }


    handleSubmit = event => {
        
        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/RainHarvestMaster/UpdateRainHarvestMaster', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Data Save Successfully");
                    this.props.history.push('/rwharvestingmaster')
                }

                else {
                    alert('Data Saved Successfully');
                    this.props.history.push('/rwharvestingmaster')
                }
            })
    }

    handleChange = event => {

        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        const value = event.target.value;
        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
            harvestId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="type">Type</label>
                        <input name="harvetingType" className="form-control" id="harvetingType" defaultValue={this.state.harvetingType} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="unit">Unit</label>
                        <input name="unit" className="form-control" id="unit" defaultValue={this.state.unit} onChange={this.handleChange} />
                    </div>
                </div>


                <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Update</button>
            </Form>
        );
    }
}

export default Editrwharvestingmaster