 import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editdirectghgemission1 extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {

            count: 0,

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: ''


        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectGhgEmission1/GetDirectEmissiononeById', { params: { DirectId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0]


            });

        });


    }









    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name


        const value = parseFloat(e.target.value)


        var rows = this.state.rows;

        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };




    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectGhgEmission1/UpdateDirectEmissiononeDetails', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push("/energyview/?name=" + "6.Direct GHG Emission(Scope 1) &&" + "id=" + submissionid);
            }).catch(err => console.log(err));


    }
   


    handletotal  = e => {


        e.preventDefault();

        var rows = this.state.rows;
        rows.scope1 = (parseFloat(rows.refill) * parseFloat(rows.global)) / 1000

        this.setState({
            rows
        });
    };


    handletotal1 =  e => {


        e.preventDefault();

        var rows = this.state.rows;
        rows.scope2 = parseFloat(rows.heatValue) * (parseFloat(rows.emissionFactor1) + parseFloat(rows.emissionFactor2) * 28 + parseFloat(rows.emissionFactor3 * 265)) / 1000

        this.setState({
            rows
        });
    };


    handletotal2 = (idx1, idx2) => (e) => {

        
        e.preventDefault();

        var rows = this.state.rows;
        rows[idx1].total = parseFloat(rows[idx1].scope1) + parseFloat(rows[idx1].scope2) + parseFloat(rows[idx2].scope1) + parseFloat(rows[idx2].scope2)

        rows[idx2].total = parseFloat(rows[idx1].scope1) + parseFloat(rows[idx1].scope2) + parseFloat(rows[idx2].scope1) + parseFloat(rows[idx2].scope2)

        this.setState({
            rows
        });
    };





    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Edit Direct Ghg Emission</h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Refrigerant">
                                    <label class="no-space-break">Refrigerant</label>
                                    <input required name="refrigerant" type="text"  class="form-control" defaultValue={this.state.rows.refrigerant} onChange={this.handleChange} onKeyUp={this.handletotal}/>
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Refill">
                                    <label class="no-space-break" >Refill</label>
                                    <input required name="refill" type="number" step="any" class="form-control" defaultValue={this.state.rows.refill} onChange={this.handleChange} onKeyUp={this.handletotal}/>
                                </div>
                            </div>


                        </div>



                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Global">
                                    <label class="no-space-break">Global</label><input required name="global" type="number" step="any" class="form-control" defaultValue={this.state.rows.global} onChange={this.handleChange} onKeyUp={this.handletotal}  />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="scope1">
                                    <label class="no-space-break">Scope 1</label><input disabled name="scope1" type="number" step="any" class="form-control" value={this.state.rows.scope1}  />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="FuelType">
                                    <label class="no-space-break">Fuel Type</label><input disabled name="fuelType" type="text" class="form-control" defaultValue={this.state.rows.fuelType}  />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="HeatValue">
                                    <label class="no-space-break">Heat Value</label><input name="heatValue" type="number" step="any" class="form-control" defaultValue={this.state.rows.heatValue} onChange={this.handleChange} onKeyUp={this.handletotal1} />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="EmissionFactor1">
                                    <label class="no-space-break">Emission Factor 1</label>
                                    <input required name="emissionFactor1" type="number" step="any" class="form-control" defaultValue={this.state.rows.emissionFactor1} onChange={this.handleChange} onKeyUp={this.handletotal1}/>
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="EmissionFactor2">
                                    <label class="no-space-break" >Emission Factor 2</label>
                                    <input required name="emissionFactor2" type="number" step="any"  class="form-control" defaultValue={this.state.rows.emissionFactor2} onChange={this.handleChange} onKeyUp={this.handletotal1}/>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="EmissionFactor3">
                                    <label class="no-space-break" >Emission Factor 3</label>
                                    <input required name="emissionFactor3" type="number" step="any" class="form-control" defaultValue={this.state.rows.emissionFactor3} onChange={this.handleChange} onKeyUp={this.handletotal1} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="scope2">
                                    <label class="no-space-break">Scope 2</label><input disabled name="scope2" type="number" class="form-control" value={this.state.rows.scope2}  />
                                </div>
                            </div>
                            


                        </div>


                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editdirectghgemission1