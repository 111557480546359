import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editdeconsumption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows:[],
            id:0,
            fuelType: '',
            unit: '',
            densityNtp: '',
            calorificFuelVal: '',
            quantity:''
        };
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/DirectEnergyConsumption/GetDirectEnergybyId', { params: { IndEnergyConId: id } }).then(response => {
           console.log(response.data);
            this.setState({
                rows:response.data[0],
                fuelType: response.data[0].fuelType,
                
                densityNtp: response.data[0].densityNtp,
                calorificFuelValue: response.data[0].calorificFuelValue,
                quantityltr: response.data[0].quantityltr,
                fuelConsumption: response.data[0].fuelConsumption,
                heatValue: response.data[0].heatValue,
                quantity: response.data[0].quantity,
                id:id
            });
           
        });
    }

    handletotal () {


     
        var rows = this.state.rows;
        rows.fuelConsumption = rows.quantityltr * rows.densityNtp

        this.setState({
            rows
        });
    };

    handletotal1(){
        


        var rows = this.state.rows;
        rows.heatValue = (rows.fuelConsumption * rows.calorificFuelValue) / 1000000

        this.setState({
            rows
        });
    };

    handletotal2() {

            
    
        var rows = this.state.rows;
        rows.quantity = rows.heatValue * 277.78
     
        this.setState({
            rows
        });
    };

    handleSubmit = event => {
        
        event.preventDefault();
        let row = this.state.rows;
       
        const Options = {
            method: 'PUT',
           // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
       // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/DirectEnergyConsumption/UpdateDirectEnergy', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                
                this.props.history.push("/energyview/?name=" + "1.Non Renewable Fuels Consumed &&" +"id="+ submissionid);
            }).catch(err => console.log(err));
    }

    handleChange = event => {
      
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;
        var value = 0;
        if (name == "quantityltr")
            {
            value = event.target.value;
        }
        else {
            value = parseInt(event.target.value);
        }
        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {
            
            ...rows,
            
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







   // handleChange = (event) => {
     //   this.setState({ type: event.target.value });
   // }




  //  handleSubmit = (event) => {
    //    event.preventDefault();
      //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
   // }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="type">Fuel Type</label>
                        <input disabled name="fuelType" className="form-control" id="fuelType" defaultValue={this.state.fuelType} />
                    </div>
                </div>
               
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="densityNtp">Density Ntp</label>
                        <input disabled name="densityNtp" className="form-control" id="densityNtp" defaultValue={this.state.densityNtp} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="">Calorific Value</label>
                        <input disabled required type="number" name="calorificFuelVal" className="form-control" id="calorificFuelVal" defaultValue={this.state.calorificFuelValue}  />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label >Qty Used</label>
                        <input required type="number" name="quantityltr" step="any" className="form-control" id="quantityltr" defaultValue={this.state.quantityltr} onKeyUp={() => { this.handletotal(); this.handletotal1();this.handletotal2() }} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label >Fuel Consumption </label>
                        <input required type="number" name="fuelConsumption" className="form-control" id="fuelConsumption" value={this.state.rows.fuelConsumption}  onClick={this.handletotal} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label >Heat Value </label>
                        <input required type="number" name="heatValue" className="form-control" id="heatValue" value={this.state.rows.heatValue} onKeyUp={this.handletotal1} onClick={this.handletotal1} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label >Qty Consumed </label>
                        <input  type="number" step="any" name="quantity" className="form-control" id="quantity" value={this.state.rows.quantity} onKeyUp={this.handletotal2} onClick={this.handletotal2} />
                    </div>
                </div>
                <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>
            </Form>
        );
    }
}

export default Editdeconsumption