import { Chart } from "react-google-charts";
import React, { useState, useEffect, Component } from "react";


import axios from "axios";
import "./custom.css"
import Switch from "react-switch";




class Companyperformance extends Component {


    constructor(props) {

        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {

            Data: [],

            utype: localStorage.getItem('utype'),
            esg: 0,
            ehs: 0,
            compliance: 0,
            compliancedata: [],
            environmentdata: 0,
            safetydata: 0,
            complianceindex:0,
            rows: [],
           
            location: localStorage.getItem("location"),
            locations:[],
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            month: prevMonth,
            year:longYear,
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            checked: false,
            mode: 2,
            level: 0,
         
            
        };

    }
   



   
    componentWillUnmount() {
        if (this.intervalID === null) return;
        clearInterval(this.intervalID);
    }

    async componentDidMount() {
    debugger;
        localStorage.setItem('homepage', false);
       document.getElementById("navribbon").style.display = "none";
       document.getElementById('location').style.display = 'none';
       document.getElementById("advanced").style.display = "none"
       var utypeid = localStorage.getItem("utypeid")
       var location = localStorage.getItem("location")
       
       var month = this.state.month;
        var year = this.state.year
        var mode=this.state.mode
       var startDate = new Date('01' + '-' + month + '-' + year);
        var endDate = new Date('30' + '-' + month + '-' + year);
        await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/CompanyPerformance/GetEnvironmentComplianceScore', { params: { startDate: startDate, endDate: endDate, LocationId: location } })
           .then(response => {
               debugger;

               let environmentdata = parseInt(response.data);

               this.setState({
                   environmentdata: environmentdata
               })
           })

           .catch(error => {
               console.log(error);

        });
       
        
        await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/LocationMaster/GetTotalSafetyScore', { params: { startDate: startDate, endDate: endDate, LocationId: location } })
           .then(response => {
          
              
               let safety = response.data.result;
         
               let safetydata = parseInt(safety.substring(13, 16));

               this.setState({
                   safetydata: safetydata
               })
           })

           .catch(error => {
               console.log(error);

           });
       if (utypeid == 2) {
           await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/CompanyPerformance/GetCompanyComplianceDataLocationWise', { params: { LocationId: location, startDate: startDate, endDate: endDate } })
               .then(response => {


                   if (response.data.length > 0) {
                       let compliancedata = response.data;
                       var totalcompliance = 0;
                       var totalnoncompliance = 0;
                       var totalnotapplicable = 0;
                       var total = 97;
                       for (var i = 0; i < compliancedata.length; i++) {
                           totalcompliance = totalcompliance + compliancedata[i].compliance
                           totalnoncompliance = totalnoncompliance + compliancedata[i].nonCompliance
                           totalnotapplicable = totalnotapplicable + compliancedata[i].nonApplicable
                       }
                       debugger;
                       var actualtotal = total - totalnotapplicable
                       var complianceindex = parseInt(totalcompliance / actualtotal )* 100;
                       this.setState({
                           complianceindex: complianceindex
                       })
                   }
               })

               .catch(error => {
                   console.log(error);

               });

       }
       else {
         
           await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/CompanyPerformance/GetCompanyComplianceData', { params: { startDate: startDate, endDate: endDate,mode:mode } })
               .then(response => {


                   if (response.data.length > 0) {
                       let compliancedata = response.data;

                       
                       var totalcompliance = 0;
                       var totalnoncompliance = 0;
                       var totalnotapplicable = 0;
                       var total = 97;
                       for (var i = 0; i < compliancedata.length; i++) {
                           totalcompliance = totalcompliance + compliancedata[i].compliance
                           totalnoncompliance = totalnoncompliance + compliancedata[i].nonCompliance
                           totalnotapplicable = totalnotapplicable + compliancedata[i].nonApplicable
                       }

                       var actualtotal = total - totalnotapplicable
                       var complianceindex = parseInt(totalcompliance / actualtotal) * 100;
                       this.setState({
                           complianceindex: complianceindex
                       })
                   }
               })

               .catch(error => {
                   console.log(error);

               });

           

        }
       
        this.intervalID = setInterval(() => {
            this.setState(state => {
              
                return {
                    ...state,
                    ehs: this.state.safetydata,
                    esg: this.state.environmentdata,
                    compliance:this.state.complianceindex
                };
            });
        }, 5000);
       
        if (utypeid == 1 || utypeid == 2 || utypeid == 3) {
            document.getElementById('level').style.display = 'none';
            document.getElementById('location').style.display = 'inline';
             axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboard/GetUserRoleLocation')
                .then(response => {
                    
                    let typesapi1 = response.data;
                    
                    var actloc = location - 1;
                    this.setState({
                        locations: typesapi1,

                    })
                    var locations = this.state.locations
                    this.state.location = locations[actloc].locationId

                })

                .catch(error => {
                    console.log(error);

                });

        }

        else {

            document.getElementById('level').style.display = 'inline';
            document.getElementById('location').style.display = 'none';
            this.setState({
                locations: [],
                location: 0
            })
        }
    }
 
    intervalID = null;
    getData = () => {
        
        return [
            ["Label", "Value"],
            
            ["", this.state.safetydata],
            ["",this.state.environmentdata],
            ["",this.state.complianceindex]
           
        ];
    };

    async handledata(startDate,endDate) {
       debugger;
        var utypeid = localStorage.getItem("utypeid")
        var location = this.state.location
      var mode=this.state.mode
        
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CompanyPerformance/GetEnvironmentComplianceScore', { params: { startDate: startDate, endDate: endDate, LocationId: location } })
           .then(response => {

               debugger;
              
               let environmentdata = response.data;

               this.setState({
                   environmentdata: environmentdata
               })
           })

           .catch(error => {
               console.log(error);

           });
        await  axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/LocationMaster/GetTotalSafetyScore', { params: { startDate: startDate, endDate: endDate, LocationId: location } })
           .then(response => {

               debugger;
               let safety = response.data.result;

               let safetydata = parseInt(safety.substring(13, 16));

               this.setState({
                   safetydata: safetydata
               })
           })

           .catch(error => {
               console.log(error);

           });
        if (utypeid == 2) {
            await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CompanyPerformance/GetCompanyComplianceDataLocationWise', { params: { LocationId: location, startDate: startDate, endDate: endDate } })
                .then(response => {
                    debugger;


                    if (response.data.length > 0) {
                        let compliancedata = response.data;

                        var totalcompliance = 0;
                        var totalnoncompliance = 0;
                        var totalnotapplicable = 0;
                        var total = 97;
                        for (var i = 0; i < compliancedata.length; i++) {
                            totalcompliance = totalcompliance + compliancedata[i].compliance
                            totalnoncompliance = totalnoncompliance + compliancedata[i].nonCompliance
                            totalnotapplicable = totalnotapplicable + compliancedata[i].nonApplicable
                        }

                        var actualtotal = total - totalnotapplicable
                        var complianceindex = totalcompliance / actualtotal * 100;
                        this.setState({
                            complianceindex: complianceindex
                        })
                    }
                })

                .catch(error => {
                    console.log(error);

                });

         
            
        }
        else {
 
            await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CompanyPerformance/GetCompanyComplianceData', { params: { startDate: startDate, endDate: endDate,mode:mode } })
                .then(response => {

                    debugger;

                                                                                                                                              
                    if (response.data.length > 0) {
                        let compliancedata = response.data;

                        var totalcompliance = 0;
                        var totalnoncompliance = 0;
                        var totalnotapplicable = 0;
                        var total = 97;
                        for (var i = 0; i < compliancedata.length; i++) {
                            totalcompliance = totalcompliance + compliancedata[i].compliance
                            totalnoncompliance = totalnoncompliance + compliancedata[i].nonCompliance
                            totalnotapplicable = totalnotapplicable + compliancedata[i].nonApplicable
                        }

                        var actualtotal = total - totalnotapplicable
                        var complianceindex = parseInt(totalcompliance / actualtotal * 100);
                        this.state.complianceindex = complianceindex
                    }
                })
                  
                .catch(error => {
                    console.log(error);

                });
            
           
        }
       
       this.intervalID = setInterval(() => {
           this.setState(state => {
               return {
                   ...state,

                   ehs: this.state.safetydata,
                   esg: this.state.environmentdata,
                   compliance: this.state.complianceindex
               };
           });
       }, 0);
       
          
    
    }

    async handledata1(startDate, endDate) {
        debugger;
        var utypeid = localStorage.getItem("utypeid")
        var location = this.state.location
        var mode = this.state.mode
        var level=this.state.level
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CompanyPerformance/GetEnvironmentComplianceScore', { params: { startDate: startDate, endDate: endDate, LocationId: location } })
            .then(response => {

                debugger;

                //let environmentdata = response.data.filter(level == 1 || level == 3 ? x=> x.userRoleId == level : null);

                this.setState({
                    environmentdata: 0
                })
            })

            .catch(error => {
                console.log(error);

            });
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/LocationMaster/GetTotalSafetyScore', { params: { startDate: startDate, endDate: endDate, LocationId: location } })
            .then(response => {

                debugger;

                //let safetydata = response.data.filter(level == 1 || level == 3 ? x => x.userRoleId == level : null);

                this.setState({
                    safetydata: 0
                })
            })

            .catch(error => {
                console.log(error);

            });
        if (utypeid == 2) {
            await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CompanyPerformance/GetCompanyComplianceDataLocationWise', { params: { LocationId: location, startDate: startDate, endDate: endDate } })
                .then(response => {
                    debugger;


                    if (response.data.length > 0) {
                        //let compliancedata = response.data.filter(level == 1 || level == 3 ? x => x.userRoleId == level : null);

                        //var totalcompliance = 0;
                        //var totalnoncompliance = 0;
                        //var totalnotapplicable = 0;
                        //var total = 97;
                        //for (var i = 0; i < compliancedata.length; i++) {
                        //    totalcompliance = totalcompliance + compliancedata[i].compliance
                        //    totalnoncompliance = totalnoncompliance + compliancedata[i].nonCompliance
                        //    totalnotapplicable = totalnotapplicable + compliancedata[i].nonApplicable
                        //}

                        //var actualtotal = total - totalnotapplicable
                        //var complianceindex = totalcompliance / actualtotal * 100;
                        this.setState({
                            complianceindex: 0
                        })
                    }
                })

                .catch(error => {
                    console.log(error);

                });



        }
        else {

            await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/CompanyPerformance/GetCompanyComplianceData', { params: { startDate: startDate, endDate: endDate, mode: mode } })
                .then(response => {

                    debugger;


                    //if (response.data.length > 0) {
                    //    let compliancedata = response.data.filter(level == 1 || level == 3 ? x => x.userRoleId == level : null);

                    //    var totalcompliance = 0;
                    //    var totalnoncompliance = 0;
                    //    var totalnotapplicable = 0;
                    //    var total = 97;
                    //    for (var i = 0; i < compliancedata.length; i++) {
                    //        totalcompliance = totalcompliance + compliancedata[i].compliance
                    //        totalnoncompliance = totalnoncompliance + compliancedata[i].nonCompliance
                    //        totalnotapplicable = totalnotapplicable + compliancedata[i].nonApplicable
                    //    }

                    //    var actualtotal = total - totalnotapplicable
                    //    var complianceindex = parseInt(totalcompliance / actualtotal * 100);
                        this.state.complianceindex = 0
                    //}
                })

                .catch(error => {
                    console.log(error);

                });


        }

        this.intervalID = setInterval(() => {
            this.setState(state => {
                return {
                    ...state,

                    ehs: this.state.safetydata,
                    esg: this.state.environmentdata,
                    compliance: this.state.complianceindex
                };
            });
        }, 0);



    }
    getLast3Months() {

        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        var today = new Date();
        var last3Months = []
        var month = today.getMonth();
        var i = 0;
        do {

            if (month == 0) {
                month = 11;

            } else {
                month--;
            }
            last3Months.push(monthNames[month]);
            i++;
        }

        while (i < 3)

        return last3Months;
    }
     handleChange = (e) => {
        e.preventDefault();
         debugger;
         const value = parseInt(e.target.value);
         this.state.level=value
        
         var month = this.state.month;
         var year = this.state.year
         
         var startDate = new Date('01' + '-' + month + '-' + year);
         var endDate = new Date('30' + '-' + month + '-' + year);
         if (value == 1 || value == 3) {

         


             this.setState({
                 locations: []
             })
            document.getElementById('location').style.display = 'inline';
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/GetUserRoleLocation', { params: { UserRoleId: value } })
                .then(response => {
                    let typesapi1 = response.data;
                    console.log(typesapi1);
                    this.setState({
                        locations:typesapi1
                    })

                })

                .catch(error => {
                    console.log(error);

                });
            
             this.handledata1(startDate, endDate);
        }

        else if (value == 0) {
            document.getElementById('location').style.display = 'none';
             this.setState({
                 locations: []
             })
             this.handledata(startDate, endDate);
        }

        else {

             document.getElementById('location').style.display = 'none';
             this.handledata(startDate, endDate);
        }
    };

    handleChange1 = (e) => {
        debugger;
        var startDate = new Date('01' + '-' + 'January' + '-' + '2022');
        var endDate = new Date('31' + '-' + 'December' + '-' + '2022');
        var location=parseInt(e.target.value)
        this.state.location = location;
         
         this.handledata(startDate, endDate);
    }

    handleChange2 = event => {
        debugger;
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;      
        if (name == "month") {
            this.state.month = value;
        }

        else {

            this.state.year = value;
        }
        var month = this.state.month;
        var year = this.state.year;
        var startDate = new Date('01' + '-' + month + '-' + year);
        var endDate = new Date('31' + '-' + month + '-' + year);
        this.handledata(startDate,endDate);
    };

    handleChange3 = event => {
        var checked=this.state.checked
        this.setState({ checked: !checked })

        if (checked == false) {

            document.getElementById("month").style.display = "none"
            document.getElementById("year").style.display = "none"
            document.getElementById("advanced").style.display = "inline"
        }

        else {
            document.getElementById("month").style.display = "inline"
            document.getElementById("year").style.display = "inline"
            document.getElementById("advanced").style.display = "none"
            var month = this.state.month;
            var year = this.state.year;
            var startDate = new Date('01' + '-' + month + '-' + year);
            var endDate = new Date('31' + '-' + month + '-' + year);
             this.handledata(startDate,endDate);
        }
        
    };

    handleChange4 = event => {
        debugger;
        const value = event.target.value
        if (value == "LastThreeMonths") {
            var year = this.state.longYear;
            let months = this.getLast3Months();
            var sDate = new Date('01' + '-' + months[2] + '-' + year);
            var eDate = new Date('31' + '-' + months[0] + '-' + year);
            var startDate = sDate.toISOString().substring(0, 10);
            var endDate = eDate.toISOString().substring(0, 10);
            this.handledata(startDate, endDate);
        }
        else if (value == "ThisYear") {
            var year = this.state.longYear;
            
            this.setState({
                mode:2
            })
            var startDate = new Date('01' + '-' + 'January' + '-' + year);
            var endDate = new Date('31' + '-' + 'December' + '-' + year);
            this.handledata(startDate,endDate);
        }

        else {
            var year = this.state.prevYear;
            this.setState({
                mode: 2
            })
            var startDate = new Date('01' + '-' + 'January' + '-' + year);
            var endDate = new Date('31' + '-' + 'Decemeber' + '-' + year);
            this.handledata(startDate, endDate);
        }
            }
    isDisabled() {

        var utypeid = localStorage.getItem("utypeid")
        if (utypeid == 1 || utypeid == 2 || utypeid == 3) {

            return true
        }


        else {

            return false
        }

    }
    render() {
     
        return (
            <div >
                <br />


                <h4 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Company Performance Index</h4>
              
                <div class="bg-white shadow p-3 mb-4 card border-light">
                    <br />
                    <div>
                <div id="level" style={{ width: '220px', float: 'left' }}>

                    <select name="Level" class="form-control" onChange={this.handleChange} >

                        <option selected value={0}>Company Level</option>
                        <option value={1}>Plant Level </option>
                        <option value={3}>Office Level</option>

                    </select></div>
                <div id="location" style={{ width: '220px', float: 'left', marginLeft: '10px' }}>

                            <select name="LocationId" required class="form-control" onChange={this.handleChange1} value={this.state.location} disabled={this.isDisabled()}> 
                        <option selected value='0'>--Select Location--</option>
                        {this.state.locations.map(type => (

                            <option
                                key={type.locationId}
                                value={type.locationId}
                            >
                                {type.locationName}
                            </option>
                        ))}
                            </select></div>
                        <div id="month" style={{ width: '220px', float: 'left', marginLeft: '10px' }}>

                           
                                <select
                                class="form-control"
                                    value={this.state.month}
                                    name="month"
                                    onChange={this.handleChange2}
                                >
                                    <option selected disabled value='0'>--Select Month--</option>
                                    <option value={this.state.month1} >January</option>
                                    <option value={this.state.month2} >February</option>
                                    <option value={this.state.month3} >March</option>
                                    <option value={this.state.month4} >April</option>
                                    <option value={this.state.month5} >May</option>
                                    <option value={this.state.month6} >June</option>
                                    <option value={this.state.month7} >July</option>
                                    <option value={this.state.month8} >August</option>
                                    <option value={this.state.month9} >September</option>
                                    <option value={this.state.month10} >October</option>
                                    <option value={this.state.month11} >November</option>
                                    <option value={this.state.month12} >December</option>

                                </select>
                            </div>
                        <div id="year" style={{ width: '220px', float: 'left', marginLeft: '10px' }}>
                            <select
                                class="form-control"
                                    value={this.state.year}
                                    name="year"
                                    onChange={this.handleChange2}
                                >
                                    <option selected disabled value='0'>--Select Year--</option>
                                    <option value={this.state.prevYear} >{this.state.prevYear}</option>
                                    <option value={this.state.longYear} >{this.state.longYear}</option>
                                </select>
                            
                        </div>
                        <div className="no-space-break" style={{float: 'right', marginRight: '50px' }}>
                            <label >
                            Change View
                            &nbsp;
                        <input
                                type="checkbox"
                                checked={this.state.checked}
                                onChange={this.handleChange3}
                                />
                                </label>
                        </div>
                   
                   
                    <br />
                    <br />
                    <br />
                    
                   
                      
                        
                <div className="no-space-break" id="advanced" style={{ float: 'left', marginLeft: '10px' }} onChange={this.handleChange4}>
                            <input type="radio" value="LastThreeMonths" name="view" /> Last Three Months &nbsp;&nbsp;
               <input type="radio" value="ThisYear" name="view" /> This Year &nbsp;&nbsp;
               <input type="radio" value="LastYear" name="view" /> Last Year &nbsp;&nbsp;
              
                 
                        </div>
                <br />
                <br />
              
                 
                    <div style={{ marginLeft: '80px' }}>
                    <Chart
                        width={1000}
                        height={300}
                        chartType="Gauge"

                        data={this.getData()}
                        options={{

                            yellowFrom: 35,
                            yellowTo: 70,
                            redFrom: 0,
                            redTo: 35,
                            greenFrom: 70,
                            greenTo: 100,
                            minorTicks: 5,
                            animation: {
                                duration: 1000,
                                easing: 'inAndOut',
                            },
                            
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                    </div>
                    <label className="no-space-break" style={{ marginLeft: '190px', fontWeight: 'bold', color: 'black' }}>Safety Index &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;Environment Index &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;Compliance Index</label>
                
                </div>

            </div>



            </div>





        )
    }
}

export default Companyperformance