import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'
import '../index.css';

class Editinitiatives extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: '',
            id: 0,
          

        };
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/Initiatives/GetInitiativesById', { params: { InitiativeId: id } }).then(response => {
            console.log(response.data);

            this.setState({
                rows: response.data[0]
            });


        });
    }
    handleSubmit = event => {

        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/Initiatives/UpdateInitiativeList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/monthlyehsview')

            }).catch(err => console.log(err));
    }

    handleChange = event => {
        
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        const value = event.target.value;
        var rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,

            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <div className="form-container">
                <Form className="register-form" onSubmit={this.handleSubmit}>
                    <br />
                    
                    
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="description">Initiatives</label>
                            <input name="description" onChange={this.handleChange} className="form-control" id="description" defaultValue={this.state.rows.description} />
                        </div>
                    </div>


                    <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Update</button>

                </Form>
            </div>
        );
    }
}

export default Editinitiatives              