import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class editcriticalarea extends Component {

    constructor(props) {
        super(props)
       
        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {

            count: 0,

            type1: "Safe",
            type2: 'Unsafe',
            
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: ''


        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/CriticalArea/GetCriticalAreaById', { params: { CriticalId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0],
                image: process.env.PUBLIC_URL + response.data[0].uploadedImage

            });

        });


    }









    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name
       
        const value = e.target.value

        var rows = this.state.rows;
        if (name == "observation") {
            if (value == "Safe") {
                rows.remarks = 'NA'
                document.getElementById("remarks").disabled=true

            }
            else {
                rows.remarks = ''
                document.getElementById("remarks").disabled = false
            }
        }
        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };


    handleChange2 = e => {

        e.preventDefault();


        const name = e.target.name;


        this.setState({ selectedFile: e.target.files });
        this.setState({ image: URL.createObjectURL(e.target.files[0]) });

        console.log(this.state.selectedFile)
        let filename = e.target.files[0].name;
        let root = `/Images/` + filename

        //const val = e.target.value;
        //var sub=val.substring(12,val.length)


        var rows = this.state.rows;
        var utype = parseInt(this.state.utype)
        let row = {
            ...rows,

            [name]: root,


        };

        rows = row;
        this.setState({
            rows
        });


    };



    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);

        const file = new FormData();
        if (this.state.selectedFile != null) {
            // Update the formData object
            file.append("formFile", this.state.selectedFile[0])
            file.append("filename", this.state.selectedFile[0].name)
        }

        axios.all([
            axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/CriticalArea/UpdateCriticalAreaList', newbody),

            axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/SafetyWalkList/UploadeFiles', file)

        ]).then(axios.spread((response1, response2) => {
            alert("Entry Updated Successfully");
            this.props.history.push('/criticalareaview')
        })).catch(err => console.log(err));


    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Edit Critical Area Fire Monitoring </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="AreaName">
                                    <label class="no-space-break">Area Name</label>
                                    <input name="areaName" type="text" class="form-control" value={this.state.rows.areaName} onChange={this.handleChange} />
                                </div>
                                <br/>
                                <div id="Observation">
                                    <label class="no-space-break">Observation</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.observation} name="observation">

                                        <option value={this.state.type1} >Safe</option>
                                        <option value={this.state.type2} >Unsafe</option>

                                    </select>
                                </div>
                                <br />
                                <div id="=Remarks">
                                    <label class="no-space-break">Remarks </label>
                                    <input id="remarks" name="remarks" type="text" class="form-control" value={this.state.rows.remarks} onChange={this.handleChange} />

                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <br />
                                <div class='box' ><img className="img-fluid1" src={this.state.image} alt='No Image Selected' width="520" height="200" />
                                    <br />
                                    <br />

                                    <input style={{ width: '520px' }} className="btn btn-primary btn-sm" type="file" name="uploadedImage" onChange={this.handleChange2} /> <br />

                                </div>

                            </div>
                          

                        </div>

                     

                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default editcriticalarea