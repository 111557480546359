import React, { Component } from 'react'
import { Table, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import '../index.css';
import Tooltip from "react-simple-tooltip"
import axios from 'axios';



class Directghgemission1 extends Component {
    constructor(props) {
        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;
        this.state = {
            count: 0,
            rows: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4}],
            type1: 'HSD',
            type2:'PNG',
            type3:'LPG-Cooking',
            type4:'Diesel-Vehicle',
            type5:'Gasoline-Vehicle',
            row1: { ground: '', river: '', municipal: '', harvested: '', total: '' },
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            isOpen: false

        }

    }
    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var directghgscope1 = JSON.parse(localStorage.getItem("directghgscope1"));
        var rows = this.state.rows;

        if (directghgscope1 != null) {
            rows = directghgscope1

            this.setState({
                rows
            })
        }
    }

    openModal = (e) => {


        this.setState({ isOpen: true }, () => {
            window.setTimeout(() => {
                this.setState({ isOpen: false })
            }, 2000)
        });

    }

    closeModal = () => this.setState({ isOpen: false });

    handletotal = (idx) => (e) => {


        e.preventDefault();

        const rows = [...this.state.rows];
        rows[idx].scope1 = (parseFloat(rows[idx].Refill) * parseFloat(rows[idx].Global))/1000

        this.setState({
            rows
        });
        this.handletotal2(0, 1,2,3,4)
    };


    handletotal1 = (idx) => (e) => {


        e.preventDefault();

        const rows = [...this.state.rows];
        rows[idx].scope2 = parseFloat(rows[idx].HeatValue) * (parseFloat(rows[idx].EmissionFactor1) + parseFloat(rows[idx].EmissionFactor2) * 28 + parseFloat(rows[idx].EmissionFactor3*265))/1000

        this.setState({
            rows
        });
        this.handletotal2(0,1,2,3,4)
    };


    handletotal2(idx1, idx2, idx3, idx4, idx5) {

       
       
        const rows = [...this.state.rows];
        rows[idx1].total = parseFloat(rows[idx1].scope1) + parseFloat(rows[idx1].scope2) + parseFloat(rows[idx2].scope1) + parseFloat(rows[idx2].scope2) + parseFloat(rows[idx3].scope1) + parseFloat(rows[idx3].scope2) + parseFloat(rows[idx4].scope1) + parseFloat(rows[idx4].scope2) + parseFloat(rows[idx5].scope1) + parseFloat(rows[idx5].scope2) 

        rows[idx2].total = parseFloat(rows[idx1].scope1) + parseFloat(rows[idx1].scope2) + parseFloat(rows[idx2].scope1) + parseFloat(rows[idx2].scope2) + parseFloat(rows[idx3].scope1) + parseFloat(rows[idx3].scope2) + parseFloat(rows[idx4].scope1) + parseFloat(rows[idx4].scope2) + parseFloat(rows[idx5].scope1) + parseFloat(rows[idx5].scope2) 

        rows[idx3].total = parseFloat(rows[idx1].scope1) + parseFloat(rows[idx1].scope2) + parseFloat(rows[idx2].scope1) + parseFloat(rows[idx2].scope2) + parseFloat(rows[idx3].scope1) + parseFloat(rows[idx3].scope2) + parseFloat(rows[idx4].scope1) + parseFloat(rows[idx4].scope2) + parseFloat(rows[idx5].scope1) + parseFloat(rows[idx5].scope2) 

        rows[idx4].total = parseFloat(rows[idx1].scope1) + parseFloat(rows[idx1].scope2) + parseFloat(rows[idx2].scope1) + parseFloat(rows[idx2].scope2) + parseFloat(rows[idx3].scope1) + parseFloat(rows[idx3].scope2) + parseFloat(rows[idx4].scope1) + parseFloat(rows[idx4].scope2) + parseFloat(rows[idx5].scope1) + parseFloat(rows[idx5].scope2) 

        rows[idx5].total = parseFloat(rows[idx1].scope1) + parseFloat(rows[idx1].scope2) + parseFloat(rows[idx2].scope1) + parseFloat(rows[idx2].scope2) + parseFloat(rows[idx3].scope1) + parseFloat(rows[idx3].scope2) + parseFloat(rows[idx4].scope1) + parseFloat(rows[idx4].scope2) + parseFloat(rows[idx5].scope1) + parseFloat(rows[idx5].scope2) 

        this.setState({
            rows
        });
    };


    handleChange = (idx) => (e) => {
       
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        var value;
        if (name == "Refrigerant") {
            value = e.target.value

        }
        else {
            value = parseFloat(e.target.value);
        }
        //let id=name+(idx);

       
        var FuelType = '';
        switch (idx) {
            case 0:
                FuelType = "HSD"
                break;  
            case 1:
                FuelType = "PNG"
                break;  
            case 2:
                FuelType = "LPG-Cooking"
                break;  
            case 3:
                FuelType = "Diesel-Vehicle"
                break;  
            case 4:
                FuelType = "Gasoline-Vehicle"
                break;  
        }
        
        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        //var year = 0

        //var prevYear = this.state.prevYear;
        //if (prevMonth == "December") {
        //    year = prevYear
        //}
        //else {
        //    year = longYear
        //}


        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "FuelType":FuelType,
            "Year": year,
            "Month": month,
            "LocationId": location,
            "UserId": utype,
           "ReferenceId":Reference
        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };

    handleAddRow = () => (event) => {

        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {

        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }
    isDisabled1() {

        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }


    handleSubmit = event => {
        event.preventDefault();
        const { rows } = this.state;
        //var prevMonth = this.state.prevMonth;
        //var year = 0;
        //var longYear = this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year = longYear
        //}
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');
        var id = localStorage.getItem('utype')
        //axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Directghgemission1/GetDirectEmissiononeByMonth', { params: { Month: month, Year: year, UserId: id } })
        //    .then(response => {
                
        //        if (response.data.length == 0) {


                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    localStorage.setItem('directghgscope1', Options.body);
                    let newbody = JSON.parse(Options.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SaveGhgEmissionScope1temp', newbody).then(response => {

                        if (response.data == 1) {
                            this.openModal()
                        }

                        else {

                            alert("Data Not Saved.Kindly Save Again")
                        }

                    })

                //}
                //else {

                //    alert("Data Already Submitted")
                //}

           // })

        
        /* let newbody = JSON.parse(Options.body);
         axios.post("http://localhost:23013/api/TotalWaterWithdrawn/SaveTotalWaterWithdrawn", newbody)
             .then(response => {
                 if (response.data.Status === 'Success') {
                     alert("Data Save Successfully");
                     window.location.reload();
                     console.log(response.data.Status);
 
                 }
 
                 else {
                     alert('Data Saved Successfully');
                     window.location.reload();
                 }
 
             })
 
             //  .then(data => {
             // let typesapi = Object.keys(data.type);
             //console.log(typesapi);
 
 
 
             //    })
             .catch(error => {
                 console.log(error);
 
             });*/



    }




    
    render() {
        const text = "These emissions result from intentional or unintentional releases hydrofluorocarbon (HFC) during the use of refrigeration.\nIt also accounts for the emissions from usage of non-renewable fuels."
        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>
                <br />
                <div className="App">
                    <div className="wordalign">
                        {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                        <label>
                            {this.state.longMonth}
                            {this.state.longYear}
  &nbsp;  &nbsp;

                              <Tooltip
                                arrow={15}
                                background="#ffffff"
                                border="black"
                                color="black"
                                content={text.split("\n").map((i, key) => {

                                    return <div key={key}>{i}</div>;


                                })}
                                fadeDuration={0}
                                fadeEasing="linear"
                                fixed={false}
                                fontFamily="Bw Glenn Sans Medium"
                                fontSize="12px"
                                offset={-20}
                                padding={15}

                                placement="right"
                                radius={50}
                                zIndex={1}




                            >

                                <span className="btn btn-outline-danger btn-sm">

                                    info
                               {/*<svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                                </span>
                            </Tooltip>
                        </label>
                    </div>
                </div>
                <Modal style={{ backgroundColor: 'white', width: '500px', height: '200px', borderColor: '#ffffff00' }}

                    show={this.state.isOpen} onHide={this.closeModal}>

                    <Modal.Header style={{ backgroundColor: '#001946', color: 'white' }} >
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Data Saved Successfully

                          </Modal.Body>
                </Modal>
                <br />
                {/*   <h5>TOTAL WATER WITHDRAWAL </h5> */}


                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white", overflowX: 'scroll', display: 'block', overflowY: 'hidden' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                            <th>REFRIGERANT<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>REFILL QUALITY(A) in Kgs (ESTIMATED FUGITIVE EMISSION)</th>
                            <th>GLOBAL WARMING POTENTIAL(B) IN KGSCO2E/KG<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>SCOPE1 EMISSION DUE TO FUGITIVE EMISSIONS(AXB)/1000 TC02EQ<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>FUEL TYPE<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>HEAT VALUE (IN TJ)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>EMISSION FACTOR (CO2) KGCO2/TJ<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>EMISSION FACTOR (CH4) KGCH4/TJ<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>EMISSION FACTOR (N20) KGN20/TJ<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span><br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>SCOPE1 EMISSION (TCO2E) DUE TO COMBUSTION OF FUEL<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            
                          
                        </tr>
                    </thead>
                    <tbody>

                        <tr id="addr0" key={0}>



                            <td>
                                <TextField type="text" required id="standard-required" name="Refrigerant" onKeyUp={this.handletotal(0)} onChange={this.handleChange(0)} value={this.state.rows[0].Refrigerant}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Refill" onKeyUp={this.handletotal(0)} onChange={this.handleChange(0)} value={this.state.rows[0].Refill}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Global" onKeyUp={this.handletotal(0)} onChange={this.handleChange(0)} value={this.state.rows[0].Global}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number"  required id="standard-required" name="scope1" value={this.state.rows[0].scope1}  />
                            </td>
                            <td>
                                <TextField InputProps={{ readOnly: true }} type="text"  name="Type" defaultValue={this.state.type1} />
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="HeatValue" onKeyUp={this.handletotal1(0)} onChange={this.handleChange(0)} value={this.state.rows[0].HeatValue} />
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor1" onKeyUp={this.handletotal1(0)} onChange={this.handleChange(0)} value={this.state.rows[0].EmissionFactor1}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor2" onKeyUp={this.handletotal1(0)} onChange={this.handleChange(0)} value={this.state.rows[0].EmissionFactor2}/>
                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor3" onKeyUp={this.handletotal1(0)} onChange={this.handleChange(0)} value={this.state.rows[0].EmissionFactor3} />
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number"  required id="standard-required" name="scope2" value={this.state.rows[0].scope2} />
                            </td>


                        </tr>
                        <tr id="addr1" key={1}>



                            <td>
                                <TextField type="text" required id="standard-required" name="Refrigerant" onKeyUp={this.handletotal(1)} onChange={this.handleChange(1)} value={this.state.rows[1].Refrigerant}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Refill" onKeyUp={this.handletotal(1)} onChange={this.handleChange(1)} value={this.state.rows[1].Refill}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Global" onKeyUp={this.handletotal(1)} onChange={this.handleChange(1)} value={this.state.rows[1].Global}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="scope1" value={this.state.rows[1].scope1} />
                            </td>
                            <td>
                                <TextField InputProps={{ readOnly: true }} type="text" name="Type" defaultValue={this.state.type2} />
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="HeatValue" onKeyUp={this.handletotal1(1)} onChange={this.handleChange(1)} value={this.state.rows[1].HeatValue}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor1" onKeyUp={this.handletotal1(1)} onChange={this.handleChange(1)} value={this.state.rows[1].EmissionFactor1}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor2" onKeyUp={this.handletotal1(1)} onChange={this.handleChange(1)} value={this.state.rows[1].EmissionFactor2}/>
                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor3" onKeyUp={this.handletotal1(1)} onChange={this.handleChange(1)} value={this.state.rows[1].EmissionFactor3}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="scope2" value={this.state.rows[1].scope2}  />
                            </td>
                           
                        </tr>
                        <tr id="addr2" key={2}>



                            <td>
                                <TextField type="text" required id="standard-required" name="Refrigerant" onKeyUp={this.handletotal(2)} onChange={this.handleChange(2)} value={this.state.rows[2].Refrigerant}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Refill" onKeyUp={this.handletotal(2)} onChange={this.handleChange(2)} value={this.state.rows[2].Refill}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Global" onKeyUp={this.handletotal(2)} onChange={this.handleChange(2)} value={this.state.rows[2].Global}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="scope1" value={this.state.rows[2].scope1} />
                            </td>
                            <td>
                                <TextField InputProps={{ readOnly: true }} type="text" name="Type" defaultValue={this.state.type3} />
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="HeatValue" onKeyUp={this.handletotal1(2)} onChange={this.handleChange(2)} value={this.state.rows[2].HeatValue}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor1" onKeyUp={this.handletotal1(2)} onChange={this.handleChange(2)} value={this.state.rows[2].EmissionFactor1}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor2" onKeyUp={this.handletotal1(2)} onChange={this.handleChange(2)} value={this.state.rows[2].EmissionFactor2}/>
                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor3" onKeyUp={this.handletotal1(2)} onChange={this.handleChange(2)} value={this.state.rows[2].EmissionFactor3}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="scope2" value={this.state.rows[2].scope2} />
                            </td>

                        </tr>

                        <tr id="addr3" key={3}>



                            <td>
                                <TextField type="text" required id="standard-required" name="Refrigerant" onKeyUp={this.handletotal(3)} onChange={this.handleChange(3)} value={this.state.rows[3].Refrigerant}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Refill" onKeyUp={this.handletotal(3)} onChange={this.handleChange(3)} value={this.state.rows[3].Refill}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Global" onKeyUp={this.handletotal(3)} onChange={this.handleChange(3)} value={this.state.rows[3].Global}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="scope1" value={this.state.rows[3].scope1} />
                            </td>
                            <td>
                                <TextField InputProps={{ readOnly: true }} type="text" name="Type" defaultValue={this.state.type4} />
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="HeatValue" onKeyUp={this.handletotal1(3)} onChange={this.handleChange(3)} value={this.state.rows[3].HeatValue}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor1" onKeyUp={this.handletotal1(3)} onChange={this.handleChange(3)} value={this.state.rows[3].EmissionFactor1}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor2" onKeyUp={this.handletotal1(3)} onChange={this.handleChange(3)} value={this.state.rows[3].EmissionFactor2}/>
                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor3" onKeyUp={this.handletotal1(3)} onChange={this.handleChange(3)} value={this.state.rows[3].EmissionFactor3}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="scope2" value={this.state.rows[3].scope2} />
                            </td>

                        </tr>
                        <tr id="addr4" key={4}>



                            <td>
                                <TextField type="text" required id="standard-required" name="Refrigerant" onKeyUp={this.handletotal(4)} onChange={this.handleChange(4)} value={this.state.rows[4].Refrigerant}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Refill" onKeyUp={this.handletotal(4)} onChange={this.handleChange(4)} value={this.state.rows[4].Refill}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="Global" onKeyUp={this.handletotal(4)} onChange={this.handleChange(4)} value={this.state.rows[4].Global}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="scope1" value={this.state.rows[4].scope1} />
                            </td>
                            <td>
                                <TextField InputProps={{ readOnly: true }} type="text" name="Type" style={{ width: '130px' }} defaultValue={this.state.type5} />
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="HeatValue" onKeyUp={this.handletotal1(4)} onChange={this.handleChange(4)} value={this.state.rows[4].HeatValue}/>
                            </td>

                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor1" onKeyUp={this.handletotal1(4)} onChange={this.handleChange(4)} value={this.state.rows[4].EmissionFactor1}/>

                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor2" onKeyUp={this.handletotal1(4)} onChange={this.handleChange(4)} value={this.state.rows[4].EmissionFactor2}/>
                            </td>
                            <td>
                                <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} type="number" required id="standard-required" name="EmissionFactor3" onKeyUp={this.handletotal1(4)} onChange={this.handleChange(4)} value={this.state.rows[4].EmissionFactor3}/>
                            </td>

                            <td>
                                <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="scope2" value={this.state.rows[4].scope2} />
                            </td>

                        </tr>
                    </tbody>
                    <br />
                    <div  style={{ marginLeft:'650px' }}>
                        <label className="no-space-break" style={{ fontSize: '12px' }}>SCOPE1 TOTAL EMISSIONS TCO2EQ</label>
                        <TextField style={{ marginLeft: '150px' }} InputProps={{ readOnly: true }} type="number" name="total" value={this.state.rows[4].total}  />
                    </div>
                    <br />
                    
                </Table>
                

              
                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit} disabled={this.isDisabled1()}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Directghgemission1