import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Moment from 'moment';
import { RiPencilLine } from "react-icons/ri";
import '../index.css';
import Modal from './modal.js';
import Edit from './Editrawmaterial'
import { BiFontSize } from 'react-icons/bi';





class Riskassessmentview extends Component {

    constructor(props) {
        super(props)
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        var utype = localStorage.getItem('utype')



        this.state = {
            count: 0,
            isOpen: false,
            rows: [],
            rows2: [],
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            year1: '2020',
            year2: '2021',

            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,

            prevYear: prevYear,
            month: prevMonth,
            year: longYear,
            status1: 'Approved',
            status2: 'Rejected',
            status: "pending",
            id: ''



        }

    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null ) {
            window.location.href = '/'
            return;
        }
        var prevYear = this.state.prevYear;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        if (prevMonth == "December") {
            this.setState({
                year: prevYear
            })
        }
        else {
            this.setState({
                year: longYear
            })
        }


        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        if (utypeid == 1) {


            document.getElementById('monthfilter').style.display = "none";
            
            document.getElementById("resubmit").style.display = "none";

            var adminhistoryehs = localStorage.getItem('adminhistoryehs')
            if (adminhistoryehs == 'true') {
                document.getElementById('status').style.display = "none";
            }

            else {
                document.getElementById('status').style.display = "inline";
            }
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            this.setState({
                id: id
            });
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskAssessment/GetRiskAssessmentFiltered', { params: { SubmissionId: id } }).then(response => {
                this.setState({
                    rows: response.data,

                })

            })

        }
        else {
            var Status = localStorage.getItem('Status')
            localStorage.removeItem("Status");
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            if (Status == 'Rejected' || id != null) {
                document.getElementById('monthfilter').style.display = "none";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "inline";


                localStorage.setItem('submissionid', id);

                this.setState({
                    id: id
                });

                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskAssessment/GetRiskAssessmentFiltered', { params: { SubmissionId: id } }).then(response => {
                    this.setState({
                        rows: response.data

                    })
                    console.log(response.data)
                })
            }

            else {

                document.getElementById('monthfilter').style.display = "inline";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "none";
                var uid = localStorage.getItem('utype')
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskAssessment/GetRiskAssessmentByUId', { params: { UserId: uid } }).then(response => {
                    var prevMonth = this.state.prevMonth;

                    // const rows = {...this.state.rows}
                    var rows = response.data.filter(x => x.month == prevMonth);
                    this.setState({
                        rows: rows


                    })
                    console.log(response.data)
                });

            }
        }
    }
    isDisabled() {
        var utype = localStorage.getItem('utype')

        if (utype == 1) {
            return true;

        }
        else {
            return false;
        }

    }
    isDisplayed() {


        var Status = localStorage.getItem('Status')
        var id = localStorage.getItem('submissionid')
        if (Status == 'Rejected' || id != null) {
            return "inline"
        }
        else {
            return "none";
        }


    }

    handleAddRow = () => (event) => {

        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]


        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx, id) => (e) => {

        e.preventDefault();
        var result = window.confirm("Are you sure you want to delete?");
        if (result) {
            axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskAssessment/DeleteRiskAssessment', { params: { RiskId: id } }).then(response => {
                alert("Entry Deleted Successfully");
                window.location.reload();

            }).catch(err => console.log(err));
        }


        //   let rowarr = [...this.state.rows];
        // let deletedItem = rowarr.findIndex(item => item.id === idx);
        // rowarr.splice(deletedItem, 1);
        // this.setState({...this.state.rows=rowarr})

        // let row = [...this.state.rows.filter(x=>x.id!==idx)];
        // this.setState({rows:row})




        //  const rows = [...this.state.rows]
        //   rows.splice(idx,1)
        //   this.setState({ rows })
        //   e.preventDefault()
        //  var newrows=this.state.rows.slice();
        //  newrows.splice(idx,1);
        //  this.setState({ rows:newrows })

        // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
        //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
        //  this.setState({
        //    rows: newRows,
        //  });

    }

    edit(idx) {


        this.props.history.push("/editriskassessment/?id=" + idx);
    }

    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    formatDate(string) {

        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('DD-MM-YYYY')


    }

    handleSubmit = event => {
        event.preventDefault();
        
        var month = this.state.month;
        var year = this.state.year;
        var uid = localStorage.getItem('utype')
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskAssessment/GetRiskAssessmentByMonth', { params: { Month: month, Year: year, UserId: uid } })
            .then(response => {
                this.setState({
                    rows: response.data
                });
                console.log(response.data)
            })
    }



    handleChange = event => {
        
        event.preventDefault();
        const name = event.target.name;

        const value = event.target.value;


        this.setState({
            [name]: value
        });
    };

    handleChange1 = e => {
        
        const rows2 = [...this.state.rows2];
        let row = {
            [e.currentTarget.name]: e.currentTarget.value,

            "Status": this.state.status,
            "RegulatorId": 1,
            "SubmissionId": parseInt(this.state.id)
        }
        rows2[0] = row
        this.setState({
            rows2
        });
    };

    handleSubmit1 = e => {
        
        const { rows2 } = this.state;
        var Status = localStorage.getItem('Status')
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows2)
        };
        let newbody = JSON.parse(Options.body);
        if (Status == 'Submitted') {
            axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateUserSubmittedEhs', newbody[0])

                .then(response => {

                    this.setState({ isOpen1: false })
                    alert("Data Rejected Again");
                    this.props.history.push('/usersubmittedehs')
                });
        }

        else {
            axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSStatus', newbody)
                .then(response => {
                    this.setState({ isOpen: false })
                    alert("Status Updated Successfully");
                    this.props.history.push('/ehsadminview')
                });
        }

    }


    renderRows() {
        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.riskId}>




                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.activity} />
                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.hazard} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.impact} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.controls} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.probability} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.severity} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.riskLevel} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.riskRating} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.riskControls} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.probability1} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.severity1} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" style={{ width: '85px' }} required id="standard-required" defaultValue={item.residualRisk} />
                    </td>



                    <td >
                        <div style={{width:'85px'}}>
                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.riskId)} style={{ marginRight: '8px', display: this.isDisplayed() }} disabled={this.isDisabled()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </button>
                        <button id="delete"
                            className="btn btn-outline-danger btn-sm"
                                onClick={this.handleRemoveSpecificRow(idx, item.riskId)} style={{ display: this.isDisplayed() }} disabled={this.isDisabled()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button>
</div>

                    </td>
                </tr>

            );

        });

    }




    render() {

        return (
            <Form className="font-link" >

                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Risk Assessment </h5>


                <br />
                <div id="monthfilter">
                    <FormControl>

                        <select

                            value={this.state.month}
                            name="month"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Month--</option>
                            <option value={this.state.month1} >January</option>
                            <option value={this.state.month2} >February</option>
                            <option value={this.state.month3} >March</option>
                            <option value={this.state.month4} >April</option>
                            <option value={this.state.month5} >May</option>
                            <option value={this.state.month6} >June</option>
                            <option value={this.state.month7} >July</option>
                            <option value={this.state.month8} >August</option>
                            <option value={this.state.month9} >September</option>
                            <option value={this.state.month10} >October</option>
                            <option value={this.state.month11} >November</option>
                            <option value={this.state.month12} >December</option>

                        </select>

                    </FormControl>

                    &nbsp;


                    <FormControl>
                        <select
                            value={this.state.year}
                            name="year"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Year--</option>
                            <option value={this.state.prevYear} >{this.state.prevYear}</option>
                            <option value={this.state.longYear} >{this.state.longYear}</option>
                        </select>
                    </FormControl>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit} type="submit" className="btn btn-success btn-sm">SUBMIT</button>

                </div>

                <br />
                <div id="status" style={{ float: 'right' }}>
                    <label style={{ fontWeight: 'bold' }}>Status:</label>
                    <FormControl>

                        <select


                            name="Status"
                            onChange={this.openModal}
                        >
                            <option selected disabled value='0'>--Pending--</option>
                            <option value={this.state.status1} >Approve</option>
                            <option value={this.state.status2} >Reject</option>


                        </select>

                    </FormControl>
                </div>
                <div id="resubmit">
                    <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit2}>RE-SUBMIT FOR APPROVAL</button>

                </div>

                <br />

             
                <Modal style={{ backgroundColor: '#ffffff00', width: '500px', borderColor: '#ffffff00' }}

                    show={this.state.isOpen} onHide={this.closeModal}>
                    <div className="App1">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<label>
                                Date:
                                </label>
                              {this.state.currentDateTime} <br/> */}

                            <label for='Remarks'>Remarks: </label>
                            <TextField type="text" name="Remarks" id="Remarks" onChange={this.handleChange1} style={{ width: '350px' }} />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.handleSubmit1} style={{ marginRight: '8px' }}> Submit </Button>
                            <Button variant="secondary" onClick={this.closeModal}>

                                Close
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
                <br />
                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white", overflowX: 'scroll', display: 'block', overflowY: 'hidden', paddingTop: '28px' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                            <th>ACTIVITY</th>
                            <th>HAZARD</th>
                            <th>IMPACT</th>
                            <th>CURRENT CONTROLS </th>
                            <th>PROBABILITY</th>
                            <th>SEVERITY</th>
                            <th>RISK LEVEL</th>
                            <th>RISK RATING</th>
                            <th>RISK CONTROLS</th>
                            <th>PROBABILITY</th>
                            <th>SEVERITY</th>
                            <th>RESIDUAL RISK</th>


                            <th>ACTION</th>

                        </tr>
                    </thead>
                    <tbody>







                        {this.renderRows()}

                    </tbody>
                </Table>





                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )


    }
}

export default Riskassessmentview