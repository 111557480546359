import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import Modal from "./modal.js";

import axios from 'axios';
import { RiAdminFill, RiPencilLine } from "react-icons/ri";
import '../index.css';
import Edit from './Editrawmaterial'
import { BiFontSize } from 'react-icons/bi';

import {
    PopupboxManager,
    PopupboxContainer
} from 'react-popupbox';





class EHSFormSubmission extends Component {

    constructor(props) {
        super(props)
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);



        this.state = {
            count: 0,
            type1: 'Approved',
            type2: 'Rejected',
            addModalShow: false,
            types: [],
            rows: [],
            admin: 1,
            utype: localStorage.getItem('utype'),



        }

    }




    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var utype = localStorage.getItem('utype')


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/GetEHSFormNoStats', { params: { UserId: parseInt(utype) } }).then(response => {
            console.log(response.data);
            //  console.log(response.data[0].location.locationName);
            this.setState({
                rows: response.data
            });
        });
    }




    handleChange1 = (idx, id) => (e) => {
        

        e.preventDefault();


        // const { name, value } = e.target;
        const name = e.target.name;


        //let id=name+(idx);

        const value = e.target.value;
        const rows = [...this.state.rows];

        const types = [...this.state.types];
        let row = {


            "SubmissionId": rows[idx].submissionId,
            "UserId": rows[idx].userId,
            "ApprovedDate": new Date(),
            "RegulatorId": 1,
            [name]: value



        };
        types[0] = row;
        this.setState({
            types
        });


        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(types)
        };
        let newbody = JSON.parse(Options.body);
        axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/SaveAdminApprovedData', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Status Updated Successfully");
                    window.location.reload();
                }

                else {
                    alert('Status Updated Successfully');
                    window.location.reload();
                }
            })
        console.log(this.state.types)
    };



    handleRowClick = (id,idx) => (event) => {
        debugger;
        localStorage.setItem('adminhistoryehs', 'false')
        this.props.history.push("/" + this.state.rows[idx].FormName+"/?id=" + id);
    }


    handleAddRow = () => (event) => {

        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]


        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx, id) => (e) => {

        e.preventDefault();
        let index = idx + 1;
        axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalRawMaterial/DeleteRawMaterial', { params: { TMatId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));



        //   let rowarr = [...this.state.rows];
        // let deletedItem = rowarr.findIndex(item => item.id === idx);
        // rowarr.splice(deletedItem, 1);
        // this.setState({...this.state.rows=rowarr})

        // let row = [...this.state.rows.filter(x=>x.id!==idx)];
        // this.setState({rows:row})




        //  const rows = [...this.state.rows]
        //   rows.splice(idx,1)
        //   this.setState({ rows })
        //   e.preventDefault()
        //  var newrows=this.state.rows.slice();
        //  newrows.splice(idx,1);
        //  this.setState({ rows:newrows })

        // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
        //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
        //  this.setState({
        //    rows: newRows,
        //  });

    }

    edit(idx) {


        this.props.history.push("/editrawmaterial/?id=" + idx);
    }


     capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


    handleSubmit = event => {
        alert(`${this.state.rows}`)
        const data = new FormData(event.target);
        fetch('weatherforecast', {
            method: 'POST',
            body: data,
        });
    }

    renderRows() {

        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}  >




                    {/*  <td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="submissionid" required id="standard-required" defaultValue={item.submissionId} />
                    </td > */}

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="user" required id="standard-required" defaultValue={item.UserName} />
                    </td>


                    <td>

                        <TextField InputProps={{ readOnly: true }} type="text" name="location" required id="standard-required" defaultValue={item.LocationName} />



                    </td >
                    <td>

                        <TextField InputProps={{ readOnly: true }} type="text" name="FormName" required id="standard-required" defaultValue={this.capitalizeFirstLetter(item.FormName.replace('view',''))} />

                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="date" required id="standard-required" defaultValue={item.SubmissionDate} />
                    </td>

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="status" required id="standard-required" defaultValue={item.Status} />
                    </td>

                    <td onClick={this.handleRowClick(item.submissionId,idx)} style={{ color: 'blue', textDecoration: 'underline' }}>
                        <a> View  </a>
                    </td>
                    {/*  <td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="submitted" required id="standard-required" defaultValue={item.submittedTo} />
                    </td> */}
                    {/*  <td>
                       
                        <FormControl>
                           
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="status"
                                onChange= {this.handleChange1(idx,item.submissionId)}
                            >
                                <MenuItem value={this.state.type1}>Approve</MenuItem>
                                <MenuItem value={this.state.type2}>Reject</MenuItem>
                               
                            </Select>
                                
                               
                            </FormControl></td> */}




                </tr>


            );

        });

    }




    render() {
        let addModalClose = () => this.setState({ addModalShow: false });
        return (
            <div>
                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Pending EHS List </h5>
                <br />
                <Form className="font-link" >
                    {/*  <h5>USER SUBMISSION </h5> */}

                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                                {/* <th>SUBMISSION ID</th> */}


                                <th>SUBMITTED BY</th>
                                <th>LOCATION</th>
                                <th>FORM NAME</th>
                                <th>SUBMISSION DATE</th>
                                <th>STATUS</th>
                                <th>ACTION</th>


                            </tr>
                        </thead>
                        <tbody>







                            {this.renderRows()}




                        </tbody>
                    </Table>





                    {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                </Form>
            </div>
        )


    }
}


export default EHSFormSubmission