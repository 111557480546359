import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'
import TextField from '@material-ui/core/TextField';
import { Table } from 'react-bootstrap';
import { alignPropType } from 'react-bootstrap/esm/DropdownMenu';
import { MenuItem } from '@material-ui/core';
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";



class Locationmasterentry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            types:[],
            type: '',
            unit: ''

        };
    }


    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/GetUsersRole')
            .then(response => {
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi

                });

            })

            .catch(error => {
                console.log(error);

            });
    }  

    handleSubmit = event => {

        const { rows } = this.state;

        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        let newbody = JSON.parse(Options.body);
        console.log(newbody)
        axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/LocationMaster/SaveLocationMaster', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Data Save Successfully");
                    this.props.history.push('/locationmaster')
                }

                else {
                    alert('Data Saved Successfully');
                    this.props.history.push('/locationmaster')
                }
            })
    }


    handleChange = event => {
        
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        var value = event.target.value;
        let rows = this.state.rows;
        let row = {

            ...rows,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (



            <Form className="font-link" onSubmit={this.handleSubmit}>

                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                            <th>ROLE TYPE</th>
                            <th>LOCATION NAME</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tr>
                        <td style={{width:'450px'}}>
                            <FormControl>

                                <Select isSearchable required
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    defaultValue="Select"
                                    name="UserRoleId"
                                    onChange={this.handleChange}

                                >
                                    {this.state.types.map(type => (
                                        <MenuItem
                                            key={type.userRoleId}
                                            value={type.userRoleId}
                                        >
                                            {type.roleType}
                                        </MenuItem>
                                    ))}
                                  
                                </Select>
                               

                            </FormControl> </td>
                        <td>
                            <TextField required name="LocationName" id="unit" defaultValue={this.state.unit} onChange={this.handleChange} />

                        </td>
                        <td>
                            <button type="submit" style={{ float: 'center' }} className="btn btn-success">SAVE</button>
                        </td>
                    </tr>
                </Table>
            </Form>

        );
    }
}

export default Locationmasterentry        