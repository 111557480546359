import React from 'react';
import './styles.css';
import TabNav from './TabNav';
import './App.css';
import Tab from './Tab';
import Rawmaterial from './Rawmaterial';
import Deconsumption from './Deconsumption';
import Ieconsumption from './Ieconsumption';
import Reconsumption from './Reconsumption';
import Waterwithdrawal from './Waterwithdrawal';
import Waterrecycled from './Waterrecycled';
import Rwharvesting from './Rwharvesting';
import FormControl from "@material-ui/core/FormControl";
import Ozonedepletion from './Ozonedepletion';
import Waterdischarge from "./Waterdischarge";
import Dischargedwaterquality from "./Dischargedwaterquality";
import Significantspills from "./Significantspills";
import Legalcompliance from "./Legalcompliance";
import Emission from "./Emission";
import Carbonemission from './Carbonemission';
import Totalexpenditure from './Totalexpenditure';
import Injuriesfatalities from './Injuriesfatalities';
import Misproduction from './Misproduction';
import Misfireaccident from './Misfireaccident';
import Missuggestion from './Missuggestion';
import Mismedical from './Mismedical';
import $ from 'jquery';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import axios from 'axios';

import { ScrollableTabs } from 'react-scrollable-tabs';
import 'react-scrollable-tabs/dist/index.css';
import Nonreconsumption from './Nonreconsumption';
import Energyintensity from './Energyintensity';
import Directghgemission1 from './Directghgemission1';
import Directghgemission2 from './Directghgemission2';
import OtherSignificant from './OtherSignificant';
import Wastebytd from './Wastebytd';
import Sfclabelledmaterial from './Sfclabelledmaterial';
import Legalcomplianceoccupational from './Legalcomplianceoccupational';
import Costavoidance from './Costavoidance';
import Workrelatedfatalities from './Workrelatedfatalities';
import Triandltifr from './Triandltifr';
import Ehstraining from './Ehstraining';
import { Label } from 'devextreme-react/circular-gauge';
import { Rings  } from 'react-loader-spinner';
import { Modal } from "react-bootstrap";
import { v4 as uuid } from 'uuid';
class Tabbar extends React.Component {
    constructor(props) {
        super(props);

        var ScrollableTabs = require('react-scrollable-tabs');
        let date = new Date()
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
       
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;
        let prevtoPrevYear = longYear - 2;
       
        this.state = {
            selected: '1.Non Renewable Fuels Consumed',
            rows: [],
            typesapi: [],
            prevlength: 0,
            nextlength:4335,
            status: '',
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            admincode: localStorage.getItem('admincode'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            prevtoPrevYear: prevtoPrevYear,
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            year1: '2020',
            year2: '2021',
            month: prevMonth,
            year: longYear,
            disabled: true,
            displayed: 'none',
            requirementKey: Math.random(),
            locationName: "",
            disabled1: false,
            loading: true,
            isOpen: false,
            submissionid: 0,
            submissiondate:0
        }
    }
    componentDidMount() {
       
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        
        var locationid = localStorage.getItem("location")
        if (utypeid != "4") {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/GetLocationName', { params: { LocationId: locationid } })
                .then(response => {
                    this.setState({
                        locationName: response.data[0].locationName
                    })
                })
        }
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');

        if (month == null && year == null) {
            localStorage.setItem('month1', this.state.month)
            localStorage.setItem('year1', this.state.year);
            var prevYear = this.state.prevYear;
            var longYear = this.state.longYear;
            var prevMonth = this.state.prevMonth;
            if (prevMonth == "December") {
                this.setState({
                    year: prevYear
                })
            }
            else {
                this.setState({
                    year: longYear
                })
            }
        }

        else {

            this.setState({
                month: month,
                year:year

            })
        }
       
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('name');
        
        this.setState({ selected: id });
        
        if (id == "1.Non Renewable Fuels Consumed") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }
        else if (id == "2.Non Renewable Energy Purchased") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }
        else if (id == "3.Renewable Energy Consumption") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }

        else if (id == "4.Non Renewable Energy Consumption") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 870;
            
        }
        
        else if (id == "5.Energy Intensity") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1185;
        }
        else if (id == "6.Direct GHG Emission(Scope 1)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1280;
        }
        else if (id == "7.Direct GHG Emission(Scope 2)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1630;
        }
        else if (id == "8.NOx,SOx(tonnes)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1900;
        }
        else if (id == "9.Water Recycled") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2079;
        }
        else if (id == "10.Water Discharge") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2239;
        }
        else if (id == "11.Rain Water Harvesting") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2410;

        }
        else if (id == "12.Waste By Type and Disposal") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2638;
        }
        else if (id == "13.Raw Material") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2899;
        }
        else if (id == "14.SFC Labelled Material") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3049;
        }
        else if (id == "15.CO2 Emissions(Scope3)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3265;
        }
        else if (id == "16.Legal Compliance Environment") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3505;
        }
        else if (id == "17.Legal Compliance Safety") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3784;

        }
        else if (id == "18.Total Expenditure") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4018;
        }
        else if (id == "19.Total Cost Avoidance") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4208;
        }
        else if (id == "20.Total Water Withdrawal") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4368;
        }
        else if (id == "21.Work Related Fatalities") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4568;

        }
        else if (id == "22.Total Occupational Illness") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4768;
        }
        else if (id == "23.TRI AND LTI FR") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5268;
        }
        else if (id == "24.EHS Trainings") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5465;
        }
    }


  

    setSelected = (tab) => {
       
        this.setState({ selected: tab });
        this.setState({ requirementKey: Math.random() });
        document.getElementById("change").style.display = "none";
        if (tab == "1.Non Renewable Fuels Consumed") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }
        else if (tab == "2.Non Renewable Energy Purchased") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }
        else if (tab == "3.Renewable Energy Consumption") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }

        else if (tab == "4.Non Renewable Energy Consumption") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 870;
            
        }

        else if (tab == "5.Energy Intensity") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1185;
        }
        else if (tab == "6.Direct GHG Emission(Scope 1)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1280;
        }
        else if (tab == "7.Direct GHG Emission(Scope 2)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1630;
        }
        else if (tab == "8.NOx,SOx(tonnes)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1900;
        }
        else if (tab == "9.Water Recycled") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2079;
        }
        else if (tab == "10.Water Discharge") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2239;
        }
        else if (tab == "11.Rain Water Harvesting") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2410;

        }
        else if (tab == "12.Waste By Type and Disposal") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2638;
        }
        else if (tab == "13.Raw Material") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2899;

        }
        else if (tab == "14.SFC Labelled Material") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3049;
        }
        else if (tab == "15.CO2 Emissions(Scope3)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3265;
        }
        else if (tab == "16.Legal Compliance Environment") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3505;
        }
        else if (tab == "17.Legal Compliance Safety") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3784;
        }
        else if (tab == "18.Total Expenditure") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4018;
        }
        else if (tab == "19.Total Cost Avoidance") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4208;
        }
        else if (tab == "20.Total Water Withdrawal") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4368;
        }
        else if (tab == "21.Work Related Fatalities") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4568;

        }
        else if (tab == "22.Total Occupational Illness") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4768;
        }
        else if (tab == "23.TRI AND LTI FR") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5268;

        }
        else if (tab == "24.EHS Trainings") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5465;
        }
    }


    handleClick1 = () => (event) => {
        localStorage.setItem('Status', "None")
        this.props.history.push("/energyview");
    };
    handleSubmit = (value) => (event) => {
       
        
        this.setState({ selected: value });
        
        document.getElementById("change").style.display = "none";

         if (value == "1.Non Renewable Fuels Consumed") {
        
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
            
        }
        else if (value == "2.Non Renewable Energy Purchased") {
          
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
            
        }
        else if (value == "3.Renewable Energy Consumption") {
         
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
            
        }
        else if (value == "4.Non Renewable Energy Consumption") {
       
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 870;
            
        }
        else if (value == "5.Energy Intensity") {
          
            var down = document.getElementById("nav-tabs");
            down.scrollLeft =1185;
            
        }
        else if (value == "6.Direct GHG Emission(Scope 1)") {
           
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1280;
            
        }
        else if (value == "7.Direct GHG Emission(Scope 2)") {
           
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1630;
            
        }
        else if (value == "8.NOx,SOx(tonnes)") {
           
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1900;
            
        }
        else if (value == "9.Water Recycled") {
          
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2079;
            
        }
        else if (value == "10.Water Discharge") {
            
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2239;
            
        }
        else if (value == "11.Rain Water Harvesting") {
            
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2410;
            
        }
        else if (value == "12.Waste By Type and Disposal") {
            
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2638;
            
        }
        else if (value == "13.Raw Material") {
          
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2899;
            
        }
        else if (value == "14.SFC Labelled Material") {
           
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3049;
            
        }
        else if (value == "15.CO2 Emissions(Scope3)") {
        
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3265;
            
        }
        else if (value == "16.Legal Compliance Environment") {
         
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3505;
           
        }
        else if (value == "17.Legal Compliance Safety") {
            
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3784;
            
        }
        else if (value == "18.Total Expenditure") {
         
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4018;
           
        }
        else if (value == "19.Total Cost Avoidance") {
           
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4208;
            
        }
        
        else if (value == "20.Total Water Withdrawal") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4368;

        }
        else if (value == "21.Work Related Fatalities") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4568;

        }

        else if (value == "22.Total Occupational Illness") {

            var down = document.getElementById("nav-tabs");
             down.scrollLeft = 4768;

        }


        else if (value == "23.TRI AND LTI FR") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5268;

        }
        else if (value == "24.EHS Trainings") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5465;

        }
    }

    isDisabled1() {

        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }
    handleSubmit1 = async (event) => {
        
        
        // ucode=localStorage.getItem('username'),
        // utype=localStorage.getItem('utype'),
        // location=localStorage.getItem('location')
        
        //var prevMonth = this.state.prevMonth;
        //var year = 0;
        //var longYear = this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year = longYear
        //}
        
        this.setState({
            disabled1:true
        })
        var month=localStorage.getItem('month1')
        var year = localStorage.getItem('year1');

        this.setState({
            month: month,
            year:year
         })
        var id = localStorage.getItem('utype')

                this.transferdata();
     //await axios.all([
     //      axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectEnergyConsumption/GetDirectEnergyMonthWise', { params: { Month: month, ConsumptionYear: year, UserId: id } }),
     //      axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IndirectEnergyConsumption/GetIndirectEnergyMonthWise', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/GetRenewablEnergyMonthWise', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/NonRenewableConsumption/GetNonRenewableByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EnergyIntensity/GetEnergyIntensityByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Directghgemission1/GetDirectEmissiononeByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Directghgemission2/GetDirectEmissiontwoByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/NoxSoxEmissions/GetNoxSoxEmissionsByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalWaterRecycled/GetTotalWaterRecycledMonthWise', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalDischargedWater/GetTotalDischargedWaterMonthWise', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RainWaterHarvesting/GetRainHarvestTransacMonthWise', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WasteByTd/GetWasteByTdByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalRawMaterial/GetTotalRawMaterialByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SfcLabelledMaterial/GetSfcLabelledMaterialByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SignificantCarbonEmission/GetCarbonEmissionByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ComplianceDetails/GetComplianceDataMonthWise', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyComplianceDetails/GetSafetyComplianceByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalExpenditure/GetTotalExpenditureByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalCostAvoidance/GetTotalCostAvoidanceByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalWaterWithdrawn/GetTotalWaterWithdrawnByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkRelatedFatalities/GetWorkRelatedFatalitiesByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MisFireAccident/GetMisFireAccidentByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Triandltifr/GetTriAndLtiFrByMonth', { params: { Month: month, Year: year, UserId: id } }),
     //    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTraining/GetEhsTrainingByMonth', { params: { Month: month, Year: year, UserId: id } })
     //   ]).then(axios.spread((...responses) => {
          
     //       if (responses[0].data.length === 0 && responses[1].data.length === 0 && responses[2].data.length === 0 && responses[3].data.length === 0 && responses[4].data.length === 0 && responses[5].data.length === 0 && responses[6].data.length === 0 && responses[7].data.length === 0 && responses[8].data.length === 0 && responses[9].data.length === 0 && responses[10].data.length === 0 && responses[11].data.length === 0 && responses[12].data.length === 0 && responses[13].data.length === 0 && responses[14].data.length === 0 && responses[15].data.length === 0 && responses[16].data.length === 0 && responses[17].data.length === 0 && responses[18].data.length === 0 && responses[19].data.length === 0 && responses[20].data.length === 0 && responses[21].data.length === 0 && responses[22].data.length === 0 && responses[23].data.length === 0  ) {

     //               this.transferdata();
     //       }
     //       else {

     //           alert("Data Already Submitted")
     //           window.location.reload();
                
     //       }
     //   }
     //   ))

       
       

    }
    transferdata = async () => {
        this.openModal();
        var directenergy = JSON.parse(localStorage.getItem("directenergy"));
        if (directenergy == null) {

            directenergy = [];
        }
        var indirectenergy = JSON.parse(localStorage.getItem("indirectenergy"));
        if (indirectenergy == null) {

            indirectenergy = [];
        }
        var reconsumption = JSON.parse(localStorage.getItem("reconsumption"));
        if (reconsumption == null) {

            reconsumption = [];
        }
        var nonreconsumption = JSON.parse(localStorage.getItem("nonreconsumption"));
        if (nonreconsumption == null) {

            nonreconsumption = [];
        }
        var energyintensity = JSON.parse(localStorage.getItem("energyintensity"));

        console.log(energyintensity)
        if (energyintensity == null) {

            energyintensity = [];
        }
        var directghgscope1 = JSON.parse(localStorage.getItem("directghgscope1"));
        if (directghgscope1 == null) {

            directghgscope1 = [];
        }
        var directghgscope2 = JSON.parse(localStorage.getItem("directghgscope2"));
        if (directghgscope2 == null) {

            directghgscope2 = [];
        }
        var othersignificant = JSON.parse(localStorage.getItem("othersignificant"));
        if (othersignificant == null) {

            othersignificant = [];
        }
        var waterrecycled = JSON.parse(localStorage.getItem("waterrecycled"));
        if (waterrecycled == null) {

            waterrecycled = [];
        }
        var discharge = JSON.parse(localStorage.getItem("discharge"));
        if (discharge == null) {

            discharge = [];
        }
        var rwharvesting = JSON.parse(localStorage.getItem("rwharvesting"));
        if (rwharvesting == null) {

            rwharvesting = [];
        }
        var wastebytd = JSON.parse(localStorage.getItem("wastebytd"));

        if (wastebytd == null) {

            wastebytd = [];
        }
        var rawmaterial = JSON.parse(localStorage.getItem("rawmaterial"));
        if (rawmaterial == null) {

            rawmaterial = [];
        }
        var sfclabelled = JSON.parse(localStorage.getItem("sfclabelled"));
        if (sfclabelled == null) {

            sfclabelled = [];
        }
        var carbon = JSON.parse(localStorage.getItem("carbon"));
        if (carbon == null) {

            carbon = [];
        }
        var legal = JSON.parse(localStorage.getItem("legal"));
        if (legal == null) {

            legal = [];
        }
        var legaloccupational = JSON.parse(localStorage.getItem("legaloccupational"));
        if (legaloccupational == null) {

            legaloccupational = [];
        }
        var expenditure = JSON.parse(localStorage.getItem("expenditure"));
        if (expenditure == null) {

            expenditure = [];
        }
        var costavoidance = JSON.parse(localStorage.getItem("costavoidance"));
        if (costavoidance == null) {

            costavoidance = [];
        }
        var waterwithdrawal = JSON.parse(localStorage.getItem("waterwithdrawal"));
        if (waterwithdrawal == null) {

            waterwithdrawal = [];
        }
        var fatalities = JSON.parse(localStorage.getItem("fatalities"));
        if (fatalities == null) {

            fatalities = [];
        }
        var fire = JSON.parse(localStorage.getItem("fire"));
        if (fire == null) {

            fire = [];
        }
        var triand = JSON.parse(localStorage.getItem("triand"));
        if (triand == null) {

            triand = [];
        }
        var ehstraining = JSON.parse(localStorage.getItem("ehstraining"));
        if (ehstraining == null) {

            ehstraining = [];
        }

        /*   var ozone = JSON.parse(localStorage.getItem("ozone"));
           var emissions = JSON.parse(localStorage.getItem("emissions"));
           var dischargequality = JSON.parse(localStorage.getItem("dischargequality"));
           var spills = JSON.parse(localStorage.getItem("spills"));
           var injuries = JSON.parse(localStorage.getItem("injuries"));
           var production = JSON.parse(localStorage.getItem("production"));
           var suggestion = JSON.parse(localStorage.getItem("suggestion"));
           var medical = JSON.parse(localStorage.getItem("medical")); */


        


        /*  localStorage.removeItem("ozone");
          localStorage.removeItem("emissions");
          localStorage.removeItem("dischargequality");
          localStorage.removeItem("spills");
          localStorage.removeItem("legal");
          localStorage.removeItem("injuries");
          localStorage.removeItem("production");
          localStorage.removeItem("suggestion");
          localStorage.removeItem("medical");
          console.log(rawmaterial)*/
        
        if (directenergy == null && indirectenergy == null && reconsumption == null && nonreconsumption == null && energyintensity == null && directghgscope1 == null && directghgscope2 == null && othersignificant == null && waterrecycled == null && discharge == null && rwharvesting == null && wastebytd == null && rawmaterial == null && sfclabelled == null && carbon == null && legal == null && legaloccupational == null && expenditure == null && costavoidance == null && waterwithdrawal == null && fatalities == null && fire == null && triand == null && ehstraining == null) {
            alert("No data Submitted")

        }

        else {
            let utype = parseInt(this.state.utype);
            let location = parseInt(this.state.location);
            var Reference=localStorage.getItem('globalvar')
            var response = [];
            debugger;
            let row = {


                "UserId": utype,
                "LocationId": location,
                "SubmittedTo": parseInt(this.state.admincode),
               "ReferenceId":Reference

            };
            let rows = [...this.state.rows];
            rows[0] = row;
           
            this.setState({
                rows
            });
            console.log(this.state.rows)

            // const { rows } = this.state;
            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            var newbody = JSON.parse(Options.body);
            

            //var transactionmodel = [newbody, directenergy, indirectenergy, reconsumption, nonreconsumption, energyintensity, directghgscope1, directghgscope2, othersignificant, waterrecycled, discharge, rwharvesting, wastebytd, rawmaterial, sfclabelled, carbon, legal, legaloccupational, expenditure, costavoidance, waterwithdrawal, fatalities, fire, triand, ehstraining]
           
            //axios.all([


            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/UserSubmission/SaveESGSubmission', newbody),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectEnergyConsumption/SaveDirectEnergyConsumption', directenergy),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/IndirectEnergyConsumption/SaveIndirectEnergyConsumed', indirectenergy),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/SaveRenewableEnergyConsumed', reconsumption),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/NonRenewableConsumption/SaveNonRenewableDetails', nonreconsumption),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EnergyIntensity/SaveEnergyIntensityDetails', energyintensity),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectGhgEmission1/SaveDirectEmissiononeDetails', directghgscope1),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectGhgEmission2/SaveDirectEmissiontwoDetails', directghgscope2),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/NoxSoxEmissions/SaveNoxSoxEmissionsDetails', othersignificant),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalWaterRecycled/SaveTotalWaterRecycled', waterrecycled),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalDischargedWater/SaveTotalDischargedWater', discharge),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/RainWaterHarvesting/SaveRainHarvestTransac', rwharvesting),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/WasteByTd/SaveWasteByTd', wastebytd),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalRawMaterial/SaveTotalRawMaterials', rawmaterial),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SfcLabelledMaterial/SaveSfcLabelledMaterialDetails', sfclabelled),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SignificantCarbonEmission/SaveCarbonEmission', carbon),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/ComplianceDetails/SaveComplianceData', legal),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyComplianceDetails/SaveSafetyComplianceDetails', legaloccupational),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalExpenditure/SaveTotalExpenditure', expenditure),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalCostAvoidance/SaveTotalCostAvoidance', costavoidance),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalWaterWithdrawn/SaveTotalWaterWithdrawn', waterwithdrawal),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkRelatedFatalities/SaveWorkRelatedFatalities', fatalities),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/MisFireAccident/SaveMisFireAccident', fire),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TriAndLtiFr/SaveTriAndLtiFr', triand),

            //    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTraining/SaveEhsTraining', ehstraining),

               

            //]).then(axios.spread((...responses) => {

            //    response = responses[0].data
            //    this.state.submissionid = response.SubmissionId
            //   this.state.submissiondate=response.SubmissionDate
            //})).catch(errors => {

            //    console.log(errors)
            //    console.log("Data Not Saved")
            //})
           // var transactionmodel = directenergy



            


            

            await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/UserSubmission/SaveESGSubmission', newbody).then(response => {
                debugger;
               
                this.closeModal();
                var unique_id = "null";
                if (response.data.SubmissionId != 0) {
                    unique_id = uuid();
                    localStorage.setItem('globalvar', unique_id)
                }
                   localStorage.setItem("submitcounter",true)

                    localStorage.removeItem("directenergy");
                    localStorage.removeItem("indirectenergy");
                    localStorage.removeItem("reconsumption");
                    localStorage.removeItem("nonreconsumption");
                    localStorage.removeItem("energyintensity");
                    localStorage.removeItem("directghgscope1");
                    localStorage.removeItem("directghgscope2");
                    localStorage.removeItem("othersignificant");
                    localStorage.removeItem("waterrecycled");
                    localStorage.removeItem("discharge");
                    localStorage.removeItem("rwharvesting");
                    localStorage.removeItem("wastebytd");
                    localStorage.removeItem("rawmaterial");
                    localStorage.removeItem("sfclabelled");
                    localStorage.removeItem("carbon");
                    localStorage.removeItem("legal");
                    localStorage.removeItem("legaloccupational");
                    localStorage.removeItem("expenditure");
                    localStorage.removeItem("costavoidance");
                    localStorage.removeItem("waterwithdrawal");
                    localStorage.removeItem("fatalities");
                    localStorage.removeItem("fire");
                    localStorage.removeItem("triand");
                    localStorage.removeItem("ehstraining");
                    var email = localStorage.getItem("adminemail")
                    debugger;
                    var submissionid = response.data.SubmissionId;
                var submissiondate = response.data.SubmissionDate

                    var locationName = this.state.locationName;
                    let rows1 = {
                        "ToEmail": email,
                        "Subject": "Action Required",
                        "Body": "Dear user,<br />Action is required for the Request no &nbsp;" + submissionid + "&nbsp;for &nbsp;" + locationName + "&nbsp; Location dated  &nbsp;" + submissiondate + ".<br />Kindly do the needful at earliest.<br />Link: www.ehs.timesgroup.com .<br /> .<br /> Thanks,"

                    }

                    const Options1 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows1)
                    };
                    let checkbody = JSON.parse(Options1.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/Mail/Send', checkbody)
                        .then(response => {

                        })
                    var isopen = this.state.isOpen;
                    if (isopen == false) {
                        alert("Data Saved Successfully")
                        window.location.reload();
                    }
               
            })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectEnergyConsumption/SaveDirectEnergyConsumption', directenergy).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/IndirectEnergyConsumption/SaveIndirectEnergyConsumed', indirectenergy).then(response => { })
            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/SaveRenewableEnergyConsumed', reconsumption).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/NonRenewableConsumption/SaveNonRenewableDetails', nonreconsumption).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EnergyIntensity/SaveEnergyIntensityDetails', energyintensity).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectGhgEmission1/SaveDirectEmissiononeDetails', directghgscope1).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectGhgEmission2/SaveDirectEmissiontwoDetails', directghgscope2).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/NoxSoxEmissions/SaveNoxSoxEmissionsDetails', othersignificant).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalWaterRecycled/SaveTotalWaterRecycled', waterrecycled).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalDischargedWater/SaveTotalDischargedWater', discharge).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/RainWaterHarvesting/SaveRainHarvestTransac', rwharvesting).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/WasteByTd/SaveWasteByTd', wastebytd).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalRawMaterial/SaveTotalRawMaterials', rawmaterial).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SfcLabelledMaterial/SaveSfcLabelledMaterialDetails', sfclabelled).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SignificantCarbonEmission/SaveCarbonEmission', carbon).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/ComplianceDetails/SaveComplianceData', legal).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyComplianceDetails/SaveSafetyComplianceDetails', legaloccupational).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalExpenditure/SaveTotalExpenditure', expenditure).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalCostAvoidance/SaveTotalCostAvoidance', costavoidance).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalWaterWithdrawn/SaveTotalWaterWithdrawn', waterwithdrawal).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/WorkRelatedFatalities/SaveWorkRelatedFatalities', fatalities).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/MisFireAccident/SaveMisFireAccident', fire).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/TriAndLtiFr/SaveTriAndLtiFr', triand).then(response => { })

            //await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTraining/SaveEhsTraining', ehstraining).then(response => { //})

          
            //debugger;
            //await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SaveAllTemporaryTables', { params: { ReferenceId: Reference } }).then(response => {
            //    debugger;
            //    if (response.data != 0) {
            //        console.log("In then Window")
            //        console.log(response.data)
            //        this.closeModal();


            //        localStorage.removeItem("directenergy");
            //        localStorage.removeItem("indirectenergy");
            //        localStorage.removeItem("reconsumption");
            //        localStorage.removeItem("nonreconsumption");
            //        localStorage.removeItem("energyintensity");
            //        localStorage.removeItem("directghgscope1");
            //        localStorage.removeItem("directghgscope2");
            //        localStorage.removeItem("othersignificant");
            //        localStorage.removeItem("waterrecycled");
            //        localStorage.removeItem("discharge");
            //        localStorage.removeItem("rwharvesting");
            //        localStorage.removeItem("wastebytd");
            //        localStorage.removeItem("rawmaterial");
            //        localStorage.removeItem("sfclabelled");
            //        localStorage.removeItem("carbon");
            //        localStorage.removeItem("legal");
            //        localStorage.removeItem("legaloccupational");
            //        localStorage.removeItem("expenditure");
            //        localStorage.removeItem("costavoidance");
            //        localStorage.removeItem("waterwithdrawal");
            //        localStorage.removeItem("fatalities");
            //        localStorage.removeItem("fire");
            //        localStorage.removeItem("triand");
            //        localStorage.removeItem("ehstraining");
            //        var email = localStorage.getItem("adminemail")
            //        debugger;
            //        var submissionid = this.state.submissionid;
            //        var submissiondate = this.state.submissiondate

            //        var locationName = this.state.locationName;
            //        let rows1 = {
            //            "ToEmail": email,
            //            "Subject": "Action Required",
            //            "Body": "Dear user,<br />Action is required for the Request no &nbsp;" + submissionid + "&nbsp;for &nbsp;" + locationName + "&nbsp; Location dated  &nbsp;" + submissiondate + ".<br />Kindly do the needful at earliest.<br />Link: www.ehs.timesgroup.com .<br /> .<br /> Thanks,"

            //        }

            //        const Options1 = {
            //            method: 'POST',
            //            headers: { 'Content-Type': 'application/json' },
            //            body: JSON.stringify(rows1)
            //        };
            //        let checkbody = JSON.parse(Options1.body);
            //        axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/Mail/Send', checkbody)
            //            .then(response => {

            //            })
            //        var isopen = this.state.isOpen;
            //        if (isopen == false) {
            //            alert("Data Saved Successfully")
            //            window.location.reload();
            //        }
            //    }
            //    else {

            //        alert("Something Went Wrong")
            //    }
            //}
            //)
           
        }
    
    }



     /*transferdata = async () => {
         await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserSubmission/GetMaxIdSumitted')
            .then(response => this.setState({ typesapi: response.data })


        )
        this.transferdata2();
    }
            
             

    
      
transferdata2 = () => {

    let utype = parseInt(this.state.utype);
    let location = parseInt(this.state.location);

    let row = {

        "MisProdId": this.state.typesapi[0],
        "EconsumptionId": this.state.typesapi[1],
        "EmittedId": this.state.typesapi[2],
        "MedicalId": this.state.typesapi[3],
        "MisFireId": this.state.typesapi[4],
        "InjuryId": this.state.typesapi[5],
        "ComplianceId": this.state.typesapi[6],
        "SuggestionsId": this.state.typesapi[7],
        "WatQualId": this.state.typesapi[8],
        "TotWatId": this.state.typesapi[9],
        "TotalRecyledId": this.state.typesapi[10],
        "WatDischargedId": this.state.typesapi[11],
        "TmatId": this.state.typesapi[12],
        "ExpenditureId": this.state.typesapi[13],
        "SpillageId": this.state.typesapi[14],
        "CoEmissionId": this.state.typesapi[15],
        "RenewId": this.state.typesapi[16],
        "HarvestTranId": this.state.typesapi[17],
        "OzoneId": this.state.typesapi[18],
        "IndEnergyConId": this.state.typesapi[19],
        "UserId": utype,
        "LocationId": location,
        "SubmittedTo": parseInt(this.state.admincode)

    };
    let rows = [...this.state.rows];
    rows[0] = row;
    
    this.setState({
        rows
    });
    console.log(this.state.rows)

       // const { rows } = this.state;
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
    axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/UserSubmission/SaveUserSubmission', newbody)
            .then(response => {
                if (response.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            })
    } */

    handleChange2 = event => {

        event.preventDefault();
        const name = event.target.name;

        const value = event.target.value;


        this.setState({
            [name]: value
        });
    };

   
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => {
        

        this.setState({ isOpen: false })
    };

    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit2 = event => {
       
        event.preventDefault();
        document.getElementById("month").disabled = true;
        document.getElementById("year").disabled = true;
        document.getElementById("submit").style.display = "none";
        var Reference = localStorage.getItem('globalvar')
        var userid = parseInt(this.state.utype);
        var location = parseInt(this.state.location);
        var month = this.state.month
        var year = this.state.year
        debugger;
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/CheckESGFormReSubmission', { params: { ReferenceId: Reference,UserId:userid, Month: month, Year: year,Mode:1 } }).then(response => {
            debugger;
            if (response.data > 0) {
                alert("Data Already Submitted for this Month.Kindly Choose Different Month")
                this.setSelected(this.state.selected);
                localStorage.setItem('month1', this.state.month)
                localStorage.setItem('year1', this.state.year);
                document.getElementById("change").style.display = "inline";
                localStorage.setItem("submitcounter", true)
                
                window.location.reload()
            }

            else {
                localStorage.setItem("submitcounter", false)
                
                localStorage.removeItem("directenergy");
                localStorage.removeItem("indirectenergy");
                localStorage.removeItem("reconsumption");
                localStorage.removeItem("nonreconsumption");
                localStorage.removeItem("energyintensity");
                localStorage.removeItem("directghgscope1");
                localStorage.removeItem("directghgscope2");
                localStorage.removeItem("othersignificant");
                localStorage.removeItem("waterrecycled");
                localStorage.removeItem("discharge");
                localStorage.removeItem("rwharvesting");
                localStorage.removeItem("wastebytd");
                localStorage.removeItem("rawmaterial");
                localStorage.removeItem("sfclabelled");
                localStorage.removeItem("carbon");
                localStorage.removeItem("legal");
                localStorage.removeItem("legaloccupational");
                localStorage.removeItem("expenditure");
                localStorage.removeItem("costavoidance");
                localStorage.removeItem("waterwithdrawal");
                localStorage.removeItem("fatalities");
                localStorage.removeItem("fire");
                localStorage.removeItem("triand");
                localStorage.removeItem("ehstraining");
                this.setSelected(this.state.selected);
                localStorage.setItem('month1', this.state.month)
                localStorage.setItem('year1', this.state.year);
                document.getElementById("change").style.display = "inline";
           
               
            }
        })
        
       

        


    }

    handleSubmit3 = event => {
    
        event.preventDefault();
        var Reference = localStorage.getItem('globalvar')
        var userid = parseInt(this.state.utype);
        var location = parseInt(this.state.location);
        var month = this.state.month
        var year = this.state.year
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/CheckESGFormReSubmission', { params: { ReferenceId: Reference, UserId: userid, Month: month, Year: year, Mode: 3 } }).then(response => {
            debugger;
            if (response.data > 0) {

                var result = window.confirm("You have partially saved data but not submitted.Do you want to submit?.If clicked Cancel,Data will be lost");
                if (result) {
                    let row = {


                        "UserId": userid,
                        "LocationId": location,
                        "SubmittedTo": parseInt(this.state.admincode),
                        "ReferenceId": Reference

                    };
                    let rows = [...this.state.rows];
                    rows[0] = row;

                    console.log(this.state.rows)

                    // const { rows } = this.state;
                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    var newbody = JSON.parse(Options.body);

                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/UserSubmission/SaveESGSubmission', newbody).then(response => {

                        var unique_id = "null";
                        if (response.data.SubmissionId != 0) {
                            unique_id = uuid();
                            localStorage.setItem('globalvar', unique_id)
                            debugger;
                            localStorage.removeItem("directenergy");
                            localStorage.removeItem("indirectenergy");
                            localStorage.removeItem("reconsumption");
                            localStorage.removeItem("nonreconsumption");
                            localStorage.removeItem("energyintensity");
                            localStorage.removeItem("directghgscope1");
                            localStorage.removeItem("directghgscope2");
                            localStorage.removeItem("othersignificant");
                            localStorage.removeItem("waterrecycled");
                            localStorage.removeItem("discharge");
                            localStorage.removeItem("rwharvesting");
                            localStorage.removeItem("wastebytd");
                            localStorage.removeItem("rawmaterial");
                            localStorage.removeItem("sfclabelled");
                            localStorage.removeItem("carbon");
                            localStorage.removeItem("legal");
                            localStorage.removeItem("legaloccupational");
                            localStorage.removeItem("expenditure");
                            localStorage.removeItem("costavoidance");
                            localStorage.removeItem("waterwithdrawal");
                            localStorage.removeItem("fatalities");
                            localStorage.removeItem("fire");
                            localStorage.removeItem("triand");
                            localStorage.removeItem("ehstraining");
                            alert("Data Saved Successfully")
                            window.location.reload();
                        }


                    })
                }
                else {
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/CheckESGFormReSubmission', { params: { ReferenceId: Reference, UserId: userid, Month: month, Year: year, Mode: 2 } }).then(response => {

                        localStorage.removeItem("directenergy");
                        localStorage.removeItem("indirectenergy");
                        localStorage.removeItem("reconsumption");
                        localStorage.removeItem("nonreconsumption");
                        localStorage.removeItem("energyintensity");
                        localStorage.removeItem("directghgscope1");
                        localStorage.removeItem("directghgscope2");
                        localStorage.removeItem("othersignificant");
                        localStorage.removeItem("waterrecycled");
                        localStorage.removeItem("discharge");
                        localStorage.removeItem("rwharvesting");
                        localStorage.removeItem("wastebytd");
                        localStorage.removeItem("rawmaterial");
                        localStorage.removeItem("sfclabelled");
                        localStorage.removeItem("carbon");
                        localStorage.removeItem("legal");
                        localStorage.removeItem("legaloccupational");
                        localStorage.removeItem("expenditure");
                        localStorage.removeItem("costavoidance");
                        localStorage.removeItem("waterwithdrawal");
                        localStorage.removeItem("fatalities");
                        localStorage.removeItem("fire");
                        localStorage.removeItem("triand");
                        localStorage.removeItem("ehstraining");
                        this.setSelected(this.state.selected);
                        localStorage.setItem('month1', this.state.month)
                        localStorage.setItem('year1', this.state.year);
                        document.getElementById("change").style.display = "inline";
                        alert("Partial Data Deleted Successfully")
                    })

                }
            }

            else {
                //localStorage.removeItem("directenergy");
                //localStorage.removeItem("indirectenergy");
                //localStorage.removeItem("reconsumption");
                //localStorage.removeItem("nonreconsumption");
                //localStorage.removeItem("energyintensity");
                //localStorage.removeItem("directghgscope1");
                //localStorage.removeItem("directghgscope2");
                //localStorage.removeItem("othersignificant");
                //localStorage.removeItem("waterrecycled");
                //localStorage.removeItem("discharge");
                //localStorage.removeItem("rwharvesting");
                //localStorage.removeItem("wastebytd");
                //localStorage.removeItem("rawmaterial");
                //localStorage.removeItem("sfclabelled");
                //localStorage.removeItem("carbon");
                //localStorage.removeItem("legal");
                //localStorage.removeItem("legaloccupational");
                //localStorage.removeItem("expenditure");
                //localStorage.removeItem("costavoidance");
                //localStorage.removeItem("waterwithdrawal");
                //localStorage.removeItem("fatalities");
                //localStorage.removeItem("fire");
                //localStorage.removeItem("triand");
                //localStorage.removeItem("ehstraining");
                this.setSelected(this.state.selected);
                localStorage.setItem('month1', this.state.month)
                localStorage.setItem('year1', this.state.year);
                document.getElementById("month").disabled = false;
                document.getElementById("year").disabled = false;
                document.getElementById("submit").style.display = "inline";
                document.getElementById("change").style.display = "none";


            }
        })

        
        
    }

    render() {
        return (
            <div className="App mt-4">
                <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                    <path d="M7.5 1v7h1V1h-1z" />
                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                </svg></button><br /> <br />
                <label> Data For:  </label>
                <FormControl>

                    <select

                        value={this.state.month}
                        name="month"
                        id="month"
                            onChange={this.handleChange2}
                            disabled={this.state.disabled}
                    >
                        <option selected disabled value='0'>--Select Month--</option>
                        <option value={this.state.month1} >January</option>
                        <option value={this.state.month2} >February</option>
                        <option value={this.state.month3} >March</option>
                        <option value={this.state.month4} >April</option>
                        <option value={this.state.month5} >May</option>
                        <option value={this.state.month6} >June</option>
                        <option value={this.state.month7} >July</option>
                        <option value={this.state.month8} >August</option>
                        <option value={this.state.month9} >September</option>
                        <option value={this.state.month10} >October</option>
                        <option value={this.state.month11} >November</option>
                        <option value={this.state.month12} >December</option>

                    </select>

                </FormControl>

                    &nbsp;

                <FormControl>
                    <select
                        value={this.state.year}
                        name="year"
                        id="year"
                            onChange={this.handleChange2}
                            disabled={this.state.disabled}
                    >
                        <option selected disabled value='0'>--Select Year--</option>
                        <option value={this.state.prevtoPrevYear} >{this.state.prevtoPrevYear}</option>
                        <option value={this.state.prevYear} >{this.state.prevYear}</option>
                        <option value={this.state.longYear} >{this.state.longYear}</option>
                    </select>
                </FormControl>
              
               <button id="submit" style={{ marginLeft: '15px', fontWeight: 'bold', display: this.state.displayed }} onClick={this.handleSubmit2} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
                <button id="change" style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit3} type="submit" className="btn btn-success btn-sm">CHANGE</button>
               
                <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn x   btn-primary btn-md">View Previous Data</button>
                <label> </label>
                <br />
                <br /> 
                
                <TabNav

                    tabs={['1.Non Renewable Fuels Consumed', '2.Non Renewable Energy Purchased', '3.Renewable Energy Consumption', '4.Non Renewable Energy Consumption', '5.Energy Intensity', '6.Direct GHG Emission(Scope 1)', '7.Direct GHG Emission(Scope 2)', '8.NOx,SOx(tonnes)', '9.Water Recycled', '10.Water Discharge', '11.Rain Water Harvesting', '12.Waste By Type and Disposal', '13.Raw Material', '14.SFC Labelled Material', '15.CO2 Emissions(Scope3)','16.Legal Compliance Environment', '17.Legal Compliance Safety', '18.Total Expenditure', '19.Total Cost Avoidance','20.Total Water Withdrawal', '21.Work Related Fatalities', '22.Total Occupational Illness','23.TRI AND LTI FR','24.EHS Trainings']} selected={this.state.selected} setSelected={this.setSelected}>


                    <Tab value={0} isSelected={this.state.selected === '1.Non Renewable Fuels Consumed'}  >
                        <Deconsumption key={this.state.requirementKey}/>

                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('2.Non Renewable Energy Purchased')}>NEXT</button>


                    </Tab>

                    <Tab value={1} isSelected={this.state.selected === '2.Non Renewable Energy Purchased'} >
                        <Ieconsumption key={this.state.requirementKey} />
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('3.Renewable Energy Consumption')}>NEXT</button >
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('1.Non Renewable Fuels Consumed')}>PREV</button >
                    </Tab>
                    <Tab value={2} isSelected={this.state.selected === '3.Renewable Energy Consumption'}>
                        <Reconsumption key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('4.Non Renewable Energy Consumption')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('2.Non Renewable Energy Purchased')}>PREV</button>
                    </Tab>
                    <Tab value={3} isSelected={this.state.selected === '4.Non Renewable Energy Consumption'}>
                        <Nonreconsumption key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('5.Energy Intensity')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('3.Renewable Energy Consumption')}>PREV</button>
                    </Tab>
                    <Tab value={4} isSelected={this.state.selected === '5.Energy Intensity'}>
                        <Energyintensity key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('6.Direct GHG Emission(Scope 1)')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('4.Non Renewable Energy Consumption')}>PREV</button>

                    </Tab>
                    <Tab value={5} isSelected={this.state.selected === '6.Direct GHG Emission(Scope 1)'}>
                        <Directghgemission1 key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('7.Direct GHG Emission(Scope 2)')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('5.Energy Intensity')}>PREV</button>
                    </Tab>
                    <Tab value={6} isSelected={this.state.selected === '7.Direct GHG Emission(Scope 2)'}>
                        <Directghgemission2 key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('8.NOx,SOx(tonnes)')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('6.Direct GHG Emission(Scope 1)')}>PREV</button>
                    </Tab>
                    <Tab value={7} isSelected={this.state.selected === '8.NOx,SOx(tonnes)'}>
                        < OtherSignificant key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('9.Water Recycled')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('7.Direct GHG Emission(Scope 2)')}>PREV</button>
                    </Tab>
                    <Tab value={8} isSelected={this.state.selected === '9.Water Recycled'}>
                        <Waterrecycled key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('10.Water Discharge')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('8.NOx,SOx(tonnes)')}>PREV</button>
                    </Tab>

                    <Tab value={9} isSelected={this.state.selected === '10.Water Discharge'}>
                        <Waterdischarge key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('11.Rain Water Harvesting')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('9.Water Recycled')}>PREV</button>
                    </Tab>
                    <Tab value={10} isSelected={this.state.selected === '11.Rain Water Harvesting'}>
                        <Rwharvesting key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('12.Waste By Type and Disposal')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('10.Water Discharge')}>PREV</button>
                    </Tab>
                    <Tab value={11} isSelected={this.state.selected === '12.Waste By Type and Disposal'}>
                        <Wastebytd key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('13.Raw Material')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('11.Rain Water Harvesting')}>PREV</button>
                    </Tab>
                    <Tab value={12} isSelected={this.state.selected === '13.Raw Material'}>
                        <Rawmaterial key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('14.SFC Labelled Material')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('12.Waste By Type and Disposal')}>PREV</button>
                    </Tab>
                    <Tab value={13} isSelected={this.state.selected === '14.SFC Labelled Material'}>
                        <Sfclabelledmaterial key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('15.CO2 Emissions(Scope3)')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('13.Raw Material')}>PREV</button>
                    </Tab>
                    <Tab value={14} isSelected={this.state.selected === '15.CO2 Emissions(Scope3)'}>
                        <Carbonemission key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('16.Legal Compliance Environment')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('14.SFC Labelled Material')}>PREV</button>
                    </Tab>
                    <Tab value={15} isSelected={this.state.selected === '16.Legal Compliance Environment'}>
                        <Legalcompliance key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('17.Legal Compliance Safety')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('15.CO2 Emissions(Scope3)')}>PREV</button>
                    </Tab>
                    <Tab value={16} isSelected={this.state.selected === '17.Legal Compliance Safety'}>
                        <Legalcomplianceoccupational key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('18.Total Expenditure')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('16.Legal Compliance Environment')}>PREV</button>
                    </Tab>
                    <Tab value={17} isSelected={this.state.selected === '18.Total Expenditure'}>
                        <Totalexpenditure key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('19.Total Cost Avoidance')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('17.Legal Compliance Safety')}>PREV</button>
                    </Tab>
                    <Tab value={18} isSelected={this.state.selected === '19.Total Cost Avoidance'}>
                        <Costavoidance key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('20.Total Water Withdrawal')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('18.Total Expenditure')}>PREV</button>
                    </Tab>
                    <Tab value={19} isSelected={this.state.selected === '20.Total Water Withdrawal'}>
                        <Waterwithdrawal key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('21.Work Related Fatalities')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('19.Total Cost Avoidance')}>PREV</button>
                    </Tab>
                    <Tab value={20} isSelected={this.state.selected === '21.Work Related Fatalities'}>
                        <Workrelatedfatalities key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('22.Total Occupational Illness')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('20.Total Water Withdrawal')}>PREV</button>
                    </Tab>
                    <Tab value={21} isSelected={this.state.selected === '22.Total Occupational Illness'}>
                        <Misfireaccident key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('23.TRI AND LTI FR')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('21.Work Related Fatalities')}>PREV</button>
                    </Tab>
                    <Tab value={22} isSelected={this.state.selected === '23.TRI AND LTI FR'}>
                        <Triandltifr key={this.state.requirementKey}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('24.EHS Trainings')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('22.Total Occupational Illness')}>PREV</button>
                    </Tab>
                    <Tab value={23} isSelected={this.state.selected === '24.EHS Trainings'}>
                        <Ehstraining key={this.state.requirementKey}/>

                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" disabled={this.isDisabled1()} onClick={this.handleSubmit1}>SUBMIT FOR APPROVAL</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('23.TRI AND LTI FR')}>PREV</button>

                    </Tab>
                </TabNav>
                <Modal style={{ backgroundColor: '#ffffff00', borderColor: '#ffffff00', width: '840px' }}
                    size="lg"
                    show={this.state.isOpen} >
                    <div className="App1" >
                        <Modal.Header>
                            <Modal.Title>Validating Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='sweet-loading' style={{marginLeft:'300px'}}>
                                <Rings  show={this.state.loading} type="box" message="Loading Data" />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                           
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>

        );
    }
}
export default Tabbar;
