import React,{Component} from 'react'
import {Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink  } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';



class FireSafetyReadiness extends Component {

    constructor(props) {
     super(props)
     this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            types: [],
            rows: [],
            rows1: [],
            selected:null,
            unit: '',
            validationError: "",
      count:0,
      type1:'Completed',
      type2:'In Progress', 
      type3:'To be expedited',
      type4:'Not Applicable',
        rows:[{id:0}],
        
            login: [],
            ucode:localStorage.getItem('username'),
            utype:localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            admincode: localStorage.getItem('admincode'),
             longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear:prevYear,
            check: ""
     }
    } 

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        
    }  

    
    
    handleChange = (idx) => (e) => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        const value = e.target.value;

        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)

        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows[idx],

            [name]: value,
            "StructureId": idx + 1,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[idx] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],

            "FormId": 15,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });

    };




   
                          handleAddRow =() => (event) => {
                              this.setState({ selected: null });
                            let count=this.state.count
                            count=count+1;
                            this.setState({count})

                            // this.setState((prevState) => ({ count: prevState.count + '1'}));
                         
                            const item = {
                                id: count,
                                unit:''
                              // type: "",
                              // unit: "",
                              // consumed: "",
                              // recycle:""
                              
                            };
                            event.preventDefault();
                            this.setState({
                              rows: [...this.state.rows, item]
                           
                              
                            });
                          };
                          // handleRemoveRow = (event) => {
                          //   event.preventDefault();
                          //   this.setState({
                          //     rows: this.state.rows.slice(0, -1)
                          //   });
                          // };
                          handleRemoveSpecificRow = (idx) => (e) => {

                              e.preventDefault();
                              let count = this.state.count
                              count = count-1;
                              this.setState({ count })
                            let rowarr = [...this.state.rows];
                            let deletedItem = rowarr.findIndex(item => item.id === idx);
                            rowarr.splice(deletedItem, 1);
                            this.setState({...this.state.rows=rowarr})
                            
                            // let row = [...this.state.rows.filter(x=>x.id!==idx)];
                            // this.setState({rows:row})
                          
                            


                            //  const rows = [...this.state.rows]
                            //   rows.splice(idx,1)
                            //   this.setState({ rows })
                            //   e.preventDefault()
                          //  var newrows=this.state.rows.slice();
                          //  newrows.splice(idx,1);
                          //  this.setState({ rows:newrows })

                          // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
                            //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
                            //  this.setState({
                            //    rows: newRows,
                            //  });
                         
    }
    
    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }
    
    handleSubmit = async (event) => {
       
     
        event.preventDefault();
        
        const { rows } = this.state;
        const { rows1 } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/FireSafetyReadiness/GetFireSafetyMonthWise', { params: { Month: prevMonth, Year: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows2: response.data
                })

            })

        var rows2 = this.state.rows2
        if (rows2.length == 0) {



            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            let newbody = JSON.parse(Options.body);


            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let checkbody = JSON.parse(Options1.body);
            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/FireSafetyReadiness/SaveFireSafetyList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody),

            ]).then(axios.spread((response1, response2) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));
        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }


        

      
             }   
             
   
    handleClick1 = () => (event) => {

        this.props.history.push("/firesafetyreadinessview");
    };



   render() {
      
       return (
          <Form className="font-link" onSubmit={this.handleSubmit}>
              
            <br />
              {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
              <div className="App">
                  <div className="wordalign">
                  {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                 
                  <label>
                      {this.state.longMonth}   &nbsp;
                   {this.state.longYear}
 

                  </label>
                       <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                       <br />
                       <br />
                  </div>
               </div>
               <br />
              <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                  <thead>
                       <tr style={{ backgroundColor: '#001946', color: 'white' }}>
           
            <th>STRUCTURE TYPE</th>
            <th >STATUS</th>
          
        
                     


            
          </tr>
        </thead>
        <tbody>
                       <br />
                       <label style={{ width: '900px', textAlign: 'center', fontSize: '14px', backgroundColor: '#001946', color: 'white', paddingTop: '5px', paddingBottom: '5px' }}class="no-space-break"> Infra Development </label>
                       <br />
                       <br />
                       <tr id="addr0" key={0}>

                          


                           <td >

                               <label  class="no-space-break"> Building Structure </label>
                           </td >
                          
                           <td >
                               
                               <FormControl>

                                   <Select required
                                       style={{width:'200px'}}
                                       name="Status"
                                       onChange={this.handleChange(0)}
                                   >

                                       <MenuItem value={this.state.type1} >Completed</MenuItem>
                                       <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                       <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                       <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                   </Select>
                                  
                               </FormControl>
                           </td>
                          
                       </tr>
                       <tr id="addr1" key={1}>

                        


                           <td >

                               <label class="no-space-break"> Emergency exits/routes </label>
                           </td >

                           <td>

                               <FormControl>

                                   <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                       onChange={this.handleChange(1)}
                                   >

                                       <MenuItem value={this.state.type1} >Completed</MenuItem>
                                       <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                       <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                       <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                   </Select>

                               </FormControl>
                           </td>

                       </tr>
                       <tr id="addr3" key={3}>

                         


                           <td >

                               <label class="no-space-break"> Power train </label>
                           </td >

                           <td>

                               <FormControl>

                                   <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                       onChange={this.handleChange(2)}
                                   >

                                       <MenuItem value={this.state.type1} >Completed</MenuItem>
                                       <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                       <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                       <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                   </Select>

                               </FormControl>
                           </td>

                       </tr>
                       <tr id="addr4" key={4}>

                         

                           <td >

                               <label class="no-space-break"> Cabling </label>
                           </td >

                           <td>

                               <FormControl>

                                   <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                       onChange={this.handleChange(3)}
                                   >

                                       <MenuItem value={this.state.type1} >Completed</MenuItem>
                                       <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                       <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                       <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                   </Select>

                               </FormControl>
                           </td>

                       </tr>
                       <tr id="addr5" key={5}>

                          


                           <td >

                               <label class="no-space-break"> Surge protection </label>
                           </td >

                           <td>

                               <FormControl>

                                   <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                       onChange={this.handleChange(4)}
                                   >

                                       <MenuItem value={this.state.type1} >Completed</MenuItem>
                                       <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                       <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                       <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                   </Select>

                               </FormControl>
                           </td>

                       </tr>
                       <tr id="addr6" key={6}>

                         



                           <td >

                               <label class="no-space-break"> Air Conditioning </label>
                           </td >

                           <td>

                               <FormControl>

                                   <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                       onChange={this.handleChange(5)}
                                   >

                                       <MenuItem value={this.state.type1} >Completed</MenuItem>
                                       <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                       <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                       <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                   </Select>

                               </FormControl>
                           </td>

                       </tr>
                       <br />
                       <label style={{ width: '900px', textAlign: 'center', fontSize: '14px', backgroundColor: '#001946', color: 'white', paddingTop: '5px', paddingBottom: '5px'  }} class="no-space-break"> Fire System </label>
                       <br />
                       <br />
                       <tr id="addr7" key={7}>

                         



                           <td >

                               <label class="no-space-break"> Fire compartmentation  </label>
                           </td >

                           <td>

                               <FormControl>

                                   <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                       onChange={this.handleChange(6)}
                                   >

                                       <MenuItem value={this.state.type1} >Completed</MenuItem>
                                       <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                       <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                       <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                   </Select>

                               </FormControl>
                           </td>

                       </tr>
                       <tr id="addr8" key={8}>

                         


                           <td >

                               <label class="no-space-break"> Fire detection system </label>
                           </td >

                           <td>

                               <FormControl>

                                   <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                       onChange={this.handleChange(7)}
                                   >

                                       <MenuItem value={this.state.type1} >Completed</MenuItem>
                                       <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                       <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                       <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                   </Select>

                               </FormControl>
                           </td>
                       </tr>
                           <tr id="addr9" key={9}>

                              

                               <td >

                               <label class="no-space-break"> Fire sprinkler system </label>
                               </td >

                               <td>

                                   <FormControl>

                                       <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                           onChange={this.handleChange(8)}
                                       >

                                           <MenuItem value={this.state.type1} >Completed</MenuItem>
                                           <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                           <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                           <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                       </Select>

                                   </FormControl>
                               </td>

                       </tr>
                       <br />
                       <label style={{ width: '900px', textAlign: 'center', fontSize: '14px', backgroundColor: '#001946', color: 'white', paddingTop: '5px', paddingBottom: '5px' }} class="no-space-break"> Security & Housekeeping </label>
                       <br />
                       <br />
                           <tr id="addr10" key={10}>

                             



                               <td >

                               <label class="no-space-break"> Housekeeping & storage </label>
                               </td >

                               <td>

                                   <FormControl>

                                       <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                           onChange={this.handleChange(9)}
                                       >

                                           <MenuItem value={this.state.type1} >Completed</MenuItem>
                                           <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                           <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                           <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                       </Select>

                                   </FormControl>
                               </td>

                           </tr>
                           <tr id="addr11" key={11}>




                               <td >

                               <label class="no-space-break" > CCTV </label>
                               </td >

                               <td>

                                   <FormControl>

                                       <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                           onChange={this.handleChange(10)}
                                       >

                                           <MenuItem value={this.state.type1} >Completed</MenuItem>
                                           <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                           <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                           <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                       </Select>

                                   </FormControl>
                               </td>

                           </tr>
                           <tr id="addr12" key={12}>



                               <td >

                               <label class="no-space-break"> Access control system  </label>
                               </td >

                               <td>

                                   <FormControl>

                                       <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                           onChange={this.handleChange(11)}
                                       >

                                           <MenuItem value={this.state.type1} >Completed</MenuItem>
                                           <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                           <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                           <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                       </Select>

                                   </FormControl>
                               </td>

                       </tr>
                       <br />
                       <label style={{ width: '900px', textAlign: 'center', fontSize: '14px', backgroundColor: '#001946', color: 'white',paddingTop:'5px',paddingBottom:'5px' }} class="no-space-break"> Regulatory </label>
                       <br />
                       <br />
                           <tr id="addr13" key={13}>



                               <td >

                               <label class="no-space-break"> Fire NOC,OC & CC </label>
                               </td >

                               <td>

                                   <FormControl>

                                       <Select required
                                       style={{ width: '200px' }}
                                       name="Status"
                                           onChange={this.handleChange(12)}
                                       >

                                           <MenuItem value={this.state.type1} >Completed</MenuItem>
                                           <MenuItem value={this.state.type2} >In Progress</MenuItem>
                                           <MenuItem value={this.state.type3} >To be expedited</MenuItem>
                                           <MenuItem value={this.state.type4} >Not Applicable</MenuItem>


                                       </Select>

                                   </FormControl>
                               </td>

                           </tr>
                      
                      
           
       </tbody>
       </Table>

   

      
              <button type="submit" style={{ float: 'right'}} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button> 

     {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
       
     
       </Form>
           
      )
    }
}

export default FireSafetyReadiness