import { Chart } from "react-google-charts";
import React, { useState, useEffect, Component } from "react";

import { Line, Bar, Pie, Doughnut, Radar } from "react-chartjs-2";
import axios from "axios";
import "./custom.css"
import Switch from "react-switch";
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { DEBUG } from "@devexpress/analytics-core/core/utils/_utils";
import { Center } from "devextreme-react/map";



class Dashboardehs extends Component {


    constructor(props) {

        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        this.state = {

            Data: {},
            Data1: {},

            Data2: {},
            Data3: {},
            Data4: {},
            Data5: {},
            Data6: {},
            Data7: {},
            Data8: {},
            utype: localStorage.getItem('utype'),

            datalen: '',
            checked: false,
            rows: [],
            check: [{ id: 0 }],
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            time: 'Last Three Months',
            level: 0,
            location: 0,
            locations: [],
            employees: 0,
            manworked: 0,
            training: 0,
            serious: 0,
            nearmiss: 0,
            firstaid: 0,
            minor: 0,
            losttime: 0,
            fatal: 0,
            manlost: 0,
            audit: 0,
            fire: 0,
            mockdrill: 0,
            incident: 0

        };
        this.handleChange = this.handleChange.bind(this);

    }

    async componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);


        var utypeid = localStorage.getItem("utypeid")
        var location = localStorage.getItem("location")

        if (utypeid == 1 || utypeid == 2 || utypeid == 3) {
            document.getElementById('level').style.display = 'none';
            document.getElementById('location').style.display = 'inline';
            await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboard/GetUserRoleLocation')
                .then(response => {

                    let typesapi1 = response.data;
                    console.log(typesapi1);
                    var actloc = location - 1;
                    this.setState({
                        locations: typesapi1,

                    })
                    var locations = this.state.locations
                    this.state.location = locations[actloc].locationId

                })

                .catch(error => {
                    console.log(error);

                });

        }

        else {

            document.getElementById('level').style.display = 'inline';
            document.getElementById('location').style.display = 'none';
            this.setState({
                locations: [],
                location: 0
            })
        }
        let months = this.getLast3Months();
        var today = new Date();
        let longMonth = today.toLocaleString('en-us', { month: 'long' });
        var currentyear = 0;
        if (longMonth == "January") {
            currentyear = today.getFullYear() - 1;
        }
        else {
            currentyear = today.getFullYear();
        }

        var sDate = new Date('01' + '-' + months[2] + '-' + currentyear);
        var eDate = new Date('31' + '-' + months[0] + '-' + currentyear);
        var startDate = sDate.toISOString().substring(0, 10);
        var endDate = eDate.toISOString().substring(0, 10);

        this.handledata(startDate, endDate)

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UplMockDrill/GetMockDrillDetails')
            .then(response => {

                const datalen = response.data.length;

                console.log(datalen);



                this.setState({
                    datalen: datalen
                });



            })
            .catch(err => {
                console.log(err);
            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyWalkList/GetSafetyWalkListData')
            .then(response => {

                let count = 0;
                let count1 = 0;
                let count2 = 0;


                console.log(response.data);
                for (const dataObj of response.data) {
                    if (dataObj.isRequired == 'Yes') {

                        count++;
                    }
                    else if (dataObj.isRequired == 'No') {

                        count1++;
                    }
                    else {

                        count2++;
                    }
                }

                const Data = [['Type', 'Quantity']]

                Data.push(['Yes', count]);
                Data.push(['No', count1]);
                Data.push(['NA', count2]);

                // calorificFuelValue.push(dataObj.calorificFuelValue);
                //fuelType.push(dataObj.fuelType);
                /* let datenew = dataObj.submissionDate;
                 
                 datenew = datenew.split('T', 3).join(' ');
                 datenew = datenew.split(':', 3).join(' ');
                 datenew = datenew.split('00', 3).join(' ');
               
 
                 date.push(datenew)
                 location.push(dataObj.locationName)
                 console.log(fuelType)*/


                this.setState({
                    Data: Data
                });


            })
            .catch(err => {
                console.log(err);
            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalRawMaterial/GetTotalRawMaterials')
            .then(response => {



                console.log(response.data);

                const Data = [['Month', 'Compliance', 'Non Compliance', 'NA']];
                Data.push(['June', 44, 36, 41]);
                Data.push(['July', 32, 22, 67]);
                Data.push(['August', 74, 25, 22]);
                /* for (const dataObj of response.data) {
                     Data.push([dataObj.month, dataObj.groundWater, dataObj.riverOrSurfaceWater, dataObj.municipalOrOther, dataObj.withdrawalHarvest]);
 
                     // calorificFuelValue.push(dataObj.calorificFuelValue);
                     //fuelType.push(dataObj.fuelType);
                      let datenew = dataObj.submissionDate;
                      
                      datenew = datenew.split('T', 3).join(' ');
                      datenew = datenew.split(':', 3).join(' ');
                      datenew = datenew.split('00', 3).join(' ');
                    
     
                      date.push(datenew)
                      location.push(dataObj.locationName)
                      console.log(fuelType)
                 }*/

                this.setState({
                    Data1: Data
                });



            })
            .catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/TotalRawMaterial/GetTotalRawMaterials')
            .then(response => {



                console.log(response.data);

                const Data = [['Status', 'Quantity']];
                Data.push(['Open', 36]);
                Data.push(['Closed', 64]);

                /* for (const dataObj of response.data) {
                     Data.push([dataObj.month, dataObj.groundWater, dataObj.riverOrSurfaceWater, dataObj.municipalOrOther, dataObj.withdrawalHarvest]);
 
                     // calorificFuelValue.push(dataObj.calorificFuelValue);
                     //fuelType.push(dataObj.fuelType);
                      let datenew = dataObj.submissionDate;
                      
                      datenew = datenew.split('T', 3).join(' ');
                      datenew = datenew.split(':', 3).join(' ');
                      datenew = datenew.split('00', 3).join(' ');
                    
     
                      date.push(datenew)
                      location.push(dataObj.locationName)
                      console.log(fuelType)
                 }*/

                this.setState({
                    Data2: Data
                });



            })
            .catch(err => {
                console.log(err);
            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/SignificantSpills/GetSpillageDetails')
            .then(response => {



                console.log(response.data);

                const Data = [['Accident Type', 'Number']];
                Data.push(['First Aid ', 12]);
                Data.push(['Minor Injury', 15]);
                Data.push(['Lost Time Injury', 5]);
                Data.push(['Serious Injury', 1]);
                Data.push(['Fatal', 3]);


                /*  for (const dataObj of response.data) {
                      Data.push([dataObj.spillageType, dataObj.spilledAmt]);
  
                      // calorificFuelValue.push(dataObj.calorificFuelValue);
                      //fuelType.push(dataObj.fuelType);
                      /* let datenew = dataObj.submissionDate;
                       
                       datenew = datenew.split('T', 3).join(' ');
                       datenew = datenew.split(':', 3).join(' ');
                       datenew = datenew.split('00', 3).join(' ');
                     
      
                       date.push(datenew)
                       location.push(dataObj.locationName)
                       console.log(fuelType)
                  }*/

                this.setState({
                    Data3: Data
                });



            })
            .catch(err => {
                console.log(err);
            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/GetRenewablEnergyConsumed').then(response => {
            let typesapi = response.data;

            this.setState({
                rows: typesapi

            });
            console.log(response.data);

            const Data = [['Person Type', 'Number']]
            Data.push(['Employees ', 11]);
            Data.push(['Contractors', 18]);


            /* for (const dataObj of response.data) {
                 Data.push([dataObj.usedFor, dataObj.energyConsumed]);
 
                 // calorificFuelValue.push(dataObj.calorificFuelValue);
                 //fuelType.push(dataObj.fuelType);
                  let datenew = dataObj.submissionDate;
                  
                  datenew = datenew.split('T', 3).join(' ');
                  datenew = datenew.split(':', 3).join(' ');
                  datenew = datenew.split('00', 3).join(' ');
                
 
                  date.push(datenew)
                  location.push(dataObj.locationName)
                  console.log(fuelType)
             }*/

            this.setState({
                Data4: Data
            });



        })
            .catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/GetRenewablEnergyConsumed').then(response => {
            let typesapi = response.data;

            this.setState({
                rows: typesapi

            });
            console.log(response.data);

            const Data = [['Incident Type', 'Number']]
            Data.push(['Near Miss', 14]);
            Data.push(['First Aid', 16]);
            Data.push(['Doctor Visit', 7]);
            Data.push(['Lost Time', 5]);

            Data.push(['Fatality ', 22]);
            /* for (const dataObj of response.data) {
                 Data.push([dataObj.usedFor, dataObj.energyConsumed]);
 
                 // calorificFuelValue.push(dataObj.calorificFuelValue);
                 //fuelType.push(dataObj.fuelType);
                  let datenew = dataObj.submissionDate;
                  
                  datenew = datenew.split('T', 3).join(' ');
                  datenew = datenew.split(':', 3).join(' ');
                  datenew = datenew.split('00', 3).join(' ');
                
 
                  date.push(datenew)
                  location.push(dataObj.locationName)
                  console.log(fuelType)
             }*/

            this.setState({
                Data5: Data
            });



        })
            .catch(err => {
                console.log(err);
            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/GetRenewablEnergyConsumed').then(response => {
            let typesapi = response.data;

            this.setState({
                rows: typesapi

            });
            console.log(response.data);

            const Data = [['Employee Type', 'Number']]
            Data.push(['Regular full time ', 342]);
            Data.push(['Regular part time', 225]);
            Data.push(['Seasonal', 97]);
            Data.push(['Temporary', 86]);

            /* for (const dataObj of response.data) {
                 Data.push([dataObj.usedFor, dataObj.energyConsumed]);
 
                 // calorificFuelValue.push(dataObj.calorificFuelValue);
                 //fuelType.push(dataObj.fuelType);
                  let datenew = dataObj.submissionDate;
                  
                  datenew = datenew.split('T', 3).join(' ');
                  datenew = datenew.split(':', 3).join(' ');
                  datenew = datenew.split('00', 3).join(' ');
                
 
                  date.push(datenew)
                  location.push(dataObj.locationName)
                  console.log(fuelType)
             }*/

            this.setState({
                Data6: Data
            });



        })
            .catch(err => {
                console.log(err);
            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/GetRenewablEnergyConsumed').then(response => {
            let typesapi = response.data;

            this.setState({
                rows: typesapi

            });
            console.log(response.data);

            const Data = [['Nature of Injury ', 'Number']]
            Data.push(['Abrasion,scrapes ', 82]);
            Data.push(['Amputation', 25]);
            Data.push(['Broken Nose', 97]);
            Data.push(['Bruise', 86]);
            Data.push(['Burn(Heat)', 22]);
            Data.push(['Burn(Chemical)', 78]);
            Data.push(['Concussion(to the head)', 12]);
            Data.push(['Crushing Injury', 43]);
            Data.push(['Cut,laceration,puncture', 24]);
            Data.push(['Hernia', 53]);
            Data.push(['Illness', 22]);
            Data.push(['Sprain,strain', 21]);
            Data.push(['Damage to body system', 45]);
            Data.push(['Other', 12]);

            /* for (const dataObj of response.data) {
                 Data.push([dataObj.usedFor, dataObj.energyConsumed]);
 
                 // calorificFuelValue.push(dataObj.calorificFuelValue);
                 //fuelType.push(dataObj.fuelType);
                  let datenew = dataObj.submissionDate;
                  
                  datenew = datenew.split('T', 3).join(' ');
                  datenew = datenew.split(':', 3).join(' ');
                  datenew = datenew.split('00', 3).join(' ');
                
 
                  date.push(datenew)
                  location.push(dataObj.locationName)
                  console.log(fuelType)
             }*/

            this.setState({
                Data7: Data
            });



        })
            .catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/GetRenewablEnergyConsumed').then(response => {
            let typesapi = response.data;

            this.setState({
                rows: typesapi

            });
            console.log(response.data);

            const Data = [['Label ', 'Value']]
            Data.push(['Fire ', 12]);

            /* for (const dataObj of response.data) {
                 Data.push([dataObj.usedFor, dataObj.energyConsumed]);
 
                 // calorificFuelValue.push(dataObj.calorificFuelValue);
                 //fuelType.push(dataObj.fuelType);
                  let datenew = dataObj.submissionDate;
                  
                  datenew = datenew.split('T', 3).join(' ');
                  datenew = datenew.split(':', 3).join(' ');
                  datenew = datenew.split('00', 3).join(' ');
                
 
                  date.push(datenew)
                  location.push(dataObj.locationName)
                  console.log(fuelType)
             }*/

            this.setState({
                Data8: Data
            });



        })
            .catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/FireExtinguisher/GetFireExtinguisherAll')
            .then(response => {



                console.log(response.data);

                const Data = [['Month', 'Fire Extinguisher', 'Service Done', 'Service Due']];
                Data.push(['June', 84, 54, 30]);
                Data.push(['July', 63, 33, 30]);
                Data.push(['August', 74, 49, 25]);

                /* for (const dataObj of response.data) {
                     Data.push([dataObj.month, dataObj.groundWater, dataObj.riverOrSurfaceWater, dataObj.municipalOrOther, dataObj.withdrawalHarvest]);
 
                     // calorificFuelValue.push(dataObj.calorificFuelValue);
                     //fuelType.push(dataObj.fuelType);
                      let datenew = dataObj.submissionDate;
                      
                      datenew = datenew.split('T', 3).join(' ');
                      datenew = datenew.split(':', 3).join(' ');
                      datenew = datenew.split('00', 3).join(' ');
                    
     
                      date.push(datenew)
                      location.push(dataObj.locationName)
                      console.log(fuelType)
                 }*/

                this.setState({
                    Data9: Data
                });



            })
            .catch(err => {
                console.log(err);
            });


    }
    isDisabled() {

        var utypeid = localStorage.getItem("utypeid")
        if (utypeid == 1 || utypeid == 2 || utypeid == 3) {

            return true
        }


        else {

            return false
        }

    }
    handledata(startDate, endDate) {

        let checked = this.state.checked
        var level = this.state.level
        var location = this.state.location
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardehs/GetDashboardAuditNumber', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {

                this.setState({
                    audit: response.data[0].Total
                })
            })
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardehs/GetDashboardFireInvestigation', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {


                this.setState({
                    fire: response.data[0].Total
                })
            })
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardehs/GetDashboardMockDrill', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {

                this.setState({
                    mockdrill: response.data[0].Total
                })
            })
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardehs/GetDashboardInjuredEmployee', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {

                this.setState({
                    incident: response.data[0].Total

                })
            })
        if (checked == false) {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardehs/GetDashboardAccidentStatistics', { params: { startDate: startDate, endDate: endDate } })
                .then(response => {


                    let finalrows = response.data.filter(level == 0 && location == 0 ? x => x.statisticsId == 0 : location == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == location);
                    if (finalrows.length == 0) {
                        this.setState({
                            employees: 0,
                            manworked: 0,
                            training: 0,
                            serious: 0,
                            nearmiss: 0,
                            firstaid: 0,
                            minor: 0,
                            losttime: 0,
                            fatal: 0,
                            manlost: 0
                        })
                    }
                    else {
                        this.setState({
                            employees: finalrows[0].sum,
                            manworked: finalrows[1].sum,
                            training: finalrows[2].sum,
                            serious: finalrows[3].sum,
                            nearmiss: finalrows[4].sum,
                            firstaid: finalrows[5].sum,
                            minor: finalrows[6].sum,
                            losttime: finalrows[7].sum,
                            fatal: finalrows[8].sum,
                            manlost: finalrows[9].sum
                        })
                    }
                })
                .catch(error => {
                    console.log(error);

                });
        }

        else {
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardehs/GetDashboardAccidentContractors', { params: { startDate: startDate, endDate: endDate } })
                .then(response => {
                    let finalrows = []
                    finalrows = response.data.filter(level == 0 && location == 0 ? x => x.statisticsId == 0 : location == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == location);
                    if (finalrows.length == 0) {
                        this.setState({
                            employees: 0,
                            manworked: 0,
                            training: 0,
                            serious: 0,
                            nearmiss: 0,
                            firstaid: 0,
                            minor: 0,
                            losttime: 0,
                            fatal: 0,
                            manlost: 0
                        })
                    }
                    else {
                        this.setState({
                            employees: finalrows[0].sum,
                            manworked: finalrows[1].sum,
                            training: finalrows[2].sum,
                            serious: finalrows[3].sum,
                            nearmiss: finalrows[4].sum,
                            firstaid: finalrows[5].sum,
                            minor: finalrows[6].sum,
                            losttime: finalrows[7].sum,
                            fatal: finalrows[8].sum,
                            manlost: finalrows[9].sum
                        })
                    }
                })
                .catch(error => {
                    console.log(error);

                });
        }

    }

    handleChange(checked) {



        // this.setState({ checked });
        var check = this.state.checked;
        check = checked
        this.state.checked = checked

        var time = this.state.time;
        if (checked == true) {
            document.getElementById('card-body5').style.display = "none";
            document.getElementById('card-body4').style.marginLeft = "140px";
            document.getElementById('new').innerHTML = "Total <br />Contractors";
        }
        else {
            document.getElementById('card-body5').style.display = "flex";
            document.getElementById('card-body4').style.marginLeft = "0px";
            document.getElementById('new').innerHTML = "Total <br />Employees";
        }

        if (time == "Last Three Months") {
            let months = this.getLast3Months();
            var today = new Date();
            let longMonth = today.toLocaleString('en-us', { month: 'long' });
            var currentyear = 0;
            if (longMonth == "January") {
                currentyear = today.getFullYear() - 1;
            }
            else {
                currentyear = today.getFullYear();
            }
            var sDate = new Date('01' + '-' + months[2] + '-' + currentyear);
            var eDate = new Date('31' + '-' + months[0] + '-' + currentyear);
            var startDate = sDate.toISOString().substring(0, 10);
            var endDate = eDate.toISOString().substring(0, 10);

            this.handledata(startDate, endDate)
        }
        else if (time == "This Year") {
            var today = new Date();
            const currentyear = today.getFullYear();
            var sDate = new Date('01' + '-' + 'January' + '-' + currentyear);
            var eDate = new Date('31' + '-' + 'December' + '-' + currentyear);
            var startDate = sDate.toISOString().substring(0, 10);
            var endDate = eDate.toISOString().substring(0, 10);

            this.handledata(startDate, endDate)

        }

        else {

            var today = new Date();
            const previousyear = today.getFullYear() - 1;
            var sDate = new Date('01' + '-' + 'January' + '-' + previousyear);
            var eDate = new Date('31' + '-' + 'December' + '-' + previousyear);
            var startDate = sDate.toISOString().substring(0, 10);
            var endDate = eDate.toISOString().substring(0, 10);

            this.handledata(startDate, endDate)
        }
    };



    handleChange1 = (e) => {
        e.preventDefault();

        const value = parseInt(e.target.value);
        this.setState({
            level: value
        })
        var time = this.state.time;
        if (value == 0) {
            this.setState({
                location: 0
            })
            document.getElementById('location').style.display = 'none';
            if (time == "Last Three Months") {
                let months = this.getLast3Months();
                var today = new Date();
                let longMonth = today.toLocaleString('en-us', { month: 'long' });
                var currentyear = 0;
                if (longMonth == "January") {
                    currentyear = today.getFullYear() - 1;
                }
                else {
                    currentyear = today.getFullYear();
                }
                var sDate = new Date('01' + '-' + months[2] + '-' + currentyear);
                var eDate = new Date('31' + '-' + months[0] + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)

            }
            else if (time == "This Year") {

                var today = new Date();
                const currentyear = today.getFullYear();
                var sDate = new Date('01' + '-' + 'January' + '-' + currentyear);
                var eDate = new Date('31' + '-' + 'December' + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)



            }

            else {


                var today = new Date();
                const previousyear = today.getFullYear() - 1;
                var sDate = new Date('01' + '-' + 'January' + '-' + previousyear);
                var eDate = new Date('31' + '-' + 'December' + '-' + previousyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }

        }
        else if (value == 1 || value == 3) {

            document.getElementById('location').style.display = 'inline';
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/GetUserRoleLocation', { params: { UserRoleId: value } })
                .then(response => {

                    this.setState({
                        locations: response.data
                    })
                    console.log(response.data)

                })

                .catch(error => {
                    console.log(error);

                });
            this.setState({
                level: value
            })
            if (time == "Last Three Months") {
                let months = this.getLast3Months();
                var today = new Date();
                let longMonth = today.toLocaleString('en-us', { month: 'long' });
                var currentyear = 0;
                if (longMonth == "January") {
                    currentyear = today.getFullYear() - 1;
                }
                else {
                    currentyear = today.getFullYear();
                }
                var sDate = new Date('01' + '-' + months[2] + '-' + currentyear);
                var eDate = new Date('31' + '-' + months[0] + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }
            else if (time == "This Year") {
                var today = new Date();
                const currentyear = today.getFullYear();
                var sDate = new Date('01' + '-' + 'January' + '-' + currentyear);
                var eDate = new Date('31' + '-' + 'December' + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)

            }

            else {

                var today = new Date();
                const previousyear = today.getFullYear() - 1;
                var sDate = new Date('01' + '-' + 'January' + '-' + previousyear);
                var eDate = new Date('31' + '-' + 'December' + '-' + previousyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }
        }

        else {
            alert("No Such Condition")
        }
    }

    handleChange2 = (e) => {

        var value = parseInt(e.target.value)
        var time = this.state.time

        var location = this.state.location;
        location = value;
        this.state.location = value;




        if (time == "Last Three Months") {
            let months = this.getLast3Months();
            var today = new Date();
            let longMonth = today.toLocaleString('en-us', { month: 'long' });
            var currentyear = 0;
            if (longMonth == "January") {
                currentyear = today.getFullYear() - 1;
            }
            else {
                currentyear = today.getFullYear();
            }
            var sDate = new Date('01' + '-' + months[2] + '-' + currentyear);
            var eDate = new Date('31' + '-' + months[0] + '-' + currentyear);
            var startDate = sDate.toISOString().substring(0, 10);
            var endDate = eDate.toISOString().substring(0, 10);

            this.handledata(startDate, endDate)
        }
        else if (time == "This Year") {
            var today = new Date();
            const currentyear = today.getFullYear();
            var sDate = new Date('01' + '-' + 'January' + '-' + currentyear);
            var eDate = new Date('31' + '-' + 'December' + '-' + currentyear);
            var startDate = sDate.toISOString().substring(0, 10);
            var endDate = eDate.toISOString().substring(0, 10);

            this.handledata(startDate, endDate)

        }

        else {

            var today = new Date();
            const previousyear = today.getFullYear() - 1;
            var sDate = new Date('01' + '-' + 'January' + '-' + previousyear);
            var eDate = new Date('31' + '-' + 'December' + '-' + previousyear);
            var startDate = sDate.toISOString().substring(0, 10);
            var endDate = eDate.toISOString().substring(0, 10);

            this.handledata(startDate, endDate)
        }
    }

    handleChange3 = (e) => {
        e.preventDefault();

        const value = e.target.value;
        var level = this.state.level;
        if (value == "Last Three Months") {
            if (level == 0) {
                let months = this.getLast3Months();
                var today = new Date();
                let longMonth = today.toLocaleString('en-us', { month: 'long' });
                var currentyear = 0;
                if (longMonth == "January") {
                    currentyear = today.getFullYear() - 1;
                }
                else {
                    currentyear = today.getFullYear();
                }
                var sDate = new Date('01' + '-' + months[2] + '-' + currentyear);
                var eDate = new Date('31' + '-' + months[0] + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }
            else {

                let months = this.getLast3Months();
                var today = new Date();
                let longMonth = today.toLocaleString('en-us', { month: 'long' });
                var currentyear = 0;
                if (longMonth == "January") {
                    currentyear = today.getFullYear() - 1;
                }
                else {
                    currentyear = today.getFullYear();
                }
                var sDate = new Date('01' + '-' + months[2] + '-' + currentyear);
                var eDate = new Date('31' + '-' + months[0] + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }

        }

        else if (value == "This Year") {


            this.setState({
                time: value
            })

            if (level == 0) {
                var today = new Date();
                const currentyear = today.getFullYear();
                var sDate = new Date('01' + '-' + 'January' + '-' + currentyear);
                var eDate = new Date('31' + '-' + 'December' + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }
            else {

                var today = new Date();
                const currentyear = today.getFullYear();
                var sDate = new Date('01' + '-' + 'January' + '-' + currentyear);
                var eDate = new Date('31' + '-' + 'December' + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }

        }
        else {
            this.setState({
                time: value
            })
            if (level == 0) {
                var today = new Date();
                const previousyear = today.getFullYear() - 1;
                var sDate = new Date('01' + '-' + 'January' + '-' + previousyear);
                var eDate = new Date('31' + '-' + 'December' + '-' + previousyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }
            else {
                var today = new Date();
                const previousyear = today.getFullYear() - 1;
                var sDate = new Date('01' + '-' + 'January' + '-' + previousyear);
                var eDate = new Date('31' + '-' + 'December' + '-' + previousyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);

                this.handledata(startDate, endDate)
            }

        }
    }
    getLast3Months() {

        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        var today = new Date();
        var last3Months = []
        var month = today.getMonth();
        var i = 0;
        do {

            if (month == 0) {
                month = 11;

            } else {
                month--;
            }
            last3Months.push(monthNames[month]);
            i++;
        }

        while (i < 3)

        return last3Months;
    }
    handleSubmit = event => {


    }

    render() {

        return (
            <div >



                <h4 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>EHS Dashboard </h4>
                <br />

                <div >
                    <div id="level" style={{ width: '220px', float: 'left' }}>

                        <select name="Level" required class="form-control" onChange={this.handleChange1}>

                            <option selected value={0} >Company Level</option>
                            <option value={1} >Plant Level </option>
                            <option value={3} >Office Level</option>

                        </select></div>
                    <div id="location" style={{ width: '220px', float: 'left', marginLeft: '10px' }}>

                        <select name="LocationId" required class="form-control" onChange={this.handleChange2} value={this.state.location} disabled={this.isDisabled()}>
                            <option selected disabled value='0'>--Select Location--</option>
                            {this.state.locations.map(type => (
                                <option
                                    key={type.locationId}
                                    value={type.locationId}
                                >
                                    {type.locationName}
                                </option>
                            ))}
                        </select></div>


                    <div id="time" style={{ width: '220px', float: 'left', marginLeft: '10px' }}>

                        <select name="Time" required class="form-control" onChange={this.handleChange3}>

                            <option selected value={'Last Three Months'} >Last Three Months</option>
                            <option value={'This Year'} > This Year </option>
                            <option value={'Last Year'} >Last Year</option>

                        </select></div>
                    <div style={{ float: 'right' }}>
                        <span >   <label  >Employees</label> <div class='brtop'><Switch onChange={this.handleChange} checked={this.state.checked} /></div>  <label class='brleft'>  Contractor</label> </span>
                    </div>
                </div>
                <br />
                <br />
                <div>

                    {/*  <div class="card-body3">
                        <h6 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Site Information </h6>
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                             

                                   
                                <div>
                                    <div class="card-body6">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px',paddingTop: '5px', paddingBottom: '5px' }}>Total<br />Employees  </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946', fontSize: '14px' }}>NA  </label>



                                            </div>

                                        </div>
                                    </div>
                                    <div class="card-body8">
                                        <h6 style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px'}}>Number of <br />Accidents</h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946', fontSize: '14px' }}>52 </label>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div style={{marginBottom:'5px' }}>
                                        <div class="card-body6">
                                        <h6 style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Man Hours<br />(Contractors)   </h6>
                                            <div class="bg-white shadow p-3 mb-4 card border-light">

                                                <div class="row">


                                                    <label class="no-space-break" style={{ textAlign: 'center', color: '#001946', fontSize: '14px' }}>2338419 </label>



                                                </div>

                                            </div>
                                        </div>
                                        <div class="card-body8">
                                        <h6 style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Man  Hours <br />(Employees) </h6>
                                            <div class="bg-white shadow p-3 mb-4 card border-light">

                                                <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946', fontSize: '14px' }}>2701185 </label>



                                                </div>
                                            </div>
                                        </div>



                                  


                                    </div>
                                 
                              


                               
                            </div>

                        </div>
                    </div> */}
                    <div class="card-body4" id="card-body4">
                        {/* <h6 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>{ this.state.locationName} </h6> */}

                        <div class="bg-white shadow p-3 mb-4 card border-light" >

                            <div class="row" >
                                <div>
                                    <div class="card-body9">
                                        <h6 id="new" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Total<br />Employees </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946', fontSize: '14px' }}>{this.state.employees}  </label>



                                            </div>

                                        </div>
                                    </div>
                                    <div class="card-body10">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Man<br />Hours </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.manworked} </label>



                                            </div>
                                        </div>
                                    </div>


                                    <div class="card-body10">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Training<br />Hours </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.training}  </label>



                                            </div>

                                        </div>
                                    </div>

                                    <div class="card-body10">
                                        <h6 style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Number of<br />Accidents  </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.serious}  </label>



                                            </div>

                                        </div>
                                    </div>


                                    <div class="card-body11">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Near Miss<br />Count  </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946', fontSize: '14px' }}>{this.state.nearmiss} </label>



                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <div>

                                    <div class="card-body9">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>First Aid<br />Injury</h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946', fontSize: '14px' }}>{this.state.firstaid} </label>



                                            </div>

                                        </div>
                                    </div>
                                    <div class="card-body10">
                                        <h6 style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Minor<br />Injury</h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.minor} </label>



                                            </div>
                                        </div>
                                    </div>


                                    <div class="card-body10">
                                        <h6 style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Lost<br />Time Injury </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.losttime} </label>



                                            </div>

                                        </div>
                                    </div>

                                    <div class="card-body10">
                                        <h6 style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Fatal<br />Injury</h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.fatal}  </label>



                                            </div>

                                        </div>
                                    </div>



                                    <div class="card-body11">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Man Hours<br />Lost </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.manlost} </label>



                                            </div>
                                        </div>
                                    </div>

                                </div>






                            </div>

                        </div>
                    </div>


                    <div class="card-body5" id="card-body5">
                        {/* <h6 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Other Indices </h6> */}
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <div>
                                    <div class="card-body6">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Number of <br />Audit  </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946', fontSize: '14px' }}>{this.state.audit}  </label>



                                            </div>

                                        </div>
                                    </div>

                                    <div class="card-body8">
                                        <h6 style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Number of Fire<br /> Investigation   </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.fire}  </label>





                                            </div>
                                        </div>



                                    </div>



                                </div>
                                <div>
                                    <div class="card-body6">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>Number of<br />Mock Drill </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.mockdrill}  </label>



                                            </div>

                                        </div>
                                    </div>
                                    <div class="card-body8">
                                        <h6 class="no-space-break" style={{ backgroundColor: '#002D7E', textAlign: 'center', color: 'white', fontSize: '14px', paddingTop: '5px', paddingBottom: '5px' }}>No. of Incident<br />Investigation </h6>
                                        <div class="bg-white shadow p-3 mb-4 card border-light">

                                            <div class="row">


                                                <label class="no-space-break" style={{ textAlign: 'center', color: '#001946' }}>{this.state.incident} </label>





                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <br />



                <h4 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', marginTop: '50px', marginBottom: '20px', paddingTop: '5px', paddingBottom: '5px', marginTop: '400px' }}>EHS Performance </h4>
                {/* <div id="location">

                    <select name="LocationId" required class="form-control" onChange={this.handleChange}>
                        <option selected disabled value='0'>--Select Location--</option>
                        {this.state.locations.map(type => (
                            <option
                                key={type.locationId}
                                value={type.locationId}
                            >
                                {type.locationName}
                            </option>
                        ))}
                    </select></div> */}
                <br />

                <div>
                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">


                                <h7 class="ribbon1" style={{
                                    fontSize: '20px', marginLeft: '40px', color: '#003DAC', paddingLeft: '5px', paddingRight: '5px', transition: '0.5'
                                }}>Corporate EHS Audit Status </h7>
                                <Chart
                                    width={'350px'}
                                    height={'250px'}
                                    chartType="PieChart"
                                    loader={<div>Loading Corporate EHS Audit Status</div>}
                                    data={this.state.Data2}


                                    options={{
                                        title: "Audit Checklist Status",
                                        titlePosition: 'none',
                                        legend: { position: 'bottom' },
                                        colors: ['#FB755F', '#A9D18E'],
                                        pieHole: 0.4,


                                    }}
                                    rootProps={{ 'data-testid': '5' }}
                                />

                            </div>


                        </div>
                    </div>
                    <div class="card-body2">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">

                                <h7 class="ribbon1" style={{
                                    fontSize: '20px', marginLeft: '20px', color: '#003DAC', paddingLeft: '5px', paddingRight: '5px', transition: '0.5'
                                }}>Monthly Safety Checklist Status </h7>
                                <div class="row2">
                                    <Chart
                                        width={'330px'}
                                        height={'250px'}
                                        chartType="ComboChart"
                                        loader={<div>Loading -Monthly Safety Checklist Status</div>}
                                        data={this.state.Data1}
                                        options={{

                                            title: 'Audit Checklist',
                                            titlePosition: 'none',
                                            legend: { position: 'bottom' },
                                            vAxis: { title: 'Quantity' },
                                            colors: ['#A9D18E', '#FB755F', '#FFC000'],
                                            seriesType: 'bars',
                                            series: { 5: { type: 'line' } },

                                        }}
                                        // For tests
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body12">
                        <div class="bg-white shadow p-3 mb-4 card border-light">
                            <div class="row">
                                <h7 class="ribbon1" style={{
                                    fontSize: '20px', marginLeft: '80px', color: '#003DAC', paddingLeft: '5px', paddingRight: '5px', transition: '0.5'
                                }}>Safety Walk Status</h7>
                                <Chart
                                    width={'350px'}
                                    height={'250px'}
                                    chartType="PieChart"
                                    loader={<div>Loading Safety Walk Status</div>}
                                    data={this.state.Data}
                                    options={{
                                        title: 'Safety Walk List',
                                        titlePosition: 'none',
                                        colors: ['#A9D18E', '#FB755F', '#FFC000'],
                                        legend: { position: 'bottom' },
                                        is3D: true,
                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '2' }}
                                />

                            </div>

                        </div>
                    </div>

                </div>

                <div>




                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">


                            <div class="row">
                                <h7 class="ribbon1" style={{
                                    fontSize: '20px', marginLeft: '5px', color: '#003DAC', paddingLeft: '5px', paddingRight: '5px', transition: '0.5'
                                }}>Accident Report-Injured Person Type</h7>
                                <Chart
                                    width={'350px'}
                                    height={'250px'}
                                    chartType="PieChart"
                                    loader={<div>Loading Injured Person Type</div>}
                                    data={this.state.Data4}
                                    options={{
                                        title: 'Accident Report-Injured Person Type',
                                        titlePosition: 'none',

                                        legend: { position: 'bottom' },
                                        colors: ['#FB755F', '#A9D18E'],
                                        pieHole: 0.4,

                                        vAxis: {
                                            title: 'Number',
                                            minValue: 0,
                                        },
                                        hAxis: {
                                            title: 'Person Type',
                                        },
                                        seriesType: 'bars',

                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '2' }}
                                />

                            </div>


                        </div>
                    </div>
                </div>
                <div>
                    <div class="card-body2" >
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">

                                <h7 class="ribbon1" style={{
                                    fontSize: '20px', marginLeft: '90px', color: '#003DAC', paddingLeft: '5px', paddingRight: '5px', transition: '0.5'
                                }}>Type Of Incident</h7>


                                <Chart
                                    width={'350px'}
                                    height={'250px'}
                                    chartType="ComboChart"
                                    loader={<div>Loading Type Of Incident</div>}
                                    data={this.state.Data5}
                                    options={{
                                        title: "Incident Investigation-Incident Type",
                                        titlePosition: 'none',
                                        isStacked: 'true',
                                        seriesType: 'bars',
                                        legend: { position: 'none' },
                                        colors: ['#006666'],

                                        chartArea: { height: '60%', width: '80%' },
                                        vAxis: {
                                            minValue: 0,
                                            title: 'Number',
                                        },

                                        hAxis: {

                                            title: 'Incident Type',
                                        },

                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                />













                            </div>

                        </div>
                    </div>

                    <div class="card-body12">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 class="ribbon1" style={{
                                    fontSize: '20px', marginLeft: '100px', color: '#003DAC', paddingLeft: '5px', paddingRight: '5px', transition: '0.5'
                                }}>Type Of Injury</h7>

                                <Chart
                                    width={'360px'}
                                    height={'250px'}
                                    chartType="ComboChart"
                                    loader={<div>Loading Type Of Injury</div>}
                                    data={this.state.Data3}
                                    options={{
                                        title: "Accident Report-Type",
                                        titlePosition: 'none',
                                        seriesType: 'bars',
                                        legend: { position: 'none' },
                                        colors: ['#006666'],

                                        chartArea: { width: '85%' },
                                        vAxis: { title: 'Number' },
                                        hAxis: { title: 'Accident Type' },

                                    }}
                                    rootProps={{ 'data-testid': '2' }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div>

                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">

                                <h7 class="ribbon1" style={{
                                    fontSize: '20px', marginLeft: '80px', color: '#003DAC', paddingLeft: '5px', paddingRight: '5px', transition: '0.5'
                                }}>Fire Extinguisher </h7>

                                <Chart
                                    width={'360px'}
                                    height={'250px'}
                                    chartType="ComboChart"
                                    loader={<div>Loading Fire Extinguisher</div>}
                                    data={this.state.Data9}
                                    options={{

                                        title: 'Fire Extinguisher',
                                        titlePosition: 'none',
                                        legend: { position: 'bottom' },
                                        vAxis: { title: 'Quantity' },
                                        colors: ['#A9D18E', '#FB755F', '#FFC000'],
                                        seriesType: 'bars',
                                        series: { 5: { type: 'line' } },

                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '2' }}
                                />

                            </div>
                        </div>
                    </div>
                    <div class="card-body2">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                    </div>
                </div>


                {/*   <div>
                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '160px', color: '#000000' }}>Employee Type(Incident)</h7>
                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="SteppedAreaChart"
                                    loader={<div>Loading Employee Type(Incident)</div>}
                                        data={this.state.Data6}
                                        options={{
                                            title: 'Incident Employee Type',
                                            titlePosition:'none',
                                            legend: { position: 'none' },
                                           
                                            vAxis: {
                                                title: 'Number',
                                                minValue: 0,
                                            },
                                            hAxis: {

                                                title: 'Employee Type',
                                            },
                                           
                                        }}
                                        // For tests
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            
                        </div>
                    </div>



                   <div class="card-body2">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row1">
                                <div class="mb-3 col-md-6">
                                    <h7 class="no-space-break" style={{ color: 'black', fontWeight: 'bold', fontSize: '12px' }}> Incident Investigation-Total Number </h7>

                                    <Chart
                                        width={'400px'}
                                        height={'280px'}
                                        chartType="Gauge"
                                        loader={<div>Loading Incident Investigation</div>}
                                        data={this.state.Data8}
                                        options={{


                                            title: 'Incident Investigation-Total Number',
                                            redFrom: 90,
                                            redTo: 100,
                                            yellowFrom: 75,
                                            yellowTo: 90,
                                            minorTicks: 5


                                        }}
                                        // For tests
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div> 


                </div>


                   

                    <div>



                        <div class="card-body1">
                            <div class="bg-white shadow p-3 mb-4 card border-light">

                                <div class="row">
                                    <div class="mb-3 col-md-6">
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="PieChart"
                                            loader={<div>Loading Incident Investigation</div>}
                                            data={this.state.Data7}
                                            options={{
                                                title: "Incident Investigation-Injury Nature",
                                              
                                                pieHole: 0.4,

                                            }}
                                            rootProps={{ 'data-testid': '5' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>  
                   


                        </div>   */}



            </div>








        )
    }
}

export default Dashboardehs