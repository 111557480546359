import { quarters } from "../Data/Quarter";

export default function computeWasteGeneratedArray(temp) {

    let quarter1 = 0;
    let quarter2 = 0;
    let quarter3 = 0;
    let quarter4 = 0;

    temp.map(instance => {

        if (quarters.q1.includes(instance.month))
        {
            quarter1 += Math.round(instance.quantityGenerated);
        }
        else if(quarters.q2.includes(instance.month))
        {
            quarter2 += Math.round(instance.quantityGenerated);
        }
        else if(quarters.q3.includes(instance.month))
        {
            quarter3 += Math.round(instance.quantityGenerated);
        }
        else
        {
            quarter4 += Math.round(instance.quantityGenerated);
        }

    })

    let actualGeneratedWaste = [];
    actualGeneratedWaste.push(quarter1);
    actualGeneratedWaste.push(quarter2);
    actualGeneratedWaste.push(quarter3);
    actualGeneratedWaste.push(quarter4);

    // console.log(actualDirectEmission);
    return actualGeneratedWaste;

}