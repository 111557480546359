import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LineChart from "../components/LineChart/LineChart";
import SimpleCard from "../components/Card";
import computeRenewableEnergyArray from '../utils/RenewableEnergyConsumption';
import React from 'react'
const useStyles = makeStyles({
    root : {
        boxShadow: " 10px 10px 10px #888888!important",
        padding: "1%",
    }
})


export default function EnergyConsumption(props) {
    const classes = useStyles();

    // const year1 = props.year1;
    // const year2 = props.year2;

    let renewableOne = props.renewable1;
    let renewableTwo = props.renewable2;
    let nonRenewableOne = props.nonRenewable1;
    let nonRenewableTwo = props.nonRenewable2;

    let actualRenewableEnergyOne = computeRenewableEnergyArray(renewableOne);
    let actualRenewableEnergyTwo = computeRenewableEnergyArray(renewableTwo);
    //both renewable and non-renewable have the same key to be plot
    let actualNonRenewableEnergyOne = computeRenewableEnergyArray(nonRenewableOne);
    let actualNonRenewableEnergyTwo = computeRenewableEnergyArray(nonRenewableTwo);


    return (
        <div className={classes.root}>
            <SimpleCard title="Energy Consumption" unit={"All Values in MwH"}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                        <LineChart data1={actualRenewableEnergyOne} data2={actualRenewableEnergyTwo} title="Renewable Energy" year1={props.year1} year2={props.year2} unit="MwH"/>
                </Grid>
                <Grid item xs={12} lg={6}>
                        <LineChart data1={actualNonRenewableEnergyOne} data2={actualNonRenewableEnergyTwo} title="Non Renewable Energy" year1={props.year1} year2={props.year2} unit="MwH"/>
                </Grid>
            </Grid>
        </SimpleCard>

        </div>
    )


}