import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { Modal, Button } from "react-bootstrap";
import Moment from 'moment';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";
import { Prev } from 'react-bootstrap/esm/PageItem';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
class Minutesofmeetingview extends Component {

    constructor(props) {
        super(props)

        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,

            types: [],
            unit: '',
            validationError: "",
            count: 0,
          
            rows: [{ id: 0 }],
            rows1: [{ id: 0 }],
            rows2: [{ id: 0 }],
            rows3: [{ id: 0 }],
            rows4: [{ id: 0 }],
            login: [],
            status1: 'Approved',
            status2: 'Rejected',
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            month: prevMonth,
            year: longYear,
            admincode: localStorage.getItem('admincode'),
            date: '',
            time: '',
            venue: '',
            agenda: '',
            members:''

        }
    }

    componentDidMount() {
        
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null ) {
            window.location.href = '/'
            return;
        }
        var prevYear = this.state.prevYear;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        if (prevMonth == "December") {
            this.setState({
                year: prevYear
            })
        }
        else {
            this.setState({
                year: longYear
            })
        }

        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        
        if (utypeid == 1) {


            document.getElementById('monthfilter').style.display = "none";
           

            var adminhistoryehs = localStorage.getItem('adminhistoryehs')
            if (adminhistoryehs == 'true') {
                document.getElementById('status').style.display = "none";
            }

            else {
                document.getElementById('status').style.display = "inline";
            }
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            this.setState({
                id: id
            });

            axios.all([
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesMeeting/GetMinutesDataFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesStatusOld/GetMinutesStatusFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingOldPoints/GetMeetingOldFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingNewPoints/GetMeetingNewFiltered', { params: { SubmissionId: id } }),


            ]).then(axios.spread((response1, response2, response3, response4) => {
                this.setState({
                    rows: response1.data,
                    rows1: response2.data,
                    rows2: response3.data,
                    rows3: response4.data,
                    date: response1.data[0].date,
                    time: response1.data[0].time,
                    venue: response1.data[0].venue,
                    agenda: response1.data[0].agenda,
                    members: response1.data[0].membersPresent
                })

            }))

        }
        else {
            document.getElementById('monthfilter').style.display = "flex";
            document.getElementById('status').style.display = "none";
            var id = localStorage.getItem('utype')
            axios.all([
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesMeeting/GetMinutesMeetingByUId', { params: { UserId: id } }),

                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesStatusOld/GetMinutesStatusByUId', { params: { UserId: id } }),

                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingOldPoints/GetMeetingOldPointsByUId', { params: { UserId: id } }),

                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingNewPoints/GetMeetingNewPointsByUId', { params: { UserId: id } })

            ]).then(axios.spread((response1, response2, response3, response4) => {
                
                var prevMonth = this.state.prevMonth;

                // const rows = {...this.state.rows}
                var rows = response1.data.filter(x => x.month == prevMonth);
                var rows1 = response2.data.filter(x => x.month == prevMonth);
                var rows2 = response3.data.filter(x => x.month == prevMonth);
                var rows3 = response4.data.filter(x => x.month == prevMonth);
               
                this.setState({
                    rows: rows,
                    rows1: rows1,
                    rows2: rows2,
                    rows3: rows3,
                    date: rows[0].date,
                    time: rows[0].time,
                    venue: rows[0].venue,
                    agenda: rows[0].agenda,
                    members: rows[0].membersPresent
                });

            }));
        }
        
    }
    formatDate(string) {
        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('YYYY-MM-DD')


    }
    openModal = (e) => {
        
        this.setState({ status: e.target.value });
        this.setState({ isOpen: true });


    }
    closeModal = () => this.setState({ isOpen: false });

    handleChange = event => {
        
        event.preventDefault();
        const name = event.target.name;

        const value = event.target.value;


        this.setState({
            [name]: value
        });
    };


    handleChange1 = e => {
        
        const rows4 = [...this.state.rows4];
        let row = {
            [e.currentTarget.name]: e.currentTarget.value,

            "Status": this.state.status,
            "RegulatorId": 1,
            "SubmissionId": parseInt(this.state.id)
        }
        rows4[0] = row
        this.setState({
            rows4
        });
    };





    
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows2 = [...this.state.rows2]
        rows2.splice(idx, 1)
        this.setState({ rows2 })
    }

    handleRemoveSpecificRow1 = (idx) => () => {
        
        const rows3 = [...this.state.rows3]
        rows3.splice(idx, 1)
        this.setState({ rows3 })
    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = event => {
        event.preventDefault();
        
        var month = this.state.month;
        var year = this.state.year;

        axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesMeeting/GetMinutesMeetingByMonth', { params: { Month: month, Year: year } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MinutesStatusOld/GetMinutesStatusByMonth', { params: { Month: month, Year: year } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingOldPoints/GetMeetingOldPointsByMonth', { params: { Month: month, Year: year } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/MeetingNewPoints/GetMeetingNewPointsByMonth', { params: { Month: month, Year: year } })

        ]).then(axios.spread((response1, response2, response3, response4) => {
            console.log(response1.data[0].members)
            console.log(response2.data)
            console.log(response3.data)
            console.log(response4.data)
            this.setState({
                rows: response1.data,
                rows1: response2.data,
                rows2: response3.data,
                rows3: response4.data,
                date: response1.data[0].date,
                time: response1.data[0].time,
                venue: response1.data[0].venue,
                agenda:response1.data[0].agenda,
                members:response1.data[0].membersPresent
            });
        }));
    }
    handleSubmit1 = e => {
        
        const { rows4 } = this.state;

        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows4)
        };
        let newbody = JSON.parse(Options.body);

        axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSStatus', newbody)
            .then(response => {
                this.setState({ isOpen: false })
                alert("Status Updated Successfully");
                this.props.history.push('/ehsadminview')
            });
    }
    handleSubmit3 = event => {
        event.preventDefault();
        this.setState({
            month: this.state.prevMonth,
            year: this.state.longYear
        })
        this.componentDidMount();
    }

    toggle = (index) => {
        let collapse = "isOpen" + index;

        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }

    handleClick1 = () => (event) => {

        this.props.history.push("/minutesofmeetingview");
    };

    edit(idx) {


        this.props.history.push("/editoldstatus/?id=" + idx);
    }

    edit1(idx) {


        this.props.history.push("/editoldpoints/?id=" + idx);
    }

    edit2(idx) {


        this.props.history.push("/editnewpoints/?id=" + idx);
    }

    




    render() {

        return (


            <div >
                <h5 style={{ backgroundColor: '#001946', width: '1100px', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Minutes Of Meeting </h5>

                                <br />

                <div id="monthfilter" style={{ fontSize: '16px' }}>

                    <FormControl>

                        <select

                            value={this.state.month}
                            name="month"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Month--</option>
                            <option value={this.state.month1} >January</option>
                            <option value={this.state.month2} >February</option>
                            <option value={this.state.month3} >March</option>
                            <option value={this.state.month4} >April</option>
                            <option value={this.state.month5} >May</option>
                            <option value={this.state.month6} >June</option>
                            <option value={this.state.month7} >July</option>
                            <option value={this.state.month8} >August</option>
                            <option value={this.state.month9} >September</option>
                            <option value={this.state.month10} >October</option>
                            <option value={this.state.month11} >November</option>
                            <option value={this.state.month12} >December</option>

                        </select>

                    </FormControl>

                    &nbsp;


                   <FormControl>
                        <select
                            value={this.state.year}
                            name="year"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Year--</option>
                            <option value={this.state.prevYear} >{this.state.prevYear}</option>
                            <option value={this.state.longYear} >{this.state.longYear}</option>
                        </select>
                    </FormControl>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit3} type="submit" className="btn btn-success btn-sm">CLEAR</button>
                </div>

                <br />
                <div id="status" style={{ float: 'right', fontSize: '16px' }}>
                    <label style={{ fontWeight: 'bold' }}>Status:</label>
                    <FormControl>

                        <select


                            name="Status"
                            onChange={this.openModal}
                        >
                            <option selected disabled value='0'>--Pending--</option>
                            <option value={this.state.status1} >Approve</option>
                            <option value={this.state.status2} >Reject</option>


                        </select>

                    </FormControl>
                </div>
                <Modal style={{ backgroundColor: '#ffffff00', width: '500px', borderColor: '#ffffff00' }}

                    show={this.state.isOpen} onHide={this.closeModal}>
                    <div className="App1">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<label>
                                Date:
                                </label>
                              {this.state.currentDateTime} <br/> */}

                            <label for='Remarks'>Remarks: </label>
                            <TextField type="text" name="Remarks" id="Remarks" onChange={this.handleChange1} style={{ width: '350px' }} />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.handleSubmit1} style={{ marginRight: '8px' }}> Submit </Button>
                            <Button variant="secondary" onClick={this.closeModal}>

                                Close
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
                <br />


              
                    <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                            
                            

                        </div>

                        <div className="col-4">
                            <br />


                            <div >
                                <label class="no-space-break">Date: </label> <br />
                            <TextField InputProps={{ readOnly: true }} selected type="date" name="Date" required id="standard-required" value={this.formatDate(this.state.date)}  />
                            </div>
                            <br />
                            <div>
                                <label class="no-space-break">Time: </label>
                                <br />
                            <TextField
                               
                                    name="Time"

                                    type="time"
                                
                                value={this.state.time}
                                onChange={this.handleChange}
                                InputProps={{ readOnly: true }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </div>

                        </div>

                        <div className="col-2">
                            <br />



                            <div >
                                <label class="no-space-break">Venue: </label> <br />
                            <TextField InputProps={{ readOnly: true }} type="text" name="Venue" required id="standard-required" value={this.state.venue} />
                            </div>

                            <br />

                            <div >
                                <label class="no-space-break">Agenda: </label> <br />
                            <TextField InputProps={{ readOnly: true }} type="text" name="Agenda" required id="standard-required" value={this.state.agenda} />
                            </div>

                    </div>
                    <br />
                
                    
                        <label class="no-space-break">Members Present: </label>
                        <br />
                        <TextField
                            id="outlined-full-width"
                            label="Description"
                            style={{ margin: 8 }}
                       
                        value={this.state.members}
                            name="MembersPresent"
                            fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </Form>
                <br />

                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen2
                    })}
                    onClick={() => this.toggle(2)}
                >
                    <span className="app__toggle-text">STATUS OF OLD POINTS </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen2}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen2 ? "app__collapse--active" : "")
                    }
                >





                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>


                                <th>TOTAL POINTS RAISED</th>
                                <th>POINTS CLOSED</th>
                                <th>STATUS %</th>
                              

                            </tr>

                        </thead>
                        <tbody>
                            {this.state.rows1.map((item, idx) => (
                                <tr id="addr0" key={item.id}>


                                 
                                    <td>
                                        <TextField InputProps={{ readOnly: true }}type="number" required id="standard-required" name="PointsRaised" defaultValue={item.pointsRaised} />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="PointsClosed" defaultValue={item.pointsClosed}/>
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="Status" defaultValue={item.status} />
                                    </td>
                                 
                                        <td width="10%">

                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.statusId)} style={{ marginRight: '8px' }} >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </button>
                                            {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}



                                    </td>
                                </tr>




                            ))}


                        </tbody>
                    </Table>

                    {/*   <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button> */}
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen3
                    })}
                    onClick={() => this.toggle(3)}
                >
                    <span className="app__toggle-text">OLD POINTS </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen3 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen3}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen3 ? "app__collapse--active" : "")
                    }
                >






                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>


                                <th>POINT</th>
                                <th>ACTION</th>
                                <th>RESPONSIBILITY</th>
                                <th>TIMELINE</th>
                                <th>STATUS</th>
                               

                            </tr>

                        </thead>
                        <tbody>
                            {this.state.rows2.map((item, idx) => (
                                <tr id="addr0" key={item.id}>




                                    
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="Point" defaultValue={item.point}  />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="Action" defaultValue={item.action}   />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="Responsibility" defaultValue={item.responsibility}  />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="Timeline" defaultValue={item.timeLine} />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="Status" defaultValue={item.status}  />
                                    </td>
                                    <td width="10%">

                                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit1(item.oldId)} style={{ marginRight: '8px' }} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </button>
                                        {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}



                                    </td>
                                </tr>




                            ))}


                        </tbody>
                    </Table>
                    {/*<button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button> */}
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen4
                    })}
                    onClick={() => this.toggle(4)}
                >
                    <span className="app__toggle-text">NEW POINTS</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen4 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen4}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen4 ? "app__collapse--active" : "")
                    }
                >




                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>


                                <th>POINT</th>
                                <th>ACTION</th>
                                <th>RESPONSIBILITY</th>
                                <th>TIMELINE</th>
                                
                               

                            </tr>

                        </thead>
                    <tbody>
                        {this.state.rows3.map((item, idx) => (
                            <tr id="addr0" key={item.id}>





                                <td>
                                    <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="Point" defaultValue={item.point}  />
                                </td>
                                <td>
                                    <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="Action" defaultValue={item.action} />
                                </td>
                                <td>
                                    <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="Responsibility" defaultValue={item.responsibility} />
                                </td>
                                <td>
                                    <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" name="TimeLine" defaultValue={item.timeLine}  />
                                </td>
                               
                                <td width="10%">

                                    <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit2(item.newId)} style={{ marginRight: '8px' }} >
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>
                                    </button>
                                    {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}



                                </td>
                            </tr>




                        ))}


                        </tbody>
                    </Table>

                </Collapse>


            </div>


        )
    }
}

export default Minutesofmeetingview