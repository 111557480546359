import React, { Component } from "react";

class Unauthorized extends Component {
    render() {
        return (
            <div>
                <h4><font size="6"
                    face="times"
                    color="red">You are not authorized to view this Page </font> </h4>
               
            </div>
        );
    }
}

export default Unauthorized;