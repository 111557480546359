import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Modal, Button } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";

class Accidentstatisticsview extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });

        this.state = {
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            rows: [{ id: 0 }],
            rows1: [{ id: 0 }],
            rows2: [{ id: 0 }],
            rows3: [{ id: 0 }],
            rows4: [],
            rows5: [{ id: 0 }],
            isOpen: false,
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            year1: '2020',
            year2: '2021',
            status1: 'Approved',
            status2: 'Rejected',	
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            month: prevMonth,
            year: longYear,
            status: "pending",
            id: ''
         }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
       
        if (loggedin == null) {
            window.location.href = '/'
            return;
        }
        var prevYear = this.state.prevYear;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        if (prevMonth == "December") {
            this.setState({
                year: prevYear
            })
        }
        else {
            this.setState({
                year: longYear
            })
        }
        localStorage.setItem('homepage', false);
        document.getElementById("navribbon").style.display = "none";
        var utypeid = localStorage.getItem('utypeid')
        if (utypeid == 1) {
            document.getElementById('monthfilter').style.display = "none";
            document.getElementById("resubmit").style.display = "none";
            var adminhistoryehs = localStorage.getItem('adminhistoryehs')
            if (adminhistoryehs == 'true') {
                document.getElementById('status').style.display = "none";

            }

            else {
                document.getElementById('status').style.display = "inline";

            }

            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            this.setState({
                id: id
            });
            axios.all([
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatistics/GetAccidentStatisticsFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Awards/GetAwardsFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTrainingProgram/GetTrainingFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Initiatives/GetInitiativesFiltered', { params: { SubmissionId: id } }),
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatisticsContractors/GetAccidentContractorsFiltered', { params: { SubmissionId: id } }),
            ]).then(axios.spread((response1, response2, response3, response4, response5) => {
                this.setState({
                    rows: response1.data,
                    rows1: response2.data,
                    rows2: response3.data,
                    rows3: response4.data,
                    rows5:response5.data
                })
            }))
        }
        else {
            var Status = localStorage.getItem('Status')
            localStorage.removeItem("Status");
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            if (Status == 'Rejected' || id != null) {
                document.getElementById('monthfilter').style.display = "none";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "inline";
                localStorage.setItem('submissionid', id);
                this.setState({
                    id: id
                });
                axios.all([
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatistics/GetAccidentStatisticsFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Awards/GetAwardsFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTrainingProgram/GetTrainingFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Initiatives/GetInitiativesFiltered', { params: { SubmissionId: id } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatisticsContractors/GetAccidentContractorsFiltered', { params: { SubmissionId: id } }),
                ]).then(axios.spread((response1, response2, response3, response4,response5) => {
                    this.setState({
                        rows: response1.data,
                        rows1: response2.data,
                        rows2: response3.data,
                        rows3: response4.data,
                        rows5:response5.data

                    })
                }))
            }

            else {
                document.getElementById('monthfilter').style.display = "inline";
                document.getElementById('status').style.display = "none";
                document.getElementById("resubmit").style.display = "none";
                var uid = localStorage.getItem('utype')
                axios.all([
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatistics/GetAccidentStatisticsByUId', { params: { UserId: uid } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Awards/GetAwardsByUId', { params: { UserId: uid } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTrainingProgram/GetTrainingProgramByUId', { params: { UserId: uid } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Initiatives/GetInitiativesByUId', { params: { UserId: uid } }),
                    axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatisticsContractors/GetAccidentContractorsByUId', { params: { UserId: uid } }),
                ]).then(axios.spread((response1, response2, response3, response4,response5) => {
                    var prevMonth = this.state.prevMonth;
                    var rows = response1.data.filter(x => x.month == prevMonth);
                    var rows1 = response2.data.filter(x => x.month == prevMonth);
                    var rows2 = response3.data.filter(x => x.month == prevMonth);
                    var rows3 = response4.data.filter(x => x.month == prevMonth);
                    var rows5 = response5.data.filter(x => x.month == prevMonth);
                    this.setState({
                        rows: rows,
                        rows1: rows1,
                        rows2: rows2,
                        rows3: rows3,
                        rows5:rows5
                    });
                }));
            }
        }
    }

    openModal = (e) => {
        this.setState({ status: e.target.value });
        this.setState({ isOpen: true });
    }

    closeModal = () => this.setState({ isOpen: false });
   
    handleRemoveSpecificRow = (idx, id) => (e) => {
        e.preventDefault();
       axios.delete(process.env.REACT_APP_GLOBAL_CODE +'/api/AccidentStatistics/DeleteAccidentStatistics', { params: { StatisticsId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));
    }

    handleRemoveSpecificRow1 = (id) => (e) => {
        
        e.preventDefault();

        axios.delete(process.env.REACT_APP_GLOBAL_CODE +'/api/Awards/DeleteAwards', { params: { AwardId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));

    }

    handleRemoveSpecificRow2 = (idx, id) => (e) => {

        e.preventDefault();

        axios.delete(process.env.REACT_APP_GLOBAL_CODE +'/api/EhsTrainingProgram/DeleteTrainingProgram', { params: { TrainingId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));
    }

    handleRemoveSpecificRow3 = (id) => (e) => {

        e.preventDefault();

        axios.delete(process.env.REACT_APP_GLOBAL_CODE +'/api/Initiatives/DeleteInitiative', { params: { InitiativeId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));
    }

    handleRemoveSpecificRow4 = (id) => (e) => {

        e.preventDefault();

        axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatisticsContractors/DeleteAccidentContractors', { params: { SContractorId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));
    }


    edit(idx) {
        this.props.history.push("/editstatistics/?id=" + idx);
    }

    edit1(idx) {
        this.props.history.push("/editawards/?id=" + idx);
    }

    edit2(idx) {
        this.props.history.push("/edittraining/?id=" + idx);
    }

    edit3(idx) {
        this.props.history.push("/editinitiatives/?id=" + idx);
    }
    edit4(idx) {
        this.props.history.push("/editstatisticscontractor/?id=" + idx);
    }

    handleSubmit9 = (event) => {
        this.props.history.push("/");
    }

    isDisplayed() {
       
        var Status = localStorage.getItem('Status')
        var id = localStorage.getItem('submissionid')
        if (Status == 'Rejected' || id != null) {
            return "inline"
        }
        else {
            return "none";
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        var month = this.state.month;
        var year = this.state.year;
        var uid = localStorage.getItem('utype')
        axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatistics/GetAccidentStatisticsByMonth', { params: { Month: month, Year: year, UserId: uid  } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Awards/GetAwardsByMonth', { params: { Month: month, Year: year, UserId: uid  } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EhsTrainingProgram/GetTrainingProgramByMonth', { params: { Month: month, Year: year, UserId: uid } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Initiatives/GetInitiativesByMonth', { params: { Month: month, Year: year, UserId: uid  } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentStatisticsContractors/GetAccidentContractorsByMonth', { params: { Month: month, Year: year, UserId: uid  } }),
        ]).then(axios.spread((response1, response2, response3, response4,response5) => {
            this.setState({
                rows: response1.data,
                rows1: response2.data,
                rows2: response3.data,
                rows3: response4.data,
                rows5:response5.data
            });
        }));
    }

    handleSubmit1 = e => {
        const { rows4 } = this.state;
        var Status = localStorage.getItem('Status')
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows4)
        };
        let newbody = JSON.parse(Options.body);
        if (Status == 'Submitted') {
            axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateUserSubmittedEhs', newbody[0])
                .then(response => {
                    this.setState({ isOpen1: false })
                    alert("Data Rejected Again");
                    this.props.history.push('/usersubmittedehs')
                });
        }
        else {
            axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSStatus', newbody)
                .then(response => {
                    this.setState({ isOpen: false })
                    alert("Status Updated Successfully");
                    this.props.history.push('/ehsadminview')
                });
        }
    }

    handleSubmit2 = e => {
        e.preventDefault();
        let SubmissionId = parseInt(this.state.id);
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalEhs/UpdateEhsStatus', { params: { SubmissionId: SubmissionId } })
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/adminrejectedehs')
            }).catch(err => console.log(err));
    }


    handleSubmit3 = event => {
        event.preventDefault();
        this.setState({
            month: this.state.prevMonth,
            year: this.state.longYear
        })
        this.componentDidMount();
    }
    handleChange = event => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    };


    handleChange1 = e => {
        const rows4 = [...this.state.rows4];
        let row = {
            [e.currentTarget.name]: e.currentTarget.value,

            "Status": this.state.status,
            "RegulatorId": 1,
            "SubmissionId": parseInt(this.state.id)
        }
        rows4[0] = row
        this.setState({
            rows4
        });
    };
    
    toggle = (index) => {
        let collapse = "isOpen" + index;
        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }

    render() {
        return (
            <div className="app">
                <h5 style={{ backgroundColor: '#001946', width: '1100px', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Monthly EHS Report </h5>
                <br />
                <div id="monthfilter" style={{fontSize:'16px'}}>
                    <FormControl>
                        <select
                            value={this.state.month}
                            name="month"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Month--</option>
                            <option value={this.state.month1} >January</option>
                            <option value={this.state.month2} >February</option>
                            <option value={this.state.month3} >March</option>
                            <option value={this.state.month4} >April</option>
                            <option value={this.state.month5} >May</option>
                            <option value={this.state.month6} >June</option>
                            <option value={this.state.month7} >July</option>
                            <option value={this.state.month8} >August</option>
                            <option value={this.state.month9} >September</option>
                            <option value={this.state.month10} >October</option>
                            <option value={this.state.month11} >November</option>
                            <option value={this.state.month12} >December</option>
                        </select>
                    </FormControl>

                    &nbsp;


                    <FormControl>
                        <select
                            value={this.state.year}
                            name="year"
                            onChange={this.handleChange}
                        >
                            <option selected disabled value='0'>--Select Year--</option>
                            <option value={this.state.prevYear} >{this.state.prevYear}</option>
                            <option value={this.state.longYear} >{this.state.longYear}</option>
                        </select>
                    </FormControl>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit3} type="submit" className="btn btn-success btn-sm">CLEAR</button>
                </div>
                <br />
                <div id="status" style={{ float: 'right', fontSize: '16px'}}>
                    <label style={{ fontWeight: 'bold' }}>Status:</label>
                    <FormControl>
                        <select
                            name="Status"
                            onChange={this.openModal}
                        >
                            <option selected disabled value='0'>--Pending--</option>
                            <option value={this.state.status1} >Approve</option>
                            <option value={this.state.status2} >Reject</option>
                        </select>
                    </FormControl>
                </div>
                <div id="resubmit">
                    <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit2}>RE-SUBMIT FOR APPROVAL</button>
                </div>
                <br />
                <Modal style={{ backgroundColor: '#ffffff00', width: '500px', borderColor: '#ffffff00' }}
                    show={this.state.isOpen} onHide={this.closeModal}>
                    <div className="App1">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label for='Remarks'>Remarks: </label>
                            <TextField type="text" name="Remarks" id="Remarks" onChange={this.handleChange1} style={{ width: '350px' }} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.handleSubmit1} style={{ marginRight: '8px' }}> Submit </Button>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
                <br />
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen1
                    })}
                    onClick={() => this.toggle(1)}
                >
                    <span className="app__toggle-text">Accident Statistics(Employees)</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen1}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen1 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" onSubmit={this.handleSubmit}>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>DETAILS</th>
                                    <th>UNIT</th>
                                    <th>THIS MONTH</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.rows.map((item, idx) => (
                                    <tr id="addr0" key={item.statisticsId}>
                                    <td>
                                            <TextField InputProps={{ readOnly: true }} style={{ width: '280px' }} type="text" required id="standard-required" defaultValue={item.details} />
                                    </td >
                                    <td>
                                            <TextField InputProps={{ readOnly: true }} style={{ width: '140px'}} type="text" required id="standard-required" defaultValue={item.unit} />
                                    </td>
                                    <td>
                                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" defaultValue={item.value} />
                                    </td>
                                    <td width="10%">
                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.statisticsId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </button>
                                            {/*<button id="delete"
                                            className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx, item.statisticsId)} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>*/}


                                    </td>
                                </tr>
            )
        )}
                            </tbody>
                        </Table>
                        {/*  <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>
               <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
                    </Form>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen5
                    })}
                    onClick={() => this.toggle(5)}
                >
                    <span className="app__toggle-text">Accident Statistics(Contractors)</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen5 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen5}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen5 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" onSubmit={this.handleSubmit}>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>DETAILS</th>
                                    <th>UNIT</th>
                                    <th>THIS MONTH</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.rows5.map((item, idx) => (
                                    <tr id="addr0" key={item.scontractorId}>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} style={{ width: '280px' }} type="text" required id="standard-required" defaultValue={item.details} />
                                        </td >
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} style={{ width: '140px' }} type="text" required id="standard-required" defaultValue={item.unit} />
                                        </td>
                                        <td>
                                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" defaultValue={item.value} />
                                        </td>
                                       <td width="10%">
                                            <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit4(item.scontractorId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </button>
                                       </td>
                                    </tr>

                                )
                                )}
                            </tbody>
                        </Table>
                    </Form>
                </Collapse>
               <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen2
                    })}
                    onClick={() => this.toggle(2)}
                >
                    <span className="app__toggle-text">AWARDS/CERTIFICATION/ACHIEVEMENTS </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen2}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen2 ? "app__collapse--active" : "")
                    }
                >
                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                <th>AWARDS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.rows1.map((item, idx) => (
                                <tr id="addr0" key={item.awardsId}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.descriptionId} />
                                    </td >
                                    <td width="10%">
                                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit1(item.awardsId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            )
                            )}
                        </tbody>
                    </Table>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen3
                    })}
                    onClick={() => this.toggle(3)}
                >
                    <span className="app__toggle-text">DETAILS OF EHS TRAINING PROGRAMMES   </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen3 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen3}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen3 ? "app__collapse--active" : "")
                    }
                >

                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>


                                <th>NAME OF THE PROGRAMME</th>
                                <th>CATEGORY OF TRAINEE</th>
                                <th>TYPE OF TRAINER</th>
                                <th>NO OF PARTICIPANTS</th>
                                <th>FACULTY NAME</th>
                                <th>INSTITUTION</th>
                             
                            </tr>

                        </thead>
                        <tbody>
                            {this.state.rows2.map((item, idx) => (
                                <tr id="addr0" key={item.trainingId}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} style={{width:'220px'}} type="text" required id="standard-required" defaultValue={item.programee} />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.category} />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.trainerType}  />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" defaultValue={item.participants}  />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.facultyName}  />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.institute} />
                                    </td>
                                    <td width="10%">

                                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit2(item.trainingId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen4
                    })}
                    onClick={() => this.toggle(4)}
                >
                    <span className="app__toggle-text">SIGNIFICANT EHS INITIATIVES /CONCERN</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen4 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen4}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen4 ? "app__collapse--active" : "")
                    }
                >

                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                <th>INITIATIVE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.rows3.map((item, idx) => (
                                <tr id="addr0" key={item.initiativeId}>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" required id="standard-required" defaultValue={item.description} />
                                    </td >
                                    <td width="10%">
                                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit3(item.initiativeId)} style={{ marginRight: '8px', display: this.isDisplayed() }} >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            )
                            )}
                        </tbody>
                    </Table>
                </Collapse>
            </div>
        )
    }
}

export default Accidentstatisticsview