import * as React from "react";
import Chart from "react-apexcharts";
import MDCard from "../MDCard";


const quarters = ["Q1" , "Q2" , "Q3" , "Q4"]
const series1 = [100 , 123 , 231 , 154];
const series2 = [87 , 123 , 243 , 167];
let counter = 0;

export default function LineChart(props) {

    

    const option = {

        chart : {
            height: 350,
            type: "line",
            id: "areachart-2",
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: true  //show tooltips on datapoints
          },
        stroke: {
            curve: "smooth"
          },
        grid: {
            padding: {
              right: 30,
              left: 20
            }
          },
        title: {
            text: props.title ? props.title : "Air Emission",
            align: "left"
          },
        labels: quarters,
        
        xaxis: {
            
                tooltip: {
                    enabled: true,
                    offsetX: 0,
                    formatter: (value, indexObj) => {
                        let total1 = ['Jan + Feb + Mar', 'Apr + May + June', 'July + Aug + Sep', 'Oct + Nov + Dec']
                        let total = total1[indexObj.dataPointIndex]
                       
                    return total
                    },
               
            }
        },
        //   xaxis: {
        //     type: "datetime"
        //   }
        colors : ["#1ad1ff", "#247BA0"],
        legend : {
            show: true,
            showForZeroSeries : true
        },
        yaxis : {
            floating: false,
            decimalsInFloat: 2,
            title: {
                text: props.unit,
               
                style: {
                    fontSize: '14px',
                    fontFamily: 'Astoria Classic Sans Light',
                }
            }
        }

    }

    const series = [
        {
            name: props.year1,
            data: props.data1 ? props.data1 : series1
        },
        {
            name: props.year2,
            data: props.data2 ? props.data2 : series2
        }
    ]

    return (
        <div>
            <Chart
                options={option}
                series={series}
                type="line"
                height="350"
                
            />
           
        </div>

    )
}