import React, { Component } from 'react'
import { Table, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import '../index.css';
import Tooltip from "react-simple-tooltip"
import axios from 'axios';



class Triandltifr extends Component {
    constructor(props) {
        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;

        this.state = {
            count: 0,
            rows: [{ id: 0 }, { id: 1 }],
            type1: 'Employees',
            type2: 'Contractors',
            row1: { ground: '', river: '', municipal: '', harvested: '', total: '' },
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            isOpen: false
        }

    }


    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var triand = JSON.parse(localStorage.getItem("triand"));
        var rows = this.state.rows;

        if (triand != null) {
            rows = triand

            this.setState({
                rows
            })
        }
    }



    openModal = (e) => {


        this.setState({ isOpen: true }, () => {
            window.setTimeout(() => {
                this.setState({ isOpen: false })
            }, 2000)
        });

    }
    closeModal = () => this.setState({ isOpen: false });

    handletotal(idx) {


        const rows = [...this.state.rows];
        var total = (parseFloat(rows[idx].Lti) / parseFloat(rows[idx].Manhours)) * 1000000
        rows[idx].ltifr=Math.round(total*100)/100
        this.setState({
            rows
        });
    };


    handletotal1(idx) {

        const rows = [...this.state.rows];
        var total = (parseFloat(rows[idx].Fatalities) + parseFloat(rows[idx].Lti) + parseFloat(rows[idx].MedicalCases)) / parseFloat(rows[idx].Manhours) * 100000
        rows[idx].trifr = Math.round(total * 100000) / 100000
        this.setState({
            rows
        });
    };


  

    handleChange = (idx) => (e) => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        var value = 0;
        //let id=name+(idx);

        value = parseFloat(e.target.value);
        var Type = '';
        if (idx == 0) {
            Type = "Employees"
        }
        else {
            Type = "Contractors"
        }

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        //var year = 0

        //var prevYear = this.state.prevYear;
        //if (prevMonth == "December") {
        //    year = prevYear
        //}
        //else {
        //    year = longYear
        //}

        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "Type":Type,
            "Year": year,
            "Month": month,
            "LocationId": location,
            "UserId": utype,
            "ReferenceId":Reference
        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };

    handleAddRow = () => (event) => {

        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {

        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }

    isDisabled1() {

        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        const { rows } = this.state;
        debugger;
        //var prevMonth = this.state.prevMonth;
        //var year = 0;
        //var longYear = this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year = longYear
        //}
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');
        var id = localStorage.getItem('utype')
        //axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Triandltifr/GetTriAndLtiFrByMonth', { params: { Month: month, Year: year, UserId: id } })
        //    .then(response => {
        //        debugger;
        //        if (response.data.length == 0) {
                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    localStorage.setItem('triand', Options.body);
                    let newbody = JSON.parse(Options.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SavetriLTItemp', newbody).then(response => {

                        if (response.data == 1) {
                            this.openModal()
                        }

                        else {

                            alert("Data Not Saved.Kindly Save Again")
                        }

                    })

            //    }
            //    else {

            //        alert("Data Already Submitted")
            //    }

            //})
      
        /* let newbody = JSON.parse(Options.body);
         axios.post("http://localhost:23013/api/TotalWaterWithdrawn/SaveTotalWaterWithdrawn", newbody)
             .then(response => {
                 if (response.data.Status === 'Success') {
                     alert("Data Save Successfully");
                     window.location.reload();
                     console.log(response.data.Status);
 
                 }
 
                 else {
                     alert('Data Saved Successfully');
                     window.location.reload();
                 }
 
             })
 
             //  .then(data => {
             // let typesapi = Object.keys(data.type);
             //console.log(typesapi);
 
 
 
             //    })
             .catch(error => {
                 console.log(error);
 
             });*/



    }





    render() {
        const text = "The total recordable injury frequency rate (TRIFR), or total recordable injury rate, is the number of fatalities,\nlost time injuries and other injuries requiring treatment by a medical professional per million hours worked."
        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>
                <br />
                <div className="App">
                    <div className="wordalign">
                        {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                        <label>
                            {this.state.longMonth}
                            {this.state.longYear}
  &nbsp;  &nbsp;

                              <Tooltip
                                arrow={15}
                                background="#ffffff"
                                border="black"
                                color="black"
                                content={text.split("\n").map((i, key) => {

                                    return <div key={key}>{i}</div>;


                                })}
                                fadeDuration={0}
                                fadeEasing="linear"
                                fixed={false}
                                fontFamily="Bw Glenn Sans Medium"
                                fontSize="12px"
                                offset={-20}
                                padding={15}

                                placement="right"
                                radius={50}
                                zIndex={1}




                            >

                                <span className="btn btn-outline-danger btn-sm">

                                    info
                               {/*<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                                </span>
                            </Tooltip>
                        </label>
                    </div>
                </div>

                <Modal style={{ backgroundColor: 'white', width: '500px', height: '200px', borderColor: '#ffffff00' }}

                    show={this.state.isOpen} onHide={this.closeModal}>

                    <Modal.Header style={{ backgroundColor: '#001946', color: 'white' }} >
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Data Saved Successfully

                          </Modal.Body>
                </Modal>
                <br />
                {/*   <h5>TOTAL WATER WITHDRAWAL </h5> */}


                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                          
                            <th>TYPE<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>FATALITIES<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>LTI<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>MEDICAL TREATMENT CASES</th>
                            <th>TOTAL MANHOURS WORKED</th>
                            <th>LTI FR<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            <th>TRI FR<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                            

                        </tr>
                    </thead>
                    <tbody>

                        <tr id="addr0" key={0}>



                            
                            <td>
                                <TextField InputProps={{ readOnly: true }} type="text"  name="Type" defaultValue={this.state.type1} />
                            </td>
                            <td>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" name="Fatalities" onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal(0); this.handletotal1(0) }} value={this.state.rows[0].Fatalities} />
                            </td>

                            <td>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" name="Lti" onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal(0); this.handletotal1(0) }} value={this.state.rows[0].Lti}/>
                            </td>

                            <td>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" name="MedicalCases" onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal(0); this.handletotal1(0) }} value={this.state.rows[0].MedicalCases}/>

                            </td>
                            <td>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" name="Manhours" onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal(0); this.handletotal1(0) }} value={this.state.rows[0].Manhours}/>
                            </td>
                            <td>
                                <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" name="ltifr" value={this.state.rows[0].ltifr}/>
                            </td>

                            <td>
                                <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" name="trifr" value={this.state.rows[0].trifr} />
                            </td>


                        </tr>
                        <tr id="addr1" key={1}>




                            <td>
                                <TextField InputProps={{ readOnly: true }} type="text"  name="Type" defaultValue={this.state.type2} />
                            </td>
                            <td>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" name="Fatalities" onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal(1); this.handletotal1(1) }} value={this.state.rows[1].Fatalities}/>
                            </td>


                            <td>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" name="Lti" onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal(1); this.handletotal1(1) }} value={this.state.rows[1].Lti}/>
                            </td>

                            <td>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" name="MedicalCases" onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal(1); this.handletotal1(1) }} value={this.state.rows[1].MedicalCases}/>

                            </td>
                            <td>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999, step: "any" } }} required id="standard-required" name="Manhours" onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal(1); this.handletotal1(1) }} value={this.state.rows[1].Manhours}/>
                            </td>
                            <td>
                                <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" name="ltifr" value={this.state.rows[1].ltifr} onKeyUp={() => { this.handletotal(1); this.handletotal1(1) }} />
                            </td>

                            <td>
                                <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" name="trifr" value={this.state.rows[1].trifr}  />
                            </td>
                        </tr>

                    </tbody>
                </Table>

                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit} disabled={this.isDisabled1()}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Triandltifr