

import React, { Component } from 'react'

import Header from './components/Header/header'
import IdleTimeOutHandler from './components/IdleTimeOutHandler'

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props)
        this.state = {
            isActive: true,
            isLogout: false,
           
        }
    }
    
  render () {
  return (
    <div className="App">
          <Header/>
          {/*<IdleTimeOutHandler
              onActive={() => {
                  this.setState({
                      isActive:true
                  })
              }}
              onIdle={() => {
                  this.setState({
                      isActive: false
                  }) }}
              onLogout={() => {
                  this.setState({
                      isLogout: true
                  }) }}
          /> */}
    </div>
    
  );
 }
}

