import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";
import { autoExpand } from 'devexpress-dashboard/model/index.metadata';

class AuditChecklist extends Component {

    constructor(props) {
        super(props)
      
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();

        this.state = {
            isOpen0:true,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            isOpen6: false,
            isOpen7: false,
            
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            program1: 'Fire',
            program2: 'Ehs',
            program3: 'Safety',
            program4: 'Others',
            type1: 'Yes',
            type2: 'No',
            type3: 'Open',
            type4: 'Closed',
            type5: 'External',
            rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
           
            rows1: [],
            rows2:[],

            admincode: localStorage.getItem('admincode'),

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear


        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
       
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var auditchecklist = JSON.parse(localStorage.getItem("auditchecklist"));
        var rows = this.state.rows;
       
        if (auditchecklist != null) {
            rows = auditchecklist

            this.setState({
                rows
            })
        }
    }



    handleChange = (idx) => (e) => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        
        const value = e.target.value;

        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows[idx],
           
            [name]: value,
            "ElectricId":idx+1,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[idx] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],

            "FormId": 12,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });
    };





    handleAddRow = () => (event) => {
        
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {
        
        event.preventDefault();

        const { rows } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AuditPlantElectrical/GetPlantElectricalByMonth', { params: { Month: prevMonth, Year: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows2: response.data
                })

            })

        var rows2 = this.state.rows2
        if (rows2.length == 0) {


            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };


            var newbody = JSON.parse(Options.body);
            const { rows1 } = this.state;


            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let checkbody = JSON.parse(Options1.body);

            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/AuditPlantElectrical/SavePlantElectricalList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

            ]).then(axios.spread((response1, response2) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }



        


    }
    handleSubmit1 = event => {
        debugger;
        event.preventDefault()
        const { rows } = this.state;
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };


        localStorage.setItem('auditchecklist', Options.body)

    }

    toggle = (index) =>
    {
       
      
        let collapse = "isOpen" + index;
        
        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
        console.log(this.state.prevState)
    }

    handleClick1 = () => (event) => {

        this.props.history.push("/auditchecklistview");    
    };

    renderRows()
    {
       
      
        
            return (
                 
                <Collapse
                    isOpen={this.state.isOpen1}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen1 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                           



                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>



                                <tr id="addr0" key={0}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" required id="standard-required" defaultValue='Is entry notice and list of authorized persons available?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" required id="standard-required" defaultValue='Authorized personnel entry restriction notice and the list of Authorized Persons should be available.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(0)}
                                                value={this.state.rows[0].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(0)}
                                                value={this.state.rows[0].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr1" key={1}>




                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='In case of Dry type transformers, is the transformer room/cabinet installed at suitable location and kept clean?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Transformer should be installed at suitable location and surrounding of the transformer should be clean.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(1)}
                                                value={this.state.rows[1].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(1)}
                                                value={this.state.rows[1].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr2" key={2}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are two separate earth connections provided for the transformer neutral and transformer body?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Two separate earth connections has to be provided for the transformer neutral and transformer body.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(2)}
                                                value={this.state.rows[2].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Responsibility}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(2)}
                                                value={this.state.rows[2].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr3" key={3}>




                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the transformer body or the enclosure/cabinet corroded?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Transformer body shall be maintained clean and not corroded.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(3)}
                                                value={this.state.rows[3].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Responsibility}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(3)}
                                                value={this.state.rows[4].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr4" key={4}>




                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the cable trench in the transformer covered with dry sand to prevent spread of fire?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Cable trenches should be filled with sand, pebbles or similar non-inflammable materials or completely covered with non-inflammable slabs.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(4)}
                                                value={this.state.rows[4].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(4)}
                                                value={this.state.rows[4].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                                <tr id="addr5" key={5}>




                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Whether danger warning signage displayed at the transformer room/cabinet?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Danger sign should be provided on the transformer room/cabinet' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(5)}
                                                value={this.state.rows[5].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Responsibility}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(5)}
                                                value={this.state.rows[5].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr6" key={6}>




                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Silica gel breather in good condition(Blue in colour)' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The silica gel to be filled into the breather shall have blue colour.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(6)}
                                                value={this.state.rows[6].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(6)}
                                                value={this.state.rows[6].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr7" key={7}>



                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Whether Cup below the silica gel breather of the transformer is empty or oil filled.' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The silica gel breather cup of a Transformer is partially filled with oil to avoid entering of moisture into the Transformer.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(7)}
                                                value={this.state.rows[7].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Responsibility}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(7)}
                                                value={this.state.rows[7].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr8" key={8}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Dissolved gas analysis test carried out for the transformer every year?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The frequency of sampling for DGA is recommended to be performed once every year.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(8)}
                                                value={this.state.rows[8].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Responsibility}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(8)}
                                                value={this.state.rows[8].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr9" key={9}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are fire buckets with clean dry sand and fire extinguishers provided near the transformer room/ yard?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire buckets filled with clean dry sand in addition to fire extinguishers of suitable fire class shall be provided at conspicuous locations.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(9)}
                                                value={this.state.rows[9].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(9)}
                                                value={this.state.rows[9].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>



                                <tr id="addr10" key={10}>



                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is marshalling box, radiator and terminal box of the transformer is earthed.' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='To maintain the equipotential bonding, main frame of the transformer and its various other auxiliaries / components are equipotential i.e. safely routed to earth from any single earthing point' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(10)}
                                                value={this.state.rows[10].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(10)}
                                                value={this.state.rows[10].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>




                            </tbody>
                        </Table>






                        {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                    </Form>
                </Collapse>
            )
        }
       
    

    renderRows2() {
       
            return (
                <Collapse
                    isOpen={this.state.isOpen2}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen2 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>


                                <tr id="addr11" key={11}>




                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the spacing on the front, sides & top of the electrical panels and DBs adequate as per standard?' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Adequate spacing should be provided throughout the panels in the panel rooms.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(11)}
                                                value={this.state.rows[11].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Responsibility}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(11)}
                                                value={this.state.rows[11].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr12" key={12}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are Insulated rubber mats are provided at the front and rear side of the panel?' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Insulated rubber mats should be laid at rear side of the panel too as it protects the individual from electric shock in case of abnormal conditions' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(12)}
                                                value={this.state.rows[12].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(12)}
                                                value={this.state.rows[12].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr13" key={13}>




                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Door earthing provided for the panel.?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Door earthing should be provided to ensure that door and cabinet are equipotential.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(13)}
                                                value={this.state.rows[13].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(13)}
                                                value={this.state.rows[13].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr14" key={14}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Gasket inside the panel is in good condition or worn-out.?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rubber gaskets inside the door cabinet of the panel should be provided and maintain in good condition as they contribute in maintaining the IP rating of the panel keeping dust and other micro foreign substance at bay.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(14)}
                                                value={this.state.rows[14].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(14)}
                                                value={this.state.rows[14].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr15" key={15}>




                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is danger notice provided on the main power panel as per IS 2551 specifications? Check whether notice is conspicuous and displayed in local language/Hindi/English (bilingual)?' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Danger notice signage should be provided as per IS 2551 in Hindi or English and the local language ofthe District, with a sign of skull and bones.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(15)}
                                                value={this.state.rows[15].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(15)}
                                                value={this.state.rows[15].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                                <tr id="addr16" key={16}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the Panels and Feeders clearly identified?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Permanent Identification should be provided on the panels and feeders as it leads to easy location of panels in fault conditions and in normal control circuit activity.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(16)}
                                                value={this.state.rows[16].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Responsibility}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(16)}
                                                value={this.state.rows[16].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr17" key={17}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Proper Housekeeping is observed inside the panel?' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Housekeeping shall be done on regular basis and records shall be maintained ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(17)}
                                                value={this.state.rows[17].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(17)}
                                                value={this.state.rows[17].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr18" key={18}>



                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is double body earthing is provided to the panels.?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Double body earthing should be provided so as to assure proper earth continuity, even in case of loose connection of one earth strip/wire.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(18)}
                                                value={this.state.rows[18].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(18)}
                                                value={this.state.rows[18].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr19" key={19}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Any holes/gaps observed for the cable openings?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All unused gaps or openings shall be closed using dummy glands, to avoid rodent / moisture or dust entry and thus preventing any internal fault or arc flash. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(19)}
                                                value={this.state.rows[19].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(19)}
                                                value={this.state.rows[19].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr20" key={20}>




                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are indicating lamps provided for the panel and are in working condition?' />
                                    </td >
                                    <td>
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Indication lamps should be in working condition.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(20)}
                                                value={this.state.rows[20].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(20)}
                                                value={this.state.rows[20].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>



                                <tr id="addr21" key={21}>



                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the cable entries into the room are sealed?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Cable entries shall be sealed with fire retardant sealant having fire resisting capacity for two hours. Sealing of cable entry prevents the fire propagation along a cable system through wall, floor, or floor ceiling.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(21)}
                                                value={this.state.rows[21].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(21)}
                                                value={this.state.rows[21].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr22" key={22}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are SOP (Standard Operating Procedure) is displayed near the panels.?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='SOPs should be displayed near the panels to eliminate the electrical hazards.The purpose of a SOP is to carry out the operations correctly and always in the same manner.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(22)}
                                                value={this.state.rows[22].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(22)}
                                                value={this.state.rows[22].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr23" key={23}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are Live parts within all the panels are shrouded or not?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Acrylic sheet should be provided in between the live parts in the feeder to minimize the hazard due to direct contact accidentally.Live parts shall be enclosed or guarded to prevent accidental contact by persons and objects.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(23)}
                                                value={this.state.rows[23].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(23)}
                                                value={this.state.rows[23].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr24" key={24}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is control wiring inside the panel is in order.?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Cables should be laid properly as haphazard laying may reduce panel space, which effects cooling. Also, in case of fault (break down of cable insulation) in cable, it comes directly in contact with the cabinets door which is unsafe and can lead to fatality.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(24)}
                                                value={this.state.rows[24].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(24)}
                                                value={this.state.rows[24].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr25" key={25}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Surge protective device provided for PLC and instrument panels. ?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='SPDs should be provided as it attempts to limit the voltage supplied to an electric device by either blocking or shorting to ground any unwanted voltages above a safe threshold.' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(25)}
                                                value={this.state.rows[25].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(25)}
                                                value={this.state.rows[25].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr26" key={26}>




                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is ELCB provided for electric panels and distribution boards (PDB, LDB)?' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='ELCB should be provided as to protect the individual from fatality ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(26)}
                                                value={this.state.rows[26].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(26)}
                                                value={this.state.rows[26].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr27" key={27}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are Panel enclosures are rusted at many places?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rusting should not be present in the view of maintaining ingress protection, life and avoid damage of panel' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(27)}
                                                value={this.state.rows[27].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(27)}
                                                value={this.state.rows[27].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                                <tr id="addr28" key={28}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Single Line Diagram (SLD) of Plant Electrical Distribution System available in the main LT panel room.' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Single line diagram should be available in the panel room as it shows the components of the circuit as simplified shapes, and the power and signal connections between the devices. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(28)}
                                                value={this.state.rows[28].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(28)}
                                                value={this.state.rows[28].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr29" key={29}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Shock Treatment Chart is present in the main panel room.' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Shock treatment chart should be displayed in main panel room for the resuscitation of persons suffering from electric shock' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(29)}
                                                value={this.state.rows[29].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(29)}
                                                value={this.state.rows[29].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr30" key={30}>



                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are relay co-ordination study carried out.?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Relay coordination studies has to carried out to ensure safety operation of the system and to avoid the nuisance tripping. In relay protection coordination services examining the coordination between the protective devices with the help of time current characteristics (TCC) from the lower stream to the upper stream and the short circuit values at the feeder, it helps in isolating only the faulty part of circuit without disturbing the supply of healthy circuit.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(30)}
                                                value={this.state.rows[30].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(30)}
                                                value={this.state.rows[30].Status}

                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr31" key={31}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is relay-calibration done or not.?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Relay Calibration has to be done as instrumentation transformer such as current and potential transformers can transfer harmonics from a primary to a secondary , resulting in misoperation of instrumentation , protective relaying and control circuits.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(31)}
                                                value={this.state.rows[31].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(31)}
                                                value={this.state.rows[31].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr32" key={32}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Cable tagging is done for the plant cabling system.?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue=' Cable tags should be provided on the cables as it simpler troubleshooting and maintenance procedures, which saves repair and movement requirements.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(32)}
                                                value={this.state.rows[32].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(32)}
                                                value={this.state.rows[32].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>



                            </tbody>
                        </Table>









                    </Form>
                </Collapse>
            )
       
    }



    renderRows3() {
      
            return (
        <Collapse
            isOpen={this.state.isOpen3}
            className={
                "app__collapse app__collapse--gradient " +
                (this.state.isOpen3 ? "app__collapse--active" : "")
            }
        >

            <Form className="font-link" onSubmit={this.handleSubmit}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                    </thead>
                    <tbody>


                        <tr id="addr33" key={33}>




                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Whether Indicating lamps are working properly? ' />
                            </td >
                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Indication lamps should be in working condition.' />
                            </td >
                            <td>
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(33)}
                                                value={this.state.rows[33].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Observations} />
                            </td>
                            <td>

                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Plan} />
                            </td>
                            <td>
                                <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Responsibility} />
                            </td>
                            <td>
                                <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Timelines} />
                            </td>
                            <td>
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(33)}
                                                value={this.state.rows[33].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr34" key={34}>




                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is adequate ventilation available in the room to avoid any accumulation of dangerous gases? ' />
                            </td >
                            <td>
                                <br />

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Adequate ventilation should be provided for inverter and UPS.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(34)}
                                                value={this.state.rows[34].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(34)}
                                                value={this.state.rows[34].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>

                        <tr id="addr35" key={35}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is battery bank rack earthing provided?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Double body earthing should be provided so as to assure proper earth continuity, even in case of loose connection of one earth strip/wire.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(35)}
                                                value={this.state.rows[35].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(35)}
                                                value={this.state.rows[35].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr36" key={36}>




                            <td>
                                <br />
                                <br />
                                <br />

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is any acid/battery water spillage noticed near the battery bank?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Acid water spillage should not be present near the battery bank as housekeeping shall be done on regular basis and records shall be maintained ' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(36)}
                                                value={this.state.rows[36].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Plan}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(36)}
                                                value={this.state.rows[36].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr37" key={37}>




                            <td>
                                <br />
                                <br />

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are battery terminals covered, placed in a rack?' />
                            </td >
                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Terminal covers should be provided to avoid accidental touch and any spark.' />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(37)}
                                                value={this.state.rows[37].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Plan} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(37)}
                                                value={this.state.rows[37].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>



                        <tr id="addr38" key={38}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Hydrogen gas detector is provided for the battery room.' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Hydrogen gas detector should be provided in the battery room for early detection of Hydrogen gas.Battery rooms shall be provided with ventilation to limit the concentration of hydrogen to one percent by volume.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(38)}
                                                value={this.state.rows[38].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(38)}
                                                value={this.state.rows[38].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>




                    </tbody>
                </Table>
            </Form>
        </Collapse>
        )
   
    }


    renderRows4() {
       
            return (
        <Collapse
            isOpen={this.state.isOpen4}
            className={
                "app__collapse app__collapse--gradient " +
                (this.state.isOpen4 ? "app__collapse--active" : "")
            }
        >

            <Form className="font-link" onSubmit={this.handleSubmit}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                    </thead>
                    <tbody>


                        <tr id="addr39" key={39}>




                            <td>
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the body and neutral of the DG set provided with double body earthing?' />
                            </td >
                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Two separate earth connections have to be provided for the DG neutral and body.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(39)}
                                                value={this.state.rows[39].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(39)}
                                                value={this.state.rows[39].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr40" key={40}>




                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Whether anti vibration pads are installed? Are the pads in good condition?' />
                            </td >
                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Anti-vibration pads should be installed maintained in good condition' />
                            </td >
                            <td>
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(40)}
                                                value={this.state.rows[40].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />



                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(40)}
                                                value={this.state.rows[40].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>

                        <tr id="addr41" key={41}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the DG Canopy free from water leakage?Is the area free from lube oil or diesel leakage?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='DG canopy should be free from oil/diesel leakage as spilled diesel/oil can be flammable which may lead to fire hazard, thus housekeeping is to be done properly and all the leakages shall be arrested immediately.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(41)}
                                                value={this.state.rows[41].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[41].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[41].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[41].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[41].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(41)}
                                                value={this.state.rows[41].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr42" key={42}>




                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are battery lead terminals tightened and lubricated? Are the battery terminals covered?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Terminal covers should be provided to avoid accidental touch and any spark.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(42)}
                                                value={this.state.rows[42].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(42)}
                                                value={this.state.rows[42].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr43" key={43}>




                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the DG canopy light and exhaust fan in working condition?' />
                            </td >
                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='DG canopy light and exhaust fan should be in working condition.' />
                            </td>
                            <td>
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(43)}
                                                value={this.state.rows[43].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[43].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[43].Plan} />
                            </td>
                            <td>

                                <br />
                                <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[43].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[43].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(43)}
                                                value={this.state.rows[43].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>



                        <tr id="addr44" key={44}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='"How is diesel stored? Is storage done in plastic drums?If stored in tank, is earthing provided for the same?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Diesel should be stored in steel drum as in case of plastic drum, static electricity is generated which results in to explosion as there is no provision of doing earthing to plastic drum.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(44)}
                                                value={this.state.rows[44].Status}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[44].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[44].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[44].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[44].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(44)}
                                                value={this.state.rows[44].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>

                        <tr id="addr45" key={45}>




                            <td>
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the diesel transfer pump flameproof?' />
                            </td >
                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Transfer pump should be flame-proof so that it should be preventive in case of any emergency.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(45)}
                                                value={this.state.rows[45].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].Plan} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(45)}
                                                value={this.state.rows[45].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>



                    </tbody>
                </Table>
            </Form>
        </Collapse>

        )
    }



    renderRows5() {

        
            return (
        <Collapse
            isOpen={this.state.isOpen5}
            className={
                "app__collapse app__collapse--gradient " +
                (this.state.isOpen5 ? "app__collapse--active" : "")
            }
        >

            <Form className="font-link" onSubmit={this.handleSubmit}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                    </thead>
                    <tbody>


                        <tr id="addr46" key={46}>




                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Whether Indicating lamps are working properly? ' />
                            </td >
                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Indication lamps should be in working condition. ' />
                            </td >
                            <td>
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(46)}
                                                value={this.state.rows[46].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].Observations} />
                            </td>
                            <td>

                                <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].Plan} />
                            </td>
                            <td>
                                <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].Responsibility} />
                            </td>
                            <td>
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].Timelines} />
                            </td>
                            <td>
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(46)}
                                                value={this.state.rows[46].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr47" key={47}>




                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is ELCB provided for electric panels and distribution boards (PDB,LDB).?' />
                            </td >
                            <td>
                                <br />

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='ELCB should be provided as to protect the individual from fatality ' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(47)}
                                                value={this.state.rows[47].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(47)} value={this.state.rows[47].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(47)} value={this.state.rows[47].Plan}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(47)} value={this.state.rows[47].Responsibility}/>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(47)} value={this.state.rows[47].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(47)}
                                                value={this.state.rows[47].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>

                        <tr id="addr48" key={48}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is control wiring inside the panel is in order?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Cables should be laid properly as haphazard laying may reduce panel space, which effects cooling. Also, in case of fault (break down of cable insulation) in cable, it comes directly in contact with the cabinets door which is unsafe and can lead to fatality. ' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(48)}
                                                value={this.state.rows[48].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Plan}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(48)}
                                                value={this.state.rows[48].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr49" key={49}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Surge protective device provided for PLC and instrument panels.?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='SPDs should be provided as it attempts to limit the voltage supplied to an electric device by either blocking or shorting to ground any unwanted voltages above a safe threshold.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(49)}
                                                value={this.state.rows[49].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(49)}
                                                value={this.state.rows[49].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr50" key={50}>




                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is double earthing provided to the panels and motors.?' />
                            </td >
                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Double earthing should be provided so as to assure proper earth continuity, even in case of loose connection of one earth strip/wire.' />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(50)}
                                                value={this.state.rows[50].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].Plan} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].Timelines}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(50)}
                                                value={this.state.rows[50].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>



                        <tr id="addr51" key={51}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the cable entries into the room are sealed?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Cable entries shall be sealed  with fire retardant sealant having fire resisting capacity for two  hours. Sealing of cable entry prevents the fire propagation along a cable system through wall, floor, or floor ceiling.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(51)}
                                                value={this.state.rows[51].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].Observations} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(51)}
                                                value={this.state.rows[51].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>

                        <tr id="addr52" key={52}>




                            <td>
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are door limit switch and / or panel illumination lamps in working condition?' />
                            </td >
                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Door limit switch should be in working condition in order to prevent ambiguity during equipment working.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(52)}
                                                value={this.state.rows[52].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].Plan} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(52)}
                                                value={this.state.rows[52].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>
                        <tr id="addr53" key={53}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are live parts within all the panels shrouded or not.?' />
                            </td >
                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Acrylic sheet should be provided in between the live parts in the feeder to minimize the hazard due to direct contact accidentally.Live parts shall be enclosed or guarded to prevent accidental contact by persons and objects.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(53)}
                                                value={this.state.rows[53].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].Observations}/>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].Plan} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].Responsibilty} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].Timelines}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(53)}
                                                value={this.state.rows[53].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>
                        <tr id="addr54" key={54}>




                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Any holes/gaps observed for the cable openings?' />
                            </td >
                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All unused gaps or openings shall be closed using dummy glands, to avoid rodent / moisture or dust entry and thus preventing any internal fault or arc flash.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                        onChange={this.handleChange(54)}
                                                value={this.state.rows[54].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(54)}
                                                value={this.state.rows[54].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>



                    </tbody>
                </Table>
            </Form>
        </Collapse>
        )
    }


    renderRows6() {
       
            return (
        <Collapse
            isOpen={this.state.isOpen6}
            className={
                "app__collapse app__collapse--gradient " +
                (this.state.isOpen6 ? "app__collapse--active" : "")
            }
        >

            <Form className="font-link" onSubmit={this.handleSubmit}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                    </thead>
                    <tbody>


                        <tr id="addr55" key={55}>




                            <td>

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are there Checks and Control in place to restrict unauthorized entry to the site? Are the proper documents (Visitor Register) maintained)? Are cigarettes, lighters and other flammable materials restricted inside the premises? ' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Checks and Control should be in place to restrict any unauthorized entry in the warehouse.Visitor register should be maintained and flammable materials should be prohibited.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(55)}
                                                value={this.state.rows[55].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].Timelines}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(55)}
                                                value={this.state.rows[55].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr56" key={56}>




                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all security personnel trained in firefighting, first aid and emergency response?Is the security guard trained in Electrical Shock Treatment?' />
                            </td >
                            <td>
                                <br />
                                <br />



                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Security Personnel should be trained in firefighting, electrical shock treatment and emergency response.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />




                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(56)}
                                                value={this.state.rows[56].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(56)} value={this.state.rows[56].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(56)} value={this.state.rows[56].Plan}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(56)} value={this.state.rows[56].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(56)} value={this.state.rows[56].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(56)}
                                                value={this.state.rows[56].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>

                        <tr id="addr57" key={57}>




                            <td>
                                <br />

                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the list of ‘Emergency Contact Numbers’ displayed/available near the Security? What is the mode(s) of communication in cases of emergency?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='List of Emergency Contact Numbers should be available near the Security Guards.Mode of communication available could be either Cellular Phones & Landline Phone.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Action"
                                                onChange={this.handleChange(57)}
                                                value={this.state.rows[57].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].Observations}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].Timelines} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select required

                                        name="Status"
                                                onChange={this.handleChange(57)}
                                                value={this.state.rows[57].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form>
        </Collapse>
        )
    
    }



    renderRows7() {
     
            return (
        <Collapse
            isOpen={this.state.isOpen7}
            className={
                "app__collapse app__collapse--gradient " +
                (this.state.isOpen7 ? "app__collapse--active" : "")
            }
        >

            <Form className="font-link" onSubmit={this.handleSubmit}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                    </thead>
                    <tbody>


                        <tr id="addr58" key={58}>




                            <td>
                                <br />
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are electrical safety shoes provided for the electricians?' />
                            </td >
                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='ISI approved electrical safety shoes shall be provided and used by the electrician' />
                            </td >
                            <td>

                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select 

                                        name="Action"
                                                onChange={this.handleChange(58)}
                                                value={this.state.rows[58].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Observations" onChange={this.handleChange(58)} value={this.state.rows[58].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Plan" onChange={this.handleChange(58)} value={this.state.rows[58].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />


                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(58)} value={this.state.rows[58].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                        <TextField type="text" name="Timelines" onChange={this.handleChange(58)} value={this.state.rows[58].Timelines}  />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />

                                <FormControl>

                                    <Select 

                                        name="Status"
                                                onChange={this.handleChange(58)}
                                                value={this.state.rows[58].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>

                        <tr id="addr59" key={59}>




                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the Insulating Rubber Gloves provided for the electrician? What is the test and operating voltage of the hand-gloves?' />
                            </td >
                            <td>
                                <br />
                                <br />



                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='ISI approved rubber gloves shall be provided and used by the electrician.' />
                            </td >
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />






                                <FormControl>

                                    <Select 

                                        name="Action"
                                                onChange={this.handleChange(59)}
                                                value={this.state.rows[59]. Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Observations" onChange={this.handleChange(59)} value={this.state.rows[59].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Plan" onChange={this.handleChange(59)} value={this.state.rows[59].Plan} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(59)} value={this.state.rows[59].Responsibility} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Timelines" onChange={this.handleChange(59)} value={this.state.rows[59].Timelines}/>
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />




                                <FormControl>

                                    <Select 

                                        name="Status"
                                                onChange={this.handleChange(59)}
                                                value={this.state.rows[59].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>

                        </tr>

                        <tr id="addr60" key={60}>




                            <td>


                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the PPE (Personal Protective Equipment) inspected and maintained?  Is the inspection record maintained?' />
                            </td >
                            <td>
                                <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='PPEs shall be inspected at regular intervals, preferably once a month and the records shall be maintained. ' />
                            </td >
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select 

                                        name="Action"
                                                onChange={this.handleChange(60)}
                                                value={this.state.rows[60].Action}
                                    >

                                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                                        <MenuItem value={this.state.type2} >No</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Observations" onChange={this.handleChange(60)} value={this.state.rows[60].Observations} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Plan" onChange={this.handleChange(60)} value={this.state.rows[60].Plan} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />




                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(60)} value={this.state.rows[60].Responsibility} />
                            </td>
                            <td>
                                <br />
                                <br />
                                <br />
                                <br />



                                        <TextField type="text" name="Timelines" onChange={this.handleChange(60)} value={this.state.rows[60].Timelines} />
                            </td>
                            <td>

                                <br />
                                <br />
                                <br />
                                <br />


                                <FormControl>

                                    <Select 

                                        name="Status"
                                                onChange={this.handleChange(60)}
                                                value={this.state.rows[60].Status}
                                    >

                                        <MenuItem value={this.state.type3} >Open</MenuItem>
                                        <MenuItem value={this.state.type4} >Closed</MenuItem>


                                    </Select>

                                </FormControl>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                        <button type="submit" style={{ float: 'right',marginLeft:'5px'}} className="btn btn-success" onSubmit={this.handleSubmit}>SUBMIT</button>

                    </Form>
                    <button type="submit" style={{ float: 'right' }} className="btn btn-success" onClick={this.handleSubmit1}>SAVE</button>

        </Collapse>
        )
 
    }

    render() {

        return (


            <div className="app">

                <h4 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px', width: '1080px' }}>Audit Checklist</h4>
                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px', width: '1080px' }}>Internal Audit Checklist: Plant</h5>
                
                <br />
                <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                <br />

                <button style={{ backgroundColor: '#00215C', color: 'white' }}
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen0
                    })}
                    onClick={() => this.toggle(0)}
                >
                    <span className="app__toggle-text">Electrical Safety Audit Check Sheet</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen0 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen0}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen0 ? "app__collapse--active" : "")
                    }
                >

                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen1
                    })}
                    onClick={() => this.toggle(1)}
                >
                    <span className="app__toggle-text">Transformer Yard</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                    { this.renderRows()}

                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen2
                    })}
                    onClick={() => this.toggle(2)}
                >
                    <span className="app__toggle-text">MAIN LT PANEL</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                    {this.renderRows2()}
               
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen3
                    })}
                    onClick={() => this.toggle(3)}
                >
                    <span className="app__toggle-text">INVERTER & UPS SUPPLY   </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen3 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                    {this.renderRows3()}
                
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen4
                    })}
                    onClick={() => this.toggle(4)}
                >
                    <span className="app__toggle-text">DG AREA</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen4 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                    {this.renderRows4()}
                

         
             <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen5
                    })}
                    onClick={() => this.toggle(5)}
                >
                    <span className="app__toggle-text">PRODUCTION AREA/UTILITY AREA</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen5 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                    {this.renderRows5()}
               
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen6
                    })}
                    onClick={() => this.toggle(6)}
                >
                    <span className="app__toggle-text">SECURITY & ACCESS CONTROL</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen6 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                    { this.renderRows6()}
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen7
                    })}
                    onClick={() => this.toggle(7)}
                >
                    <span className="app__toggle-text">PERSONAL PROTECTIVE EQUIPMENT</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen7 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                    {this.renderRows7()}
                
                </Collapse>
               
            </div >
        )
    }
}

export default AuditChecklist       