import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";
import { autoExpand } from 'devexpress-dashboard/model/index.metadata';

class Auditchecklistoffice extends Component {

    constructor(props) {
        super(props)

        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            isOpen0: true,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            isOpen6: false,
            isOpen7: false,
            isOpen8: false,
            isOpen9: false,
            
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            program1: 'Fire',
            program2: 'Ehs',
            program3: 'Safety',
            program4: 'Others',
            type1: 'Yes',
            type2: 'No',
            type3: 'Open',
            type4: 'Closed',
            type5: 'External',
            rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            rows1: [],
            rows2:[],
            admincode: localStorage.getItem('admincode'),

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear:prevYear

        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var auditchecklistoffice = JSON.parse(localStorage.getItem("auditchecklistoffice"));
        var rows = this.state.rows;

        if (auditchecklistoffice != null) {
            rows = auditchecklistoffice

            this.setState({
                rows
            })
        } 

    }



    handleChange = (idx) => (e) => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        const value = e.target.value;

        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)

        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows[idx],

            [name]: value,
            "OfficeId": idx + 1,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[idx] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],

            "FormId": 16,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });
    };





    handleAddRow = () => (event) => {
        
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {
        
        event.preventDefault();

        const { rows } = this.state;
        const { rows1 } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AuditOffice/GetAuditOfficeByMonth', { params: { Month: prevMonth, Year: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows2: response.data
                })

            })

        var rows2 = this.state.rows2
        if (rows2.length == 0) {



            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            var newbody = JSON.parse(Options.body);

            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let checkbody = JSON.parse(Options1.body);
            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/AuditOffice/SaveAuditOfficeList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

            ]).then(axios.spread((response1, response2) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }
        

    }
    handleSubmit1 = event => {
        debugger;
        event.preventDefault()
        const { rows } = this.state;
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };


        localStorage.setItem('auditchecklistoffice', Options.body)

    }


    toggle = (index) => {
        let collapse = "isOpen" + index;
        
        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
        console.log(this.state.prevState)
    }

    handleClick1 = () => (event) => {

        this.props.history.push("/auditofficeview");
    };

    renderRows() {

      
            return (

                <Collapse
                    isOpen={this.state.isOpen1}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen1 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>



                                <tr id="addr0" key={0}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" required id="standard-required" defaultValue='Are fire detectors coverage adequate and functional?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" required id="standard-required" defaultValue='Smoke detectors should be installed in each area of the site. Smoke detectors blink on normal mode and continue red LED on activation.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(0)}
                                                value={this.state.rows[0].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Observations} />
                                    </td>
                                    <td>


                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Plan} />
                                    </td>
                                    <td>


                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Responsibility} />
                                    </td>
                                    <td>


                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(0)}
                                                value={this.state.rows[0].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr1" key={1}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Visual Inspection of the detectors.' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='It should be in operating condition, in proper location, and is free of physical damage or conditions that impair operation.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(1)}
                                                value={this.state.rows[1].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(1)}
                                                value={this.state.rows[1].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr2" key={2}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Manual Triggering of Fire alarm system.' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Smoke detectors should be in operating mode and the same shall be reflecting on Fire alarm Panel. Hooters and alarm system will be in operating mode.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(2)}
                                                value={this.state.rows[2].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(2)}
                                                value={this.state.rows[2].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr3" key={3}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are manual call points and hooters functional?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='No continuous red light on normal mode and all hooters sound on activation of smoke detectors and MCPs.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(3)}
                                                value={this.state.rows[3].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(3)}
                                                value={this.state.rows[3].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr4" key={4}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is fire panel functional?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Power supply to fire panel is available and all the fire panel LEDs are working i.e., in case of any fire indication LED is red. In normal condition LEDs are amber.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(4)}
                                                value={this.state.rows[4].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(4)}
                                                value={this.state.rows[4].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                                <tr id="addr5" key={5}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are zones specified near Fire Alarm Panel (FAP)?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Area wise distribution of zones at fire alarm panel should be specified to identify the location of fire alarm without any delay. Zonal diagram should also be displayed with FAP' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(5)}
                                                value={this.state.rows[5].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(5)}
                                                value={this.state.rows[5].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr6" key={6}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is PA system and FAP Connected to UPS?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='PA system and fire alarm panel should be connected to UPS.On RAW power failure, system should draw power from UPS' />
                                    </td >
                                    <td>
                                      
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(6)}
                                                value={this.state.rows[6].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(6)}
                                                value={this.state.rows[6].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr7" key={7}>



                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Load testing of fire alarm system and remote supply standby power batteries.' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Standby Power batteries and load of fire alarm shall be tested.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(7)}
                                                value={this.state.rows[7].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(7)}
                                                value={this.state.rows[7].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr8" key={8}>




                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Visual inspection and functional testing of automatic and manual alarm initiating devices.' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Check whether they are working properly or not and note the response time of the system.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(8)}
                                                value={this.state.rows[8].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(8)}
                                                value={this.state.rows[8].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr9" key={9}>




                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Visual inspection and functional testing of audible and visual notification appliances.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Check the frequency and sound level.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        
                                        
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(9)}
                                                value={this.state.rows[9].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       
                                     
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       
                                       
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       
                                     
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       
                                       
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                    
                                     
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(9)}
                                                value={this.state.rows[9].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>



                                <tr id="addr10" key={10}>



                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Visual inspection and functional testing of emergency two-way communications systems' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                     
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Check the condition and healthiness of the system' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(10)}
                                                value={this.state.rows[10].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(10)}
                                                value={this.state.rows[10].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>
                                <tr id="addr11" key={11}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Verification of alarm signal transmission to central station' />
                                    </td >
                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='check on sample basis, that alarm signal is received in central (control) station.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(11)}
                                                value={this.state.rows[11].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(11)}
                                                value={this.state.rows[11].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr12" key={12}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Back-up Supply of FAP is in healthy condition or not ?' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Electrical supply parameters such as Voltage, Current, PF etc. is maintained. All the connections must be tight and clean' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(12)}
                                                value={this.state.rows[12].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(12)}
                                                value={this.state.rows[12].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                            </tbody>
                        </Table>






                        {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                    </Form>
                </Collapse>
            )
        }

    

    renderRows2() {
        
            return (
                <Collapse
                    isOpen={this.state.isOpen2}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen2 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>


                              

                                <tr id="addr13" key={13}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are Fire extinguishers placed at defined places?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire extinguishers bottom should be 1 mar. above the ground level.Location of fire extinguishers should be same as marked in evacuation plan.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(13)}
                                                value={this.state.rows[13].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(13)}
                                                value={this.state.rows[13].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr14" key={14}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all fire extinguishers accessible ?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All fire extinguishers accessible and hanged properly. No material should be stored in front of fire extinguishers' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(14)}
                                                value={this.state.rows[14].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(14)}
                                                value={this.state.rows[14].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr15" key={15}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all fire extinguishers functional ?' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='ABC type fire extinguishers should in green zone and weight of CO2 fire extinguishers should not be less than 10% of total weight. or weight should not be less than 90% ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(15)}
                                                value={this.state.rows[15].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(15)}
                                                value={this.state.rows[15].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                                <tr id="addr16" key={16}>




                                    <td>
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Service records of fire extinguishers are maintained?'/>
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Service records consists of Capacity, Type, Refilling on, Refilling off, Pressure gauge readings etc. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(16)}
                                                value={this.state.rows[16].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                    
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(16)}
                                                value={this.state.rows[16].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr17" key={17}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />  
                                        <br />  
                                        <br />  
                                        <br />  
                                        <br />  
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all the fire extinguisher placed according to the suitability of fire extinguishers for different classes of fire. ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='As per IS 2190 
a) Class A fires — Water, foam, ABC dry power and halocarbons. 
b) Class B fires — Foam, dry powder, clean agent and carbon dioxide extinguishers. 
c) Class C fires — Dry powder, clean agent and carbon dioxide extinguishers. 
d) Class D fires — Extinguishers with special dry powder for metal fires.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />  
                                        <br />  
                                        <br />  
                                        <br />  

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(17)}
                                                value={this.state.rows[17].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />  

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />  

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />  

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />  

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />  

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(17)}
                                                value={this.state.rows[17].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr18" key={18}>



                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are inspection slips displayed and maintained ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Monthly inspection slip of fire extinguisher should be maintained and displayed on extinguisher.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                 

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(18)}
                                                value={this.state.rows[18].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[19].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                      

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[19].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                  

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(18)}
                                                value={this.state.rows[18].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                             


                            </tbody>
                        </Table>









                    </Form>
                </Collapse>
            )
        }
   



    renderRows3() {
       
            return (
                <Collapse
                    isOpen={this.state.isOpen3}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen3 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>

                                <tr id="addr19" key={19}>




                                    <td>
                                        <br />
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are fire exit doors accessible and free from obstruction ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire exit doors should be free from obstruction at all the times. Door should be easily openable.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(19)}
                                                value={this.state.rows[19].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Timelines}  />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(19)}
                                                value={this.state.rows[19].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr20" key={20}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are panic bars along with self luminary signages provided ? ' />
                                    </td >
                                    <td>
                                       


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire exit door should have panic bar and display of "push bar to open" signage.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(20)}
                                                value={this.state.rows[20].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(20)}
                                                value={this.state.rows[20].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>



                                <tr id="addr21" key={21}>



                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Visual Inspection of the fire exit ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Visual Inspection consists:Door leaf, Door frame, Door closer (self-closing devices), Hinges, Intumescent door strip and cold smoke seals, Glazing (vision panels), Locks and levers/handles, Fire safety signage, Hold open devices, Gaps around the doors and threshold gaps, Panic hardware devices for external final fire exit doors ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(21)}
                                                value={this.state.rows[21].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Timelines}  />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(21)}
                                                value={this.state.rows[21].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                             



                            </tbody>
                        </Table>
                    </Form>
                </Collapse>
            )
        }


    renderRows4() {
       
            return (
                <Collapse
                    isOpen={this.state.isOpen4}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen4 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>
                                <tr id="addr22" key={22}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all fire-fighting pumps operational and on auto mode ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Electrical Panel selector switches should be on auto mode.All the fire pumps should start automatically when the system pressure drops and reach to the set point of the respective pumps.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(22)}
                                                value={this.state.rows[22].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(22)}
                                                value={this.state.rows[22].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr23" key={23}>




                                    <td>
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all supply valves open ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The handle of valve should be parallel to the line and locked in open position.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                     
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(23)}
                                                value={this.state.rows[23].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[24].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(23)}
                                                value={this.state.rows[23].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr24" key={24}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       
                                        


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are Pressure of all the pumps maintained? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Pressure Range - 4 kg/cm2 - 7 kg/cm2 will be maintained.At the remotest and farthest end it shall not be less than 3.5kg/cm2 ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                   
                                       

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(24)}
                                                value={this.state.rows[24].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(24)}
                                                value={this.state.rows[24].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr25" key={25}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Visual Inspection of Pump motor ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Consists of: 
Lock and tag out equipment 
Record equipment data 
Check all mounting and flange bolts to insure proper torque 
Check equipment base for soundness 
Visual inspection of pump grout for soundness 
Check for mechanical seal leaks 
Check condition of oil and grease seals 
Check packing for excessive leakage and adjust and/or replace ' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(25)}
                                                value={this.state.rows[25].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(25)}
                                                value={this.state.rows[25].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr26" key={26}>




                                    <td>
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Gong bell alarm working? ' />
                                    </td >
                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='When we trigger the system, gong bell system starts and the pumps will start working ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(26)}
                                                value={this.state.rows[26].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(26)}
                                                value={this.state.rows[26].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr27" key={27}>




                                    <td>
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is diesel tank completely  filled with diesel ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The level of oil in the fuel tank of diesel driven fire engine should be 100 %.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(27)}
                                                value={this.state.rows[27].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                    
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Observations}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(27)}
                                                value={this.state.rows[27].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                            


                            </tbody>
                        </Table>
                    </Form>
                </Collapse>

            )
        }
   



    renderRows5() {

            return (
                <Collapse
                    isOpen={this.state.isOpen5}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen5 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>

                                <tr id="addr28" key={28}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all area covered by sprinklers ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Sprinklers should be installed in all  areas except over electrical installations and server room . ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(28)}
                                                value={this.state.rows[28].Action}

                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(28)}
                                                value={this.state.rows[28].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr29" key={29}>




                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all supply valves in open position ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The handle of valve should be parallel to the line and locked in open position .' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                      
                                       

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(29)}
                                                value={this.state.rows[29].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(29)}
                                                value={this.state.rows[29].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr30" key={30}>



                                    <td>
                                        <br />
                                        <br />
                                       
                                       
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are water flow switches provided and functional ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Flow switches installed on sprinkler line should operate when there is any flow of water in the line. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      
                                      

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(30)}
                                                value={this.state.rows[30].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(30)}
                                                value={this.state.rows[30].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr31" key={31}>




                                    <td>
                                        <br />
                                       


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Positioned in a normal open or closed state? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Check the open or closed state of the Stop Valve, it should be in open state. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                       
                                        
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(31)}
                                                value={this.state.rows[31].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(31)}
                                                value={this.state.rows[31].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr32" key={32}>




                                    <td>
                                       
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Sealed, locked or supervised correctly ? ' />
                                    </td >
                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='- ' />
                                    </td >
                                    <td>
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(32)}
                                                value={this.state.rows[32].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                     
                                        

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Observations} />
                                    </td>
                                    <td>
                                        <br />

                                       

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                      
                                       
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                       
                                        
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                       
                                       
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(32)}
                                                value={this.state.rows[32].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr33" key={33}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='No external or internal leaks? ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Check for the leaks, specially at the joints and bending joints ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(33)}
                                                value={this.state.rows[33].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(33)}
                                                value={this.state.rows[33].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr34" key={34}>




                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='No leaks from the retarding chamber or alarm drain?  ' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Check for the leakage ' />
                                    </td >
                                    <td>
                                        <br />
                                      


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(34)}
                                                value={this.state.rows[34].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                       
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[37].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                    

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                       

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(34)}
                                                value={this.state.rows[34].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr35" key={35}>




                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Check pipes and fittings on the floor level  ' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='-' />
                                    </td >
                                    <td>
                                        <br />
                                       
                                       

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(35)}
                                                value={this.state.rows[35].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                      
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                       


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(35)}
                                                value={this.state.rows[35].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr36" key={36}>




                                    <td>
                                        <br />
                                        <br />
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Spare sprinkler heads available or not ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='At the time of quick replacement, spare sprinkler should be available. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(36)}
                                                value={this.state.rows[36].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(36)}
                                                value={this.state.rows[36].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr37" key={37}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Onsite tools for sprinkler head replacement present or not ' />
                                    </td >
                                    <td>

                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='For fitting of the sprinkler heads ' />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                     

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(37)}
                                                value={this.state.rows[37].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(37)}
                                                value={this.state.rows[37].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                                <tr id="addr38" key={38}>




                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Properly labelled? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='- ' />
                                    </td >
                                    <td>
                                       
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(38)}
                                                value={this.state.rows[38].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                       
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Observations} />
                                    </td>
                                    <td>
                                       

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Plan} />
                                    </td>
                                    <td>
                                      

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Responsibility} />
                                    </td>
                                    <td>
                                        
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Timelines} />
                                    </td>
                                    <td>
                                     
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(38)}
                                                value={this.state.rows[38].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>


                                <tr id="addr39" key={39}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is water flow switch panel functional ? ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Power supply to panel is available  and all the fire panel LEDs are working i.e. in case of water flow  LED should be  red. In normal condition LEDs should be amber.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(39)}
                                                value={this.state.rows[39].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(39)}
                                                value={this.state.rows[39].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                


                            </tbody>
                        </Table>
                    </Form>
                </Collapse>
            )
        }
    

    renderRows6() {
      
            return (
                <Collapse
                    isOpen={this.state.isOpen6}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen6 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>

                                <tr id="addr40" key={40}>




                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all hydrants / hose- reel functional ? ' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the hydrants should operate smoothly and hose reel drum should move freely and hose reel should not have any damages/cracks. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(40)}
                                                value={this.state.rows[40].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
    

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(40)}
                                                value={this.state.rows[40].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr41" key={41}>




                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all FHCs and hose boxes accessible ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Access to FHC should be obstruction free and no material should be kept in front of it. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                     
                                        

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(41)}
                                                value={this.state.rows[41].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[57].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />



                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[41].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />




                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[41].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />



                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[41].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(41)}
                                                value={this.state.rows[41].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr42" key={42}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Check the location of the fire hose cabinet ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='No obstruction should be there to reach the fire hose cabinet ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(42)}
                                                value={this.state.rows[42].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(42)}
                                                value={this.state.rows[42].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr43" key={43}>




                                    <td>

                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all FHCs and hose boxes equipped with hoses and branch?' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the FHC should have two hose pipes, one short branch, one fireman axe kept inside. All the equipment should be in good' />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(43)}
                                                value={this.state.rows[43].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[43].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[43].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[57].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[43].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(43)}
                                                value={this.state.rows[43].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                               
                            </tbody>
                        </Table>
                    </Form>
                </Collapse>
            )
        }
    



    renderRows7() {
       
            return (
                <Collapse
                    isOpen={this.state.isOpen7}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen7 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>
                                <tr id="addr44" key={44}>




                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are electro mats laid properly in front of all LT / HT panels ?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Insulating mats should be available in front of electrical panels.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        
                                      
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(44)}
                                                value={this.state.rows[44].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[57].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[57].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[57].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[57].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(44)}
                                                value={this.state.rows[44].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr45" key={45}>




                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Work Permit system following or not ? ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Work permit system should be followed and properly documented ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(45)}
                                                value={this.state.rows[45].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(45)}
                                                value={this.state.rows[45].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr46" key={46}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Up-to-date diagrammatic drawings present in the panel rooms? ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Diagrammatic drawings shall be consulted to ensure that no electrical circuit interlock operation can result in reenergizing the circuit being worked on ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(46)}
                                                value={this.state.rows[46].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(46)}
                                                value={this.state.rows[46].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr47" key={47}>




                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the working area (In the panel Room & Feeders) & exit passages clear without any obstructions? ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Working area should be clear without any obstruction ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(47)}
                                                value={this.state.rows[47].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(47)} value={this.state.rows[47].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(47)} value={this.state.rows[47].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(47)} value={this.state.rows[47].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(47)} value={this.state.rows[47].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(47)}
                                                value={this.state.rows[47].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr48" key={48}>




                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the safety instructions and Identification of components legible & available at spaces? ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                       
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Example: Electric shock treatment chart ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      
                                      

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(48)}
                                                value={this.state.rows[48].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(48)}
                                                value={this.state.rows[48].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr49" key={49}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are electrical safety signages provided ?  ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Warning signages of "Danger" , "Restricted Entry", "High Voltage"  etc should be displayed near/on electrical installations. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                      

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(49)}
                                                value={this.state.rows[49].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(49)}
                                                value={this.state.rows[49].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr50" key={50}>




                                    <td>

                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is ELCB/MCB Provided in lighting distribution boards (DBs) and functional ? ' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='ELCB / MCB should be provided in every circuits. ' />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(50)}
                                                value={this.state.rows[50].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].Responsibility}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(50)}
                                                value={this.state.rows[50].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>



                                <tr id="addr51" key={51}>




                                    <td>
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all the ELCB/MCB maintained? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Maintenance and testing of the ELCB/MCB should be done periodically to check its status.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(51)}
                                                value={this.state.rows[51].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(51)}
                                                value={this.state.rows[51].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr52" key={52}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Condition of the Cables & their Routing ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue=' Properly rioted through cable tray and shall not be damages ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(52)}
                                                value={this.state.rows[52].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                    
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                     
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(52)}
                                                value={this.state.rows[52].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr53" key={53}>




                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is there any loose wiring/ open Jn box in any area of the store ? ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the electrical wires should be laid in the conduit and no joints or exposed wiring should be in the store. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(53)}
                                                value={this.state.rows[53].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].Plan}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(53)}
                                                value={this.state.rows[53].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr54" key={54}>




                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is DG in working condition ? ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='DG set  should be in working condition . ' />
                                    </td >
                                    <td>
                                        <br />
                                        


                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(54)}
                                                value={this.state.rows[54].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                       
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                      
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                     
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(54)}
                                                value={this.state.rows[54].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr55" key={55}>




                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is any oil spillage / combustible material around DG and Servo Stabilizer ?' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='There should not be any oil spillage around the DG and stacking of combustible materials. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                                                               
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(55)}
                                                value={this.state.rows[55].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(55)}
                                                value={this.state.rows[55].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr56" key={56}>




                                    <td>

                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is transformer room secured and sealed ? ' />
                                    </td >
                                    <td>
                                      



                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Transformer room should be locked properly and unauthorised entry should be restricted.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />





                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(56)}
                                                value={this.state.rows[56].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       



                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(56)} value={this.state.rows[56].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        



                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(56)} value={this.state.rows[56].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                    




                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(56)} value={this.state.rows[56].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       



                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(56)} value={this.state.rows[56].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(56)}
                                                value={this.state.rows[56].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr57" key={57}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is oil level of the transformer maintained or not? ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue=' Check the oil level ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                         

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(57)}
                                                value={this.state.rows[57].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />




                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].Observations} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />



                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />



                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />


                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(57)}
                                                value={this.state.rows[57].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr58" key={58}>




                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Check the OTI and WTI readings of the transformer ' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue=' Check OTI and WTI trip and alarm settings ' />
                                    </td >
                                    <td>

                                       
                                        <br />

                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(58)}
                                                value={this.state.rows[58].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                       


                                        <TextField type="text" name="Observations" onChange={this.handleChange(58)} value={this.state.rows[58].Observation}/>
                                    </td>
                                    <td>

                                        <br />
                                       

                                        <TextField type="text" name="Plan" onChange={this.handleChange(58)} value={this.state.rows[58].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        


                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(58)} value={this.state.rows[58].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                  

                                        <TextField type="text" name="Timelines" onChange={this.handleChange(58)} value={this.state.rows[58].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                      

                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(58)}
                                                value={this.state.rows[58].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr59" key={59}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is Lock Out and Tag Out ( LOTO ) implemented ? ' />
                                    </td >
                                    <td>
                                        




                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Lock Out Tag Out (LOTO) process should be followed during any electrical and mechanical repairing work. Lock the panel /machine and display the tag on electrical installations. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />






                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(59)}
                                                value={this.state.rows[59].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />





                                        <TextField type="text" name="Observations" onChange={this.handleChange(59)} value={this.state.rows[59].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />




                                        <TextField type="text" name="Plan" onChange={this.handleChange(59)} value={this.state.rows[59].Plan}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />




                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(59)} value={this.state.rows[59].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />





                                        <TextField type="text" name="Timelines" onChange={this.handleChange(59)} value={this.state.rows[59].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />




                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(59)}
                                                value={this.state.rows[59].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr60" key={60}>




                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are extension cords and plastic fans being used on a permanent basis ?' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Only permanent sockets and industrial type fans should be used. ' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                      


                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(60)}
                                                value={this.state.rows[60].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       




                                        <TextField type="text" name="Observations" onChange={this.handleChange(60)} value={this.state.rows[60].Status} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                     



                                        <TextField type="text" name="Plan" onChange={this.handleChange(60)} value={this.state.rows[60].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       




                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(60)} value={this.state.rows[60].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      



                                        <TextField type="text" name="Timelines" onChange={this.handleChange(60)} value={this.state.rows[60].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(60)}
                                                value={this.state.rows[60].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr61" key={61}>




                                    <td>

                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is lightening arrestor and aviation light provided and operational ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Lightening arrester and aviation light should be provided on the top of the height of store.' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(61)}
                                                value={this.state.rows[61].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                    




                                        <TextField type="text" name="Observations" onChange={this.handleChange(61)} value={this.state.rows[61].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       


                                        <TextField type="text" name="Plan" onChange={this.handleChange(61)} value={this.state.rows[61].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                      

                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(61)} value={this.state.rows[61].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        



                                        <TextField type="text" name="Timelines" onChange={this.handleChange(61)} value={this.state.rows[61].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(61)}
                                                value={this.state.rows[61].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr62" key={62}>




                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all lights in the receiving area and outside working properly ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the lights in the receiving area should be in working  condition.' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                        


                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(62)}
                                                value={this.state.rows[62].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        




                                        <TextField type="text" name="Observations" onChange={this.handleChange(62)} value={this.state.rows[62].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        

                                        <TextField type="text" name="Plan" onChange={this.handleChange(62)} value={this.state.rows[62].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        




                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(62)} value={this.state.rows[62].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        


                                        <TextField type="text" name="Timelines" onChange={this.handleChange(62)} value={this.state.rows[62].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        


                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(62)}
                                                value={this.state.rows[62].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr63" key={63}>




                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Housekeeping inside the panel is maintained or not. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='-' />
                                    </td >
                                    <td>

                                        <br />
                                        
                                        


                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(63)}
                                                value={this.state.rows[63].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />



                                        <TextField type="text" name="Observations" onChange={this.handleChange(63)} value={this.state.rows[63].Observations} />
                                    </td>
                                    <td>

                                        <br />


                                        <TextField type="text" name="Plan" onChange={this.handleChange(63)} value={this.state.rows[63].Plan}/>
                                    </td>
                                    <td>

                                        <br />



                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(63)} value={this.state.rows[63].Responsibility} />
                                    </td>
                                    <td>
                                        <br />


                                        <TextField type="text" name="Timelines" onChange={this.handleChange(63)} value={this.state.rows[63].Timelines} />
                                    </td>
                                    <td>

                                        <br />  


                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(63)}
                                                value={this.state.rows[63].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr64" key={64}>




                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Check the earthing connections of the different electrical equipments' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Check for the corrosion and loose connection.' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                  

                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(64)}
                                                value={this.state.rows[64].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        



                                        <TextField type="text" name="Observations" onChange={this.handleChange(64)} value={this.state.rows[64].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        


                                        <TextField type="text" name="Plan" onChange={this.handleChange(64)} value={this.state.rows[64].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       


                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(64)} value={this.state.rows[64].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        

                                        <TextField type="text" name="Timelines" onChange={this.handleChange(64)} value={this.state.rows[64].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        


                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(64)}
                                                value={this.state.rows[64].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr65" key={65}>




                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is body of any electrical equipment corroded? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Repaint the body of the equipment. ' />
                                    </td >
                                    <td>

                                        <br />
                                    
                                       


                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(65)}
                                                value={this.state.rows[65].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                      




                                        <TextField type="text" name="Observations" onChange={this.handleChange(65)} value={this.state.rows[65].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                     
                                        



                                        <TextField type="text" name="Plan" onChange={this.handleChange(65)} value={this.state.rows[65].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                       



                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(65)} value={this.state.rows[65].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        
                                        



                                        <TextField type="text" name="Timelines" onChange={this.handleChange(65)} value={this.state.rows[65].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                      


                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(65)}
                                                value={this.state.rows[65].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr66" key={66}>




                                    <td>
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all emergency lights in working condition ?   ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Emergency lights should be functional during power failure or change over to DG.' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(66)}
                                                value={this.state.rows[66].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       




                                        <TextField type="text" name="Observations" onChange={this.handleChange(66)} value={this.state.rows[66].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        



                                        <TextField type="text" name="Plan" onChange={this.handleChange(66)} value={this.state.rows[66].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        



                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(66)} value={this.state.rows[66].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        



                                        <TextField type="text" name="Timelines" onChange={this.handleChange(66)} value={this.state.rows[66].Timelines} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        

                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(66)}
                                                value={this.state.rows[66].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                       
                    </Form>
                </Collapse>
            )
        }
    

    renderRows8() {
       
            return (
                <Collapse
                    isOpen={this.state.isOpen8}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen8 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>
                                <tr id="addr67" key={67}>




                                    <td>
                                        <br />
                                        <br />
                                       
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is fire evacuation plan  & fire exit  signs displayed and visible to the customers and associates ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='fire evacuation plan should be placed in such a way that it should be matched with the layout / entry ,exit direction and safety signages should be visible from each aisles. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(67)}
                                                value={this.state.rows[67].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(67)} value={this.state.rows[67].Status} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(67)} value={this.state.rows[67].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(67)} value={this.state.rows[67].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(67)} value={this.state.rows[67].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(67)}
                                                value={this.state.rows[67].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr68" key={68}>




                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are aisles and exit ways free from obstruction ?  ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='MDSE should not be stored in aisles and exit ways. ' />
                                    </td >
                                    <td>
                                        <br />
                                        
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(68)}
                                                value={this.state.rows[68].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                      
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(68)} value={this.state.rows[68].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                      
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(68)} value={this.state.rows[68].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                      

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(68)} value={this.state.rows[68].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                     
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(68)} value={this.state.rows[68].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(68)}
                                                value={this.state.rows[68].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr69" key={69}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       
                                       


                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is PA system working properly ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='PA speaker sound should be audible from each corner of the store and microphone should be in working condition.  ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(69)}
                                                value={this.state.rows[69].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(69)} value={this.state.rows[69].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(69)} value={this.state.rows[69].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(69)} value={this.state.rows[69].Responsibility} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(69)} value={this.state.rows[69].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(69)}
                                                value={this.state.rows[69].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                                <tr id="addr70" key={70}>




                                    <td>
                                        <br />
                                      
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is quarterly mock drill conducted ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Mock drill should be conducted on quarterly basis and documented. ' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(70)}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(70)} value={this.state.rows[70].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(70)} value={this.state.rows[70].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(70)} value={this.state.rows[70].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                     
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(70)} value={this.state.rows[70].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(70)}
                                                value={this.state.rows[70].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr71" key={71}>




                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is first-aid box maintained ? ' />
                                    </td >
                                    <td>
                                     

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='First aid box should be maintained and easily accessible. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select required

                                                name="Action"
                                                onChange={this.handleChange(71)}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      

                                        <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(71)} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(71)} value={this.state.rows[71].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                       
                                        <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(71)} value={this.state.rows[71].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(71)} value={this.state.rows[71].Timelines}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       
                                        <FormControl>

                                            <Select required

                                                name="Status"
                                                onChange={this.handleChange(71)}
                                                value={this.state.rows[71].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                





                            </tbody>
                        </Table>
                    </Form>
                </Collapse>

            )
        }
    


    renderRows9() {
      
            return (
                <Collapse
                    isOpen={this.state.isOpen9}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen9 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                        </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                            <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                    <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>ACTION PLAN</th>
                                    <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                </tr>
                            </thead>
                            <tbody>

                                <tr id="addr72" key={72}>




                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are security cameras working properly ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All camera should be operational. ' />
                                    </td >
                                    <td>
                                        <br />
                                       

                                        <FormControl>

                                            <Select 

                                                name="Action"
                                                onChange={this.handleChange(72)}
                                                value={this.state.rows[72].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                       

                                        <TextField type="text" name="Observations" onChange={this.handleChange(72)} value={this.state.rows[72].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                       
                                        <TextField type="text" name="Plan" onChange={this.handleChange(72)} value={this.state.rows[72].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                       

                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(72)} value={this.state.rows[72].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        
                                        <TextField type="text" name="Timelines" onChange={this.handleChange(72)} value={this.state.rows[72].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                       
                                        <FormControl>

                                            <Select 

                                                name="Status"
                                                onChange={this.handleChange(72)}
                                                value={this.state.rows[72].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr73" key={73}>




                                    <td>
                                        <br />
                                    

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the security cameras recording properly? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the DVR should have the recording capacity of minimum 30 days.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                       
                                        <FormControl>

                                            <Select 

                                                name="Action"
                                                onChange={this.handleChange(73)}
                                                value={this.state.rows[73]. Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      

                                        <TextField type="text" name="Observations" onChange={this.handleChange(73)} value={this.state.rows[73].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Plan" onChange={this.handleChange(73)} value={this.state.rows[73].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                     
                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(73)} value={this.state.rows[73].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                      
                                        <TextField type="text" name="Timelines" onChange={this.handleChange(73)} value={this.state.rows[73].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select 

                                                name="Status"
                                                onChange={this.handleChange(73)}
                                                value={this.state.rows[73].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr id="addr74" key={74}>




                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is metal ladder in use and safe  ? ' />
                                    </td >
                                    <td>
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Wooden ladder is not allowed, and existing metal ladder should be in good condition.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                       
                                        <FormControl>

                                            <Select

                                                name="Action"
                                                onChange={this.handleChange(74)}
                                                value={this.state.rows[74].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                       
                                     
                                        <TextField type="text" name="Observations" onChange={this.handleChange(74)} value={this.state.rows[74].Observations}/>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                   
                                       
                                        <TextField type="text" name="Plan" onChange={this.handleChange(74)} value={this.state.rows[74].Plan} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       
                                        
                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(74)} value={this.state.rows[74].Responsibility} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                     
                                        <TextField type="text" name="Timelines" onChange={this.handleChange(74)} value={this.state.rows[74].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select 

                                                name="Status"
                                                onChange={this.handleChange(74)}
                                                value={this.state.rows[74].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                </tr>

                                <tr id="addr75" key={75}>




                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is housekeeping near the electrical installation maintained or not? ' />
                                    </td >
                                    <td>
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Unwanted material shall not be stored. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                      
                                       
                                        <FormControl>

                                            <Select 

                                                name="Action"
                                                onChange={this.handleChange(75)}
                                                value={this.state.rows[75].Action}
                                            >

                                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                <MenuItem value={this.state.type2} >No</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                  

                                        <TextField type="text" name="Observations" onChange={this.handleChange(75)} value={this.state.rows[75].Observations} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                    

                                        <TextField type="text" name="Plan" onChange={this.handleChange(75)} value={this.state.rows[75].Plan} />
                                    </td>
                                    <td>

                                        <br />
                                        <br />
                                     

                                        <TextField type="text" name="Responsibility" onChange={this.handleChange(75)} value={this.state.rows[75].Responsibility}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      


                                        <TextField type="text" name="Timelines" onChange={this.handleChange(75)} value={this.state.rows[75].Timelines} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      
                                        <FormControl>

                                            <Select

                                                name="Status"
                                                onChange={this.handleChange(75)}
                                                value={this.state.rows[75].Status}
                                            >

                                                <MenuItem value={this.state.type3} >Open</MenuItem>
                                                <MenuItem value={this.state.type4} >Closed</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>

                                </tr>

                              






                            </tbody>
                        </Table>
                        <button type="submit" style={{ float: 'right', marginLeft: '5px' }} className="btn btn-success" onSubmit={this.handleSubmit}>SUBMIT</button>
                    </Form>
                    <button type="submit" style={{ float: 'right' }} className="btn btn-success" onClick={this.handleSubmit1}>SAVE</button>
                </Collapse>

            )
        }
    

    render() {

        return (


            <div className="app">

                <h4 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px', width: '1080px' }}>Audit Checklist</h4>
                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px', width: '1080px' }}>Internal Audit Checklist: Office</h5>

                <br />
                <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                <br />

               

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen1
                        })}
                        onClick={() => this.toggle(1)}
                    >
                        <span className="app__toggle-text">FIRE ALARM & DETECTION SYSTEM </span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    {this.renderRows()}

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen2
                        })}
                        onClick={() => this.toggle(2)}
                    >
                        <span className="app__toggle-text">FIRE EXTINGUISHER</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    {this.renderRows2()}

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen3
                        })}
                        onClick={() => this.toggle(3)}
                    >
                        <span className="app__toggle-text">FIRE EXITS</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen3 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    {this.renderRows3()}

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen4
                        })}
                        onClick={() => this.toggle(4)}
                    >
                        <span className="app__toggle-text">FIRE PUMP ROOM </span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen4 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    {this.renderRows4()}



                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen5
                        })}
                        onClick={() => this.toggle(5)}
                    >
                        <span className="app__toggle-text">SPRINKLER SYSTEM</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen5 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    {this.renderRows5()}

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen6
                        })}
                        onClick={() => this.toggle(6)}
                    >
                        <span className="app__toggle-text">FIRE HYDRANT SYSTEM</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen6 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    {this.renderRows6()}
                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen7
                        })}
                        onClick={() => this.toggle(7)}
                    >
                        <span className="app__toggle-text">ELECTRICITY SAFETY</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen7 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    {this.renderRows7()}

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen8
                        })}
                        onClick={() => this.toggle(8)}
                    >
                        <span className="app__toggle-text">EMERGENCY PREPAREDNESS</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen8 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    {this.renderRows8()}

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen9
                        })}
                        onClick={() => this.toggle(9)}
                    >
                        <span className="app__toggle-text">OTHERS</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen9 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    {this.renderRows9()}

               

            </div >
        )
    }
}

export default Auditchecklistoffice