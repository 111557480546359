import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editriskassessment extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
          
            count: 0,
            pro1: 1,
            pro2: 2,
            pro3: 3,
            pro4: 4,
            pro5: 5,
            pro6: 6,

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: ''


        }
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/RiskAssessment/GetRiskAssessmentById', { params: { RiskId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0]
                

            });

        });


    }









    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name
        var value = 0;
        if (name == "probabilityId" || name == "severityId" || name == "probabilityId1" || name == "severityId1") {
            value = parseInt(e.target.value)
        }
        else {
            value=e.target.value

        }
        var rows = this.state.rows;

        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };


    formatDate(string) {
        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('YYYY-MM-DD')


    }

    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows )
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/RiskAssessment/UpdateRiskAssessmentDetails', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/riskassessmentview')
            }).catch(err => console.log(err));


    }



    handletotal  = e => {

        
       
        e.preventDefault();

        var rows = this.state.rows;
        rows.riskLevel = parseInt(rows.probabilityId) * parseInt(rows.severityId)
        if (rows.riskLevel <= 8) {
            rows.riskRating = 'Low'
        }
        else if (rows.riskLevel <= 15) {
            rows.riskRating = 'Medium'
        }
        else if (rows.riskLevel >= 16) {
            rows.riskRating = 'High'
        }

        this.setState({
            rows
        });
    };


    handletotal1 = e => {


        
        e.preventDefault();
        var rows = this.state.rows;
        rows.residualRisk = parseInt(rows.probabilityId1) * parseInt(rows.severityId1)


        this.setState({
            rows
        });
    };


    render() {
        const text = "Improbable 1\nExtremely Remote 2\nRemote 3\nOccasional 4\nFrequent 5\nContinuous 6"
        const text1 = "No Impact 1\nMinor 2\nMajor 3\nFatality 4\nMultiple Fatalities 5 "
        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color:'white',textAlign: 'center',paddingTop:'10px',paddingBottom:'10px' }}>Edit Risk Assessment </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Activity">
                                    <label class="no-space-break">Activity</label> 
                                    <input required  name="activity" type="text" class="form-control" defaultValue={this.state.rows.activity} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Hazard">
                                    <label class="no-space-break" >Hazard</label>
                                    <input required  name="hazard" type="text" class="form-control" defaultValue={this.state.rows.hazard} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>



                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Impact">
                                    <label class="no-space-break">Impact </label><input required name="impact" type="text" class="form-control" defaultValue={this.state.rows.impact} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="no-space-break">Current Controls</label>
                                    <div  class="input-group">
                                       
                                      
                                    <input required name="controls" class="form-control" type="text" value={this.state.rows.controls} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Probability" style={{ whiteSpace: 'nowrap', overflowWrap: 'break-word' }}>
                                    <label class="no-space-break">Probability  &nbsp;<Tooltip
                                        arrow={15}
                                        background="#ffffff"
                                        border="black"
                                        color="black"
                                        content={text.split("\n").map((i, key) => {

                                            return <div key={key}>{i}</div>;


                                        })}
                                        fadeDuration={0}
                                        fadeEasing="linear"
                                        fixed={false}
                                        fontFamily="Bw Glenn Sans Medium"
                                        fontSize="12px"
                                        offset={-20}
                                        padding={15}

                                        placement="right"
                                        radius={50}
                                        zIndex={1}

                                    >
                                        <span >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            </svg>
                                        </span>

                                    </Tooltip></label>
                                   
                                    <select required type="number" class="form-control" onChange={this.handleChange} onBlur={this.handletotal}value={this.state.rows.probabilityId} name="probabilityId">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.pro1} >1</option>
                                        <option value={this.state.pro2} >2</option>
                                        <option value={this.state.pro3} >3</option>
                                        <option value={this.state.pro4} >4</option>
                                        <option value={this.state.pro5} >5</option>
                                        <option value={this.state.pro6} >6</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Severity">
                                    <label class="no-space-break">Severity &nbsp;
                                     <Tooltip
                                            arrow={15}
                                            background="#ffffff"
                                            border="black"
                                            color="black"
                                            content={text1.split("\n").map((i, key) => {

                                                return <div key={key}>{i}</div>;


                                            })}
                                            fadeDuration={0}
                                            fadeEasing="linear"
                                            fixed={false}
                                            fontFamily="Bw Glenn Sans Medium"
                                            fontSize="12px"
                                            offset={-20}
                                            padding={15}

                                            placement="right"
                                            radius={50}
                                            zIndex={1}

                                        >
                                            <span >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                </svg>
                                            </span>

                                        </Tooltip>
                                    
                                        </label>
                                    <select required type="number" class="form-control" onChange={this.handleChange} onBlur={this.handletotal}value={this.state.rows.severityId} name="severityId">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.pro1} >1</option>
                                        <option value={this.state.pro2} >2</option>
                                        <option value={this.state.pro3} >3</option>
                                        <option value={this.state.pro4} >4</option>
                                        <option value={this.state.pro5} >5</option>
                                        
                                    </select>
                                </div>
                            </div>
                            </div>

                       

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="RiskLevel">
                                    <label class="no-space-break">Risk Level</label>
                                    <input disabled  name="riskLevel" type="text" class="form-control" value={this.state.rows.riskLevel} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="RiskRating">
                                    <label class="no-space-break">Risk Rating</label>
                                    <input disabled  name="riskRating" type="text" class="form-control" value={this.state.rows.riskRating} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="RiskControls">
                                    <label class="no-space-break">Risk Control</label>
                                    <input required name="riskControls" type="text" class="form-control" value={this.state.rows.riskControls} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Probability1" style={{ whiteSpace: 'nowrap', overflowWrap: 'break-word' }}>
                                    <label class="no-space-break">Probability  &nbsp;<Tooltip
                                        arrow={15}
                                        background="#ffffff"
                                        border="black"
                                        color="black"
                                        content={text.split("\n").map((i, key) => {

                                            return <div key={key}>{i}</div>;


                                        })}
                                        fadeDuration={0}
                                        fadeEasing="linear"
                                        fixed={false}
                                        fontFamily="Bw Glenn Sans Medium"
                                        fontSize="12px"
                                        offset={-20}
                                        padding={15}

                                        placement="right"
                                        radius={50}
                                        zIndex={1}

                                    >
                                        <span >
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            </svg>
                                        </span>

                                    </Tooltip></label>

                                    <select required type="number" class="form-control" onChange={this.handleChange} onBlur={this.handletotal1} value={this.state.rows.probabilityId1} name="probabilityId1">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.pro1} >1</option>
                                        <option value={this.state.pro2} >2</option>
                                        <option value={this.state.pro3} >3</option>
                                        <option value={this.state.pro4} >4</option>
                                        <option value={this.state.pro5} >5</option>
                                        <option value={this.state.pro6} >6</option>
                                    </select>
                                </div>
                            </div>
                            


                        </div>

                        <div class="row">
                            
                            <div class="mb-3 col-md-6">
                                <div id="Severity1">
                                    <label class="no-space-break">Severity &nbsp;
                                     <Tooltip
                                            arrow={15}
                                            background="#ffffff"
                                            border="black"
                                            color="black"
                                            content={text1.split("\n").map((i, key) => {

                                                return <div key={key}>{i}</div>;


                                            })}
                                            fadeDuration={0}
                                            fadeEasing="linear"
                                            fixed={false}
                                            fontFamily="Bw Glenn Sans Medium"
                                            fontSize="12px"
                                            offset={-20}
                                            padding={15}

                                            placement="right"
                                            radius={50}
                                            zIndex={1}

                                        >
                                            <span >
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                </svg>
                                            </span>

                                        </Tooltip>

                                    </label>
                                    <select required type="number" class="form-control" onChange={this.handleChange} onBlur={this.handletotal1} value={this.state.rows.severityId1} name="severityId1">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.pro1} >1</option>
                                        <option value={this.state.pro2} >2</option>
                                        <option value={this.state.pro3} >3</option>
                                        <option value={this.state.pro4} >4</option>
                                        <option value={this.state.pro5} >5</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="ResidualRisk">
                                    <label class="no-space-break">Residual Risk</label>
                                    <input disabled name="residualRisk" type="number" class="form-control" value={this.state.rows.residualRisk} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editriskassessment