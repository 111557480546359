import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editnonreconsumption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            id: 0,
            reference:'',
            rows1:[]
           
        };
    }

    async componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/NonRenewableConsumption/GetNonRenewableById', { params: { NonRenewableId: id } }).then(response => {
            console.log(response.data);
            this.setState({
                rows:response.data[0],
                reference: response.data[0].referenceId,
                id: id
            });

        });
        debugger;
        var reference = this.state.reference
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EnergyIntensity/GetEnergyIntensityByReferenceId', { params: { ReferenceId: reference } }).then(response => {
            console.log(response.data);


            this.setState({
                rows1: response.data[0]


            });

        });
    }
    handleSubmit = event => {
        debugger;
        event.preventDefault();
        let row = this.state.rows;
        let rows1 = this.state.rows1
        rows1.nonRenewableEnergy = row.energyConsumed;
        rows1.energyConsumed = parseFloat(row.energyConsumed) + parseFloat(rows1.renewableEnergy)
        rows1.intensity = (parseFloat(rows1.energyConsumed) / parseFloat(rows1.production) * 10000000)
        this.setState({
            rows1: rows1
        })
        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        const Options1 = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows1)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);

        let checkbody = JSON.parse(Options1.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/EnergyIntensity/UpdateEnergyIntensityDetails', checkbody)
            .then(response => {

            }).catch(err => console.log(err));
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/NonRenewableConsumption/UpdateNonRenewableDetails', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push("/energyview/?name=" + "4.Non Renewable Energy Consumption &&" + "id=" + submissionid);

            }).catch(err => console.log(err));
    }

    handleChange = event => {

        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;


        const value = parseFloat(event.target.value);

        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
            TotWatId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };



    handletotal = e => {


        e.preventDefault();

        var rows = this.state.rows;
        rows.energyConsumed = (parseFloat(rows.fuelUsed) + parseFloat(rows.electricityPurchased))

        this.setState({
            rows
        });
    };



    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="FuelUsed">Fuel Used</label>
                        <input type="number" name="fuelUsed" className="form-control" step="any" id="FuelUsed" defaultValue={this.state.rows.fuelUsed} onChange={this.handleChange} onKeyUp={this.handletotal}/>
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="ElectricityPurchased">Electricity Purchased</label>
                        <input type="text" name="electricityPurchased" className="form-control" step="any" id="ElectricityPurchased" defaultValue={this.state.rows.electricityPurchased} onChange={this.handleChange} onKeyUp={this.handletotal} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="EnergyConsumed">Energy Consumed</label>
                        <input disabled type="text" name="energyConsumed" className="form-control" step="any" id="EnergyConsumed" defaultValue={this.state.rows.energyConsumed} />
                    </div>
                </div>
              


                <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>
            </Form>
        );
    }
}

export default Editnonreconsumption