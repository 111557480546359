import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import './App.css';
import TabNav from './TabNav';
import Tab from './Tab';
import Rawmaterialview from './Rawmaterialview';
import Deconsumptionview from './Deconsumptionview';
import Ieconsumptionview from './Ieconsumptionview';
import Reconsumptionview from './Reconsumptionview';
import Waterwithdrawalview from './Waterwithdrawalview';
import Waterrecycledview from './Waterrecycledview';
import Rwharvestingview from './Rwharvestingview';
import Nonreconsumptionview from './Nonreconsumptionview';
import Energyintensityview from './Energyintensityview';
import Directghgemission1view from './Directghgemission1view';
import Directghgemission2view from './Directghgemission2view';
import OtherSignificantview from './Othersignificantview';
//import Wastebytdview from './Wastebytdview';
import Sfclabelledmaterialview from './Sfclabelledmaterialview';
import Legalcomplianceoccupationalview from './Legalcomplianceoccupationalview';
import Costavoidanceview from './Costavoidanceview';
import Workrelatedfatalitiesview from './Workrelatedfatalitiesview';
import Triandltifrview from './Triandltifrview';
import Ehstrainingview from './Ehstrainingview';
import Wastebytdview from './Wastebytdview';
import Ozonedepletionview from './Ozonedepletionview';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

import { RiPencilLine } from "react-icons/ri";

import Airemissions from "./Airemissions";
import Waterdischargeview from "./Waterdischargeview";
import Dischargedwaterqualityview from "./Dischargedwaterqualityview";
import Significantspillsview from "./Significantspillsview";
import Legalcomplianceview from "./Legalcomplianceview";
import Emissionview from "./Emissionview";
import Carbonemissionview from './Carbonemissionview';
import Totalexpenditureview from './Totalexpenditureview';
import Injuriesfatalitiesview from './Injuriesfatalitiesview';
import Misproductionview from './Misproductionview';
import Misfireaccidentview from './Misfireaccidentview';
import Missuggestionview from './Missuggestionview';
import Mismedicalview from './Mismedicalview';
import { LocalStorageHelper } from "devexpress-dashboard/data/_utils";
import Deconsumption from "./Deconsumption";

class Tabbarview extends React.Component {


    constructor(props) {
        super(props);
      
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        let prevtoPrevYear = (longYear - 2).toString();
        this.state = {
            selected: '1.Non Renewable Fuels Consumed',
            isOpen: false,
            isOpen1: false,
            currentDateTime: date,
            onerow: [],
            rows: [],
            rows1: [],
            key:1,
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            year1: '2020',
            year2: '2021',
            count:1,
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            month: prevMonth,
            prevYear: prevYear,
            prevtoPrevYear: prevtoPrevYear,
            year: longYear,
            requirementKey: Math.random(),
            userid: '',
            id: '',
            types: ['All Types', '1.Non Renewable Fuels Consumed', '2.Non Renewable Energy Purchased', '3.Renewable Energy Consumption', '4.Non Renewable Energy Consumption', '5.Energy Intensity', '6.Direct GHG Emission(Scope 1)', '7.Direct GHG Emission(Scope 2)', '8.NOx,SOx(tonnes)', '9.Water Recycled', '10.Water Discharge', '11.Rain Water Harvesting', '12.Waste By Type & Disposal', '13.Raw Material', '14.SFC Labelled Material', '15.Legal Compliance Environment', '16.Legal Compliance Safety', '17.Total Expenditure', '18.Total Cost Avoidance', '19.Total Water Withdrawal', '20.Work Related Fatalities', '21.Total Occupational Illness', '22.TRI AND LTI FR', '23.EHS Training']
        }

    }
    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null ) {
            window.location.href = '/'
            return;
        }
    
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var prevYear = this.state.prevYear;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        if (prevMonth == "December") {
            this.setState({
                year: prevYear
            })
        }
        else {
            this.setState({
                year: longYear
            })
        }
         debugger;
        var utype = localStorage.getItem('utypeid')
        if (utype == 1) {
            
            document.getElementById("monthfilter").style.display = "none";
            document.getElementById("resubmit").style.display = "none";
            var adminhistory = localStorage.getItem('adminhistory')
            if (adminhistory == 'true') {
                document.getElementById("approve").style.display = "none";
                
            }

            else {
                document.getElementById("approve").style.display = "flex";
               
            }

            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            localStorage.setItem('submissionid', id);
           
            this.setState({
                id: id
            });
            debugger;
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectEnergyConsumption/GetDirectEnergyFiltered', { params: { SubmissionId: id } }).then(response => {
                this.setState({
                    rows: response.data,


                })

            })
            this.setSelected('1.Non Renewable Fuels Consumed')
        }


        else if (utype == 4)
        {

            
            document.getElementById("approve").style.display = "none";
            document.getElementById("resubmit").style.display = "none";
            var adminhistory = localStorage.getItem('adminhistory')
            if (adminhistory == 'true') {
                document.getElementById("monthfilter").style.display = "none";

            }

            else {
                document.getElementById("monthfilter").style.display = "flex";

            }

            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            localStorage.setItem('submissionid', id);
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectEnergyConsumption/GetDirectEnergyFiltered', { params: { SubmissionId: id } }).then(response => {
                this.setState({
                    rows: response.data,


                })
                console.log(response.data)
            })
            this.setSelected('1.Non Renewable Fuels Consumed')
        }
        else {
             debugger;
            var Status = localStorage.getItem('Status')
            //localStorage.removeItem("Status");
            const values = this.props.location.search
            const params = new URLSearchParams(values);
            const id = params.get('id');
            localStorage.setItem('submissionid', id);
            document.getElementById("approve").style.display = "none";
            document.getElementById("monthfilter").style.display = "flex";
            document.getElementById("resubmit").style.display = "none";

            if (adminhistory == 'true') {
                document.getElementById("monthfilter").style.display = "none";
                document.getElementById("resubmit").style.display = "none";
            }

          
            if (Status == 'Rejected') {
                
                document.getElementById("monthfilter").style.display = "none";
                document.getElementById("resubmit").style.display = "flex";

                
                localStorage.setItem('submissionid', id);

                this.setState({
                    id: id
                });

                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectEnergyConsumption/GetDirectEnergyFiltered', { params: { SubmissionId: id } }).then(response => {
                    this.setState({
                        rows: response.data,


                    })
                    console.log(response.data)
                })
                this.setSelected('1.Non Renewable Fuels Consumed')
            }
           
            else {
               
                document.getElementById("monthfilter").style.display = "flex";
                document.getElementById("resubmit").style.display = "none";
               
                var month = this.state.prevMonth;
                var year = this.state.longYear;
                var uid = localStorage.getItem('utype')
                localStorage.setItem('month', month)
                localStorage.setItem('year', year)
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/DirectEnergyConsumption/GetDirectEnergyMonthWise', { params: { Month: month, ConsumptionYear: year, UserId: uid } })
                    .then(response => {
                        this.setState({
                            rows: response.data
                        });
                        console.log(response.data)
                    })
                this.setSelected('1.Non Renewable Fuels Consumed')
            }
        }
       /* axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserSubmission/GetUserSubmissionByID', { params: { TMatId: id } }).then(response => {
            console.log(response.data);
            //  console.log(response.data[0].location.locationName);

            let typesapi = response.data


            let userid = typesapi[0].userId;
            this.setState({
                userid: userid
            });
            var MisProdId = typesapi[0].misProdId;
            localStorage.setItem('MisProdId', MisProdId);
            var EconsumptionId = typesapi[0].econsumptionId;
            localStorage.setItem('EconsumptionId', EconsumptionId);
            var EmittedId = typesapi[0].emittedId;
            localStorage.setItem('EmittedId', EmittedId);
            var MedicalId = typesapi[0].medicalId;
            localStorage.setItem('MedicalId', MedicalId);
            var MisFireId = typesapi[0].misFireId;
            localStorage.setItem('MisFireId', MisFireId);
            var InjuryId = typesapi[0].injuryId;
            localStorage.setItem('InjuryId', InjuryId);
            var ComplianceId = typesapi[0].complianceId;
            localStorage.setItem('ComplianceId', ComplianceId);
            var SuggestionsId = typesapi[0].suggestionsId;
            localStorage.setItem('SuggestionsId', SuggestionsId);
            var WatQualId = typesapi[0].watQualId;
            localStorage.setItem('WatQualId', WatQualId);
            var TotWatId = typesapi[0].totWatId;
            localStorage.setItem('TotWatId', TotWatId);
            var TotalRecyledId = typesapi[0].totalRecyledId;
            localStorage.setItem('TotalRecyledId', TotalRecyledId);
            var WatDischargedId = typesapi[0].watDischargedId;
            localStorage.setItem('WatDischargedId', WatDischargedId);`
            var TmatId = typesapi[0].tmatId;
            localStorage.setItem('TmatId', TmatId);
            var ExpenditureId = typesapi[0].expenditureId;
            localStorage.setItem('ExpenditureId', ExpenditureId);
            var SpillageId = typesapi[0].spillageId;
            localStorage.setItem('SpillageId', SpillageId);
            var CoEmissionId = typesapi[0].coEmissionId;
            localStorage.setItem('CoEmissionId', CoEmissionId);
            var RenewId = typesapi[0].renewId;
            localStorage.setItem('RenewId', RenewId);
            var HarvestTranId = typesapi[0].harvestTranId;
            localStorage.setItem('HarvestTranId', HarvestTranId);
            var OzoneId = typesapi[0].ozoneId;
            localStorage.setItem('OzoneId', OzoneId);
            var IndEnergyConId = typesapi[0].indEnergyConId;
            localStorage.setItem('IndEnergyConId', IndEnergyConId);



        }); */

    }
   

    handleChange = e => {
        debugger;
        const rows1 = [...this.state.rows1];
        var userid = localStorage.getItem('userid')
        let row = {
            ...rows1[0],
            [e.currentTarget.name]: e.currentTarget.value,
            "UserId": parseInt(userid),
            "Status": "Approved",
            "RegulatorId": 1,
            "submissionId": parseInt(this.state.id)
        }
        rows1[0] = row
        this.setState({
            rows1
        });
    };


    handleChange1 = (e) => {
        
        e.preventDefault();
        const rows1 = [...this.state.rows1];
        var userid=localStorage.getItem('userid')

        let row = {
            ...rows1[0],
            [e.target.name]: e.target.value,
            "UserId": parseInt(userid),
            "Status": "Rejected",
            "RegulatorId": 1,
            "submissionId": parseInt(this.state.id)
        }

        rows1[0] = row
        this.setState({
            rows1
        });
    };




    handleSubmit11 = e => {
        debugger;
        const { rows1 } = this.state;

        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows1)
        };
        let newbody = JSON.parse(Options.body);

        axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/AdminApproval/SaveAdminApprovedData', newbody)
            .then(response => {
                this.setState({ isOpen: false })
                alert("Data Approved Successfully");
                this.props.history.push('/usersubmissionview')
            });
    }


    handleSubmit12 = e => {
        
        var Status = localStorage.getItem('Status')
        const { rows1 } = this.state;


        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows1)
        };
        let newbody = JSON.parse(Options.body);




        if (Status == 'Submitted') {

            axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/AdminApproval/UpdateUserSubmittedEsg', newbody[0])

                .then(response => {
                    localStorage.setItem('Status','Rejected')
                    this.setState({ isOpen1: false })
                    alert("Data Rejected Again");
                    this.props.history.push('/usersubmittedesg')
                });
        }
        else {
            axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/AdminApproval/SaveAdminRejectedData', newbody)

                .then(response => {

                    this.setState({ isOpen1: false })
                    localStorage.setItem('Status', 'Rejected');
                    alert("Data Rejected Successfully");
                    this.props.history.push('/usersubmissionview')
                });
        }
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    openModal1 = () => this.setState({ isOpen1: true });
    closeModal1 = () => this.setState({ isOpen1: false });
    setSelected = (tab) => {
        
        this.setState({ selected: tab });
        
        this.setState({ requirementKey: Math.random() });

        if (tab == "1.Non Renewable Fuels Consumed") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }
        else if (tab == "2.Non Renewable Energy Purchased") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }
        else if (tab == "3.Renewable Energy Consumption") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;
        }

        else if (tab == "4.Non Renewable Energy Consumption") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 870;

        }

        else if (tab == "5.Energy Intensity") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1185;
        }
        else if (tab == "6.Direct GHG Emission(Scope 1)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1280;
        }
        else if (tab == "7.Direct GHG Emission(Scope 2)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1630;
        }
        else if (tab == "8.NOx,SOx(tonnes)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1900;
        }
        else if (tab == "9.Water Recycled") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2079;
        }
        else if (tab == "10.Water Discharge") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2239;
        }
        else if (tab == "11.Rain Water Harvesting") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2410;

        }
        else if (tab == "12.Waste By Type and Disposal") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2638;
        }
        else if (tab == "13.Raw Material") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2899;

        }
        else if (tab == "14.SFC Labelled Material") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3049;
        }
        else if (tab == "15.CO2 Emissions(Scope3)") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3265;
        }
        else if (tab == "16.Legal Compliance Environment") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3505;
        }
        else if (tab == "17.Legal Compliance Safety") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3784;
        }
        else if (tab == "18.Total Expenditure") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4018;
        }
        else if (tab == "19.Total Cost Avoidance") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4208;
        }
        else if (tab == "20.Total Water Withdrawal") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4368;
        }
        else if (tab == "21.Work Related Fatalities") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4568;

        }
        else if (tab == "22.Total Occupational Illness") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4768;
        }
        else if (tab == "23.TRI AND LTI FR") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5268;

        }
        else if (tab == "24.EHS Trainings") {
            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5465;
        }
    }
    





    handleSubmit = (value) => (event) => {

        
        this.setState({ selected: value });
        if (value == "1.Non Renewable Fuels Consumed") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;

        }
        else if (value == "2.Non Renewable Energy Purchased") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;

        }
        else if (value == "3.Renewable Energy Consumption") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 0;

        }
        else if (value == "4.Non Renewable Energy Consumption") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 870;

        }
        else if (value == "5.Energy Intensity") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1185;

        }
        else if (value == "6.Direct GHG Emission(Scope 1)") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1280;

        }
        else if (value == "7.Direct GHG Emission(Scope 2)") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1630;

        }
        else if (value == "8.NOx,SOx(tonnes)") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 1900;

        }
        else if (value == "9.Water Recycled") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2079;

        }
        else if (value == "10.Water Discharge") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2239;

        }
        else if (value == "11.Rain Water Harvesting") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2410;

        }
        else if (value == "12.Waste By Type and Disposal") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2638;

        }
        else if (value == "13.Raw Material") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 2899;

        }
        else if (value == "14.SFC Labelled Material") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3049;

        }
        else if (value == "15.CO2 Emissions(Scope3)") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3265;

        }
        else if (value == "16.Legal Compliance Environment") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3505;

        }
        else if (value == "17.Legal Compliance Safety") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 3784;

        }
        else if (value == "18.Total Expenditure") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4018;

        }
        else if (value == "19.Total Cost Avoidance") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4208;

        }

        else if (value == "20.Total Water Withdrawal") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4368;

        }
        else if (value == "21.Work Related Fatalities") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4568;

        }

        else if (value == "22.Total Occupational Illness") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 4768;

        }


        else if (value == "23.TRI AND LTI FR") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5268;

        }
        else if (value == "24.EHS Trainings") {

            var down = document.getElementById("nav-tabs");
            down.scrollLeft = 5465;

        }
    }

    handleSubmit1 = (event) => {


        this.props.history.push("/");

    }


    handleSubmit2 = event => {
        event.preventDefault();
        
        var count = this.state.count
        count = count + 1;
        this.setState({
            count: count
        })
        if (count == 1) {
            localStorage.setItem('month',this.state.prevMonth)
            localStorage.setItem('year', this.state.year); 
            count = count + 1;
            this.setState({
                count: count
            })
        }
        else {
            localStorage.setItem('month', this.state.month);
            localStorage.setItem('year', this.state.year);  
            count = count + 1;
            this.setState({
                count: count
            })
        }
       
        this.setSelected(this.state.selected);

      
    }

    handleSubmit3= event => {
        event.preventDefault();
        

        localStorage.setItem('month', this.state.prevMonth);
        localStorage.setItem('year', this.state.longYear);  
        this.setState({
            month: this.state.prevMonth,
            year:this.state.longYear
        })
        this.setSelected('1.Non Renewable Fuels Consumed')
          
    }

   


    handleSubmit4 = e => {
        
        let SubmissionId = parseInt(this.state.id);
       
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GetAdminApprovalForm/UpdateEsgStatus', { params: { SubmissionId: SubmissionId } })
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/adminrejectedesg')

            }).catch(err => console.log(err));
    }

    



    handleChange2 = event => {

        event.preventDefault();
        const name = event.target.name;

        const value = event.target.value;


        this.setState({
            [name]: value
        });
    };

    handleUrl = (langValue) => {
        
       this.props.history.push(langValue)
    }




    render() {



        return (

            <div className="App mt-4">

                <button id="logout" onClick={this.handleSubmit1} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                    <path d="M7.5 1v7h1V1h-1z" />
                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                </svg></button><br /> <br />

                <div id="monthfilter">

                    <FormControl>

                        <select

                            value={this.state.month}
                            name="month"
                            onChange={this.handleChange2}
                        >
                            <option selected disabled value='0'>--Select Month--</option>
                            <option value={this.state.month1} >January</option>
                            <option value={this.state.month2} >February</option>
                            <option value={this.state.month3} >March</option>
                            <option value={this.state.month4} >April</option>
                            <option value={this.state.month5} >May</option>
                            <option value={this.state.month6} >June</option>
                            <option value={this.state.month7} >July</option>
                            <option value={this.state.month8} >August</option>
                            <option value={this.state.month9} >September</option>
                            <option value={this.state.month10} >October</option>
                            <option value={this.state.month11} >November</option>
                            <option value={this.state.month12} >December</option>

                        </select>

                    </FormControl>

                    &nbsp;

                    <FormControl>
                        <select
                            value={this.state.year}
                            name="year"
                            onChange={this.handleChange2}
                        >
                            <option selected disabled value='0'>--Select Year--</option>

                            <option value={this.state.prevtoPrevYear} >{this.state.prevtoPrevYear}</option>
                            <option value={this.state.prevYear} >{this.state.prevYear}</option>
                            <option value={this.state.longYear} >{this.state.longYear}</option>
                        </select>
                    </FormControl>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit2} type="submit" className="btn btn-success btn-sm">SUBMIT</button>
                    <button style={{ marginLeft: '15px', fontWeight: 'bold' }} onClick={this.handleSubmit3} type="submit" className="btn btn-success btn-sm">CLEAR</button>

                </div>

                <br />
                <div id="approve" style={{ float: 'right' }}>
                    <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.openModal}>APPROVE</button>

                <button type="submit" style={{ float: 'right' }} className="btn btn-danger" onClick={this.openModal1}>REJECT</button>
                </div>

                <div id="resubmit">
                    <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit4}>RE-SUBMIT FOR APPROVAL</button>

                    </div>
                    <br /> <br />
                <TabNav 

                    tabs={['1.Non Renewable Fuels Consumed', '2.Non Renewable Energy Purchased', '3.Renewable Energy Consumption', '4.Non Renewable Energy Consumption', '5.Energy Intensity', '6.Direct GHG Emission(Scope 1)', '7.Direct GHG Emission(Scope 2)', '8.NOx,SOx(tonnes)', '9.Water Recycled', '10.Water Discharge', '11.Rain Water Harvesting', '12.Waste By Type & Disposal', '13.Raw Material', '14.SFC Labelled Material', '15.CO2 Emissions(Scope3)', '16.Legal Compliance Environment', '17.Legal Compliance Safety', '18.Total Expenditure', '19.Total Cost Avoidance', '20.Total Water Withdrawal', '21.Work Related Fatalities', '22.Total Occupational Illness', '23.TRI AND LTI FR', '24.EHS Trainings']} selected={this.state.selected} setSelected={this.setSelected}>


                    <Tab value={0} isSelected={this.state.selected === '1.Non Renewable Fuels Consumed'}  >
                        <Deconsumptionview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('2.Non Renewable Energy Purchased')}>NEXT</button>
                    </Tab>

                    <Tab value={1} isSelected={this.state.selected === '2.Non Renewable Energy Purchased'} >
                        <Ieconsumptionview key={this.state.requirementKey} setUrl={this.handleUrl} />
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('3.Renewable Energy Consumption')}>NEXT</button >
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('1.Non Renewable Fuels Consumed')}>PREV</button >
                    </Tab>
                    <Tab value={2} isSelected={this.state.selected === '3.Renewable Energy Consumption'}>
                        <Reconsumptionview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('4.Non Renewable Energy Consumption')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('2.Non Renewable Energy Purchased')}>PREV</button>
                    </Tab>
                     <Tab value={3} isSelected={this.state.selected === '4.Non Renewable Energy Consumption'}>
                        <Nonreconsumptionview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('5.Energy Intensity')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('3.Renewable Energy Consumption')}>PREV</button>
                    </Tab> 
                    <Tab value={4} isSelected={this.state.selected === '5.Energy Intensity'}>
                        <Energyintensityview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('6.Direct GHG Emission(Scope 1)')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('4.Non Renewable Energy Consumption')}>PREV</button>

                    </Tab>
                    <Tab value={5} isSelected={this.state.selected === '6.Direct GHG Emission(Scope 1)'}>
                        <Directghgemission1view key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('7.Direct GHG Emission(Scope 2)')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('5.Energy Intensity')}>PREV</button>
                    </Tab>
                    <Tab value={6} isSelected={this.state.selected === '7.Direct GHG Emission(Scope 2)'}>
                        <Directghgemission2view key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('8.NOx,SOx(tonnes)')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('6.Direct GHG Emission(Scope 1)')}>PREV</button>
                    </Tab>
                    <Tab value={7} isSelected={this.state.selected === '8.NOx,SOx(tonnes)'}>
                        < OtherSignificantview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('9.Water Recycled')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('7.Direct GHG Emission(Scope 2)')}>PREV</button>
                    </Tab> 
                    <Tab value={8} isSelected={this.state.selected === '9.Water Recycled'}>
                        <Waterrecycledview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('10.Water Discharge')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('8.NOx,SOx(tonnes)')}>PREV</button>
                    </Tab>

                    <Tab value={9} isSelected={this.state.selected === '10.Water Discharge'}>
                        <Waterdischargeview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('11.Rain Water Harvesting')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('9.Water Recycled')}>PREV</button>
                    </Tab>
                    <Tab value={10} isSelected={this.state.selected === '11.Rain Water Harvesting'}>
                        <Rwharvestingview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('12.Waste By Type & Disposal')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('10.Water Discharge')}>PREV</button>
                    </Tab>
                   
                     <Tab value={11} isSelected={this.state.selected === '12.Waste By Type & Disposal'}>
                        <Wastebytdview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('13.Raw Material')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('11.Rain Water Harvesting')}>PREV</button>
                    </Tab> 
                    <Tab value={12} isSelected={this.state.selected === '13.Raw Material'}>
                        <Rawmaterialview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('14.SFC Labelled Material')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('12.Waste By Type & Disposal')}>PREV</button>
                    </Tab>
                    <Tab value={13} isSelected={this.state.selected === '14.SFC Labelled Material'}>
                        <Sfclabelledmaterialview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('15.CO2 Emissions(Scope3)')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('13.Raw Material')}>PREV</button>
                    </Tab> 
                    <Tab value={14} isSelected={this.state.selected === '15.CO2 Emissions(Scope3)'}>
                        <Carbonemissionview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('16.Legal Compliance Environment')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('14.SFC Labelled Material')}>PREV</button>
                    </Tab>
                    <Tab value={15} isSelected={this.state.selected === '16.Legal Compliance Environment'}>
                        <Legalcomplianceview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('17.Legal Compliance Safety')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('15.CO2 Emissions(Scope3)')}>PREV</button>
                    </Tab>
                    <Tab value={16} isSelected={this.state.selected === '17.Legal Compliance Safety'}>
                        <Legalcomplianceoccupationalview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('18.Total Expenditure')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('16.Legal Compliance Environment')}>PREV</button>
                    </Tab>
                    <Tab value={17} isSelected={this.state.selected === '18.Total Expenditure'}>
                        <Totalexpenditureview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('19.Total Cost Avoidance')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('17.Legal Compliance Safety')}>PREV</button>
                    </Tab>
                    <Tab value={18} isSelected={this.state.selected === '19.Total Cost Avoidance'}>
                        <Costavoidanceview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('20.Total Water Withdrawal')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('18.Total Expenditure')}>PREV</button>
                    </Tab> 
                    <Tab value={19} isSelected={this.state.selected === '20.Total Water Withdrawal'}>
                        <Waterwithdrawalview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('21.Work Related Fatalities')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('19.Total Cost Avoidance')}>PREV</button>
                    </Tab>
                     <Tab value={20} isSelected={this.state.selected === '21.Work Related Fatalities'}>
                        <Workrelatedfatalitiesview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('22.Total Occupational Illness')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('20.Total Water Withdrawal')}>PREV</button>
                    </Tab>
                    <Tab value={21} isSelected={this.state.selected === '22.Total Occupational Illness'}>
                        <Misfireaccidentview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('23.TRI AND LTI FR')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('21.Work Related Fatalities')}>PREV</button>
                    </Tab>
                    <Tab value={22} isSelected={this.state.selected === '23.TRI AND LTI FR'}>
                        <Triandltifrview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('24.EHS Trainings')}>NEXT</button>
                        <button type="submit" style={{ float: 'right', marginRight: '8px' }} className="btn btn-success" onClick={this.handleSubmit('22.Total Occupational Illness')}>PREV</button>
                    </Tab>
                    <Tab value={23} isSelected={this.state.selected === '24.EHS Trainings'}>
                        <Ehstrainingview key={this.state.requirementKey} setUrl={this.handleUrl}/>
                       
                            </Tab>
                </TabNav>
                
                <Modal style={{ backgroundColor: '#ffffff00', width: '500px', borderColor: '#ffffff00'}}
                    
                    show={this.state.isOpen} onHide={this.closeModal}>
                    <div className="App1">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<label>
                                Date:
                                </label>
                              {this.state.currentDateTime} <br/> */}

                            <label for='Remarks'>Remarks: </label>
                            <TextField type="text" name="Remarks" id="Remarks" onChange={this.handleChange} style={{ width: '350px' }} />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.handleSubmit11} style={{ marginRight: '8px' }}> Submit </Button>
                            <Button variant="secondary" onClick={this.closeModal}>

                                Close
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>

                <Modal
                    style={{ backgroundColor: '#ffffff00', width: '500px', borderColor: '#ffffff00' }}
                    show={this.state.isOpen1} onHide={this.closeModal1}>
                    <div className="App1">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<label>
                                Date:
                                </label>
                              {this.state.currentDateTime} <br/> */}
                            <label for='ObjectionPoint'>Objection: </label>
                            <FormControl style={{ minWidth: 150 }}>
                                <Select isSearchable required
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    defaultValue="Select"
                                    name="ObjectionPoint"
                                    onChange={this.handleChange1}

                                >
                                    {this.state.types.map(type => (
                                        <MenuItem
                                            key={type}
                                            value={type}
                                        >
                                            {type}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            {/* <input type="text" name="point" id="point" onChange={this.handleChange} style={{ width: '350px' }} /> */}
                            <br />
                            <br />
                            <label for='Remarks'>Remarks: </label>
                            <TextField type="text" name="Remarks" id="Remarks" onChange={this.handleChange1} style={{ width: '350px' }} />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.handleSubmit12} style={{ marginRight: '8px' }}> Submit </Button>
                            <Button variant="secondary" onClick={this.closeModal1}>

                                Close
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>


            </div>

        );
    }
}
export default Tabbarview;