import { quarters } from "../Data/Quarter";

export default function computeWasteRecycledArray(temp) {

    let quarter1 = 0;
    let quarter2 = 0;
    let quarter3 = 0;
    let quarter4 = 0;

    temp.map(instance => {

        if (quarters.q1.includes(instance.month))
        {
            quarter1 += Math.round(instance.quantityRecycled);
        }
        else if(quarters.q2.includes(instance.month))
        {
            quarter2 += Math.round(instance.quantityRecycled);
        }
        else if(quarters.q3.includes(instance.month))
        {
            quarter3 += Math.round(instance.quantityRecycled);
        }
        else
        {
            quarter4 += Math.round(instance.quantityRecycled);
        }

    })

    let actualRecycledWaste = [];
    actualRecycledWaste.push(quarter1);
    actualRecycledWaste.push(quarter2);
    actualRecycledWaste.push(quarter3);
    actualRecycledWaste.push(quarter4);

    // console.log(actualDirectEmission);
    return actualRecycledWaste;
}