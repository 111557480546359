//import useState hook to create menu collapse state
import React, { useState } from 'react';
import { Route } from 'react-router';
import { Layout } from '../Layout';
import { Link } from 'react-router-dom';
//import react pro sidebar components
import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
//import icons from react icons
import { FaAccessibleIcon, FaAdjust, FaAdversal, FaAirbnb, FaAnchor, FaAtlassian, FaList, FaMeetup, FaPeopleCarry, FaRegHeart, FaSafari, FaSlack, FaUsers } from "react-icons/fa";
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle, FiHeart, FiAirplay, FiActivity } from "react-icons/fi";
import { RiAccountPinBoxFill, RiPencilLine } from "react-icons/ri";
import { BiAlarm, BiCog, BiPencil } from "react-icons/bi";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./header.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import { Scrollbars } from 'react-custom-scrollbars';

import { Home } from '../Home';
import { FetchData } from '../FetchData';
import { Counter } from '../Counter';
import Rawmaterial from '../Rawmaterial';
import Deconsumption from '../Deconsumption';
import Ieconsumption from '../Ieconsumption';
import Reconsumption from '../Reconsumption';
import Waterwithdrawal from '../Waterwithdrawal';
import Waterrecycled from '../Waterrecycled';
import Rwharvesting from '../Rwharvesting';
import Ozonedepletion from '../Ozonedepletion';
import Airemissions from '../Airemissions';
import Waterdischarge from '../Waterdischarge';
import Waterquality from '../Dischargedwaterquality';
import Significantspills from '../Significantspills';
import Legalcompliance from '../Legalcompliance';
import Rawmaterialview from '../Rawmaterialview';
import Carbonemission from '../Carbonemission';
import Totalexpenditure from '../Totalexpenditure';
import Injuriesfatalities from '../Injuriesfatalities';
import Misproduction from '../Misproduction';
import Misfireaccident from '../Misfireaccident';
import Missuggestion from '../Missuggestion';
import Mismedical from '../Mismedical';
import Wastebytd from '../Wastebytd';
import Deconsumptionview from '../Deconsumptionview';
import Ieconsumptionview from '../Ieconsumptionview';
import Editrawmaterial from '../Editrawmaterial';
import Editdeconsumption from '../Editdeconsumption';
import Editieconsumption from '../Editieconsumption';
import Rawmaterialmaster from '../Rawmaterialmaster';
import Deconsumptionmaster from '../Deconsumptionmaster';
import Ieconsumptionmaster from '../Ieconsumptionmaster';
import Login from '../Login';
import Editrawmaterialmaster from '../Editrawmaterialmaster';
import Editdeconsumptionmaster from '../Editdeconsumptionmaster';
import Editieconsumptionmaster from '../Editieconsumptionmaster';
import Rawmaterialmasterentry from '../Rawmaterialmasterentry';
import Deconsumptionmasterentry from '../Deconsumptionmasterentry';
import Ieconsumptionmasterentry from '../Ieconsumptionmasterentry';
import UserSubmissionview from '../Usersubmissionview';
import Example from '../Example';

import Dashboardrender from '../Dashboardrender';



import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import Unauthorized from '../Unauthorized';
import Tabbar from '../Tabbar';
import Tabbarview from '../Tabbarview';
import Reconsumptionview from '../Reconsumptionview';
import Editreconsumption from '../Editreconsumption';
import Waterwithdrawalview from '../Waterwithdrawalview';
import Editwaterwithdrawal from '../Editwaterwithdrawal';
import Approvalmodal from '../Approvalmodal';
import Waterrecycledview from '../Waterrecycledview';
import Editwaterrecycled from '../Editwaterrecycled';
import Ozonedepletionview from '../Ozonedepletionview';
import Editozonedepletion from '../Editozonedepletion';
import Rwharvestingmasterentry from '../Rwharvestingmasterentry';
import Rwharvestingmaster from '../Rwharvestingmaster';
import Editrwharvestingmaster from '../Editrwharvestingmaster';
import Rwharvestingview from '../Rwharvestingview';
import Editrwharvesting from '../Editrwharvesting';
import Emissionmasterentry from '../Emissionmasterentry';
import Emissionmaster from '../Emissionmaster';
import Editemissionmaster from '../Editemissionmaster';
import Emission from '../Emission';
import Emissionview from '../Emissionview';
import Editemission from '../Editemission';
import Waterdischargeview from '../Waterdischargeview';
import Editwaterdischarge from '../Editwaterdischarge';
import Dischargedwaterquality from '../Dischargedwaterquality';
import Dischargedwaterqualityview from '../Dischargedwaterqualityview';
import Editwaterquality from '../Editwaterquality';
import Significantspillsview from '../Significantspillsview';
import Editsignificantspills from '../Editsignificantspills';
import Legalcomplianceview from '../Legalcomplianceview';
import Editlegalcompliance from '../Editlegalcompliance';
import Carbonemissionview from '../Carbonemissionview';
import Editcarbonemission from '../Editcarbonemission';
import Totalexpenditureview from '../Totalexpenditureview';
import Edittotalexpenditure from '../Edittotalexpenditure';
import Injuriesfatalitiesview from '../Injuriesfatalitiesview';
import Editinjuriesfatalities from '../Editinjuriesfatalities';
import Misproductionview from '../Misproductionview';
import Editmisproduction from '../Editmisproduction';
import Misfireaccidentview from '../Misfireaccidentview';
import Editmisfireaccident from '../Editmisfireaccident';
import Missuggestionview from '../Missuggestionview';
import Editmissuggestion from '../Editmissuggestion';
import Mismedicalview from '../Mismedicalview';
import Editmismedical from '../Editmismedical';
import Adminhistory from '../Adminhistory';
import Mockdrill from '../Mockdrill';
import Nearmiss from '../Nearmiss';
import Fireinvesting from '../Fireinvesting';
import Safetywalk from '../SafetyWalk';
import Accidentreport from '../Accidentreport';
import Accidentstatistics from '../Accidentstatistics';
import Incidentinvestigation from '../Incidentinvestigation';
import Workpermit from '../Workpermit';
import Workpermitcontent from '../Workpermitcontent';
import Permitheight from '../Permitheight';
import Electricpermit from '../Electricpermit';
import Userregistration from '../Userregistration';
import Userlist from '../Userlist';
import Editusers from '../Editusers';
import Locationmaster from '../Locationmaster';
import Editlocationmaster from '../Editlocationmaster';
import modal from '../modal';
import Mockdrillview from '../Mockdrillview';
import Editmockdrill from '../Editmockdrill';
import AuditChecklist from '../Auditchecklist';
import Auditchecklistfire from '../Auditchecklistfire';
import Regulatorview from '../Regulatorview';
import Dashboardnew from '../Dashboardnew';
import Dashboardehs from '../Dashboardehs';
import Homepage from '../Homepage';
import Fireextenguisher from '../Fireextenguisher';
import Safetywalkview from '../Safetywalkview';
import Editsafety from '../Editsafety';
import Riskassessment from '../Riskassessment';
import Aspectimpact from '../Aspectimpact';
import Criticalarea from '../Criticalarea';
import Nearmissview from '../Nearmissview';
import Editnearmiss from '../Editnearmiss';
import Fireinvestingview from '../Fireinvestingview';
import Editfireinvesting from '../Editfireinvesting';
import Fireextinguisherview from '../Fireextinguisherview';
import Editfireextinguisher from '../Editfireextinguisher';
import Riskassessmentview from '../Riskassessmentview';
import Editriskassessment from '../Editriskassessment';
import Accidentstatisticsview from '../Accidentstatisticsview';
import Editstatistics from '../Editstatistics';
import Editawards from '../Editawards';
import Edittraining from '../Edittraining';
import Editinitiatives from '../Editinitiatives';
import Incidentinvestigationview from '../Incidentinvestigationview';
import Editinjuredemployee from '../Editinjuredemployee';
import Editincidentdescription from '../Editincidentdescription';
import Editincidenthappen from '../Editincidenthappen';
import Editfutureincident from '../Editfutureincident';
import Editreviewedby from '../Editreviewedby';
import Accidentreportview from '../Accidentreportview';
import Editaccidentreport from '../Editaccidentreport';
import criticalareaview from '../Criticalareaview';
import editcriticalarea from '../Editcriticalarea';
import Auditchecklistview from '../Auditchecklistview';
import Editauditchecklist from '../Editauditchecklist';
import Auditchecklistfireview from '../Auditchecklistfireview';
import Editauditchecklistfire from '../Editauditchecklistfire';
import EHSFormSubmission from '../EHSFormSubmission';
import MinutesOfMeeting from '../MinutesOfMeeting';
import FireSafetyReadiness from '../FireSafetyReadiness';
import Minutesofmeetingview from '../Minutesofmeetingview';
import Editoldstatus from '../Editoldstatus';
import Editoldpoints from '../Editoldpoints';
import Editnewpoints from '../Editnewpoints';
import Firesafetyreadinessview from '../Firesafetyreadinessview';
import Editfiresafetyreadiness from '../Editfiresafetyreadiness';
import Energyintensity from '../Energyintensity';
import Directghgemission2 from '../Directghgemission2';
import Directghgemission1 from '../Directghgemission1';
import Sfclabelledmaterial from '../Sfclabelledmaterial';
import Legalcomplianceoccupational from '../Legalcomplianceoccupational';
import Costavoidance from '../Costavoidance';
import Workrelatedfatalities from '../Workrelatedfatalities';
import OtherSignificant from '../OtherSignificant';
import Nonreconsumption from '../Nonreconsumption';
import Triandltifr from '../Triandltifr';
import Ehstraining from '../Ehstraining';
import Energyintensityview from '../Energyintensityview';
import Editenergyintensity from '../Editenergyintensity';
import Directghgemission1view from '../Directghgemission1view';
import Editdirectghgemission1 from '../Editdirectghgemission1';
import Directghgemission2view from '../Directghgemission2view';
import Editdirectghgemission2 from '../Editdirectghgemission2';
import Othersignificantview from '../Othersignificantview';
import Editothersignificant from '../Editothersignificant';
import Sfclabelledmaterialview from '../Sfclabelledmaterialview';
import Editsfclabelledmaterial from '../Editsfclabelledmaterial';
import Legalcomplianceoccupationalview from '../Legalcomplianceoccupationalview';
import Editlegalcomplianceoccupational from '../Editlegalcomplianceoccupational';
import Costavoidanceview from '../Costavoidanceview';
import Editcostavoidance from '../Editcostavoidance';
import Workrelatedfatalitiesview from '../Workrelatedfatalitiesview';
import Editworkrelatedfatalities from '../Editworkrelatedfatalities';
import Triandltifrview from '../Triandltifrview';
import Edittriandltifr from '../Edittriandltifr';
import Ehstrainingview from '../Ehstrainingview';
import Editehstraining from '../Editehstraining';
import Nonreconsumptionview from '../Nonreconsumptionview';
import Editnonreconsumption from '../Editnonreconsumption';
import Auditchecklistoffice from '../Auditchecklistoffice';
import Dashboard from '../dashboard';
import AuditOfficeView from '../AuditOfficeView';
import Editauditoffice from '../Editauditoffice';
import Companyperformance from '../Companyperformance';
import Adminhistoryehs from '../Adminhistoryehs';
import Wastebytdview from '../Wastebytdview';
import Editwastebytd from '../Editwastebytd';
import Adminrejectedesg from '../Adminrejectedesg';
import Usersubmittedesg from '../Usersubmittedesg';
import Adminrejectedehs from '../Adminrejectedehs';
import Usersubmittedehs from '../Usersubmittedehs';
import Riskregister from '../Riskregister';
import Riskregisterview from '../Riskregisterview';
import Editriskregister from '../Editriskregister';
import Editstatisticscontractor from '../Editstatisticscontractor';
import Workpermitview from '../Workpermitview';
import Workpermitcontentview from '../Workpermitcontentview';
import Permitheightview from '../Permitheightview';
import Electricpermitview from '../Electricpermitview';
import Editworkpermit from '../Editworkpermit';
import Editworkpermitcheck from '../Editworkpermitcheck';
import Editworkpermitcontent from '../Editworkpermitcontent';
import Editpermitheight from '../Editpermitheight';
import Multilocationselection from '../Multilocationselection';
import ForgotPassword from '../ForgotPassword';
import ChangePassword from '../Changepassword';
import LandingPage from '../../pages/LandingPage';
import CumilativeData from '../CumilativeData';








const Header = () => {

    var utype = localStorage.getItem('utypeid')
    var userroleid = localStorage.getItem('userroleid')

    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(true)

    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {

        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
        var homepage = localStorage.getItem("homepage");

        if (menuCollapse === false) {

            /*document.getElementById("header").style.width = "0px";*/
            document.getElementById("main").style.marginLeft = "0px";
            document.getElementById("menu").style.marginLeft = "0px";
            document.getElementById("header").style.width = "80px";
            if (homepage == "true") {
                document.getElementById("ribbon").style.marginLeft = "17px";
                document.getElementById("div6").style.paddingBottom = "65px"
                document.getElementById("div5").style.paddingBottom = "0px"
                document.getElementById("ribbon").style.width = "68%"

            }
        }

        else {

            document.getElementById("header").style.width = "300px";
            document.getElementById("main").style.marginLeft = "310px";
            document.getElementById("menu").style.marginLeft = "-100px";

            if (homepage == "true") {


                document.getElementById("div5").style.paddingBottom = "80px"
                document.getElementById("ribbon").style.width = "72%"
                document.getElementById("ribbon").style.marginLeft = "10px"


            }
            //  document.getElementById("policy").style.width = "473px";
            // document.getElementById("leaders").style.width = "473px";
            // document.body.style.backgroundColor = "white";

        }

        console.log(utype)
        // if (utype == 3) {
        //    
        //    document.getElementById("check2").style.visibility = "hidden";


        //   }

    };


    const handleClick0 = event => {

        window.location.href = '/homepage'
    };
    const handleClick = event => {
        window.location.href = "/tabbar/?name=" + "13.Raw Material";

    };
    const handleClick1 = event => {
        window.location.href = "/tabbar/?name=" + "1.Non Renewable Fuels Consumed";

    };
    const handleClick2 = event => {
        window.location.href = "/tabbar/?name=" + "2.Non Renewable Energy Purchased";
    };
    const handleClick3 = event => {
        window.location.href = "/tabbar/?name=" + "3.Renewable Energy Consumption";
    };
    const handleClick4 = event => {
        window.location.href = "/tabbar/?name=" + "20.Total Water Withdrawal";
    };
    const handleClick5 = event => {
        window.location.href = "/tabbar/?name=" + "9.Water Recycled";
    };
    const handleClick6 = event => {
        window.location.href = "/tabbar/?name=" + "11.Rain Water Harvesting";

    };
    const handleClick7 = event => {


        window.location.href = "/tabbar/?name=" + "6.Direct GHG Emission(Scope 1)";

    };
    const handleClick8 = event => {
        window.location.href = "/tabbar/?name=" + "7.Direct GHG Emission(Scope 2)";

    };
    const handleClick9 = event => {
        window.location.href = "/tabbar/?name=" + "4.Non Renewable Energy Consumption";

    };

    const handleClick10 = event => {
        window.location.href = "/tabbar/?name=" + "10.Water Discharge";

    };

    const handleClick11 = event => {

        window.location.href = "/tabbar/?name=" + "5.Energy Intensity";

    };
    const handleClick12 = event => {

        window.location.href = "/tabbar/?name=" + "12.Waste By Type and Disposal";

    };

    const handleClick13 = event => {

        window.location.href = "/tabbar/?name=" + "8.NOx,SOx(tonnes)";

    };
    const handleClick14 = event => {

        window.location.href = "/tabbar/?name=" + "16.Legal Compliance Environment";

    };
    const handleClick15 = event => {

        window.location.href = "/tabbar/?name=" + "21.Work Related Fatalities";

    };
    const handleClick16 = event => {

        window.location.href = "/tabbar/?name=" + "14.SFC Labelled Material";

    };
    const handleClick17 = event => {

        window.location.href = "/tabbar/?name=" + "22.Total Occupational Illness";

    };
    const handleClick18 = event => {

        window.location.href = "/tabbar/?name=" + "17.Legal Compliance Safety";

    };
    const handleClick19 = event => {

        window.location.href = "/tabbar/?name=" + "19.Total Cost Avoidance";

    };
    const handleClick20 = event => {

        window.location.href = "/tabbar/?name=" + "18.Total Expenditure";
    };
    const handleClick21 = event => {

        window.location.href = '/safetywalk'
    };
    const handleClick22 = event => {

        window.location.href = '/mockdrill'
    };
    const handleClick23 = event => {

        window.location.href = '/nearmiss'
    };
    const handleClick24 = event => {

        window.location.href = '/fireinvestigating'
    };
    const handleClick25 = event => {

        window.location.href = '/accidentreport'
    };
    const handleClick26 = event => {

        window.location.href = '/monthlyehs'
    };

    const handleClick27 = event => {

        window.location.href = '/incidentinvestigation'
    };

    const handleClick28 = event => {

        window.location.href = '/workpermit'
    };
    const handleClick29 = event => {

        window.location.href = '/workpermitcontent'
    };

    const handleClick30 = event => {

        window.location.href = '/permitheight'
    };
    const handleClick31 = event => {

        window.location.href = '/electricpermit'
    };

    const handleClick32 = event => {

        window.location.href = '/adminhistory'
    };

    const handleClick33 = event => {

        window.location.href = '/adminhistoryehs'
    };

    const handleClick34 = event => {

        localStorage.setItem('isLoggedIn', "false");

        window.location.href = '/'
    };
    const handleClick35 = event => {

        window.location.href = '/dashboardehs'
    };

    const handleClick36 = event => {

        window.location.href = '/fireextinguisher'
    };

    const handleClick37 = event => {

        window.location.href = '/riskassessment'
    };

    const handleClick38 = event => {

        window.location.href = '/aspectimpact'
    };

    const handleClick39 = event => {

        window.location.href = '/criticalarea'
    };
    const handleClick40 = event => {

        window.location.href = '/auditchecklist'
    };
    const handleClick41 = event => {

        window.location.href = '/auditchecklistfire'
    };

    const handleClick42 = event => {

        window.location.href = '/minutesofmeeting'
    };
    const handleClick43 = event => {

        window.location.href = '/firesafetyreadiness'
    };
    const handleClick44 = event => {

        window.location.href = "/tabbar/?name=" + "23.TRI AND LTI FR";

    };
    const handleClick45 = event => {

        window.location.href = "/tabbar/?name=" + "24.EHS Trainings";

    };

    const handleClick46 = event => {

        window.location.href = '/dashboardesg'
    };
    const handleClick47 = event => {

        window.location.href = '/auditchecklistoffice'
    };

    const handleClick48 = event => {

        window.location.href = '/companyperformance'
    };

    const handleClick49 = event => {

        window.location.href = '/adminrejectedesg'
    };

    const handleClick50 = event => {

        window.location.href = '/usersubmissionview'
    };

    const handleClick51 = event => {

        window.location.href = '/ehsadminview'
    };
    const handleClick52 = event => {

        window.location.href = '/userregistration'
    };

    const handleClick53 = event => {

        window.location.href = '/userlist'
    };

    const handleClick54 = event => {

        window.location.href = '/usersubmittedesg'
    };

    const handleClick55 = event => {

        window.location.href = '/adminrejectedehs'
    };

    const handleClick56 = event => {

        window.location.href = '/usersubmittedehs'
    };

    const handleClick57 = event => {

        window.location.href = '/riskregister'
    };


    const handleClick58 = event => {

        window.location.href = "/tabbar/?name=" + "15.CO2 Emissions(Scope3)"
    };

    const handleClick59 = event => {

        window.location.href = "/multilocationselection"
    };

    const handleClick60 = event => {

        window.location.href = "/changepassword"
    };
    const handleClick61 = event => {

        window.location.href = "/yoycomparison"
    };
    const handleClick62 = event => {

        window.location.href = "/cumilativedata"
    };
    return (

        <>

            <div id="header" style={{ zIndex: 9999 }} >

                <span className="font-link" >

                    {/* collapsed props to change menu size using menucollapse state */}
                    <ProSidebar collapsed={menuCollapse} >

                        <SidebarHeader>
                            <div className="logotext">
                                {/* small and big change using menucollapse state */}
                                {/* <p>{menuCollapse ? <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/logo.ico`} alt='logo'/>: <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/logo.ico`} alt="logo"/>}</p> */}
                            </div>
                            <div className="close" onClick={menuIconClick}>
                                {/* changing menu collapse icon on click */}
                                {menuCollapse ? (
                                    <FiArrowRightCircle />
                                ) : (
                                        <FiArrowLeftCircle />
                                    )}
                            </div>
                        </SidebarHeader>

                        <Scrollbars autoHeight={true}
                            autoHeightMax={550}
                        >
                            <SidebarContent >
                                <Menu iconShape="square">
                                    <MenuItem active={true} onClick={handleClick0} icon={<FiHome />}>
                                        Home
              </MenuItem>
                                    <SubMenu title="Dashboard" icon={<FaAtlassian />}>



                                        {/*<NavLink style={{ fontSize: '12px', color: 'white', fontWeight: 'bold' }} tag={Link} to="/companyperformance" >Company Performance</NavLink>*/}


                                        {/*<NavLink style={{ fontSize: '12px', color: 'white' ,fontWeight: 'bold'}} tag={Link} to="/dashboardesg" >Dashboard ESG</NavLink>*/}


                                        {/*    <NavLink style={{ fontSize: '12px', color: 'white',fontWeight:'bold' }} tag={Link} to="/dashboardehs">Dashboard EHS</NavLink>*/}

                                        <SubMenu title="Company Performance" onClick={handleClick48} icon={<FiActivity />}>


                                        </SubMenu>
                                        <SubMenu title="Dashboard ESG" onClick={handleClick46} icon={<FiActivity />}>


                                        </SubMenu>
                                        <SubMenu title="Dashboard EHS" onClick={handleClick35} icon={<FiAirplay />}>


                                        </SubMenu>
                                        {(utype == 4) ?
                                            <SubMenu title="YOY Comparison" onClick={handleClick61} icon={<FiAirplay />}>


                                            </SubMenu> : null}

                                    </SubMenu>

                                    {(utype == 1) ? <SubMenu title="Pending List" icon={<FaList />}>
                                        <SubMenu onClick={handleClick50} title="ESG" icon={<FaList />}> </SubMenu>
                                        <SubMenu onClick={handleClick51} title="EHS" icon={<FaList />}>  </SubMenu>

                                    </SubMenu>


                                        : <SubMenu title="ESG Input" icon={<FaList />}>

                                            <SubMenu title="Raw Material" icon={<FaList />}>

                                                <SubMenu onClick={handleClick} title="Raw Material Used" icon={<FaList />}>


                                                </SubMenu>

                                                <SubMenu onClick={handleClick16} title="SFC Labelled Material" icon={<FaList />}>


                                                </SubMenu>

                                            </SubMenu>

                                            <SubMenu title="Energy" icon={<FaList />}>
                                                <SubMenu style={{ width: '400px', fontsize: '10px' }} onClick={handleClick1} title="Non Renewable Fuels Consumed" icon={<FaList />}>

                                                </SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick2} title="Non Renewable Energy Purchased" icon={<FaList />}>

                                                </SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick3} title="Renewable Energy Consumption " icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick9} title=" Non Renewable Energy Consumption " icon={<FiActivity />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick11} title="Energy Intensity" icon={<FiHeart />}></SubMenu>

                                            </SubMenu>

                                            <SubMenu title="Water" icon={<FaList />}>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick4} title="Water Withdrawal By Source" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick5} title="Water Recycled and Reused" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick10} title="Water Discharge" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick6} title="Rain Water Harvesting" icon={<FiHeart />}></SubMenu>
                                            </SubMenu>


                                            <SubMenu title="Emission" icon={<FaList />}>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick7} title="Direct GHG Emissions (Scope1)" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick8} title="Direct GHG Emissions (Scope2)" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick58} title="CO2 Emissions (Scope3)" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick13} title="NOx, SOx Emissions" icon={<FiHeart />}></SubMenu>
                                            </SubMenu>

                                            <SubMenu title="Waste Management" icon={<FaList />}>

                                                {/*<SubMenu style={{ width: '400px' }} onClick={handleClick11} title="Discharged Water Quality" icon={<FiHeart />}></SubMenu>*/}
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick12} title="Waste by Type and Disposal Method" icon={<FiHeart />}></SubMenu>
                                                {/* <SubMenu style={{ width: '400px' }} onClick={handleClick13} title="Significant Spills" icon={<FiHeart />}></SubMenu> */}
                                            </SubMenu>

                                            <SubMenu title="Compliance" icon={<FaList />}>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick14} title="Legal Compliance - Environmental" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick18} title="Legal Compliance - Safety" icon={<FiHeart />}></SubMenu>

                                            </SubMenu>


                                            <SubMenu title="Expenditure" icon={<FaList />}>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick20} title="Expenditure on EHS" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick19} title="Cost Avoidance" icon={<FiHeart />}></SubMenu>

                                            </SubMenu>

                                            <SubMenu title="Safety MIS" icon={<FaAirbnb />}>

                                                <SubMenu style={{ width: '400px' }} onClick={handleClick15} title="Work Related Fatalities" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick17} title="Occupational Illness Issues" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick44} title="TRI & LTI FR" icon={<FiHeart />}></SubMenu>
                                                <SubMenu style={{ width: '400px' }} onClick={handleClick45} title="EHS Training" icon={<FiHeart />}></SubMenu>


                                            </SubMenu>

                                        </SubMenu>}
                                    {(utype == 1) ? <SubMenu title="Resubmitted List" icon={<FaAnchor />}>
                                        <SubMenu onClick={handleClick54} title="ESG" icon={<FaList />}> </SubMenu>
                                        <SubMenu onClick={handleClick56} title="EHS" icon={<FaList />}>  </SubMenu>

                                    </SubMenu> :
                                        <SubMenu title="EHS Input" icon={<FaAdjust />}>

                                            <SubMenu onClick={handleClick21} title="Safety Walk Check Sheet" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick22} title="Mock Drill Record" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick23} title="Incident/Near Miss Report" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick24} title="Preliminary Fire Investigation" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick25} title="Accident Report" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick26} title="Monthly EHS Report" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick27} title="Incident Investigation Report" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick36} title="Fire Extinguisher" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick37} title="Risk Assessment" icon={<FaList />}> </SubMenu>
                                            {/* <SubMenu onClick={handleClick38} title="Aspect and Impact" icon={<FaList />}> </SubMenu> */}
                                            <SubMenu onClick={handleClick39} title="Critical Area Fire Monitoring" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick42} title="Minutes Of Meeting" icon={<FaMeetup />}></SubMenu>
                                            <SubMenu onClick={handleClick43} title="Fire Safety Readiness" icon={<FaSafari />}></SubMenu>
                                            <SubMenu onClick={handleClick57} title="Risk Register" icon={<FaSafari />}></SubMenu>





                                        </SubMenu>

                                    }


                                    {(utype == 1) ? null : <SubMenu title="Work Permit" icon={<FaAnchor />}>

                                        <SubMenu onClick={handleClick28} title="Hot Work Permit" icon={<FaList />}> </SubMenu>
                                        <SubMenu onClick={handleClick29} title="General Work Permit" icon={<FaList />}> </SubMenu>
                                        <SubMenu onClick={handleClick30} title="Work At Height Permit" icon={<FaList />}> </SubMenu>
                                        <SubMenu onClick={handleClick31} title="Electrical Work Permit" icon={<FaList />}> </SubMenu>


                                    </SubMenu>}

                                    {(utype == 1) ? null :
                                        (userroleid == 1 || userroleid == 4) ?
                                            <SubMenu title="Audit Checklist" icon={<FaRegHeart />}>

                                                <SubMenu onClick={handleClick40} title="Electrical" icon={<FaList />}> </SubMenu>
                                                <SubMenu onClick={handleClick41} title="Fire" icon={<FaList />}>  </SubMenu>
                                            </SubMenu> :
                                            <SubMenu title="Audit Checklist" icon={<FaRegHeart />}>
                                                <SubMenu onClick={handleClick47} title="Office" icon={<FaList />}>  </SubMenu>
                                            </SubMenu>}
                                    {(utype == 2) ?
                                        <SubMenu title="Admin Rejected" icon={<BiAlarm />}>

                                            <SubMenu onClick={handleClick49} title="ESG" icon={<FaList />}></SubMenu>
                                            <SubMenu onClick={handleClick55} title="EHS" icon={<FaList />}></SubMenu>

                                        </SubMenu> :
                                        <SubMenu title="Summary List" icon={<FaSlack />}>
                                            <SubMenu onClick={handleClick32} title="ESG" icon={<FaList />}> </SubMenu>
                                            <SubMenu onClick={handleClick33} title="EHS" icon={<FaList />}>  </SubMenu>

                                        </SubMenu>}
                                    {(utype == 4) ?
                                        <MenuItem onClick={handleClick62} icon={<FiAirplay />}>
                                            Cumilative Data

                                      </MenuItem> : null}
                                    {(utype == 4) ? <SubMenu title="Users" icon={<FaUsers />}>
                                        <SubMenu onClick={handleClick52} title="User Registration" icon={<FaList />}> </SubMenu>
                                        <SubMenu onClick={handleClick53} title="User List" icon={<FaList />}> </SubMenu>
                                        <SubMenu onClick={handleClick59} title="Multi Location Admin" icon={<FaList />}> </SubMenu>
                                    </SubMenu> : null

                                    }


                                    <MenuItem onClick={handleClick60} icon={<BiPencil />}>Change Password</MenuItem>
                                </Menu>
                            </SidebarContent>

                            <SidebarFooter>
                                <Menu iconShape="square">
                                    <MenuItem onClick={handleClick34} icon={<FiLogOut />}>Logout</MenuItem>
                                </Menu>
                            </SidebarFooter>
                        </Scrollbars>
                    </ProSidebar>
                </span>

            </div>

            <div id="main" style={{ zIndex: 1 }}>
                <Layout>

                    <Route exact path='/' component={Login} />
                    <Route path='/home' component={Home} />
                    <Route path='/counter' component={Counter} />
                    <Route path='/fetch-data' component={FetchData} />
                    <Route path='/rawmaterial' component={Rawmaterial} />
                    <Route path='/deconsumption' component={Deconsumption} />
                    <Route path='/ieconsumption' component={Ieconsumption} />
                    <Route path='/reconsumption' component={Reconsumption} />
                    <Route path='/waterwithdrawal' component={Waterwithdrawal} />
                    <Route path='/waterrecycled' component={Waterrecycled} />
                    <Route path='/rwharvesting' component={Rwharvesting} />
                    <Route path='/ozonedepletion' component={Ozonedepletion} />
                    <Route path='/airemissions' component={Airemissions} />
                    <Route path='/waterdischarge' component={Waterdischarge} />
                    <Route path='/waterquality' component={Waterquality} />
                    <Route path='/significantspills' component={Significantspills} />
                    <Route path='/legalcompliance' component={Legalcompliance} />
                    <Route path='/rawmaterialview' component={Rawmaterialview} />
                    <Route path='/carbonemission' component={Carbonemission} />
                    <Route path='/Totalexpenditure' component={Totalexpenditure} />
                    <Route path='/injuriesfatalities' component={Injuriesfatalities} />
                    <Route path='/misproduction' component={Misproduction} />
                    <Route path='/misfireaccident' component={Misfireaccident} />
                    <Route path='/missuggestions' component={Missuggestion} />
                    <Route path='/mismedical' component={Mismedical} />
                    <Route path='/wastebytd' component={Wastebytd} />
                    <Route path='/deconsumptionview' component={Deconsumptionview} />
                    <Route path='/ieconsumptionview' component={Ieconsumptionview} />
                    <Route path='/editrawmaterial' component={Editrawmaterial} />
                    <Route path='/editdeconsumption' component={Editdeconsumption} />
                    <Route path='/editieconsumption' component={Editieconsumption} />
                    <Route path='/rawmaterialmaster' component={Rawmaterialmaster} />
                    <Route path='/deconsumptionmaster' component={Deconsumptionmaster} />
                    <Route path='/ieconsumptionmaster' component={Ieconsumptionmaster} />
                    <Route path='/editrawmaterialmaster' component={Editrawmaterialmaster} />
                    <Route path='/editdeconsumptionmaster' component={Editdeconsumptionmaster} />
                    <Route path='/editieconsumptionmaster' component={Editieconsumptionmaster} />
                    <Route path='/rawmaterialmasterentry' component={Rawmaterialmasterentry} />
                    <Route path='/deconsumptionmasterentry' component={Deconsumptionmasterentry} />
                    <Route path='/ieconsumptionmasterentry' component={Ieconsumptionmasterentry} />
                    <Route path='/usersubmissionview' component={UserSubmissionview} />
                    <Route path='/example' component={Example} />
                    {/*  <Route path='/dashboard' component={Dashboardrender} /> */}

                    <Route path='/unauthorized' component={Unauthorized} />
                    <Route path='/tabbar' component={Tabbar} />
                    <Route path='/energyview' component={Tabbarview} />
                    <Route path='/reconsumptionview' component={Reconsumptionview} />
                    <Route path='/editreconsumption' component={Editreconsumption} />
                    <Route path='/waterwithdrawalview' component={Waterwithdrawalview} />
                    <Route path='/editwaterwithdrawal' component={Editwaterwithdrawal} />
                    <Route path='/approvalmodal' component={Approvalmodal} />
                    <Route path='/waterrecycledview' component={Waterrecycledview} />
                    <Route path='/editwaterrecycled' component={Editwaterrecycled} />
                    <Route path='/ozonedepletionview' component={Ozonedepletionview} />
                    <Route path='/editozonedepletion' component={Editozonedepletion} />
                    <Route path='/rwharvestingmasterentry' component={Rwharvestingmasterentry} />
                    <Route path='/rwharvestingmaster' component={Rwharvestingmaster} />
                    <Route path='/editrwharvestingmaster' component={Editrwharvestingmaster} />
                    <Route path='/rwharvestingview' component={Rwharvestingview} />
                    <Route path='/editrwharvesting' component={Editrwharvesting} />
                    <Route path='/emissionmasterentry' component={Emissionmasterentry} />
                    <Route path='/emissionmaster' component={Emissionmaster} />
                    <Route path='/editemissionmaster' component={Editemissionmaster} />
                    <Route path='/emission' component={Emission} />
                    <Route path='/emissionview' component={Emissionview} />
                    <Route path='/editemission' component={Editemission} />
                    <Route path='/waterdischargeview' component={Waterdischargeview} />
                    <Route path='/editwaterdischarge' component={Editwaterdischarge} />
                    <Route path='/waterqualityview' component={Dischargedwaterqualityview} />
                    <Route path='/editwaterquality' component={Editwaterquality} />
                    <Route path='/significantspillsview' component={Significantspillsview} />
                    <Route path='/editsignificantspills' component={Editsignificantspills} />
                    <Route path='/legalcomplianceview' component={Legalcomplianceview} />
                    <Route path='/editlegalcompliance' component={Editlegalcompliance} />
                    <Route path='/carbonemissionview' component={Carbonemissionview} />
                    <Route path='/editcarbonemission' component={Editcarbonemission} />
                    <Route path='/totalexpenditureview' component={Totalexpenditureview} />
                    <Route path='/edittotalexpenditure' component={Edittotalexpenditure} />
                    <Route path='/injuriesfatalitiesview' component={Injuriesfatalitiesview} />
                    <Route path='/editinjuriesfatalities' component={Editinjuriesfatalities} />
                    <Route path='/misproductionview' component={Misproductionview} />
                    <Route path='/editmisproduction' component={Editmisproduction} />
                    <Route path='/misfireaccidentview' component={Misfireaccidentview} />
                    <Route path='/editmisfireaccident' component={Editmisfireaccident} />
                    <Route path='/missuggestionview' component={Missuggestionview} />
                    <Route path='/editmissuggestion' component={Editmissuggestion} />
                    <Route path='/mismedicalview' component={Mismedicalview} />
                    <Route path='/editmismedical' component={Editmismedical} />
                    <Route path='/adminhistory' component={Adminhistory} />
                    <Route path='/mockdrill' component={Mockdrill} />
                    <Route path='/nearmiss' component={Nearmiss} />
                    <Route path='/fireinvestigating' component={Fireinvesting} />
                    <Route path='/safetywalk' component={Safetywalk} />
                    <Route path='/accidentreport' component={Accidentreport} />
                    <Route path='/monthlyehs' component={Accidentstatistics} />
                    <Route path='/incidentinvestigation' component={Incidentinvestigation} />
                    <Route path='/workpermit' component={Workpermit} />

                    <Route path='/workpermitcontent' component={Workpermitcontent} />
                    <Route path='/permitheight' component={Permitheight} />
                    <Route path='/electricpermit' component={Electricpermit} />
                    <Route path='/userregistration' component={Userregistration} />
                    <Route path='/userlist' component={Userlist} />
                    <Route path='/editusers' component={Editusers} />
                    <Route path='/locationmaster' component={Locationmaster} />
                    <Route path='/editlocationmaster' component={Editlocationmaster} />
                    <Route path='/modal' component={modal} />
                    <Route path='/mockdrillview' component={Mockdrillview} />
                    <Route path='/editmockdrill' component={Editmockdrill} />
                    <Route path='/auditchecklist' component={AuditChecklist} />
                    <Route path='/auditchecklistfire' component={Auditchecklistfire} />
                    <Route path='/dashboardrender' component={Dashboardrender} />
                    <Route path='/regulatorview' component={Regulatorview} />
                    <Route path='/dashboardnew' component={Dashboardnew} />
                    <Route path='/dashboardehs' component={Dashboardehs} />
                    <Route path='/homepage' component={Homepage} />
                    <Route path='/fireextinguisher' component={Fireextenguisher} />
                    <Route path='/safetywalkview' component={Safetywalkview} />
                    <Route path='/editsafety' component={Editsafety} />
                    <Route path='/riskassessment' component={Riskassessment} />

                    <Route path='/criticalarea' component={Criticalarea} />
                    <Route path='/nearmissview' component={Nearmissview} />
                    <Route path='/editnearmiss' component={Editnearmiss} />
                    <Route path='/fireinvestigatingview' component={Fireinvestingview} />
                    <Route path='/editfireinvesting' component={Editfireinvesting} />
                    <Route path='/fireextinguisherview' component={Fireextinguisherview} />
                    <Route path='/editfireextinguisher' component={Editfireextinguisher} />
                    <Route path='/riskassessmentview' component={Riskassessmentview} />
                    <Route path='/editriskassessment' component={Editriskassessment} />
                    <Route path='/monthlyehsview' component={Accidentstatisticsview} />
                    <Route path='/editstatistics' component={Editstatistics} />
                    <Route path='/editawards' component={Editawards} />
                    <Route path='/edittraining' component={Edittraining} />
                    <Route path='/editinitiatives' component={Editinitiatives} />
                    <Route path='/incidentinvestigationview' component={Incidentinvestigationview} />
                    <Route path='/editinjuredemployee' component={Editinjuredemployee} />
                    <Route path='/editincidentdescription' component={Editincidentdescription} />
                    <Route path='/editincidenthappen' component={Editincidenthappen} />
                    <Route path='/editfutureincidents' component={Editfutureincident} />
                    <Route path='/editreviewedby' component={Editreviewedby} />
                    <Route path='/accidentreportview' component={Accidentreportview} />
                    <Route path='/editaccidentreport' component={Editaccidentreport} />
                    <Route path='/criticalareaview' component={criticalareaview} />
                    <Route path='/editcriticalarea' component={editcriticalarea} />
                    <Route path='/auditchecklistview' component={Auditchecklistview} />
                    <Route path='/editauditchecklist' component={Editauditchecklist} />
                    <Route path='/auditchecklistfireview' component={Auditchecklistfireview} />
                    <Route path='/editauditchecklistfire' component={Editauditchecklistfire} />
                    <Route path='/ehsadminview' component={EHSFormSubmission} />
                    <Route path='/minutesofmeeting' component={MinutesOfMeeting} />
                    <Route path='/minutesofmeetingview' component={Minutesofmeetingview} />
                    <Route path='/editoldstatus' component={Editoldstatus} />
                    <Route path='/editoldpoints' component={Editoldpoints} />
                    <Route path='/editnewpoints' component={Editnewpoints} />
                    <Route path='/firesafetyreadiness' component={FireSafetyReadiness} />
                    <Route path='/firesafetyreadinessview' component={Firesafetyreadinessview} />
                    <Route path='/editfiresafetyreadiness' component={Editfiresafetyreadiness} />
                    <Route path='/energyintensity' component={Energyintensity} />
                    <Route path='/directghgemission2' component={Directghgemission2} />
                    <Route path='/directghgemission1' component={Directghgemission1} />
                    <Route path='/sfclabelledmaterial' component={Sfclabelledmaterial} />
                    <Route path='/legalcomplianceoccupational' component={Legalcomplianceoccupational} />
                    <Route path='/costavoidance' component={Costavoidance} />
                    <Route path='/workrelatedfatalities' component={Workrelatedfatalities} />
                    <Route path='/othersignificant' component={OtherSignificant} />
                    <Route path='/nonreconsumption' component={Nonreconsumption} />
                    <Route path='/triandltifr' component={Triandltifr} />
                    <Route path='/ehstraining' component={Ehstraining} />
                    <Route path='/energyintensityview' component={Energyintensityview} />
                    <Route path='/editenergyintensity' component={Editenergyintensity} />
                    <Route path='/directghgemission1view' component={Directghgemission1view} />
                    <Route path='/editdirectghgemission1' component={Editdirectghgemission1} />
                    <Route path='/directghgemission2view' component={Directghgemission2view} />
                    <Route path='/editdirectghgemission2' component={Editdirectghgemission2} />
                    <Route path='/othersignificantview' component={Othersignificantview} />
                    <Route path='/editothersignificant' component={Editothersignificant} />
                    <Route path='/sfclabelledmaterialview' component={Sfclabelledmaterialview} />
                    <Route path='/editsfclabelledmaterial' component={Editsfclabelledmaterial} />
                    <Route path='/legalcomplianceoccupationalview' component={Legalcomplianceoccupationalview} />
                    <Route path='/editlegalcomplianceoccupational' component={Editlegalcomplianceoccupational} />
                    <Route path='/costavoidanceview' component={Costavoidanceview} />
                    <Route path='/editcostavoidance' component={Editcostavoidance} />
                    <Route path='/workrelatedfatalitiesview' component={Workrelatedfatalitiesview} />
                    <Route path='/editworkrelatedfatalities' component={Editworkrelatedfatalities} />
                    <Route path='/triandltifrview' component={Triandltifrview} />
                    <Route path='/edittriandltifr' component={Edittriandltifr} />
                    <Route path='/ehstrainingview' component={Ehstrainingview} />
                    <Route path='/editehstraining' component={Editehstraining} />
                    <Route path='/nonreconsumptionview' component={Nonreconsumptionview} />
                    <Route path='/editnonreconsumption' component={Editnonreconsumption} />
                    <Route path='/auditchecklistoffice' component={Auditchecklistoffice} />
                    <Route path='/dashboardesg' component={Dashboard} />
                    <Route path='/auditofficeview' component={AuditOfficeView} />
                    <Route path='/editauditoffice' component={Editauditoffice} />
                    <Route path='/companyperformance' component={Companyperformance} />
                    <Route path='/adminhistoryehs' component={Adminhistoryehs} />
                    <Route path='/wastebytdview' component={Wastebytdview} />
                    <Route path='/editwastebytd' component={Editwastebytd} />
                    <Route path='/adminrejectedesg' component={Adminrejectedesg} />
                    <Route path='/usersubmittedesg' component={Usersubmittedesg} />
                    <Route path='/adminrejectedehs' component={Adminrejectedehs} />
                    <Route path='/usersubmittedehs' component={Usersubmittedehs} />
                    <Route path='/riskregister' component={Riskregister} />
                    <Route path='/riskregisterview' component={Riskregisterview} />
                    <Route path='/editriskregister' component={Editriskregister} />
                    <Route path='/editstatisticscontractor' component={Editstatisticscontractor} />
                    <Route path='/workpermitview' component={Workpermitview} />
                    <Route path='/workpermitcontentview' component={Workpermitcontentview} />
                    <Route path='/permitheightview' component={Permitheightview} />
                    <Route path='/electricpermitview' component={Electricpermitview} />
                    <Route path='/editworkpermit' component={Editworkpermit} />
                    <Route path='/editworkpermitcheck' component={Editworkpermitcheck} />
                    <Route path='/editworkpermitcontent' component={Editworkpermitcontent} />
                    <Route path='/editpermitheight' component={Editpermitheight} />
                    <Route path='/multilocationselection' component={Multilocationselection} />
                    <Route path='/forgotpassword' component={ForgotPassword} />
                    <Route path='/changepassword' component={ChangePassword} />
                    <Route path='/yoycomparison' component={LandingPage} />
                    <Route path='/cumilativedata' component={CumilativeData} />














































                </Layout>
            </div>
        </>


    );
};

export default Header;