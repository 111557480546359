import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'
import TextField from '@material-ui/core/TextField';
import { Table } from 'react-bootstrap';
import { alignPropType } from 'react-bootstrap/esm/DropdownMenu';
import Deconsumptionmasterentry from './Deconsumptionmasterentry';



class Deconsumptionmaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            id: 0,
            FuelType: '',
            Unit: '',
            DensityNtp: '',
            CalorificFuelValue:''
           
        };
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var utype = localStorage.getItem('utype')
        if (utype == 3) {
            this.props.history.push('/unauthorized')

        }
       
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/DirectEnergyMaster/GetDirectEnergyMaster').then(response => {
            console.log(response.data);
            this.setState({
                rows: response.data
            });
        });
    }  

    edit(idx) {


        this.props.history.push("/editdeconsumptionmaster/?id=" + idx);
    }



    handleRemoveSpecificRow = (id) => (e) => {

        e.preventDefault();
        alert("Master Deletion Not Allowed");
        {/*  axios.delete(process.env.REACT_APP_GLOBAL_CODE +'/api/DirectEnergyMaster/DeleteDirectEnergyMaster', { params: { DMasterId: id } }).then(response => {
            alert("Entry Deleted Successfully");
            window.location.reload();

        }).catch(err => console.log(err));*/}
    }
    handleSubmit = event => {
      
        const { rows } = this.state;

        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        let newbody = JSON.parse(Options.body);
        console.log(newbody)
        axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/DirectEnergyMaster/SaveDirectEnergyMaster', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Data Save Successfully");
                    this.props.history.push('/rawmaterial')
                }

                else {
                    alert('Data Saved Successfully');
                    this.props.history.push('/rawmaterial')
                }
            })
    }

    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = event => {

        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;
        let value=0
        if (name == "DensityNtp" || name == "CalorificFuelVal" ) {
            value = parseInt(event.target.value);

        }
        else {
            value = event.target.value;
        }
        let rows = this.state.rows;

        let row = {
            ...rows,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (



            <Form className="font-link" onSubmit={this.handleSubmit}>
                <br />
                <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                    <path d="M7.5 1v7h1V1h-1z" />
                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                </svg></button><br /> <br />
                <Deconsumptionmasterentry /> 
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                            <th>FUEL TYPE</th>
                            <th>UNIT</th>
                            <th>DENSITY NTP</th>
                            <th>CALORIFIC VALUE</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                 
                        
                        {this.state.rows.map(item => {
                            return (

                                <tr id="addr0" key={item.id}>




                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" name="type" required id="standard-required" defaultValue={item.fuelType} />
                                    </td >
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="text" name="unit" required id="standard-required" defaultValue={item.unit} />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="number" name="densityntp" required id="standard-required" defaultValue={item.densityNtp} />
                                    </td>
                                    <td>
                                        <TextField InputProps={{ readOnly: true }} type="number" name="calorificvalue" required id="standard-required" defaultValue={item.calorificFuelValue} />
                                    </td>

                                    <td>
                                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.dmasterId)} style={{ marginRight: '8px' }}>
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>      
                                        </button>
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={this.handleRemoveSpecificRow(item.dmasterId)}>
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>


                                    </td>
                                </tr>
                            )

                        })}

                    </tbody>
                </Table>
            </Form>

        );
    }
}
export default Deconsumptionmaster    