import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
      return (
          <div id="menu" style={{ zIndex: 3 }}>
      <header >
        <Navbar className="navbar navbar-expand-lg navbar-light bg-light" light>
          <Container>
                    <NavbarBrand><p> <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/sparrow.png`} alt='logo' width="100" height="100"/></p></NavbarBrand>
                          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />

                          <h1 class="ribbon"  id="navribbon" style={{ marginTop: '20px',marginLeft:'40px' }}>
                              <strong style={{ fontSize: '18px', transition: '0.5', fontFamily: 'Arial, Helvetica, sans-serif',paddingLeft:'180px' }} class="ribbon-content">ESG Dashboard</strong>
                          </h1>
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">

                                 
                            
                <NavItem>
                                <NavbarBrand><p style={{ marginRight:'50px' }}> <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/industry.png`} alt='logo' width="140" height="140" /></p></NavbarBrand>
                                
                </NavItem>
                
              </ul>
            </Collapse>
          </Container>
        </Navbar>
              </header>
              </div>
    );
  }
}
