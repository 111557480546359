import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'
import TextField from '@material-ui/core/TextField';
import { Table } from 'react-bootstrap';
import { alignPropType } from 'react-bootstrap/esm/DropdownMenu';



class Rwharvestingmasterentry extends Component {
    constructor(props) {
        super(props);
        this.state = {

            type: '',
            unit: ''

        };
    }


    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
    }

    handleSubmit = event => {

        const { rows } = this.state;

        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        let newbody = JSON.parse(Options.body);
        console.log(newbody)
        axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/RainHarvestMaster/SaveRainHarvestMaster', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Data Save Successfully");
                    this.props.history.push('/rwharvestingmaster')
                }

                else {
                    alert('Data Saved Successfully');
                    this.props.history.push('/rwharvestingmaster')
                }
            })
    }


    handleChange = event => {

        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        var value = event.target.value;
        let rows = this.state.rows;
        let row = {

            ...rows,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (



            <Form className="font-link" onSubmit={this.handleSubmit}>

                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                            <th>HARVEST TYPE</th>
                            <th>UNIT</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <TextField required type="text" name="HarvetingType" id="HarvetingType" defaultValue={this.state.HarvetingType} onChange={this.handleChange} />
                        </td>
                        <td>
                            <TextField required type="text"  name="unit" id="unit" defaultValue={this.state.unit} onChange={this.handleChange} />

                        </td>
                        <td>
                            <button type="submit" style={{ float: 'center' }} className="btn btn-success">SAVE</button>
                        </td>
                    </tr>
                </Table>
            </Form>

        );
    }
}

export default Rwharvestingmasterentry        