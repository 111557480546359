import { maxWidth } from 'devexpress-dashboard/model/index.metadata';
import React, { Component } from 'react'
import { Modal, Button } from "react-bootstrap";

import '../index.css';




class Homepage extends Component {

    constructor(props) {
        super(props)
        
       

        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            isOpen: false,
            path: process.env.PUBLIC_URL +'/assets/EHS Policy.pdf',
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            check: "",
            
        }
    }

    componentDidMount() {
      
    
        var loggedin = sessionStorage.getItem('LoggedIn')
       localStorage.setItem("homepage",true)
     
        
        if (loggedin == null) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";

        
    }



    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });




    render() {
      
        return (
           

             
            <div className="font-link" >
                <h4 style={{ backgroundColor: '#306754', textAlign: 'center', color: 'white', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>Welcome to Online EHS Management System </h4>
                {/*<button type="submit" style={{ float: 'right', backgroundColor: '#ffffff00',color:'black'}} className="btn btn-success" onClick={this.openModal}>Our Policy</button>*/}

                {/*<Modal size="lg" style={{ backgroundColor: '#ffffff00',width:'800px', borderColor: '#ffffff00' }}*/}

                {/*    show={this.state.isOpen} onHide={this.closeModal}>*/}
                  
                {/*        <Modal.Header closeButton>*/}
                {/*            <Modal.Title></Modal.Title>*/}
                {/*        </Modal.Header>*/}
                {/*        <Modal.Body>*/}
                           
                {/*            <embed src={this.state.path} width="780px" height="500"*/}
                {/*                type="application/pdf" />*/}
                {/*        </Modal.Body>*/}
                       
                   
                {/*</Modal>*/}
                <br />
                <br />
                <h5 className='no-space-break' id="leaders" style={{ backgroundColor: '#306754', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Vision Statement</h5>
                <div id='div5' style={{ marginTop: '40px' }}>
                    <div class='box' id='box' ><img className="img-fluid1" src={`${process.env.PUBLIC_URL}/assets/image.png`} alt='Leadership' width="100%" height="100%" />
                        {/*<h1 class="ribbon" id="ribbon" style={{ marginLeft: '18px' }}>*/}
                        {/*    <strong style={{ fontSize: '16px', transition: '0.5', fontFamily: 'Arial, Helvetica, sans-serif' }} class="ribbon-content">Mr. Sivakumar Sundaram</strong><br /><strong style={{ fontSize: '12px', transition: '0.5', fontFamily: 'Arial, Helvetica, sans-serif' }} class="ribbon-content">Chairman, Executive Committee</strong>*/}
                        {/*</h1>*/}

                    </div>
                </div>
                
                <div id='div6'>
                
                    
                   
                    <div id="div7" style={{ paddingLeft: '10px', paddingRight: '10px',paddingTop:'40px', fontSize: '14px', fontFamily: 'Arial', fontWeight: 'bold', color:'#36454F' }}>
                        We are committed to protecting the environment, promoting health and safety, and achieving sustainability. Our vision is to be a leader in sustainable practices, setting an example for our industry and contributing to a better future for all.<br /><br />
                         We will achieve our vision by continuously improving our operations to minimize our environmental impact, reduce waste and pollution, and promote the use of renewable energy sources. We will prioritize the health and safety of our employees and customers, providing a safe and healthy workplace and promoting wellness in all aspects of our operations.<br /><br />
                        We will also work with our suppliers and partners to promote sustainable practices throughout our supply chain, and we will engage with our communities to promote sustainability and contribute to the well-being of society.<br /><br />
                        By living this vision, we will create long-term value for our stakeholders, contribute to a more sustainable future, and leave a positive impact on the world."
                    </div>
                    <br />
                    </div>
                    </div>
              
        )
    }
}

export default Homepage