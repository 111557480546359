import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";
import { autoExpand } from 'devexpress-dashboard/model/index.metadata';

class Auditchecklistfire extends Component {

    constructor(props) {
        super(props)

        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();

        this.state = {
            isOpen8: true,
            isOpen9: false,
            isOpen10: false,
            isOpen11: false,
            isOpen12: false,
            isOpen13: false,
            isOpen14: false,
            isOpen15: false,
            isOpen16: false,
            isOpen17: false,
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            program1: 'Fire',
            program2: 'Ehs',
            program3: 'Safety',
            program4: 'Others',
            type1: 'Yes',
            type2: 'No',
            type3: 'Open',
            type4: 'Closed',
            type5: 'External',
            rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            rows1: [],
            rows2: [],

            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            admincode: localStorage.getItem('admincode'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear:prevYear
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);

        var auditchecklistfire = JSON.parse(localStorage.getItem("auditchecklistfire"));
        var rows = this.state.rows;

        if (auditchecklistfire != null) {
            rows = auditchecklistfire

            this.setState({
                rows
            })
        } 

    }



    handleChange = (idx) => (e) => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        const value = e.target.value;

        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows[idx],

            [name]: value,
            "FireId": idx+1,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[idx] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],

            "FormId": 13,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });


    };





    handleAddRow = () => (event) => {
        
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {
        
        event.preventDefault();

        const { rows } = this.state;

        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/AuditPlantFire/GetPlantFireByMonth', { params: { Month: prevMonth, Year: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows2: response.data
                })

            })

        var rows2 = this.state.rows2
        if (rows2.length == 0) {


            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };

            var newbody = JSON.parse(Options.body);
            const { rows1 } = this.state;


            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let checkbody = JSON.parse(Options1.body);

            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/AuditPlantFire/SavePlantFireList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

            ]).then(axios.spread((response1, response2) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));
        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }


     



    }

    handleSubmit1 = event => {
        debugger;
        event.preventDefault()
        const { rows } = this.state;
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };


        localStorage.setItem('auditchecklistfire', Options.body)

    }


    toggle = (index) => {
        let collapse = "isOpen" + index;

        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }

    handleClick1 = () => (event) => {

        this.props.history.push("/auditchecklistview");    
    };


    render() {

        return (


            <div className="app">
                <h4 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px', width: '1080px' }}>Audit Checklist</h4>
                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px', width: '1080px' }}>Internal Audit Checklist: Electrical</h5>

                <br />
                <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                <br />
               
                <button style={{ backgroundColor: '#00215C', color: 'white' }}
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen8
                    })}
                    onClick={() => this.toggle(8)}
                >
                    <span className="app__toggle-text">FIRE SAFETY AUDIT CHECK SHEET</span>
                    <div style={{ color: 'white' }} className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen8 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen8}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen8 ? "app__collapse--active" : "")
                    }
                >
                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen9
                        })}
                        onClick={() => this.toggle(9)}
                    >
                        <span className="app__toggle-text">FIRE PREVENTION & PROTECTION ARRANGEMENTS </span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen9 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    <Collapse
                        isOpen={this.state.isOpen9}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen9 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr0" key={0}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is fire hydrant system provided for the facility?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire hydrant system - internal & external should be provided in the facility' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(0)}
                                                    value={this.state.rows[0].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(0)} value={this.state.rows[0].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(0)}
                                                    value={this.state.rows[0].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr1" key={1}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are hose reel provided for the facility? ' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire hose reels to be provided in the facility' />
                                        </td >
                                        <td>
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(1)}
                                                    value={this.state.rows[1].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Observations} />
                                        </td>
                                        <td>
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Plan} />
                                        </td>
                                        <td>
                                            <br />




                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Responsibility} />
                                        </td>
                                        <td>

                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].Timelines} />
                                        </td>
                                        <td>
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(1)}
                                                    value={this.state.rows[1].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr2" key={2}>




                                        <td>
                                            <br />

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is sprinkler system installed in the facility? What is the pressure maintained in the pipelines?' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Sprinkler system should be provided in warehouses and storage areas and pressure should be maintained.' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(2)}
                                                    value={this.state.rows[2].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].Timelines}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(2)}
                                                    value={this.state.rows[2].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr3" key={3}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='What is the capacity of the underground & overhead fire water tank?' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire water storage should be available as per NBC codes /local statutory norms  ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(3)}
                                                    value={this.state.rows[3].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(3)}
                                                    value={this.state.rows[3].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr4" key={4}>




                                        <td>

                                            <br />
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is jockey pump, hydrant pump, diesel pump & sprinkler pump available in the facility & working in auto mode?' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Jockey, hydrant, diesel & sprinkler pump should be provided as per NBC codes /local statutory norms. All the pumps should be functional & remain in auto mode.' />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(4)}
                                                    value={this.state.rows[4].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].Timelines}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(4)}
                                                    value={this.state.rows[4].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>



                                    <tr id="addr5" key={5}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have fire compartmentation in chemical room, electrical room, UPS room, emergency exits, AHU room, storage area and press hall?' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire compartmentation should be done in chemical room, electrical room, UPS room, emergency exits, AHU room, storage area and press hall of 2 hrs. fire ratings.' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(5)}
                                                    value={this.state.rows[5].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(5)}
                                                    value={this.state.rows[5].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr6" key={6}>




                                        <td>
                                            <br />
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is gas-based suppression system is installed in the server and UPS rooms?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Gas based suppression system (NOVEC 1230) should be installed in the server rooms and UPS rooms ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(6)}
                                                    value={this.state.rows[6].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(6)}
                                                    value={this.state.rows[6].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>



                                </tbody>
                            </Table>
                        </Form>
                    </Collapse>
                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen10
                        })}
                        onClick={() => this.toggle(10)}
                    >
                        <span className="app__toggle-text">FIRE DETECTION AND ALARM SYSTEM </span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen10 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    <Collapse
                        isOpen={this.state.isOpen10}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen10 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>


                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr7" key={7}>




                                        <td>
                                          
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is manual operated fire alarm system installed in the facility?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Manual operated fire alarm system should be installed in the facility. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(7)}
                                                    value={this.state.rows[7].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(7)}
                                                    value={this.state.rows[7].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr8" key={8}>




                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is automatic fire detection and alarm system installed in in press hall, storage and other areas?' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Automatic fire alarm system - Smoke detectors /heat detectors/beam detectors/hooters should be installed in the facility. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(8)}
                                                    value={this.state.rows[8].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(8)}
                                                    value={this.state.rows[8].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr9" key={9}>




                                        <td>
                                            <br />

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is public address system provided and working properly? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Public address system should be provided in the premises and should be always operational.' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(9)}
                                                    value={this.state.rows[9].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Plan}  />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Responsibility}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(9)}
                                                    value={this.state.rows[9].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr10" key={10}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='What is the condition of Fire Alarm Panel (FAP) & detectors? Is the Fire Alarm Panel fully operational? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire alarm pane & fire detectors should be maintained in fully functional & working condition. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(10)}
                                                    value={this.state.rows[10].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(10)}
                                                    value={this.state.rows[10].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr11" key={11}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are response indicators provided for the smoke detectors above the false ceiling and below the raised flooring? ' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Response indicators should be provided for smoke detectors above false ceiling and below false floors' />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(11)}
                                                    value={this.state.rows[11].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(11)}
                                                    value={this.state.rows[11].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>







                                </tbody>
                            </Table>
                        </Form>
                    </Collapse>

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen11
                        })}
                        onClick={() => this.toggle(11)}
                    >
                        <span className="app__toggle-text">PORTABLE FIRE EXTINGUISHERS</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen11 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    <Collapse
                        isOpen={this.state.isOpen11}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen11 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr12" key={12}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='What is the adequacy of the portable fire extinguishers at the facility ' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Portable fire extinguishers should be provided as per IS 2190 standard,' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(12)}
                                                    value={this.state.rows[12].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(12)}
                                                    value={this.state.rows[12].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr13" key={13}>




                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are luminary signage displayed near the fire extinguishers? ' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Radium based Luminary signage for the location of portable fire extinguishers should be provided. Signage shall be providing in such a manner so that position of PFEs shall be easily identified. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(13)}
                                                    value={this.state.rows[13].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(13)}
                                                    value={this.state.rows[13].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr14" key={14}>




                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='What is the condition of the fire extinguishers? What is the inspection and monitoring schedule for the fire extinguishers?' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The pressure needle of a fire extinguisher must show adequate pressure and should be in green zone. Inspection and monitoring schedule of the fire extinguisher shall be maintained' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(14)}
                                                    value={this.state.rows[14].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(14)}
                                                    value={this.state.rows[14].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr15" key={15}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the records maintained for the inspection and testing of the fire extinguishers? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Inspection and testing schedule for the fire extinguishers shall be maintained. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(15)}
                                                    value={this.state.rows[15].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(15)}
                                                    value={this.state.rows[15].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>



                                </tbody>
                            </Table>
                        </Form>
                    </Collapse>


                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen12
                        })}
                        onClick={() => this.toggle(12)}
                    >
                        <span className="app__toggle-text">LIFE SAFETY-ESCAPE ROUTES </span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen12 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    <Collapse
                        isOpen={this.state.isOpen12}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen12 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr16" key={16}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the facility provided with alternate exit based upon the floor occupant load /no. of staffs etc.? ' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Alternate exits shall be provided as there can be case where fire may happen at exit door. Not less than two exits should be provided for every floor. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(16)}
                                                    value={this.state.rows[16].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(16)}
                                                    value={this.state.rows[16].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr17" key={17}>




                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is emergency exit & routes free of any obstruction?' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The exits routes/ pathways should be free from any obstruction.  ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(17)}
                                                    value={this.state.rows[17].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Observations}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Status} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(17)}
                                                    value={this.state.rows[17].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr18" key={18}>




                                        <td>
                                            <br />

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are fire exit doors not locked? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire exit doors should not be locked. ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(18)}
                                                    value={this.state.rows[18].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(18)}
                                                    value={this.state.rows[18].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr19" key={19}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all “EXIT” signs visible and legible?' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Exit signs shall be provided such that no point in an exit access is more than 30 from a visible exit directional sign.  Colour of exit signs shall be green.' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(19)}
                                                    value={this.state.rows[19].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].Timelines}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(19)}
                                                    value={this.state.rows[19].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr20" key={20}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is a fire evacuation plan displayed depicting the escape route and assembly point? Is assembly point located outside the building? ' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire evacuation plan depicting the escape routes, location of fire extinguisher, emergency contact numbers should be displayed for each of the area. Assembly point should be located outside the building.' />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(20)}
                                                    value={this.state.rows[20].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(20)}
                                                    value={this.state.rows[20].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>







                                </tbody>
                            </Table>
                        </Form>
                    </Collapse>

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen13
                        })}
                        onClick={() => this.toggle(13)}
                    >
                        <span className="app__toggle-text">EMERGENCY & ESCAPE LIGHTING</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen13 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    <Collapse
                        isOpen={this.state.isOpen13}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen13 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr21" key={21}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the emergency lighting powered by an independent source? ' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Emergency lightings should be powered by UPS provided in the facility ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(21)}
                                                    value={this.state.rows[21].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(21)}
                                                    value={this.state.rows[21].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr22" key={22}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='What is the minimum duration of the power backup of the emergency lighting? ' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Emergency Lightings should be provided with power backup of minimum 90 min. duration. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(22)}
                                                    value={this.state.rows[22].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />




                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(22)}
                                                    value={this.state.rows[22].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr23" key={23}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is emergency lighting provided for the all the escape routes, exit doors and assembly area? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Emergency lighting should be provided for all the escape routes, exit doors and assembly area. ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(23)}
                                                    value={this.state.rows[23].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].Timelines}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(23)}
                                                    value={this.state.rows[23].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr24" key={24}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are the emergency light fittings identified? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Clear identification has to be provided for the Emergency Light fittings.' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(24)}
                                                    value={this.state.rows[24].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Plan}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(24)}
                                                    value={this.state.rows[24].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr25" key={25}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the lighting around the premises boundary, entrance area, staircases, exit routes adequate? ' />
                                        </td >
                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Adequate lighting should be available in the premises. ' />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(25)}
                                                    value={this.state.rows[25].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />




                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(25)}
                                                    value={this.state.rows[25].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>







                                </tbody>
                            </Table>
                        </Form>
                    </Collapse>

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen14
                        })}
                        onClick={() => this.toggle(14)}
                    >
                        <span className="app__toggle-text">SIGNAGES</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen14 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    <Collapse
                        isOpen={this.state.isOpen14}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen14 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr26" key={26}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are signage displayed for the non-authorized entry in the restricted areas of the electrical installations? ' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Signage for un-authorized entry in the restricted areas of the electrical installations should be displayed.' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(26)}
                                                    value={this.state.rows[26].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Observations}/>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(26)}
                                                    value={this.state.rows[26].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr27" key={27}>




                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are luminary fire safety signages displayed?  ' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Auto glow type fire safety signage (fire exit, fire extinguisher, MCP, FHC etc.) to be provided at appropriate locations. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(27)}
                                                    value={this.state.rows[27].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(27)}
                                                    value={this.state.rows[27].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr28" key={28}>




                                        <td>
                                            <br />

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are signage displayed for the Assembly Area? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Signage should be displayed for the Assembly Area.' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(28)}
                                                    value={this.state.rows[28].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />




                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />




                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(28)}
                                                    value={this.state.rows[28].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr29" key={29}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are necessary signage (prohibitive, caution & mandatory actions) displayed for hazardous waste, machine area, storage, PPEs etc. ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All necessary signage (prohibitive, caution & mandatory actions) should be displayed for hazardous waste, machine area, storage, PPEs etc. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(29)}
                                                    value={this.state.rows[29].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(29)}
                                                    value={this.state.rows[29].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>



                                </tbody>
                            </Table>
                        </Form>
                    </Collapse>
                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen15
                        })}
                        onClick={() => this.toggle(15)}
                    >
                        <span className="app__toggle-text">SECURITY & ACCESS CONTROL</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen15 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    <Collapse
                        isOpen={this.state.isOpen15}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen15 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr30" key={30}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are there Checks & Control  in place to restrict unauthorized entry to the site? Are the proper documents (Visitor Register) maintained)? Are cigarettes, lighters and other flammable materials restricted inside the premises? ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Checks and Control should be in place to restrict any unauthorized entry in the warehouse. Visitor register should be maintained, and flammable materials should be prohibited. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(30)}
                                                    value={this.state.rows[30].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].Timelines}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(30)}
                                                    value={this.state.rows[30].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr31" key={31}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are all security personnel trained in firefighting, first aid and emergency response?' />
                                        </td >
                                        <td>
                                            <br />

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Security Personnel should be trained in firefighting and emergency response. ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(31)}
                                                    value={this.state.rows[31].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(31)}
                                                    value={this.state.rows[31].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr32" key={32}>




                                        <td>
                                            <br />

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is the list of ‘Emergency Contact Numbers’ displayed/available near the Security? What is the mode(s) of communication in cases of emergency? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='List of Emergency Contact Numbers should be available near the Security Guards. Mode of communication available could be either Cellular Phones & Landline Phone. ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(32)}
                                                    value={this.state.rows[32].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(32)}
                                                    value={this.state.rows[32].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>





                                </tbody>
                            </Table>
                        </Form>
                    </Collapse>

                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen16
                        })}
                        onClick={() => this.toggle(16)}
                    >
                        <span className="app__toggle-text">ELECTRICAL SAFETY REQUIREMENTS</span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen16 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    <Collapse
                        isOpen={this.state.isOpen16}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen16 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr33" key={33}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have electro mat placed in front of electrical panels?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Electro mat should be placed in front of electrical panels  ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(33)}
                                                    value={this.state.rows[33].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(33)}
                                                    value={this.state.rows[33].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr34" key={34}>




                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have electro mat placed in front of electrical panels?' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the electrical wiring should be laid in metal conduit and loose wiring not allowed' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(34)}
                                                    value={this.state.rows[34].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />




                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Responsibility}/>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].Timelines}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(34)}
                                                    value={this.state.rows[34].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr35" key={35}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are they keeping electrical lights, ventilation system OFF when not in use ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Electrical lights, ventilation system should remain OFF when not in use ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(35)}
                                                    value={this.state.rows[35].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].Timelines}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(35)}
                                                    value={this.state.rows[35].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr36" key={36}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have trip devices installed in the electrical system?' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the electrical circuits should be protected with MCB, ELCB.  ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(36)}
                                                    value={this.state.rows[36].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(36)}
                                                    value={this.state.rows[36].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr37" key={37}>




                                        <td>


                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have earthing protection of all the critical electrical installations? ' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the critical electrical installations should be connected with proper earthing' />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(37)}
                                                    value={this.state.rows[37].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Plan}/>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(37)}
                                                    value={this.state.rows[37].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>



                                    <tr id="addr38" key={38}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they display electric shock chart in the electrical rooms' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Electric shock chart should be displayed in the electrical rooms ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(38)}
                                                    value={this.state.rows[38].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(38)}
                                                    value={this.state.rows[38].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr39" key={39}>




                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have certified electrician in the premises?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Certified electrician should be available in the premises.' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(39)}
                                                    value={this.state.rows[39].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Responsibility}/>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(39)}
                                                    value={this.state.rows[39].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>
                                    <tr id="addr40" key={40}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they follow Lock Out & Tag Out (LOTO) procedures during shutdown & maintenance of electrical installation? ' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Lock Out & Tag Out (LOTO) procedures should be followed during shutdown & maintenance of electrical installation' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select required

                                                    name="Action"
                                                    onChange={this.handleChange(40)}
                                                    value={this.state.rows[40].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Observations" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Plan" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Responsibility" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <TextField type="text" name="Timelines" required id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <FormControl>

                                                <Select required

                                                    name="Status"
                                                    onChange={this.handleChange(40)}
                                                    value={this.state.rows[40].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>


                                </tbody>
                            </Table>
                        </Form>
                    </Collapse>


                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen17
                        })}
                        onClick={() => this.toggle(17)}
                    >
                        <span className="app__toggle-text">GENERAL HEALTH & SAFETY REQUIREMENTS </span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen17 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>
                    <Collapse
                        isOpen={this.state.isOpen17}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen17 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th>CHECK POINTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>REQUIREMENTS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>OBSERVATIONS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>TIMELINE(DAYS)<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>





                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr41" key={41}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Is there Safety Coordinator available for the facility? ' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Safety Coordinator should be available for the facility. ' />
                                        </td >
                                        <td>
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(41)}
                                                    value={this.state.rows[41].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(41)} value={this.state.rows[41].Observations} />
                                        </td>
                                        <td>

                                            <br />


                                            <TextField type="text" name="Plan" onChange={this.handleChange(41)} value={this.state.rows[41].Plan} />
                                        </td>
                                        <td>
                                            <br />


                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(41)} value={this.state.rows[41].Responsibility} />
                                        </td>
                                        <td>
                                            <br />


                                            <TextField type="text" name="Timelines" onChange={this.handleChange(41)} value={this.state.rows[41].Timelines} />
                                        </td>
                                        <td>
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(41)}
                                                    value={this.state.rows[41].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr42" key={42}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are safety meetings conducted and the proceedings recorded and discussed between all the staffs and safety coordinator of the facility? ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Monthly safety meetings shall be conducted at site. Records for the same shall be maintained' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(42)}
                                                    value={this.state.rows[42].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(42)} value={this.state.rows[42].Observation} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" onChange={this.handleChange(42)} value={this.state.rows[42].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />




                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(42)} value={this.state.rows[42].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" onChange={this.handleChange(42)} value={this.state.rows[42].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(42)}
                                                    value={this.state.rows[42].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr43" key={43}>




                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have Method to control hot works e.g., welding etc. ' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Work permit system to be followed and hot work to be done in supervision of fire personnel ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(43)}
                                                    value={this.state.rows[43].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" onChange={this.handleChange(43)} value={this.state.rows[43].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" onChange={this.handleChange(43)} value={this.state.rows[43].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(43)} value={this.state.rows[43].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" onChange={this.handleChange(43)} value={this.state.rows[43].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(43)}
                                                    value={this.state.rows[43].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr44" key={44}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have "No Smoking" Policy & ensured?  ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='"No Smoking" Policy" should be available and enforced in the premises ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(44)}
                                                    value={this.state.rows[44].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(44)} value={this.state.rows[44].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" onChange={this.handleChange(44)} value={this.state.rows[44].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(44)} value={this.state.rows[44].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" onChange={this.handleChange(44)} value={this.state.rows[44].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(44)}
                                                    value={this.state.rows[44].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr45" key={45}>




                                        <td>


                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they remove flammable & combustible material regularly' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the flammable & combustible material should be removed regularly' />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(45)}
                                                    value={this.state.rows[45].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(45)} value={this.state.rows[45].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" onChange={this.handleChange(45)} value={this.state.rows[45].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(45)} value={this.state.rows[45].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" onChange={this.handleChange(45)} value={this.state.rows[45].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(45)}
                                                    value={this.state.rows[45].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>



                                    <tr id="addr46" key={46}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are waste disposal areas tidy, segregated, covered, safe and secure? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Waste disposal areas should be tidy, segregated, covered, safe and secured' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(46)}
                                                    value={this.state.rows[46].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" onChange={this.handleChange(46)} value={this.state.rows[46].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" onChange={this.handleChange(46)} value={this.state.rows[46].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(46)} value={this.state.rows[46].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" onChange={this.handleChange(46)} value={this.state.rows[46].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(46)}
                                                    value={this.state.rows[46].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr47" key={47}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they store flammable liquids & gases in designated areas and handle carefully?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Flammable liquids & gases should be stored in designated areas only and handle carefully. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(47)}
                                                    value={this.state.rows[47].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" onChange={this.handleChange(47)} value={this.state.rows[47].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" onChange={this.handleChange(47)} value={this.state.rows[47].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(47)} value={this.state.rows[47].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" onChange={this.handleChange(47)} value={this.state.rows[47].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(47)}
                                                    value={this.state.rows[47].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>
                                    <tr id="addr48" key={48}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have adequate Personal Protective Equipment (PPEs) at site?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Adequate Personal Protective Equipment (PPEs) should be available for employees at site.' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(48)}
                                                    value={this.state.rows[48].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" onChange={this.handleChange(48)} value={this.state.rows[48].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" onChange={this.handleChange(48)} value={this.state.rows[48].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(48)} value={this.state.rows[48].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" onChange={this.handleChange(48)} value={this.state.rows[48].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(48)}
                                                    value={this.state.rows[48].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>
                                    <tr id="addr49" key={49}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they maintain good housekeeping' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Good housekeeping should be maintained' />
                                        </td >
                                        <td>
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(49)}
                                                    value={this.state.rows[49].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(49)} value={this.state.rows[49].Observations} />
                                        </td>
                                        <td>

                                            <br />


                                            <TextField type="text" name="Plan" onChange={this.handleChange(49)} value={this.state.rows[49].Plan} />
                                        </td>
                                        <td>
                                            <br />


                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(49)} value={this.state.rows[49].Responsibility} />
                                        </td>
                                        <td>
                                            <br />


                                            <TextField type="text" name="Timelines" onChange={this.handleChange(49)} value={this.state.rows[49].Timelines} />
                                        </td>
                                        <td>
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(49)}
                                                    value={this.state.rows[49].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr50" key={50}>




                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they keep all doors & window closed when not in use (Stores) ' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All doors & window closed when not in use (Stores) should not be locked condition ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(50)}
                                                    value={this.state.rows[50].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(50)} value={this.state.rows[50].Observations}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" onChange={this.handleChange(50)} value={this.state.rows[50].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />




                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(50)} value={this.state.rows[50].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" onChange={this.handleChange(50)} value={this.state.rows[50].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(50)}
                                                    value={this.state.rows[50].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr51" key={51}>




                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have periodic fire mock drills?' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Mock drill should be conducted on quarterly basis. ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(51)}
                                                    value={this.state.rows[51].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" onChange={this.handleChange(51)} value={this.state.rows[51].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" onChange={this.handleChange(51)} value={this.state.rows[51].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(51)} value={this.state.rows[51].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" onChange={this.handleChange(51)} value={this.state.rows[51].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(51)}
                                                    value={this.state.rows[51].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr52" key={52}>




                                        <td>
                                            <br />
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they carry out daily safety survey/inspection? ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Daily safety survey/inspection should be conducted for each area of facility.' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(52)}
                                                    value={this.state.rows[52].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(52)} value={this.state.rows[52].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Plan" onChange={this.handleChange(52)} value={this.state.rows[52].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(52)} value={this.state.rows[52].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" onChange={this.handleChange(52)} value={this.state.rows[52].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(52)}
                                                    value={this.state.rows[52].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr53" key={53}>




                                        <td>


                                            <br />
                                            <br />

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have system to report & track Near Miss, Minor, Major Accident' />
                                        </td >
                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='All the near miss, minor, major accident should be analyzed, tracked and reported and corrective action should be taken. ' />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(53)}
                                                    value={this.state.rows[53].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" onChange={this.handleChange(53)} value={this.state.rows[53].Observations}/>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" onChange={this.handleChange(53)} value={this.state.rows[53].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(53)} value={this.state.rows[53].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />
                                            <br />




                                            <TextField type="text" name="Timelines" onChange={this.handleChange(53)} value={this.state.rows[53].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(53)}
                                                    value={this.state.rows[53].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>



                                    <tr id="addr54" key={54}>




                                        <td>
                                            <br />
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have Emergency Hotline & Onsite Emergency Plan (OEP) ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Onsite Emergency Plan (OEP) should be conducted for the facility including roles and responsibilities of teams.' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(54)}
                                                    value={this.state.rows[54].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(54)} value={this.state.rows[54].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" onChange={this.handleChange(54)} value={this.state.rows[54].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(54)} value={this.state.rows[54].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" onChange={this.handleChange(54)} value={this.state.rows[54].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(54)}
                                                    value={this.state.rows[54].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr55" key={55}>




                                        <td>

                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have trained fire safety manpower to monitor and fight the fire?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Trained and qualified fire safety manpower should be available at facility in each shift' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(55)}
                                                    value={this.state.rows[55].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" onChange={this.handleChange(55)} value={this.state.rows[55].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" onChange={this.handleChange(55)} value={this.state.rows[55].Plan} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(55)} value={this.state.rows[55].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" onChange={this.handleChange(55)} value={this.state.rows[55].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(55)}
                                                    value={this.state.rows[55].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>
                                    <tr id="addr56" key={56}>




                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they conduct first aid training for employees?' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Trained first aider should be available in each shift. ' />
                                        </td >
                                        <td>
                                            <br />




                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(56)}
                                                    value={this.state.rows[56].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />

                                            <TextField type="text" name="Observations" onChange={this.handleChange(56)} value={this.state.rows[56].Observations} />
                                        </td>
                                        <td>

                                            <br />


                                            <TextField type="text" name="Plan" onChange={this.handleChange(56)} value={this.state.rows[56].Plan}/>
                                        </td>
                                        <td>

                                            <br />

                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(56)} value={this.state.rows[56].Responsibility} />
                                        </td>
                                        <td>

                                            <br />


                                            <TextField type="text" name="Timelines" onChange={this.handleChange(56)} value={this.state.rows[56].Timelines} />
                                        </td>
                                        <td>
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(56)}
                                                    value={this.state.rows[56].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>
                                    <tr id="addr57" key={57}>




                                        <td>
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Do they have fire warden /fire vigilantes? ' />
                                        </td >
                                        <td>

                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire warden/vigilantes should be developed for each area' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(57)}
                                                    value={this.state.rows[57].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Observations" onChange={this.handleChange(57)} value={this.state.rows[57].Observations} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />

                                            <TextField type="text" name="Plan" onChange={this.handleChange(57)} value={this.state.rows[57].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(57)} value={this.state.rows[57].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />

                                            <TextField type="text" name="Timelines" onChange={this.handleChange(57)} value={this.state.rows[57].Status} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(57)}
                                                    value={this.state.rows[57].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>

                                    <tr id="addr58" key={58}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details" required id="standard-required" defaultValue='Are they follow forklift safety procedures - training to operators, flashers & hooters in forklifts ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Forklift safety procedures should be followed. ' />
                                        </td >
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(58)}
                                                    value={this.state.rows[58].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <TextField type="text" name="Observations" onChange={this.handleChange(58)} value={this.state.rows[58].Observations} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" onChange={this.handleChange(58)} value={this.state.rows[58].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />




                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(58)} value={this.state.rows[58].Responsibility} />
                                        </td>
                                        <td>

                                            <br />
                                            <br />
                                            <br />



                                            <TextField type="text" name="Timelines" onChange={this.handleChange(58)} value={this.state.rows[58].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(58)}
                                                    value={this.state.rows[58].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>

                                    </tr>

                                    <tr id="addr59" key={59}>




                                        <td>


                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="details"  defaultValue='Do they have adequate guarding of moving machinery?  ' />
                                        </td >
                                        <td>
                                            <TextField multiline InputProps={{ readOnly: true }} style={{ width: '200px' }} selected type="text" name="unit" defaultValue='All the movable machinery should be covered with safety guard.  ' />
                                        </td >
                                        <td>

                                            <br />
                                            <br />

                                            <FormControl>

                                                <Select 

                                                    name="Action"
                                                    onChange={this.handleChange(59)}
                                                    value={this.state.rows[59].Action}
                                                >

                                                    <MenuItem value={this.state.type1} >Yes</MenuItem>
                                                    <MenuItem value={this.state.type2} >No</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Observations" onChange={this.handleChange(59)} value={this.state.rows[59].Observations}/>
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Plan" onChange={this.handleChange(59)} value={this.state.rows[59].Plan} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />



                                            <TextField type="text" name="Responsibility" onChange={this.handleChange(59)} value={this.state.rows[59].Responsibility} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <TextField type="text" name="Timelines" onChange={this.handleChange(59)} value={this.state.rows[59].Timelines} />
                                        </td>
                                        <td>
                                            <br />
                                            <br />


                                            <FormControl>

                                                <Select 

                                                    name="Status"
                                                    onChange={this.handleChange(59)}
                                                    value={this.state.rows[59].Status}
                                                >

                                                    <MenuItem value={this.state.type3} >Open</MenuItem>
                                                    <MenuItem value={this.state.type4} >Closed</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                    </tr>




                                </tbody>
                            </Table>
                            <button type="submit" style={{ float: 'right', marginLeft: '5px' }} className="btn btn-success" onSubmit={this.handleSubmit}>SUBMIT</button>
                        </Form>
                        <button type="submit" style={{ float: 'right' }} className="btn btn-success" onClick={this.handleSubmit1}>SAVE</button>
                    </Collapse>

                </Collapse>

            </div >
        )
    }
}

export default Auditchecklistfire       