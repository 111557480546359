import React, { Component } from 'react'
import Form from 'react-bootstrap/Form';
import axios from 'axios'


class Multilocationselection extends Component {
    constructor(props) {
        super(props)
        this.handleChange1 = this.handleChange1.bind(this);
        this.state = {
            types: [],
            locations: [],
            LocationId: [],
            rows: [],
            admin: 0,
            counter: false,
            value:[]
        }

    }



    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/GetAdminAll')
            .then(response => {
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi

                });

            })

            .catch(error => {
                console.log(error);

            });

    }
    handleChange = event => {
        
        event.preventDefault();
        

        var data = event.target.value;
        const mydata =data.split(',')
        var val = parseInt(mydata[0]);
        var val1 = parseInt(mydata[1]);

        this.setState({admin:val1})
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/GetUserRoleLocation', { params: { UserRoleId: val } })
                .then(response => {
                  
                    this.setState({
                        locations: response.data

                    });

                })

                .catch(error => {
                    console.log(error);

                });
        }  


    handleChange1(e) {
        debugger;
        let target = e.target
        let name = target.name
        
        let value =Array.from(target.selectedOptions, option => option.value)
        
        this.setState({ [name]: value });
        this.state = { selectOptions1: [] };
        this.setState({ value: value });
        
    }
    isDisabled() {
      
        var counter=this.state.counter

        if (counter == true) {

            return true;

        }
        else {
            return false;
        }

    }
    isDisplayed() {

        var counter = this.state.counter

        if (counter == true) {

            return 'inline';

        }
        else {
            return 'none';
        }

    }
    handleSubmit = event => {
        debugger
        event.preventDefault();
        let rows = this.state.rows;
        this.setState({counter:0})
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        //let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/SaveMultiLocationUser', newbody)
            .then(response => {
                alert("User Updated Successfully");
                window.location.reload();

            }).catch(err => console.log(err));
    }
    handleSubmit1 = event => {
        debugger
        event.preventDefault()
        const rows = this.state.rows;
        var admin = this.state.admin
        var value = this.state.value
      
        this.setState({ counter: true })

        for (var i = 0; i < value.length; i++) {
            let row = {
                ...rows[i],
                "UserId": admin,
                "LocationId": parseInt(value[i])
            };
            rows[i] = row;
        }
        this.setState({
            rows
        });
        
    }

    render() {
        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>
   
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white' }}>Multi Location Updation</h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="AdminName">
                                    <label class="form-label">Admin Name</label>
                                    <select required class="form-control" onChange={this.handleChange} name="UserId">
                                        <option selected disabled value='0'>--Select--</option>
                                        {this.state.types.map(type => (
                                            <option
                                                key={type.userRoleId}
                                                value={[type.userRoleId,type.userId]}
                                            >
                                                {type.uname}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Locations">
                                    <label class="form-label" >Location</label>
                                    <select name="LocationId"
                                        id="Locations"
                                        multiple={true}
                                        value={this.state.LocationId}
                                        width={'100px'}
                                        onChange={this.handleChange1}
                                        disabled={this.isDisabled()}

                                    >
                                        {this.state.locations.map(type => (
                                            <option
                                                key={type.locationId}
                                                value={type.locationId}
                                            >
                                                {type.locationName}
                                            </option>
                                        ))}
                                        


                                    </select>
                                    <button type="submit" style={{ float: 'right' }} className="btn btn-success" onClick={this.handleSubmit1}>FREEZE</button>
                                </div>
                            </div>

                     
                        </div>
                    </div>
                </div>
                <button type="submit" style={{ float: 'right', display: this.isDisplayed() }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>
            </Form>
            
            )
    }
}

export default Multilocationselection