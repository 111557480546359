import React, { Component } from 'react';
import '../index.css';
import axios from 'axios';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';

export class Home extends Component {
  static displayName = Home.name;
    async componentDidMount() {
        
               var utype = localStorage.getItem('utype')
      await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/AdminApproval/GetUserAdminId', { params: { UserId: utype } })
            .then(response => {
              
                console.log(response.data[1])
                localStorage.setItem('admincode', response.data[0]);  
                localStorage.setItem('adminname', response.data[1]);  
                localStorage.setItem('adminemail', response.data[2]);  
                // ucode=localStorage.getItem('username'),
                // utype=localStorage.getItem('utype'),
                // location=localStorage.getItem('location')

            })
          
            .catch(error => {
                console.log(error);

            });
        if (utype == 1) {
            this.props.history.push('/homepage')

        }
        else {
       //     this.props.history.push("/tabbar/?name="+"1.Raw Material")
            this.props.history.push("/homepage")
        }

       
        document.getElementById('header').style.display = "flex"

        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount < 2) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }

    componentWillUnmount() {
     //   document.getElementById('logout').style.display = "flex";
    }
  render () {
    return (
      <div>
        
        
      </div>
    );
  }
}
