import React from 'react';
import './App.css';
import { RiPencilLine } from "react-icons/ri";



class TabNav extends React.Component {
    render() {
        return (
            <div className="navs" >
                
                <div className="tabs">
                    <ul className="nav-tabs" id="nav-tabs" >
                    {
                        this.props.tabs.map(tab => {
                            const active = (tab === this.props.selected ? 'active ' : '');
                            return (
                               
                                <li className="nav-item" key={tab}>
                                    <a className= {"nav-link " + active} onClick={() => this.props.setSelected(tab)}>
                                        {tab}
                                    </a>
                                    </li>
                                   
                                    
                            );
                        })
                    }
                   </ul>
                    </div>
                { this.props.children}
            </div>
    );
    }
}
export default TabNav;