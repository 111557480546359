import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editwaterwithdrawal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            id: 0,
            LostTimeFatality: '',
            LostManHourFatality: '',
            TotalManhoursWorked: '',
            TotalFatatlity: '',
        };
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalFatality/GetTotalInjuryByID', { params: { InjuryId: id } }).then(response => {
            console.log(response.data);
            this.setState({
                LostTimeFatality: response.data[0].lostTimeFatality,
                LostManHourFatality: response.data[0].lostManHourFatality,
                TotalManhoursWorked: response.data[0].totalManhoursWorked,
                TotalFatatlity: response.data[0].totalFatatlity,

                id: id
            });

        });
    }
    handleSubmit = event => {

        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalFatality/UpdateTotalFatality', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/injuriesfatalitiesview')

            }).catch(err => console.log(err));
    }

    handleChange = event => {

        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;


        const value = parseInt(event.target.value);

        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
            InjuryId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="LostTimeFatality">Lost Time Fatality</label>
                        <input type="number" name="LostTimeFatality" className="form-control" id="LostTimeFatality" defaultValue={this.state.LostTimeFatality} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="LostManHourFatality">Lost Man Hour Fatality</label>
                        <input type="text" name="LostManHourFatality" className="form-control" id="LostManHourFatality" defaultValue={this.state.LostManHourFatality} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="TotalManhoursWorked">Total Manhours Worked</label>
                        <input type="text" name="TotalManhoursWorked" className="form-control" id="TotalManhoursWorked" defaultValue={this.state.TotalManhoursWorked} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="TotalFatatlity">Total Fatality</label>
                        <input type="text" name="TotalFatatlity" className="form-control" id="TotalFatatlity" defaultValue={this.state.TotalFatatlity} onChange={this.handleChange} />
                    </div>
                </div>


                <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>
            </Form>
        );
    }
}

export default Editwaterwithdrawal