import React, {useState, Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";
import '../index.css';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from "@material-ui/core/ListItemText";

import { value } from 'devexpress-dashboard/model/index.metadata';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';





class Incidentinvestigation extends Component {

    constructor(props) {
        super(props)
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.handleChange3 = this.handleChange3.bind(this);

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' });
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
           
            
           
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            program1: 'Fire',
            program2: 'Ehs',
            program3: 'Safety',
            type1: 'Male',
            type2: 'Female',
            type3: 'Abrasion,scrapes',
            type4: 'Amputation',
            type5: 'Broken bone',
            type6: 'Bruise',
            type7: 'Burn(heat)',
            type8: 'Burn(chemical)',
            type9: 'Concussion(head)',
            type10: 'Crushing Injury',
            type11: 'Cut/Laceration/Puncture',
            type12: 'Hernia',
            type13: 'Illness',
            type14: 'Sprain/Strain',
            type15: 'Body Part Damage',
            type16: 'Others',
            type17: 'Regular Full Time',
            type18: 'Regular Part Time',
            type19: 'Seasonal',
            type20: 'Temporary',
            type21: 'Entering or Leaving Work',
            type22: 'During Normal Work Activities',
            type23: 'During Meal Period',
            type24: 'During Break',
            type25: 'Working Overtime',
            type26: 'Others',
            type27: 'Yes',
            type28: 'No',
            type29: 'Near Miss',
            type30: 'First Aid',
            type31: 'Doctor Visit',
            type32: 'Lost Time',
            type33: 'Fatality',
            rows: [{ id: 0 }],
            rows1: [{ id: 0 }],
            rows2: [{ id: 0 }],
            rows3: [{ id: 0 }],
            rows4: [{ id: 0 }],
            rows5: [],
            rows6:[],
            rows7:[],
            rows8:[],
            rows9:[],
            rows10:[],
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            admincode: localStorage.getItem('admincode'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear:prevYear,
            newdate: newdate,
            UnsafeConditions: [],
            UnsafeActs: [],
            SuggestedChanges: [],
            selectedFile: null,

        }
    }

   
    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin ==null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
    }


    

   
   



    

    handleAddRow = () => (event) => {
        
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

     

    handleSubmit = async (event) => {

        
        event.preventDefault();

        const { rows } = this.state;
        const { rows1 } = this.state;
        const { rows2 } = this.state;
        const { rows3 } = this.state;
        const { rows4 } = this.state;
        const { rows5 } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/InjuredEmployee/GetInjuredEmployeeByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentDescription/GetIncidentDescriptionByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentHappen/GetIncidentHappenByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/FutureIncidents/GetFutureIncidentByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/ReviewedBy/GetReviewedByMonth', { params: { Month: prevMonth, Year: year, UserId: id } }),
        ]).then(axios.spread((response1, response2, response3, response4, response5) => {
            this.setState({
                rows6: response1.data,
                rows7: response2.data,
                rows8: response3.data,
                rows9: response4.data,
                rows10: response5.data
            })
        }))
       

        var rows6 = this.state.rows6
        var rows7 = this.state.rows7
        var rows8 = this.state.rows8
        var rows9 = this.state.rows9
        var rows10 = this.state.rows10
        if (rows6.length == 0 || rows7.length == 0 || rows8.length == 0 || rows9.length == 0 || rows10.length == 0) {


            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            let newbody = JSON.parse(Options.body);

            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let newbody1 = JSON.parse(Options1.body);

            const Options2 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows2)
            };
            let newbody2 = JSON.parse(Options2.body);
            const Options3 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows3)
            };
            let newbody3 = JSON.parse(Options3.body);
            const Options4 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows4)
            };
            let newbody4 = JSON.parse(Options4.body);
            const Options5 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows5)
            };
            let checkbody = JSON.parse(Options5.body);
            //localStorage.setItem('rawmaterial', Options.body);
            //alert("Data Saved Successfully");
            /*  const Options1 = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(login)
              };
              let checkbody = JSON.parse(Options1.body);*/
            // Create an object of formData
            const file = new FormData();

            // Update the formData object
            if (this.state.selectedFile != null) {
                file.append("formFile", this.state.selectedFile[0])
                file.append("filename", this.state.selectedFile[0].name)
            }
            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/InjuredEmployee/SaveInjuredEmployeeList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentDescription/SaveIncidentDescriptionList', newbody1),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/IncidentHappen/SaveIncidentHappenList', newbody2),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/FutureIncidents/SaveFutureIncidentList', newbody3),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/ReviewedBy/SaveReviewedByList', newbody4),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyWalkList/UploadeFiles', file),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

            ]).then(axios.spread((response1, response2) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }


        



    }


    toggle = (index) => {
        let collapse = "isOpen" + index;

        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }
    
    handleChange1(e) {
        debugger;
        let target = e.target
        let name = target.name
        var rows2 = this.state.rows2;
     

        let value = Array.from(target.selectedOptions, option => option.value);

        this.setState({ [name]: value });
        this.state = { selectOptions1: [] };

        let row = {
            ...rows2[0],
            [name]: value.join(',')
        };
        rows2[0] = row;
        this.setState({
            rows2
        });
    }

    handleChange2 (e){
        
        let target = e.target
        let name = target.name
        var rows2 = this.state.rows2;
        
        let value = Array.from(target.selectedOptions, option => option.value);
      
        this.setState({ [name]: value });
        this.state = { selectOptions2: [] };
        let row = {
            ...rows2[0],
            [name]: value.join(',')
        };
        rows2[0] = row;
        this.setState({
            rows2
        });
     
    }

    handleChange3(e) {

       
        let target = e.target
        let name = target.name
        var rows3 = this.state.rows3;
        
        let value = Array.from(target.selectedOptions, option => option.value);

        this.setState({ [name]: value });

        this.state = { selectOptions3: [] };

        let row = {

            [name]: value.join(',')
        };
        rows3[0] = row;
        this.setState({
            rows3
        });
    }
       
    handleChange4 = e => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        //let id=name+(idx);

        var value = 0;
        if (name == "Age" || name == "MonthsJob" || name == "MonthsEmployer") {
            value = parseInt(e.target.value);

        }
        else {
            value = e.target.value;
        }

        var rows = this.state.rows;
        const rows5 = [...this.state.rows5];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows[0],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[0] = row;
        this.setState({
            rows
        });

        let row1 = {
            ...rows5[0],

            "FormId": 7,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows5[0] = row1;
        this.setState({
            rows5
        });
    };

    handleChange5 = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        //let id=name+(idx);

        const value = e.target.value;


        var rows1 = this.state.rows1;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows1[0],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows1[0] = row;
        this.setState({
            rows1
        });
    };

    handleChange6 = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        //let id=name+(idx);

        const value = e.target.value;


        var rows2 = this.state.rows2;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows2[0],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows2[0] = row;
        this.setState({
            rows2
        });
    };

    handleChange7 = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        //let id=name+(idx);

        const value = e.target.value;


        var rows3 = this.state.rows3;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows3[0],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows3[0] = row;
        this.setState({
            rows3
        });
    };

    handleChange8 = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        //let id=name+(idx);

        const value = e.target.value;


        var rows4 = this.state.rows4;
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows4[0],

            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows4[0] = row;
        this.setState({
            rows4
        });
    };

    handleChange9 = e => {
       
        e.preventDefault();


        const name = e.target.name;


        this.setState({ selectedFile: e.target.files });
       
        let filename = e.target.files[0].name;
        let root = `/Images/` + filename

        //const val = e.target.value;
        //var sub=val.substring(12,val.length)

       
        const rows1 = [...this.state.rows1];
        var utype = parseInt(this.state.utype)
        let row = {
            ...rows1[0],

            "UploadedPath": root,
            "SafetyWalkId": name,
            "UserId": utype

        };

        rows1[0] = row;
        this.setState({
            rows1
        });
    };

    handleClick1 = () => (event) => {

        this.props.history.push("/incidentinvestigationview");
    };


    render() {
       
       
        return (
            <div className="app">
                <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                <br />
                <h5 style={{ backgroundColor: '#001946', width:'1100px',textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Incident Investigation Report </h5>

                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen1
                    })}
                    onClick={() => this.toggle(1)}
                >
                    <span className="app__toggle-text">Injured Employee(Complete This Part for Each Employer)</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen1}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen1 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" style={{fontSize:'medium'}} onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                            

                            

 

                        </div>
                        <div className="col-1">
                           
                           
                         

                            <div >
                                <label class="no-space-break">Name: </label> <br />
                                <TextField style={{ width: '200px' }} type="text" name="Name" required id="standard-required" onChange={this.handleChange4} />
                            </div>




                            <br />
                            <div>
                                <label class="no-space-break">Sex: </label> <br />
                                <FormControl>

                                    <select isSearchable required
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        onChange={this.handleChange4}
                                        name="Sex"

                                    >
                                        <option disabled selected value="0" >--Select--</option>
                                        <option value={this.state.type1} >Male</option>
                                        <option value={this.state.type2} >Female</option>


                                    </select>

                                </FormControl>
                            </div>
                            <br />
                            <div >
                                <label class="no-space-break">Age: </label> <br />
                                <TextField style={{ width: '100px' }} type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="Age" required id="standard-required" onChange={this.handleChange4} />
                            </div>
                            <br/>
                            <div>
                                <label class="no-space-break">Employee Type: </label> <br />
                                <FormControl>

                                    <select isSearchable required
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        onChange={this.handleChange4}
                                        name="EmployeeType"

                                    >
                                        <option disabled selected value="0" >--Select--</option>
                                        <option value={this.state.type17} >Regular Full Time</option>
                                        <option value={this.state.type18} >Regular Part Time</option>
                                        <option value={this.state.type19} >Seasonal</option>
                                        <option value={this.state.type20} >Temporary</option>


                                    </select>

                                </FormControl>
                            </div>
                            <br />
                            <div >
                                <label class="no-space-break">Months During This Job: </label> <br />
                                <TextField style={{ width: '100px' }} type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="MonthsJob" required id="standard-required" onChange={this.handleChange4} />
                            </div>


                            <br />
                            
                        </div>
                        <div className="col-2">
                           
                           
                            



                          
                            <div >
                                <label class="no-space-break">Department: </label> <br />
                                <TextField style={{ width: '200px' }} type="text" name="Department" required id="standard-required" onChange={this.handleChange4}/>
                            </div>


                            <br />


                            <div >
                                <label class="no-space-break">Job Title:</label> <br />
                                <TextField style={{ width: '200px' }} type="text" name="JobTitle" required id="standard-required" onChange={this.handleChange4}/>
                            </div>
                            <br />



                            <div >
                                <label class="no-space-break">Months With This Employer: </label> <br />
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="MonthsEmployer" required id="standard-required" onChange={this.handleChange4}/>
                            </div>
                            <br />
                            <div>
                                <label class="no-space-break">Nature of Injury: </label> <br />
                                <FormControl>

                                    <select isSearchable required
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        onChange={this.handleChange4}
                                        name="InjuryNature"

                                    >
                                        <option disabled selected value="0" >--Select--</option>
                                        <option value={this.state.type3} >Abrasion,scrapes</option>
                                        <option value={this.state.type4} >Amputation</option>
                                        <option value={this.state.type5} >Broken Bone</option>
                                        <option value={this.state.type6} >Bruise</option>
                                        <option value={this.state.type7} >Burn(heat)</option>
                                        <option value={this.state.type8} >Burn(chemical)</option>
                                        <option value={this.state.type9} >Concussion(To the Head)</option>
                                        <option value={this.state.type10} >Crushing Injury</option>
                                        <option value={this.state.type11} >Cut/Laceration/Puncture</option>
                                        <option value={this.state.type12} >Hernia</option>
                                        <option value={this.state.type13} >Illness</option>
                                        <option value={this.state.type14} >Sprain/Strain</option>
                                        <option value={this.state.type15} >Damage To Body System</option>
                                        <option value={this.state.type16} >Others</option>


                                    </select>

                                </FormControl>
                            </div>

                           
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                        </div>




                        {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                    </Form>
                </Collapse>

                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen2
                    })}
                    onClick={() => this.toggle(2)}
                >
                    <span className="app__toggle-text">DESCRIBE THE INCIDENT </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen2}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen2 ? "app__collapse--active" : "")
                    }
                >

                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}







                        </div>
                        <div className="col-1">
                            <br />

                            <div>
                                <label class="no-space-break">Report of:</label> <br />
                                <FormControl>

                                    <select required
                                        native
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        onChange={this.handleChange5}
                                        name="IncidentType"

                                    >
                                        <option disabled selected value="0" >--Select--</option>
                                        <option value={this.state.type29} >Near Miss</option>
                                        <option value={this.state.type30} >First Aid</option>
                                        <option value={this.state.type31} >Doctor Visit</option>
                                        <option value={this.state.type32} >Lost Time</option>
                                        <option value={this.state.type33} >Fatality</option>
                                       


                                    </select>

                                </FormControl>
                            </div>
                            <br />

                            <div >
                                <label class="no-space-break">Exact Location Of Incident: </label> <br />
                                <TextField type="text" name="IncidentLocation" required id="standard-required" onChange={this.handleChange5} />
                            </div>




                            <br />
                            <br />
                            <div>
                                <label class="no-space-break">What Part of Employee Workday?  </label> <br />
                                <FormControl>

                                    <select  required
                                        native
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        onChange={this.handleChange5}
                                        name="WorkdayPart"

                                    >
                                        <option disabled selected value="0" >--Select--</option>
                                        <option value={this.state.type21} >Entering or Leaving Work</option>
                                        <option value={this.state.type22} >During Normal Work Activities</option>
                                        <option value={this.state.type23} >During Meal Period</option>
                                        <option value={this.state.type24} >During Break</option>
                                        <option value={this.state.type25} >Working Overtime</option>
                                        <option value={this.state.type26} >Others</option>


                                    </select>

                                </FormControl>
                            </div>
                            <br />
                           
                        </div>
                        <div className="col-2">
                            <br />






                            <div>
                                <label class="no-space-break">Exact Time: </label>
                                <br />
                                <TextField
                                    name="Time"

                                    type="time"
                                    defaultValue="hh:mm"
                                    onChange={this.handleChange5}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                   
                                />
                            </div>



                           

                            <br />


                            <div >
                                <label class="no-space-break">What Personal Protective Equipments was Being Used?  </label> <br />
                                <TextField type="text" name="Equipments" required id="standard-required" onChange={this.handleChange5}/>
                            </div>
                           

                        
                         
                         
                            <br />
                            <label className="no-space-break">Attachments(If any)</label>
                            <input className="btn btn-primary btn-sm" type="file" name="UploadedPath" onChange={this.handleChange9} /> <br />
                            <br />

                            
                        </div>

                        <br />
                        <label class="no-space-break">Names of Witness(If any): </label>
                        <br />

                        <TextField
                            id="outlined-full-width"
                            label="Description"
                            name="Witness"
                            onChange={this.handleChange5}

                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                        <br />
                        <label class="no-space-break">Describe step by step the events that led to Injury.Include name of any machinery,parts,objects,tools,materials and other important details: </label>
                        <br />

                        <TextField
                            id="outlined-full-width"
                            label="Description"
                           
                            onChange={this.handleChange5}
                            name="Events"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />


                        {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                    </Form>

                   
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen3
                    })}
                    onClick={() => this.toggle(3)}
                >
                    <span className="app__toggle-text">WHY DID THE INCIDENT HAPPEN?   </span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen3 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen3}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen3 ? "app__collapse--active" : "")
                    }
                >


                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>

                        {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                        <div className="App">
                            {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                        </div>
                        <br />

                        <div>
                                <label class="no-space-break">Unsafe Workplace Conditions(Use Ctrl Key For Multiple Selection): </label> <br />
                            <select name="UnsafeConditions"
                                multiple={true}
                                value={this.state.UnsafeConditions}

                                onChange={this.handleChange1}


                            >

                                <option value="Inadequate guard" >Inadequate guard</option>
                                <option value="Unguarded Hazard" >Unguarded Hazard</option>
                                <option value="Safety device is defective" >Safety device is defective</option>
                                <option value="Tool or equipment defective" >"Tool or equipment defective</option>
                                <option value="Workstation Layout is Hazardous" >Workstation Layout is Hazardous</option>
                                <option value="Unsafe Lighting" >Unsafe Lighting</option>
                                
                                <option value="Lack of needed personal protective equipment" >Lack of needed personal protective equipment</option>
                                <option value="Lack of appropriate equipment/tools" >Lack of appropriate equipment/tools</option>
                                <option value="Unsafe Clothing" >Unsafe Clothing</option>

                                <option value="No training or Insufficient training" >No training or Insufficient training</option>
                                <option value="Others" >Others</option>

                               
                            </select>

                           </div>
                           
                        <br />
                        <br />
                      
                        <div >
                            <label class="no-space-break">Unsafe Acts By People(Use Ctrl Key For Multiple Selection):</label> <br />


                            
                            <select name="UnsafeActs"
                                multiple={true}
                                value={this.state.UnsafeActs}
                               
                                onChange={this.handleChange2}
                               

                            >
                              
                                <option value="Operating without permission" >Operating without permission</option>
                                <option value="Operating at unsafe speed" >Operating at unsafe speed</option>
                                <option value="Safety device is defective" >Safety device is defective</option>
                                <option value="Servicing equipment that has power to it" >Servicing equipment that has power to it</option>
                                <option value="Making a safety device inoperative" >"Making a safety device inoperative</option>
                                <option value="Using defective equipment" >Using defective equipment</option>
                                <option value="Using equipment in an unapproved way" >Using equipment in an unapproved way</option>
                                <option value="Unsafe lifting" >Unsafe lifting</option>
                                <option value="Taking an unsafe position or posture" >Taking an unsafe position or posture</option>
                                <option value="Failure to wear personal protective equipment" >Failure to wear personal protective equipment</option>
                                <option value="Failure to use the available equipment/tools" >Failure to use the available equipment/tools</option>
                                <option value="Others" >Others</option>

                               
                            </select>
                            <div>
                                {this.state.UnsafeActs.join(', ')}
                            </div>
                               </div>
                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
                        <div>
                            <br />
                            <label class="no-space-break">Why did the unsafe conditions Exists? </label>
                            <br />

                            <TextField
                                id="outlined-full-width"
                                label="Description"
                                name="UnsafeExists"
                                onChange={this.handleChange6}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />

                            <br />
                            <label class="no-space-break">Why did the unsafe acts occur? </label>
                            <br />

                            <TextField
                                id="outlined-full-width"
                                label="Description"
                                
                                onChange={this.handleChange6}
                                name="UnsafeOccurs"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />

                            <br />
                            <br />
                            <label class="no-space-break">Is there a reward (such as "the job can be done more quickly" or "the product is less likely to be damaged")that may have encouraged the unsafe <br />conditions or acts? </label>
                            <br />

                            <FormControl style={{float:'right'}}>

                                <Select isSearchable required
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    defaultValue="Select"
                                    name="Reward"
                                    onChange={this.handleChange6}
                                >
                                    <MenuItem value={this.state.type27} >Yes</MenuItem>
                                    <MenuItem value={this.state.type28} >No </MenuItem>
                                  


                                </Select>

                            </FormControl>
                            <br />

                            <label class="no-space-break">Were the unsafe acts or conditions reported prior to incident? </label>
                            <br />

                            <FormControl style={{ float: 'right' }}>

                                <Select isSearchable required
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    defaultValue="Select"
                                    name="UnsafeReported"
                                    onChange={this.handleChange6}
                                >
                                    <MenuItem value={this.state.type27} >Yes</MenuItem>
                                    <MenuItem value={this.state.type28} >No </MenuItem>



                                </Select>

                            </FormControl>
                            <br />
                            <br />
                            <label class="no-space-break">Have there been similar incidents or near misses prior to this one    ? </label>
                            <br />

                            <FormControl style={{ float: 'right' }}>

                                <Select isSearchable required
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    defaultValue="Select"
                                    name="SimilarIncidents"
                                    onChange={this.handleChange6}
                                >
                                    <MenuItem value={this.state.type27} >Yes</MenuItem>
                                    <MenuItem value={this.state.type28} >No </MenuItem>



                                </Select>

                            </FormControl>
                            <br />

                        </div>
                            <br />

                        <br />


                     






                    </Form>
                </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen4
                    })}
                    onClick={() => this.toggle(4)}
                >
                    <span className="app__toggle-text">HOW CAN FUTURE INCIDENTS BE PREVENTED?</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen4 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>

                <Collapse
                    isOpen={this.state.isOpen4}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen4 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>
                    <div>
                        <label class="no-space-break">What changes do you suggest to prevent this incident/near miss from happening again?: </label> <br />

                            <select name="SuggestedChanges"
                                multiple={true}
                                value={this.state.SuggestedChanges}

                                onChange={this.handleChange3}


                            >

                                <option value="Stop this activity" >Stop this activity</option>
                                <option value="Guard the hazard" >Guard the hazard</option>
                                <option value="Train the employee(s)" >Train the employee(s)</option>
                                <option value="Train the supervisor(s)" >"Train the supervisor(s)</option>
                                <option value="Redesign task steps" >Redesign task steps</option>
                                <option value="Redesign work station" >Redesign work station</option>
                                <option value="Write a new policy/rule" >Write a new policy/rule</option>
                                <option value="Enforce existing policy" >Enforce existing policy</option>
                                <option value="Routinely inspect for the hazard" >Routinely inspect for the hazard</option>
                                <option value="Personal Protective Equipment" >Personal Protective Equipment</option>
                                <option value="Others" >Others</option>


                         
                            </select>
                      
                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
                        </div>
                    <br />
                    <label class="no-space-break">What should be(or has been) done to carry out the suggestion(s) checked above? </label>
                    <br />

                    <TextField
                        id="outlined-full-width"
                        label="Description"
                       
                            onChange={this.handleChange7}
                            name="CarrySuggestion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />


              
                    </Form>
                        </Collapse>
                <button
                    className={cx("app__toggle", {
                        "app__toggle--active": this.state.isOpen5
                    })}
                    onClick={() => this.toggle(5)}
                >
                    <span className="app__toggle-text">WHO COMPLETED AND REVIEWED THIS FORM?</span>
                    <div className="rotate90">
                        <svg
                            className={cx("icon", { "icon--expanded": this.state.isOpen5 })}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </button>
                <Collapse
                    isOpen={this.state.isOpen5}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (this.state.isOpen5 ? "app__collapse--active" : "")
                    }
                >
                    <Form className="font-link" style={{ fontSize: 'medium' }} onSubmit={this.handleSubmit}>

                        <div className="col-1">
                            <br />



                            <div >
                                <label class="no-space-break">Investigated By: </label> <br />
                                <TextField type="text" name="InvestigatedBy" required id="standard-required" onChange={this.handleChange8}/>
                            </div>




                            <br />
                            <div >
                                <label class="no-space-break">Department: </label> <br />
                                <TextField type="text" name="Department" required id="standard-required" onChange={this.handleChange8} />
                            </div>
                        </div>
                        <div className="col-2">
                            <br />
                            <div >
                                <label class="no-space-break">Title: </label> <br />
                                <TextField type="text" name="Title" required id="standard-required"  onChange={this.handleChange8} />
                            </div>

                        <br />


                            <div >
                                <label class="no-space-break">Date: </label> <br />
                                <TextField selected type="date" name="Date" required id="standard-required" onChange={this.handleChange8}/>
                            </div>


                        <br />
                          
                         
                        </div>
                        <label class="no-space-break">Name of Investigation team members: </label>
                        <br />
                        <TextField
                            id="outlined-full-width"
                            label="Description"
                            name="TeamMembers"
                            onChange={this.handleChange8}

                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />

                        <br />
                        <br />
                        <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>
                 </Form>
              </Collapse>
            </div>
            

              
        )
    }
}

export default Incidentinvestigation