import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';



class Riskassessment extends Component {

    constructor(props) {
        super(props)

        this.handletotal = this.handletotal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            types: [],
            hasError: false,
            selected: null,
            unit: '',
            validationError: "",
            count: 0,
            rows: [{ id: 0 }],
            rows1: [],
            rows2:[],
            login: [],
            pro1:1,
            pro2:2,
            pro3:3,
            pro4:4,
            pro5:5,
            pro6:6,
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            admincode: localStorage.getItem('admincode'),
            longMonth: longMonth,
            longYear: longYear,
            check: "",
            prevMonth: prevMonth,
            prevYear:prevYear
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
       
        
    }



    handleChange = (idx) => (e) => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        var value = 0;
        //let id=name+(idx);
      
        if (name == "ProbabilityId" || name == "SeverityId" || name == "ProbabilityId1" || name == "SeverityId1") {
            value = parseInt(e.target.value);

        }
        else {
            value = e.target.value;
        }
        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype)
        var admincode = parseInt(this.state.admincode)

        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype

        };
        rows[idx] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],

            "FormId": 1,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });
    };



  
    handletotal = (idx) => (e) => {

        
        e.preventDefault();

        const rows = [...this.state.rows];
        rows[idx].RiskLevel = parseInt(rows[idx].ProbabilityId) *parseInt(rows[idx].SeverityId)
        if (rows[idx].RiskLevel <= 8) {
            rows[idx].RiskRating='Low'
        }
        else if (rows[idx].RiskLevel <= 15) {
            rows[idx].RiskRating = 'Medium'
        }
        else if (rows[idx].RiskLevel >= 16) {
            rows[idx].RiskRating = 'High'
        }

        this.setState({
            rows
        });
    };
    // handleTypeChange = event => {
    //   
    //   this.setState({
    //   type1: event.target.value,

    //     })
    //    }

    handletotal1 = (idx) => (e) => {

        
        e.preventDefault();

        const rows = [...this.state.rows];
        rows[idx].ResidualRisk = parseInt(rows[idx].ProbabilityId1) * parseInt(rows[idx].SeverityId1)
        

        this.setState({
            rows
        });
    };
   
    handleAddRow = () => (event) => {
        this.setState({ selected: null });
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count,
            unit: ''
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]


        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => (e) => {

        e.preventDefault();
        let count = this.state.count
        count = count - 1;
        this.setState({ count })
        let rowarr = [...this.state.rows];
        let deletedItem = rowarr.findIndex(item => item.id === idx);
        rowarr.splice(deletedItem, 1);
        this.setState({ ...this.state.rows = rowarr })

        // let row = [...this.state.rows.filter(x=>x.id!==idx)];
        // this.setState({rows:row})




        //  const rows = [...this.state.rows]
        //   rows.splice(idx,1)
        //   this.setState({ rows })
        //   e.preventDefault()
        //  var newrows=this.state.rows.slice();
        //  newrows.splice(idx,1);
        //  this.setState({ rows:newrows })

        // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
        //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
        //  this.setState({
        //    rows: newRows,
        //  });

    }
    
    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {

        
        event.preventDefault();

        const { rows } = this.state;
        const { rows1 } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskAssessment/GetRiskAssessmentByMonth', { params: { Month: prevMonth, Year: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows2: response.data
                })

            })

        var rows2 = this.state.rows2
        if (rows2.length == 0) {


            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            var newbody = JSON.parse(Options.body);
            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let checkbody = JSON.parse(Options1.body);


            axios.all([
               await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskAssessment/SaveRiskAssessmentDetails', newbody),
                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)
            ]).then(axios.spread((response1, response2) => {
                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));
        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }



        


    }

    handleClick1 = () => (event) => {

        this.props.history.push("/riskassessmentview");   
    };

    renderRows() {
        const { hasError } = this.state;
        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}>


                     
                    <td>
                        <TextField style={{ width: '150px' }} type="text" name="Activity" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td >
                    <td>
                        <TextField style={{ width: '150px' }} type="text" name="Hazard" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td >
                    <td>
                        <TextField type="text" name="Impact" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td >
                    <td>
                        <TextField style={{ width: '150px' }} type="text" name="Controls" required id="standard-required" onChange={this.handleChange(idx)} />
                    </td >
                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="ProbabilityId"
                                onChange={this.handleChange(idx)}
                                onBlur={this.handletotal(idx)}
                                
                            >
                                <MenuItem value={this.state.pro1} >1</MenuItem>
                                <MenuItem value={this.state.pro2} >2</MenuItem>
                                <MenuItem value={this.state.pro3} >3</MenuItem>
                                <MenuItem value={this.state.pro4} >4</MenuItem>
                                <MenuItem value={this.state.pro5} >5</MenuItem>
                                <MenuItem value={this.state.pro6} >6</MenuItem>
                                {/*   <MenuItem value={this.state.row1.type1} >Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem> */}
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="SeverityId"
                                onChange={this.handleChange(idx)}
                                onBlur={this.handletotal(idx)}
                            >
                                <MenuItem value={this.state.pro1} >1</MenuItem>
                                <MenuItem value={this.state.pro2} >2</MenuItem>
                                <MenuItem value={this.state.pro3} >3</MenuItem>
                                <MenuItem value={this.state.pro4} >4</MenuItem>
                                <MenuItem value={this.state.pro5} >5</MenuItem>
                                
                                {/*   <MenuItem value={this.state.row1.type1} >Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem> */}
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} selected type="number" name="RiskLevel" required id="standard-required" value={this.state.rows[idx].RiskLevel} onKeyUp={this.handletotal(idx)} onClick={this.handletotal(idx)} />
                    </td >


                    <td>
                        <TextField InputProps={{ readOnly: true }} value={this.state.rows[idx].RiskRating} type="text" name="RiskRating" required id="standard-required"  />
                    </td >
                    <td>
                        <TextField type="text" name="RiskControls" required id="standard-required" onChange={this.handleChange(idx)}  />
                    </td>

                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="ProbabilityId1"
                                onChange={this.handleChange(idx)}
                                onBlur={this.handletotal1(idx)}

                            >
                                <MenuItem value={this.state.pro1} >1</MenuItem>
                                <MenuItem value={this.state.pro2} >2</MenuItem>
                                <MenuItem value={this.state.pro3} >3</MenuItem>
                                <MenuItem value={this.state.pro4} >4</MenuItem>
                                <MenuItem value={this.state.pro5} >5</MenuItem>
                                <MenuItem value={this.state.pro6} >6</MenuItem>
                                {/*   <MenuItem value={this.state.row1.type1} >Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem> */}
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <FormControl error={hasError}>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="SeverityId1"
                                onChange={this.handleChange(idx)}
                                onBlur={this.handletotal1(idx)}
                            >
                                <MenuItem value={this.state.pro1} >1</MenuItem>
                                <MenuItem value={this.state.pro2} >2</MenuItem>
                                <MenuItem value={this.state.pro3} >3</MenuItem>
                                <MenuItem value={this.state.pro4} >4</MenuItem>
                                <MenuItem value={this.state.pro5} >5</MenuItem>

                                {/*   <MenuItem value={this.state.row1.type1} >Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem> */}
                            </Select>
                            {hasError && <FormHelperText>This is required!</FormHelperText>}

                        </FormControl> </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="ResidualRisk" required id="standard-required" value={this.state.rows[idx].ResidualRisk} onKeyUp={this.handletotal1(idx)} onClick={this.handletotal1(idx)}  />
                    </td>
                    <td>
                        <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button>

                    </td>
                </tr>

            );
        });
    }




    render() {
        const text = "Improbable 1\nExtremely Remote 2\nRemote 3\nOccasional 4\nFrequent 5\nContinuous 6"
        const text1 = "No Impact 1\nMinor 2\nMajor 3\nFatality 4\nMultiple Fatalities 5 "
        const { hasError } = this.state;
        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>

                <br />
                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    <div className="wordalign">
                        {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                        <label>
                            {this.state.longMonth}   &nbsp;
                   {this.state.longYear}

                        </label>

                        <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                       
                       

                    </div>
                </div>
                <br />
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Risk Assessment </h5>

                <br />
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white", overflowX: 'scroll', display: 'block', overflowY: 'hidden',paddingTop:'28px'}}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white'}}>
                           
                            <th>ACTIVITY <br /><span style={{ color: '#001946', visibility: 'hidden' }}> ACTIVITY</span></th>
                            <th>HAZARD <br /> <span style={{ color: '#001946', visibility: 'hidden' }} >HAZARD</span></th>
                            <th>IMPACT <span style={{ color: '#001946', visibility: 'hidden' }} >IMPACT</span></th>
                            <th>CURRENT CONTROLS<br /><span style={{ color: '#001946', visibility: 'hidden' }}> ACTIVITY</span></th>
                           
                           
                            <th style={{ width: '130px',height:'65px',whiteSpace:'nowrap' ,overflowWrap:'break-word'}}>PROBABILITY 
                              <br />
                                
                                <Tooltip
                                        arrow={15}
                                        background="#ffffff"
                                        border="black"
                                    color="black"
                                    className="mytooltip"
                                        content={text.split("\n").map((i, key) => {

                                            return <div key={key}>{i}</div>;


                                        })}
                                        fadeDuration={0}
                                        fadeEasing="linear"
                                        fixed={false}
                                        fontFamily="Bw Glenn Sans Medium"
                                        fontSize="12px"
                                        offset={-20}
                                        padding={15}
                                        
                                        placement="right"
                                        radius={50}
                                        zIndex={1}

                                > 
                                        <span >
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>
                                        </span>
                              
                                </Tooltip>
                         
                                        </th>
                           
                            <th style={{ width: '130px', height: '65px', whiteSpace: 'nowrap', overflowWrap: 'break-word' }}>SEVERITY &nbsp;
                                <br />
                                <Tooltip
                                    arrow={15}
                                    background="#ffffff"
                                    border="black"
                                    color="black"
                                    className="mytooltip"
                                    content={text1.split("\n").map((i, key) => {

                                        return <div key={key}>{i}</div>;


                                    })}
                                    fadeDuration={0}
                                    fadeEasing="linear"
                                    fixed={false}
                                    fontFamily="Bw Glenn Sans Medium"
                                    fontSize="12px"
                                    offset={-20}
                                    padding={15}

                                    placement="right"
                                    radius={50}
                                    zIndex={1}

                                >
                                    <span >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>

                                </Tooltip>
                              
                                </th>
                                
                            <th>RISK LEVEL </th>
                            <th>RISK RATING</th>
                            <th>RISK CONTROLS</th>

                            <th style={{ width: '130px', height: '65px', whiteSpace: 'nowrap', overflowWrap: 'break-word' }}>PROBABILITY
                              <br />

                                <Tooltip
                                    arrow={15}
                                    background="#ffffff"
                                    border="black"
                                    color="black"
                                    className="mytooltip"
                                    content={text.split("\n").map((i, key) => {

                                        return <div key={key}>{i}</div>;


                                    })}
                                    fadeDuration={0}
                                    fadeEasing="linear"
                                    fixed={false}
                                    fontFamily="Bw Glenn Sans Medium"
                                    fontSize="12px"
                                    offset={-20}
                                    padding={15}

                                    placement="right"
                                    radius={50}
                                    zIndex={1}

                                >
                                    <span >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>

                                </Tooltip>

                            </th>

                            <th style={{ width: '130px', height: '65px', whiteSpace: 'nowrap', overflowWrap: 'break-word' }}>SEVERITY &nbsp;
                                <br />
                                <Tooltip
                                    arrow={15}
                                    background="#ffffff"
                                    border="black"
                                    color="black"
                                    className="mytooltip"
                                    content={text1.split("\n").map((i, key) => {

                                        return <div key={key}>{i}</div>;


                                    })}
                                    fadeDuration={0}
                                    fadeEasing="linear"
                                    fixed={false}
                                    fontFamily="Bw Glenn Sans Medium"
                                    fontSize="12px"
                                    offset={-20}
                                    padding={15}

                                    placement="right"
                                    radius={50}
                                    zIndex={1}

                                >
                                    <span >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>

                                </Tooltip>

                            </th>
                            <th>RESIDUAL RISK</th>
                            <th>ACTION <span style={{ color: '#001946', visibility: 'hidden' }} >ACTION</span></th>




                        </tr>
                    </thead>
                    <tbody>

                        {/* {this.state.rows.map((item, idx) => (
      
        
        <tr id="addr0" key={item.id}>
       
         <td>{idx}</td> 
        
        <td>  
        <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="type"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.type1}>Newspaper</MenuItem>
          <MenuItem value={this.state.row2.type2}>Ink</MenuItem>
          <MenuItem value={this.state.row3.type3}>Chemical</MenuItem>
          <MenuItem value={this.state.row4.type4}>Plates</MenuItem>
        </Select>
       
      </FormControl> </td>
       
       <td>
       <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="unit"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.unit1}>Metric Ton</MenuItem>
          <MenuItem value={this.state.row2.unit2}>Kgs</MenuItem>
          <MenuItem value={this.state.row3.unit3}>Ltr</MenuItem>
          <MenuItem value={this.state.row4.unit4}>Nos</MenuItem>
        </Select>
       
      </FormControl>
       </td>

       <td>
       <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          defaultValue="Select"
          name="consumed"
          onChange={this.handleChange(idx)}
        >
         <MenuItem value={this.state.row1.consumed1}>Value</MenuItem>
          
        </Select>
       
      </FormControl>
       </td >
       <td>
       <TextField   name="recycled" required id="standard-required"  onChange={this.handleChange(idx)} defaultValue={this.state.rows.recycle}/>
       </td>    
       <td>
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx)}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>                          
                        </button>
                        
                      </td>
                      
    </tr>
    
       
   ))}
        */}
                        {this.renderRows()}

                    </tbody>
                </Table>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>
                <button onClick={this.handleAddRow()} style={{ float: 'right', marginRight: '5px' }} className="btn btn-info btn-md">+ADD</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Riskassessment