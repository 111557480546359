import React,{Component} from 'react'
import { Table, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink  } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Tooltip from "react-simple-tooltip"

class Ieconsumption extends Component {
    constructor(props) {
        super(props)
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;
        this.state = {
            types: [],
            count: 0,
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
      rows:[{id:0}],
            row1: { type1: 'Electricity', unit1: 'MwH', quantity1: '' },
            longMonth: longMonth,
            longYear: longYear,
            prevMonth:prevMonth,
            prevYear: prevYear,
            isOpen: false
        }
            
  
}



    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var indirectenergy = JSON.parse(localStorage.getItem("indirectenergy"));
        var rows = this.state.rows;

        if (indirectenergy != null) {
            rows = indirectenergy

            this.setState({
                rows
            })
        }
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/IndirectEnergyConsumption/GetIndirectType')
            .then(response => {
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi

                });
            })
            .catch(error => {
                console.log(error);

            });
    }  


    openModal = (e) => {


        this.setState({ isOpen: true }, () => {
            window.setTimeout(() => {
                this.setState({ isOpen: false })
            }, 2000)
        });

    }

    closeModal = () => this.setState({ isOpen: false });
handleChange = (idx) => (e) => {
    
  e.preventDefault();
  // const { name, value } = e.target;
  const name=e.target.name;
  //let id=name+(idx);
   
    var year = 0
    var value = parseFloat(e.target.value);

    //let id=name+(idx);
   
   const rows = [...this.state.rows];
    var longYear = this.state.longYear;
    var prevMonth = this.state.prevMonth;
    var prevYear = this.state.prevYear;
    let utype = parseInt(this.state.utype);
    let location = parseInt(this.state.location);
    //if (prevMonth == "December") {
    //    year = prevYear
    //}
    //else {
    //    year = longYear
    //}
    var month = localStorage.getItem("month1");
    var year = localStorage.getItem("year1")
    var Reference = localStorage.getItem("globalvar")
    let row = {
        ...rows[idx],
        id: idx,
        [name]: value,
      
        "Month": month,
        "IndEnrYear": year,
        "LocationId": location,
        "UserId": utype,
        "ReferenceId":Reference
    };
    rows[idx] = row;
    this.setState({
        rows
    });
    };


   
    getIndex(value) {
       
        let arr = this.state.types;
        let prop = 'indEnergyId'
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }
                        
                        // handleRemoveRow = (event) => {
                        //   event.preventDefault();
                        //   this.setState({
                        //     rows: this.state.rows.slice(0, -1)
                        //   });
                        // };
                        handleRemoveSpecificRow = (idx) => (e) => {
                         
                            e.preventDefault();
                            let count = this.state.count
                            count = count - 1;
                            this.setState({ count })
                            let rowarr = [...this.state.rows];
                            let deletedItem = rowarr.findIndex(item => item.id === idx);
                            rowarr.splice(deletedItem, 1);
                            this.setState({ ...this.state.rows = rowarr })
                        }
                    
    isDisabled1() {

        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = event => {
        
        event.preventDefault();
        var prevMonth = this.state.prevMonth;
        const { rows } = this.state;
        //var year = 0;
        //var longYear = this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year = longYear
        //}
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');
        var id = localStorage.getItem('utype')
        //axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IndirectEnergyConsumption/GetIndirectEnergyMonthWise', { params: { Month: month, Year: year, UserId: id } })
        //    .then(response => {
                
        //        if (response.data.length == 0) {


                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    localStorage.setItem('indirectenergy', Options.body);
                    let newbody = JSON.parse(Options.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SaveIndirectEnergytemp', newbody).then(response => {

                        if (response.data == 1) {
                            this.openModal()
                        }

                        else {

                            alert("Data Not Saved.Kindly Save Again")
                        }

                    })
            //    }
            //    else {

            //        alert("Data Already Submitted")
            //    }

            //})
                
                 
                 
       /* let newbody = JSON.parse(Options.body);
        const Options1 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(login)
        };
        let checkbody = JSON.parse(Options1.body);

        axios.all([
            axios.post('http://localhost:23013/api/IndirectEnergyConsumption/SaveIndirectEnergyConsumed', newbody),

            axios.post('http://localhost:23013/api/UserSubmission/SaveUserSubmission', checkbody)

        ]).then(axios.spread((response1, response2) => {

            if (response1.data.Status === 'Success') {
                alert("Data Save Successfully");
                window.location.reload();
                console.log(response1.data.Status);

            }

            else {
                alert('Data Saved Successfully');
                window.location.reload();
            }
        })); */


             }    




             renderRows(){
              return this.state.rows.map((item, idx) => {
                return(
                
                <tr id="addr0" key={item.id}>
               
               
                
                        {/*  <td>  
                <FormControl>
               
                <Select 
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  defaultValue="Select"
                  name="IndEnergyId"
                                    onChange={this.handleChange1(idx)}
                >
                                    {this.state.types.map(type => (
                                        <MenuItem
                                            key={type.IndEnergyId}
                                            value={type.indEnergyId}
                                        >
                                            {type.type}
                                        </MenuItem>
                                    ))}
               
               
                </Select>
                               
                            </FormControl>
                            </td> 
                                <td>
                                    <TextField InputProps={{ readOnly: true }} selected type="text" name="unit" required id="standard-required" value={this.state.rows[idx].unit} />
                                </td >*/}
               
               <td>
                            <TextField InputProps={{ inputProps: { min: 0, max: 999999, step: "any"} }} type="number"  required id="standard-required" name="quantity" value={this.state.rows[0].quantity} onChange={this.handleChange(idx)}/>
               </td>    
              
            </tr>
               
            );
          });
            }

             render() {
                 const text = "This is electricity purchased from outside\nfor the press/office location."
              return  (
                  <Form className="font-link" onSubmit={this.handleSubmit}>
                      <br />
                      <div className="App">
                          <div className="wordalign">
                          {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                          <label>
                              {this.state.longMonth}   &nbsp;
                   {this.state.longYear}
                                  &nbsp;&nbsp;
                                  <Tooltip
                                      arrow={15}
                                      background="#ffffff"
                                      border="black"
                                      color="black"
                                      content={text.split("\n").map((i, key) => {

                                          return <div key={key}>{i}</div>;


                                      })}
                                      fadeDuration={0}
                                      fadeEasing="linear"
                                      fixed={false}
                                      fontFamily="Bw Glenn Sans Medium"
                                      fontSize="12px"
                                      offset={-20}
                                      padding={15}

                                      placement="right"
                                      radius={50}
                                      zIndex={1}




                                  >

                                      <span className="btn btn-outline-danger btn-sm">

                                          info
                               {/*<svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                                      </span>
                                  </Tooltip>
                              </label>
                             
                      {/* <h5>INDIRECT ENERGY CONSUMPTION </h5> */}
                          </div>
                      </div>
                      <Modal style={{ backgroundColor: 'white', width: '500px', height: '200px', borderColor: '#ffffff00' }}

                          show={this.state.isOpen} onHide={this.closeModal}>

                          <Modal.Header style={{ backgroundColor: '#001946', color: 'white' }} >
                              <Modal.Title>Message</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                              Data Saved Successfully

                          </Modal.Body>
                      </Modal>
                      <br />
                      <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                          <thead>
                              <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                   
                                  {/* <th>TYPE</th>
                    <th>UNITS</th> */}
                    <th>ENERGY PURCHASED (MwH)</th>
                   
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>1</td>
                    <td>
                        <select value={this.state.type1} onChange={this.handleTypeChange1}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit1} onChange={this.handleUnitChange1}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density1} onChange={this.handleDensityChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific1} onChange={this.handleCalorificChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value1} onChange={this.handleValueChange1 } />  
                   </td>    
        
                </tr>
                <tr>
                    <td>2</td>
                    <td>
                        <select value={this.state.type2} onChange={this.handleTypeChange2}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit2} onChange={this.handleUnitChange2}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density2} onChange={this.handleDensityChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific2} onChange={this.handleCalorificChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value2} onChange={this.handleValueChange2} />  
                   </td>  
        
                </tr>
                <tr>
                    <td>3</td>
                    <td>
                        <select value={this.state.type3} onChange={this.handleTypeChange3}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit3} onChange={this.handleUnitChange3}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density3} onChange={this.handleDensityChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific3} onChange={this.handleCalorificChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value3} onChange={this.handleValueChange3} />  
                   </td>  
                  </tr> */}
               
                  {this.renderRows()}
                   
               </tbody>
               </Table>
              
                      <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit} disabled={this.isDisabled1()}>SAVE</button>   
 
             {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
               
             
               </Form>
                   
              )
            }
}

export default Ieconsumption