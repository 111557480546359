import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import cx from "classnames";
import Moment from 'moment';
import { autoExpand } from 'devexpress-dashboard/model/index.metadata';

class Riskregister extends Component {

    constructor(props) {
        super(props)

        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();

        this.state = {
            isOpen1: true,
           
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            program1: 'Fire',
            program2: 'Ehs',
            program3: 'Safety',
            program4: 'Others',
            type1: 'Compliant',
            type2: 'NonCompliant',
            type3: 'NotApplicable',
          
            rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            rows1: [],
            rows2:[],
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            admincode: localStorage.getItem('admincode'),
            date:date,
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear:prevYear

        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var riskregisters = JSON.parse(localStorage.getItem("riskregisters"));
        var rows = this.state.rows;

        if (riskregisters != null) {
            rows = riskregisters

            this.setState({
                rows
            })
        } 
    }

    disablePastDate() {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };


    handleChange = (idx) => (e) => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;

        const value = e.target.value;

        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows[idx],

            [name]: value,
            "RMasterId": idx + 1,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[idx] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],

            "FormId": 17,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });


    };





    handleAddRow = () => (event) => {
        
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }


    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {
        
        event.preventDefault();

        const { rows } = this.state;
        if (rows.length == 0) {
            alert('No Data Submitted');
           
        }
        else {
            var prevMonth = this.state.prevMonth;
            var year = 0;
            var longYear = this.state.longYear

            if (prevMonth == "December") {
                year = longYear - 1;
            }

            else {

                year = longYear
            }

            var id = localStorage.getItem('utype')
            await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskRegister/GetRiskRegistereByMonth', { params: { Month: prevMonth, Year: year, UserId: id } })
                .then(response => {
                    
                    this.setState({
                        rows2: response.data
                    })

                })

            var rows2 = this.state.rows2
            if (rows2.length == 0) {


                const Options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(rows)
                };

                var newbody = JSON.parse(Options.body);
                const { rows1 } = this.state;


                const Options1 = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(rows1)
                };
                let checkbody = JSON.parse(Options1.body);

                axios.all([
                    await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/RiskRegister/SaveRiskRegisterList', newbody),

                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

                ]).then(axios.spread((response1, response2) => {

                    if (response1.data.Status === 'Success') {
                        alert("Data Save Successfully");
                        window.location.reload();
                        console.log(response1.data.Status);

                    }

                    else {
                        alert('Data Saved Successfully');
                        window.location.reload();
                    }
                }));
            }
            else {

                alert("Data Already Submitted")
                window.location.reload();
            }
        }


    }

    handleSubmit1 = event => {
        debugger;
        event.preventDefault()
        const { rows } = this.state;
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };


        localStorage.setItem('riskregister', Options.body)

    }

    toggle = (index) => {
        let collapse = "isOpen" + index;

        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }

    handleClick1 = () => (event) => {

        this.props.history.push("/riskregisterview");
    };


    render() {

        return (


            <div className="app">
                
                <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                <br />

              
                    <button
                        className={cx("app__toggle", {
                            "app__toggle--active": this.state.isOpen1
                        })}
                        onClick={() => this.toggle(1)}
                    >
                        <span className="app__toggle-text">LEGAL REGISTER </span>
                        <div className="rotate90">
                            <svg
                                className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>
                    </button>

                    <Collapse
                        isOpen={this.state.isOpen1}
                        className={
                            "app__collapse app__collapse--gradient " +
                            (this.state.isOpen1 ? "app__collapse--active" : "")
                        }
                    >

                        <Form className="font-link" onSubmit={this.handleSubmit}>

                            {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                            <div className="App">
                                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                            </div>
                        <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                        <th >APPLICABLE LEGISLATIONS/LAW</th>
                                    <th>CRITERIA DEFINITION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        <th>PROCEDURAL REQUIREMENTS</th>
                                        <th>REFERENCE DOCUMENTS</th>
                                    <th>LICENSE No.<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>STATUS<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                    <th>NEXT DUE DATE<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                        

                                    </tr>
                                </thead>
                                <tbody>


                                    <tr id="addr0" key={0}>




                                        <td>
                                         <br />
                                         <br />
                                         <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }}style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Air (prevention & control of pollution) act-1981 and rules-1982' />
                                        </td >
                                        <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Return should be sent to state pollution board every year stack emission/s and ambient air u/s 26 & 27' />
                                        </td >
                                        <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='consent to operate" from SPCB ' />
                                        </td >
                                        <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Online submission of form at SPCB website, along with test certificate (stack emission) ambient air certificate at SPCB website.' />
                                        </td >

                                        <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField inputProps={{style: { fontSize: 12}}} type="text" name="License"  id="standard-required" onChange={this.handleChange(0)} />
                                        </td>
                                        <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                            <FormControl>

                                                <Select 
                                                style={{ fontSize: 12 }}
                                                    name="Status"
                                                onChange={this.handleChange(0)}
                                                value={this.state.rows[0].Status}
                                                >

                                                    <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                    <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                    <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                                </Select>

                                            </FormControl>
                                        </td>
                                      
                                      
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}
                                            name="NextDate"
                                            
                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(0)}
                                            InputLabelProps={{
                                                shrink: true,
                                            
                                                }}
                                            />
                                        </td>
                                      
                                    </tr>

                                <tr id="addr1" key={1}>




                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '150px' }} inputProps={{ style: { fontSize: 12 } }} selected type="text" name="details" required id="standard-required" defaultValue='Air (prevention & control of pollution) act-1981 and rules-1982' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '150px' }} inputProps={{ style: { fontSize: 12 } }} selected type="text" name="unit" required id="standard-required" defaultValue='Stacks monitoring of dg sets, hot water stack monitoring' />
                                    </td >
                                    <td>
                                        <br />
                                      
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '150px' }} inputProps={{ style: { fontSize: 12 } }}selected type="text" name="details" required id="standard-required" defaultValue='Testing from NABL certified lab ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} style={{ width: '150px' }} inputProps={{ style: { fontSize: 12 } }} selected type="text" name="unit" required id="standard-required" defaultValue='Testing reports' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField type="text" inputProps={{ style: { fontSize: 12 } }} name="License" id="standard-required" onChange={this.handleChange(1)} value={this.state.rows[1].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select 
                                                style={{fontSize:12 }}
                                                name="Status"
                                                onChange={this.handleChange(1)}
                                                value={this.state.rows[1].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}
                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(1)}
                                            InputLabelProps={{
                                                shrink: true
                                               
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr2" key={2}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }}style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Water (prevention & control of pollution) act-1974 rules,1975' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12} }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Submit renewal application to state pollution board every year with water consumption and test certificate issued by the auth. Lab.  Restriction on new outlets and new discharges & provision discharge of sewage or trade effluent under section 25 & 26.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='consent to operate" from SPCB ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Online submission of form-1 at SPCB website, along with test certificate and water consumption report ' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(2)} value={this.state.rows[2].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12}}
                                                name="Status"
                                                onChange={this.handleChange(2)}
                                                value={this.state.rows[2].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}  

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(2)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr3" key={3}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Water (prevention & control of pollution) cess act, 1977 and cess rules1978 with amendments 2003' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Measuring and recording the quantity of water consumed and schedule-2 under section-3 shall be referred.' />
                                    </td >
                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='To deposit the - water cess charges, furnish the return in form-i before 10th of every month' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-i' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(3)} value={this.state.rows[3].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                       

                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(3)}
                                                value={this.state.rows[3].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                   
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(3)}
                                            value={this.state.rows[3].date}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr4" key={4}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The environment (protection) act 1986 & rules,1986 & amendment rules 2004' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Submission of return ending 31st of march every year on or before 30th September every year, under rule 14, standard for vehicle  (schedule-iv) general standards (schedule -vi) ambient noise standards (schedule-iii)' />
                                    </td >
                                    <td>
                                        
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Environment audit & statement to be submitted to SPCB, environmental statement hazardous waste disposal, solid waste disposal, ambient air monitoring inside the premises, ambient noise level monitoring form the auth. lab' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='FORM-V' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(4)} value={this.state.rows[4].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        

                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(4)}
                                                value={this.state.rows[4].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(4)}
                                            value={this.state.rows[4].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr5" key={5}>




                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The environment (protection) act 1986 & rules,1986 & amendment rules 2004' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue=''/>
                                    </td >
                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Under rules-5 hazardous waste (management, handling & transboundary movement) rules,2008' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='FORM-1' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(5)} value={this.state.rows[5].License} />
                                    </td>
                                    <td>
                                        <br />s
                                        <br />
                                        <br />
                                        


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(5)}
                                                value={this.state.rows[5].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(5)}
                                            value={this.state.rows[5].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr6" key={6}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Hazardous waste (management, handling & transboundary movement) rules,2016' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Facility engaged in handling, generation, storage, packaging, transportation, use, treatment, processing, recycling, recovery, pre-processing, co-processing, utilization, offering for sale, transfer or disposal of the hazardous and other wastes shall be required to make an application to the SPCB for authorization' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='RULE 6(1): Grant of authorisation' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='FORM-1' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(6)} value={this.state.rows[6].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(6)}
                                                value={this.state.rows[6].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(6)}
                                            value={this.state.rows[6].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr8" key={8}>




                                    <td>
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Hazardous waste (management, handling & transboundary movement) rules,2008' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Submit to the State Pollution Control Board, an annual return containing the details specified in Form 4 on or before the 30th day of June' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Rule 6 (2): submission of returns' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Filling up return in form no. 4' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(7)} value={this.state.rows[7].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(7)}
                                                value={this.state.rows[7].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(7)}
                                            value={this.state.rows[7].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                              


                                <tr id="addr8" key={8}>




                                    <td>
                                        <br />
                                       
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Hazardous waste (management, handling & transboundary movement) rules,2008' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The occupier handling hazardous or other wastes and operator of disposal facility shall maintain records of such operations' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='RULE 20  Records' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='FORM NO 3' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                    
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(8)} value={this.state.rows[8].License}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     
                                      


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(8)}
                                                value={this.state.rows[8].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     
                                       
                                        

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(8)}
                                            value={this.state.rows[8].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr9" key={9}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Hazardous waste (management, handling & transboundary movement) rules,2008' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='To provide the transporter with the relevant information in Form 9, regarding the hazardous nature of the wastes and measures to be taken in case of an emergency and shall label the hazardous and other wastes containers as per Form 8.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='RULE 18 Transportation of hazardous and other wastes.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form 9 and Form 8' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(9)} value={this.state.rows[9].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(9)}
                                                value={this.state.rows[9].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(9)}
                                            value={this.state.rows[9].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>


                                <tr id="addr10" key={10}>




                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Hazardous waste (management, handling & transboundary movement) rules,2008' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The sender of the waste shall prepare seven copies of the manifest in Form 10 comprising of colour code' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                     
                                        
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='RULE 19 Manifest system (Movement Document)' />
                                    </td >
                                    <td>
                                        <br />
                                      
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form 10 to be followed transporting hazardous waste' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(10)} value={this.state.rows[10].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(10)}
                                                value={this.state.rows[10].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(10)}
                                            value={this.state.rows[10].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr11" key={11}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The manufacture storage & import of hazardous chemical rules -1989' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Chemicals list as per schedule -i, ii & iii,  responsibility  identified the accident hazardous & report with 48 hours in schedule 5 under rules-2(b) and 3, and schedule-6 under rule 5 (1) ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                      
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Hazardous chemicals used MSDS for same to be made available for handling the chemical and information for the notification of sites' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Major accident report as per schedule-6 schedule-7(site)-' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(11)} value={this.state.rows[11].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(11)}
                                                value={this.state.rows[11].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(11)}
                                            value={this.state.rows[11].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr12" key={12}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The noise pollution (regulation & control) rules-2000 ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='The noise levels in any area shall not exceed the ambient air quality standards specified in the schedule also ' />
                                    </td >
                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Noise should not exceeds the ambient noise standard by 10 db.(a) as per rule-7 every worker employed shall examined after every 12 months if noise exceeds the maximum permissible.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='As per schedule, under rule 3(1) & 4(1)' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(12)} value={this.state.rows[12].License}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(12)}
                                                value={this.state.rows[12].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(12)}
                                            value={this.state.rows[12].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr13" key={13}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory license to be obtained. Approval of plans in form 2 when ever additional alteration in layout (under sec 7, see rule 4 (1), 7(2) and 12) ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='All abstracts displayed at the security gate.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='License copy' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(13)} value={this.state.rows[13].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    
                                       


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(13)}
                                                value={this.state.rows[13].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                      
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(13)}
                                            value={this.state.rows[13].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr14" key={14}>




                                    <td>
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Sec 110 under rule 120 (1)' />
                                    </td >
                                    <td>
                                        
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Submission of annual return in form-21' />
                                    </td >
                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form - 21' />
                                    </td >

                                    <td>
                                        <br />
                                      

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(14)} value={this.state.rows[14].License} />
                                    </td>
                                    <td>
                                        <br />
                                      


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(14)}
                                                value={this.state.rows[14].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                           

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(14)}
                                            value={this.state.rows[14].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr15" key={15}>




                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 6 (a) (b) (c) (d) rule 8' />
                                    </td >
                                    <td>



                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Submission of license renewal application' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form 4-b' />
                                    </td >

                                    <td>
                                        <br />


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(15)} value={this.state.rows[15].License}/>
                                    </td>
                                    <td>
                                        <br />



                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(15)}
                                                value={this.state.rows[15].Status}

                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(15)}
                                            value={this.state.rows[15].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr16" key={16}>




                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 11' />
                                    </td >
                                    <td>



                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Cleanliness' />
                                    </td >
                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Housekeeping checklist' />
                                    </td >

                                    <td>
                                        


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(16)} value={this.state.rows[16].License} />
                                    </td>
                                    <td>
                                      


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(16)}
                                                value={this.state.rows[16].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                     


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(16)}
                                            value={this.state.rows[16].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr17" key={17}>




                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 12' />
                                    </td >
                                    <td>



                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Disposal of wastes and effluents' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Waste disposal records' />
                                    </td >

                                    <td>
                                        <br />


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(17)} value={this.state.rows[17].License} />
                                    </td>
                                    <td>
                                        <br />



                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(17)}
                                                value={this.state.rows[17].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(17)}
                                            value={this.state.rows[17].Status}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr18" key={18}>




                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 13' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                    

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Ventilation & temperatures' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='AC, FANS and Ventilation to be provided in sufficient numbers' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                      
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(18)} value={this.state.rows[18].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      


                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(18)}
                                                value={this.state.rows[18].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(18)}
                                            value={this.state.rows[18].NextDate}
                                            InputLabelProps={{
                                                shrink: true,

                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr19" key={19}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 16' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Overcrowding' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='14.2 cubic meters around and 4.27 metres above the floor level of working space for workmen' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(19)} value={this.state.rows[19].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select 
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(19)}
                                                value={this.state.rows[19].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(19)}
                                            value={this.state.rows[19].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr20" key={20}>




                                    <td>
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                    

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 17' />
                                    </td >
                                    <td>

                                        <br />
                                      


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Lighting' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Work place illumination survey' />
                                    </td >

                                    <td>
                                        <br />
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(20)} value={this.state.rows[20].License} />
                                    </td>
                                    <td>
                                        <br />
                                        



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(20)}
                                                value={this.state.rows[20].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(20)}
                                            value={this.state.rows[20].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr21" key={21}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 18' />
                                    </td >
                                    <td>

                                    

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Drinking water' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Drinking water testing' />
                                    </td >

                                    <td>
                                     
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(21)} value={this.state.rows[21].License} />
                                    </td>
                                    <td>
                                      


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(21)}
                                                value={this.state.rows[21].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                       
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(21)}
                                            value={this.state.rows[21].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr22" key={22}>




                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                     

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 19' />
                                    </td >
                                    <td>

                                        


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Latrines and urinals' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Provided' />
                                    </td >

                                    <td>
                                      

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(22)} value={this.state.rows[22].License} />
                                    </td>
                                    <td>
                                       

                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(22)}
                                                value={this.state.rows[22].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(22)}
                                            value={this.state.rows[22].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr23" key={23}>




                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 20' />
                                    </td >
                                    <td>




                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Spittoons ' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Provided' />
                                    </td >

                                    <td>

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(23)} value={this.state.rows[23].License} />
                                    </td>
                                    <td>



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(23)}
                                                value={this.state.rows[23].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(23)}
                                            value={this.state.rows[23].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr24" key={24}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                     
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 21' />
                                    </td >
                                    <td>

                                      


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Fencing of machinery' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Provided' />
                                    </td >

                                    <td>
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(24)} value={this.state.rows[24].License} />
                                    </td>
                                    <td>
                                    


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(24)}
                                                value={this.state.rows[24].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(24)}
                                            value={this.state.rows[24].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr25" key={25}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 22' />
                                    </td >
                                    <td>

                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='No woman or child near the machine shall be engaged to clean the moving parts of the machinery' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(25)} value={this.state.rows[25].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(25)}
                                                value={this.state.rows[25].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(25)}
                                            value={this.state.rows[25].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr26" key={26}>




                                    <td>
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 26' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Casing of new machinery' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='As per requirement' />
                                    </td >

                                    <td>
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(26)} value={this.state.rows[26].License} />
                                    </td>
                                    <td>
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(26)}
                                                value={this.state.rows[26].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(26)}
                                            value={this.state.rows[26].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr27" key={27}>




                                    <td>
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 31' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Tastings reports' />
                                    </td >

                                    <td>
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(27)} value={this.state.rows[27].License}/>
                                    </td>
                                    <td>
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(27)}
                                                value={this.state.rows[27].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(27)}
                                            value={this.state.rows[27].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr28" key={28}>




                                    <td>
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                     
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 32' />
                                    </td >
                                    <td>

                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Floors, stairs and means of access' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Are sound in construction' />
                                    </td >

                                    <td>
                                        <br />
                                      
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(28)} value={this.state.rows[28].License} />
                                    </td>
                                    <td>
                                        <br />
                                      


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(28)}
                                                value={this.state.rows[28].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(28)}
                                            value={this.state.rows[28].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr29" key={29}>




                                    <td>
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 33' />
                                    </td >
                                    <td>

                                      
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Pits, sumps, openings in floors' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Proper civil work for filling pits etc' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(29)} value={this.state.rows[29].License} />
                                    </td>
                                    <td>
                                        <br />
                                      


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(29)}
                                                value={this.state.rows[29].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                      


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(29)}
                                            value={this.state.rows[29].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                              
                                <tr id="addr30" key={30}>




                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 34' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                        


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Excessive weights' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Not allowing people to lift excessive weight (adult male 50 kg, female 30 kg)' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(30)} value={this.state.rows[30].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(30)}
                                                value={this.state.rows[30].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                      


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(30)}
                                            value={this.state.rows[30].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr31" key={31}>




                                    <td>
                                        <br />
                                    
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 35' />
                                    </td >
                                    <td>

                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Protection of eyes' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='PPE provided according to work' />
                                    </td >

                                    <td>
                                        <br />
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(31)} value={this.state.rows[31].License} />
                                    </td>
                                    <td>
                                        <br />
                                      



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(31)}
                                                value={this.state.rows[31].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(31)}
                                            value={this.state.rows[31].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr32" key={32}>




                                    <td>
                                        <br />
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 36' />
                                    </td >
                                    <td>

                                     

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Precaution against dangerous fumes, gases etc.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='On site emergency plan' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(32)} value={this.state.rows[32].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(32)}
                                                value={this.state.rows[32].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(32)}
                                            value={this.state.rows[32].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr33" key={33}>




                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 38' />
                                    </td >
                                    <td>

                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Precaution in case of fire' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='On site emergency plan' />
                                    </td >

                                    <td>
                                      

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(33)} value={this.state.rows[33].License} />
                                    </td>
                                    <td>
                                        



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(33)}
                                                value={this.state.rows[33].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(33)}
                                            value={this.state.rows[33].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr34" key={34}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 44' />
                                    </td >
                                    <td>



                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Facilities for sitting' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Provided' />
                                    </td >

                                    <td>
                                      
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(34)} value={this.state.rows[34].License} />
                                    </td>
                                    <td>
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(34)}
                                                value={this.state.rows[34].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                      
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(34)}
                                            value={this.state.rows[34].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr35" key={35}>




                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 45' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='First aid appliances' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='First aid file' />
                                    </td >

                                    <td>
                                      

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(35)} value={this.state.rows[35].License} />
                                    </td>
                                    <td>
                                        



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(35)}
                                                value={this.state.rows[35].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                      
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(35)}
                                            value={this.state.rows[35].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr36" key={36}>




                                    <td>
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                    

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 46' />
                                    </td >
                                    <td>

                                        


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='For more than 250 workers hygienic canteen to be provided' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Canteen committee mom' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(36)} value={this.state.rows[36].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(36)}
                                                value={this.state.rows[36].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                     


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(36)}
                                            value={this.state.rows[36].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr37" key={37}>




                                    <td>
                                     

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 51' />
                                    </td >
                                    <td>

                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Weekly hours' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-11' />
                                    </td >

                                    <td>
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(37)} value={this.state.rows[37].License} />
                                    </td>
                                    <td>
                                    



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(37)}
                                                value={this.state.rows[37].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(37)}
                                            value={this.state.rows[37].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr38" key={38}>




                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 52' />
                                    </td >
                                    <td>



                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Weekly holidays' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-11' />
                                    </td >

                                    <td>
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(38)} value={this.state.rows[38].License} />
                                    </td>
                                    <td>
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(38)}
                                                value={this.state.rows[38].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                      


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(38)}
                                            value={this.state.rows[38].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr39" key={39}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 54' />
                                    </td >
                                    <td>

                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Daily hours' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-11' />
                                    </td >

                                    <td>
                                      

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(39)} value={this.state.rows[39].License} />
                                    </td>
                                    <td>
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(39)}
                                                value={this.state.rows[39].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(39)}
                                            value={this.state.rows[39].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr40" key={40}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 55' />
                                    </td >
                                    <td>

                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Intervals for rest' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-11' />
                                    </td >

                                    <td>
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(40)} value={this.state.rows[40].License} />
                                    </td>
                                    <td>
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(40)}
                                                value={this.state.rows[40].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(40)}
                                            value={this.state.rows[40].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                <tr id="addr41" key={41}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 56' />
                                    </td >
                                    <td>



                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Spread over' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='NA' />
                                    </td >

                                    <td>
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(41)} value={this.state.rows[41].License} />
                                    </td>
                                    <td>
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(41)}
                                                value={this.state.rows[41].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(41)}
                                            value={this.state.rows[41].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr42" key={42}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 57' />
                                    </td >
                                    <td>




                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Night shifts' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-11' />
                                    </td >

                                    <td>
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(42)} value={this.state.rows[42].License} />
                                    </td>
                                    <td>
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(42)}
                                                value={this.state.rows[42].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                       
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(42)}
                                            value={this.state.rows[42].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr43" key={43}>




                                    <td>
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 66' />
                                    </td >
                                    <td>

                                       
                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Restriction on employment of women' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='NA' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(43)} value={this.state.rows[43].License} />
                                    </td>
                                    <td>
                                        <br />
                                        



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(43)}
                                                value={this.state.rows[43].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(43)}
                                            value={this.state.rows[43].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr44" key={44}>




                                    <td>
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Under sec 88 & 88 a rule 110 and rule 10' />
                                    </td >
                                    <td>

                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Reporting of accident (reportable) dangerous occurrence' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form- 18 and form - 18a' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(44)} value={this.state.rows[44].License}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />




                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(44)}
                                                value={this.state.rows[19].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />



                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(44)}
                                            value={this.state.rows[44].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr45" key={45}>




                                    <td>
                                        <br />
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 3' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Fire NOC from local fire department' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire NOC' />
                                    </td >

                                    <td>
                                        <br />
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(45)} value={this.state.rows[45].License} />
                                    </td>
                                    <td>
                                        <br />


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(45)}
                                                value={this.state.rows[45].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(45)}
                                            value={this.state.rows[45].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                               

                                <tr id="addr46" key={46}>




                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Approval of plans' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-1' />
                                    </td >

                                    <td>
                                      

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(46)} value={this.state.rows[46].License} />
                                    </td>
                                    <td>
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(46)}
                                                value={this.state.rows[46].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                      

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(46)}
                                            value={this.state.rows[46].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr47" key={47}>




                                    <td>
                                        <br />
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 3a' />
                                    </td >
                                    <td>

                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Certificate of stability' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-2' />
                                    </td >

                                    <td>
                                        <br />
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(47)} />
                                    </td>
                                    <td>
                                        <br />
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(47)}
                                                value={this.state.rows[47].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(47)}
                                            value={this.state.rows[47].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr48" key={48}>




                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rules 14- d' />
                                    </td >
                                    <td>

                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Notice of change of manager' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form - 4-a' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(48)} value={this.state.rows[48].Status} />
                                    </td>
                                    <td>
                                        <br />
                                      



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(48)}
                                                value={this.state.rows[48].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                    


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(48)}
                                            value={this.state.rows[48].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr49" key={49}>




                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rules 48' />
                                    </td >
                                    <td>

                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Record of lime washing, painting etc.' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form - 8' />
                                    </td >

                                    <td>
                                        <br />
                                     

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(49)} value={this.state.rows[49].Status} />
                                    </td>
                                    <td>
                                        <br />
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(49)}
                                                value={this.state.rows[49].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(49)}
                                            value={this.state.rows[49].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr50" key={50}>




                                    <td>
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 31' />
                                    </td >
                                    <td>

                                        <br />
                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Standards of lighting' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='As per norms' />
                                    </td >

                                    <td>
                                        <br />
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(50)} value={this.state.rows[50].License} />
                                    </td>
                                    <td>
                                        <br />
                                        



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(50)}
                                                value={this.state.rows[50].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(50)}
                                            value={this.state.rows[50].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr51" key={51}>




                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 36' />
                                    </td >
                                    <td>

                                        <br />
                                     
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Quantity of drinking water' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='As per norms' />
                                    </td >

                                    <td>
                                        <br />
                                        
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(51)} value={this.state.rows[51].License} />
                                    </td>
                                    <td>
                                        <br />
                                      



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(51)}
                                                value={this.state.rows[51].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                      


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(51)}
                                            value={this.state.rows[51].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr52" key={52}>




                                    <td>
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 37' />
                                    </td >
                                    <td>

                                        <br />
                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Source of water' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Available' />
                                    </td >

                                    <td>
                                        <br />
                                     

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(52)} value={this.state.rows[52].License} />
                                    </td>
                                    <td>
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(52)}
                                                value={this.state.rows[52].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(52)}
                                            value={this.state.rows[52].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr53" key={53}>




                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 41' />
                                    </td >
                                    <td>

                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Latrine accommodation' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Provided' />
                                    </td >

                                    <td>
                                        <br />
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(53)} value={this.state.rows[53].License} />
                                    </td>
                                    <td>
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(53)}
                                                value={this.state.rows[53].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(53)}
                                            value={this.state.rows[53].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr54" key={54}>




                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 43' />
                                    </td >
                                    <td>

                                        <br />
                                        


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Privacy of latrines' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Separate ladies/gents toilet ' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(54)} value={this.state.rows[54].License} />
                                    </td>
                                    <td>
                                        <br />
                                      



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(54)}
                                                value={this.state.rows[54].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(54)}
                                            value={this.state.rows[54].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr55" key={55}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                     
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 44' />
                                    </td >
                                    <td>

                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Sign boards for latrines to be displayed' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Displayed' />
                                    </td >

                                    <td>
                                        <br />
                                      

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(55)} value={this.state.rows[55].License} />
                                    </td>
                                    <td>
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(55)}
                                                value={this.state.rows[55].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                      

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(55)}
                                            value={this.state.rows[55].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr56" key={56}>




                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 45' />
                                    </td >
                                    <td>

                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Urinal accommodation' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Physical Check' />
                                    </td >

                                    <td>
                                        <br />
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(59)} value={this.state.rows[59].License}/>
                                    </td>
                                    <td>
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(59)}
                                                value={this.state.rows[59].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(59)}
                                            value={this.state.rows[59].NextDay}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr57" key={57}>




                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 46-47' />
                                    </td >
                                    <td>

                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Connection to sewerage system' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Connected' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(57)} value={this.state.rows[57].License} />
                                    </td>
                                    <td>
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(57)}
                                                value={this.state.rows[57].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>



                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(57)}
                                            value={this.state.rows[57].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr58" key={58}>




                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 64' />
                                    </td >
                                    <td>

                                       
                                      


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Lifting machines, chains, ropes and lifting tackles' />
                                    </td >
                                    <td>

                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Testing reports' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(58)} value={this.state.rows[58].License} />
                                    </td>
                                    <td>
                                        <br />
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(58)}
                                                value={this.state.rows[58].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(58)}
                                            value={this.state.rows[58].NextDay}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr59" key={59}>




                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 61' />
                                    </td >
                                    <td>

                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Adequate means of protection and escape' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Available' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(59)} value={this.state.rows[59].License} />
                                    </td>
                                    <td>
                                        <br />
                                        



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(59)}
                                                value={this.state.rows[59].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(59)}
                                            value={this.state.rows[59].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr60" key={60}>




                                    <td>
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                     
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                        <br />
                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Access to fire fighting' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire extinguisher/hydrant are in sufficient quantity' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(60)} value={this.state.rows[60].License} />
                                    </td>
                                    <td>
                                        <br />
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(60)}
                                                value={this.state.rows[60].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                      

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(60)}
                                            value={this.state.rows[60].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr61" key={61}>




                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                    


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Protection against lighting' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Lighting Arrester provided' />
                                    </td >

                                    <td>
                                      
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(61)} value={this.state.rows[61].License} />
                                    </td>
                                    <td>
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(61)}
                                                value={this.state.rows[61].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(61)}
                                            value={this.state.rows[61].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr62" key={62}>




                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Precautions against ignition' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='N.A.' />
                                    </td >

                                    <td>
                                        <br />
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(62)} value={this.state.rows[62].License} />
                                    </td>
                                    <td>
                                        <br />
                                       

                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(62)}
                                                value={this.state.rows[62].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(62)}
                                            value={this.state.rows[62].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr63" key={63}>




                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Spontaneous ignitions' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='N.A.' />
                                    </td >

                                    <td>
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(63)} value={this.state.rows[63].License} />
                                    </td>
                                    <td>
                                      



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(63)}
                                                value={this.state.rows[63].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(63)}
                                            value={this.state.rows[63].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr64" key={64}>




                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Cylinders containing compressed gas' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='NA' />
                                    </td >

                                    <td>
                                        <br />
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(64)} value={this.state.rows[64].License} />
                                    </td>
                                    <td>
                                        <br />
                                      

                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(64)}
                                                value={this.state.rows[64].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                      

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(64)}
                                            value={this.state.rows[64].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr65" key={65}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                        


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Storage of flammable liquids (diesel)' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form no.3' />
                                    </td >

                                    <td>
                                        <br />
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(65)} value={this.state.rows[65].License} />
                                    </td>
                                    <td>
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(65)}
                                                value={this.state.rows[65].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(65)}
                                            value={this.state.rows[65].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr66" key={66}>




                                    <td>
                                        <br />
                                    

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                       
                                        


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Storage of flammable gases (LPG)' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form no.3' />
                                    </td >

                                    <td>
                                        <br />
                                        
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(66)} value={this.state.rows[66].License} />
                                    </td>
                                    <td>
                                        <br />
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(66)}
                                                value={this.state.rows[66].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                     

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(66)}
                                            value={this.state.rows[66].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr67" key={67}>




                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                     

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Storage of gases (Ar & CO2)' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form no.3' />
                                    </td >

                                    <td>
                                        <br />
                                        
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(67)} value={this.state.rows[67].License}/>
                                    </td>
                                    <td>
                                        <br />
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(67)}
                                                value={this.state.rows[67].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(67)}
                                            value={this.state.rows[67].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr68" key={68}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Accumulation of flammable dust, gas, fume or vapour in air flammable waste material on the floors' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                      
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Saw dust for spillage control' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(68)} value={this.state.rows[68].License}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(68)}
                                                value={this.state.rows[68].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(68)}
                                            value={this.state.rows[68].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>


                                <tr id="addr69" key={69}>




                                    <td>
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>

                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Fire exits' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Signs are at fire exits' />
                                    </td >

                                    <td>
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(69)} value={this.state.rows[69].License} />
                                    </td>
                                    <td>
                                      


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(69)}
                                                value={this.state.rows[69].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(69)}
                                            value={this.state.rows[69].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr70" key={70}>




                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 71b' />
                                    </td >
                                    <td>

                                     
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Firefighting apparatus and water supply' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Fire extinguisher / hydrant list' />
                                    </td >

                                    <td>
                                        <br />
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(70)} value={this.state.rows[70].License} />
                                    </td>
                                    <td>
                                      

                                        <br />
                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(70)}
                                                value={this.state.rows[70].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(70)}
                                            value={this.state.rows[70].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>


                                <tr id="addr71" key={71}>




                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 78' />
                                    </td >
                                    <td>

                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Notice of period of work for adults' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form 12 ' />
                                    </td >

                                    <td>
                                        <br />
                                        

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(71)} value={this.state.rows[71].License} />
                                    </td>
                                    <td>
                                        <br />
                                      



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(71)}
                                                value={this.state.rows[71].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(71)}
                                            value={this.state.rows[71].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr72" key={72}>




                                    <td>
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 120' />
                                    </td >
                                    <td>

                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Register of leave with wages' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form 14 ' />
                                    </td >

                                    <td>
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(72)} value={this.state.rows[72].License} />
                                    </td>
                                    <td>
                                        <br />
                                      


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(72)}
                                                value={this.state.rows[72].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(72)}
                                            value={this.state.rows[72].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr73" key={73}>




                                    <td>
                                        <br />
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Factory rules, 1969 under rule 122' />
                                    </td >
                                    <td>

                                     

                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Muster roll' />
                                    </td >
                                    <td>
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form 12' />
                                    </td >

                                    <td>
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(73)} value={this.state.rows[73].License} />
                                    </td>
                                    <td>
                                        <br />
                                       



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(73)}
                                                value={this.state.rows[73].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                      

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(73)}
                                            value={this.state.rows[73].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr74" key={74}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule-13- no employment of children<18years5' />
                                    </td >
                                    <td>

                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Please refer the book for marking the colour coding. And if the quantity goes beyond the prescribed limit license is required for the same.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Boards/no smoking signages displayed' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(74)} value={this.state.rows[74].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(74)}
                                                value={this.state.rows[74].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(74)}
                                            value={this.state.rows[74].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>


                                <tr id="addr75" key={75}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Cleanliness u/s-11 disposal of waste & effluents u/s 12(2) drinking water u/s18 lighting -us/17.  Latrines and urinals u/s19 ' />
                                    </td >
                                    <td>

                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='White wash done after every 14 month, waste & effluents as per water prevention & control of pollution act1974 drinking water near to work place 6mt. From urinals,urinals-separate for male & female compulsory disclosure of information.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Physically at site' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(75)} value={this.state.rows[75].License}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(75)}
                                                value={this.state.rows[75].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(75)}
                                            value={this.state.rows[75].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr76" key={76}>




                                    <td>
                                        <br />
                                        <br />
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                      
                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Annual health check-up of the employees in critical processes.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='FORM-16 Health Register' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(76)} value={this.state.rows[76].License}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(76)}
                                                value={this.state.rows[76].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                       


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(76)}
                                            value={this.state.rows[76].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr77" key={77}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Factories act 1948' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Washing facilities u/s-42 rule 64                                                                                                          storing/locker rooms u/s 43 rule-65 first a' />
                                    </td >
                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Washing facilities to be provided with wash basins, locker rooms to be provided, more than 500 workmen ambulance & dispensary to be provided, more than 250 workmen canteen to be provided, more than 150 workmen shelter /rest room to be provided.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Physically to check' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(77)} value={this.state.rows[77].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(77)}
                                                value={this.state.rows[77].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(77)}
                                            value={this.state.rows[77].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr78" key={78}>




                                    <td>
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The Indian electricity rules 1956.The electricity act 2003' />
                                    </td >
                                    <td>
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 61 earthing, rule 29 construction, installation, operation/maintenance of electric supply/apparatus.' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Rule 35 display of danger notices rule 44a intimation of accident rule 43 provision applicable to protective equipment.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Inspection reports' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(78)} value={this.state.rows[78].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(78)}
                                                value={this.state.rows[78].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(78)}
                                            value={this.state.rows[78].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr79" key={79}>




                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The Indian electricity rules 1956.The electricity act 2003' />
                                    </td >
                                    <td>
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Periodical inspection of dg set installation' />
                                    </td >
                                    <td>

                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Annually electrical inspectorate will inspect and submit reports.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Inspection reports' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                       
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(79)} value={this.state.rows[79].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                      


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(79)}
                                                value={this.state.rows[79].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                     
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(79)}
                                            value={this.state.rows[79].NextDate  }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr80" key={80}>




                                    <td>
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The Indian electricity rules 1956.The electricity act 2003' />
                                    </td >
                                    <td>
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Periodical inspection of HT/LT installation ' />
                                    </td >
                                    <td>

                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Annually electrical inspectorate will inspect and submit reports.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Inspection reports' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(80)} value={this.state.rows[80].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                       


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(80)}
                                                value={this.state.rows[80].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                       

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(80)}
                                            value={this.state.rows[80].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr81" key={81}>




                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The Indian electricity rules 1956.The electricity act 2003' />
                                    </td >
                                    <td>
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule-14- no. Smoking in storage area.' />
                                    </td >
                                    <td>

                                     


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Annually electrical inspectorate will inspect and submit reports. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Inspection Reports' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(81)} value={this.state.rows[81].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(81)}
                                                value={this.state.rows[81].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(81)}
                                            value={this.state.rows[81].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr82" key={82}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='The Petroleum rules 1976' />
                                    </td >
                                    <td>
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Section 116. Licence for storage- save as provided in secs. 7, 8 and 9 of the act no person shall store petroleum except under and in accordance with a licence granted under these rules' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='License to be obtained for storing of fuel based on classification and to be renewed from time to time. ' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='License copy' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(82)} value={this.state.rows[82].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />




                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(82)}
                                                value={this.state.rows[82].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(82)}
                                            value={this.state.rows[82].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr83" key={83}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Central Motor Vehicles Act 1988 & Rules 1989' />
                                    </td >
                                    <td>
                                     
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Every consignor intending to transport any dangerous or hazardous goods are loaded, supply to the owner of the goods carriage full and adequate information about such dangerous or hazardous goods so as to enable such owner and its driver to be aware of the risks created by such goods' />
                                    </td >
                                    <td>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Comply with the requirements of rules 129 to 137' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Copy of information' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(83)} value={this.state.rows[83].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(83)}
                                                value={this.state.rows[83].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(83)}
                                            value={this.state.rows[83].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr84" key={84}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Gas Cylinder Rules, 2004 under Explosives Act, 1884 ( 4 of 1884)' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='For obtaining licence to store Oxygen, Carbon di Oxide , Dissolved Acetylene & Liquefied ammonia gas cylinders' />
                                    </td >
                                    <td>

                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Licence for filling & possession, ii) Rule 49 & 55- Application for and renewal of licence, iii) Rule 21 - Storage of cylinders,   iv) Rule 18 - Handling & use  v) Rule 26 -  Re-testing of cylinders for further use and transport  vi) Rule 4 - No license needed for filling & possessing in certain cases.Form C - Application for licence, Form F - Licence granted to store compressed gas in cylinders' />
                                    </td >
                                    <td>
                                         <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Copy of license and Form C and Form F' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(84)} value={this.state.rows[84].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />




                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(84)}
                                                value={this.state.rows[84].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(84)}
                                            value={this.state.rows[84].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr85" key={85}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Bio-Medical Waste (Management and Handling) Rules, 1998) Revised 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Submission of Annual Report Rule 13' />
                                    </td >
                                    <td>


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Every occupier or operator of common bio-medical waste treatment facility shall submit an annual report to the prescribed authority in Form-IV, on or before the 30th June of every year' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form IV' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(85)} value={this.state.rows[85].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(85)}
                                                value={this.state.rows[85].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(85)}
                                            value={this.state.rows[85].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr86" key={86}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Reporting of Accident Rule 15' />
                                    </td >
                                    <td>

                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='In case of any major accident at any institution or facility or any other site while handling bio-medical waste, the authorized person shall intimate immediately to the prescribed authority about such accident and forward a report within twenty-four hours in writing regarding the remedial steps taken in Form I.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Report of Accident Form I' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(86)} value={this.state.rows[86].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(86)}
                                                value={this.state.rows[86].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(86)}
                                            value={this.state.rows[86].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr87" key={87}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Ozone depleting substances (regulation and control) rules 2000 (amended 2016)' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 3' />
                                    </td >
                                    <td>

                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Site shall not produce or cause to produce any ozone depleting substance after the date specified in column (5) of Schedule V unless he is registered with the authority specified in column (4) of that Schedule.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Copy of Information' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(87)} value={this.state.rows[87].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(87)}
                                                value={this.state.rows[87].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(87)}
                                            value={this.state.rows[87].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr88" key={88}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Plastic Waste Management Rule 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 9(6)' />
                                    </td >
                                    <td>

                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Maintain a record of details of the person engaged in supply of plastic used as raw material to manufacture carry bags or plastic sheet or like or cover made of plastic sheet or multi-layered packaging' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Copy of records' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(88)} value={this.state.rows[88].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(88)}
                                                value={this.state.rows[88].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(88)}
                                            value={this.state.rows[88].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr89" key={89}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Plastic Waste Management Rule 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 4 (c )' />
                                    </td >
                                    <td>

                                      


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='carry bag made of virgin or recycled plastic, shall not be less than fifty microns in thickness; plastic sheet or like, which is not an integral part of multi-layered packaging and cover made of plastic sheet used for packaging, wrapping the commodity shall not be less than fifty microns in thickness except where the thickness of such plastic sheets impair the functionality of the product;' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Verification' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(89)} value={this.state.rows[89].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(89)}
                                                value={this.state.rows[89].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(89)}
                                            value={this.state.rows[89].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr90" key={90}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Plastic Waste Management Rule 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 4 (a)' />
                                    </td >
                                    <td>

                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='carry bags and plastic packaging shall either be in natural shade which is without any added pigments or made using only those pigments and colourants which are in conformity with Indian Standard : IS 9833:1981.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Physical Verification' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(90)} value={this.state.rows[90].Status} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(90)}
                                                value={this.state.rows[90].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(90)}
                                            value={this.state.rows[90].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr91" key={91}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Plastic Waste Management Rule 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 8' />
                                    </td >
                                    <td>

                                     

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='take steps to minimize generation of plastic waste and segregate plastic waste at source in accordance with the Solid Waste Management Rules 2000 or as amended from time to time.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Process development and verification ' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(91)} value={this.state.rows[91].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(91)}
                                                value={this.state.rows[91].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(91)}
                                            value={this.state.rows[91].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr92" key={92}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='E-Waste (Management) Rules 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 9' />
                                    </td >
                                    <td>

                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Consumers or bulk consumers of electrical and electronic equipment listed in Schedule I shall ensure that e-waste generated by them is channelized through collection centre or dealer of authorised producer or dismantler or recycler or through the designated take back service provider of the producer to authorised dismantler or recycler.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Compliance document' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(92)} value={this.state.rows[92].License}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(92)}
                                                value={this.state.rows[92].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(92)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr93" key={93}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='E-Waste (Management) Rules 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 9' />
                                    </td >
                                    <td>

                                       


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Bulk consumers of electrical and electronic equipment listed in Schedule I shall maintain records of e-waste generated by them and make such records available for scrutiny by the SPCB.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form-2' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(93)} value={this.state.rows[93].License}/>
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(93)}
                                                value={this.state.rows[93].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(93)}
                                            value={this.state.rows[93].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr94" key={94}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='E-Waste (Management) Rules 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 9' />
                                    </td >
                                    <td>

                                       

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Bulk consumers of electrical and electronic equipment listed in Schedule I shall file annual returns in Form-3, to the concerned State Pollution Control Board on or before the 30th day of June following the financial year to which that return relates. Manifest for form 6.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form 3 & Form 6 ' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(94)} value={this.state.rows[94].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(94)}
                                                value={this.state.rows[94].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(94)}
                                            value={this.state.rows[94].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr95" key={95}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Construction and Demolition Waste Management Rules 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 14' />
                                    </td >
                                    <td>

                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='In case of any accident during construction and demolition waste processing or treatment or disposal facility, the officer in charge of the facility in the local authority or the operator of the facility shall report of the accident in Form-V to the local authority' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Form V' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(95)} value={this.state.rows[95].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />




                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(95)}
                                                value={this.state.rows[95].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(95)}
                                            value={this.state.rows[95].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>

                                <tr id="addr96" key={96}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                     

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Construction and Demolition Waste Management Rules 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 4' />
                                    </td >
                                    <td>

                                        

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Every waste generator shall be responsible for collection, segregation of concrete, soil and others and storage of construction and demolition waste generated, as directed or notified by the concerned local authority in consonance with these rules.' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                       
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Physical compliance and record' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(96)} value={this.state.rows[96].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />



                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(96)}
                                                value={this.state.rows[96].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(96)}
                                            value={this.state.rows[96].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>


                                <tr id="addr97" key={97}>




                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                      
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='Solid Waste Management Rules 2016' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Rule 4' />
                                    </td >
                                    <td>

                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="details" required id="standard-required" defaultValue='segregate and store the waste generated by them in three separate streams namely bio-degradable, non biodegradable and domestic hazardous wastes in suitable bins and handover segregated wastes to authorised waste pickers or waste collectors as per the direction or notification by the local authorities from time to time' />
                                    </td >
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TextField multiline InputProps={{ readOnly: true }} inputProps={{ style: { fontSize: 12 } }} style={{ width: '150px' }} selected type="text" name="unit" required id="standard-required" defaultValue='Physical compliance and record' />
                                    </td >

                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <TextField inputProps={{ style: { fontSize: 12 } }} type="text" name="License" id="standard-required" onChange={this.handleChange(98)} value={this.state.rows[98].License} />
                                    </td>
                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <FormControl>

                                            <Select
                                                style={{ fontSize: 12 }}
                                                name="Status"
                                                onChange={this.handleChange(98)}
                                                value={this.state.rows[98].Status}
                                            >

                                                <MenuItem value={this.state.type1} >Compliant</MenuItem>
                                                <MenuItem value={this.state.type2} >Non-Compliant</MenuItem>
                                                <MenuItem value={this.state.type3} >Not-Applicable</MenuItem>


                                            </Select>

                                        </FormControl>
                                    </td>


                                    <td>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />


                                        <TextField
                                            inputProps={{ style: { fontSize: 12 } }}
                                            style={{ width: '145px' }}

                                            name="NextDate"

                                            type="date"
                                            defaultValue="YYYY-MM-DD"
                                            onChange={this.handleChange(98)}
                                            value={this.state.rows[98].NextDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </td>

                                </tr>
                                </tbody>
                        </Table>
                        <button type="submit" style={{ float: 'right', marginLeft: '5px' }} className="btn btn-success" onSubmit={this.handleSubmit}>SUBMIT</button>

                    </Form>
                    <button type="submit" style={{ float: 'right' }} className="btn btn-success" onClick={this.handleSubmit1}>SAVE</button>
                    </Collapse>
                    

            

            </div >
        )
    }
}

export default Riskregister       