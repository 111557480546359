
import React, { useCallback, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

import '../index.css';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";


// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Info from "@material-ui/icons/InfoOutlined";
import Bookmark from "@material-ui/icons/OfflineBoltSharp";

import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";

import BugReport from "@material-ui/icons/Person";
import Code from "@material-ui/icons/Code";
import axios from "axios";


import Cloud from "@material-ui/icons/Cloud";
// layout for this page
import Admin from "../../src/components/layouts/Admin.js";
// core components
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import Table from "../components/Table/Table.js";
import Tasks from "../components/Tasks/Tasks.js";
import CustomTabs from "../components/CustomTabs/CustomTabs.js";
import Danger from "../components/Typography/Danger.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import CardBody from "../components/Card/CardBody.js";
import CardFooter from "../components/Card/CardFooter.js";

import { bugs, website, server } from "../components/variables/general.js";

import {

    emailsSubscriptionChart,
    completedTasksChart,
    wasteChart
} from "../components/variables/charts.js";


import styles from "../components/assets/jss/nextjs-material-dashboard/views/dashboardStyle.js";
import { height } from "devexpress-dashboard/model/index.metadata";
import { useEventCallback } from "@material-ui/core";
import { current } from "devextreme/ui/themes";
var delays = 80,
    durations = 500;

const tooltip = require('chartist-plugin-legend');
const hover = require('chartist-plugin-tooltips-updated');
const axistitle = require('chartist-plugin-axistitle')
const pointlabel = require('chartist-plugin-pointlabels')



function Dashboard() {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    let [locations, setLocations] = React.useState([]);
    let [currentloc, setcurrentloc] = React.useState(0);

    let [labels, setLabels] = React.useState([]);

    let [scopetotal1, setScopearray1] = React.useState([]);
    let [scopetotal2, setScopearray2] = React.useState([]);
    let [scopetotal3, setScopearray3] = React.useState([]);
    let [scopetotal4, setScopearray4] = React.useState([]);
    let [scopetotal5, setScopearray5] = React.useState([]);
    let [scopetotal6, setScopearray6] = React.useState([]);
    let [scopetotal7, setScopearray7] = React.useState([]);
    let [scopetotal8, setScopearray8] = React.useState([]);
    let [scopetotal9, setScopearray9] = React.useState([]);
    let [scopetotal10, setScopearray10] = React.useState(0);
    let [scopetotal11, setScopearray11] = React.useState(0);
    let [scopetotal12, setScopearray12] = React.useState(0);
    let [scopetotal13, setScopearray13] = React.useState(0);
    let [scopetotal14, setScopearray14] = React.useState(0);
    let [scopetotal15, setScopearray15] = React.useState(0);
    let [scopetotal16, setScopearray16] = React.useState(0);
    let [scopetotal17, setScopearray17] = React.useState(0);
    let [scopetotal18, setScopearray18] = React.useState(0);
    let [scopetotal19, setScopearray19] = React.useState(0);
    let [scopetotal20, setScopearray20] = React.useState(0);
    let [scopetotal21, setScopearray21] = React.useState(0);
    let [scopetotal22, setScopearray22] = React.useState(0);
    let [scopetotal23, setScopearray23] = React.useState(0);
    let [scopetotal24, setScopearray24] = React.useState([]);
    let [scopetotal25, setScopearray25] = React.useState([]);
    let [rows1, setrows1] = React.useState([]);
    let [rows2, setrows2] = React.useState([]);
    let [rows3, setrows3] = React.useState([]);
    let [rows4, setrows4] = React.useState([]);
    let [rows5, setrows5] = React.useState([]);
    let [rows6, setrows6] = React.useState([]);
    let [rows7, setrows7] = React.useState([]);
    let [rows8, setrows8] = React.useState([]);
    let [rows9, setrows9] = React.useState([]);
    let [rows10, setrows10] = React.useState([]);
    let [rows11, setrows11] = React.useState([]);
    let [rows12, setrows12] = React.useState([]);
    let [rows13, setrows13] = React.useState([]);
    let [rows14, setrows14] = React.useState([]);
    let [rows15, setrows15] = React.useState([]);
    let [rows16, setrows16] = React.useState([]);
    let [rows17, setrows17] = React.useState([]);
    let [rows18, setrows18] = React.useState([]);
    let [monthly1, setmonthly1] = React.useState([]);
    let [monthly2, setmonthly2] = React.useState([]);
    let [monthly3, setmonthly3] = React.useState([]);
    let [monthly4, setmonthly4] = React.useState([]);
    let [monthly5, setmonthly5] = React.useState([]);
    let [monthly6, setmonthly6] = React.useState([]);
    let [monthly7, setmonthly7] = React.useState([]);
    let [monthly8, setmonthly8] = React.useState([]);
    let [monthly9, setmonthly9] = React.useState([]);
    let [monthly10, setmonthly10] = React.useState([]);
    let [monthly11, setmonthly11] = React.useState([]);
    let [monthly12, setmonthly12] = React.useState([]);
    let [monthly13, setmonthly13] = React.useState([]);
    let [monthly14, setmonthly14] = React.useState([]);
    let [monthly15, setmonthly15] = React.useState([]);
    let [monthly16, setmonthly16] = React.useState([]);
    let [monthly17, setmonthly17] = React.useState([]);
    let [monthly18, setmonthly18] = React.useState([]);
    let [monthly19, setmonthly19] = React.useState([]);
    let [monthly20, setmonthly20] = React.useState([]);
    let [lastrows1, setlastrows1] = React.useState([]);
    let [lastrows2, setlastrows2] = React.useState([]);
    let [lastrows3, setlastrows3] = React.useState([]);
    let [lastrows4, setlastrows4] = React.useState([]);
    let [lastrows5, setlastrows5] = React.useState([]);
    let [lastrows6, setlastrows6] = React.useState([]);
    let [lastrows7, setlastrows7] = React.useState([]);
    let [lastrows8, setlastrows8] = React.useState([]);
    let [lastrows9, setlastrows9] = React.useState([]);
    let [lastrows10, setlastrows10] = React.useState([]);
    let [lastrows11, setlastrows11] = React.useState([]);
    let [lastrows12, setlastrows12] = React.useState([]);
    let [lastrows13, setlastrows13] = React.useState([]);
    let [lastrows14, setlastrows14] = React.useState([]);
    let [lastrows15, setlastrows15] = React.useState([]);
    let [lastrows16, setlastrows16] = React.useState([]);
    let [lastrows17, setlastrows17] = React.useState([]);
    let [lastrows18, setlastrows18] = React.useState([]);
    let [time, settime] = React.useState("Last Three Months");
    let [locationstate, setlocationstate] = React.useState(0);
    let [level, setlevel] = React.useState(0);
    const [rerender, setRerender] = React.useState(false);


    let [previousyear, setpreviousyear] = React.useState(0);
    React.useEffect(() => {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null) {
            window.location.href = '/'
            return;
        }
        let months = getLast3Months();
        var today = new Date();
        let longMonth = today.toLocaleString('en-us', { month: 'long' });
        var currentyear = 0;
        var year = 0;
        if (longMonth == "January") {
            currentyear = today.getFullYear() - 1;

        }
        else {
            currentyear = today.getFullYear();
        }
        if (utypeid == 1 || utypeid == 2 || utypeid == 3) {
            userwisedata();
        }
        var startDate = new Date('01' + '-' + 'January' + '-' + currentyear);
        var endDate = new Date('31' + '-' + 'December' + '-' + currentyear);
        var qstart = new Date('01' + '-' + months[2] + '-' + currentyear);
     /*   var quarterstart = qstart.toISOString().substring(0, 10);*/
        var quarterstart = qstart
        var qend = new Date('30' + '-' + months[0] + '-' + currentyear);
/*        var quarterend = qend.toISOString().substring(0, 10);*/
        var quarterend = qend

        getdatathreemonths(startDate, endDate, quarterstart, quarterend, months)


        if (window.location.pathname === '/dashboardesg') {

            document.getElementById("navribbon").style.display = "flex";
        }
        localStorage.setItem('homepage', false);

    }, [rerender]);
                                    

    const handleChange = (e) => {
        e.preventDefault();
                               
        const value = parseInt(e.target.value);
        setlocationstate(locationstate = 0)
        setlevel(level = value)

        if (value == 1 || value == 3) {

            getplantorofficewise(value)

            setLocations(locations = []);
            document.getElementById('location').style.display = 'inline';
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/GetUserRoleLocation', { params: { UserRoleId: value } })
                .then(response => {
                    let typesapi1 = response.data;
                    console.log(typesapi1);
                    setLocations(locations = typesapi1);
                }).catch(error => {
                    console.log(error);
                });
        }

        else if (value == 0) {
            document.getElementById('location').style.display = 'none';
            setLocations(locations = []);
            if (time == "Last Three Months") {
                setRerender(!rerender);

            }

            else if (time == "This Year") {
                getdatathisyearorlastyear(1)

            }

            else {
                getdatathisyearorlastyear(2)

            }
        }

        else {

            document.getElementById('location').style.display = 'none';
        }
    };





    const handleChange1 = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setcurrentloc(currentloc = value)
        getlevelwise(value)

    }

    const handleChange2 = (e) => {

        e.preventDefault();

        var value = e.target.value;
        settime(time = value)

        if (value == "Last Three Months") {
            if (level == 0) {
                setLocations(locations = []);
                document.getElementById('location').style.display = 'none';
                setScopearray1([]);
                setScopearray2([]);
                setScopearray3([]);
                setScopearray4([]);
                setScopearray5([]);
                setScopearray6([]);
                setScopearray7([]);
                setScopearray8([]);
                setScopearray9([]);
                setScopearray10(0);
                setScopearray11(0);
                setScopearray12(0);
                setScopearray13(0);

                setRerender(!rerender);
            }

            else {
                if (locationstate == 0) {
                    var newlabels = ['Q1', 'Q2', 'Q3', 'Q4']
                    setLabels(labels = newlabels)
                    getplantorofficewise(level)


                }
                else {
                    document.getElementById('location').style.display = 'inline';

                    setLabels(labels = labels)
                    getlevelwise(locationstate)

                }

            }


        }


        else if (value == "This Year") {

            if (level == 0) {
                setLocations(locations = []);
                document.getElementById('location').style.display = 'none';
                getdatathisyearorlastyear(1);
            }

            else {
                if (locationstate == 0) {
                    getdatathisyearorlastyear(1);
                }
                else {
                    document.getElementById('location').style.display = 'inline';
                    getdatathisyearorlastyear(1);
                }
            }


        }


        else {
            if (level == 0) {
                setLocations(locations = []);
                document.getElementById('location').style.display = 'none';
                getdatathisyearorlastyear(2);
            }

            else {
                if (locationstate == 0) {
                    getdatathisyearorlastyear(2);
                }
                else {
                    document.getElementById('location').style.display = 'inline';
                    getdatathisyearorlastyear(2);
                }
            }


        }




    };

    function isDisabled() {

        var utypeid = localStorage.getItem("utypeid")
        if (utypeid == 1 || utypeid == 2 || utypeid == 3) {

            return true
        }


        else {

            return false
        }

    }

    function decimalfilter(number) {
        
        if ((number % 1) > 0.5) {
            number = number + 1;
            return parseInt(number)

        }
        else {
           
            return parseInt(number)
        }
    }

    function getLast3Months() {

        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        var today = new Date();
        var last3Months = []
        var month = today.getMonth();


        var i = 0;
        do {

            if (month == 0) {
                month = 11;


            } else {
                month--;

            }
            last3Months.push(monthNames[month]);
            i++;
        }

        while (i < 3)

        return last3Months.reverse();
    }

    function userwisedata() {

       

        var utypeid = localStorage.getItem("utypeid")
        var location = localStorage.getItem("location")
        if (utypeid == 1 || utypeid == 2 || utypeid == 3) {
            document.getElementById('level').style.display = 'none';
            document.getElementById('location').style.display = 'inline';
            setlevel(level = 1)
            setlocationstate(locationstate = location)

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboard/GetUserRoleLocation')
                .then(response => {
                    let typesapi1 = response.data;
                    console.log(typesapi1);
                    var actloc = location - 1;
                    setLocations(locations = typesapi1);
                    setcurrentloc(currentloc = locations[actloc].locationId)
                })

                .catch(error => {
                    console.log(error);

                });
        }

        else {
            document.getElementById('level').style.display = 'inline';
            document.getElementById('location').style.display = 'none';

            setLocations(locations = []);
            setcurrentloc(currentloc = 0)
        }

    }


    async function getdatathreemonths(startDate, endDate, quarterstart, quarterend, months) {
      
        var location = localStorage.getItem("location")
        var utypeid = localStorage.getItem('utypeid')
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardDirectEmissionone', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows1(rows1 = response.data)
                var scope1 = 0;

                let scopearray1 = [];

                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                console.log(rows)
                setmonthly1(monthly1 = rows);
                for (var i = 0; i < months.length; i++) {
                    let rows1 = rows.filter(x => x.month == months[i]);

                    for (var j = 0; j < rows1.length; j++) {
                        scope1 = scope1 + rows1[j].total


                    }
                    if (scope1 == 0) {
                        scopearray1.push(null);
                    }
                    else {
                        scopearray1.push(Math.round(scope1));
                        scope1 = 0
                    }

                }



                setScopearray1(scopetotal1 = scopearray1);

                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardDirectEmissiontwo', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows2(rows2 = response.data)
                var scope2 = 0;

                let scopearray2 = [];

                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                console.log(rows)
                setmonthly2(monthly2 = rows);
                for (var i = 0; i < months.length; i++) {
                    let rows1 = rows.filter(x => x.month == months[i]);
                    for (var j = 0; j < rows1.length; j++) {

                        scope2 = scope2 + rows1[j].scope2

                    }
                    if (scope2 == 0) {
                        scopearray2.push(null);
                    }
                    else {
                        scopearray2.push(Math.round(scope2));
                        scope2 = 0
                    }

                }



                setScopearray2(scopetotal2 = scopearray2);
                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardCarbonEmission', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
               
                setrows17(rows17 = response.data)


                var scope1 = 0;
                console.log(response.data)
                let scopearray1 = [];

                                                              


                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                console.log(rows)
                setmonthly19(monthly19 = rows);
                for (var i = 0; i < months.length; i++) {
                    let rows1 = rows.filter(x => x.month == months[i]);

                    for (var j = 0; j < rows1.length; j++) {
                        scope1 = scope1 + rows1[j].sum


                    }
                    scope1 = Math.round(scope1 * 100) / 100
                    if (scope1 == 0) {
                        scopearray1.push(null);
                    }
                    else {
                        scopearray1.push(Math.round(scope1));
                        scope1 = 0
                    }

                }



                setScopearray24(scopetotal24 = scopearray1);

                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardRenewablEnergy', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows3(rows3 = response.data)
                var scope1 = 0;

                let scopearray1 = [];



                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month==months[2])
                setmonthly3(monthly3 = rows);
                for (var i = 0; i < months.length; i++) {
                    let rows1 = rows.filter(x => x.month == months[i]);
                    for (var j = 0; j < rows1.length; j++) {
                        scope1 = scope1 + rows1[j].energyConsumed


                    }
                    if (scope1 == 0) {
                        scopearray1.push(null);
                    }
                    else {
                        scopearray1.push(Math.round(scope1));
                        scope1 = 0
                    }

                }



                setScopearray3(scopetotal3 = scopearray1);

                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardNonRenewable', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows4(rows4 = response.data)
                var scope2 = 0;

                let scopearray2 = [];


                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                setmonthly4(monthly4 = rows);
                for (var i = 0; i < months.length; i++) {
                    let rows1 = rows.filter(x => x.month == months[i]);
                    for (var j = 0; j < rows1.length; j++) {

                        scope2 = scope2 + rows1[j].energyConsumed

                    }
                    if (scope2 == 0) {
                        scopearray2.push(null);
                    }
                    else {
                        scopearray2.push(Math.round(scope2));
                        scope2 = 0
                    }


                }


                setScopearray4(scopetotal4 = scopearray2);
                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWaterWithdrawal', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows5(rows5 = response.data)
                var scope1 = 0;

                let scopearray1 = [];


                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                setmonthly5(monthly5 = rows);
                for (var i = 0; i < months.length; i++) {
                    let rows1 = rows.filter(x => x.month == months[i]);
                    for (var j = 0; j < rows1.length; j++) {
                        scope1 = scope1 + rows1[j].total


                    }
                    if (scope1 == 0) {
                        scopearray1.push(null);
                    }
                    else {
                        scopearray1.push(Math.round(scope1));
                        scope1 = 0
                    }

                }



                setScopearray5(scopetotal5 = scopearray1);

                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWaterRecycled', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows6(rows6 = response.data)
                var scope2 = 0;

                let scopearray2 = [];

                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                setmonthly6(monthly6 = rows);
                for (var i = 0; i < months.length; i++) {
                    let rows1 = rows.filter(x => x.month == months[i]);
                    for (var j = 0; j < rows1.length; j++) {

                        scope2 = scope2 + rows1[j].quantity

                    }
                    if (scope2 == 0) {
                        scopearray2.push(null);
                    }
                    else {
                        scopearray2.push(Math.round(scope2));
                        scope2 = 0
                    }
                }




                setScopearray6(scopetotal6 = scopearray2);
                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWastebytd', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows7(rows7 = response.data)
                var scope1 = 0;
                var scope2 = 0;
                var scope3 = 0;

                let scopearray1 = [];
                let scopearray2 = [];
                let scopearray3 = [];



                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                setmonthly7(monthly7 = rows);
                for (var i = 0; i < months.length; i++) {
                    let rows1 = rows.filter(x => x.month == months[i]);
                    for (var j = 0; j < rows1.length; j++) {
                        scope1 = scope1 + rows1[j].quantityGenerated
                        scope2 = scope2 + rows1[j].quantityRecycled
                        scope3 = scope3 + rows1[j].disposal


                    }
                    if (scope1 == 0) {
                        scopearray1.push(null);
                    }
                    else {
                        scopearray1.push(Math.round(scope1));
                        scope1 = 0
                    }
                    if (scope2 == 0) {
                        scopearray2.push(null);
                    }
                    else {
                        scopearray2.push(Math.round(scope2));
                        scope2 = 0
                    }
                    if (scope3 == 0) {
                        scopearray3.push(null);
                    }
                    else {
                        scopearray3.push(Math.round(scope3));
                        scope3 = 0
                    }


                }



                setScopearray7(scopetotal7 = scopearray1);
                setScopearray8(scopetotal8 = scopearray2);
                setScopearray9(scopetotal9 = scopearray3);

                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardSfcLabelledMaterial', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows18(rows18 = response.data)
                var scope1 = 0;

                let scopearray1 = [];

                                   
          

                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                setmonthly20(monthly20 = rows);
                
                    let value = 20;
                for (var j = 0; j < rows.length; j++) {

                    let rows1 = rows.filter(x => x.rmasterId == value)
                    for (var k = 0; k < rows1.length; k++) {
                        scope1 = scope1 + rows1[k].percentage

                    }
                    scope1=scope1/2

                    value = value + 1;

                    if (scope1 == 0) {
                        scopearray1.push(0);
                    }
                    else {
                        scopearray1.push(scope1);
                        scope1 = 0
                    }

                }



                setScopearray25(scopetotal25 = scopearray1);

                setLabels(labels = months);
            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEnergyIntensity', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows8(rows8 = response.data)
                var scope1 = 0;




                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                setmonthly8(monthly8 = rows);
                for (var j = 0; j < rows.length; j++) {
                    scope1 = scope1 + rows[j].intensity


                }






                setScopearray10(scopetotal10 = scope1);


            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardCostAvoidance', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows9(rows9 = response.data)
                var scope1 = 0;


                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                setmonthly9(monthly9 = rows);
                for (var j = 0; j < rows.length; j++) {
                    scope1 = scope1 + rows[j].total

                }




                setScopearray11(scopetotal11 = scope1);


            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardTotalExpenditure', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows10(rows10 = response.data)
                var scope1 = 0;

                let rows = response.data.filter(x => x.monthFor == months[0] || x.monthFor == months[1] || x.monthFor == months[2])
                setmonthly10(monthly10 = rows);
                for (var j = 0; j < rows.length; j++) {
                    scope1 = scope1 + rows[j].totalExpense

                }



                console.log(scope1)
                setScopearray12(scopetotal12 = scope1);


            })

            .catch(error => {
                console.log(error);

            });


        axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEnvLegalCompliance', { params: { startDate: startDate, endDate: endDate } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardSafLegalCompliance', { params: { startDate: startDate, endDate: endDate } }),



        ]).then(axios.spread((...responses) => {
            
            var scope1 = 0;
            var scope2 = 0;
            setrows11(rows11 = responses[0].data)
            setrows12(rows12 = responses[1].data)

            let rows = responses[0].data.filter(x => x.complianceMonth == months[0] || x.complianceMonth == months[1] || x.complianceMonth == months[2])
            setmonthly11(monthly11 = rows);

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].finePaid

            }




            let rows1 = responses[1].data.filter(x => x.complianceMonth == months[0] || x.complianceMonth == months[1] || x.complianceMonth == months[2])
            setmonthly12(monthly12 = rows1);
            for (var j = 0; j < rows1.length; j++) {
                scope2 = scope2 + rows1[j].finePaid

            }

            var scope3 = scope1 + scope2;
            console.log(scope1)
            setScopearray23(scopetotal23 = scope3);




        }))


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWorkRelatedFatalities', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows13(rows13 = response.data)
                var scope1 = 0;
                var scope2 = 0;


                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2])
                setmonthly13(monthly13 = rows);
                for (var j = 0; j < rows.length; j++) {
                    scope1 = scope1 + rows[j].employeeFatalities
                    scope2 = scope2 + rows[j].contractorFatalities
                }





                setScopearray13(scopetotal13 = scope1);
                setScopearray14(scopetotal14 = scope2);

            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardFireAccident', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows14(rows14 = response.data)
                var scope1 = 0;
                var scope2 = 0;



                let rows = response.data.filter(x => x.monthofFire == months[0] || x.monthofFire == months[1] || x.monthofFire == months[2])
                setmonthly14(monthly14 = rows);
                for (var j = 0; j < rows.length; j++) {
                    scope1 = scope1 + rows[j].totalAccidents
                    scope2 = scope2 + rows[j].totalAccidentsContractor
                }



                setScopearray15(scopetotal15 = scope1);
                setScopearray16(scopetotal16 = scope2);


            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEhsTraining', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows15(rows15 = response.data)
                var scope1 = 0;
                var scope2 = 0;


                let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2] && x.type == "Employees");
                setmonthly15(monthly15 = rows);
                for (var j = 0; j < rows.length; j++) {
                    scope1 = scope1 + rows[j].noOfTrainingHours

                }



                let rows1 = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2] && x.type == "Contractors");
                setmonthly16(monthly16 = rows1);
                for (var j = 0; j < rows1.length; j++) {

                    scope2 = scope2 + rows1[j].noOfTrainingHours
                }



                setScopearray17(scopetotal17 = scope1);
                setScopearray18(scopetotal18 = scope2);

            })

            .catch(error => {
                console.log(error);

            });

        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardTriAndLtiFr', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                
                setrows16(rows16 = response.data)
                var scope1 = 0;
                var scope2 = 0;
                var scope3 = 0;
                var scope4 = 0;

                for (var i = 0; i < months.length; i++) {
                    let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2] && x.type == "Employees");
                    setmonthly17(monthly17 = rows);
                    for (var j = 0; j < rows.length; j++) {
                        scope1 = scope1 + rows[j].triFr
                        scope2 = scope2 + rows[j].ltiFr
                    }

                }
                for (var i = 0; i < months.length; i++) {
                    let rows = response.data.filter(x => x.month == months[0] || x.month == months[1] || x.month == months[2] && x.type == "Contractors");
                    setmonthly18(monthly18 = rows);
                    for (var j = 0; j < rows.length; j++) {

                        scope3 = scope3 + rows[j].triFr
                        scope4 = scope4 + rows[j].ltiFr
                    }

                }

                scope1 = Math.round(scope1 * 100) / 100
                scope3 = Math.round(scope3 * 100) / 100
                setScopearray19(scopetotal19 = scope1);
                setScopearray20(scopetotal20 = scope2);
                setScopearray21(scopetotal21 = scope3);
                setScopearray22(scopetotal22 = scope4);

            })

            .catch(error => {
                console.log(error);

            });
        
        if (utypeid != 4) {
            getlevelwise(location)
        }
    }

    function getdatathisyear(start, end, loc) {
        
        var scope1 = 0;
        var scope2 = 0;
        var scope3 = 0;
        var scope4 = 0;
        let scopearray1 = [];
        let scopearray2 = [];
        let scopearray3 = [];
        let scopearray4 = [];
        let scopearray5 = [];
        let scopearray6 = [];
        let scopearray7 = [];
        let scopearray8 = [];
        let scopearray9 = [];
        let scopearray10 = [];
        let scopearray11 = [];

        var newlabels = ['Q1', 'Q2', 'Q3', 'Q4']
        setLabels(labels = newlabels)
        //var startDate = start.toISOString().substring(0, 10);
        //var endDate = end.toISOString().substring(0, 10);
        var startDate = start
        var endDate=end
        var today = new Date();
        const currentyear = today.getFullYear();

        var monthsall = ["April", "May", "June",
            "July", "August", "September", "October", "November", "December", "January", "February", "March",
        ];


        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"]

        var startindex = 0;
        var midindex = 1
        var endindex = 2;
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardDirectEmissionone', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                
                setrows1(rows1 = response.data);
                startindex = 0;
                midindex = 1
                endindex = 2;
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].total

                    }
                    if (scope1 == 0) {
                        scopearray1.push(null);
                    }
                    else {
                        scopearray1.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;


                setScopearray1(scopetotal1 = scopearray1);

            })
            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardDirectEmissiontwo', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows2(rows2 = response.data);
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]

                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear)|| (x.month == monthsall[endindex] && x.year == currentyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].scope2

                    }
                    if (scope1 == 0) {
                        scopearray2.push(null);
                    }
                    else {
                        scopearray2.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;

                setScopearray2(scopetotal2 = scopearray2);

            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardCarbonEmission', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows17(rows17 = response.data);
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)));

                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].sum

                    }
                    if (scope1 == 0) {
                        scopearray10.push(null);
                    }
                    else {
                        scopearray10.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;
                setScopearray24(scopetotal24 = scopearray10);

            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardRenewablEnergy', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows3(rows3 = response.data);

                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].energyConsumed

                    }
                    if (scope1 == 0) {
                        scopearray3.push(null);
                    }
                    else {
                        scopearray3.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;


                setScopearray3(scopetotal3 = scopearray3);
            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardNonRenewable', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows4(rows4 = response.data);
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].energyConsumed

                    }
                    if (scope1 == 0) {
                        scopearray4.push(null);
                    }
                    else {
                        scopearray4.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;

                setScopearray4(scopetotal4 = scopearray4);
            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWaterWithdrawal', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows5(rows5 = response.data);

                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.yearTotal == currentyear) ||(x.month == monthsall[midindex] && x.yearTotal == currentyear) || (x.month == monthsall[endindex] && x.yearTotal == currentyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.yearTotal == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.yearTotal == currentyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.yearTotal == currentyear) || (x.month == monthsall[midindex] && x.yearTotal == currentyear) || (x.month == monthsall[endindex] && x.yearTotal == currentyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].total

                    }
                    if (scope1 == 0) {
                        scopearray5.push(null);
                    }
                    else {
                        scopearray5.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;

                setScopearray5(scopetotal5 = scopearray5);
            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWaterRecycled', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {

                debugger;
                setrows6(rows6 = response.data);

                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]

                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].quantity

                    }
                    if (scope1 == 0) {
                        scopearray6.push(null);
                    }
                    else {
                        scopearray6.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;


                setScopearray6(scopetotal6 = scopearray6);
            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWastebytd', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;

                setrows7(rows7 = response.data);
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == currentyear) || (x.month == monthsall[midindex] && x.year == currentyear) || (x.month == monthsall[endindex] && x.year == currentyear)));
                    for (var j = 0; j < rows.length; j++) {
                        scope1 = scope1 + rows[j].quantityGenerated
                        scope2 = scope2 + rows[j].quantityRecycled
                        scope3 = scope3 + rows[j].disposal

                    }
                    if (scope1 == 0) {
                        scopearray7.push(null);
                    }
                    else {
                        scopearray7.push(Math.round(scope1));
                        scope1 = 0
                    }
                    if (scope2 == 0) {
                        scopearray8.push(null);
                    }
                    else {
                        scopearray8.push(Math.round(scope2));
                        scope2 = 0
                    }
                    if (scope3 == 0) {
                        scopearray9.push(null);
                    }
                    else {
                        scopearray9.push(Math.round(scope3));
                        scope3 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }



                setScopearray7(scopetotal7 = scopearray7);
                setScopearray8(scopetotal8 = scopearray8);
                setScopearray9(scopetotal9 = scopearray9);

            })

            .catch(error => {
                console.log(error);

            });
        var startmonth = monthNames[0];
        var endmonth = monthNames[11];
        var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
        var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
        var startDate = sDate.toISOString().substring(0, 10);
        var endDate = eDate.toISOString().substring(0, 10);

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEnergyIntensity', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {

                debugger;
                setrows8(rows8 = response.data);
                var startmonth = monthNames[0];
                var endmonth = monthNames[11];
                var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
                let finalrows = response.data.filter(loc == 0 && level == 0 ? x => x.year == currentyear : loc == 0 && level == 1 || level == 3 ? x =>  x.userRoleId == level : x => x.locationId == loc);
                debugger;
                for (var j = 0; j < finalrows.length; j++) {
                    scope1 = scope1 + finalrows[j].intensity


                }


                setScopearray10(scopetotal10 = scope1);
                scope1 = 0;

            })

            .catch(error => {
                console.log(error);

            });

        var startmonth = monthNames[0];
        var endmonth = monthNames[11];
        var sDate = new Date('01' + '-' + startmonth + '-' + currentyear);
        var eDate = new Date('31' + '-' + endmonth + '-' + currentyear);
        var startDate = sDate.toISOString().substring(0, 10);
        var endDate = eDate.toISOString().substring(0, 10);

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardSfcLabelledMaterial', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                
                setrows18(rows18 = response.data);
                var value = 20;
                for (var i = 0; i < 4; i++) {

                    let rows = response.data.filter(loc == 0 && level == 0 ? x => x.rmasterId == value : loc == 0 && level == 1 || level == 3 ? x => x.rmasterId == value && x.userRoleId == level : x => x.rmasterId == value && x.locationId == loc);
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].percentage / 2

                    }
                    if (scope1 == 0) {
                        scopearray11.push(0);
                    }
                    else {
                        scopearray11.push(scope1);
                        scope1 = 0
                    }
                    value = value + 1;
                }



                setScopearray25(scopetotal25 = scopearray11);
                console.log(scopetotal25)
            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardCostAvoidance', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;

                setrows9(rows9 = response.data);


                let finalrows1 = response.data.filter(loc == 0 && level == 0 ? x => x.year==currentyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);

                for (var j = 0; j < finalrows1.length; j++) {
                    scope1 = scope1 + finalrows1[j].total
                }


                setScopearray11(scopetotal11 = scope1);
                scope1 = 0;

            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardTotalExpenditure', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows10(rows10 = response.data);

                let finalrows2 = response.data.filter(loc == 0 && level == 0 ? x => x.yearFor ==currentyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);

                for (var j = 0; j < finalrows2.length; j++) {
                    scope1 = scope1 + finalrows2[j].totalExpense
                }


                setScopearray12(scopetotal12 = scope1);
                scope1 = 0;

            })

            .catch(error => {
                console.log(error);

            });


        axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEnvLegalCompliance', { params: { startDate: startDate, endDate: endDate } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardSafLegalCompliance', { params: { startDate: startDate, endDate: endDate } }),



        ]).then(axios.spread((...responses) => {
            debugger;
            setrows11(rows11 = responses[0].data);
            setrows12(rows12 = responses[1].data);

            let finalrows3 = responses[0].data.filter(loc == 0 && level == 0 ? x => x.complianceYear == currentyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);

            for (var i = 0; i < finalrows3.length; i++) {
                scope1 = scope1 + finalrows3[i].finePaid

            }


            let finalrows4 = responses[1].data.filter(loc == 0 && level == 0 ? x => x.complianceYear == currentyear : loc == 0 && level == 1 || level == 3 ? x =>  x.userRoleId == level : x =>  x.locationId == loc);

            for (var j = 0; j < finalrows4.length; j++) {
                scope2 = scope2 + finalrows4[j].finePaid

            }

            var scope3 = scope1 + scope2
            setScopearray23(scopetotal23 = scope3)
            scope1 = 0;
            scope2 = 0;
        }))


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWorkRelatedFatalities', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows13(rows13 = response.data);
                let finalrows5 = response.data.filter(loc == 0 && level == 0 ? x => x.year == currentyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);

                for (var j = 0; j < finalrows5.length; j++) {
                    scope1 = scope1 + finalrows5[j].employeeFatalities
                    scope2 = scope2 + finalrows5[j].contractorFatalities
                }


                setScopearray13(scopetotal13 = scope1);
                setScopearray14(scopetotal14 = scope2);

                scope1 = 0;
                scope2 = 0;
            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardFireAccident', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setrows14(rows14 = response.data);
                let finalrows6 = response.data.filter(loc == 0 && level == 0 ? x => x.fireYear == currentyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);

                for (var j = 0; j < finalrows6.length; j++) {
                    scope1 = scope1 + finalrows6[j].totalAccidents
                    scope2 = scope2 + finalrows6[j].totalAccidentsContractor
                }



                setScopearray15(scopetotal15 = scope1);
                setScopearray16(scopetotal16 = scope2);


            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEhsTraining', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {


                setrows15(rows15 = response.data);
                let finalrows7 = response.data.filter(loc == 0 && level == 0 ? x => x.type == "Employees" : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level && x.type == "Employees" : x => x.locationId == loc && x.type == "Employees");

                for (var j = 0; j < finalrows7.length; j++) {
                    scope1 = scope1 + finalrows7[j].noOfTrainingHours

                }
                let finalrows8 = response.data.filter(loc == 0 && level == 0 ? x => x.type == "Contractors" : loc == 0 && level == 1 || level == 3 ? x =>  x.userRoleId == level && x.type == "Contractors" : x => x.locationId == loc && x.type == "Contractors");
                for (var j = 0; j < finalrows8.length; j++) {

                    scope2 = scope2 + finalrows8[j].noOfTrainingHours
                }
                setScopearray17(scopetotal17 = scope1);
                setScopearray18(scopetotal18 = scope2);
                scope1 = 0;
                scope2 = 0;
            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardTriAndLtiFr', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {

                setrows16(rows16 = response.data);
                let finalrows9 = response.data.filter(loc == 0 && level == 0 ? x => x.type == "Employees" : loc == 0 && level == 1 || level == 3 ? x =>  x.userRoleId == level && x.type == "Employees" : x => x.locationId == loc && x.type == "Employees");


                for (var j = 0; j < finalrows9.length; j++) {
                    scope1 = scope1 + finalrows9[j].triFr
                    scope2 = scope2 + finalrows9[j].ltiFr
                }



                let finalrows10 = response.data.filter(loc == 0 && level == 0 ? x => x.type == "Contractors" : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level && x.type == "Contractors" : x => x.locationId == loc && x.type == "Contractors");

                for (var j = 0; j < finalrows10.length; j++) {

                    scope3 = scope3 + finalrows10[j].triFr
                    scope4 = scope4 + finalrows10[j].ltiFr
                }



                scope1 = Math.round(scope1 * 100) / 100
                scope3 = Math.round(scope3 * 100) / 100
                setScopearray19(scopetotal19 = scope1);
                setScopearray20(scopetotal20 = scope2);
                setScopearray21(scopetotal21 = scope3);
                setScopearray22(scopetotal22 = scope4);
            })

            .catch(error => {
                console.log(error);

            });


    }

    function getdatalastyear(start, end, loc) {

        var scope1 = 0;
        var scope2 = 0;
        var scope3 = 0;
        var scope4 = 0;
        let scopearray1 = [];
        let scopearray2 = [];
        let scopearray3 = [];
        let scopearray4 = [];
        let scopearray5 = [];
        let scopearray6 = [];
        let scopearray7 = [];
        let scopearray8 = [];
        let scopearray9 = [];
        let scopearray10 = [];
        let scopearray11 = [];
        var newlabels = ['Q1', 'Q2', 'Q3', 'Q4']
        setLabels(labels = newlabels)
        //var startDate = start.toISOString().substring(0, 10);
        //var endDate = end.toISOString().substring(0, 10);
        var startDate = start
        var endDate = end
        var startindex = 0;
        var endindex = 2;
        var today = new Date();
        const currentyear = today.getFullYear();
        const prevyear = currentyear - 1;
        var monthsall = ["April", "May", "June",
            "July", "August", "September", "October", "November", "December", "January", "February", "March",
        ];


        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"]
        var startindex = 0;
        var midindex = 1
        var endindex = 2;
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardDirectEmissionone', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows1(lastrows1 = response.data)
                 startindex = 0;
                 midindex = 1;
                 endindex = 2;



                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];

                    var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].total

                    }
                    if (scope1 == 0) {
                        scopearray1.push(null);
                    }
                    else {
                        scopearray1.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;

                setScopearray1(scopetotal1 = scopearray1);
            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardDirectEmissiontwo', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows2(lastrows2 = response.data)

                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]

                    var endmonth = monthsall[endindex];
                    var today = new Date();
                    var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)));

                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].scope2

                    }
                    if (scope1 == 0) {
                        scopearray2.push(null);
                    }
                    else {
                        scopearray2.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;
                setScopearray2(scopetotal2 = scopearray2);
            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardCarbonEmission', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows17(lastrows17 = response.data)

                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]

                    var endmonth = monthsall[endindex];
                    var today = new Date();
                    var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => x.createdDate > startDate && x.createdDate <= endDate : loc == 0 && level == 1 || level == 3 ? x => x.createdDate > startDate && x.createdDate <= endDate && x.userRoleId == level : x => x.createdDate > startDate && x.createdDate <= endDate && x.locationId == loc);

                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].scope2

                    }
                    if (scope1 == 0) {
                        scopearray10.push(null);
                    }
                    else {
                        scopearray10.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;
                setScopearray24(scopetotal24 = scopearray10);
            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardRenewablEnergy', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows3(lastrows3 = response.data)
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]

                    var endmonth = monthsall[endindex];
                    var today = new Date();
                    var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].energyConsumed

                    }
                    if (scope1 == 0) {
                        scopearray3.push(null);
                    }
                    else {
                        scopearray3.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;


                setScopearray3(scopetotal3 = scopearray3);
            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardNonRenewable', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows4(lastrows4 = response.data)
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]

                    var endmonth = monthsall[endindex];
                    var today = new Date();
                    var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].energyConsumed

                    }
                    if (scope1 == 0) {
                        scopearray4.push(null);
                    }
                    else {
                        scopearray4.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;

                setScopearray4(scopetotal4 = scopearray4);
            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWaterWithdrawal', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows5(lastrows5 = response.data)
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]


                    var endmonth = monthsall[endindex];
                    var today = new Date();
                    var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.yearTotal == prevyear) || (x.month == monthsall[midindex] && x.yearTotal == prevyear) || (x.month == monthsall[endindex] && x.yearTotal == prevyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.yearTotal == prevyear) || (x.month == monthsall[midindex] && x.yearTotal == prevyear) || (x.month == monthsall[endindex] && x.yearTotal == prevyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.yearTotal == prevyear) || (x.month == monthsall[midindex] && x.yearTotal == prevyear) || (x.month == monthsall[endindex] && x.yearTotal == prevyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].total

                    }
                    if (scope1 == 0) {
                        scopearray5.push(null);
                    }
                    else {
                        scopearray5.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;


                setScopearray5(scopetotal5 = scopearray5);
            })

            .catch(error => {
                console.log(error);

            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWaterRecycled', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows6(lastrows6 = response.data)
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];
                    var today = new Date();
                    var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);
                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear)|| (x.month == monthsall[endindex] && x.year == prevyear)));
                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].quantity

                    }
                    if (scope1 == 0) {
                        scopearray6.push(null);
                    }
                    else {
                        scopearray6.push(Math.round(scope1));
                        scope1 = 0
                    }
                    startindex = startindex + 3
                    midindex = midindex + 3
                    endindex = endindex + 3;
                }
                startindex = 0;
                midindex = 1
                endindex = 2;

                setScopearray6(scopetotal6 = scopearray6);

            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWastebytd', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows7(lastrows7 = response.data)
                for (var i = 0; i < 4; i++) {
                    var startmonth = monthsall[startindex];
                    var midmonth = monthsall[midindex]
                    var endmonth = monthsall[endindex];
                    var today = new Date();
                    var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
                    var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
                    var startDate = sDate.toISOString().substring(0, 10);
                    var endDate = eDate.toISOString().substring(0, 10);

                    let rows = response.data.filter(loc == 0 && level == 0 ? x => (x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear) : loc == 0 && level == 1 || level == 3 ? x => ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)) && x.userRoleId == level : x => x.locationId == loc && ((x.month == monthsall[startindex] && x.year == prevyear) || (x.month == monthsall[midindex] && x.year == prevyear) || (x.month == monthsall[endindex] && x.year == prevyear)));
                    for (var j = 0; j < rows.length; j++) {
                        scope1 = scope1 + rows[j].quantityGenerated
                        scope2 = scope2 + rows[j].quantityRecycled
                        scope3 = scope3 + rows[j].disposal

                    }
                    if (scope1 == 0) {
                        scopearray7.push(null);
                    }
                    else {
                        scopearray7.push(Math.round(scope1));
                        scope1 = 0
                    }
                    if (scope2 == 0) {
                        scopearray8.push(null);
                    }
                    else {
                        scopearray8.push(Math.round(scope2));
                        scope2 = 0
                    }
                    if (scope3 == 0) {
                        scopearray9.push(null);
                    }
                    else {
                        scopearray9.push(Math.round(scope3));
                        scope3 = 0
                    }
                   startindex = startindex + 3
            midindex=midindex+3
            endindex = endindex + 3;
                }




                setScopearray7(scopetotal7 = scopearray7);
                setScopearray8(scopetotal8 = scopearray8);
                setScopearray9(scopetotal9 = scopearray9);

                setLabels(labels = labels);
            })

            .catch(error => {
                console.log(error);

            });

        var startmonth = monthNames[0];
        var endmonth = monthNames[11];
        var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
        var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
        var startDate = sDate.toISOString().substring(0, 10);
        var endDate = eDate.toISOString().substring(0, 10);
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEnergyIntensity', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setlastrows8(lastrows8 = response.data)
                let finalrows = response.data.filter(loc == 0 && level == 0 ? x => x.year == prevyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);
                for (var j = 0; j < finalrows.length; j++) {
                    scope1 = scope1 + finalrows[j].intensity


                }


                setScopearray10(scopetotal10 = scope1);
                scope1 = 0;



            })

            .catch(error => {
                console.log(error);

            });

        var startmonth = monthNames[0];
        var endmonth = monthNames[11];
        var sDate = new Date('01' + '-' + startmonth + '-' + prevyear);
        var eDate = new Date('31' + '-' + endmonth + '-' + prevyear);
        var startDate = sDate.toISOString().substring(0, 10);
        var endDate = eDate.toISOString().substring(0, 10);

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardSfcLabelledMaterial', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {


                setlastrows18(lastrows18 = response.data)

                var value = 20;
                for (var i = 0; i < 4; i++) {

                    let rows = response.data.filter(loc == 0 && level == 0 ? x => x.rmasterId == value : loc == 0 && level == 1 || level == 3 ? x => x.rmasterId == value && x.userRoleId == level : x => x.rmasterId == value && x.locationId == loc);                    for (var j = 0; j < rows.length; j++) {

                        scope1 = scope1 + rows[j].percentage / 2

                    }
                    if (scope1 == 0) {
                        scopearray11.push(0);
                    }
                    else {
                        scopearray11.push(scope1);
                        scope1 = 0
                    }
                    value = value + 1;
                }



                setScopearray25(scopetotal25 = scopearray11);
                console.log(scopetotal25)
            })


            .catch(error => {
                console.log(error);
            })
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardCostAvoidance', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                debugger;
                setlastrows9(lastrows9 = response.data)
                let finalrows1 = response.data.filter(loc == 0 && level == 0 ? x => x.year == prevyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);

                for (var j = 0; j < finalrows1.length; j++) {
                    scope1 = scope1 + finalrows1[j].total
                }


                setScopearray11(scopetotal11 = scope1);
                scope1 = 0;

            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardTotalExpenditure', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {

                debugger;
                setlastrows10(lastrows10 = response.data)
                let finalrows2 = response.data.filter(loc == 0 && level == 0 ? x => x.yearFor == prevyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);
                for (var j = 0; j < finalrows2.length; j++) {
                    scope1 = scope1 + finalrows2[j].totalExpense
                }
                setScopearray12(scopetotal12 = scope1);


                scope1 = 0;


            })

            .catch(error => {
                console.log(error);

            });


        axios.all([
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEnvLegalCompliance', { params: { startDate: startDate, endDate: endDate } }),

            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardSafLegalCompliance', { params: { startDate: startDate, endDate: endDate } }),



        ]).then(axios.spread((...responses) => {

            debugger;
            setlastrows11(lastrows11 = responses[0].data)
            setlastrows12(lastrows12 = responses[1].data)
            let finalrows3 = responses[0].data.filter(loc == 0 && level == 0 ? x => x.complianceYear == prevyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);            for (var i = 0; i < finalrows3.length; i++) {
                scope1 = scope1 + finalrows3[i].finePaid

            }


            let finalrows4 = responses[1].data.filter(loc == 0 && level == 0 ? x => x.complianceYear == prevyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);            for (var j = 0; j < finalrows4.length; j++) {
                scope2 = scope2 + finalrows4[j].finePaid

            }

            var scope3 = scope1 + scope2
            setScopearray23(scopetotal23 = scope3)
            scope1 = 0;
            scope2 = 0;




        }))


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardWorkRelatedFatalities', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows13(lastrows13 = response.data)
                let finalrows5 = response.data.filter(loc == 0 && level == 0 ? x => x.year == prevyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);                for (var j = 0; j < finalrows5.length; j++) {
                    scope1 = scope1 + finalrows5[j].employeeFatalities
                    scope2 = scope2 + finalrows5[j].contractorFatalities
                }


                setScopearray13(scopetotal13 = scope1);
                setScopearray14(scopetotal14 = scope2);

                scope1 = 0;
                scope2 = 0;

            })

            .catch(error => {
                console.log(error);

            });


        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardFireAccident', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows14(lastrows14 = response.data)
                let finalrows6 = response.data.filter(loc == 0 && level == 0 ? x => x.fireYear == prevyear : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level : x => x.locationId == loc);                for (var j = 0; j < finalrows6.length; j++) {
                    scope1 = scope1 + finalrows6[j].totalAccidents
                    scope2 = scope2 + finalrows6[j].totalAccidentsContractor
                }



                setScopearray15(scopetotal15 = scope1);
                setScopearray16(scopetotal16 = scope2);


            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardEhsTraining', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows15(lastrows15 = response.data)
                var scope1 = 0;
                var scope2 = 0;


                let finalrows7 = response.data.filter(loc == 0 && level == 0 ? x => x.type == "Employees" : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level && x.type == "Employees" : x => x.locationId == loc && x.type == "Employees");
                for (var j = 0; j < finalrows7.length; j++) {
                    scope1 = scope1 + finalrows7[j].noOfTrainingHours

                }



                let finalrows8 = response.data.filter(loc == 0 && level == 0 ? x => x.type == "Contractors" : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level && x.type == "Contractors" : x => x.locationId == loc && x.type == "Contractors");
                for (var j = 0; j < finalrows8.length; j++) {

                    scope2 = scope2 + finalrows8[j].noOfTrainingHours
                }



                setScopearray17(scopetotal17 = scope1);
                setScopearray18(scopetotal18 = scope2);

            })

            .catch(error => {
                console.log(error);

            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/Dashboardesg/GetDashboardTriAndLtiFr', { params: { startDate: startDate, endDate: endDate } })
            .then(response => {
                setlastrows16(lastrows16 = response.data)
                let finalrows9 = response.data.filter(loc == 0 && level == 0 ? x => x.type == "Employees" : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level && x.type == "Employees" : x => x.locationId == loc && x.type == "Employees");
                for (var j = 0; j < finalrows9.length; j++) {
                    scope1 = scope1 + finalrows9[j].triFr
                    scope2 = scope2 + finalrows9[j].ltiFr
                }



                let finalrows10 = response.data.filter(loc == 0 && level == 0 ? x => x.type == "Contractors" : loc == 0 && level == 1 || level == 3 ? x => x.userRoleId == level && x.type == "Contractors" : x => x.locationId == loc && x.type == "Contractors");
                for (var j = 0; j < finalrows10.length; j++) {

                    scope3 = scope3 + finalrows10[j].triFr
                    scope4 = scope4 + finalrows10[j].ltiFr
                }



                scope1 = Math.round(scope1 * 100) / 100
                scope3 = Math.round(scope3 * 100) / 100
                setScopearray19(scopetotal19 = scope1);
                setScopearray20(scopetotal20 = scope2);
                setScopearray21(scopetotal21 = scope3);
                setScopearray22(scopetotal22 = scope4);

            })

            .catch(error => {
                console.log(error);

            });


    }

    function getdatathisyearorlastyear(mode) {


        var today = new Date();
        const currentyear = today.getFullYear();
        const prevyear = currentyear - 1;
        setpreviousyear(previousyear = prevyear)



        if (mode == 1) {


            var startDate = new Date('01' + '-' + 'January' + '-' + currentyear);
            var endDate = new Date('31' + '-' + 'December' + '-' + currentyear);

            getdatathisyear(startDate, endDate, locationstate)




        }

        else {



            var startDate = new Date('01' + '-' + 'January' + '-' + prevyear);
            var endDate = new Date('31' + '-' + 'December' + '-' + prevyear);


            getdatalastyear(startDate, endDate, locationstate)

        }


    }

    function getlevelwise(value) {
        
        let scope1 = 0
        let scope2 = 0
        let scope3 = 0
        let scope4 = 0
        let scopearray1 = [];
        let scopearray2 = [];
        let scopearray3 = [];
        let scopearray4 = [];
        let scopearray5 = [];
        let scopearray6 = [];
        let scopearray7 = [];
        let scopearray8 = [];
        let scopearray9 = [];
        let scopearray10 = [];
        let scopearray11 = [];
      
        let newrows1 = []
        let newrows2 = []
        let newrows3 = []
        let newrows4 = []
        let newrows5 = []
        let newrows6 = []
        let newrows7 = []
        let newrows8 = []
        let newrows9 = []
        let newrows10 = []
        let newrows11 = []
        let newrows12 = []
        let newrows13 = []
        let newrows14 = []
        let newrows15 = []
        let newrows16 = []
        let newrows17 = []
        let newrows18 = []
        let newrows19 = []
        let newrows20 = []
        var today = new Date();

        const currentyear = today.getFullYear();
        const prevyear = currentyear - 1;
        let months = getLast3Months();

        setlocationstate(locationstate = value)
        let shuffleyear = 0;
        var looplength = 0

        if (time == "Last Three Months") {
            
            shuffleyear = currentyear
            looplength = months.length;
            setLabels(labels = months)
            newrows1 = monthly1
            newrows2 = monthly2
            newrows3 = monthly3
            newrows4 = monthly4
            newrows5 = monthly5
            newrows6 = monthly6
            newrows7 = monthly7
            newrows8 = monthly8
            newrows9 = monthly9
            newrows10 = monthly10
            newrows11 = monthly11
            newrows12 = monthly12
            newrows13 = monthly13
            newrows14 = monthly14
            newrows15 = monthly15
            newrows16 = monthly16
            newrows17 = monthly17
            newrows18 = monthly18
            newrows19 = monthly19
            newrows20 = monthly20
        }

        else if (time == "This Year") {
            shuffleyear = currentyear
            looplength = 4;
            newrows1 = rows1
            newrows2 = rows2
            newrows3 = rows3
            newrows4 = rows4
            newrows5 = rows5
            newrows6 = rows6
            newrows7 = rows7
            newrows8 = rows8
            newrows9 = rows9
            newrows10 = rows10
            newrows11 = rows11
            newrows12 = rows12
            newrows13 = rows13
            newrows14 = rows14
            newrows15 = rows15
            newrows16 = rows15
            newrows17 = rows16
            newrows18 = rows16
            newrows19 = rows17
            newrows20 = rows18

        }


        else {
            shuffleyear = prevyear
            looplength = 4;
            newrows1 = lastrows1
            newrows2 = lastrows2
            newrows3 = lastrows3
            newrows4 = lastrows4
            newrows5 = lastrows5
            newrows6 = lastrows6
            newrows7 = lastrows7
            newrows8 = lastrows8
            newrows9 = lastrows9
            newrows10 = lastrows10
            newrows11 = lastrows11
            newrows12 = lastrows12
            newrows13 = lastrows13
            newrows14 = lastrows14
            newrows15 = lastrows15
            newrows16 = lastrows15
            newrows17 = lastrows16
            newrows18 = lastrows16
            newrows19 = lastrows17
            newrows20 = lastrows18

        }
        

        var monthsall = ["April", "May", "June",
            "July", "August", "September", "October", "November", "December", "January", "February", "March",
        ];


        var startindex = 0;
        var midindex=1
        var endindex = 2;
        
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }

           
            let rows = newrows1.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].scope2


            }
            if (scope1 == 0) {
                scopearray1.push(null);
            }
            else {
                scopearray1.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex=midindex+3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex=1
        endindex = 2;
        setScopearray1(scopetotal1 = scopearray1)
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows2.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].scope2


            }
            if (scope1 == 0) {
                scopearray2.push(null);
            }
            else {
                scopearray2.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;

        setScopearray2(scopetotal2 = scopearray2)

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows19.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].sum


            }
            if (scope1 == 0) {
                scopearray10.push(null);
            }
            else {
                scopearray10.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;
        setScopearray24(scopetotal24 = scopearray10)
        
       
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            
            let rows = newrows20.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i]: x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));
            let value1 = 20;
            for (var j = 0; j < rows.length; j++) {

                let rows1 = rows.filter(x => x.rmasterId == value1)
                for (var k = 0; k < rows1.length; k++) {
                    scope1 = scope1 + rows1[k].percentage 

                }
                scope1=scope1/ 2
                value1 = value1 + 1;
            
            if (scope1 == 0) {
                scopearray11.push(0);
            }
            else {
                scopearray11.push(Math.round(scope1));
                scope1 = 0
                }
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;
        setScopearray25(scopetotal25 = scopearray11)
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows3.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].energyConsumed


            }
            if (scope1 == 0) {
                scopearray3.push(null);
            }
            else {
                scopearray3.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;
        setScopearray3(scopetotal3 = scopearray3)

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows4.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {

                scope2 = scope2 + rows[j].energyConsumed

            }
            if (scope2 == 0) {
                scopearray4.push(null);
            }
            else {
                scopearray4.push(Math.round(scope2));
                scope2 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        var startindex = 0;
        var midindex = 1
        var endindex = 2;


        setScopearray4(scopetotal4 = scopearray4);


        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows5.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].total


            }
            if (scope1 == 0) {
                scopearray5.push(null);
            }
            else {
                scopearray5.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;




        setScopearray5(scopetotal5 = scopearray5);

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows6.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear )|| (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {

                scope2 = scope2 + rows[j].quantity

            }
            if (scope2 == 0) {
                scopearray6.push(null);
            }
            else {
                scopearray6.push(Math.round(scope2));
                scope2 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;



        setScopearray6(scopetotal6 = scopearray6);

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
          
            let rows = newrows7.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] && x.wmasterId == 9 : x => x.locationId == value && x.wmasterId == 9 && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].quantityGenerated
                scope2 = scope2 + rows[j].quantityRecycled
                scope3 = scope3 + rows[j].disposal


            }
            if (scope1 == 0) {
                scopearray7.push(null);
            }
            else {
                scopearray7.push(Math.round(scope1));
                scope1 = 0
            }
            if (scope2 == 0) {
                scopearray8.push(null);
            }
            else {
                scopearray8.push(Math.round(scope2));
                scope2 = 0
            }
            if (scope3 == 0) {
                scopearray9.push(null);
            }
            else {
                scopearray9.push(Math.round(scope3));
                scope3 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;



        setScopearray7(scopetotal7 = scopearray7);
        setScopearray8(scopetotal8 = scopearray8);
        setScopearray9(scopetotal9 = scopearray9);


        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            debugger;
            let rows = newrows8.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].intensity


            }


            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;




        setScopearray10(scopetotal10 = scope1);
        scope1 = 0

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows9.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].total

            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        var startindex = 0;
        var midindex = 1
        var endindex = 2;



        setScopearray11(scopetotal11 = scope1);
        scope1 = 0
        
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows10.filter(time == "Last Three Months" ? x => x.locationId == value && x.monthFor == months[i] : x => x.locationId == value && ((x.monthFor == monthsall[startindex] && x.yearFor <= shuffleyear) || (x.monthFor == monthsall[midindex] && x.yearFor <= shuffleyear) || (x.monthFor == monthsall[endindex] && x.yearFor <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].totalExpense

            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;
        console.log(scope1)
        setScopearray12(scopetotal12 = scope1);
        scope1 = 0



        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows11.filter(time == "Last Three Months" ? x => x.locationId == value && x.complianceMonth == months[i] : x => x.locationId == value && ((x.complianceMonth == monthsall[startindex] && x.complianceYear <= shuffleyear )|| (x.complianceMonth == monthsall[midindex] && x.complianceYear <= shuffleyear) ||( x.complianceMonth == monthsall[endindex] && x.complianceYear <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].finePaid

            }

        }

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows12.filter(time == "Last Three Months" ? x => x.locationId == value && x.complianceMonth == months[i] : x => x.locationId == value && ((x.complianceMonth == monthsall[startindex] && x.complianceYear <= shuffleyear) || (x.complianceMonth == monthsall[midindex] && x.complianceYear <= shuffleyear) || (x.complianceMonth == monthsall[endindex] && x.complianceYear <= shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope2 = scope2 + rows[j].finePaid

            }
            scope3 = scope1 + scope2;
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;

        setScopearray23(scopetotal23 = scope3);
        scope1 = 0;
        scope2 = 0;
        scope3 = 0;


        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows13.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] : x => x.locationId == value && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear)|| (x.month == monthsall[endindex] && x.year <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].employeeFatalities
                scope2 = scope2 + rows[j].contractorFatalities
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;
                                                                                         
                                                     
        setScopearray13(scopetotal13 = scope1);
        setScopearray14(scopetotal14 = scope2);
        scope1 = 0;
        scope2 = 0;
        
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows14.filter(time == "Last Three Months" ? x => x.locationId == value && x.monthofFire == months[i] : x => x.locationId == value && ((x.monthofFire == monthsall[startindex] && x.fireYear <= shuffleyear) || (x.monthofFire == monthsall[midindex] && x.fireYear <= shuffleyear) || (x.monthofFire == monthsall[endindex] && x.fireYear <= shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].totalAccidents
                scope2 = scope2 + rows[j].totalAccidentsContractor
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;





        setScopearray15(scopetotal15 = scope1);
        setScopearray16(scopetotal16 = scope2);
        scope1 = 0;
        scope2 = 0;


        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows15.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] && x.type == "Employees" : x => x.locationId == value && x.type == "Employees" &&(( x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].noOfTrainingHours

            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        var startindex = 0;
        var midindex = 1
        var endindex = 2;
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            debugger;
            let rows = newrows16.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] && x.type == "Contractors" : x => x.locationId == value && x.type == "Contractors" && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));
            for (var j = 0; j < rows.length; j++) {

                scope2 = scope2 + rows[j].noOfTrainingHours
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;

        setScopearray17(scopetotal17 = scope1);
        setScopearray18(scopetotal18 = scope2);
        scope1 = 0;
        scope2 = 0;

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows17.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] && x.type == "Employees" : x => x.locationId == value && x.type == "Employees" && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].triFr
                scope2 = scope2 + rows[j].ltiFr
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows18.filter(time == "Last Three Months" ? x => x.locationId == value && x.month == months[i] && x.type == "Contractors" : x => x.locationId == value && x.type == "Contractors"  && ((x.month == monthsall[startindex] && x.year <= shuffleyear) || (x.month == monthsall[midindex] && x.year <= shuffleyear) || (x.month == monthsall[endindex] && x.year <= shuffleyear)));
            for (var j = 0; j < rows.length; j++) {

                scope3 = scope3 + rows[j].triFr
                scope4 = scope4 + rows[j].ltiFr
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;



        setScopearray19(scopetotal19 = scope1);
        setScopearray20(scopetotal20 = scope2);
        setScopearray21(scopetotal21 = scope3);
        setScopearray22(scopetotal22 = scope4);
        scope1 = 0;
        scope2 = 0;
        scope3 = 0;
        scope4 = 0;




    }

    function getplantorofficewise(value) {
        let scope1 = 0
        let scope2 = 0
        let scope3 = 0
        let scope4 = 0
        let scopearray1 = [];
        let scopearray2 = [];
        let scopearray3 = [];
        let scopearray4 = [];
        let scopearray5 = [];
        let scopearray6 = [];
        let scopearray7 = [];
        let scopearray8 = [];
        let scopearray9 = [];
        let scopearray10 = [];
        let newrows1 = []
        let newrows2 = []
        let newrows3 = []
        let newrows4 = []
        let newrows5 = []
        let newrows6 = []
        let newrows7 = []
        let newrows8 = []
        let newrows9 = []
        let newrows10 = []
        let newrows11 = []
        let newrows12 = []
        let newrows13 = []
        let newrows14 = []
        let newrows15 = []
        let newrows16 = []
        let newrows17 = []
        let newrows18 = []
        let newrows19 = []
        var today = new Date();

        const currentyear = today.getFullYear();
        const prevyear = currentyear - 1;
        let months = getLast3Months();

        //   setlocationstate(locationstate = value)
        let shuffleyear = 0;
        var looplength = 0

        if (time == "Last Three Months") {
            shuffleyear = currentyear
            looplength = months.length
            setLabels(labels = months)
            newrows1 = monthly1
            newrows2 = monthly2
            newrows3 = monthly3
            newrows4 = monthly4
            newrows5 = monthly5
            newrows6 = monthly6
            newrows7 = monthly7
            newrows8 = monthly8
            newrows9 = monthly9
            newrows10 = monthly10
            newrows11 = monthly11
            newrows12 = monthly12
            newrows13 = monthly13
            newrows14 = monthly14
            newrows15 = monthly15
            newrows16 = monthly16
            newrows17 = monthly17
            newrows18 = monthly18
            newrows19 = monthly19
        }

        else if (time == "This Year") {
            var newlabels = ['Q1', 'Q2', 'Q3', 'Q4']
            setLabels(labels = newlabels)
            shuffleyear = currentyear
            looplength = 4
            newrows1 = rows1
            newrows2 = rows2
            newrows3 = rows3
            newrows4 = rows4
            newrows5 = rows5
            newrows6 = rows6
            newrows7 = rows7
            newrows8 = rows8
            newrows9 = rows9
            newrows10 = rows10
            newrows11 = rows11
            newrows12 = rows12
            newrows13 = rows13
            newrows14 = rows14
            newrows15 = rows15
            newrows16 = rows15
            newrows17 = rows16
            newrows18 = rows16
            newrows19 = rows17

        }


        else {
            var newlabels = ['Q1', 'Q2', 'Q3', 'Q4']
            setLabels(labels = newlabels)
            shuffleyear = prevyear
            looplength = 4
            newrows1 = lastrows1
            newrows2 = lastrows2
            newrows3 = lastrows3
            newrows4 = lastrows4
            newrows5 = lastrows5
            newrows6 = lastrows6
            newrows7 = lastrows7
            newrows8 = lastrows8
            newrows9 = lastrows9
            newrows10 = lastrows10
            newrows11 = lastrows11
            newrows12 = lastrows12
            newrows13 = lastrows13
            newrows14 = lastrows14
            newrows15 = lastrows15
            newrows16 = lastrows15
            newrows17 = lastrows16
            newrows18 = lastrows16
            newrows19 = lastrows17

        }


        var monthsall = ["April", "May", "June",
            "July", "August", "September", "October", "November", "December", "January", "February", "March",
        ];


        var startindex = 0;
        var midindex = 1
        var endindex = 2;
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows1.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].total


            }
            if (scope1 == 0) {
                scopearray1.push(null);
            }
            else {
                scopearray1.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;
        setScopearray1(scopetotal1 = scopearray1)

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];

                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows2.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear)|| (x.month == monthsall[endindex] && x.year == shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].scope2


            }
            if (scope1 == 0) {
                scopearray2.push(null);
            }
            else {
                scopearray2.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;

        setScopearray2(scopetotal2 = scopearray2)
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows19.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].sum


            }
            if (scope1 == 0) {
                scopearray10.push(null);
            }
            else {
                scopearray10.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;
        setScopearray24(scopetotal24 = scopearray10)
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows3.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear)|| (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].energyConsumed


            }
            if (scope1 == 0) {
                scopearray3.push(null);
            }
            else {
                scopearray3.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;

        setScopearray3(scopetotal3 = scopearray3)

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows4.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear )));

            for (var j = 0; j < rows.length; j++) {

                scope2 = scope2 + rows[j].energyConsumed

            }
            if (scope2 == 0) {
                scopearray4.push(null);
            }
            else {
                scopearray4.push(Math.round(scope2));
                scope2 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;



        setScopearray4(scopetotal4 = scopearray4);


        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows5.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].total


            }
            if (scope1 == 0) {
                scopearray5.push(null);
            }
            else {
                scopearray5.push(Math.round(scope1));
                scope1 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;



        setScopearray5(scopetotal5 = scopearray5);

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows6.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear)|| (x.month == monthsall[midindex] && x.year == shuffleyear) ||(x.month == monthsall[endindex] && x.year == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {

                scope2 = scope2 + rows[j].quantity

            }
            if (scope2 == 0) {
                scopearray6.push(null);
            }
            else {
                scopearray6.push(Math.round(scope2));
                scope2 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;


        setScopearray6(scopetotal6 = scopearray6);

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows7.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear)||( x.month == monthsall[endindex] && x.year == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].quantityGenerated
                scope2 = scope2 + rows[j].quantityRecycled
                scope3 = scope3 + rows[j].disposal


            }
            if (scope1 == 0) {
                scopearray7.push(null);
            }
            else {
                scopearray7.push(Math.round(scope1));
                scope1 = 0
            }
            if (scope2 == 0) {
                scopearray8.push(null);
            }
            else {
                scopearray8.push(Math.round(scope2));
                scope2 = 0
            }
            if (scope3 == 0) {
                scopearray9.push(null);
            }
            else {
                scopearray9.push(Math.round(scope3));
                scope3 = 0
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;



        setScopearray7(scopetotal7 = scopearray7);
        setScopearray8(scopetotal8 = scopearray8);
        setScopearray9(scopetotal9 = scopearray9);


        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows8.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value &&(( x.month == monthsall[startindex] && x.year == shuffleyear) ||( x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].intensity


            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;




        setScopearray10(scopetotal10 = scope1);
        scope1 = 0

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows9.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear)||( x.month == monthsall[endindex] && x.year == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].total

            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;


        setScopearray11(scopetotal11 = scope1);
        scope1 = 0

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows10.filter(time == "Last Three Months" ? x => x.monthFor == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.monthFor == monthsall[startindex] && x.yearFor == shuffleyear) || (x.monthFor == monthsall[midindex] && x.yearFor == shuffleyear) || (x.monthFor == monthsall[endindex] && x.yearFor == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].totalExpense

            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;


        console.log(scope1)
        setScopearray12(scopetotal12 = scope1);
        scope1 = 0



        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows11.filter(time == "Last Three Months" ? x => x.complianceMonth == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.complianceMonth == monthsall[startindex] && x.complianceYear == shuffleyear) || (x.complianceMonth == monthsall[midindex] && x.complianceYear == shuffleyear) || (x.complianceMonth == monthsall[endindex] && x.complianceYear == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].finePaid

            }

        }
        
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows12.filter(time == "Last Three Months" ? x => x.complianceMonth == months[i] && x.userRoleId == value : x => x => x.userRoleId == value &&(( x.complianceMonth == monthsall[startindex] && x.complianceYear == shuffleyear) || (x.complianceMonth == monthsall[midindex] && x.complianceYear == shuffleyear) || (x.complianceMonth == monthsall[endindex] && x.complianceYear == shuffleyear )));
            for (var j = 0; j < rows.length; j++) {
                scope2 = scope2 + rows[j].finePaid

            }
            scope3 = scope1 + scope2;
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
         startindex = 0;
         midindex = 1
         endindex = 2;

        setScopearray23(scopetotal23 = scope3);
        scope1 = 0;
        scope2 = 0;
        scope3 = 0;


        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows13.filter(time == "Last Three Months" ? x => x.month == months[i] && x.userRoleId == value : x => x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].employeeFatalities
                scope2 = scope2 + rows[j].contractorFatalities
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;



        setScopearray13(scopetotal13 = scope1);
        setScopearray14(scopetotal14 = scope2);
        scope1 = 0;
        scope2 = 0;
        
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows14.filter(time == "Last Three Months" ? x => x.monthofFire == months[i] && x.userRoleId == value : x => x.userRoleId == value &&(( x.monthofFire == monthsall[startindex] && x.fireYear == shuffleyear) || (x.monthofFire == monthsall[midindex] && x.fireYear == shuffleyear) || (x.monthofFire == monthsall[endindex] && x.fireYear == shuffleyear)));

            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].totalAccidents
                scope2 = scope2 + rows[j].totalAccidentsContractor
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;




        setScopearray15(scopetotal15 = scope1);
        setScopearray16(scopetotal16 = scope2);
        scope1 = 0;
        scope2 = 0;


        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]

                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows15.filter(time == "Last Three Months" ? x => x.userRoleId == value && x.month == months[i] && x.type == "Employees" : x => x.type == "Employees" && x.userRoleId == value &&(( x.month == monthsall[startindex] && x.year == shuffleyear)|| (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].noOfTrainingHours

            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows16.filter(time == "Last Three Months" ? x => x.month == months[i] && x.type == "Contractors" && x.userRoleId == value : x => x.type == "Contractors" && x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));
            for (var j = 0; j < rows.length; j++) {

                scope2 = scope2 + rows[j].noOfTrainingHours
            }
            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;



        setScopearray17(scopetotal17 = scope1);
        setScopearray18(scopetotal18 = scope2);
        scope1 = 0;
        scope2 = 0;

        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows17.filter(time == "Last Three Months" ? x => x.month == months[i] && x.type == "Employees" && x.userRoleId == value : x => x.type == "Employees" && x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));
            for (var j = 0; j < rows.length; j++) {
                scope1 = scope1 + rows[j].triFr
                scope2 = scope2 + rows[j].ltiFr
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;
        for (var i = 0; i < looplength; i++) {
            if (time == "This Year" || time == "Last Year") {
                var startmonth = monthsall[startindex];
                var midindex = monthsall[midindex]
                var endmonth = monthsall[endindex];
                var today = new Date();
                var sDate = new Date('01' + '-' + startmonth + '-' + shuffleyear);
                var eDate = new Date('31' + '-' + endmonth + '-' + shuffleyear);
                var startDate = sDate.toISOString().substring(0, 10);
                var endDate = eDate.toISOString().substring(0, 10);
            }
            let rows = newrows18.filter(time == "Last Three Months" ? x => x.month == months[i] && x.type == "Contractors" && x.userRoleId == value : x => x.type == "Contractors" && x.userRoleId == value && ((x.month == monthsall[startindex] && x.year == shuffleyear) || (x.month == monthsall[midindex] && x.year == shuffleyear) || (x.month == monthsall[endindex] && x.year == shuffleyear)));
            for (var j = 0; j < rows.length; j++) {

                scope3 = scope3 + rows[j].triFr
                scope4 = scope4 + rows[j].ltiFr
            }

            startindex = startindex + 3
            midindex = midindex + 3
            endindex = endindex + 3;
        }
        startindex = 0;
        midindex = 1
        endindex = 2;



        setScopearray19(scopetotal19 = scope1);
        setScopearray20(scopetotal20 = scope2);
        setScopearray21(scopetotal21 = scope3);
        setScopearray22(scopetotal22 = scope4);
        scope1 = 0;
        scope2 = 0;
        scope3 = 0;
        scope4 = 0;

    }
    function findMaxValue(value1,value2,value3) {
        
        let value = Math.max(Math.max(0, ...value1), Math.max(0, ...value2), Math.max(0, ...value3))

        
        value = Math.round(value + (value * 0.4))
        return value
    }

    function findMaxValue1(value1, value2) {

        let value = Math.max(Math.max(0, ...value1), Math.max(0, ...value2))


        value = Math.round(value + (value * 0.4))
        return value
    }
    const dailySalesChart = {


        data: {
            labels: labels,
            series: [
                {

                    name: 'Direct',
                    data: scopetotal1
                },
                {

                    name: 'Indirect',
                    data: scopetotal2
                }
                ,
                {

                    name: 'Transportation',
                    data: scopetotal24
                }
            ]
        },
        options: {
            
            low: 0,
            high: findMaxValue(scopetotal1,scopetotal2,scopetotal24), // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10,
            },


            plugins: [

                axistitle({
                    
                    axisY: {
                        axisTitle: 'Metric TC02e',
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 8,
                            y: 12
                        },
                        textAnchor: 'middle',
                        flipTitle: true
                    }
                }),
                tooltip({
                    position: 'bottom',
                    clickable:false
                   
                    
                }),
                hover({
                    anchorToPoint: true,
                    appendToBody: true,
                }),
                pointlabel({
                    textAnchor: 'middle'
                })


            ]

        },
        // for animation
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                        },
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * delays,
                            dur: durations,
                            from: 0,
                            to: 1,
                            easing: "ease",
                        },
                    });
                }

            },
        },
    };


    const emailsSubscriptionChart = {



        data: {

            labels: labels,
            series: [
                {

                    name: 'Renewable',
                    data: scopetotal3,

                },
                {

                    name: 'Non Renewable',
                    data: scopetotal4,

                }
            ]

        },
        options: {
            axisX: {
                showGrid: false,
            },
           
            low: 0,
            high: findMaxValue1(scopetotal3, scopetotal4),
            chartPadding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10,
            },
            plugins: [
                axistitle({

                    axisY: {
                        axisTitle: 'MwH',
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 8,
                            y: 12
                        },
                        textAnchor: 'middle',
                        flipTitle: true
                    }
                }),
                tooltip({
                    position: 'bottom',

                    clickable: false

                }),
                ,
                hover(),
                pointlabel({
                    textAnchor: 'middle'
                })

            ],



        },
        responsiveOptions: [
            [

                "screen and (max-width: 640px)",
                {
                    seriesBarDistance: 5,
                    axisX: {
                        labelInterpolationFnc: function (value) {
                            return value[0];
                        },
                    },
                },

            ],
        ],
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                        },
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * delays,
                            dur: durations,
                            from: 0,
                            to: 1,
                            easing: "ease",
                        },
                    });
                    {/* if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });*/}
                    //data.element._node.onclick = event => {


                    //    this.newlabels = []
                    //    this.newseries = [[]]

                    //    var uniqueid = event.offsetX

                    //    if (uniqueid >= '56' && uniqueid <= '74') {



                    //        this.newlabels = ["Jan", "Feb", "Mar", "Apr", "May"]
                    //        this.newseries = [[220, 660, 480, 320, 554]]
                    //        //  data.element._node.update({ series: this.newSeries })
                    //        // document.getElementById('ct-barchart').update({series:this.newseries})
                    //        // data.update({ labels:this.newlabels,series: this.newseries })
                    //    }
                    //    else if (uniqueid >= '106' && uniqueid <= '124') {
                    //        alert('2.Indirect Energy Consumption')
                    //    }

                    //    else if (uniqueid >= '156' && uniqueid <= '174') {
                    //        alert('3.Renewable Energy Consumption')
                    //    }

                    //    else if (uniqueid >= '206' && uniqueid <= '224') {
                    //        alert('4.Non Renewable Energy Consumption')
                    //    }
                    //    else if (uniqueid >= '256' && uniqueid <= '274') {
                    //        alert('5.Energy Intensity')
                    //    }
                    //    //  alert(uniqueid)
                    //}

                }

            },

        },


    };


    const completedTasksChart = {
        data: {
            labels: labels,
            series: [
                {

                    name: 'Withdrawal',
                    data: scopetotal5
                },
                {

                    name: 'Recycled',
                    data: scopetotal6
                }]
        },
        options: {
            low: 0,
            high: findMaxValue1(scopetotal5, scopetotal6), // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10,
            },
            plugins: [
                axistitle({

                    axisY: {
                        axisTitle: 'm³',
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 8,
                            y: 12
                        },
                        textAnchor: 'middle',
                        flipTitle: true
                    }
                }),
                tooltip({
                    position: 'bottom',

                    clickable: false
                }),
                
                hover(),
                pointlabel({
                    textAnchor: 'middle'
                })
            ]

        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                        },
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * delays,
                            dur: durations,
                            from: 0,
                            to: 1,
                            easing: "ease",
                        },
                    });
                }
            },
        },
    };



    const wasteChart = {
        data: {
            labels: labels,
            series: [
                {

                    name: 'Generated',
                    data: scopetotal7
                },
                {

                    name: 'Recycle',
                    data: scopetotal8
                },
                {

                    name: 'Reuse',
                    data: scopetotal9
                }]
        },
        options: {
            low: 0,
            high: findMaxValue1(scopetotal8, scopetotal9), // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          
            chartPadding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10,
            },

            plugins: [
                axistitle({

                    axisY: {
                        axisTitle: 'MT',
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 8,
                            y: 12
                        },
                        textAnchor: 'middle',
                        flipTitle: true
                    }
                }),
                tooltip({
                    position: 'bottom',

                    clickable: false
                }),
                
                hover(),
                pointlabel({
                    textAnchor: 'middle'
                })
            ]

        },
        // for animation
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                        },
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * delays,
                            dur: durations,
                            from: 0,
                            to: 1,
                            easing: "ease",
                        },
                    });
                }

            },
        },
    };






    return (
        <div>
            <br />
            <div >
                
                <div id="level" style={{ width: '320px', float: 'left' }}>

                    <select name="Level" class="form-control" onChange={handleChange} >

                        <option selected value={0}>Company Level</option>
                        <option value={1}>Plant Level </option>
                        <option value={3}>Office Level</option>

                    </select></div>
                <div id="location" style={{ width: '320px', float: 'left', marginLeft: '10px' }}>

                    <select name="LocationId" required class="form-control" onChange={handleChange1} value={currentloc} disabled={isDisabled()}>
                        <option selected value='0'>--Select Location--</option>
                        {locations.map(type => (

                            <option
                                key={type.locationId}
                                value={type.locationId}
                            >
                                {type.locationName}
                            </option>
                        ))}
                    </select></div>

                <div id="time" style={{ width: '320px', float: 'right' }}>

                    <select name="Time" required class="form-control" onChange={handleChange2} >

                        <option value={'Last Three Months'} >Last 3 Months</option>
                        <option value={'This Year'} > This Year </option>
                        <option value={'Last Year'} >Last Year</option>

                    </select></div>
            </div>
            <br />
            <br />


            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <Card chart >
                        <CardHeader > {/*color="success"*/}
                            <ChartistGraph
                                className="ct-chart"
                                id="ct-barchart"
                                data={dailySalesChart.data}
                                type="Line"
                                options={dailySalesChart.options}
                                listener={dailySalesChart.animation}

                            />
                        </CardHeader>
                        <CardBody>
                            <h5 className={classes.cardTitle}>Air Emissions</h5>
                            <p className={classes.cardCategory}>Direct Vs Indirect Vs Emission</p>
                            {/*<p className={classes.cardCategory}>
                                <span className={classes.successText}>
                                    55% <ArrowUpward className={classes.upArrowCardCategory} />
                                </span>{" "}
                in last two months
              </p>*/}
                        </CardBody>
                        <CardFooter chart>
              {/*              <div className={classes.stats}>*/}
              {/*                  <AccessTime /> All Values in Metric TCO2e*/}
              {/*</div>*/}
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <Card chart>
                        <CardHeader > {/*color="warning" */}

                            <ChartistGraph key={emailsSubscriptionChart.options.requirementKey}
                                className="ct-chart"
                                data={emailsSubscriptionChart.data}
                                type="Line"
                                options={emailsSubscriptionChart.options}
                                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                                listener={emailsSubscriptionChart.animation}

                            />
                        </CardHeader>
                       
                        <CardBody>
                            <h5 className={classes.cardTitle}>Energy Consumption</h5>
                            <p className={classes.cardCategory}>Renewable Vs Non Renewable</p>
                        </CardBody>
                        <CardFooter chart>
              {/*              <div className={classes.stats}>*/}
              {/*                  <AccessTime /> All Values in MwH*/}
              {/*</div>*/}
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <Card chart>
                        <CardHeader > {/*color="danger"*/}
                            <ChartistGraph
                                className="ct-chart"
                                data={completedTasksChart.data}
                                type="Line"
                                options={completedTasksChart.options}
                                listener={completedTasksChart.animation}
                            />
                        </CardHeader>
                        
                        <CardBody>
                            <h5 className={classes.cardTitle}>Water </h5>
                            <p className={classes.cardCategory}>Withdrawal Vs Recycled</p>
                        </CardBody>
                        <CardFooter chart>
              {/*              <div className={classes.stats}>*/}
              {/*                  <AccessTime />All Values in m³*/}
              {/*</div>*/}
                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                    <Card chart>
                        <CardHeader > {/*color="dark"*/}
                            <ChartistGraph
                                className="ct-chart"
                                id="ct-barchart"
                                data={wasteChart.data}
                                type="Line"
                                options={wasteChart.options}
                                listener={wasteChart.animation}
                            />
                        </CardHeader>
                        <CardBody>
                            <h5 className={classes.cardTitle}>Waste </h5>
                            <p className={classes.cardCategory}>Generated Vs Recycle Vs Reuse</p>
                            {/* <p className={classes.cardCategory} >
                              <span className={classes.Warning} >
                                 

                                  <ArrowDownward className={classes.downArrowCardCategory} /> 22%
                </span>{" "}
                decrease in last two months
              </p> */}
                        </CardBody>
                        <CardFooter chart>
              {/*              <div className={classes.stats}>*/}
              {/*                  <AccessTime /> All Values in Metric Tonne*/}
              {/*</div>*/}
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="info">
                                <Bookmark />
                            </CardIcon>
                            <p className={classes.cardCategory}>Energy Intensity</p>
                            <h3 className={classes.cardTitle}>
                                {decimalfilter(scopetotal10)}<small></small>
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Danger>
                                    <Warning />
                                </Danger>
                               Unit Per Million Copy
                              {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Unit Per Copy
                </a> */}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                {/*<Accessibility/> */}
                                <img src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/56/ffffff/external-money-saving-economy-flatart-icons-solid-flatarticons.png" />

                            </CardIcon>
                            <p className={classes.cardCategory}>Cost Avoidance</p>
                            <h3 className={classes.cardTitle}>₹{decimalfilter(scopetotal11)}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Update />
                INR Lakhs
              </div>
                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="info">
                                {/* <Info /> */}
                                <img src="https://img.icons8.com/ios-glyphs/54/ffffff/receive-change.png" />

                            </CardIcon>
                            <p className={classes.cardCategory}>EHS Expenditure</p>
                            <h3 className={classes.cardTitle}>₹{decimalfilter(scopetotal12)}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <LocalOffer />
             Including CAT A and CAT B
              </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="dark" stats icon>
                            <CardIcon color="info">
                                <img src="https://img.icons8.com/ios-filled/54/ffffff/law.png" />
                                {/*<Store />*/}
                            </CardIcon>
                            <p className={classes.cardCategory}>Fine Paid</p>
                            <h3 className={classes.cardTitle}>₹{decimalfilter(scopetotal23)}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange />
              For Environment and Safety
              </div>
                        </CardFooter>
                    </Card>
                </GridItem>


            </GridContainer>

            <GridContainer >
                <GridItem xs={12} sm={12} md={6}  >

                    <CustomTabs
                        title="Safety MIS:"

                        headerColor="dark"
                        tabs={[
                            {
                                tabName: "Employees",
                                tabIcon: BugReport,

                                tabContent: (
                                    <Tasks
                                        checkedIndexes={[0, 4]}
                                        tasksIndexes={[0, 1, 2, 3, 4]}
                                        tasks={bugs}
                                        unit={['No.', 'No.', 'Hrs.', 'No.', 'No.']}
                                        safetydata={[[decimalfilter(scopetotal13)], [decimalfilter(scopetotal15)], [decimalfilter(scopetotal17)], [decimalfilter(scopetotal19)], [decimalfilter(scopetotal20)]]}
                                    />


                                ),
                            },
                            {
                                tabName: "Contractors",
                                tabIcon: Code,
                                tabContent: (
                                    <Tasks
                                        checkedIndexes={[0, 4]}
                                        tasksIndexes={[0, 1, 2, 3, 4]}
                                        tasks={website}
                                        unit={['No.', 'No.', 'Hrs.', 'No.', 'No.']}
                                        safetydata={[[decimalfilter(scopetotal14)], [decimalfilter(scopetotal16)], [decimalfilter(scopetotal18)], [decimalfilter(scopetotal21)], [decimalfilter(scopetotal22)]]}
                                    />
                                ),
                            },
                            /*{
                              tabName: "Contractors",
                              tabIcon: Cloud,
                              tabContent: (
                                <Tasks
                                  checkedIndexes={[1]}
                                  tasksIndexes={[0, 1, 2]}
                                  tasks={server}
                                />
                              ),
                            },*/
                        ]}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader style={{ backgroundColor: "#31859C" }}>
                            <h6 className={classes.cardTitleWhite}>Material Labelled As SFC</h6>
                            {/*  <p className={classes.cardCategoryWhite}>
                New employees on 15th September, 2016
              </p> */}
                        </CardHeader>
                        <CardBody>
                            <Table

                                tableHead={["Material", "% Labelled as SFC"]}

                                tableData={[
                                    ["Newspaper", decimalfilter(scopetotal25[0])],
                                    ["Ink", decimalfilter(scopetotal25[1])],
                                    ["Solvents", decimalfilter(scopetotal25[2])],
                                    ["CTP Plates", decimalfilter(scopetotal25[3])],
                                ]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

Dashboard.layout = Admin;

export default Dashboard;
