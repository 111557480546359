import { Chart } from "react-google-charts";
import React, { useState, useEffect, Component } from "react";

import { Line, Bar, Pie, Doughnut, Radar } from "react-chartjs-2";
import axios from "axios";
import "./custom.css"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { DEBUG } from "@devexpress/analytics-core/core/utils/_utils";



class Dashboardnew extends Component {


    constructor(props) {

        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1);
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        this.state = {

            Data: {},
            Data1: {},
            Data2: {},
            Data3: {},
            Data4: {},
            Data5: {},
            Data6: {},
            Data7: {},
            Data8: {},
            Data9: {},
            Data10: {},
            Data11: {},
            Data12: {},
            Data13: {},
            Data14: {},
            Data15: {},
            rows: [],
            check: [{ id: 0 }],
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth
        };

    }

    componentDidMount() {
        document.getElementById("navribbon").style.display = "none";

        let energyConsumed = [];
        let usedFor = [];

        var id = localStorage.getItem('location')
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/RenewableEnergyConsumption/GetRenewablEnergyConsumed').then(response => {
            let typesapi = response.data;

            this.setState({
                rows: typesapi

            });
            console.log(response.data);

            const Data = [['Used For', 'Energy Consumed']]
            for (const dataObj of response.data) {
                Data.push([dataObj.usedFor, dataObj.energyConsumed]);
               
                // calorificFuelValue.push(dataObj.calorificFuelValue);
                //fuelType.push(dataObj.fuelType);
                /* let datenew = dataObj.submissionDate;
                 
                 datenew = datenew.split('T', 3).join(' ');
                 datenew = datenew.split(':', 3).join(' ');
                 datenew = datenew.split('00', 3).join(' ');
               

                 date.push(datenew)
                 location.push(dataObj.locationName)
                 console.log(fuelType)*/
            }

            this.setState({
                Data:Data
            });


            // console.log(setChartData)
            /*     setChartData1({
                     labels: date,
                     datasets: [
                         {
                             label: "STATUS",
                             data: approvalid,
                             backgroundColor: ["#3e95cd", "#3cba9f", "#e8c3b9", "#c45850", "rgba(75, 192, 192, 0.6)"],
                             borderWidth: 4
                         }
                     ]
                 });
     
                 setChartData2({
                     labels: status,
                     datasets: [
                         {
                             label: "STATUS",
                             data: approvalid,
                             backgroundColor: ["#3e95cd", "#3cba9f", "#e8c3b9", "#c45850", "rgba(75, 192, 192, 0.6)"],
                             borderWidth: 4
                         }
                     ]
                 });
                 setChartData3({
                     labels: status,
                     datasets: [
                         {
                             label: "STATUS",
                             data: approvalid,
                             backgroundColor: ["#3e95cd", "#3cba9f", "#e8c3b9", "#c45850", "rgba(75, 192, 192, 0.6)"],
                             borderWidth: 4
                         }
                     ]
                 });*/
        })
            .catch(err => {
                console.log(err);
            });
        console.log(energyConsumed, usedFor);

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalWaterWithdrawn/GetTotalWaterWithdrawn')
.then(response => {
           

            
            console.log(response.data);
          
            const Data = [['Year','Ground Water','River/Surface Water','Municipal/Others','Withdrawal Harvested']]
            for (const dataObj of response.data) {
                Data.push([dataObj.month, dataObj.groundWater, dataObj.riverOrSurfaceWater, dataObj.municipalOrOther, dataObj.withdrawalHarvest]);

                // calorificFuelValue.push(dataObj.calorificFuelValue);
                //fuelType.push(dataObj.fuelType);
                /* let datenew = dataObj.submissionDate;
                 
                 datenew = datenew.split('T', 3).join(' ');
                 datenew = datenew.split(':', 3).join(' ');
                 datenew = datenew.split('00', 3).join(' ');
               

                 date.push(datenew)
                 location.push(dataObj.locationName)
                 console.log(fuelType)*/
            }

            this.setState({
                Data1: Data
            });


           
        })
            .catch(err => {
                console.log(err);
            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/EmissionDetails/GetEmissionDetails')
            .then(response => {



                console.log(response.data);

                const Data = [['Emission Type', 'Quantity']]
                for (const dataObj of response.data) {
                    Data.push([dataObj.emissionType, dataObj.emittedQty]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data2: Data
                });



            })
            .catch(err => {
                console.log(err);
            });
        console.log(energyConsumed, usedFor);

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalRawMaterial/GetTotalRawMaterials')
            .then(response => {



                console.log(response.data);

                const Data = [['Type', 'Quantity Consumed','Quantity Recycled']]
                for (const dataObj of response.data) {
                    Data.push([dataObj.type, dataObj.qtyConsumed,dataObj.qtyRecycled]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data3: Data
                });



            })
            .catch(err => {
                console.log(err);
            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/DirectEnergyConsumption/GetDirectEnergyConsumption')
            .then(response => {



                console.log(response.data);

                const Data = [['Type', 'Quantity']]
                let count = 0;
                
                for (const dataObj of response.data) {
                  
                    if (count != 5) {
                        Data.push([dataObj.fuelType, dataObj.quantity]);
                        count++;
                    }
                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data4: Data
                });



            })
            .catch(err => {
                console.log(err);
            });

      

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalWaterRecycled/GetTotalWaterRecycled')
            .then(response => {



                console.log(response.data);

                const Data = [['Month', 'Quantity']]
                
                for (const dataObj of response.data) {
                    Data.push([dataObj.month, dataObj.quantity]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data5:Data
                });



            })
            .catch(err => {
                console.log(err);
            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/RainWaterHarvesting/GetRainHarvestTransac')
            .then(response => {



                console.log(response.data);

                const Data = [['Harvesting Type', 'Quantity']]

                for (const dataObj of response.data) {
                    Data.push([dataObj.harvetingType, dataObj.quantity]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data6: Data
                });



            })
            .catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalDischargedWater/GetTotalDischargedWater')
            .then(response => {



                console.log(response.data);

                const Data = [['Disposal Type', 'Total Discharge']]

                for (const dataObj of response.data) {
                    Data.push([dataObj.disposalType, dataObj.totalDischarge]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data7: Data
                });



            })
            .catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/DischargeWaterQuality/GetTotalDischargedQualityWater')
            .then(response => {



                console.log(response.data);

                const Data = [['Pollutant Name', 'Discharge Amount']]

                for (const dataObj of response.data) {
                    Data.push([dataObj.pollutantName, dataObj.dischargeAmt]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data8: Data
                });



            })
            .catch(err => {
                console.log(err);
            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/SignificantSpills/GetSpillageDetails')
            .then(response => {



                console.log(response.data);

                const Data = [['Spillage Type', 'Amount']]

                for (const dataObj of response.data) {
                    Data.push([dataObj.spillageType, dataObj.spilledAmt]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data9: Data
                });



            })
            .catch(err => {
                console.log(err);
            });

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/ComplianceDetails/GetComplianceData')
            .then(response => {



                console.log(response.data);

                const Data = [['Month', 'Total Compliance','Fine Paid']]

                for (const dataObj of response.data) {
                    Data.push([dataObj.complianceMonth, dataObj.totalCompliance,dataObj.finePaid]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data10: Data
                });



            })
            .catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/SignificantCarbonEmission/GetCarbonEmission')
            .then(response => {



                console.log(response.data);

                const Data = [['Fuel Type','Fuel Used']]

                for (const dataObj of response.data) {
                    Data.push([dataObj.fuelType,dataObj.fuelUsed]);

                    // calorificFuelValue.push(dataObj.calorificFuelValue);
                    //fuelType.push(dataObj.fuelType);
                    /* let datenew = dataObj.submissionDate;
                     
                     datenew = datenew.split('T', 3).join(' ');
                     datenew = datenew.split(':', 3).join(' ');
                     datenew = datenew.split('00', 3).join(' ');
                   
    
                     date.push(datenew)
                     location.push(dataObj.locationName)
                     console.log(fuelType)*/
                }

                this.setState({
                    Data11: Data
                });



            })
            .catch(err => {
                console.log(err);
            });

    }



    handleChange = (idx) => (e) => {


    };





    handleSubmit = event => {
        

        var curmonth = this.state.longMonth;
        // const rows = {...this.state.rows}
        var check = this.state.rows.filter(x => x.month == curmonth);
        this.setState({
            check: check
        })
        let energyConsumed = [];
        let usedFor = [];
        for (const dataObj of check) {
            energyConsumed.push(dataObj.energyConsumed);
            usedFor.push(dataObj.usedFor);
        }
        this.setState({
            Data: {
                labels: usedFor,
                datasets: [

                    {
                        label: "Quantity",
                        data: energyConsumed,

                        backgroundColor: ["#3e95cd", "#3cba9f", "#e8c3b9", "#c45850", "rgba(75, 192, 192, 0.6)"],
                        borderWidth: 4
                    }
                ]
            }
        });


        console.log(energyConsumed, usedFor);
    }

    handleSubmit1 = event => {
        
        var prevMonth = this.state.prevMonth;
        // const rows = {...this.state.rows}
        var check = this.state.rows.filter(x => x.month == prevMonth);
        this.setState({
            check: check
        })
        let energyConsumed = [];
        let usedFor = [];
        for (const dataObj of check) {
            energyConsumed.push(dataObj.energyConsumed);
            usedFor.push(dataObj.usedFor);
        }
        this.setState({
            Data: {
                labels: usedFor,
                datasets: [

                    {
                        label: "Quantity",
                        data: energyConsumed,

                        backgroundColor: ["#3e95cd", "#3cba9f", "#e8c3b9", "#c45850", "rgba(75, 192, 192, 0.6)"],
                        borderWidth: 4
                    }
                ]
            }
        });





        console.log(energyConsumed, usedFor);



    }



    render() {

        return (
            <div >

                <button onClick={this.handleSubmit} style={{ marginRight: '8px' }} className="btn btn-info btn-md">This Month</button>
                <button onClick={this.handleSubmit1} className="btn btn-info btn-md">Last Month</button>
                <br />
                <br />
               
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white',paddingTop:'10px',paddingBottom:'10px' }}>Chart Indicators </h5>
                <br />
                <div>
                <div class="card-body1">
                <div class="bg-white shadow p-3 mb-4 card border-light">
                   
                        <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '160px', color: '#000000' }}>Renewable Energy Consumption</h7>
                <Chart
                    width={'500px'}
                    height={'300px'}
                                    chartType="ComboChart"
                        loader={<div>Loading Renewable Energy Consumption</div>}
                    data={this.state.Data}
                    options={{
                        title: 'Renewable Energy Consumption',
                        titlePosition:'none',
                        chartArea: { height: '60%' },
                        legend: { position: 'none' },
                        vAxis: {
                            title: 'Quantity',
                            minValue: 0,
                        },
                        hAxis: {
                            title: 'Used For',
                        },
                        seriesType: 'bars',
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '2' }}
                />

                            </div>
                        
                    </div>
                    </div>
                <div class="card-body2">
                <div class="bg-white shadow p-3 mb-4 card border-light">
                               
                                    <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '160px', color: '#000000' }}>Total Water Withdrawal</h7>
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="ComboChart"
                        loader={<div>Loading Total Water Withdrawal</div>}
                        data={this.state.Data1}
                        options={{
                           
                         
                           
                            title: 'Total Water Withdrawal',
                            titlePosition: 'none',
                            chartArea: { height: '60%' },
                            vAxis: { title: 'Quantity' },
                          
                            seriesType: 'bars',
                            series: { 5: { type: 'line' } },
                            legend: { position: 'bottom' },
                           
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '2' }}
                    />
              
                       </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '170px', color: '#000000' }}>NOx,SOx & Other Air Emissions</h7>
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Air Emissions</div>}
                        data={this.state.Data2}
                        options={{
                            legend: { position: 'bottom' },
                            title: 'NOx,SOx & Other Air Emissions',
                            titlePosition: 'none',
                            is3D: true,
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '2' }}
                    />
                            </div>
</div>
                    
                    </div>
               
                    <div class="card-body2">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '170px', color: '#000000' }}>Total Raw Material Used</h7>
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="ComboChart"
                        loader={<div>Loading Total Raw Material</div>}
                        data={this.state.Data3}
                        options={{
                            title: "Total Raw Material Used",
                            titlePosition:'none',
                            
                            vAxis: { title: 'Quantity Recycled' },
                            legend: { position: 'bottom' },
                            seriesType: 'bars',
                            series: { 5: { type: 'line' } },
                            chartArea: { height: '60%' },
                            bubble: { textStyle: { fontSize: 11 } },
                            colorAxis: { colors: ['orange', 'blue','green'] },

                        }}
                        rootProps={{ 'data-testid': '1' }}
                        />
                
                </div>
                </div>
                </div>
                </div>


                <div>
                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '170px', color: '#000000' }}> Rain Water Harvesting</h7>
                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="ComboChart"
                                        loader={<div>Loading Rain Water Harvesting</div>}
                                        data={this.state.Data6}
                                        options={{
                                            title: 'Rain Water Harvesting',
                                            titlePosition:'none',
                                            legend: { position: 'none' },
                                            hAxis: {
                                                title: 'Type',
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: 'Quantity',
                                            },
                                            seriesType: 'bars',
                                        }}
                                        // For tests
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                               
                            </div>
                        </div>
                    </div>


                    <div class="card-body2">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '200px', color: '#000000' }}> Total Water Discharge</h7>
                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Water Discharge</div>}
                                        data={this.state.Data7}
                                        options={{
                                            title: "Total Water Discharge",
                                            titlePosition:'none',
                                            legend: { position: 'bottom' },
                                          
                                            pieHole: 0.4,

                                        }}
                                        rootProps={{ 'data-testid': '5' }}
                                    />
                               
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '180px', color: '#000000' }}> Direct Energy Consumption</h7>
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="ComboChart"
                        loader={<div>Loading Direct Energy Consumption</div>}
                                    data={this.state.Data4}

                        options={{
                            title: "Direct Energy Consumption",
                           titlePosition:'none',
                           
                            legend: { position: 'none' },
                            hAxis: { title: 'Fuel Type' },
                            vAxis: { title: 'Quantity' },
                            seriesType: 'bars',
                       
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
               
                </div>
                </div>
                </div>
                   
                        <div class="card-body2">
                            <div class="bg-white shadow p-3 mb-4 card border-light">

                                <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '180px', color: '#000000' }}> Total Water Recycled</h7>
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Water Recycled</div>}
                        data={this.state.Data5}
                        options={{
                            title: "Water Recycled",
                            titlePosition:'none',
                            legend: { position: 'bottom' },
                           
                            enableInteractivity: false,

                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
               </div>
                </div>
                </div>
                </div>

            

                <div>
                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '180px', color: '#000000' }}> Discharge Water Quality</h7>
                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Discharge Water Quality</div>}
                                        data={this.state.Data8}
                                        options={{
                                            title: 'Discharge Water Quality',
                                            titlePosition: 'none',
                                            legend: { position: 'none' },
                                            hAxis: {
                                                title: 'Pollutant Name',
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: 'Discharge Amount',
                                            },

                                        }}
                                        // For tests
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                               
                            </div>
                        </div>
                    </div>


                    <div class="card-body2">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '180px', color: '#000000' }}> Significant Spills</h7>

                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="ComboChart"
                                        loader={<div>Loading Significant Spills</div>}
                                        data={this.state.Data9}
                                        options={{
                                            title: "Significant Spills",
                                            titlePosition:'none',
                                            seriesType: 'bars',
                                            legend: { position: 'none' },
                                            vAxis: { title: 'Amount' },
                                            hAxis: { title: 'Spillage Type' },
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="card-body1">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '180px', color: '#000000' }}>Env Legal Compliance</h7>
                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="BarChart"
                                        loader={<div>Loading Legal Compliance</div>}
                                        data={this.state.Data10}
                                        options={{
                                            title: 'Env Legal Compliance',
                                            titlePosition:'none',
                                            legend: { position: 'bottom' },
                                            hAxis: {
                                                
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: 'Month',
                                            },
                                            isStacked: true,
                                        }}
                                        // For tests
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                              
                            </div>
                        </div>
                    </div>


                    <div class="card-body2">
                        <div class="bg-white shadow p-3 mb-4 card border-light">

                            <div class="row">
                                <h7 style={{ fontSize: '12px', paddingLeft: '180px', color: '#000000' }}> Significant Carbon Emissions</h7>

                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                    chartType="PieChart"

                                        loader={<div>Carbon Emissions</div>}
                                        data={this.state.Data11}
                                        options={{
                                            title: "Significant Carbon Emissions",
                                            titlePosition: 'none',
                                            legend: { position: 'bottom' },
                                            is3D: true,
                                            pieHole: 0.4,
                                            colorAxis: { colors: ['yellow', 'red'] },

                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            


          


        )
    }
}

export default Dashboardnew