// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
    [ "Work Related Fatalities" ],
    ["Occupational Illness Issues"],
    ["EHS Training"],
    ["Total Recordable Injury Frequency Rate(TRI FR)"],
    ["Lost Time Injury Frequency Rate"]
]


var website = [
    ["Work Related Fatalities"],
    ["Occupational Illness Issues"],
    ["EHS Training"],
    ["Total Recordable Injury Frequency Rate(TRI FR)"],
    ["Lost Time Injury Frequency Rate"]
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"',
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
    bugs,
   
  website,
  server,
};
