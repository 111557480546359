import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';


class Editwastebytd extends Component {
    constructor(props) {
        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
            count: 0,
            rows: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }],
            waste1: 'Hazardous I(ink, chemicals etc.)',
            waste2: 'Hazardous II (Oil soaked cloth, bio medical waste etc)',
            waste3: 'Hazardous III (Used oil, waste oil etc)',
            waste4: 'SubTotal of Hazardous (I,II & III)',
            waste5: 'Non Hazardous (eg Solid waste,ETP/STP sludge biodegradable waste etc)',
            waste6: 'E Waste',

            waste7: 'SubTotal of Non Hazardous',
            waste8: 'Plastic Waste',

            waste9: 'Total Waste (in tonnes)',
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear
        }

    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/WasteByTd/GetWasteByTdById', { params: { ReferenceId: id } }).then(response => {
            console.log(response.data);

            this.setState({
                rows: response.data
            });


        });
    }


   

    handletotal1(idx1, idx2, idx3, idx) {
        
        
        var longYear = this.state.longYear;
        var longMonth = this.state.longMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        const rows = [...this.state.rows];
        rows[idx].quantityGenerated = parseFloat(rows[idx1].quantityGenerated) + parseFloat(rows[idx2].quantityGenerated) + parseFloat(rows[idx3].quantityGenerated)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = longMonth;
        rows[idx].Year = longYear;
        this.setState({
            rows
        });
       
    };


    handletotal2 (idx1, idx2, idx3, idx)  {


       
        var longYear = this.state.longYear;
        var longMonth = this.state.longMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        const rows = [...this.state.rows];
        rows[idx].quantityRecycled = parseFloat(rows[idx1].quantityRecycled) + parseFloat(rows[idx2].quantityRecycled) + parseFloat(rows[idx3].quantityRecycled)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = longMonth;
        rows[idx].Year = longYear;
        this.setState({
            rows
        });
    };


    handletotal3 (idx1, idx2, idx3, idx) {


        var longYear = this.state.longYear;
        var longMonth = this.state.longMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        const rows = [...this.state.rows];
        rows[idx].disposal = parseFloat(rows[idx1].disposal) + parseFloat(rows[idx2].disposal) + parseFloat(rows[idx3].disposal)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = longMonth;
        rows[idx].Year = longYear;
        this.setState({
            rows
        });
    };


    handletotal4 = (idx1, idx2) => (e) => {


        e.preventDefault();

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var longMonth = this.state.longMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        const name = e.target.name;
        if (name == "quantityGenerated") {
            rows[idx2].quantityGenerated = rows[idx1].quantityGenerated
        }

        else if (name == "quantityRecycled") {
            rows[idx2].quantityRecycled = rows[idx1].quantityRecycled
        }

        else {

            rows[idx2].disposal = rows[idx1].disposal
        }
        rows[idx2].WmasterId = idx2 + 1;
        rows[idx2].UserId = utype;
        rows[idx2].LocationId = location;
        rows[idx2].Month = longMonth;
        rows[idx2].Year = longYear;
        this.setState({
            rows
        });

        this.handletotal5(3, 5, 6, 7, 8);
        this.handletotal6(3, 5, 6, 7, 8);
        this.handletotal7(3, 5, 6, 7, 8);
    };
    handletotal5 (idx1, idx2, idx3, idx4, idx){


       

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var longMonth = this.state.longMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        rows[idx].quantityGenerated = parseFloat(rows[idx1].quantityGenerated) + parseFloat(rows[idx2].quantityGenerated) + parseFloat(rows[idx3].quantityGenerated) + parseFloat(rows[idx4].quantityGenerated)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = longMonth;
        rows[idx].Year = longYear;
        this.setState({
            rows
        });
    };
    handletotal6(idx1, idx2, idx3, idx4, idx) {

      

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var longMonth = this.state.longMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        rows[idx].quantityRecycled = parseFloat(rows[idx1].quantityRecycled) + parseFloat(rows[idx2].quantityRecycled) + parseFloat(rows[idx3].quantityRecycled) + parseFloat(rows[idx4].quantityRecycled)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = longMonth;
        rows[idx].Year = longYear;
        this.setState({
            rows
        });
    };

    handletotal7(idx1, idx2, idx3, idx4, idx) {

  

        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var longMonth = this.state.longMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        rows[idx].disposal = parseFloat(rows[idx1].disposal) + parseFloat(rows[idx2].disposal) + parseFloat(rows[idx3].disposal) + parseFloat(rows[idx4].disposal)
        rows[idx].WmasterId = idx + 1;
        rows[idx].UserId = utype;
        rows[idx].LocationId = location;
        rows[idx].Month = longMonth;
        rows[idx].Year = longYear;
        this.setState({
            rows
        });
    };

    handleChange = (idx) => (e) => {
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        //let id=name+(idx);
        const value = parseFloat(e.target.value);
        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var longMonth = this.state.longMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "WmasterId": idx + 1,
            "Year": longYear,
            "Month": longMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };

    handleAddRow = () => (event) => {
       
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }


    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/WasteByTd/UpdateWasteByTd', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push("/energyview/?name=" + "12.Waste By Type and Disposal &&" + "id=" + submissionid);
            }).catch(err => console.log(err));


    }




  
    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>
                {/* <button onclick={this.handleaddrow()} style={{ float: 'right' }} classname="btn btn-info btn-md">+add</button> */}

                <div class="shadow1">

                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                                <th>S.NO</th>
                                <th>WASTE CATEGORY</th>
                                <th>QUANTITY GENERATED</th>
                                <th>RECYCLE/REUSE</th>
                                <th>DISPOSAL</th>
                                {/*  <th>ACTION</th> */}
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>1</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste1}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="quantityGenerated" step="any" required id="standard-required" value={this.state.rows[0].quantityGenerated} onChange={this.handleChange(0)} onKeyUp={() => {this.handletotal1(0, 1, 2, 3);this.handletotal5(3, 5, 6, 7, 8)}}/>
                                </td>

                                <td>
                                    <TextField type="number" name="quantityRecycled" step="any" required id="standard-required" value={this.state.rows[0].quantityRecycled} onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal2(0, 1, 2, 3); this.handletotal6(3, 5, 6, 7, 8) }}/>
                                </td>
                                <td>
                                    <TextField type="number" name="disposal" step="any" required id="standard-required" value={this.state.rows[0].disposal} onChange={this.handleChange(0)} onKeyUp={() => { this.handletotal3(0, 1, 2, 3); this.handletotal7(3, 5, 6, 7, 8) }}/>
                                </td>


                            </tr>

                            <tr>
                                <td>2</td>
                                <td >
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste2}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="quantityGenerated" step="any" required id="standard-required" value={this.state.rows[1].quantityGenerated} onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal1(0, 1, 2, 3); this.handletotal5(3, 5, 6, 7, 8) }}/>
                                </td>

                                <td>
                                    <TextField type="number" name="quantityRecycled" step="any" required id="standard-required" value={this.state.rows[1].quantityRecycled} onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal2(0, 1, 2, 3); this.handletotal6(3, 5, 6, 7, 8) }}/>
                                </td>
                                <td>
                                    <TextField type="number" name="disposal" step="any" required id="standard-required" value={this.state.rows[1].disposal} onChange={this.handleChange(1)} onKeyUp={() => { this.handletotal3(0, 1, 2, 3); this.handletotal7(3, 5, 6, 7, 8) }}/>
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste3}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="quantityGenerated" step="any" required id="standard-required" value={this.state.rows[2].quantityGenerated} onChange={this.handleChange(2)} onKeyUp={() => { this.handletotal1(0, 1, 2, 3); this.handletotal5(3, 5, 6, 7, 8) }} />
                                </td>

                                <td>
                                    <TextField type="number" name="quantityRecycled" step="any" required id="standard-required" value={this.state.rows[2].quantityRecycled} onChange={this.handleChange(2)} onKeyUp={() => { this.handletotal2(0, 1, 2, 3); this.handletotal6(3, 5, 6, 7, 8) }} />
                                </td>
                                <td>
                                    <TextField type="number" name="disposal" step="any" required id="standard-required" value={this.state.rows[2].disposal} onChange={this.handleChange(2)} onKeyUp={() => { this.handletotal3(0, 1, 2, 3); this.handletotal7(3, 5, 6, 7, 8) }} />
                                </td>

                            </tr>

                            <tr>
                                <td>4</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste4}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="quantityGenerated" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[3].quantityGenerated} />
                                </td>

                                <td>
                                    <TextField type="number" name="quantityRecycled" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[3].quantityRecycled} />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }}  name="disposal" value={this.state.rows[3].disposal} />
                                </td>

                            </tr>
                            <tr class="table-info">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>

                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste5}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="quantityGenerated" step="any" required id="standard-required" value={this.state.rows[4].quantityGenerated} onChange={this.handleChange(4)} onKeyUp={this.handletotal4(4, 6)} />
                                </td>

                                <td>
                                    <TextField type="number" name="quantityRecycled" step="any" required id="standard-required" value={this.state.rows[4].quantityRecycled} onChange={this.handleChange(4)} onKeyUp={this.handletotal4(4, 6)} />
                                </td>
                                <td>
                                    <TextField type="number" name="disposal" step="any" required id="standard-required" value={this.state.rows[4].disposal} onChange={this.handleChange(4)} onKeyUp={this.handletotal4(4, 6)} />
                                </td>

                            </tr>

                            <tr>
                                <td>6</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste6}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="quantityGenerated" step="any" required id="standard-required" value={this.state.rows[5].quantityGenerated} onChange={this.handleChange(5)} onKeyUp={() => { this.handletotal1(0, 1, 2, 3); this.handletotal5(3, 5, 6, 7, 8) }}/>
                                </td>

                                <td>
                                    <TextField type="number" name="quantityRecycled" step="any" required id="standard-required" value={this.state.rows[5].quantityRecycled} onChange={this.handleChange(5)} onKeyUp={() => { this.handletotal2(0, 1, 2, 3); this.handletotal6(3, 5, 6, 7, 8) }}/>
                                </td>
                                <td>
                                    <TextField type="number" name="disposal" step="any" required id="standard-required" value={this.state.rows[5].disposal} onChange={this.handleChange(5)} onKeyUp={() => { this.handletotal3(0, 1, 2, 3); this.handletotal7(3, 5, 6, 7, 8) }} />
                                </td>

                            </tr>
                            <tr class="table-info">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>

                            </tr>
                            <tr>
                                <td>7</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste7}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="quantityGenerated" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[6].quantityGenerated} />
                                </td>

                                <td>
                                    <TextField type="number" name="quantityRecycled" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[6].quantityRecycled} />
                                </td>
                                <td>
                                    <TextField type="number" name="disposal" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[6].disposal} />
                                </td>


                            </tr>
                            <tr class="table-info">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>

                            </tr>
                            <tr>
                                <td>8</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste8}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" name="quantityGenerated" step="any" required id="standard-required" value={this.state.rows[7].quantityGenerated} onChange={this.handleChange(7)} onKeyUp={() =>this.handletotal5(3, 5, 6, 7, 8)} />
                                </td>

                                <td>
                                    <TextField type="number" name="quantityRecycled" step="any" required id="standard-required" value={this.state.rows[7].quantityRecycled} onChange={this.handleChange(7)} onKeyUp={() =>this.handletotal6(3, 5, 6, 7, 8)} />
                                </td>
                                <td>
                                    <TextField type="number" name="disposal" step="any" required id="standard-required" value={this.state.rows[7].disposal} onChange={this.handleChange(7)} onKeyUp={() => this.handletotal7(3, 5, 6, 7, 8)} />
                                </td>

                            </tr>
                            <tr>
                                <td>9</td>
                                <td>
                                    <TextField style={{ width: '500px' }}
                                        name="Waste"
                                        defaultValue={this.state.waste9}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[8].quantityGenerated} />
                                </td>

                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[8].quantityRecycled} />
                                </td>
                                <td>
                                    <TextField type="number" InputProps={{ readOnly: true }} required id="standard-required" value={this.state.rows[8].disposal} />
                                </td>

                            </tr>

                            {/*  {this.renderRows()} */}

                        </tbody>
                    </Table>
                </div >
                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editwastebytd