import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';


class Mockdrill extends Component {

    constructor(props) {
        super(props)
       
        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;
        this.state = {
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            type1: 'Newspaper',
            type2: 'Ink',
            type3: 'Chemical',
            type4: 'Plates',
            rows: [{ id: 0 }],
            rows1: [],
            rows2:[],
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            locationName:'',
            admincode: localStorage.getItem('admincode'),
            prevMonth: prevMonth,
            prevYear:prevYear
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UplMockDrill/GetUserLocation', { params: { UserId: this.state.utype } })
            .then(response => {
                let typesapi1 = response.data;
                
                this.setState({
                    locationName: typesapi1[0].locationName

                });
               console.log(this.state.locationName)
            })

            .catch(error => {
                console.log(error);

            });
        

    }



    handleChange = e => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
       
         const value = e.target.value;
        
        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = parseInt(this.state.longYear);
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var admincode = parseInt(this.state.admincode)
        var locationName=this.state.locationName
        var utype = parseInt(this.state.utype)
        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }
        let row = {
            ...rows[0],
         
            [name]: value,
            "UserId": utype,
            "LocationId": location,
            "SiteName":locationName,
            "YearOfDrill": year,
            "MonthOfDrill": prevMonth
        };
        rows[0] = row;
        this.setState({
            rows
        });


        let row1 = {
            ...rows1[0],

            "FormId": 2,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode

        };

        rows1[0] = row1;
        this.setState({
            rows1
        });

    };






    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async(event) => {
        
        event.preventDefault();
      
        const { rows } = this.state;
        const { rows1 } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear
        var prevYear=this.state.prevYear
        if (prevMonth == "December") {
            year =prevYear;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UplMockDrill/GetMockDrillDetailsMonthWise', { params: { MonthOfDrill: prevMonth, YearOfDrill: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows2:response.data
                })

            })

        var rows2 = this.state.rows2

        if (rows2.length == 0) {


            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            var newbody = JSON.parse(Options.body);
            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let checkbody = JSON.parse(Options1.body);
            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/UplMockDrill/SaveMockDrillDetails', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)
            ]).then(axios.spread((response1, response2) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }
       

    }

    handleClick1 = () => (event) => {

        this.props.history.push("/mockdrillview");
    }



    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing:'border-box'}}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label class="no-space-break">

                        Date Of Reporting:&nbsp;{this.state.newdate}  &nbsp;
                        {this.state.longMonth},
                   {this.state.longYear}

                    </label>

                    <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                    <br />
                    <br />


                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Mock Drill </h5>
                
                <div className="col-1">
                    <br />

                <div>
                        <label class="no-space-break">Name of site: </label> <br />
                        <TextField InputProps={{ readOnly: true }} selected type="text" name="SiteName" required id="standard-required" value={this.state.locationName}/>
                </div>
                <br />
                <div >
                    <label class="no-space-break">Date of evacuation drill: </label> <br />
                        <TextField selected type="date" name="DateOfDrill" required id="standard-required" onChange={this.handleChange} />
                </div>
                <br />
               

                    
                    <div>
                        <label class="no-space-break">Time alarm was raised: </label>
                        <br />
                        <TextField
                           name="AlarmTime"

                            type="time"
                            defaultValue="hh:mm"

                            InputLabelProps={{
                                shrink: true,
                            }}
                           
                            onChange={this.handleChange}
                        />
                    </div>
                    



                    <br />
                </div>
                <div className="col-2">
                <br />
                   
                
                <div>
                    <label class="no-space-break">Time of evacuation: </label>
                    <br />
                    <TextField
                            name="EvacuationTime"

                        type="time"
                        defaultValue="hh:mm"

                        InputLabelProps={{
                            shrink: true,
                        }}
                       
                            onChange={this.handleChange}
                    />
                </div>
                <br />
                <div>
                    <label class="no-space-break">Time of all clear signals: </label>
                    <br />
                    <TextField
                            name="ClearSignal"

                        type="time"
                        defaultValue="hh:mm"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        
                            onChange={this.handleChange}
                    />
                    </div>
                    <br />
                   
                    <div>
                        <label class="no-space-break">Time of arrival of fire brigade: </label>
                        <br />
                        <TextField
                            name="FireBrigadeArrivalTime"

                            type="time"
                            defaultValue="hh:mm"

                            InputLabelProps={{
                                shrink: true,
                            }}
                          
                            onChange={this.handleChange}
                        />
                    </div>

                </div>
                <div>
                    <br />
                  
                   
                    <label class="no-space-break">ERT members: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        name="TeamMemberName"
                        style={{ margin: 8 }}

                        
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.handleChange}
                    />
                   <label class="no-space-break">Defeciencies noted during drill: </label> 
                <br/>
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}
                    
                        name="DeficienciesNoted"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                        variant="outlined"
                        onChange={this.handleChange}
                />

                <br />
                <label class="no-space-break">Corrective action recommended: </label>
                <br />
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}

                        name="ActionTaken"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                        variant="outlined"
                        onChange={this.handleChange}
                />

                <br />
                <label class="no-space-break">Fire extinguisher used,if any: </label>
                <br />
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}
                        name="FireExtinguisher"

                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                        variant="outlined"
                        onChange={this.handleChange}
                />

                <br />
                <label class="no-space-break">Any special remarks: </label>
                <br />
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}

                        name="Remarks"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                        variant="outlined"
                        onChange={this.handleChange}
                />
                

                
                    </div>






                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Mockdrill