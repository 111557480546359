import React, { Component } from "react";
import "./login.css";
import CustomInput from "./CustomInput";
import { ButtonToggle } from "reactstrap";
import axios from 'axios';
import Button from "./Button";
import background from "../pic.jpg";

class ChangePassword extends Component {
    state = {
        OldPassword: "",
        Upwd: "",
        confirm:'',
        email:'',
        message: '',
        rows:[]
    };


    componentDidMount() {
 
        document.getElementById('header').style.display = "none"
        document.getElementById("navribbon").style.display = "none";
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        var email = params.get('email');
        if (email == null) {

            email = localStorage.getItem('email')
        }
        this.setState({
           email:email
        })
        const page = params.get('page');
        if (page == 'forgotpassword') {
            document.getElementById('oldpassword').style.display = "none"
        }
        else {

            document.getElementById('oldpassword').style.display = "inline"
        }
    }



    handleChange = e => {

        const name = e.target.name;
      
        const value = e.target.value;
        
        this.setState({
      [name]:value

        })
        let rows = this.state.rows;
        var email = this.state.email;
        let row = {

            ...rows[0],
            "Email":email,
            [name]: value
        };
        rows[0]= row;
        this.setState({
            rows
        });
    };

    handleClick = event => {

        event.preventDefault();
        window.location.href = '/'
    };
    handleConfirmPassword = event => {
 
        const { Upwd, confirm } = this.state;
        if (Upwd !== confirm) {
            document.getElementById('message').style.color = 'red';
            document.getElementById('message').innerHTML = 'Password Not Matching';
            this.setState({
                message:'Password Not Matching'
            })

        }
        else {

            document.getElementById('message').style.color = 'green';
            document.getElementById('message').innerHTML = 'Password Matching';
            this.setState({
                message: 'Password Matching'
            })

        }
    }

    handleOldPassword = event => {
        var email = this.state.email;
        var oldpassword = this.state.OldPassword
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserLogin/checkOldPassword', { params: { Email: email,Upwd:oldpassword } })
            .then(response => {
                
              
            })

       
    }
    isDisabled() {
        var message = this.state.message

        if (message == 'Password Matching') {

            return false;

        }
        else {
            return true;
        }

    }
    handleSubmit = e => {

        debugger;
   e.preventDefault()
            let rows = this.state.rows;
            const Options = {
                method: 'PUT',
                body: JSON.stringify(rows[0])
            };
            let newbody = JSON.parse(Options.body);
            axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/UpdatePassword', newbody)
                .then(response => {

                    alert('Password Updated Sucessfully');
                    this.props.history.push("/");


                }).catch(err => console.log(err));
   
    }


    render() {
        return (
            /* <div style={{ backgroundImage: `url(${background})`, backgroundsize: "cover",
                backgroundPosition: "center center"
               }}>*/

            <div className="login-wrapper">
                <form id="loginform"  >
                    <div className="form-group">
                        <h5>CHANGE PASSWORD</h5>
                        <br />
                        <div id='oldpassword'>
                            {/*<label for='username' className="no-space-break" style={{ color: 'white' }}> OLD PASSWORD</label>
                            <input type="password" name="OldPassword" id="OldPassword" onChange={this.handleChange} onKeyUp={this.handleOldPassword}/> */}
                            </div>
                        <label for='password' className="no-space-break" style={{ color: 'white' }}> NEW PASSWORD</label>
                        <input type="password" name="Upwd" id="Upwd" onChange={this.handleChange} />
                        <label for='password' className="no-space-break" style={{ color: 'white' }}> CONFIRM PASSWORD</label>
                        <input type="password" name="confirm" id="confirm" onChange={this.handleChange} onKeyUp={this.handleConfirmPassword} />
                        <span id='message'></span>
                        <br />
                        <span style={{ float: 'left', paddingTop: '15px' }}><a style={{ color: 'white' }} href="" onClick={this.handleClick}>Back to Login</a></span>
                        <Button onClick={this.handleSubmit} style={{ float: 'right' }} type="button" color="primary" className="form__custom-button" disabled={this.isDisabled()}>
                            SUBMIT
          </Button>
                    </div>
                </form>
            </div >
            /*</div>*/
        );
    }
}
export default ChangePassword    