import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';


class Editmockdrill extends Component {

    constructor(props) {
        super(props)
       
        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            
            rows: [],
          
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            locationName: '',
            dateOfDrill: '',
            alarmTime:''

        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UplMockDrill/GetMockDrillDetailsById', { params: { MdrillId: id } }).then(response => {
            console.log(response.data);


            this.setState({
               rows:response.data[0]
            });
            this.setState({
                alarmTime: response.data[0].alarmTime,
                unit: response.data[0].unit,
                qtyConsumed: response.data[0].qtyConsumed,
                qtyRecycled: response.data[0].qtyRecycled,
                id: id
            });

            
        });
        

    }

    formatDate(string) {
        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
       
        var check = new Date(string).toLocaleDateString([], options);
        
           return Moment(check).format('YYYY-MM-DD')
        
        
}


    handleChange = e => {
       
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
       
         const value = e.target.value;
        
        var rows = this.state.rows;
       
        
        let row = {
            ...rows,
         
            [name]: value,
           // "UserId": utype,
           // "LocationId": location,
            //"SiteName":locationName,
           // "YearOfDrill": longYear,
           // "MonthOfDrill": longMonth
        };
        rows = row;
        this.setState({
            rows
        });
    };






    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;
     

        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/FireExtingisher/UpdateFireExtinguisherDetails', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/fireextinguisherview')
            }).catch(err => console.log(err));


    }

    




    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing:'border-box'}}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
               
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px'}}>Mock Drill </h5>
                
                <div className="col-1">
                    <br />

                <div>
                        <label class="no-space-break">Name of Site: </label> <br />
                        <TextField InputProps={{ readOnly: true }} selected type="text" name="SiteName" required id="standard-required" value={this.state.rows.siteName}/>
                </div>
                <br />
                <div >
                    <label class="no-space-break">Date of Evacuation Drill: </label> <br />
                        <TextField type="date" name="dateOfDrill"  required id="standard-required" onChange={this.handleChange} value={ this.formatDate(this.state.rows.dateOfDrill)} />
                </div>
                <br />
               

                    
                    <div>
                        <label class="no-space-break">Time Alarm Was Raised: </label>
                        <br />
                        <TextField
                           name="alarmTime"

                            type="time"
                            defaultValue="hh:mm"

                            InputLabelProps={{
                                shrink: true,
                                required:true
                            }}
                           
                            onChange={this.handleChange}
                            value={this.state.rows.alarmTime}
                        />
                    </div>
                    



                    <br />
                </div>
                <div className="col-2">
                <br />
                   
                
                <div>
                    <label class="no-space-break">Time of Evacuation: </label>
                    <br />
                    <TextField
                            name="evacuationTime"

                        type="time"
                        defaultValue="hh:mm"

                        InputLabelProps={{
                            shrink: true,
                        }}
                       
                            onChange={this.handleChange}
                            value={this.state.rows.evacuationTime}
                    />
                </div>
                <br />
                <div>
                    <label class="no-space-break">Time of All Clear Signals: </label>
                    <br />
                    <TextField
                            name="clearSignal"

                        type="time"
                        defaultValue="hh:mm"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        
                            onChange={this.handleChange}
                            value={this.state.rows.clearSignal}
                    />
                    </div>
                    <br />
                   
                    <div>
                        <label class="no-space-break">Time of Arrival of Fire Brigade: </label>
                        <br />
                        <TextField
                            name="fireBrigadeArrivalTime"

                            type="time"
                            defaultValue="hh:mm"

                            InputLabelProps={{
                                shrink: true,
                            }}
                          
                            onChange={this.handleChange}
                            value={this.state.rows.fireBrigadeArrivalTime}
                        />
                    </div>

                </div>
                <div>
                    <br />
                  
                   
                    <label class="no-space-break">Safety Team Members: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        name="teamMemberName"
                        style={{ margin: 8 }}

                        
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.rows.teamMemberName}
                    />
                   <label class="no-space-break">Defeciencies Noted during Drill: </label> 
                <br/>
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}
                    
                        name="deficienciesNoted"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.rows.deficienciesNoted}
                    />
            

                <br />
                <label class="no-space-break">Corrective Action Recommended: </label>
                <br />
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}

                        name="actionTaken"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.rows.actionTaken}
                />

                <br />
                <label class="no-space-break">Fire Extinguisher Used,If any: </label>
                <br />
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}
                        name="fireExtinguisher"

                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.rows.fireExtinguisher}
                       
                />

                <br />
                <label class="no-space-break">Any Special Remarks: </label>
                <br />
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}

                        name="remarks"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.rows.remarks}
                />
                

                
                    </div>






                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editmockdrill