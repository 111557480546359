import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editrawmaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows:'',
            id:0,
            type: '',
            unit: '',
            qtyConsumed: '',
            qtyRecycled: ''
        };
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalRawMaterial/GetTotalRawMaterialbyId', { params: { TMatId: id } }).then(response => {
            console.log(response.data);
       

            this.setState({ 
                type: response.data[0].type,
                unit: response.data[0].unit,
                qtyConsumed: response.data[0].qtyConsumed,
                qtyRecycled: response.data[0].qtyRecycled,
                id:id
            });
           
        });
    }
    handleSubmit = event => {
        debugger;
        event.preventDefault();
        let row = this.state.rows;
       
        const Options = {
            method: 'PUT',
           // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
       // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalRawMaterial/UpdateRawMaterials', newbody)
        .then(response => {
            alert("Entry Updated Successfully");
            this.props.history.push("/energyview/?name=" + "13.Raw Material &&" + "id=" + submissionid);

        }).catch(err => console.log(err));
        }

    handleChange = event => {
       
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;
      
        const value = parseFloat(event.target.value);
        let rows = this.state;
        let idx = parseInt(this.state.id)
        let row = {
            
            ...rows,
            TMatId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







   // handleChange = (event) => {
     //   this.setState({ type: event.target.value });
   // }




  //  handleSubmit = (event) => {
    //    event.preventDefault();
      //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
   // }

    render() {
        return (
            <div className="form-container">
            <Form className="register-form" onSubmit={this.handleSubmit}>
                
                    <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="type">Type</label>
                        <input disabled name="type" className="form-control" id="type" defaultValue={this.state.type} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="unit">Unit</label>
                        <input disabled name="unit" className="form-control" id="unit" defaultValue={this.state.unit}  />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="qtyConsumed">Quantity Consumed</label>
                            <input required type="number" name="qtyConsumed" step="any" className="form-control" id="qtyConsumed" defaultValue={this.state.qtyConsumed} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="qtyRecycled">Quantity Recycled</label>
                            <input required type="number" name="qtyRecycled" step="any" className="form-control" id="qtyRecycled" defaultValue={this.state.qtyRecycled} onChange={this.handleChange} />
                    </div>
                </div>

                    <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>
                   
                </Form>
            </div>
        );
    }
}

export default Editrawmaterial