import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'
import TextField from '@material-ui/core/TextField';
import { Table } from 'react-bootstrap';
import { alignPropType } from 'react-bootstrap/esm/DropdownMenu';



class Deconsumptionmasterentry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: '',
            id: 0,
            FuelType: '',
            Unit: '',
            DensityNtp: '',
            CalorificFuelValue: ''

        };
    }
    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }

        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
    }
    handleSubmit = event => {
      
        const { rows } = this.state;

        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        let newbody = JSON.parse(Options.body);
        console.log(newbody)
        axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/DirectEnergyMaster/SaveDirectEnergyMaster', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Data Save Successfully");
                    this.props.history.push('/rawmaterial')
                }

                else {
                    alert('Data Saved Successfully');
                    this.props.history.push('/rawmaterial')
                }
            })
    }


    handleChange = event => {

        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        var value = event.target.value;
        let rows = this.state.rows;

        let row = {
            ...rows,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (



            <Form className="font-link" onSubmit={this.handleSubmit}>

                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#306754', color: 'white' }}>
                            <th>FUEL TYPE</th>
                            <th>UNIT</th>
                            <th>DENSITY NTP</th>
                            <th>CALORIFIC VALUE</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <TextField required name="FuelType" id="FuelType" defaultValue={this.state.type} onChange={this.handleChange} />
                        </td>


                        <td>
                            <TextField required name="Unit" id="Unit" defaultValue={this.state.unit} onChange={this.handleChange} />
                        </td>





                        <td>
                            <TextField required name="DensityNtp" id="DensityNtp" defaultValue={this.state.DensityNtp} onChange={this.handleChange} />
                        </td>
                        <td>
                            <TextField required name="CalorificFuelValue" id="CalorificFuelValue" defaultValue={this.state.CalorificFuelValue} onChange={this.handleChange} />
                        </td>


                        <td>
                            <button type="submit" style={{ float: 'right' }} className="btn btn-success">SAVE</button>
                        </td>
                        </tr>
                        </tbody>
                </Table>
            </Form>

        );
    }
}

export default Deconsumptionmasterentry    