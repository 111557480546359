import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';


class Ieconsumptionview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            count: 0,
            rows: [],
            row1: { type1: 'Electricity', unit1: 'MwH', quantity1: '' },
            month1: '',
            year1: ''
        }

    }

    componentDidMount() {
        
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null ) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        if (utypeid == 1) {


            //   document.getElementById('monthfilter').style.display = "none";
            // document.getElementById('status').style.display = "flex";
            //   const values = this.props.location.search
            //  const params = new URLSearchParams(values);
            //  const id = params.get('id');
            // this.setState({
            //     id: id
            // });
            var id = localStorage.getItem("submissionid")
            axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IndirectEnergyConsumption/GetIndirectEnergyFiltered', { params: { SubmissionId: id } }).then(response => {
                
                if (response.data.length != 0) {
                    this.setState({
                        rows: response.data,
                        month1: response.data[0].month,
                        year1: response.data[0].indEnrYear

                    })
                }

            })
        }
        else {
            
            var Status = localStorage.getItem('Status')
            var id = localStorage.getItem('submissionid')
            if (Status == 'Rejected' || id != "null") {
                var id = localStorage.getItem("submissionid")
                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IndirectEnergyConsumption/GetIndirectEnergyFiltered', { params: { SubmissionId: id } }).then(response => {
                    this.setState({
                        rows: response.data,
                        month1: response.data[0].month,
                        year1: response.data[0].indEnrYear

                    })

                })
            }


            else {

                var month = localStorage.getItem('month');
                var year = localStorage.getItem('year');
                var id = localStorage.getItem('utype')

                axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/IndirectEnergyConsumption/GetIndirectEnergyMonthWise', { params: { Month: month, Year: year, UserId: id } }).then(response => {
                    console.log(response.data);
                    this.setState({
                        rows: response.data
                    });
                });
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.rows);
    }

    isDisplayed() {


        var Status = localStorage.getItem('Status')
        var utypeid = localStorage.getItem('utypeid')

        if (utypeid == 2 && Status == 'Rejected') {
            return "inline"

        }
        else {
            return "none";
        }
    }
    handleChange = (idx) => (e) => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        //let id=name+(idx);
        var value = 0;
        //let id=name+(idx);
        if (name == "quantity") {
            value = parseInt(e.target.value);

        }
        else {
            value = e.target.value;
        }
        const rows = [...this.state.rows];

        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,


        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };

    handleAddRow = () => (event) => {
      
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx, id) => (e) => {
      
        e.preventDefault();
        var result = window.confirm("Are you sure you want to delete?");
        if (result) {
            let index = idx + 1;
            axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/IndirectEnergyConsumption/DeleteIndirectConsumption', { params: { IndEnergyConId: id } }).then(response => {
                alert("Entry Deleted Successfully");
                window.location.reload();
                console.log(response);
            });
        }
      //  let rowarr = [...this.state.rows];
        //let deletedItem = rowarr.findIndex(item => item.id === idx);
        //rowarr.splice(deletedItem, 1);
       // this.setState({ ...this.state.rows = rowarr })

        // let row = [...this.state.rows.filter(x=>x.id!==idx)];
        // this.setState({rows:row})




        //  const rows = [...this.state.rows]
        //   rows.splice(idx,1)
        //   this.setState({ rows })
        //   e.preventDefault()
        //  var newrows=this.state.rows.slice();
        //  newrows.splice(idx,1);
        //  this.setState({ rows:newrows })

        // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
        //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
        //  this.setState({
        //    rows: newRows,
        //  });

    }
    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }




    handleSubmit = event => {
        const { rows } = this.state;

        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        let newbody = JSON.parse(Options.body);
        axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/IndirectEnergyConsumption/', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Data Save Successfully");
                    this.props.history.push('/rawmaterial')
                }

                else {
                    alert('Data Saved Successfully');
                    this.props.history.push('/rawmaterial')
                }
            })



    }


    isDisabled() {
        var utype = localStorage.getItem('utype')

        if (utype == 1) {
            return true;

        }
        else {
            return false;
        }

    }
  

    edit(idx) {
        
        var url = "/editieconsumption/?id=" + idx
        this.props.setUrl(url);

    }


    renderRows() {
        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}>

                 

                   
                    {/*       <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="type" required id="standard-required" defaultValue={item.type} />
                    </td >

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="unit" required id="standard-required" defaultValue={item.unit} />
                    </td> */}

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="quantity" required id="standard-required" defaultValue={item.quantity} />
                    </td>
                   
                    <td>
                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.indEnergyConId)} style={{marginRight: '8px', display: this.isDisplayed()}} disabled={this.isDisabled()}>
                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </button>
                        <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.indEnergyConId)} disabled={this.isDisabled()} style={{ display: this.isDisplayed() }}>
                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button>

                    </td>
                </tr>

            );
        });
    }

    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit}>

                {/* <h5>INDIRECT ENERGY CONSUMPTION </h5> */}
              
                <br />
                <label className="no-space-break">
                    {this.state.month1}   &nbsp;
                   {this.state.year1}
                    </label>
                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                               
                            {/*<th>TYPE</th>
                                <th>UNITS</th> */}
                            <th>ENERGY PURCHASED (MwH)</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                    <td>1</td>
                    <td>
                        <select value={this.state.type1} onChange={this.handleTypeChange1}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit1} onChange={this.handleUnitChange1}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density1} onChange={this.handleDensityChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific1} onChange={this.handleCalorificChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value1} onChange={this.handleValueChange1 } />  
                   </td>    
        
                </tr>
                <tr>
                    <td>2</td>
                    <td>
                        <select value={this.state.type2} onChange={this.handleTypeChange2}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit2} onChange={this.handleUnitChange2}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density2} onChange={this.handleDensityChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific2} onChange={this.handleCalorificChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value2} onChange={this.handleValueChange2} />  
                   </td>  
        
                </tr>
                <tr>
                    <td>3</td>
                    <td>
                        <select value={this.state.type3} onChange={this.handleTypeChange3}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit3} onChange={this.handleUnitChange3}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density3} onChange={this.handleDensityChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific3} onChange={this.handleCalorificChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value3} onChange={this.handleValueChange3} />  
                   </td>  
                  </tr> */}

                            {this.renderRows()}

                        </tbody>
                    </Table>
               
                {/*   <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>Save</button>

               <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Ieconsumptionview