import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import AirEmission from './AirEmission';
import EnergyConsumption from './EnergyConsumption';
import Water from './Water';
import Waste  from './Waste';
import Button from '@material-ui/core/Button';
import ReactLoading from "react-loading";
import SimpleCard from '../components/Card';

const useStyles = makeStyles((theme) => ({

    flex: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        margin: theme.spacing(2, 3),
        position: "relative",
        flexWrap: 'wrap'
    
      },
    radio: {
        margin: theme.spacing(0, 4)
      },
    m2:{
        margin: theme.spacing(4)
       },
    button: {
        margin: theme.spacing(2 , 1)
    }

}))

const workingLevelMenu = ["Company Level" , "Plant Level" , "Office Level"];

const years = Array.from(new Array(70) , (val , index) => index + 1980);
// console.log(years);

function formattingDate(year , month , day) {

    let firstDayYear = new Date(year , month , day);
    firstDayYear.setDate(firstDayYear.getDate() + 1);
    firstDayYear = firstDayYear.toISOString();
    firstDayYear = firstDayYear.replace(/T.*/ , '').split("-").reverse().join("-");
    firstDayYear = firstDayYear.split("-").reverse().join("-");

    return firstDayYear

}


export default function LandingPage() {

    const classes = useStyles();
    const [workingLevel, setWorkingLevel] = React.useState(null);
    const [workingLocation , setWorkingLocation] = React.useState([]);
    const [location , setLocation] = React.useState('');
    const [yearOne , setYearOne] = React.useState('2021');
    const [yearTwo , setYearTwo] = React.useState('2022');
    const [directEmissionOne , setDirectEmissionOne] = React.useState([]);
    const [directEmissionTwo , setDirectEmissionTwo] = React.useState([]);
    const [indirectEmissionOne , setIndirectEmissionOne] = React.useState([]);
    const [indirectEmissionTwo , setIndirectEmissionTwo] = React.useState([]);
    const [transportationOne , setTransportationOne] = React.useState([]);
    const [transportationTwo , setTransportationTwo] = React.useState([]);
    const [renewableOne , setRenewableOne] = React.useState([]);
    const [renewableTwo , setRenewableTwo] = React.useState([]);
    const [nonRenewableOne , setNonRenewableOne] = React.useState([]);
    const [nonRenewableTwo , setNonRenewableTwo] = React.useState([]);
    const [withdrawOne , setWithdrawOne] = React.useState([]);
    const [withdrawTwo , setWithdrawTwo] = React.useState([]);
    const [recycledOne , setRecycledOne] = React.useState([]);
    const [recycledTwo , setRecycledTwo] = React.useState([]);
    const [wasteOne , setWasteOne] = React.useState([]);
    const [wasteTwo , setWasteTwo] = React.useState([]);
    const [loadingStatus , setLoadingStatus] = React.useState(true);



    const handleChange = (event) => {
        console.log(event.target.value);
        setWorkingLevel(event.target.value);

        axios.get(process.env.REACT_APP_GLOBAL_CODE + "/api/UserRegistration/GetUserRoleLocation" , {
            params : {
                UserRoleId: event.target.value
            }
        })
        .then((response) => {
            // console.log(response);
            setWorkingLocation(response.data);

        })
        .catch((error) => {
            console.log(error);
        })


    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    }
    const handleYearOneChange = (event) => {
        setYearOne(event.target.value);
    }
    const handleYearTwoChange = (event) => {
        setYearTwo(event.target.value);
    }

   

    const onSubmitDataHandler = async () => {
        
        setLoadingStatus(true);
        await axios.all([
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardDirectEmissionone" , {
                params : {
                    StartDate: formattingDate(yearOne , 0 , 1),
                    EndDate : formattingDate(yearOne , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardDirectEmissionone" , {
                params : {
                StartDate : formattingDate(yearTwo , 0 , 1),
                EndDate : formattingDate(yearTwo , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardDirectEmissionTwo" , {
                params : {
                    StartDate : formattingDate(yearOne , 0 , 1),
                    EndDate: formattingDate(yearOne , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardDirectEmissionTwo" , {
                params : {
                    StartDate : formattingDate(yearTwo , 0 , 1),
                    EndDate: formattingDate(yearTwo , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardCarbonEmission" , {
                params : {
                    StartDate : formattingDate(yearOne , 0 , 1),
                    EndDate: formattingDate(yearOne , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardCarbonEmission" , {
                params : {
                    StartDate : formattingDate(yearTwo , 0 , 1),
                    EndDate : formattingDate(yearTwo , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardRenewablEnergy" , {
                params : {
                    StartDate : formattingDate(yearOne , 0 , 1),
                    EndDate : formattingDate(yearOne , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardRenewablEnergy" , {
                params : {
                    StartDate : formattingDate(yearTwo , 0 , 1),
                    EndDate : formattingDate(yearTwo , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardNonRenewable" , {
                params : {
                    StartDate : formattingDate(yearOne , 0 , 1),
                    EndDate : formattingDate(yearOne , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardNonRenewable" , {
                params : {
                    StartDate : formattingDate(yearTwo , 0 , 1),
                    EndDate : formattingDate(yearTwo , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardWaterWithdrawal" , {
                params : {
                    StartDate : formattingDate(yearOne , 0 , 1),
                    EndDate : formattingDate(yearOne , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardWaterWithdrawal" , {
                params : {
                    StartDate : formattingDate(yearTwo , 0 , 1),
                    EndDate : formattingDate(yearTwo , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardWaterRecycled" , {
                params : {
                    StartDate : formattingDate(yearOne , 0 , 1),
                    EndDate : formattingDate(yearOne , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardWaterRecycled" , {
                params : {
                    StartDate : formattingDate(yearTwo , 0, 1),
                    EndDate : formattingDate(yearTwo , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardWastebytd" , {
                params : {
                    StartDate : formattingDate(yearOne , 0 , 1),
                    EndDate : formattingDate(yearOne , 11 , 31)
                }
            }),
        await axios
            .get(process.env.REACT_APP_GLOBAL_CODE + "/api/Dashboardesg/GetDashboardWastebytd" , {
                params : {
                    StartDate : formattingDate(yearTwo , 0 , 1),
                    EndDate : formattingDate(yearTwo , 11 , 31)
                }
            })
            
        ])
            .then(axios.spread((directEmissionOneResponse , directEmissionTwoResponse , indirectEmissionOneResponse , indirectEmissionTwoResponse , transportationOneResponse , transportationTwoResponse , renewableOneResponse , renewableTwoResponse , nonRenewableOneResponse , nonRenewableTwoResponse , withdrawOneResponse , withdrawTwoResponse , recycledOneResponse , recycledTwoResponse , wasteOneResponse , wasteTwoResponse) => {
                // console.log(response1.data);
                setLoadingStatus(false)
                setDirectEmissionOne(directEmissionOneResponse.data);
                // console.log(response2.data);
                setDirectEmissionTwo(directEmissionTwoResponse.data);
                // console.log(indirectEmissionOneResponse.data)
                setIndirectEmissionOne(indirectEmissionOneResponse.data);
                setIndirectEmissionTwo(indirectEmissionTwoResponse.data);
                console.log(transportationOneResponse.data)
                setTransportationOne(transportationOneResponse.data);
                setTransportationTwo(transportationTwoResponse.data);
                setRenewableOne(renewableOneResponse.data);
                setRenewableTwo(renewableTwoResponse.data);
                setNonRenewableOne(nonRenewableOneResponse.data);
                setNonRenewableTwo(nonRenewableTwoResponse.data);
                setWithdrawOne(withdrawOneResponse.data);
                setWithdrawTwo(withdrawTwoResponse.data);
                setRecycledOne(recycledOneResponse.data);
                setRecycledTwo(recycledTwoResponse.data);
                setWasteOne(wasteOneResponse.data);
                setWasteTwo(wasteTwoResponse.data);
                

            }))
            .catch(error => {
                console.log(error);
            })

    }

    React.useEffect(() => {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid!= 4) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        onSubmitDataHandler();
    } , [])

    // console.log(loadingStatus);


    return (
        
        <div>
    
            
        <div className={classes.flex}>
        <div className={classes.root}>
        
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="demo-simple-select-standard-label">Working Level</InputLabel>
            <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={workingLevel}
            onChange={handleChange}
            autoWidth
            label="workingLevel"
            > 
             <MenuItem value={2}>Company Level</MenuItem>
            <MenuItem value={1}>Plant Level</MenuItem>
            <MenuItem value={3}>Office Level</MenuItem> 
            {/* {
                workingLevelMenu.map(index => {
                    console.log(index);
                    <MenuItem value={index}>{index}</MenuItem>
                })
            } */}
            </Select>
        </FormControl>
        </div>

        <div className={classes.radio}>
        {
            workingLevel && workingLevel !== 2 &&
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
        <InputLabel id="demo-simple-select-standard-label">Location</InputLabel>
        <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={location}
            onChange={handleLocationChange}
            autoWidth
            label="workingLocation"
            >
                {
                   workingLevel && workingLevel !== 2 && workingLocation ? workingLocation.map(option => {
                        return (
                            <MenuItem key={option.locationId} value={option.locationId}>
                                {option.locationName}
                            </MenuItem>
                        )
                    })
                    :
                    null

                }
            </Select>

            
        </FormControl>
        }

        </div>

        <div className={classes.radio}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-standard-label" sx={{fontWeight:"bold"}}>Year 1</InputLabel>
        <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={yearOne}
            onChange={handleYearOneChange}
            autoWidth
            label="yearOne"
            >
            {
                years.map(index => {
                    return (
                        <MenuItem key={index} value={index}>
                            {index}
                        </MenuItem>
                    )
                })
            }


            </Select>
        </FormControl>

        </div>

        <div className={classes.radio}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-standard-label" sx={{ fontWeight: "bold" }}>Year 2</InputLabel>
        <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={yearTwo}
            onChange={handleYearTwoChange}
            autoWidth
            label="yearTwo"
            >
            {
                years.map(index => {
                    return (
                        <MenuItem key={index} value={index}>
                            {index}
                        </MenuItem>
                    )
                })
            }


            </Select>
        </FormControl>

        </div>
        
        {/* <div className={classes.radio}> */}
        <Button variant="contained" color="primary" className={classes.button} onClick={onSubmitDataHandler}>
          Compare
        </Button>
        {/* </div> */}





        </div>
        {
            

            loadingStatus ? 
            <div className={classes.m2}>
           
            <SimpleCard>
            Loading
            <ReactLoading type='Bar' color='black' />
            </SimpleCard>
            </div>
            :
        <div className={classes.m2}>
            <Grid container  spacing={6}>
            <Grid item xs = {12} lg={12}>
                
                <AirEmission year1={yearOne} year2={yearTwo} directEmission1={directEmissionOne} directEmission2 = {directEmissionTwo} indirectEmission1={indirectEmissionOne} indirectEmission2 = {indirectEmissionTwo} transportation1={transportationOne} transportation2={transportationTwo}/>
                </Grid>
            <Grid item xs={12} lg={12}>
                <EnergyConsumption year1={yearOne} year2={yearTwo} renewable1={renewableOne} renewable2={renewableTwo} nonRenewable1={nonRenewableOne} nonRenewable2 = {nonRenewableTwo}/>

            </Grid>

            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                    <Water year1={yearOne} year2={yearTwo} withdraw1={withdrawOne} withdraw2={withdrawTwo} recycled1 = {recycledOne} recycled2={recycledTwo}/>

                </Grid>
                <Grid item xs={12} lg={12}>
                    <Waste year1={yearOne} year2={yearTwo} waste1={wasteOne} waste2={wasteTwo}/>
                </Grid>
            </Grid>
        </div>
        }
        
        </div>
    );
    }
