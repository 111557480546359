import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'reactstrap';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Modal } from "react-bootstrap";
import '../index.css';

class Accidentreport extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();

        this.state = {
            types: [],
            unit: '',
            validationError: "",
            count: 0,
            type1: 'First Aid Injury',
            type2: 'Minor Injury',
            type3: 'Lost Time Injury',
            type4: 'Serious Injury',
            type5: 'Fatal',
            type6: 'Male',
            type7: 'Female',
            type8: 'Employees',
            type9: 'Third Party',
            type10: 'Visitors',
            type11: 'Others',
            type12: 'Returned to work',
            type13: 'Send to hospital',
            type14: 'Send to home',
            type15: 'Returned to work from hospital',
            type16: 'Open',
            type17: 'Closed',
            rows: [{ id: 0 }],
            rows1: [{ id: 0 }],
            rows2: [],
            rows3:[],
            login: [],
            isOpen: false,
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            admincode: localStorage.getItem('admincode'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            newdate: newdate,
            locationName: '',
            image: process.env.PUBLIC_URL + '/assets/blank.png',
            selectedFile: null,
            AccidentId: 0
        }
    }


    componentDidMount() {
        
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid==1)
        {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UplMockDrill/GetUserLocation', { params: { UserId: this.state.utype } })
            .then(response => {
                let typesapi1 = response.data;
                this.setState({
                    locationName: typesapi1[0].locationName
                });
                console.log(this.state.locationName)
            })
            .catch(error => {
                console.log(error);
            });
    }


    handleChange = (idx) => (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const rows1 = [...this.state.rows1];
        let row = {
            ...rows1[idx],
            [name]: value,
        }
        rows1[idx] = row;
        this.setState({
            rows1
        });
    };


    handleChange1 = e => {
        e.preventDefault();
        const name = e.target.name;
        var value = 0
        if (name == "PersonAge") {
            value = parseInt(e.target.value)
        }
        else {
            value = e.target.value;
        }
        const rows = [...this.state.rows];
        const rows2 = [...this.state.rows2];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var locationName = this.state.locationName
        var utype = parseInt(this.state.utype)
        var admincode = parseInt(this.state.admincode)

        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows[0],
            [name]: value,
            "UserId": utype,
            "LocationId": location,
            "SiteName": locationName,
            "AccidentYear": year,
            "AccidentMonth": prevMonth
        }
        rows[0] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows2[0],
            "FormId": 5,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode
        };
        rows2[0] = row1;
        this.setState({
            rows2
        });
    };


    handleChange2 = e => {
        e.preventDefault();
        const name = e.target.name;
        this.setState({ selectedFile: e.target.files });
        this.setState({ image: URL.createObjectURL(e.target.files[0]) });
        console.log(this.state.selectedFile)
        let filename = e.target.files[0].name;
        let root = `/Images/` + filename
        const rows = [...this.state.rows];
        let row = {
            ...rows[0],
            [name]: root
        };
        rows[0] = row;
        this.setState({
            rows
        });
    };

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => {
        document.getElementById("Button").disabled = false;

        this.setState({ isOpen: false })
    };



    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleAddRow = () => (event) => {
        
        this.setState({ selected: null });
        let count = this.state.count
        let rows1 = this.state.rows1

        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        let item = {

            id: count

        };
        rows1[count] = item
        event.preventDefault();
        this.setState({
            rows1: rows1


        });
    };


    handleRemoveSpecificRow = (idx) => (e) => {
        e.preventDefault();
        let count = this.state.count
        count = count - 1;
        this.setState({ count })
        let rowarr = [...this.state.rows];
        let deletedItem = rowarr.findIndex(item => item.id === idx);
        rowarr.splice(deletedItem, 1);
        this.setState({ ...this.state.rows = rowarr })
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        
        const { rows } = this.state;
        const { rows2 } = this.state;
        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
        await axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/AccidentReport/GetAccidentReportByMonth', { params: { AccidentMonth: prevMonth, AccidentYear: year, UserId: id } })
            .then(response => {
                
                this.setState({
                    rows3: response.data
                })

            })

        var rows3 = this.state.rows3
        if (rows3.length == 0) {



            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            let newbody = JSON.parse(Options.body);
            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows2)
            };
            let checkbody = JSON.parse(Options1.body);

            // Create an object of formData
            const file = new FormData();
            // Update the formData object
            if (this.state.selectedFile != null) {
                file.append("formFile", this.state.selectedFile[0])
                file.append("filename", this.state.selectedFile[0].name)
            }
            axios.all([
                await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentReport/SaveAccidentDetails', newbody),
                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SafetyWalkList/UploadeFiles', file),
                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)
            ]).then(axios.spread((response1, response2) => {
                var AccidentId = response1.data;
                const { rows1 } = this.state;
                var i;
                for (i = 0; i < rows1.length; i++) {
                    let row = {
                        ...rows1[i],
                        "AccidentId": AccidentId
                    }
                    rows1[i] = row

                }
                this.setState({
                    rows1: rows1
                })
                const Options1 = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(rows1)
                };
                let checkbody = JSON.parse(Options1.body);
                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/AccidentReport/SaveAccidentRecommendation', checkbody)
                    .then(response => {
                        alert('Data Saved Successfully');
                        window.location.reload();
                        console.log(response.data.Status);
                    })
            })
            );
        }
        else {

            alert("Data Already Submitted")
            window.location.reload();
        }


        
    }


    handleClick1 = () => (event) => {
        window.location.href = '/accidentreportview'
    };

    render() {
        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                    <label class="no-space-break">Date Of Reporting:&nbsp;{this.state.newdate}&nbsp;
                    {this.state.longMonth},
                   {this.state.longYear}
                    </label>

                    <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                    <br />
                    <br />
                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white', paddingTop: '10px', paddingBottom: '10px' }}>Accident Report Form </h5>
                <div className="col-1">
                    <br />
                    <div>
                        <label class="no-space-break">Accident type:</label> <br />
                        <FormControl>
                            <select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"

                                name="AccidentType"
                                onChange={this.handleChange1}
                            >
                                <option selected disabled value='0'>--Select--</option>
                                <option value={this.state.type1} >First Aid Injury</option>
                                <option value={this.state.type2} >Minor Injury</option>
                                <option value={this.state.type3} >Lost Time Injury</option>
                                <option value={this.state.type4} >Serious Injury</option>
                                <option value={this.state.type5} >Fatal</option>

                            </select>

                        </FormControl>
                    </div>

                    <br />
                    <div >
                        <label class="no-space-break">Date of accident: </label> <br />
                        <TextField selected type="date" name="AccidentDate" required id="standard-required" onChange={this.handleChange1} />
                    </div>
                    <br />

                    <div >
                        <label class="no-space-break">Employee ID: </label> <br />
                        <TextField type="text" name="EmployeeId" required id="standard-required" onChange={this.handleChange1} />
                    </div>
                    <br />
                    <div>
                        <label class="no-space-break">Gender: </label> <br />
                        <FormControl>

                            <select required

                                name="Sex"
                                onChange={this.handleChange1}
                            >
                                <option disabled selected value='0'>--Select--</option>
                                <option value={this.state.type6} >Male</option>
                                <option value={this.state.type7} >Female</option>
                            </select>
                        </FormControl>
                    </div>
                    <br />
                    <div >
                        <label class="no-space-break">Age: </label>
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="PersonAge" required id="standard-required" onChange={this.handleChange1} />
                    </div>
                </div>


                <div className="col-3">
                    <br />
                    <div >
                        <label class="no-space-break">Image upload: </label>
                        <div class='box' ><img className="img-fluid1" src={this.state.image} alt='No Image Selected' width="300" height="250" /></div>
                        <input className="btn btn-primary btn-sm" type="file" name="UploadedImage" onChange={this.handleChange2} /> <br />
                    </div>
                </div>

                <div className="col-2">
                    <br />
                    <div>
                        <label class="no-space-break">Injured person type: </label> <br />
                        <FormControl>

                            <select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"

                                name="InjuredPersontype"
                                onChange={this.handleChange1}
                            >
                                <option selected disabled value='0'>--Select--</option>
                                <option value={this.state.type8} >Employees</option>
                                <option value={this.state.type9} >Third Party</option>
                                <option value={this.state.type10} >Visitor</option>
                                <option value={this.state.type11} >Others</option>
                            </select>
                        </FormControl>
                    </div>
                    <br />
                    <div>
                        <label class="no-space-break">Time of accident: </label>
                        <br />
                        <TextField
                            id="time"
                            name="AccidentTyime"
                            type="time"
                            defaultValue="hh:mm"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleChange1}
                        />
                    </div>
                   <br />
                    <div >
                        <label class="no-space-break">Name of injured person: </label> <br />
                        <TextField type="text" name="InjuredPersonName" required id="standard-required" onChange={this.handleChange1} />
                    </div>
                    <br />
                    <div>
                        <label class="no-space-break">Status of injured person: </label> <br />
                        <FormControl>

                            <select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                name="PositionOfInjured"
                                onChange={this.handleChange1}
                            >
                                <option selected disabled value='0'>--Select--</option>
                                <option value={this.state.type12} >Returned to work</option>
                                <option value={this.state.type13} >Send to hospital</option>
                                <option value={this.state.type14} >Send to home</option>
                                <option value={this.state.type15} >Returned to hospital from work</option>
                            </select>
                        </FormControl>
                    </div>
                   <br />
                    <div >
                        <label class="no-space-break">Shift: </label> <br />
                        <TextField type="text" name="Shift" required id="standard-required" onChange={this.handleChange1} />
                    </div>
                    <br />
                </div>
                <div>
                    <br />
                    <label class="no-space-break">Name of supervisor or address of employer/visitors/ if non-company employee : </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="SupervisorName"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.handleChange1}
                    />

                    <br />
                    <label class="no-space-break">Nature of treatment: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="NatureOfTreatment"

                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.handleChange1}
                    />
                    <br />
                    <label class="no-space-break">Details of accident and location: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="DetailsOfAccident"

                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.handleChange1}
                    />
                    <br />
                    <label class="no-space-break">Name of witness: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="WitnessName"

                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.handleChange1}
                    />
                    <br />
                    <label class="no-space-break">Designation of witness: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}

                        name="WitnessDesignation"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.handleChange1}
                    />
                    <br />
                    <label class="no-space-break">Root cause analysis: </label>
                    <br />
                    <TextField
                        id="outlined-full-width"
                        label="Description"
                        style={{ margin: 8 }}
                        name="RootCauseAnalysis"

                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.handleChange1}
                    />
                </div>
                <button id="Button" type="submit" disabled style={{ float: 'right', marginLeft: '14px' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>
                <div onClick={this.openModal} style={{ color: 'blue', textDecoration: 'underline', float: 'right', marginTop: '5px' }}>
                    <a> Recommendations  </a>
                </div>

                <Modal style={{ backgroundColor: '#ffffff00', borderColor: '#ffffff00', width: '840px' }}
                    size="lg"
                    show={this.state.isOpen} >
                    <div className="App1" >
                        <Modal.Header>
                            <Modal.Title>Recommendations</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <button onClick={this.handleAddRow()} style={{ float: 'right' }} className="btn btn-info btn-md">+ADD</button>
                            <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#306754', color: 'white' }}>
                                        <th>ACTION PLAN</th>
                                        <th>RESPONSIBILITY</th>
                                        <th>TIMELINES</th>
                                        <th>STATUS</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.rows1.map((item, idx) => (
                                        <tr id="addr0" key={item.id}>
                                            <td>
                                                <TextField multiline name="ActionPlan" required id="standard-required" onChange={this.handleChange(idx)} />
                                            </td>
                                            <td>
                                                <TextField name="Responsibility" required id="standard-required" onChange={this.handleChange(idx)} />
                                            </td>
                                            <td>
                                                <TextField name="TimeLine" required id="standard-required" onChange={this.handleChange(idx)} />
                                            </td>
                                            <td>
                                                <FormControl>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        defaultValue="Select"
                                                        name="Status"
                                                        onChange={this.handleChange(idx)}
                                                    >
                                                        <MenuItem value={this.state.type16}>Open</MenuItem>
                                                        <MenuItem value={this.state.type17}>Closed</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-outline-danger btn-sm"
                                                    onClick={this.handleRemoveSpecificRow(idx)}>
                                                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>

                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                       </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                SUBMIT
            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </Form>
        )
    }
}
export default Accidentreport