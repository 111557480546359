import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editwaterdischarge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: '',
            id: 0,
            type: '',
            
            quantity: ''
            
        };
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalDischargedWater/GetTotalDischargedWaterByID', { params: { WatDischargedId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0],
                type: response.data[0].disposalType,
               
                quantity: response.data[0].totalDischarge,
                
                id: id
            });

        });
    }
    handleSubmit = event => {
        
        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')

        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalDischargedWater/UpdateTotalDischargedWater', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push("/energyview/?name=" + "10.Water Discharge &&" + "id=" + submissionid);

            }).catch(err => console.log(err));
    }

    handleChange = event => {
        
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        const value = parseFloat(event.target.value);
        var rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
            WatDischargedId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };



    handletotal =  e => {


        e.preventDefault();

        var rows = this.state.rows;
        rows.totalDischarge = parseFloat(rows.landIrrigation) + parseFloat(rows.waterBodies) + parseFloat(rows.municipalDrains)

        this.setState({
            rows
        });
    };



    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <div className="form-container">
                <Form className="register-form" onSubmit={this.handleSubmit}>

                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="DisposalType">Land/Irrigation</label>
                            <input type="number" name="landIrrigation" step="any" className="form-control" id="landIrrigation" defaultValue={this.state.rows.landIrrigation} onChange={this.handleChange} onKeyUp={this.handletotal}/>
                        </div>
                    </div>
                   
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="WaterBodies">Water Bodies</label>
                            <input required type="number" name="waterBodies" step="any" className="form-control" id="waterBodies" defaultValue={this.state.rows.waterBodies} onChange={this.handleChange} onKeyUp={this.handletotal}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="MunicipalDrains">Municipal Drains</label>
                            <input type="number" name="municipalDrains" step="any" className="form-control" id="municipalDrains" defaultValue={this.state.rows.municipalDrains} onChange={this.handleChange} onKeyUp={this.handletotal}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="TotalDischarge">Total Discharge</label>
                            <input InputProps={{ readOnly: true }} type="number" name="totalDischarge" step="any" className="form-control" id="totalDischarge" value={this.state.rows.totalDischarge} />
                        </div>
                    </div>
                    
                    <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>

                </Form>
            </div>
        );
    }
}

export default Editwaterdischarge 