import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editreconsumption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            id: 0,
            energyConsumed: '',
            usedFor: '',
            remarks: '',
            reference:'',
            rows1: [],
        };
    }

   async componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
       
      await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/RenewableEnergyConsumption/GetRenewablEnergyConsumedByID', { params: { RenewId: id } }).then(response => {
           console.log(response.data);
        

          this.setState({
               rows:response.data[0],
               energyConsumed: response.data[0].energyConsumed,
               usedFor: response.data[0].usedFor,
               remarks: response.data[0].remarks,
               reference: response.data[0].referenceId,
               id: id
           });

       });
       var reference = this.state.reference
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EnergyIntensity/GetEnergyIntensityByReferenceId', { params: { ReferenceId: reference } }).then(response => {
            console.log(response.data);
           
            
            this.setState({
                rows1: response.data[0]


            });

        });
        

        
    }
    handleSubmit = event => {
        debugger;
        event.preventDefault();
        let row = this.state.rows;
        let rows1 = this.state.rows1
        rows1.renewableEnergy = row.energyConsumed;
        rows1.energyConsumed = parseFloat(row.energyConsumed) + parseFloat(rows1.renewableEnergy)
        rows1.intensity = (parseFloat(rows1.energyConsumed) / parseFloat(rows1.production) * 10000000)
        this.setState({
        rows1:rows1
        })
        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        const Options1 = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows1)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        
        let checkbody = JSON.parse(Options1.body);
       

        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/EnergyIntensity/UpdateEnergyIntensityDetails', checkbody)
            .then(response => {
                
            }).catch(err => console.log(err));

        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/RenewableEnergyConsumption/UpdateRenewableEnergyConsumed', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push("/energyview/?name=" + "3.Renewable Energy Consumption &&" + "id=" + submissionid);


            }).catch(err => console.log(err));
    }

    handleChange = event => {
       
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;
        var value = 0;
        if (name == "energyConsumed" ) {
            value = parseFloat(event.target.value);

        }
        else {
            value = event.target.value;
        }
        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
            RenewId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="energyConsumed">Renewable Energy Used(MwH)</label>
                        <input type="number" name="energyConsumed" className="form-control" step="any" id="energyConsumed" defaultValue={this.state.energyConsumed} onChange={this.handleChange} />
                    </div>
                </div>
                {/*   <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="usedFor">Used For</label>
                        <input type="text" name="usedFor" className="form-control" id="usedFor" defaultValue={this.state.usedFor} onChange={this.handleChange}/>
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="remarks">Remarks</label>
                        <input type="text" name="remarks" className="form-control" id="remarks" defaultValue={this.state.remarks} onChange={this.handleChange}/>
                    </div>
                </div> */}
               

                <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>
            </Form>
        );
    }
}

export default Editreconsumption