import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';


class Userregistration extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
            types: [],
            locations: [],
            unit: '',
            validationError: "",
            count: 0,
            Upwd: '',
            confirm:'',
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [],
            rows1:[],
            type1:'What Is your favorite book?',
            type2:'What is your mother’s maiden name?',
            type3:'What was the first company that you worked for?',
            type4:'Where did you go to high school/college?',
            type5:'What is your favorite food?',


        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/GetUsersRole')
            .then(response => {
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi

                });
                
            })
            
            .catch(error => {
                console.log(error);

            });
    }  




    






    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = event => {
       
        event.preventDefault();
        const name = event.target.name;

        if (name == "UserRoleId") {
            var val = parseInt(event.target.value);
            axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/GetUserRoleLocation', { params: { UserRoleId: val } })
                .then(response => {
                    let typesapi1 = response.data;
                    console.log(typesapi1);
                    this.setState({
                       locations: typesapi1

                    });

                })

                .catch(error => {
                    console.log(error);

                });
        }  

        
        var value = 0;
        if (name == "UtypeId" || name == "Contact" || name == "LocationId" || name == "UserRoleId") {
            value = parseInt(event.target.value);

        }
        else {

            value = event.target.value;
        }
        this.setState({
            [name]:value
        });
        const rows = [...this.state.rows];
     
        let row = {

            ...rows[0],
           
            [name]: value
        };
        rows[0] = row;
        this.setState({
            rows
        });

        
    }

    handleSubmit = async(event) =>  {
        debugger
        event.preventDefault();
        let row = this.state.rows;
        var userid = 0;
        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        //let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);

       
       await  axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/SaveUserDetails', newbody)

           .then(response => { 
               debugger;
               userid = response.data
               alert("Entry Inserted Successfully");
               this.props.history.push('/userlist')
             }).catch(err => console.log(err));

        let rows1 = this.state.rows1;
        
        if (row[0].UtypeId == 1) {
            var locationid = row[0].LocationId
         

            let row1 = {

                "UserId": userid,
                "LocationId": locationid
            };
            rows1[0] = row1;

            this.setState({
                rows1
            });
        
        const Options1 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows1)
        };
        let newbody1 = JSON.parse(Options1.body);
        
        axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/SaveMultiLocationUser', newbody1)
            .then(response => {


                

            }).catch(err => console.log(err));
    }
    }
    

   

    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                  


                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center', color: 'white' }}>User Registration </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">
                         <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Name">
                                    <label class="form-label">Name</label>
                                    <input required placeholder="Enter Name" name="Ucode" type="text" class="form-control" onChange={this.handleChange}/>
                                        </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Contact">
                                    <label class="form-label" >Contact</label>
                                    <input required placeholder="Enter Contact Number" name="Contact" type="number" class="form-control" onChange={this.handleChange}/>
                                </div>
                            </div>

                               
                        </div>

                       

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Email">
                                    <label class="form-label">Email Id</label><input required placeholder="Enter Email Address" name="Email" type="email" class="form-control" onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Address">
                                    <label class="form-label">Address</label><input required placeholder="Address" name="Address" type="text" class="form-control" onChange={this.handleChange}/>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            
                            <div class="mb-3 col-md-6">
                                <div id="utype">
                                    <label class="form-label">User Type</label> <br /><select required class="form-control" name="UtypeId" onChange={this.handleChange}><option selected disabled value='0'>--Select--</option><option value="1">Admin</option><option value="2" selected="">Plant User</option><option value="3">Regulator</option><option value="4">Corporate</option></select></div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="business">
                                    <label class="form-label">Role</label><br />
                                    <select required class="form-control" onChange={this.handleChange} name="UserRoleId">
                                        <option selected disabled value='0'>--Select--</option>
                                        
                                        {this.state.types.map(type => (
                                            <option
                                                key={type.userRoleId}
                                                value={type.userRoleId}
                                            >
                                                {type.roleType}
                                            </option>
                                        ))}
                                    </select></div>

                            </div>

                           
                        </div>

                        <div class="row">
                            
                            <div class="mb-3 col-md-6">
                                <div id="location">
                                    <label class="form-label">Location</label><br />
                                    <select name="LocationId" required class="form-control" onChange={this.handleChange}>
                                        <option selected disabled value='0'>--Select--</option>
                                       
                                        {this.state.locations.map(type => (
                                            <option
                                                key={type.locationId}
                                                value={type.locationId}
                                            >
                                                {type.locationName}
                                            </option>
                                        ))}
                                    </select></div>
                            </div>


                        </div>


                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="question">
                                    <label class="form-label" >Question</label>
                                    <select required placeholder="Write Security Question" class="form-control" name="SecurityQuestion" type="text" onChange={this.handleChange}>
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >What Is your favorite book?</option>
                                        <option value={this.state.type2} >What is your mother’s maiden name?</option>
                                        <option value={this.state.type3} >What was the first company that you worked for?</option>
                                        <option value={this.state.type4} >Where did you go to high school/college?</option>
                                        <option value={this.state.type5} >What is your favorite food?</option>
                                        </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="answer">
                                    <label class="form-label">Answer</label><input required placeholder="Write Security Answer" name="SecurityAnswer" type="text" class="form-control" onChange={this.handleChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                                
                


                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Userregistration