import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'
import '../index.css';

class Editstatistics extends Component {
    constructor(props) {
        super(props);
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {
            rows: '',
            id: 0,
            isRequired: '',
            comments: '',
            type1: 'Yes',
            type2: 'No',
            type3: 'NA',
            types:[],
            longMonth: longMonth,
            longYear: longYear,
            check: 0,
            check1:0

        };
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/AccidentStatistics/GetAccidentStatisticsById', { params: { StatisticsId: id } }).then(response => {
            console.log(response.data);

            this.setState({
                rows: response.data[0],
                smasterId:response.data[0].smasterId
              
            });
            
        });

       
        /*axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/AccidentStatistics/GetAccidentStatistics').then(response => {
         
            this.setState({
                types: response.data
            });


            let date = new Date()
            date.setMonth(date.getMonth() - 1);
            let prevMonth = date.toLocaleString('en-us', { month: 'long' });
            var check = 0
            console.log(prevMonth)
            if (prevMonth == "March") {
                this.setState({
                    check: 0,
                    check1: 0,

                })
            }
            else {
                check = this.state.types.filter(x => x.month == prevMonth && x.smasterId==this.state.smasterId);
                this.setState({
                    check: check,
                    check1: check[0].thisYear,

                })
              
            }
            console.log(this.state.check1)
        }); */

    }
    handleSubmit = event => {

        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/AccidentStatistics/UpdateAccidentStatisticsList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/monthlyehsview')

            }).catch(err => console.log(err));
    }

    handleChange = event => {
       debugger
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        const value = parseInt(event.target.value);
        var check = this.state.check1;
        var rows = this.state.rows;
        let idx = parseInt(this.state.id)
       /* var curmonth = this.state.longMonth;
        var total = 0
        if (curmonth == "April") {
            total = value
        }
        else {
            total = value + check
        } */
        let row = {

            ...rows,

            [name]: value,
           // "ThisYear":total
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <div className="form-container">
                <Form className="register-form" onSubmit={this.handleSubmit}>
                    <br />
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label class="no-space-break" htmlFor="question">{this.state.rows.details}</label>

                        </div>
                    </div>
                 
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="comments">This Month</label>
                            <input name="value" onChange={this.handleChange} className="form-control" id="value" defaultValue={this.state.rows.value} />
                        </div>
                    </div>


                    <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Update</button>

                </Form>
            </div>
        );
    }
}

export default Editstatistics              