import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editmisfireaccident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: '',
            id: 0,
            quantity: ''
        };
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/MisFireAccident/GetMisFireAccidentByID', { params: { MisFireId: id } }).then(response => {
            console.log(response.data);
            this.setState({
                quantity: response.data[0].totalAccidents,
                quantity1: response.data[0].totalAccidentsContractor,
                id: id
            });

        });
    }
    handleSubmit = event => {

        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/MisFireAccident/UpdateMisFireAccident', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Data Save Successfully");
                    this.props.history.push("/energyview/?name=" + "22.Total Occupational Illness &&" + "id=" + submissionid);
                }

                else {
                    alert('Data Saved Successfully');
                    this.props.history.push("/energyview/?name=" + "22.Total Occupational Illness &&" + "id=" + submissionid);
                }
            })
    }

    handleChange = event => {

        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        const value = parseInt(event.target.value);
        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
            MisFireId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <div class="col-sm-3">
                        <label class="no-space-break" htmlFor="TotalAccidents">No Of Cases(Employees) </label>
                        <input required type="number" name="TotalAccidents" className="form-control" id="TotalAccidents" defaultValue={this.state.quantity} onChange={this.handleChange} />
                    </div>
                    <div class="col-sm-3">
                        <label class="no-space-break" htmlFor="TotalAccidents">No Of Cases(Contractors) </label>
                        <input required type="number" name="TotalAccidentsContractor" className="form-control" id="TotalAccidents" defaultValue={this.state.quantity1} onChange={this.handleChange} />
                    </div>
                </div>

                <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>
            </Form>
        );
    }
}

export default Editmisfireaccident