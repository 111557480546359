import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import '../index.css';
import axios from 'axios';
import Tooltip from "react-simple-tooltip"
class Emission extends Component {
    constructor(props) {
        super(props)
        let date = new Date()
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        this.state = {
            count: 0,
            quantity: '',
            rows: [{ id: 0 }],
            types: [],
            row1: { rainfall: '', area: '', rwharvested: '', gwrecharged: '', swcollected: '' },
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth:prevMonth
        }

    }


    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/EmissionMaster/GetEmissionData')
            .then(response => {
                let typesapi = response.data;
                console.log(typesapi);
                this.setState({
                    types: typesapi

                });
                // ucode=localStorage.getItem('username'),
                // utype=localStorage.getItem('utype'),
                // location=localStorage.getItem('location')

            })
            //  .then(data => {
            // let typesapi = Object.keys(data.type);
            //console.log(typesapi);



            //    })
            .catch(error => {
                console.log(error);

            });
    }

    getIndex(value) {

        let arr = this.state.types;
        let prop = 'rmasterId'
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }

    handleChange1 = (idx) => (e) => {


        e.preventDefault();
        var index = this.getIndex(e.target.value);

        // const { name, value } = e.target;
        const name = e.target.name;

        var value1 = "";
        //let id=name+(idx);

        const value = parseInt(e.target.value);


        const types = [...this.state.types];
        var type = types[index].unit

        const login = [...this.state.login]
        let utype = this.state.utype;
        let location = this.state.location;
        let row1 = {

            "FormId": parseInt(value),
            "FormSlug": "RawMaterial",
            "LocationId": parseInt(location),
            "UserId": parseInt(utype),
            "SubmittedTo": 1



        };
        login[idx] = row1;
        this.setState({
            login
        });
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        const rows = [...this.state.rows];
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,

            "Year": longYear,
            "Month": prevMonth

        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };

    handleChange = (idx) => (e) => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        var value = 0;
        //let id=name+(idx);

        value = parseInt(e.target.value);


        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "EmittedYear": longYear,
            "EmittedMonth": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[idx] = row;
        this.setState({
            rows
        });
    };


    handleAddRow = () => (event) => {
        
        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]

        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx) => () => {
        
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }



    handleSubmit = event => {

        event.preventDefault();
        const { rows } = this.state;

        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        localStorage.setItem('emissions', Options.body);
        alert("Data Saved Successfully");
        /*let newbody = JSON.parse(Options.body);


        axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/EmissionDetails/SaveEmissionDetails', newbody)
            .then(response1 => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response1.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }); */


    }







    renderRows() {
        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}>



                    <td>
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="EmissionId"
                                onChange={this.handleChange(idx)}

                            >
                                {this.state.types.map(type => (
                                    <MenuItem
                                        key={type.emissionId}
                                        value={type.emissionId}
                                    >
                                        {type.emissionType}
                                    </MenuItem>
                                ))}
                            </Select>


                        </FormControl> </td>

                    <td>
                        <TextField type="number" required id="standard-required" name="EmittedQty" defaultValue={this.state.quantity} onChange={this.handleChange(idx)} />

                    </td>

                    <td>
                        <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx)}>
                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button>

                    </td>
                </tr>

            );
        });
    }

    render() {
        const text = "Emission of pollutants due to fuel burning & other processes within the organisation's boundary\nin the reporting period based on the measurement. Wherever measurements are not available,\nestimate the emissions from the fuel specifications. For eg. SPM in tonnes= (AxB) /1,00,00,00,000,\nwhere A is Measured SPM concentration in mg/Nm3, and B is the total volume of in m3 of flue\ngas (at NTP), emitted from stack during the reporting period."
        return (

            <Form className="font-link" onSubmit={this.handleSubmit}>

                <div className="App">
                    <div className="wordalign">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label>
                        {this.state.longMonth}   &nbsp;
                   {this.state.longYear}
                            &nbsp;&nbsp;
                    
                    <Tooltip
                        arrow={15}
                        background="#ffffff"
                        border="black"
                        color="black"
                        content={text.split("\n").map((i, key) => {

                            return <div key={key}>{i}</div>;


                        })}
                        fadeDuration={0}
                        fadeEasing="linear"
                        fixed={false}
                        fontFamily="Bw Glenn Sans Medium"
                        fontSize="12px"
                        offset={-20}
                        padding={15}

                        placement="right"
                        radius={50}
                        zIndex={1}




                    >

                            <span className="btn btn-outline-danger btn-sm">

                                info
                               {/*<svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                            </span>
                            </Tooltip>
                        </label>
                    <button onClick={this.handleAddRow()} style={{ float: 'right' }} className="btn btn-info btn-md">+ADD</button>
                    </div>
                    </div>
                {/* <h5>TOTAL WATER HARVESTING </h5> */}
                <br />
                <br />
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                            <th>TYPE</th>
                            <th>QUANTITY</th>

                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
                    <td>1</td>
                    <td>
                        <select value={this.state.type1} onChange={this.handleTypeChange1}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit1} onChange={this.handleUnitChange1}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density1} onChange={this.handleDensityChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific1} onChange={this.handleCalorificChange1}> 
                        <option value="1651">1651</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value1} onChange={this.handleValueChange1 } />  
                   </td>    
        
                </tr>
                <tr>
                    <td>2</td>
                    <td>
                        <select value={this.state.type2} onChange={this.handleTypeChange2}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit2} onChange={this.handleUnitChange2}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density2} onChange={this.handleDensityChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific2} onChange={this.handleCalorificChange2}> 
                        <option value="313">313</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value2} onChange={this.handleValueChange2} />  
                   </td>  
        
                </tr>
                <tr>
                    <td>3</td>
                    <td>
                        <select value={this.state.type3} onChange={this.handleTypeChange3}> 
                        <option value="hsd">HSD</option>
                        <option value="lpg">LPG</option>
                        <option value="png">PNG</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.unit3} onChange={this.handleUnitChange3}>
                        <option value="litres1">Litres</option>
                        <option value="litres2">Litres</option>
                        <option value="scm">SCM</option>
                       
                        </select>
                   </td>
        
                   <td>
                        <select value={this.state.density3} onChange={this.handleDensityChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                        <select value={this.state.calorific3} onChange={this.handleCalorificChange3}> 
                        <option value="5050">5050</option>
                        </select>
                   </td>
                   <td>
                     <input type="text" value={this.state.value3} onChange={this.handleValueChange3} />  
                   </td>  
                  </tr> */}

                        {this.renderRows()}

                    </tbody>
                </Table>

                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Emission