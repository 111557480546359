import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Collapse from "@kunukn/react-collapse";
import "./app.scss";
import Checkbox from '@material-ui/core/Checkbox';
import cx from "classnames";
import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';


class Workpermitcontent extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = (longYear - 1).toString();
        this.state = {
            types: [],
            isOpen1: false,
            unit: '',
            validationError: "",
            count: 0,
            type1: 'Yes',
            type2: 'No',
            type3: 'Gas Cutting',
            type4: 'Grinding',
            type5: 'Yes',
            type6: 'No',
            rows: [{ id: 0 }],
           rows1:[],
           rows2:[],
            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            prevMonth: prevMonth,
            prevYear: prevYear,
            newdate: newdate,
            admincode: localStorage.getItem('admincode'),
        }
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
    }



    handleChange = e => {
      
        const name = e.target.name;
        var value = ""
        if (name == "NoOfEmployees" ) {
            value = parseInt(e.target.value);

        }
        else {
            value = e.target.value;
        }
        const rows = [...this.state.rows];
        const rows1 = [...this.state.rows1];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var admincode = parseInt(this.state.admincode)

        var year = 0

        var prevYear = this.state.prevYear;
        if (prevMonth == "December") {
            year = prevYear
        }
        else {
            year = longYear
        }

        let row = {
            ...rows[0],
            [name]: value,
            "Year": year,
            "Month": prevMonth,
            "LocationId": location,
            "UserId": utype
        };
        rows[0] = row;
        this.setState({
            rows
        });
        let row1 = {
            ...rows1[0],
            "FormId": 19,
            "UserId": utype,
            "LocationId": location,
            "SubmittedTo": admincode
        };
        rows1[0] = row1;
        this.setState({
            rows1
        });
    };






    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleSubmit = async (event) => {
        event.preventDefault();
        
        const { rows } = this.state;
        const { rows1 } = this.state;
       


        var prevMonth = this.state.prevMonth;
        var year = 0;
        var longYear = this.state.longYear

        if (prevMonth == "December") {
            year = longYear - 1;
        }

        else {

            year = longYear
        }

        var id = localStorage.getItem('utype')
       
        await axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GeneralWorkPermit/GetGeneralWorkPermitByMonth', { params: { Month: prevMonth, Year: year, UserId: id } })
         .then(response => {
            
            this.setState({
                rows2: response.data
            })
        })

        var rows2 = this.state.rows2
       
        if (rows2.length == 0 ) {

            const Options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows)
            };
            let newbody = JSON.parse(Options.body);

            const Options1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(rows1)
            };
            let checkbody = JSON.parse(Options1.body);

            axios.all([
          await axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/GeneralWorkPermit/SaveGeneralWorkPermitList', newbody),

                axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/EHSAdminApproval/SaveEHSFormSubmission', checkbody)

            ]).then(axios.spread((response1) => {

                if (response1.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }
            }));

        }
        else {
            alert("Data Already Submitted")
            window.location.reload();
        }
    }

    handleClick1 = () => (event) => {
        window.location.href = '/workpermitcontentview'
    };

    toggle = (index) => {
        let collapse = "isOpen" + index;

        this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
    }


    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                    <label class="no-space-break">

                        Date Of Reporting:&nbsp;{this.state.newdate}  &nbsp;
                        {this.state.longMonth},
                   {this.state.longYear}

                    </label>

                    <button onClick={this.handleClick1()} style={{ float: 'right' }} className="btn btn-primary btn-md">View Previous Data</button>
                    <br />
                    <br />

                </div>
                <h5 style={{ backgroundColor: '#001946', textAlign: 'center',color:'white' }}>GENERAL WORK PERMIT </h5>
                <br />
                <h5 style={{ backgroundColor: '#001946',color:'white' }} >Permit Valid For</h5>
                <div className="col-1">
                    <br />


                    <div >
                        <label class="no-space-break">Date: </label> <br />
                        <TextField selected type="date" name="ValidDate" required id="standard-required" onChange={this.handleChange}/>
                    </div>
                    <br />
                    

                </div>
                <div className="col-2">

                    <br />

                    <div>
                        <label class="no-space-break">Time: </label>
                        <br />
                        <TextField
                            name="ValidTime"

                            type="time"
                            defaultValue="hh:mm"
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        
                        />
                    </div>
                    <br />
                    </div>

                <h5 style={{ backgroundColor: '#001946',color:'white' }} >Permit Expires</h5>
                    <div className="col-1">
                        <br />


                        <div >
                            <label class="no-space-break">Date: </label> <br />
                        <TextField selected type="date" name="ExpiryDate" required id="standard-required" onChange={this.handleChange}/>
                        </div>
                    <br />
                    <div >
                        <label class="no-space-break">No of Employees: </label> <br />
                        <TextField selected type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="NoOfEmployees" required id="standard-required" onChange={this.handleChange}/>
                    </div>
                    <br />


                    </div>
                    <div className="col-2">

                        <br />

                        <div>
                            <label class="no-space-break">Time: </label>
                            <br />
                            <TextField
                                name="ExpiryTime"

                                type="time"
                                defaultValue="hh:mm"
                               onChange={this.handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                
                            />
                        </div>
                    
                    <br />
                    <div >
                        <label class="no-space-break">Work Location: </label> <br />
                        <TextField selected type="location" name="WorkLocation" required id="standard-required" onChange={this.handleChange}/>
                    </div>
                    <br />

                </div>
               
               
                <br />
                <label class="no-space-break">Work Description: </label>
                <br />
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}
                    name="WorkDescription"
                    onChange={this.handleChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                />
                <br />
                <br />
              


                <h5 style={{ backgroundColor: '#001946', color: 'white' }} >Hazards Identified/Precautions Done (initials) Comments Pre-work requirements</h5>
              


                    <br />
                <div className='col-1'>
                    <div>
                        <label class="no-space-break">Equipment Locked out? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="EquipmentLocked"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                  
                    <br />
                    <div >
                        <label class="no-space-break">Atmospheric conditions tested for safety? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="AtmosphericCondition"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                    <br />

                    <br />
                    <div >
                        <label class="no-space-break">Stand-by guard posted? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="StandByGuard"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                    <br />
                    <br />
                    <div >
                        <label class="no-space-break">Emergency rescue procedures reviewed? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="EmergencyRescue"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                    <br />

                    <br />
                    <div >
                        <label class="no-space-break">Emergency crew advised? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="EmergencyCrew"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                    <br />
                    </div>
                <div className='col-2'>
                

                    <div >
                        <label class="no-space-break">Work requirements ? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="WorkRequirement"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                   
                    <br />
                    <div >
                        <label class="no-space-break">Area barricaded, roped off, signs posted? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="AreaBarricaded"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                    <br />

                    <br />
                    <div >
                        <label class="no-space-break">Personal protective equipment adequate? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="PersonalEquipment"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                    <br />

                    <br />
                    <div >
                        <label class="no-space-break">First aid readily available? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="FirstAid"
                                onChange={this.handleChange}

                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>


                   
                    <br />
                    </div>
                <br />
              
                <br />
                <br />
                <br />
                <br />

                <h5 style={{ backgroundColor: '#001946', color: 'white' }} >Where atmospheric tests are required, indicate results of test:</h5>

                <div className="col-1">
                    <br />


                    <div >
                        <label class="no-space-break">Oxygen: </label> <br />
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="Oxygen" required id="standard-required" onChange={this.handleChange}/> %
                    </div>
                    <br />
                    <div >
                        <label class="no-space-break">Toxicity: </label> <br />
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="Toxicity" required id="standard-required" onChange={this.handleChange} />PPM
                    </div>
                    <br />
                </div>
                <div className="col-2">

                    <br />

                    <div>
                        <label class="no-space-break">Flammability: </label>
                        <br />
                        
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} name="Flammability" required id="standard-required" onChange={this.handleChange}/> %
                      
                    </div>
                    <br />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="col-1">
                    <br />


                    <div >
                        <label class="no-space-break">Date: </label> <br />
                        <TextField selected type="date" name="ResultDate" required id="standard-required" onChange={this.handleChange}/>
                    </div>
                    <br />


                </div>
                <div className="col-2">

                    <br />

                    <div>
                        <label class="no-space-break">Time: </label>
                        <br />
                        <TextField
                            name="ResultTime"
                            onChange={this.handleChange}
                            type="time"
                            defaultValue="hh:mm"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            
                        />
                    </div>
                    <br />
                   
                    </div>
                <br />
              
                
                <label class="no-space-break">Comments: </label>
                <br />
                <TextField
                    id="outlined-full-width"
                    label="Description"
                    style={{ margin: 8 }}
                    name="Comments"
                    onChange={this.handleChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                />
                <br />
                <br />
                <h5 style={{ backgroundColor: '#001946', color: 'white' }} >Completion:</h5>
                <br />
                <div className='col-1'>
                    <div>
                <label class="no-space-break">Work Completion? </label> <br />
                <FormControl>

                    <Select isSearchable required
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        defaultValue="Select"
                        name="WorkCompletion"
                     onChange={this.handleChange}
                    >
                        <MenuItem value={this.state.type1} >Yes</MenuItem>
                        <MenuItem value={this.state.type2} >No</MenuItem>


                    </Select>

                        </FormControl>
                    </div>
                    <br />
                    <div>
                        <label class="no-space-break">Requirements after work complied with? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="RequirementsAfter"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>
                    </div>

                    </div>
             
                <div className='col-2'>
                    <div>
                    <label class="no-space-break">All safeguards returned to normal? </label> <br />
                    <FormControl>

                        <Select isSearchable required
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            defaultValue="Select"
                            name="Safeguards"
                                onChange={this.handleChange}
                        >
                            <MenuItem value={this.state.type1} >Yes</MenuItem>
                            <MenuItem value={this.state.type2} >No</MenuItem>


                        </Select>

                    </FormControl>

                    </div>
                    <br />

                    <div>
                        <label class="no-space-break">Man, and Materials removed from place of work? </label> <br />
                        <FormControl>

                            <Select isSearchable required
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="ManMaterialRemoved"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={this.state.type1} >Yes</MenuItem>
                                <MenuItem value={this.state.type2} >No</MenuItem>


                            </Select>

                        </FormControl>

                    </div>
                </div>
                <br />
                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>SAVE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Workpermitcontent