import React,{Component} from 'react'
import { Table, Modal} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink  } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import '../index.css';
import Tooltip from "react-simple-tooltip"
import axios from 'axios';



class Energyintensity extends Component {
    constructor(props) {
        super(props)
        let date = new Date()

        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        date.setMonth(date.getMonth() - 1)
        let prevMonth = date.toLocaleString('en-us', { month: 'long' });
        let prevYear = longYear - 1;
     this.state= {
      count:0,
         rows: [{}],
         row1: { ground: '', river: '', municipal: '', harvested: '', total: '' },
         ucode: localStorage.getItem('username'),
         utype: localStorage.getItem('utype'),
         location: localStorage.getItem('location'),
         longMonth: longMonth,
         longYear: longYear,
         prevMonth: prevMonth,
         prevYear: prevYear,
         isOpen: false
       
         }   
  
    }

    componentDidMount() {
        
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin ==null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var reconsumption = JSON.parse(localStorage.getItem("reconsumption"));
        var nonreconsumption = JSON.parse(localStorage.getItem("nonreconsumption"));
        var energyintensity = JSON.parse(localStorage.getItem("energyintensity"));
        
        var rows = this.state.rows;
        if (nonreconsumption != null) {
            
            rows[0].NonRenewableEnergy = nonreconsumption[0].EnergyConsumed;
          
        }
        if (reconsumption != null ) {
            rows[0].RenewableEnergy = reconsumption[0].EnergyConsumed;
           
        }
       
        rows[0].EnergyConsumed = parseFloat(rows[0].NonRenewableEnergy) + parseFloat(rows[0].RenewableEnergy)

        this.setState({
            rows
        });
       
        if (energyintensity != null) {
            energyintensity[0].NonRenewableEnergy = rows[0].NonRenewableEnergy
            energyintensity[0].RenewableEnergy = rows[0].RenewableEnergy
            energyintensity[0].EnergyConsumed = rows[0].EnergyConsumed

            var Production = energyintensity[0].Production;
            var EnergyConsumed = energyintensity[0].EnergyConsumed
            var Intensity = (parseFloat(EnergyConsumed) / parseFloat(Production) * 10000000)
            energyintensity[0].Intensity = Intensity
        }

        localStorage.setItem("energyintensity",JSON.stringify(energyintensity))
        
        if (energyintensity != null) {
            rows = energyintensity

            this.setState({
                rows
            })
        }


    }
    
handletotal = (idx) => (e) => {
       
   
    e.preventDefault();
       
    const rows = [...this.state.rows];
    var Intensity = (parseFloat(rows[idx].EnergyConsumed) / parseFloat(rows[idx].Production)*10000000) 
    rows[idx].Intensity=parseFloat(Intensity)
    this.setState({
    rows
    });
    };

    
    handletotal1 = (idx) => (e) => {

        
        e.preventDefault();

        const rows = [...this.state.rows];
         var energy= parseFloat(rows[idx].NonRenewableEnergy) + parseFloat(rows[idx].RenewableEnergy)
        rows[idx].EnergyConsumed=parseFloat(energy)
        this.setState({
            rows
        });
    };
    handletotal2()  {


        
        
        const rows = [...this.state.rows];
        var Intensity = (parseFloat(rows[0].EnergyConsumed) / parseFloat(rows[0].Production) * 10000000)
        rows[0].Intensity = parseFloat(Intensity)
        this.setState({
            rows
        });
    };

    handleChange = (idx) => (e) => {
        
        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name;
        var value = 0;
        //let id=name+(idx);
       
        value = parseFloat(e.target.value);
     
         
        const rows = [...this.state.rows];
        var longYear = this.state.longYear;
        var prevMonth = this.state.prevMonth;
        var location = parseInt(this.state.location);
        var utype = parseInt(this.state.utype);
        var month = localStorage.getItem("month1");
        var year = localStorage.getItem("year1")
        var Reference = localStorage.getItem("globalvar")
        //var year = 0

        //var prevYear = this.state.prevYear;
        //if (prevMonth == "December") {
        //    year = prevYear
        //}
        //else {
        //    year = longYear
        //}
        let row = {
            ...rows[idx],
            id: idx,
            [name]: value,
            "Year": year,
            "Month": month,
            "LocationId": location,
            "UserId": utype,
            "ReferenceId":Reference
           
        };
        rows[idx] = row;
        this.state.rows=rows
        this.handletotal2()
    };

    openModal = (e) => {


        this.setState({ isOpen: true }, () => {
            window.setTimeout(() => {
                this.setState({ isOpen: false })
            }, 2000)
        });

    }

    closeModal = () => this.setState({ isOpen: false });
    
                         handleAddRow =() => (event) => {
                        
                          let count=this.state.count
                          count=count+1;
                          this.setState({count})

                          // this.setState((prevState) => ({ count: prevState.count + '1'}));
                       
                          const item = {
                            id:count
                            // type: "",
                            // unit: "",
                            // consumed: "",
                            // recycle:""
                            
                          };
                          event.preventDefault();
                          this.setState({
                            rows: [...this.state.rows, item]                       
                            
                          });
                        };
                        // handleRemoveRow = (event) => {
                        //   event.preventDefault();
                        //   this.setState({
                        //     rows: this.state.rows.slice(0, -1)
                        //   });
                        // };
                        handleRemoveSpecificRow = (idx) => () => {
                        
                          const rows = [...this.state.rows]
                          rows.splice(idx, 1)
                          this.setState({ rows })
                        }
                    
    isDisabled1() {

        var submitcounter = localStorage.getItem('submitcounter')

        if (submitcounter == "true") {

            return true;

        }
        else {
            return false;
        }

    }

    handleSubmit = event => {
        debugger;
        event.preventDefault();
        const { rows } = this.state;
        console.log(rows)
        //var prevMonth = this.state.prevMonth;
        //var year = 0;
        //var longYear = this.state.longYear

        //if (prevMonth == "December") {
        //    year = longYear - 1;
        //}

        //else {

        //    year = longYear
        //}
        var month = localStorage.getItem('month1')
        var year = localStorage.getItem('year1');
        var id = localStorage.getItem('utype')
        //axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/EnergyIntensity/GetEnergyIntensityByMonth', { params: { Month: month, Year: year, UserId: id } })
        //    .then(response => {
                
        //        if (response.data.length == 0) {


                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    localStorage.setItem('energyintensity', Options.body);
                    let newbody = JSON.parse(Options.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/SaveTemporaryAPI/SaveEnergyIntensitytemp', newbody).then(response => {

                        if (response.data == 1) {
                            this.openModal()
                        }

                        else {

                            alert("Data Not Saved.Kindly Save Again")
                        }

                    })
            //    }
            //    else {

            //        alert("Data Already Submitted")
            //    }

            //})
      
       /* let newbody = JSON.parse(Options.body);
        axios.post("http://localhost:23013/api/TotalWaterWithdrawn/SaveTotalWaterWithdrawn", newbody)
            .then(response => {
                if (response.data.Status === 'Success') {
                    alert("Data Save Successfully");
                    window.location.reload();
                    console.log(response.data.Status);

                }

                else {
                    alert('Data Saved Successfully');
                    window.location.reload();
                }

            })

            //  .then(data => {
            // let typesapi = Object.keys(data.type);
            //console.log(typesapi);



            //    })
            .catch(error => {
                console.log(error);

            });*/



    }    




             renderRows(){
              return this.state.rows.map((item, idx) => {
                return(
                
                <tr id="addr0" key={item.id}>
               
                 
                
                 <td>  
                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="NonRenewableEnergy" onKeyUp={this.handletotal1(idx)} value={this.state.rows[idx].NonRenewableEnergy}/>
                </td>
               
               <td>
                            <TextField InputProps={{ readOnly: true }} type="number" required id="standard-required" name="RenewableEnergy" onKeyUp={this.handletotal1(idx)} value={this.state.rows[idx].RenewableEnergy} />

               </td>
               <td>
                            <TextField InputProps={{ readOnly: true }} type="number" name="EnergyConsumed" value={Math.round(this.state.rows[idx].EnergyConsumed*100)/100}/>
               </td> 
               <td>
                            <TextField InputProps={{ inputProps: { min: 0, max: 9999999999, step: "any" } }} type="number" required id="standard-required" name="Production" onKeyUp={this.handletotal(idx)} onChange={this.handleChange(idx)} value={this.state.rows[idx].Production}/>
               </td>  
               <td>
                            <TextField required type="number" InputProps={{ readOnly: true }} name="Intensity" value={Math.round(this.state.rows[idx].Intensity * 100) / 100} onKeyUp={this.handletotal(idx)} onClick={this.handletotal(idx)}/>
               </td>  
                        {/*<td>
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  onClick={this.handleRemoveSpecificRow(idx)}>
        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>                          
                                </button>
                                
                              </td> */}
            </tr>
               
            );
          });
            }

             render() {
                 const text = "This is total energy consumed for printing the newspaper.\nThe production volume is counted on nos. of PAGES PRINTED"
              return  (
                  <Form className="font-link" onSubmit={this.handleSubmit}>
                      <br />
                      <div className="App">
                          <div className="wordalign">
                          {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}

                          <label>
                              {this.state.longMonth} 
                   {this.state.longYear}
  &nbsp;  &nbsp;
                            
                              <Tooltip
                                  arrow={15}
                                  background="#ffffff"
                                  border="black"
                                  color="black"
                                  content={text.split("\n").map((i, key) => {

                                      return <div key={key}>{i}</div>;


                                  })}
                                  fadeDuration={0}
                                  fadeEasing="linear"
                                  fixed={false}
                                  fontFamily="Bw Glenn Sans Medium"
                                  fontSize="12px"
                                  offset={-20}
                                  padding={15}

                                  placement="right"
                                  radius={50}
                                  zIndex={1}




                              >

                                  <span className="btn btn-outline-danger btn-sm">

                                      info
                               {/*<svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>*/}
                                  </span>
                                  </Tooltip>
                              </label>
                              {/*  <button onClick={this.handleAddRow()} style={{ float: 'right' }} className="btn btn-info btn-md">+ADD</button> */}
                          </div>
                      </div>

                      <Modal style={{ backgroundColor: 'white', width: '500px', height: '200px', borderColor: '#ffffff00' }}

                          show={this.state.isOpen} onHide={this.closeModal}>

                          <Modal.Header style={{ backgroundColor: '#001946', color: 'white' }} >
                              <Modal.Title>Message</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                              Data Saved Successfully

                          </Modal.Body>
                      </Modal>
                      <br />
                      {/*   <h5>TOTAL WATER WITHDRAWAL </h5> */}
                  
        
                      <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                          <thead>
                              <tr style={{ backgroundColor: '#001946', color: 'white' }}>
                    
                    <th>TOTAL NON RENEWABLE ENERGY CONSUMED (MwH)</th>
                    <th>TOTAL RENEWABLE ENERGY CONSUMED (MwH)</th>
                                  <th>ENERGY CONSUMED (MwH)</th>
                                  <th>PRODUCTION<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                  <th>ENERGY INTENSITY<br /><span style={{ color: '#001946', visibility: 'hidden' }} >HIDDEN</span></th>
                                  {/*  <th>ACTION</th> */}
                  </tr>
                </thead>
                <tbody>
                 
                  {this.renderRows()}
                   
               </tbody>
               </Table>
               
                      <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit} disabled={this.isDisabled1()}>SAVE</button>   
       
             {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}
               
             
               </Form>
                   
              )
            }
}

export default Energyintensity