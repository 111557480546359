import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

class modal extends Component {
    state = {
        modal: false
    }

    openModal = () => {
        
        this.setState({ modal: true });
    }
    render() {
        return (
            <div>
           

            </div>
        );
    }
}

export default modal;