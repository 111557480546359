import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import '../index.css';
import Moment from 'moment';
import Tooltip from "react-simple-tooltip"

import { margins } from '@devexpress/analytics-core/analytics-diagram';
import { Label } from 'devextreme-react/circular-gauge';
import { DEBUG } from '@devexpress/analytics-core/core/utils/_utils';
import { parse } from '@devexpress/analytics-core/analytics-criteria';


class Editworkpermitcontent extends Component {

    constructor(props) {
        super(props)

        let date = new Date()
        let newdate = date.getDate();
        let longMonth = date.toLocaleString('en-us', { month: 'long' });
        let longYear = date.toLocaleString('en-us', { year: 'numeric' })
        this.state = {

            count: 0,


            login: [],
            ucode: localStorage.getItem('username'),
            utype: localStorage.getItem('utype'),
            location: localStorage.getItem('location'),
            longMonth: longMonth,
            longYear: longYear,
            newdate: newdate,
            rows: [{ id: 0 }],
            userroleid: '',
            type1: "Yes",
            type2: "No",


        }
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }

        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/GeneralWorkPermit/GetGeneralWorkPermitById', { params: { GeneralWorkId: id } }).then(response => {
            console.log(response.data);


            this.setState({
                rows: response.data[0]


            });

        });


    }









    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }

    handleChange = e => {

        e.preventDefault();
        // const { name, value } = e.target;
        const name = e.target.name

        var value = e.target.value


        var rows = this.state.rows;

        let row = {

            ...rows,
            [name]: value,

        };
        rows = row;
        this.setState({
            rows
        });
    };


    formatDate(string) {

        var options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        var check = new Date(string).toLocaleDateString([], options);

        return Moment(check).format('YYYY-MM-DD')


    }

    handleSubmit = event => {
        event.preventDefault();
        
        const { rows } = this.state;


        const Options = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rows)
        };
        var newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE + '/api/GeneralWorkPermit/UpdateGeneralWorkPermitList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/workpermitcontentview')
            }).catch(err => console.log(err));


    }






    render() {

        return (
            <Form className="font-link" onSubmit={this.handleSubmit} style={{ boxSizing: 'border-box' }}>

                {/*  <h5>TOTAL RAW MATERIAL USED </h5> */}
                <div className="App">
                    {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}




                </div>
                <h5 style={{ backgroundColor: '#001946', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Edit General Work Permit </h5>

                <div class="bg-white shadow-sm mb-4 card border-light">
                    <div class="card-body">

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="ValidDate">
                                    <label class="no-space-break">Valid Date</label>
                                    <input required name="validDate" type="date" class="form-control" value={this.formatDate(this.state.rows.validDate)} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="ValidTime">
                                    <label class="no-space-break" >Valid Time</label>
                                    <input  name="validTime" type="time" class="form-control" defaultValue={this.state.rows.validTime} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="ExpiryDate">
                                    <label class="no-space-break">Expiry Date</label>
                                    <input required name="expiryDate" type="date" class="form-control" value={this.formatDate(this.state.rows.expiryDate)} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="ExpiryTime">
                                    <label class="no-space-break" >Expiry Time</label>
                                    <input  name="expiryTime" type="time" class="form-control" defaultValue={this.state.rows.expiryTime} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="NoOfEmployees">
                                    <label class="no-space-break">Number Of Employees</label>
                                    <input required name="noOfEmployees" type="number" class="form-control" value={this.state.rows.noOfEmployees} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="WorkLocation">
                                    <label class="no-space-break" >Work Location</label>
                                    <input name="workLocation" type="text" class="form-control" defaultValue={this.state.rows.workLocation} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>


                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="WorkDescription">
                                    <label class="no-space-break">Work Description</label>
                                    <input required name="workDescription" type="text" class="form-control" value={this.state.rows.workDescription} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="EquipmentLocked">
                                    <label class="no-space-break" >Equipment Locked</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.equipmentLocked} name="equipmentLocked">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="WorkRequirement">
                                    <label class="no-space-break" >Work Requirement</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.workRequirement} name="workRequirement">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="AtmosphericCondition">
                                    <label class="no-space-break" >Atmospheric Condition</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.atmosphericCondition} name="atmosphericCondition">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="AreaBarricaded">
                                    <label class="no-space-break" >Area Barricaded</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.areaBarricaded} name="areaBarricaded">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="StandByGuard">
                                    <label class="no-space-break" >StandBy Guard</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.standByGuard} name="standByGuard">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="PersonalEquipment">
                                    <label class="no-space-break" >Personal Equipment</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.personalEquipment} name="personalEquipment">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="EmergencyRescue">
                                    <label class="no-space-break" >Emergency Rescue</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.emergencyRescue} name="emergencyRescue">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="EmergencyCrew">
                                    <label class="no-space-break" >Emergency Crew</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.emergencyCrew} name="emergencyCrew">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="FirstAid">
                                    <label class="no-space-break" >First Aid</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.firstAid} name="firstAid">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Oxygen">
                                    <label class="no-space-break" >Oxygen</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.oxygen} name="oxygen">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Flammability">
                                    <label class="no-space-break" >Flammability</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.flammability} name="flammability">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Oxygen">
                                    <label class="no-space-break" >Oxygen</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.oxygen} name="oxygen">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Flammability">
                                    <label class="no-space-break" >Flammability</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.flammability} name="flammability">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="Toxicity">
                                    <label class="no-space-break" >Toxicity</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.toxicity} name="toxicity">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="ResultDate">
                                    <label class="no-space-break">Result Date</label>
                                    <input required name="resultDate" type="date" class="form-control" value={this.formatDate(this.state.rows.resultDate)} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="ResultTime">
                                    <label class="no-space-break" >Result Time</label>
                                    <input name="resultTime" type="time" class="form-control" defaultValue={this.state.rows.resultTime} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <div id="Comments">
                                    <label class="no-space-break">Comments</label>
                                    <input required name="comments" type="text" class="form-control" value={this.state.rows.comments} onChange={this.handleChange} />
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="WorkCompletion">
                                    <label class="no-space-break" >Work Completion</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.workCompletion} name="workCompletion">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="Safeguards">
                                    <label class="no-space-break" >Safeguards</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.safeguards} name="safeguards">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <div id="RequirementsAfter">
                                    <label class="no-space-break" >Requirements After</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.requirementsAfter} name="requirementsAfter">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <div id="ManMaterialRemoved">
                                    <label class="no-space-break" >Man Material Removed</label>
                                    <select required class="form-control" onChange={this.handleChange} value={this.state.rows.manMaterialRemoved} name="manMaterialRemoved">
                                        <option selected disabled value='0'>--Select--</option>
                                        <option value={this.state.type1} >Yes</option>
                                        <option value={this.state.type2} >No</option>

                                    </select>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>




                <button type="submit" style={{ float: 'right' }} className="btn btn-success" onSubmit={this.handleSubmit}>UPDATE</button>

                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )
    }
}

export default Editworkpermitcontent