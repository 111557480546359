import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { RiPencilLine } from "react-icons/ri";
import '../index.css';
import Modal from './modal.js';
import Edit from './Editrawmaterial'
import { BiFontSize } from 'react-icons/bi';





class Emissionview extends Component {

    constructor(props) {
        super(props)
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);

        var utype = localStorage.getItem('utype')



        this.state = {
            count: 0,
            type1: 'Newspaper',
            type2: 'Ink',
            type3: 'Chemical',
            type4: 'Plates',
            rows: [],
            row1: { type1: 'newspaper', unit1: 'metricton', consumed1: '', recycle1: '' },
            row2: { type2: 'ink', unit2: 'kgs', consumed2: '313', recycle2: '' },
            row3: { type3: 'chemical', unit3: 'ltr', consumed3: '5050', recycle3: '' },
            row4: { type4: 'plates', unit4: 'nos', consumed4: '41040', recycle4: '' },


        }

    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null ) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        var EmittedId = localStorage.getItem('EmittedId') 

        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/EmissionDetails/GetEmissionDetailsByID', { params: { EmittedId: EmittedId } }).then(response => {
            console.log(response.data);
            this.setState({
                rows: response.data
            });
        });

    }

    isDisabled() {
        var utype = localStorage.getItem('utype')

        if (utype == 1) {
            return true;

        }
        else {
            return false;
        }

    }


    handleAddRow = () => (event) => {

        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]


        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleRemoveSpecificRow = (idx, id) => (e) => {

        e.preventDefault();
        var result = window.confirm("Are you sure you want to delete?");
        if (result) {
            let index = idx + 1;
            axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/EmissionDetails/DeleteEmissionDetails', { params: { EmittedId: id } }).then(response => {
                alert("Entry Deleted Successfully");
                window.location.reload();

            }).catch(err => console.log(err));
        }


        //   let rowarr = [...this.state.rows];
        // let deletedItem = rowarr.findIndex(item => item.id === idx);
        // rowarr.splice(deletedItem, 1);
        // this.setState({...this.state.rows=rowarr})

        // let row = [...this.state.rows.filter(x=>x.id!==idx)];
        // this.setState({rows:row})




        //  const rows = [...this.state.rows]
        //   rows.splice(idx,1)
        //   this.setState({ rows })
        //   e.preventDefault()
        //  var newrows=this.state.rows.slice();
        //  newrows.splice(idx,1);
        //  this.setState({ rows:newrows })

        // return [...this.state.rows.slice(0, idx), ...this.state.rows.slice(idx + 1)]
        //  const newRows = this.state.rows.slice(0, idx).concat(this.state.rows.slice(idx + 1));
        //  this.setState({
        //    rows: newRows,
        //  });

    }

    edit(idx) {


        this.props.history.push("/editemission/?id=" + idx);
    }

    handleSubmit9 = (event) => {


        this.props.history.push("/");

    }



    handleSubmit = event => {
        alert(`${this.state.rows}`)
        const data = new FormData(event.target);
        fetch('weatherforecast', {
            method: 'POST',
            body: data,
        });
    }

    renderRows() {
        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}>




                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="EmissionType" required id="standard-required" defaultValue={item.emissionType} />
                    </td >
                   

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="EmittedQty" required id="standard-required" defaultValue={item.emittedQty} />
                    </td >

                    <td>

                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.emittedId)} style={{ marginRight: '8px' }} disabled={this.isDisabled()}>
                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </button>
                        <button id="delete"
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.emittedId)} disabled={this.isDisabled()}>
                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </button>


                    </td>
                </tr>

            );

        });

    }




    render() {

        return (
            <Form className="font-link" >

                <br />
                {/*  <button id="logout" onClick={this.handleSubmit9} style={{ float: 'right' }} class="btn btn-outline-danger btn-sm" to="/"><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg></button><br /> <br /> */}
                <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                    <thead>
                        <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                            <th>EMISSION TYPE</th>
                            
                            <th>QUANTITY </th>

                            <th>ACTION</th>

                        </tr>
                    </thead>
                    <tbody>







                        {this.renderRows()}

                    </tbody>
                </Table>





                {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


            </Form>

        )


    }
}

export default Emissionview