import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'
import '../index.css';

class Editsafety extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: '',
            id: 0,
            isRequired: '',
            comments: '',
            type1:'Yes',
            type2:'No',
            type3:'NA',
          
        };
    }

    componentDidMount() {

        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/SafetyWalkList/GetSafetyWalkListById', { params: { SafetyId: id } }).then(response => {
            console.log(response.data);

            this.setState({
                rows: response.data[0]
            });
          

        });
    }
    handleSubmit = event => {

        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/SafetyWalkList/UpdateSafetyWalkList', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push('/safetywalkview')

            }).catch(err => console.log(err));
    }

    handleChange = event => {
        
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;

        const value = event.target.value;
        var rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
            
            [name]: value
        };
        rows= row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <div className="form-container">
                <Form className="register-form" onSubmit={this.handleSubmit}>
                    <br />
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label class="no-space-break"htmlFor="question">{ this.state.rows.question}</label>
                          
                        </div>
                    </div>
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="IsRequired">Is Required</label>
                            <select required class="form-control" onChange={this.handleChange} value={this.state.rows.isRequired} name="isRequired">
                                <option selected disabled value='0'>--Select--</option>
                                <option value={this.state.type1} >Yes</option>
                                <option value={this.state.type2} >No</option>
                                <option value={this.state.type3} >NA</option>
                               </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div class="col-sm-3">
                            <label htmlFor="comments">Comments</label>
                            <input name="comments" onChange={this.handleChange} className="form-control" id="comments" defaultValue={this.state.rows.comments} />
                        </div>
                    </div>
                    

                    <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Update</button>

                </Form>
            </div>
        );
    }
}

export default Editsafety              