import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string'


class Editwaterwithdrawal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            id: 0,
            GroundWater: '',
            RiverOrSurfaceWater: '',
            MunicipalOrOther: '',
            WithdrawalHarvest:''
        };
    }

    componentDidMount() {
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 1) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
        const values = this.props.location.search
        const params = new URLSearchParams(values);
        const id = params.get('id');
        axios.get(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalWaterWithdrawn/GetTotalWaterWithdrawnByID', { params: { RenewId: id } }).then(response => {
            console.log(response.data);
            this.setState({
                GroundWater: response.data[0].groundWater,
                RiverOrSurfaceWater: response.data[0].riverOrSurfaceWater,
                MunicipalOrOther: response.data[0].municipalOrOther,
                WithdrawalHarvest: response.data[0].withdrawalHarvest,

                id: id
            });

        });
    }
    handleSubmit = event => {
      
        event.preventDefault();
        let row = this.state.rows;

        const Options = {
            method: 'PUT',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(row)
        };
        // let newbody = Options.body;
        //newbody = newbody.substring(1, newbody.Length);
        let newbody = JSON.parse(Options.body);
        var submissionid = localStorage.getItem('submissionid')
        axios.put(process.env.REACT_APP_GLOBAL_CODE +'/api/TotalWaterWithdrawn/UpdateTotalWaterWithdrawn', newbody)
            .then(response => {
                alert("Entry Updated Successfully");
                this.props.history.push("/energyview/?name=" + "20.Total Water Withdrawal &&" + "id=" + submissionid);

            }).catch(err => console.log(err));
    }

    handleChange = event => {
       
        event.preventDefault();
        // const { name, value } = e.target;
        const name = event.target.name;
       
      
        const value = parseFloat(event.target.value);
        
        let rows = this.state.rows;
        let idx = parseInt(this.state.id)
        let row = {

            ...rows,
            TotWatId: idx,
            [name]: value
        };
        rows = row;
        this.setState({
            rows
        });
        console.log(this.state.rows)
    };







    // handleChange = (event) => {
    //   this.setState({ type: event.target.value });
    // }




    //  handleSubmit = (event) => {
    //    event.preventDefault();
    //  const { type, unit, qtyconsumed, qtyrecycled } = this.state
    // }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="GroundWater">Ground Water</label>
                        <input type="number" name="GroundWater" step="any" className="form-control" id="GroundWater" defaultValue={this.state.GroundWater} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="RiverOrSurfaceWater">River/Surface Water</label>
                        <input type="text" name="RiverOrSurfaceWater" step="any" className="form-control" id="RiverOrSurfaceWater" defaultValue={this.state.RiverOrSurfaceWater} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="MunicipalOrOther">Municipal/Others</label>
                        <input type="text" name="MunicipalOrOther" step="any" className="form-control" id="MunicipalOrOther" defaultValue={this.state.MunicipalOrOther} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div class="col-sm-3">
                        <label htmlFor="WithdrawalHarvest">Withdrawal Harvest</label>
                        <input type="text" name="WithdrawalHarvest" step="any" className="form-control" id="WithdrawalHarvest" defaultValue={this.state.WithdrawalHarvest} onChange={this.handleChange} />
                    </div>
                </div>


                <button type="submit" style={{ marginLeft: '180px' }} className="btn btn-success">Submit</button>
            </Form>
        );
    }
}

export default Editwaterwithdrawal