import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import Modal from "./modal.js";

import axios from 'axios';
import { RiAdminFill, RiPencilLine } from "react-icons/ri";
import '../index.css';
import Edit from './Editrawmaterial'
import { BiFontSize } from 'react-icons/bi';

import {
    PopupboxManager,
    PopupboxContainer
} from 'react-popupbox';





class Userlist extends Component {

    constructor(props) {
        super(props)
        



        this.state = {
            count: 0,
            type1: 'Approved',
            type2: 'Rejected',
            addModalShow: false,
            types: [],
            rows: [],
            admin: 1,
            utype: localStorage.getItem('utype'),



        }

    }




    componentDidMount() {

        
        var loggedin = sessionStorage.getItem('LoggedIn')
        var utypeid = localStorage.getItem('utypeid')
        if (loggedin == null || utypeid == 2) {
            window.location.href = '/'
            return;
        }
        document.getElementById("navribbon").style.display = "none";
        localStorage.setItem('homepage', false);
     //   var location=localStorage.getItem('location')
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/GetUserDetails')
            .then(response => {
                
                console.log(response.data);
            //  console.log(response.data[0].location.locationName);
            this.setState({
                rows: response.data
            });
        });
    }




    handleChange1 = (idx, id) => (e) => {
        

        e.preventDefault();


        // const { name, value } = e.target;
        const name = e.target.name;


        //let id=name+(idx);

        const value = e.target.value;
        const rows = [...this.state.rows];

        const types = [...this.state.types];
        let row = {


            "SubmissionId": rows[idx].submissionId,
            "UserId": rows[idx].userId,
            "ApprovedDate": new Date(),
            "RegulatorId": 1,
            [name]: value



        };
        types[0] = row;
        this.setState({
            types
        });


        const Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(types)
        };
        let newbody = JSON.parse(Options.body);
        axios.post(process.env.REACT_APP_GLOBAL_CODE +'/api/GetAdminApprovalForm/SaveAdminApprovedData', newbody)
            .then(json => {
                if (json.data.Status === 'Success') {
                    console.log(json.data.Status);
                    alert("Status Updated Successfully");
                    window.location.reload();
                }

                else {
                    alert('Status Updated Successfully');
                    window.location.reload();
                }
            })
        console.log(this.state.types)
    };



    handleRowClick = (id) => (event) => {
        
        this.props.history.push("/energyview/?id=" + id);
    }


    handleAddRow = () => (event) => {

        let count = this.state.count
        count = count + 1;
        this.setState({ count })

        // this.setState((prevState) => ({ count: prevState.count + '1'}));

        const item = {
            id: count
            // type: "",
            // unit: "",
            // consumed: "",
            // recycle:""

        };
        event.preventDefault();
        this.setState({
            rows: [...this.state.rows, item]


        });
    };
    // handleRemoveRow = (event) => {
    //   event.preventDefault();
    //   this.setState({
    //     rows: this.state.rows.slice(0, -1)
    //   });
    // };
    handleDisableUser = (id) => (e) => {

        e.preventDefault();
        let active = '0';
        axios.delete(process.env.REACT_APP_GLOBAL_CODE +'/api/UserRegistration/DisableUserDetails', { params: { UserId: id ,isActive:active} }).then(response => {
            alert("User Disabled Successfully");
            window.location.reload();

        }).catch(err => console.log(err));

    }


    handleEnableUser = (id) => (e) => {

        e.preventDefault();
        let active = '1';
        axios.delete(process.env.REACT_APP_GLOBAL_CODE + '/api/UserRegistration/EnableUserDetails', { params: { UserId: id, isActive: active } }).then(response => {
            alert("User Enabled Successfully");
            window.location.reload();

        }).catch(err => console.log(err));
    }


    edit(idx) {


        this.props.history.push("/editusers/?id=" + idx);
    }

    isDisplayed(isActive) {

        
        
        if (isActive == '1') {
            return "inline"

        }
        else {
            return "none";
        }


    }
    isDisplayed1(isActive) {

        
        if (isActive == '0') {
            return "inline"

        }
        else {
            return "none";
        }


    }

    handleSubmit = event => {
        alert(`${this.state.rows}`)
        const data = new FormData(event.target);
        fetch('weatherforecast', {
            method: 'POST',
            body: data,
        });
    }

    renderRows() {

        return this.state.rows.map((item, idx) => {
            return (

                <tr id="addr0" key={item.id}  >




                    {/*  <td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="submissionid" required id="standard-required" defaultValue={item.submissionId} />
                    </td > */}

                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="Uname" required id="standard-required" defaultValue={item.uname} />
                    </td>


                    <td>

                        <TextField InputProps={{ readOnly: true }} type="text" name="Address" required id="standard-required" defaultValue={item.address} />



                    </td >
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="Contact" required id="standard-required" defaultValue={item.contact} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="Email" required id="standard-required" defaultValue={item.email} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="Role" required id="standard-required" defaultValue={item.roleType} />
                    </td>
                    <td>
                        <TextField InputProps={{ readOnly: true }} type="text" name="Location" required id="standard-required" defaultValue={item.locationName} />
                    </td>

                    <td style={{ width: '20%' }}>

                        <button class="btn btn-outline-danger btn-sm" onClick={() => this.edit(item.userId)} style={{ marginRight: '8px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                               
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </button>
                        <button id="disable"
                            title="Disable User"
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleDisableUser(item.userId)} style={{ marginRight: '8px', display: this.isDisplayed(item.isActive) }}>
                          
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x" viewBox="0 0 16 16">
                               

  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
  <path fill-rule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
</svg>
                        </button>
                        <button id="enable"
                            title="Enable User"
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleEnableUser(item.userId)} style={{ marginRight: '8px', display: this.isDisplayed1(item.isActive) }} >

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  class="bi bi-person-check" viewBox="0 0 16 16"> 
                              
                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </button>

                    </td>
                    {/*  <td>
                        <TextField InputProps={{ readOnly: true }} type="number" name="submitted" required id="standard-required" defaultValue={item.submittedTo} />
                    </td> */}
                    {/*  <td>
                       
                        <FormControl>
                           
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue="Select"
                                name="status"
                                onChange= {this.handleChange1(idx,item.submissionId)}
                            >
                                <MenuItem value={this.state.type1}>Approve</MenuItem>
                                <MenuItem value={this.state.type2}>Reject</MenuItem>
                               
                            </Select>
                                
                               
                            </FormControl></td> */}




                </tr>


            );

        });

    }




    render() {
        let addModalClose = () => this.setState({ addModalShow: false });
        return (
            <div>
                <Form className="font-link" >
                    {/*  <h5>USER SUBMISSION </h5> */}

                    <Table hover style={{ "borderWidth": "1px", 'borderColor': "white" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#001946', color: 'white' }}>

                                {/* <th>SUBMISSION ID</th> */}


                                <th>USERNAME</th>
                                <th>ADDRESS</th>
                                <th>CONTACT</th>
                                <th>EMAIL ID</th>
                                <th>ROLE</th>
                                <th>LOCATION</th>
                                <th>ACTION</th>


                            </tr>
                        </thead>
                        <tbody>







                            {this.renderRows()}




                        </tbody>
                    </Table>





                    {/* <button onClick={this.handleRemoveRow} className="btn btn-danger">Delete Row</button> */}


                </Form>
            </div>
        )


    }
}


export default Userlist