import React, { Component } from "react";
import "./login.css";
import CustomInput from "./CustomInput";
import { ButtonToggle } from "reactstrap";
import axios from 'axios';
import Button from "./Button";
import background from "../pic.jpg";

class ForgotPassword extends Component {
    state = {
        email: ""
        
    };


    componentDidMount() {
        document.getElementById('header').style.display = "none"
        document.getElementById("navribbon").style.display = "none";
    }



    handleChange = e => {
        this.setState({ [e.currentTarget.id]: e.currentTarget.value });
    };

    handleClick = event => {
        debugger;
        event.preventDefault();
        window.location.href = '/'
    };
    handlePassword = e => {
        debugger;
        e.preventDefault();

        var email = this.state.email;
        var passwordlink = process.env.REACT_APP_GLOBAL_CODE + '/changepassword?email='+email+'&&page=forgotpassword'
        axios.get(process.env.REACT_APP_GLOBAL_CODE + '/api/UserLogin/checkEmailExists', { params: { Email: email } })
            .then(response => {
                if (response.data.length < 1) {
                    alert("Email doesn't exist in our database")
                    window.location.reload();
                }
                else {
                    let rows = {
                        "ToEmail": email,
                        "Subject": "Recover Your Account",
                        "Body": passwordlink

                    }

                    const Options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(rows)
                    };
                    let newbody = JSON.parse(Options.body);
                    axios.post(process.env.REACT_APP_GLOBAL_CODE + '/api/Mail/Send', newbody)
                        .then(response => {
                            alert("Recovery Email Sent Successfully")
                            this.props.history.push("/");
                        })
                }
            })
       
    };
  

    render() {
        return (
            /* <div style={{ backgroundImage: `url(${background})`, backgroundsize: "cover",
                backgroundPosition: "center center"
               }}>*/

            <div className="forgot-wrapper">
                <form id="loginform"  >
                    <div className="form-group">
                        <h5>FORGOT PASSWORD</h5>
                        <br />

                        <label for='email' className="no-space-break" style={{color:'white'}}> Email Address</label>
                        <input required type="text" name="email" id="email" onChange={this.handleChange} />
                        <span style={{ float: 'left', paddingTop: '15px' }}><a style={{ color: 'white' }} href="" onClick={this.handleClick}>Back to Login</a></span> 

                        <Button onClick={this.handlePassword} style={{ float: 'right' }} type="button" color="primary" className="form__custom-button">
                            SUBMIT
          </Button>
                    </div>
                </form>
            </div >
            /*</div>*/
        );
    }
}
export default ForgotPassword    